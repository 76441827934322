import { MDBCard } from "mdbreact";
import React from "react";
import { Form, Row } from "react-bootstrap";

export default function Update(){
    return(
        <MDBCard className="company">
        <h1>Update Assign To Staff</h1>
        <Form className="product1">
            <Row className="mb-1">
                hello update
                </Row>
                </Form>
                </MDBCard>
    )
}