import React from 'react'
import { Mydatatable } from '../Master/Product/Mydatatable';

const Newsupplier = () => {

    let header = [
        { "label": "Id", "field": "id" },
        { "label": "Company Name", "field": "companyname" },

        // { "label": "Email", "field": "email" },
        { "label": " City", "field": "cities" },
        // { "label": "Phone number  ", "field": "phonenumber" },
        { "label": "State Name", "field": "state_name" },
        { "label": "GST No", "field": "gstno" },
        { "label": "Created Date", "field": "createddate" },
        { "label": "Created Time", "field": "createdtime" },
        { "label": "Created By", "field": "createbyname" },
     { "label": "Updated Date", "field": "updateddate" },
     { "label": "Updated Time", "field": "updatedtime" },
     { "label": "Updated By", "field": "updatedbyname" },
        { "label": "Action", "field": "action", "subaction1": <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" /><circle cx="12" cy="12" r="3" /></svg> }
    ];



    //   "id": 1,
    //   "grossamount": 89991.0,
    //   "remarks": null,
    //   "igst": 12.0,
    //   "grandtotal": 89000.0,
    //   "sdndate": null,
    //   "suppliername": "Josh"






    const sumofkeys = [{ "first": 'grossamount' }, { "first": 'igst' }]


    // grandtotal

    // grossamount

    // id

    // igst

    // remarks

    // sdndate


    // suppliername



    return (
        <Mydatatable
            head={header}
            title={"Supplier  "}
            axiostabledata={'supplier'}
            Add={"Add Supplier"}
            Create={'/SupplierCreate'}
            Edit={"/SupplierView"}
            View={"/SupplierView"}
            Sumofkeys={sumofkeys}
        />


    )
}

export default Newsupplier