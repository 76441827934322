import React from "react";
import { TransportReportComponent } from "./TransportReportComponent";
// import { Mydatatable } from "../Master/Product/Mydatatable";

function TransportReport() {
  let header = [
    { label: "Voucher Date", field: "voucherDate" },
    { label: "Voucher Number", field: "voucherNumber" },
    { label: "Party Name", field: "partyName" },
    { label: "Destination", field: "destination" },
    { label: "Amount ", field: "amount" },
    { label: " Transporter ", field: "transporter" },
    { label: "Transport Charges", field: "transportCharges" },
    { label: "%", field: "freightCost" },
    { label: "LR No.", field: "lrNumber" },
    { label: "LR Date", field: "lrnDate" },
    { label: "E.D.D.", field: "edd" },
    { label: "GRN No.", field: "grnNumber" },
    { label: "GRN Date", field: "grnDate" },
    { label: "EARLY/LATE Delivery", field: "earlyLateDelivery" },
    { label: "Total No. Of PKGS", field: "totalNoOfPKGS" },

    // {
    //   label: "Action",
    //   field: "action",
    //   subaction1: (
    //     <svg
    //       xmlns="http://www.w3.org/2000/svg"
    //       width="24"
    //       height="24"
    //       viewBox="0 0 24 24"
    //       fill="none"
    //       stroke="currentColor"
    //       stroke-width="2"
    //       stroke-linecap="round"
    //       stroke-linejoin="round"
    //       class="feather feather-eye"
    //     >
    //       <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
    //       <circle cx="12" cy="12" r="3" />
    //     </svg>
    //   ),
    // },
  ];
  const sumofkeys = [{ first: "amount" }];
  return (
    <>
      <TransportReportComponent
        head={header}
        title={"Transport Report"}
        axiostabledata={"sales/transportReport"}
        Add={""}
        Create={""}
        Edit={""}
        View={""}
        Sumofkeys={sumofkeys}
      />
    </>
  );
}

export default TransportReport;
