import React from 'react'
import Myreportcomponent from './Myreportcomponent'

const RetailerReport = () => {

    const data = [{ "label": "Retailer", "id": "retid", "type": "select" },
    { "label": "Start Date", "id": "startDate", "type": "Date" },
    { "label": "End Date", "id": "endDate", "type": "Date" }]


  return (
    <Myreportcomponent
    title={"Retailer Report"}
    data={data}
    axiostable={'retailer'}

    // title={"Distributor Report"}
    // data={data}
//    axiostable={'distributor'} 
   tradeName={'tradeName'}
   link={'RetailerId'}
    />
  )
}

export default RetailerReport