import axios from "axios";
import { MDBCard } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../services/Api";
import authHeader from "../services/auth-header";
import QRCode from "react-qr-code";
import { jwtDecode } from "jwt-decode";
// import jwt_decode from 'jwt-decode';

export default function EWayBillPrint(){
  const { id } = useParams();
  const [BuyerGstin, setBuyerGstin] = useState("");
  // const [DocDt, setDocDt] = useState("");
  const [DocNo, setDocNo] = useState("");
  const [DocTyp, setDocTyp] = useState("");
  const [Irn, setIrn] = useState("");
  const [IrnDt, setIrnDt] = useState("");
  const [ItemCnt, setItemCnt] = useState("");
  const [MainHsnCode, setMainHsnCode] = useState("");
  const [SellerGstin, setSellerGstin] = useState("");
  const [TotInvVal, setTotInvVal] = useState("");
  const [data,setdata] = useState('');
  const [salesItems,setsalesItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [DocDt, setDocDt] = useState([])
  const [value, setValue] = useState([]);
const [back, setBack] = useState('#FFFFFF');
const [fore, setFore] = useState('#000000');
const [size, setSize] = useState(256);

  const getsalesdata = () => {
    axios.get( BASE_URL+ 'sales/' +id,{
      headers:authHeader()
    } ).then(res=>{
      setIsLoading(true)
      console.log(res.data,"mello")
      setdata(res.data);
      setsalesItems(res.data.salesItems);
      // setValue(res.data.signedQrCode);
      console.log(res.data.signedQrCode)
      const token = res.data.signedQrCode;
const decodedToken = jwtDecode(token);
// console.log(JSON.parse(decodedToken.data));
console.log(decodedToken)
const data=[JSON.parse(decodedToken.data)]

console.log(data)
setBuyerGstin("BuyerGstin -"+data.map((item)=>item.BuyerGstin)[0])
// setDocDt("DocDt -"+data.map((item)=>item.DocDt)[0])
setDocNo("DocNo -"+data.map((item)=>item.DocNo)[0]);
// setValue("SellerGstin -"+data.map((item)=>item.SellerGstin)[0]);
const typist = `BuyerGstin: ${data.map((item) => item.BuyerGstin)[0]}\nDocNo-: ${data.map((item) => item.DocNo)[0]}\nDocDt-: ${data.map((item) => item.DocDt)[0]}\nDocTyp-: ${data.map((item) => item.DocTyp)[0]}\nIrn-: ${data.map((item) => item.Irn)[0]}\nItemCnt-: ${data.map((item) => item.IrnDt)[0]}\nMainHsnCode-: ${data.map((item) => item.MainHsnCode)[0]}\nSellerGstin-: ${data.map((item) => item.SellerGstin)[0]}\nTotInvVal-: ${data.map((item) => item.TotInvVal)[0]}`;


setValue(typist)
    })
  };

  useEffect(()=>{
     getsalesdata()
  },[]);

  const Print1 = () => {
    
      //console.log('print');  
      let printContents = document.getElementById('Print').innerHTML;
      let originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
    
  }
  // useEffect(()=>{
  //   Print1();
  // },[isLoading])
// useEffect(()=>{
//   if (isLoading == true) {
//   Print1()
//   }
// },[isLoading])

    return(
        <MDBCard className="company">
           <div id="Print" style={{border:'1px solid black',padding:'5px'}}>
            <h1 style={{textAlign:'center'}}>E-Way Bill</h1>
            <div style={{padding:'0px 50px'}}>
          <div style={{display:'flex',justifyContent:'space-between',borderBottom:'1px solid black',padding:'0px 8px'}}>
            <div>
          
             <span style={{fontSize:'15px',fontWeight:700}}>DOC NO : {data.no}</span><br></br>
             <span style={{fontSize:'15px',fontWeight:700}}>Date : {data.dt}</span><br></br>
             <span style={{fontSize:'15px',fontWeight:700}}></span><br></br>
             <span style={{fontSize:'15px',fontWeight:700}}>IRN : {data.irnno}</span><br></br>
             <span style={{fontSize:'15px',fontWeight:700}}>ACK NO : {data.ackno}</span><br></br>
             <span style={{fontSize:'15px',fontWeight:700}}>ACK DATE : {data.ackdate}</span><br></br>
            </div>
            <div >
              <span style={{fontSize:'15px',fontWeight:700,marginLeft:'2px'}}>E-Way Bill</span><br></br>
              {value && (
                    <QRCode
                        title="GeeksForGeeks"
                        value={value}
                        bgColor={back}
                        fgColor={fore}
                        size={256}
                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    />
                )}
            </div>
          </div>
          <div style={{display:'flex',justifyContent:'space-between',borderBottom:'1px solid black',padding:'0px 8px'}}>
           <div style={{display:'flex'}}>
            <div>
           <span style={{fontSize:'15px',fontWeight:700}}>1.e-Way Bill Details</span><br></br>
            <span style={{fontSize:'15px',fontWeight:700}}>e-Way Bill No. : {data.ewaybillno}</span><br></br>
            <span style={{fontSize:'15px',fontWeight:700}}>Generated By : </span><br></br>
            <span style={{fontSize:'15px',fontWeight:700}}>Supply Type : {data.supTyp}</span><br></br>
            </div>
           <div>
            <span></span><br></br>
           <span style={{fontSize:'15px',fontWeight:700}}>Mode : {data.transMode}</span><br></br>
           <span style={{fontSize:'15px',fontWeight:700}}>Approx Distance : {data.distance}</span><br></br>
           <span style={{fontSize:'15px',fontWeight:700}}>Transaction Type : {data.paymentTerms == null?"":data.paymentTerms}</span>
           </div>
           </div>

           <div>
            <span style={{fontSize:'15px',fontWeight:700}}>Generated Date : {new Date(data.ewbDt).toLocaleDateString('in-CA')}</span><br></br>
            <span style={{fontSize:'15px',fontWeight:700}}>Valid Upto : {data.expDt}</span>
           </div>
          </div>

          <div style={{display:'flex',justifyContent:'space-between',borderBottom:'1px solid black',padding:'0px 8px'}}>

           <div style={{width:'80%'}}>
            <span style={{fontSize:'15px',fontWeight:700}}>2. Address Details</span><br></br>
            <span style={{fontSize:'15px',fontWeight:700}}>Form</span><br></br>
            <span style={{fontSize:'15px',fontWeight:700}}>{data.lglNm}</span><br></br>
            <span style={{fontSize:'15px',fontWeight:700}}>GSTIN : {data.gstin}</span><br></br>
            <span style={{fontSize:'15px',fontWeight:700}}>MAHARASHTRA</span><br></br>
            <span style={{fontSize:'15px',fontWeight:700}}>Dispatch From</span><br></br>
            <span style={{fontSize:'15px',fontWeight:700}}>{data.addr1}</span><br></br>
            <span style={{fontSize:'15px',fontWeight:700}}>{data.addr2}</span><br></br>
            {/* <span style={{fontSize:'15px',fontWeight:700}}>Village, Next to Varun Industries,, Vasai East. Dist: </span> */}
           </div>
           <div style={{width:'80%'}}>
            <span></span><br></br>
            <span style={{fontSize:'15px',fontWeight:700}}>To</span><br></br>
            <span style={{fontSize:'15px',fontWeight:700}}>{data.blglNm}</span><br></br>
            <span style={{fontSize:'15px',fontWeight:700}}>GSTIN : {data.bgstin}</span><br></br>
             <span style={{fontSize:'15px',fontWeight:700}}>BENGAL</span><br></br>
             <span style={{fontSize:'15px',fontWeight:700}}>Ship To</span><br></br>
             <span style={{fontSize:'15px',fontWeight:700}}>{data.baddr1}</span><br></br>
             <span style={{fontSize:'15px',fontWeight:700}}>{data.baddr2}</span>

           </div>

          </div>
           <div style={{display:'flex',justifyContent:'space-between',borderBottom:'1px solid black',padding:'0px 8px'}}>
            <span style={{fontSize:'15px',fontWeight:700}}>3. Goods Details</span>
           </div>
           <Table>
          
            <tr>
              <th className="thead">HSN CODE</th>
              <th className="thead">Product Name & Desc</th>
              <th className="thead">Quantity</th>
              <th className="thead">Taxable Amt</th>
              <th className="thead">Tax Rate</th>
            </tr>
           
            <tbody>
              {salesItems.map(item=>
              <tr key={item.id}>
              <td>{item.hsncode}</td>
              <td>{item.productName}</td>
              <td>{item.salesaltquantity}</td>
              <td>{item.gstvalue}</td>
              <td>{item.total}</td>
            </tr>

              )}

              
            </tbody>
            
           </Table>
           <div style={{display:'flex',justifyContent:'space-between',borderBottom:'1px solid black',padding:'0px 8px'}}>
            <div style={{display:'flex'}}>
              <div>
              <span style={{fontSize:'15px',fontWeight:700}}>Tol.Taxable Amt : </span><br></br>
              <span style={{fontSize:'15px',fontWeight:700}}>IGST Amt : {data.igstAmt}</span>
              </div>
              <div>
              <span style={{fontSize:'15px',fontWeight:700}}>Other Amt : </span>
               
              </div>
            </div>

            <div>
              <span style={{fontSize:'15px',fontWeight:700}}>Total lnv Amt : {data.totInvVal}</span>
            </div>
           </div>
           <div style={{display:'flex',justifyContent:'space-between',borderBottom:'1px solid black',padding:'0px 8px'}}>
            <div>
                <span style={{fontSize:'15px',fontWeight:700}}>4. Transportation Details</span><br></br>
              <span style={{fontSize:'15px',fontWeight:700}}>Tol.Taxable Amt : </span><br></br>
              <span style={{fontSize:'15px',fontWeight:700}}>IGST Amt : </span>
            </div>

            <div>
              <span></span><br></br>
            <span style={{fontSize:'15px',fontWeight:700}}>Doc No. : </span><br></br>
            <span style={{fontSize:'15px',fontWeight:700}}>Date : </span>
            </div>
           </div>
           <div style={{display:'flex',justifyContent:'space-between',borderBottom:'1px solid black',padding:'0px 8px'}}>
           <div>
                <span style={{fontSize:'15px',fontWeight:700}}>5. Vehicle Details</span><br></br>
              <span style={{fontSize:'15px',fontWeight:700}}>Vehicle No. : {data.vehNo}</span>&nbsp;&nbsp;&nbsp;
              <span style={{fontSize:'15px',fontWeight:700}}>From : </span>
            </div>

            <div>
              <span></span><br></br>
              <span style={{fontSize:'15px',fontWeight:700}}>CEWB No. : </span>
            </div>
           </div>

          </div>
          </div>
        </MDBCard>
    )
}