import React, { useState } from 'react';
import * as XLSX from 'xlsx';

const Excelsheet = () => {
  const [hasValidEntries, setHasValidEntries] = useState(false);
  const [incompleteCells, setIncompleteCells] = useState([]);

  const isValidEntry = (cell) => {
    // Check if the cell is empty or undefined
    if (cell === undefined || cell === '') return false;

    // Check if the cell is a valid alphanumeric string
    const isAlphanumeric = /^[a-zA-Z0-9]*$/.test(cell);

    // Regular expressions to match date formats
    const isDateFormat = /^(0?[1-9]|[12][0-9]|3[01])[-/](0?[1-9]|1[0-2])[-/](\d{2}|\d{4})$/; // Matches DD-MM-YYYY or DD/MM/YYYY
    const isDate = (value) => {
      const date = new Date(value);
      return !isNaN(date.getTime()) || isDateFormat.test(value);
    };

    // Ensure the cell is alphanumeric and not a date
    return isAlphanumeric && !isDate(cell);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });

        // Assuming you want the first sheet
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];

        // Convert to JSON
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        // Check the first column for valid entries and incomplete cells
        const firstColumn = jsonData.map(row => row[0]);
        const validEntries = firstColumn.filter(cell => isValidEntry(cell));
        const incomplete = firstColumn
          .map((cell, index) => (cell === undefined || cell === '' ? index + 1 : null))
          .filter(index => index !== null); // Collect indices of incomplete cells

        setHasValidEntries(validEntries.length > 0);
        setIncompleteCells(incomplete);
      };

      reader.readAsArrayBuffer(file);
    }
  };

  return (
    <div>
      <input type="file" accept=".xlsx, .xls" onChange={handleFileUpload} />
      {hasValidEntries && <p>The first column contains valid alphanumeric entries!</p>}
      {!hasValidEntries && <p>The first column does not contain valid alphanumeric entries.</p>}
      {incompleteCells.length > 0 && (
        <p>Incomplete cells found in the first column at rows: {incompleteCells.join(', ')}</p>
      )}
      {incompleteCells.length === 0 && hasValidEntries && (
        <p>All cells in the first column are complete and valid.</p>
      )}
    </div>
  );
};

export default Excelsheet;
