import axios from 'axios';
import React, { useState } from 'react'
import authHeader from '../../services/auth-header';
import { useNavigate } from 'react-router-dom';

const Transportcreate = () => {
const [transporterGstin, setGstin] = useState([])
const [transporterName,settransporterName]=useState([])
const navigate = useNavigate();

const submitTransporterData = () => {
     axios.post('http://43.225.54.138:8080/scm/api/transporter/', {
        transporterGstin: transporterGstin,
        transporterName:transporterName
    }, {
        headers: authHeader()  
    })
    .then(res => {
        if (res.status === 201 || res.status === 200) {
            console.log('Successfully added:', res.data);
            navigate('/Transporttable/1')

         }
    })
    .catch(error => {
        console.error('An error occurred:', error);
     })
  
};


  return (
 
         
<div className='card card-body'>
<div className='card-header'>

<b>Transport create</b>
<div className='row'>
    <div className='col'>
        <label className='form-label'>Transporter GST Number </label>
        <input type='text' onChange={(e)=>setGstin(e.target.value)} name='TransporterGSTNumber' className='form-control'/>
    </div>
    <div className='col'>
        <label className='form-label'>Transporter Name</label>
        <input type='text' onChange={(e)=>settransporterName(e.target.value)} name='TransporterName' className='form-control'/>
    </div>
</div>
<button className='btn btn-primary' onClick={()=>submitTransporterData()}>Submit</button>
</div>

</div>
 
  )
}

export default Transportcreate