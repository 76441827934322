import axios from "axios";
import authHeader from "./auth-header";

const API_URL = "http://43.225.54.138:8080/scm/api/";


const gettemporaryReatilerTable = () => {
    return axios.get(API_URL +"retailertemporary/getAll", { headers: authHeader() });
  };

  const gettemporaryReatilerCreate = (reatiler) => {
    return axios.post(API_URL +"create1",reatiler, { headers: authHeader() });
  };
const gettemporarycompanyEdit = (Retailer) => {
  return axios.post(API_URL + "retailer/convertto", Retailer,{ headers: authHeader() });
};

const gettemporaryRetailerGet = (id) => {
  return axios.get(API_URL + "retailertemporary/getById/"+id,{ headers: authHeader() });
};

const gettemporaryDelete = ( id ) => {
    return axios.delete(API_URL + "retailertemporary/delete/"+id, { headers: authHeader() });
  };
const TemporaryReatilerService = {
    gettemporaryReatilerTable,
    gettemporaryReatilerCreate,
    gettemporarycompanyEdit,
    gettemporaryRetailerGet,
    gettemporaryDelete

};

export default TemporaryReatilerService;