import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Select from "react-select";
import authHeader from '../services/auth-header';
import { Mydatatable } from '../Master/Product/Mydatatable';
import { Newtablejs } from '../Master/Product/Newtablejs';
import AuthService from '../services/auth.service';

const Warehousestockreport = () => {
    const currentuser = AuthService.getCurrentUser();
console.log( currentuser.roles[0]=='ROLE_ADMIN' )
    const [disword, setDisword] = useState(encodeURIComponent(' '));
    const [dis, setDis] = useState([]);
    const [date, setDate] = useState([]);
    const [excel, setExcel] = useState([]);
    const [warehouse, setWarehouse] = useState(null);
    const [data2, setdata2] = useState(false)
    let header = [
        { "label": "Id", "field": "id" },
        { "label": "Date", "field": "pdate" },
        { "label": "Product Name", "field": "productname" },
        // { "label": "  Name      ", "field": "name" },
        { "label": "Quantity (Pcs)", "field": "dlppcs" },
        { "label": "Quantity (Kgs)", "field": "dlpkg" },

      
      
       ];
    
      const sumofkeys = [{ "first": 'id' }]
    
    useEffect(() => {
        axios.get(`http://43.225.54.138:8080/scm/api/warehouse/page/0/10/asc/id/${disword.length ? disword : encodeURIComponent(' ')}`, { headers: authHeader() })
            .then(response => {
                console.log(response);
                let data = response.data.data || response.data.Index;
                setDis(data.map(use => ({ value: use.id, label: use.name })));
            })
            .catch(error => {
                console.error('Error fetching warehouse data:', error);
            });
    }, [disword]);

    const onChangewarehouse = (selectedOption) => {
        setWarehouse(selectedOption);
        setdata([])

    };

    const handleDateChange = (e) => {
        setDate(e.target.value);
        setdata([])

    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        setExcel(file);
        setdata([])
    };
const [data, setdata] = useState([])
    const handleSubmit = ( ) => {
 
         const formData = new FormData();
        formData.append('warehouse', warehouse.value);  
        formData.append('pdate', date);
        if (excel) {
            formData.append('excel', excel);
        }

         axios.post('http://43.225.54.138:8080/scm/api/warehouseupload/', formData, { headers: authHeader() })
            .then(response => {
                setdata(response.data)
                setdata2(false)

                console.log('Response from server:', response);
             })
            .catch(error => {
                console.error('Error submitting form:', error);
             });
    };
    const handleClearExcel = () => {
        setExcel([]);  
    };

    const [widinout, setwidinout] = useState([])
    const [inputdatw, setinputdatw] = useState([])
const handledatewid=()=>{
    setdata2(false)
    setwidinout(warehouse)
setinputdatw(date)
    setdata([])
    setdata2(true)
}
    console.log([excel][0].name)
    return (
        <div className='card'>
            <div className='card-header flex'>
                <h1>Warehouse Stock Report</h1>
            </div>
            <div className='card-body'>
                <form onSubmit={handleSubmit}>
                    <div className='row'>
                        <div className='col'>
                            <label className='form-label'>Select Warehouse:</label>
                            <Select options={dis} onInputChange={(inputValue) => setDisword(inputValue)} onChange={onChangewarehouse} />
                        </div>
{currentuser.roles[0]=='ROLE_ADMIN'?
 <>
                        <div className='col'>
                            <label className='form-label'>Date:</label>
                            <input type='date' className='form-control' value={date} onChange={handleDateChange} />
                        </div>
                        <div className='col'>
                            <label className='form-label'>Upload file:</label>
                           {excel.length==0? <input type='file'  className='form-control'  onChange={handleFileUpload} />:<dd><p className="form-control" onClick={()=>{ setdata([]);   setExcel([]); console.log("empty")}}>{[excel][0].name} </p></dd>}
                       {/* <div onClick={()=>{    setExcel(null); console.log("empty")}}>X</div> */}
                        </div>
                        
                        </>:""}

                        <div className='col-1'>
                        <button type='button' onClick={()=>{handledatewid()}} className='finder' style={{color:"white",width:"100px"}} >View</button>
<br></br>
{currentuser.roles[0]=='ROLE_ADMIN'?<button type='button' onClick={()=>{handleSubmit()}} className='finder' style={{color:"white",width:"100px"}}>Upload</button>
:""}                        </div>
                    </div>
                 {/* {data.length} */}
                 { data2==true? 
                  <Newtablejs
      head={header}
       axiostabledata={'warehouseupload'}
       // Edit={"/pedit"}
       warehouse1={widinout}
    date1={inputdatw}
      Sumofkeys={sumofkeys}
    
    />
:""}
      {data.length!==0?<Mydatatable
      head={header}
       axiostabledata={'warehouseupload'}
       // Edit={"/pedit"}
    //    warehouse1={warehouse}
    // date1={date}
      Sumofkeys={sumofkeys}
    
    />:""}
                </form>
            </div>
        </div>
    );
};

export default Warehousestockreport;
