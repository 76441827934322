import axios from "axios";
import { MDBCard } from "mdbreact";
import React from "react";
import { useState } from "react";
import { Accordion, Button, Col, Form, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { BASE_URL } from "../services/Api";
import ProductService from "../services/ProductService";
import { changecostsalesorder, changesalesorderdiscount, changesalesordermeasurement, changesalesorderqty, changesalesordersqpbmeasurement, changesalesordertext, changeunitofMeasurementsalesorder, emptysalesorderdata, removefromcartfromsalesorder, salesorderaddtocart } from "../../redux/Action/SalesOrder";
import Select from "react-select";
import authHeader from "../services/auth-header";
import { useEffect } from "react";
import Swal from "sweetalert2";

export default function Create1() {

    const [warehousehdata, setwarehousehdata] = useState([]);
    const [warehousehvalue, setwarehousehvalue] = useState([]);
    const [warehouse, setwarehouse] = useState('');
    const [distributor, setdistributor] = useState('');
    const [retailer, setretailer] = useState('');
    const [customer, setcustomer] = useState('');
    const [quotationstatus, setquotationstatus] = useState('true');
    const [taxtype, settaxtype] = useState('withtax');
    const [remarks, setremarks] = useState('');
    const [productdata, setproductdata] = useState([]);
    const [sodate, setsodate] = useState('');
    const [buyerorder, setbuyerorder] = useState('');
    const [voucherno, setvoucherno] = useState('');
    const [dispatchedthrough, setdispatchedthrough] = useState('');
    const [destination, setdestination] = useState('');
    const [termsofdelivery, settermsofdelivery] = useState('');
    const [boxProductDiscount, setboxProductDiscount] = useState('');
    const [schemeboxProductDiscount, setschemeboxProductDiscount] = useState('');
    const [kgProductDiscount, setkgProductDiscount] = useState('');
    const [schemekgProductDiscount, setschemekgProductDiscount] = useState('');
    const [corporaetProductDiscount, setcorporaetProductDiscount] = useState('');
    const [deliveryAddress, setdeliveryAddress] = useState('');

    const [schemecorporateProductDiscount, setschemecorporateProductDiscount] = useState('');
    const [cookerProductDiscount, setcookerProductDiscount] = useState('');
    const [schemecookerProductDiscount, setschemecookerProductDiscount] = useState('');
    const [noshProductDiscount, setnoshProductDiscount] = useState('');
    const [schemenoshProductDisocunt, setschemenoshProductDisocunt] = useState('');
    const [branchdata, setbranchdata] = useState([]);
    const [branch, setbranch] = useState('');
    const [getdetailadd, setgetdetailadd] = useState([])

    const [shippingcharge, setShipping] = useState(0);
    const [shippingfortax, setshippingfortax] = useState('');
    const [round, setRound] = useState(0);
    const [bstateid, setbstateid] = useState('');
    const [dstateid, setdstateid] = useState('');
    const [paymentTerms, setpaymentTerms] = useState('');
    // const [voucherid, setVoucherid] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const applyFontSizeTransition = (elementId) => {
        const element = document.getElementById(elementId);
        element.style.transition = 'font-size 2s';
        element.style.fontSize = '30px';
        element.style.fontWeight = '600';
        // font-weight: 600;
        setTimeout(() => {

            document.getElementById("typer1").classList.add('promote');
            element.style.fontSize = '24px';
        }, 1000);
    };
    // "voucherid":"VoucherSeriesB",
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const salesOrderItems = useSelector((state) => state.salesorderdata);
    let gross_Amount = salesOrderItems.length && salesOrderItems.map(item => Math.round(item.total)).reduce((prev, next) => Math.round(prev - (-next)));
    let gst = salesOrderItems.length && salesOrderItems.map(item => (item.gstvalue)).reduce((prev, next) => (prev - (-next)));
    // console.log("mello",saslesOrderItems)
    let aa = [];
    salesOrderItems.map(user => {
        let igst_value = user.igst;
        let total_value = user.total;
        let total_amou = ((Number(shippingcharge) / Number(gross_Amount)) * Number(total_value));
        let add_amou = Number(total_amou) - (-Number(total_value));
        let final_amou = ((Number(add_amou) / 100) * Number(igst_value)).toFixed(2);
        aa.push(final_amou)

        // console.log("mello",((Number(add_amou)/100)*Number(igst_value)).reduce((prev, next) => Math.round(prev - (-next))) );
    })
    let total_per = aa.length && aa.map(user => user).reduce((prev, next) => (prev - (-next)));


    let grossAmount = Number(gross_Amount) + Number(shippingcharge);
    let grandTotal = Number(gross_Amount) + Number(shippingcharge) + Number(round) + Number(total_per);

    let igstTax = total_per == "" ? Number(gst) : Number(total_per);


    let cgstTax = total_per == "" ? (Number(gst) / 2) : (Number(total_per)) / 2;
    let sgstTax = total_per == "" ? (Number(gst) / 2) : (Number(total_per)) / 2;


    function roundData(e) {
        if (e) {

            let gross_Amount = salesOrderItems.length && salesOrderItems.map(item => Math.round(item.total)).reduce((prev, next) => Math.round(prev - (-next)));
            let gst = salesOrderItems.length && salesOrderItems.map(item => (item.gstvalue)).reduce((prev, next) => (prev - (-next)));
            // console.log("mello",salesOrderItems)
            let aa = [];
            salesOrderItems.map(user => {
                let igst_value = user.igst;
                let total_value = user.total;
                let total_amou = ((Number(shippingcharge) / Number(gross_Amount)) * Number(total_value));
                let add_amou = Number(total_amou) - (-Number(total_value));
                let final_amou = ((Number(add_amou) / 100) * Number(igst_value)).toFixed(2);
                aa.push(final_amou)

                // console.log("mello",((Number(add_amou)/100)*Number(igst_value)).reduce((prev, next) => Math.round(prev - (-next))) );
            })
            let total_per = aa.length && aa.map(user => user).reduce((prev, next) => (prev - (-next)));


            let grandTotal = Number(gross_Amount) + Number(shippingcharge) + Number(total_per);
            let grand_Total = Math.ceil(grandTotal);

            if (grand_Total > grandTotal) {
                let rou_nd = (Number(grand_Total) - Number(grandTotal)).toFixed(2);
                setRound(rou_nd);
            } else {
                setRound(0)
            }


        } else if (e == '') {
            setRound(0)
        }
    }
    /* Get Branch Data */

    const getbranchdata = () => {

        axios.get(BASE_URL + 'branch/getAll', {
            headers: authHeader()
        }).then(res => {
            // console.log("mello",res.data)
            setbranchdata((res.data).map(user => ({ value: user.id, label: user.branchname })));
        })
    };


    const getProductAll = () => {

        axios.get(BASE_URL + 'product/page/0/30/asc/id/%20', {
            headers: authHeader()
        }).then(res => {

            setproductdata((res.data.Index).map(use => ({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code + ' - ' + use.short_Name })));
        })
    };

    const customFilter = (e) => {
        let name = e;
        axios.get(BASE_URL + 'product/page/0/30/asc/id/' + name, {
            headers: authHeader()
        }).then(res => {
            setproductdata((res.data.data).map(use => ({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code + ' - ' + use.short_Name })))
        })
    };



    useEffect(() => {

        getbranchdata();
        getProductAll();
        dispatch(emptysalesorderdata());

    }, []);

    const onChangeCustomer = (e) => {
        // console.log("mello",e);
        let name = e.value;
        setdistributor({ "id": e.value });

        axios.get(BASE_URL + 'distributor/getById/' + name, {
            headers: authHeader()
        }).then(res => {

            setgetdetailadd(res.data.deliveryAddress);
            setdstateid(res.data.stateid);
            setboxProductDiscount(res.data.boxProductDiscount);
            setschemeboxProductDiscount(res.data.schemeboxProductDiscount);
            setkgProductDiscount(res.data.kgProductDiscount);
            setschemekgProductDiscount(res.data.schemekgProductDiscount);
            setcorporaetProductDiscount(res.data.corporaetProductDiscount);
            setschemecorporateProductDiscount(res.data.schemecorporateProductDiscount);
            setcookerProductDiscount(res.data.cookerProductDiscount);
            setschemecookerProductDiscount(res.data.schemecookerProductDiscount);
            setnoshProductDiscount(res.data.noshProductDiscount);
            setschemenoshProductDisocunt(res.data.schemenoshProductDisocunt);
        })
    };

    const onChangeCustomer1 = (e) => {
        // console.log("mello",e);
        let name = e.value;
        setretailer({ "id": e.value });

        axios.get(BASE_URL + 'retailer/getById/' + name, {
            headers: authHeader()
        }).then(res => {

            setgetdetailadd(res.data.deliveryAddress);
            setdstateid(res.data.stateid);
            setboxProductDiscount(res.data.boxProductDiscount);
            setschemeboxProductDiscount(res.data.schemeboxProductDiscount);
            setkgProductDiscount(res.data.kgProductDiscount);
            setschemekgProductDiscount(res.data.schemekgProductDiscount);
            setcorporaetProductDiscount(res.data.corporaetProductDiscount);
            setschemecorporateProductDiscount(res.data.schemecorporateProductDiscount);
            setcookerProductDiscount(res.data.cookerProductDiscount);
            setschemecookerProductDiscount(res.data.schemecookerProductDiscount);
            setnoshProductDiscount(res.data.noshProductDiscount);
            setschemenoshProductDisocunt(res.data.schemenoshProductDisocunt);
        })
    };

    const addData = (e) => {
        const filteredItems = salesOrderItems.filter(item => item.product.id === e.value);
        let name = e.value;
        if (branch == "" || distributor == "" && retailer == '') {
            Swal.fire({
                title: 'Please Branch or Distributor First Select',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonColor: 'red'
            })
        } else {
            if (filteredItems.length == "0") {
                dispatch(salesorderaddtocart(name, ({
                    "schemeboxProductDiscount": schemeboxProductDiscount,
                    "schemekgProductDiscount": schemekgProductDiscount,
                    "schemecorporateProductDiscount": schemecorporateProductDiscount,
                    "schemecookerProductDiscount": schemecookerProductDiscount,
                    "schemenoshProductDisocunt": schemenoshProductDisocunt,
                    "kgProductDiscount": kgProductDiscount,
                    "boxProductDiscount": boxProductDiscount,
                    "corporaetProductDiscount": corporaetProductDiscount,
                    "cookerProductDiscount": cookerProductDiscount,
                    "noshProductDiscount": noshProductDiscount
                })));
                setShipping("0");
            } else {
                Swal.fire({
                    title: 'This Product is Already Added',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showConfirmButton: false,
                    showCancelButton: true,
                    cancelButtonColor: 'red'
                })
            }
        }
    };

    let onChangeBranch = (e) => {
        let name = e.value;
        setbranch({ "id": e.value });
        setwarehousehvalue("");

        axios.get(BASE_URL + 'branch/' + name, {
            headers: authHeader()
        }).then(res => {
            // console.log("mello",res.data)
            setbstateid(res.data.states.id)
        })

        axios.get(BASE_URL + 'warehouse/getAll', {
            headers: authHeader()
        }).then(res => {
            let branch_data = [];
            (res.data).map(user => {
                // let branchid = user.branch.id;
                if (user.branch !== null) {
                    if (user.branch.id === e.value) {
                        branch_data.push({ value: user.id, label: user.name })
                    }
                }

            })

            setwarehousehdata(branch_data)

        })
    };
    const onChangewarehouse = (e) => {
        setwarehouse({ "id": e.value })
        setwarehousehvalue({ value: e.value, label: e.label })
    }
    const [voucherid, setvoucher] = useState([])
    const Onchangevoucher = (e) => {
        setvoucher(e.label)
    }


    /* get Shipping Charges */
    let settingship = (e) => {
        if (e == "") {

            setShipping("");
        } else {

            setShipping(e);
        }
    }

    /* get Round Value */
    const settinground = (e) => {

        if (e == "") {

            setRound("");
        } else {


            setRound(e);
        }

    };

    const SGSTcheck = () => {
        let aa = 0

        if (taxtype == "withtax") {

            if (bstateid === dstateid) {

                aa = sgstTax;

            } else {
                aa = 0;
                sgstTax = 0;
            }

        } else {
            aa = 0;
        }

        return aa;

    }

    const cgstcheck = () => {

        let bb = 0

        if (taxtype == "withtax") {

            if (bstateid === dstateid) {

                bb = cgstTax;

            } else {

                bb = 0;
                cgstTax = 0;
            }

        } else {

            bb = 0;

        }
        return bb;
    }

    const igstcheck = () => {
        let cc = 0;

        if (taxtype == "withtax") {
            if (bstateid === dstateid) {
                cc = 0;
                igstTax = 0;
            } else {
                cc = igstTax;

            }
        } else {
            cc = 0;
        }
        return cc;
    }

    const submitdata = () => {
        setIsLoading(true);
        let igst = igstTax;
        let cgst = cgstTax;
        let sgst = sgstTax;
        let grandtotal = grandTotal;
        let roundingofvalue = round;
        let grossamount = grossAmount;

        if (customer === 'distributor') {
            axios.post(BASE_URL + 'so/', {
                sodate, quotationstatus, voucherid, taxtype, remarks, buyerorder, voucherno, dispatchedthrough, destination, termsofdelivery,
                distributor, warehouse, salesOrderItems, branch, paymentTerms,
                igst, cgst, sgst, grandtotal, deliveryAddress, grossamount, shippingcharge, roundingofvalue,
            }, {
                headers: authHeader()
            }).then(res => {
                navigate('/SalesOrderTable/1')
            }).catch(error => {
                console.error('Error adding  :', error);
                if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `
                <div>
                  <p id="issueText">Why do I have this issue?</p>
                  <ul>
              
                  <li id="typer1">You might have an internet issue</li>
                  </ul>
                </div>
              `,
                        didOpen: () => {
                            applyFontSizeTransition('issueText');
                        }
                    });
                }
            }).finally(() => {
                setIsLoading(false);
            });
        } else if (customer === 'retailer') {
            axios.post(BASE_URL + 'so/', {
                sodate, quotationstatus, taxtype, remarks, buyerorder, voucherno, dispatchedthrough, destination, termsofdelivery,
                warehouse, salesOrderItems, branch, paymentTerms, retailer,
                igst, cgst, sgst, voucherid, grandtotal, deliveryAddress, grossamount, shippingcharge, roundingofvalue,
            }, {
                headers: authHeader()
            }).then(res => {
                navigate('/SalesOrderTable/1')
            }).catch(error => {
                console.error('Error adding  :', error);
                if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `
                <div>
                  <p id="issueText">Why do I have this issue?</p>
                  <ul>
              
                  <li id="typer1">You might have an internet issue</li>
                  </ul>
                </div>
              `,
                        didOpen: () => {
                            applyFontSizeTransition('issueText');
                        }
                    });
                }
            }).finally(() => {
                setIsLoading(false);
            });
        }
        dispatch(emptysalesorderdata());

    };

    const [disword, setdisword] = useState(encodeURIComponent(' '))
    const [dis, setdistributordata1] = useState([])
    const [distributordata12, setdistributordata12] = useState([])
    const [disword12, setdisword12] = useState(encodeURIComponent(' '))

    useEffect(() => {
        if (customer === "distributor") {
            axios.get(`http://43.225.54.138:8080/scm/api/distributor/page/0/10/asc/id/${disword.length ? disword : encodeURIComponent(' ')}`, { headers: authHeader() })
                .then(response => {
                    console.log(response.data)
                    if (response.data.data == undefined) {
                        setdistributordata1(response.data.Index.map(use => ({ value: use.id, label: use.trade_Name })));
                    } if (response.data.Index == undefined) {
                        setdistributordata1(response.data.data.map(use => ({ value: use.id, label: use.trade_Name })));

                    }

                })
        } else if (customer === 'retailer') {
            axios.get(`http://43.225.54.138:8080/scm/api/retailer/page/0/100/asc/id/${disword.length ? disword : encodeURIComponent(' ')}`, { headers: authHeader() })
                .then(response => {
                    //   console.log(response,"mello")
                    if (response.data.data == undefined) {
                        setdistributordata1(response.data.Index.map(use => ({ value: use.id, label: use.retailername })));
                    } if (response.data.Index == undefined) {
                        setdistributordata1(response.data.data.map(use => ({ value: use.id, label: use.retailername })));

                    }

                })
        }

        axios.get(BASE_URL + `voucher/page/0/100/desc/id/${disword12.length ? disword12 : encodeURIComponent(' ')}`, {
            headers: authHeader()
        }).then(response => {
            if (response.data.data == undefined) {
                setdistributordata12(response.data.Index.map(use => ({ value: use.voucherseries, label: use.voucherseries })));
            } if (response.data.Index == undefined) {
                setdistributordata12(response.data.data.map(use => ({ value: use.voucherseries, label: use.voucherseries })));

            }
            // setproductdata((res.data.Index).map(use => ({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code + ' - ' + use.short_Name })));
        })


    }, [customer, disword, disword12])

    return (
        <MDBCard className="company">
            <h1>Sales Order </h1>
            <Link className='p-2 w-200 card-header card-title bd-highlight' to={'/SalesOrderTable/1'}><Button>Back</Button></Link>

            <Form className="product1">
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Sales Order Data</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Choose Customer</Form.Label>
                                    <Form.Select onChange={(e) => setcustomer(e.target.value)}>
                                        <option>Select</option>
                                        <option value={'retailer'}>Retailer</option>
                                        <option value={'distributor'}>Distributor</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Branch Name</Form.Label>
                                    <Select options={branchdata} onChange={(e) => onChangeBranch(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">WareHouse Name</Form.Label>
                                    <Select value={warehousehvalue} options={warehousehdata} onChange={(e) => onChangewarehouse(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Date</Form.Label>
                                    <Form.Control type="date" onChange={(e) => setsodate(e.target.value)} />

                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Terms of Payment</Form.Label>
                                    <Form.Control type='text' name="paymentTerms" onChange={(e) => setpaymentTerms(e.target.value)} autoComplete="off" />
                                </Form.Group>
                                {customer === "distributor" &&
                                    <Form.Group as={Col} md="4" controlId="formBasicName">
                                        <Form.Label className="label">Customer Name</Form.Label>
                                        {/* <Select options={customerData} onChange={(e) => onChangeCustomer(e)} /> */}
                                        <Select options={dis} onInputChange={(inputValue) => setdisword(inputValue)} onChange={(options) => onChangeCustomer(options)} />

                                    </Form.Group>}
                                {customer === 'retailer' &&
                                    <Form.Group as={Col} md="4" controlId="formBasicName">
                                        <Form.Label className="label">Customer Name</Form.Label>
                                        {/* <Select options={customerData} onChange={(e) => onChangeCustomer(e)} /> */}
                                        <Select options={dis} onInputChange={(inputValue) => setdisword(inputValue)} onChange={(options) => onChangeCustomer1(options)} />

                                    </Form.Group>}
                                {/* <Form.Group as={Col} md="4" controlId="formBasicName">
                                <Form.Label className="label">Contact Name</Form.Label>
                                <Form.Select onChange={(e) => { onChnagecontactname(e.target.value) }}>
                                    <option>--Select--</option>
                                    {customerSubContactsAll.map(user =>
                                        <option key={user.id} value={user.id}>{user.contactname}</option>
                                    )
                                    }
                                </Form.Select>
                            </Form.Group> */}

                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                <Form.Label className="label">Vouchers Series</Form.Label>
                                {/* <Select options={customerData} onChange={(e) => onChangeCustomer(e)} /> */}
                                    <Select options={distributordata12} onInputChange={(inputValue) => setdisword12(inputValue)} onChange={(options) => Onchangevoucher(options)} />

                                </Form.Group>

                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Buyer Order</Form.Label>
                                    <Form.Control type="text" onChange={(e) => setbuyerorder(e.target.value)} />
                                </Form.Group>



                                {/* <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Voucher No</Form.Label>
                                    <Form.Control type="text" onChange={(e) => setvoucherno(e.target.value)} />
                                </Form.Group> */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Dispatched Through</Form.Label>
                                    <Form.Control type="text" onChange={(e) => setdispatchedthrough(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Destination</Form.Label>
                                    <Form.Control type="text" onChange={(e) => setdestination(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Terms Of Delivery</Form.Label>
                                    <Form.Control type="text" onChange={(e) => settermsofdelivery(e.target.value)} />
                                </Form.Group>
                                {/* <Form.Group as={Col} md="8" controlId="formBasicName">
                                    <Form.Label className="label">Owner</Form.Label>
                                    <Form.Control type="text" autoComplete="off" />
                                </Form.Group> */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Quotation Status</Form.Label>
                                    <Form.Select onChange={(e) => setquotationstatus(e.target.value)}>
                                        <option value='true'>Open</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Tax Type</Form.Label>
                                    <Form.Select value={taxtype} onChange={(e) => settaxtype(e.target.value)} disabled>
                                        <option>--Select--</option>
                                        <option value='withtax'>With Tax</option>
                                        <option value='withouttax'>Without Tax</option>
                                    </Form.Select>
                                </Form.Group>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    {/* <Accordion.Item eventKey="1">
                        <Accordion.Header>Billing Address</Accordion.Header>
                        <Accordion.Body> */}
                    {/* <Row className="mb-1">
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Customer Address</Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Customer Details</Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Address : -</Form.Label>
                                    <span>&nbsp;{customerAll.address}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Name : -</Form.Label>
                                    <span>&nbsp;{customerSubContactsdata == "" ? customerAll.cname : customerSubContactsdata.contactname}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">City : -</Form.Label>
                                    <span>&nbsp;{customerAll.city}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Phone No : -</Form.Label>
                                    <span>&nbsp;{customerSubContactsdata == "" ? customerAll.phonenumber : customerSubContactsdata.phoneno}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Country : -</Form.Label>
                                    <span>{"India"}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Email Id : -</Form.Label>
                                    <span> {customerSubContactsdata == "" ? customerAll.email : customerSubContactsdata.email}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">State : -</Form.Label>
                                    <span>{supplierstate}</span>
                                </Form.Group>
                            </Row> */}
                    {/* <b>Delivery address</b>
                           <select className="form-control" value={deliveryAddress}  onChange={(e)=>setdeliveryAddress(e.target.value)}>
                            <option>select one </option>
                            {
getdetailadd.map((item)=>{
    return<>
    <option value={item.id}>{item.delivery_address}</option>
    </>
})
                            }
                           </select> 



                        </Accordion.Body>
                    </Accordion.Item> */}
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>Details</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="12" controlId="formBasicName">
                                    <Form.Label className="label">Remarks</Form.Label>
                                    <Form.Control as="textarea" onChange={(e) => setremarks(e.target.value)} rows={3} name='address' placeholder='Enter Remarks' />
                                </Form.Group>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <br></br>
                <Row className="mb-1">
                    <Form.Group as={Col} md="12" controlId="formBasicName">
                        <Form.Label className="label">Product</Form.Label>
                        <Select options={productdata} onChange={(e) => addData(e)} onInputChange={(e) => customFilter(e)} />
                    </Form.Group>
                </Row>
                <br></br>
                <Table striped responsive hover size="sm">
                    <thead className='p-3 mb-2 bg-success text-white'>
                        <tr>
                            <th>PRODUCT&nbsp;NAME</th>
                            <th >UOM</th>
                            <th >QTY(PCS)</th>
                            <th >QTY(KG)</th>
                            <th>SQ</th>
                            <th>SQData</th>
                            <th >Rate</th>
                            <th >Trade&nbsp;Discount</th>
                            <th>GROSS AMOUNT (₹)</th>
                            <th >SCHEME DISCOUNT %</th>
                            <th >NET AMOUNT (₹)</th>
                            <th >GST Value</th>
                            <th >Amount</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody style={{ overflow: "scroll" }}>
                        {salesOrderItems.map(item =>
                            <tr key={item.product.id} >
                                <td>
                                    <Form.Group as={Col} md="12" >
                                        {/* <Form.Control type="text" value={(item.productName ? item.productName : item.product.productName)} size="sm" /> */}
                                        <textarea type="text" className="form-control textcontrol" value={(item.productName ? item.productName : item.product.productName)} size="sm" ></textarea>

                                    </Form.Group>
                                    <Form.Control as="textarea" onChange={(e) => { dispatch(changesalesordertext(e.target.value, item)) }} value={item.text} rows={2} className='form-control' />
                                </td>
                                <td><Form.Group as={Col} md="12">
                                    <Form.Select disabled={item.productType !== "kgproduct" ? true : false} onChange={(e) => { dispatch(changeunitofMeasurementsalesorder(e.target.value, item, item.unitofmeasurement)); }} size="sm">
                                        <option>{item.productType !== "kgproduct" ? 'PCS' : "Select"}</option>
                                        <option value="pcs">PCS</option>
                                        <option value="kg">KG</option>
                                    </Form.Select>
                                </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" size="sm" onChange={(e) => { dispatch(changesalesordermeasurement(e.target.value, item, taxtype, item.unitofmeasurement)); roundData(e.target.value) }} disabled={item.unitofmeasurement == "kg" ? true : false || item.unitofmeasurement == "" ? true : false} value={item.someasurement} />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" size="sm" onChange={(e) => { dispatch(changesalesorderqty(e.target.value, item, taxtype, item.unitofmeasurement)); roundData(e.target.value) }} disabled={item.unitofmeasurement == "pcs" || item.unitofmeasurement == "box" ? true : false || item.unitofmeasurement == "" ? true : false} value={item.unitofmeasurement == "box" ? "" : item.soqty} />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" size="sm" onChange={(e) => { dispatch(changesalesordersqpbmeasurement(e.target.value, item, taxtype, item.unitofmeasurement)); roundData(e.target.value) }} step={item.standardQtyPerBox} value={item.soquantity_placed} disabled={item.unitofmeasurement == "" ? true : false} />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" value={Number(item.soquantity_placed_kg)} disabled={item.unitofmeasurement == "box" ? true : false || item.unitofmeasurement == "" ? true : false} size="sm" requied />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" size="sm" onChange={(e) => { dispatch(changecostsalesorder(e.target.value, item, taxtype)); roundData(e.target.value) }} value={Number(item.dlp)} />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" min="0" size="sm" value={Number(item.tradediscount)} />
                                    </Form.Group>
                                </td>
                                <td><Form.Group as={Col} md="12">
                                    <Form.Control type="number" min="0" value={Number(item.grossamount)} size="sm" readOnly requied />
                                </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" onChange={(e) => { dispatch(changesalesorderdiscount(e.target.value, item, taxtype)); roundData(e.target.value) }} value={Number(item.schemeDiscount)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td><Form.Group as={Col} md="12">
                                    <Form.Control type="number" min="0" value={Number(item.netAmount)} size="sm" readOnly requied />
                                </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" value={Number(item.gstvalue)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" value={Number(item.total)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type='button' className='btn btn-danger' onClick={() => { dispatch(removefromcartfromsalesorder(item.product.id)); roundData('') }} value="X" size="sm" />
                                    </Form.Group>
                                </td>
                            </tr>
                        )}
                    </tbody>
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Packing&nbsp;&&nbsp;Forwarding</td>
                            <td><Form.Group as={Col} md="12">
                                <Form.Control type="number" value={shippingcharge} onChange={(e) => settingship(e.target.value)} style={{ height: 30,width:40 }} />
                            </Form.Group></td>
                            <td></td>

                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Net&nbsp;Amount</td>
                            <td><Form.Group as={Col} md="12">
                                <Form.Control type="number" value={grossAmount ? ((grossAmount).toFixed(2)) : "0"} style={{ height: 30 }} readOnly />
                            </Form.Group></td>
                            <td></td>
                        </tr>

                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>IGST</td>
                            <td><Form.Group as={Col} md="12">
                                <Form.Control type="number" value={igstcheck()} style={{ height: 30 }} requied />
                            </Form.Group></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>SGST</td>
                            <td><Form.Group as={Col} md="12">
                                <Form.Control type="number" value={SGSTcheck()} style={{ height: 30 }} requied />
                            </Form.Group></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>CGST</td>
                            <td><Form.Group as={Col} md="12">
                                <Form.Control type="number" value={cgstcheck()} style={{ height: 30 }} requied />
                            </Form.Group></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Round&nbsp;off&nbsp;Value</td>
                            <td> <Form.Group as={Col} md="12">
                                <Form.Control type="number" value={round} onChange={(e) => settinground(e.target.value)} style={{ height: 30 }} />
                            </Form.Group>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Grand&nbsp;Total</td>
                            <td> <Form.Group as={Col} md="12">
                                <Form.Control type="number" value={grandTotal ? ((grandTotal).toFixed(2)) : "0"} style={{ height: 30 }} readOnly />
                            </Form.Group>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </Table>
                <Button variant="primary" style={{ width: 'auto' }} onClick={submitdata} disabled={isLoading}>
                    {isLoading ? 'Loading...' : 'Submit'}
                </Button>
            </Form>
        </MDBCard>
    )
}