import axios from "axios";
import { MDBCard } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
// import { BASE_URL } from "../../services/Api";
// import authHeader from "../../services/auth-header";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import authHeader from "../../services/auth-header";
import { BASE_URL } from "../../services/Api";
 import { useDispatch, useSelector } from "react-redux";
import { setData12 } from "../../../redux/Action/Add1aPrintpagecartoon";

export default function Cartoonview(){
  const {id} = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const applyFontSizeTransition = (elementId) => {
    const element = document.getElementById(elementId);
    element.style.transition = 'font-size 2s';
    element.style.fontSize = '30px';
    element.style.fontWeight = '600';
    // font-weight: 600;
    setTimeout(() => {
       
      document.getElementById("typer1").classList.add('promote');
      element.style.fontSize = '24px';
    }, 1000);
  };
  const dispatch = useDispatch();
    const [disword, setdisword] = useState(encodeURIComponent(' '))
    const [dataomg, setDataomg] = useState([])
    const [data,setData] = useState([]);
    const [data1,setData1] = useState([]);
const[masterCartoonid,srtmastercartoonid]=useState([])
    const [masterCartoon,setmasterCartoon] = useState('');
    const data12 = useSelector(state => state.APrint1aprintcartonred.data);
console.log(data12)
    const navigate = useNavigate();
    let Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

   

        const onChangeBrand = (e) => {
            let name = e.value;
            setmasterCartoon(name)
        
    // alert(e.value)
        }
    const OnchangeProdcut=(e)=>{
        srtmastercartoonid(e.value)
        alert(e.value)
        // axios.post(`${BASE_URL}cartonbarcode/save`, {masterCartoon:{'id':e.value}}, { headers: authHeader() })
        // .then(response => {
        //   console.log(response.data);
        // //   setData1(response.data.map(item => ({
        // //     value: item.id,
        // //     label: item.product.productName
        // //   })));
        // })
        // .catch(error => {
        //   console.error('Error fetching data:', error);
        // });

    }

       

  

const findnav=()=>{
  navigate(`/CartonBarCode/Create`)


//     axios.post(`${BASE_URL}cartonbarcode/save`, {masterCartoon:{'id':masterCartoonid}}, { headers: authHeader() })
// .then(response => {
//   // setdata(response.data);
//   dispatch(setData12(response.data));

//   localStorage.setItem('responseData', JSON.stringify(response.data));
//   navigate(`/Printpageone/${masterCartoonid}`)
//   console.log(data)
//   // setdata(response.data)
// })
// .catch(error => {
//   console.error('Error fetching data:', error);
// });
    // axios.post(`${BASE_URL}cartonbarcode/save`, {masterCartoon:{'id':masterCartoonid}}, { headers: authHeader() })
    // .then(response => {
    //   console.log(response.data);
   
    // })
    // .catch(error => {
    //   console.error('Error fetching data:', error);
    // });
   
}

        
useEffect(() => {
  axios.get(BASE_URL + `cartonbarcode/getbyid/${id}`, {
    headers: authHeader()
  }).then(response => { 
   console.log(response.data)
  //  const [data,setData] = useState([]);
  //  const [data1,setData1] = useState([]);
  setData([response.data].map((item)=>item.brand.brandName))
setData1([response.data].map((item)=>item.product.productName))
console.log([response.data].map((item)=>item.brand.brandName))
 })

.catch(error => {
  console.error(error);
});
}, [  ])

const getItem = (id) =>{


  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  }).then((result) => {
    if (result.isConfirmed) {
      setIsLoading(true); 
      axios.delete(BASE_URL + `cartonbarcode/delete/${id}`, {
        headers: authHeader()
      }).then(res=>{


        navigate(`/CartonBarCode/Table/1`)
       
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        )



        // window.location.reload();
      }).catch(error => {
        console.error('Error adding  :', error);
        if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `
            <div>
              <p id="issueText">Why do I have this issue?</p>
              <ul>
          
              <li id="typer1">You might have an internet issue</li>
              </ul>
            </div>
          `,
          didOpen: () => {
            applyFontSizeTransition('issueText');
          }
        });
      }
      })
      .finally(() => {
        setIsLoading(false); 
      });
    
    }
  })


};  

console.log(data)
    return(
        <MDBCard className="company">
            <h1>Carton BarCode View</h1>
            <Link className='p-2 w-200 card-header card-title bd-highlight' variant="primary" style={{width:'auto'}} onClick={()=>getItem(id)} disabled={isLoading}>
        
        <Button className="btn-sm">  {isLoading ? 'Loading...' : 'Delete'}</Button>
      </Link>

            <Form className="product1">
                <Row className="mb-1">
                <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Brand Name</Form.Label>
                     <input type='text' disabled value={data} className="form-control"/>
                    </Form.Group>
                <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Master Carton Name</Form.Label>
                        <input type='text' disabled value={data1} className="form-control"/>
                    </Form.Group> 

                </Row>
                <Button variant="primary" type="button" onClick={findnav}>
                    Submit
                </Button>
                </Form>
        </MDBCard>
    )
}