import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { BASE_URL } from '../Component/services/Api';
import axios from 'axios';
import authHeader from '../Component/services/auth-header';
import { Alert } from 'react-bootstrap';
import Select from "react-select";
import Swal from 'sweetalert2';

export const MobileProductlunch = () => {
    const [show, setShow] = useState(false);
    const [file, setfile] = useState(null);
    const [data, setdata] = useState([]);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [loading, setLoading] = useState(false);


    const handleSaveChanges = () => {
      setLoading(true);

        const formData = new FormData();
        // formData.append('productName', productName);
        // formData.append('shortName', shortName);
        formData.append('productid', productid);
        formData.append('file', file);
    
        axios.post('http://43.225.54.138:8080/scm/api/launching/upload', formData,{ headers: authHeader() })
          .then(response => {
            console.log('Product added successfully:', response.data);
            // alert('Product added successfully:') 
            handleClose(); 
            window.location.reload();
          }).catch((error) => {
            console.error(error);
          })
          .finally(() => {
            setLoading(false); 
          });
      };
   useEffect(() => {
    handleSaveChanges12(); 
   }, [ ])

   const handleSaveChanges12 = () => {
    const formData = new FormData();
    // formData.append('productName', productName);
    // / axios.get(BASE_URL + "/launching/get/", {
    //   { headers: authHeader() }
    // }).then(res => {
    //   console.log("mello", res.data)
    //   // setdata(res.data)
    // })/ 
    formData.append('productid', productid);
    formData.append('file', file);
    setLoading(true);

    axios.get('http://43.225.54.138:8080/scm/api/launching/get', { headers: authHeader() })
      .then(response => {
        setdata(response.data)
        console.log(response.data);
        // window.location.reload();
      }).catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false); 
      });
  };
  

   const [disword, setdisword] = useState(encodeURIComponent(' '))
   const [dis,setdistributordata1]=useState([])
 const[productid,setproudct]=useState([])
   useEffect(() => {
   
     axios.get(`http://43.225.54.138:8080/scm/api/product/page/0/10/asc/id/${disword.length?disword:encodeURIComponent(' ')}`, { headers: authHeader() })
     .then(response => {
   console.log(response)
   if(response.data.data==undefined){
     setdistributordata1(response.data.Index.map(use => ({ value: use.id, label: use.product_Name})));
  }  if(response.data.Index==undefined){
   setdistributordata1(response.data.data.map(use => ({ value: use.id, label: use.product_Name})));
 
  }
     
  })
 
 
 
 }, [ disword])

// const handledelete=(id)=>{
  
//   axios.delete(`http://43.225.54.138:8080/scm//api/launching/delete${id}`,{ headers: authHeader() })
//   .then(response => {
//     console.log('Product added successfully:', response.data);
//     // alert('Product added successfully:') 
   
  
//   })
//   .catch(error => {
//     console.error('Error adding product:', error);
//   });
// }
const getItem = (id) => {

  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  }).then((result) => {
    if (result.isConfirmed) {

      axios.delete( BASE_URL + 'launching/delete/' + id, {
          headers:authHeader()
      }).then(res=>{
          window.location.reload();
          handleSaveChanges12(); 
      })
      
      Swal.fire(
        'Deleted!',
        'Your file has been deleted.',
        'success'
      )
    }
  })
};


const onChangeCustomer=(e)=>{
  // alert(e.value)
  setproudct(e.value)
}



  return (
    <div className='card'>
        <div className='card-header'>
    <h1>  New Product Launch </h1>
    <a className='btn btn-sm btn-primary' onClick={handleShow}> +  </a>

        </div>
{/* <a className='btn btn-sm btn-primary'>Create launch</a> */}


        <div className='card-body'>
<table className='table thrtable '>
    <tr>
        <th>Id</th>
        <th>Product Image</th>
        {/* <th>Product Image</th> */}
        <th>Action</th>
    </tr>

    {
  data.map((item) => (
    <tr key={item.id}>
      <td>{item.id}</td>
      <td>{item.name}</td>
      {/* <td>
        <img src={`http://43.225.54.138:8080/scm//${item.location}`}   />
      </td> */}
      <td onClick={()=>getItem(item.id)}>Delete</td>
    </tr>
  ))
}

</table>
        </div>
        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Product launch</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <label className='form-label'>Product Name</label>
            {/* <input type='text' className='form-control'/> */}
            <Select options={dis}  onInputChange={(inputValue) => setdisword(inputValue)} onChange={(options) => onChangeCustomer(options)}/>

            {/* <input type='text' className='form-control'/> */}
            <label className='form-label'>Product Image</label>
            <input type='file' onChange={e => setfile(e.target.files[0])} className='form-control'/>
        </Modal.Body>
        <Modal.Footer>
          <Button className='btn btn-sm btn-primary' onClick={handleClose}>
            Close
          </Button>
          <Button className='btn btn-sm btn-primary' onClick={()=>{handleClose();handleSaveChanges();}}>
<p>{loading?"loading":"Submit"}</p>
          </Button>
        </Modal.Footer>
      </Modal>
        </div>
  )
}
