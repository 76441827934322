import React from 'react'
import { useEffect } from 'react';
import { Button, Col, Row, Table } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom';
import UserService from '../../services/user.service';
import { useState } from 'react';
import ProductService from '../../services/ProductService';
import "./print.css";
import axios from 'axios';
import { BASE_URL } from '../../services/Api';
import authHeader from '../../services/auth-header';
const Print = () => {
  const { id } = useParams();
  // console.log(id)
  const [data, setData] = useState('');
  const [date, setDate] = useState('');
  const [primarydata, setprimarydata] = useState([]);
  const [name, setname] = useState('');
  const [product, setProduct] = useState([]);
  const [state, setState] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [deliveryAddress,setdeliveryaddress]=useState([])
const[tradeName,settradename]=useState([])
const[Zipcode,setZipcode]=useState([])
const[Contactnumber1,setnumbercontact1]=useState([])
const[Gstdis,setGstds]=useState([])
const[pandis,setpands]=useState([])
const[Contactnumber,setnumbercontact]=useState([])
const [deliveryAddressp,setdeliveryaddressp]=useState([])
const [deliveryAddresss,setdeliveryaddresss]=useState([])
const [distributorName1, setDistributorName1] = useState([])
const [distributorName2, setDistributorName2] = useState([])

  // const Print = () =>{     
  //   //console.log('print');  
  //   let printContents = document.getElementById('Print').innerHTML;
  //   let originalContents = document.body.innerHTML;
  //   document.body.innerHTML = printContents;
  //   window.print();
  //  document.body.innerHTML = originalContents; 
  // }


  useEffect(() => {
    // setdeliveryaddress

    UserService.getprimaryorderedit(id).then(async res => {
      setIsLoading(true);
      console.log(res.data.distributor.distributorName1);
      setData(res.data);
      setDate(res.data.dateCreated);
    
      setDistributorName1(res.data.distributor.distributorName1);
      setDistributorName2(res.data.distributor.distributorName1);
    
      const deldetails = res.data.distributor.deliveryAddress.filter(item => item.id === res.data.deliveryAddress);
      setdeliveryaddress(deldetails.map(item => item.delivery_address));
      setdeliveryaddressp(deldetails.map(item => item.pincode));
      setdeliveryaddresss(deldetails.map(item => item.state));
    
      settradename(res.data.distributor.tradeName);
      setname(res.data.distributor.billingAddress);
      setnumbercontact1(res.data.distributor.mobNo1);
      setnumbercontact(res.data.distributor.mobNo2);
      setGstds(res.data.distributor.gstNumber);
      setpands(res.data.distributor.panNumber);
      setZipcode(res.data.distributor.pinCode);
      setProduct(res.data.primaryOrderItems);
      setprimarydata(res.data.primaryOrderItems);
    
      // Fetch eancode data for each primaryItem
      const modifiedPrimaryItems = await Promise.all(res.data.primaryOrderItems.map(async (primaryItem) => {
        try {
          const res = await axios.get(BASE_URL + `product/getById/${primaryItem.productId}`, {
            headers: authHeader(),
          });
    
          const eancode = res.data.eanCode || '';
          const hsncode=res.data.hsnCode||'';
          console.log(eancode);
          console.log(hsncode)
          return {
            ...primaryItem,
            eancode: eancode,
            hsncode:hsncode
          };
        } catch (error) {
          console.error('Error fetching eancode data:', error);
          return {
            ...primaryItem,
            eancode: '',
            hsncode:' '
          };
        }
      }));
    
      setProduct(modifiedPrimaryItems);
      // setProduct(res.data.primaryOrderItems);
      setIsLoading1(true);
      setIsLoading(true);
            setprimarydata(modifiedPrimaryItems);
      // Fetch Zone data
      UserService.getZoneDataEdit1(res.data.distributor.zonesid).then(res1 => {
        setState(res1.data.state_zone);
      });
    });
    

//     UserService.getprimaryorderedit(id).then(res => {
//       setIsLoading(true);
//       console.log(res.data.distributor.distributorName1)
//       setData(res.data);
//       setDate(res.data.dateCreated);
//       // distributorName1(res.data.distributor.deliveryAddress.filter((item) => item.id === res.data.deliveryAddress))
//       setDistributorName1(res.data.distributor.distributorName1)
//       setDistributorName2(res.data.distributor.distributorName1)
//         console.log(res.data.distributor.deliveryAddress.filter((item) => item.id == res.data.deliveryAddress));
//         const deldetails=res.data.distributor.deliveryAddress.filter((item) => item.id == res.data.deliveryAddress)
//         setdeliveryaddress(deldetails.map((item)=>item.delivery_address))
//         setdeliveryaddressp(deldetails.map((item)=>item.pincode))
//         setdeliveryaddresss(deldetails.map((item)=>item.state))
// settradename(res.data.distributor.tradeName)    
//           setname(res.data.distributor.billingAddress);
//           setnumbercontact1(res.data.distributor.mobNo1) 
//           setnumbercontact(res.data.distributor.mobNo2)
//           setGstds(res.data.distributor.gstNumber)
//           setpands(res.data.distributor.panNumber)
//           setZipcode(res.data.distributor.pinCode)
//           setProduct(res.data.primaryOrderItems);

          
//       setprimarydata(res.data.primaryOrderItems);
//       UserService.getZoneDataEdit1(res.data.distributor.zonesid).then(res1 => {
//         setState(res1.data.state_zone)
//       })

//     })
    // ProductService.getProductAllos().then(res => {
    //   setIsLoading1(true);
    //   // setProduct(res.data);
    //   console.log(res.data)

    // });

    Print1();
  }, [isLoading, isLoading1])

  //  console.log("mello",isLoading)
  const Print1 = () => {
    if (isLoading == true && isLoading1 == true) {
      //console.log('print');  
      let printContents = document.getElementById('Print').innerHTML;
      let originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
    }
  }

  useEffect(()=>{
    Print1();
    console.log("priint")
  },[isLoading])
  const defaultValue_1 = new Date(date).toLocaleDateString('en-GB');
  const kgs = primarydata.length && primarydata.map(item => (Number(item.qty))).reduce((prev, next) => (prev - (-next)));
  const pcs = primarydata.length && primarydata.map(item => (Number(item.measurement))).reduce((prev, next) => (prev - (-next)));
  const stdkgs = primarydata.length && primarydata.map(item => (Number(item.quantity_placed_kg))).reduce((prev, next) => (prev - (-next)));
  const stdpcs = primarydata.length && primarydata.map(item => (Number(item.quantity_placed))).reduce((prev, next) => (prev - (-next)));
  const grossAmount = primarydata.length && primarydata.map(item => (Number(item.grossamount))).reduce((prev, next) => (prev - (-next)));
  const NetAmount = primarydata.length && primarydata.map(item => (Number(item.netAmount))).reduce((prev, next) => (prev - (-next)));
  const Gstvalue  = primarydata.length && primarydata.map(item => (Number(item.gstvalue))).reduce((prev, next) => (prev - (-next)));
  const TotalAmount  = primarydata.length && primarydata.map(item => (Number(item.total))).reduce((prev, next) => (prev - (-next)));
  // console.log("mello",primarydata)
  // let grossAmount = purchaseItems.length && purchaseItems.map(item => Math.round(Number(item.total))).reduce((prev, next) => Math.round(prev - (-next)));

  // const defaultValue_1 = datevalue_1.toLocaleDateString('en-CA');
  // window.location.reload();
  return (
    <>
     <div>
      <Link to={`/PrimaryOrderView/${id}/0`}><Button>Back</Button></Link>
    </div>
    <div id="Print">
   
      <div className='border border-secondary responsive w-100'>
        <div className='d-flex border-bottom border-secondary responsive'>
          <div className='col-8 firstcss'>
            <span className='d-flex justify-content-center responsive'style={{fontSize:'20px'}}>NEELAM APPLIANCES LIMITED</span>
          </div>
          <div className='col-4 ss1'>
            <img src={require('../logoneelam.png')} className='d-flex justify-content-center responsive' style={{ width: '41%', height: '50px', padding: '6px' }} />
          </div>
        </div>

        <div className='border-bottom border-secondary'>
          <p className='d-flex justify-content-center responsive' style={{ fontSize: '15px' }}>Gala No: 7/8 (Ground Floor) Gala No: 1/3/5/7/9 (First Floor), Neelam House, Fafadia Indl. Estate, Golani Naka, Waliv Village, Vasai East.</p>
        </div>
        <div className='border-bottom border-secondary'>
          <p className='d-flex justify-content-center responsive' style={{ fontSize: '15px' }}>Dist: Palghar - 401208. Ph: 8080561321 / 8080561322. Whatsapp No: 9321822426. Email : mail@neelamappliances.com</p>
        </div>
        <div className='border-bottom border-secondary'>
          <p className='d-flex justify-content-center responsive' style={{ fontSize: '15px' }}>PAN NO : AABCN9540N / GSTIN : 27AABCN9540N1ZY. CIN: U28996MH2004PLC147319 MSME No: MH17B0033446 / UDYAM No: UDYAM-MH-17-0011786</p>

        </div>
        <div>
          <p className='d-flex justify-content-center responsive' style={{ fontSize: '15px', fontWeight: 600 }}> Purchase Order</p>

        </div>

        <table striped responsive size="sm" className='border border-secondary responsive w-100'>
          <tr className='border border-secondary responsive'>
            <td className='d-flex justify-content-start' style={{fontSize:'15px',fontWeight:600,fontFamily: 'Montserrat'}}> Voucher&nbsp;Number&nbsp;: </td>
            <td className='border border-secondary responsive' style={{fontSize:'15px',fontWeight:600,fontFamily: 'Montserrat'}}>{data.workOrderId}</td>
            <td className='d-flex justify-content-start' style={{fontSize:'15px',fontWeight:600,fontFamily: 'Montserrat'}}>Voucher&nbsp;Date&nbsp;:</td>
            <td className='border border-secondary responsive' style={{fontSize:'15px',fontWeight:600,fontFamily: 'Montserrat'}}>{defaultValue_1}</td>
          </tr>
          <tr className='border border-secondary responsive'>
            <td className='d-flex justify-content-start' style={{fontSize:'15px',fontWeight:600,fontFamily: 'Montserrat'}}> Despatched&nbsp;Through&nbsp;: </td>
            <td className='border border-secondary responsive' style={{fontSize:'15px',fontWeight:600,fontFamily: 'Montserrat'}}>{name.transporterName}</td>
            <td className='d-flex justify-content-start' style={{fontSize:'15px',fontWeight:600,fontFamily: 'Montserrat'}}>Destination&nbsp;:</td>
            <td className='border border-secondary responsive' style={{fontSize:'15px',fontWeight:600,fontFamily: 'Montserrat'}}>{name.deliveryLocation}</td>
          </tr>
          <tr className='border border-secondary responsive'>
            <td className='d-flex justify-content-start' style={{fontSize:'15px',fontWeight:600,fontFamily: 'Montserrat'}}> Ship&nbsp;To&nbsp;: </td>
            <td className='border border-secondary responsive'></td>
            <td className='d-flex justify-content-start' style={{fontSize:'15px',fontWeight:600,fontFamily: 'Montserrat'}}>Bill&nbsp;To&nbsp;:</td>
            <td className='border border-secondary responsive'></td>
          </tr>
          <tr className='border border-secondary responsive'>
            <td className='d-flex justify-content-start' style={{fontSize:'15px',fontWeight:600,fontFamily: 'Montserrat'}}> Trade&nbsp;Name&nbsp;: </td>
            <td className='border border-secondary responsive' style={{fontSize:'15px',fontWeight:600,fontFamily: 'Montserrat'}}>{tradeName}</td>
            <td className='d-flex justify-content-start' style={{fontSize:'15px',fontWeight:600,fontFamily: 'Montserrat'}}>Trade&nbsp;Name&nbsp;:</td>
            <td className='border border-secondary responsive' style={{fontSize:'15px',fontWeight:600,fontFamily: 'Montserrat'}}>{tradeName}</td>
          </tr>
          <tr className='border border-secondary responsive'>
            <td className='d-flex justify-content-start' style={{fontSize:'15px',fontWeight:600,fontFamily: 'Montserrat'}}> Address&nbsp;: </td>
            <td className='border border-secondary responsive' style={{fontSize:'15px',fontWeight:600,fontFamily: 'Montserrat'}}>{deliveryAddress+""+deliveryAddressp}</td>
            <td className='d-flex justify-content-start' style={{fontSize:'15px',fontWeight:600,fontFamily: 'Montserrat'}}>Address&nbsp;:</td>
            <td className='border border-secondary responsive' style={{fontSize:'15px',fontWeight:600,fontFamily: 'Montserrat'}}>{name}</td>
          </tr>
          <tr className='border border-secondary responsive'>
            <td className='d-flex justify-content-start' style={{fontSize:'15px',fontWeight:600,fontFamily: 'Montserrat'}}> Zip&nbsp;Code&nbsp;: </td>
            <td className='border border-secondary responsive' style={{fontSize:'15px',fontWeight:600,fontFamily: 'Montserrat'}}>{deliveryAddressp} State&nbsp;:{deliveryAddresss}</td>
            <td className='d-flex justify-content-start' style={{fontSize:'15px',fontWeight:600,fontFamily: 'Montserrat'}}>Zip&nbsp;Code&nbsp;:</td>
            <td className='border border-secondary responsive' style={{fontSize:'15px',fontWeight:600,fontFamily: 'Montserrat'}}>{Zipcode} State&nbsp;: {state.map(user => user.id == name.stateid ? user.state_name : "")}</td>
          </tr>
          <tr className='border border-secondary responsive'>
            <td className='d-flex justify-content-start' style={{fontSize:'15px',fontWeight:600,fontFamily: 'Montserrat'}}> Voucher&nbsp;Number&nbsp;: </td>
            <td className='border border-secondary responsive' style={{fontSize:'15px',fontWeight:600,fontFamily: 'Montserrat'}}>{data.workOrderId}</td>
            <td className='d-flex justify-content-start' style={{fontSize:'15px',fontWeight:600,fontFamily: 'Montserrat'}}>Voucher&nbsp;Date&nbsp;:</td>
            <td className='border border-secondary responsive' style={{fontSize:'15px',fontWeight:600,fontFamily: 'Montserrat'}}>{defaultValue_1}</td>
          </tr>
          <tr className='border border-secondary responsive' style={{fontSize:'15px'}}>
            <td className='d-flex justify-content-start' style={{fontSize:'15px',fontWeight:600,fontFamily: 'Montserrat'}}> GST&nbsp;No.&nbsp;/&nbsp;Pan&nbsp;No.&nbsp;: </td>
            <td className='border border-secondary responsive' style={{fontSize:'15px',fontWeight:600,fontFamily: 'Montserrat'}}>{Gstdis+""+pandis}</td>
            <td className='d-flex justify-content-start' style={{fontSize:'15px',fontWeight:600,fontFamily: 'Montserrat'}}>GST&nbsp;No.&nbsp;/&nbsp;Pan&nbsp;No.&nbsp;:</td>
            <td className='border border-secondary responsive' style={{fontSize:'15px',fontWeight:600,fontFamily: 'Montserrat'}}>{Gstdis+""+pandis}</td>
          </tr>
          <tr className='border border-secondary responsive'>
            <td className='d-flex justify-content-start' style={{fontSize:'15px',fontWeight:600,fontFamily: 'Montserrat'}}> Contact&nbsp;Person&nbsp;: </td>
            <td className='border border-secondary responsive' style={{fontSize:'15px',fontWeight:600,fontFamily: 'Montserrat'}}>{distributorName1}</td>
            <td className='d-flex justify-content-start' style={{fontSize:'15px',fontWeight:600,fontFamily: 'Montserrat'}}>Contact&nbsp;Person&nbsp;:</td>
            <td className='border border-secondary responsive' style={{fontSize:'15px',fontWeight:600,fontFamily: 'Montserrat'}}>{distributorName2}</td>
          </tr>
          <tr className='border border-secondary responsive'>
            <td className='d-flex justify-content-start' style={{fontSize:'15px',fontWeight:600,fontFamily: 'Montserrat'}}> Contact&nbsp;Number&nbsp;: </td>
            <td className='border border-secondary responsive' style={{fontSize:'15px',fontWeight:600,fontFamily: 'Montserrat'}}>{Contactnumber+""+Contactnumber1}</td>
            <td className='d-flex justify-content-start' style={{fontSize:'15px',fontWeight:600,fontFamily: 'Montserrat'}}>Contact&nbsp;Number&nbsp;:</td>
            <td className='border border-secondary responsive' style={{fontSize:'15px',fontWeight:600,fontFamily: 'Montserrat'}}>{Contactnumber+""+Contactnumber1}</td>
          </tr>
        </table>
        <br></br>
        <table striped responsive size="sm" className='border border-secondary responsive w-100'>
          <tr className='border border-secondary responsive'>
            <td className='p-2 border border-secondary responsive' style={{ fontWeight: 600,width:'10px' }}>SR.<br></br>NO.</td>
            <td className='border border-secondary responsive' style={{ fontWeight: 600 }}>Description of Goods</td>
            <td className='border border-secondary responsive' style={{ fontWeight: 600 }}>EanCode</td>
            <td className='border border-secondary responsive'  style={{ fontWeight: 600 }}>HSN<br></br>Code</td>
            <td className='border border-secondary responsive' style={{ fontWeight: 600,width:'5%' }}>GST<br></br>Rate (%)</td>
            <td className='border border-secondary responsive' style={{ fontWeight: 600,width:'5%' }}>Order<br></br>QTY<br></br>(KGS)</td>
            <td className='border border-secondary responsive' style={{ fontWeight: 600,width:'5%' }}>Order<br></br>QTY<br></br>(PCS)</td>
            <td className='border border-secondary responsive' style={{ fontWeight: 600,width:'5%' }}>ORDER AS PER STD QTY (KGS)</td>
            <td className='border border-secondary responsive' style={{ fontWeight: 600,width:'5%' }}>ORDER AS PER STD QTY (PCS)</td>
            <td className='border border-secondary responsive' style={{ fontWeight: 600 }}>Rate&nbsp;<b style={{fontSize:'16px',fontWeight:700}}>₹</b></td>
            <td className='border border-secondary responsive' style={{ fontWeight: 600,width:'5%' }}>UOM</td>
            <td className='border border-secondary responsive' style={{ fontWeight: 600 }}>Gross<br></br>Amount</td>
            <td className='border border-secondary responsive' style={{ fontWeight: 600,width:'5%' }}>Trade<br></br>Dis. (%)</td>
            <td className='border border-secondary responsive' style={{ fontWeight: 600 }}>Net<br></br>Amount</td>
            <td className='border border-secondary responsive' style={{ fontWeight: 600,width:'5%' }}>Scheme<br></br>Dis. (%)</td>
            <td className='border border-secondary responsive' style={{ fontWeight: 600 }}>GST<br></br>Value</td>
            <td className='border border-secondary responsive' style={{ fontWeight: 600 }}>Total<br></br>Amount</td>
          </tr>
          <tbody>
            {primarydata.map((item, index) => (
              <tr key={item.id}>
                <td className='p-2 border border-secondary responsive'>{index + 1}</td>
                <td className='border border-secondary responsive brb' style={{fontSize:'15px'}}>{item.productName}</td>
                <td className='border border-secondary responsive brb'>{item.eancode}</td>
                <td className='border border-secondary responsive brb' >{item.hsncode}</td>
                <td className='border border-secondary responsive brb' >{item.igst}</td>
                <td className='border border-secondary responsive brb' >{item.qty}</td>
                <td className='border border-secondary responsive brb' >{item.measurement}</td>
                <td className='border border-secondary responsive brb' >{item.quantity_placed_kg}</td>
                <td className='border border-secondary responsive brb' >{item.quantity_placed}</td>
                <td className='border border-secondary responsive brb' >{item.dlp}</td>
                <td className='border border-secondary responsive brb' >{item.productType=="kgproduct"?"KGS":"PCS" }</td>
                <td className='border border-secondary responsive brb' >{item.grossamount}</td>
                <td className='border border-secondary responsive brb' >{item.discount}</td>
                <td className='border border-secondary responsive brb' >{item.netAmount}</td>
                <td className='border border-secondary responsive brb' >{item.discount1}</td>
                <td className='border border-secondary responsive brb' >{item.gstvalue}</td>
                <td className='border border-secondary responsive brb' >{item.total}</td>
              </tr>
            ))}
            <tr>
              <td  className='border border-secondary responsive'></td>
              <td className='border border-secondary responsive'><b>Total</b></td>
              <td  className='border border-secondary responsive'></td>
              <td  className='border border-secondary responsive'></td>
              <td  className='border border-secondary responsive'></td>
              <td  className='border border-secondary responsive'><b>{kgs.toFixed(2)}</b></td>
              <td  className='border border-secondary responsive'><b>{pcs}</b></td>
              <td  className='border border-secondary responsive'><b>{stdkgs.toFixed(2)}</b></td>
              <td  className='border border-secondary responsive'><b>{stdpcs}</b></td>
              <td  className='border border-secondary responsive'></td>
              <td  className='border border-secondary responsive'></td>
              <td  className='border border-secondary responsive'><b>{grossAmount.toFixed(2)}</b></td>
              <td  className='border border-secondary responsive'></td>
              <td  className='border border-secondary responsive'><b>{NetAmount.toFixed(2)}</b></td>
              <td  className='border border-secondary responsive'></td>
              <td  className='border border-secondary responsive'><b>{Gstvalue.toFixed(2)}</b></td>
              <td  className='border border-secondary responsive'><b>{TotalAmount.toFixed(2)}</b></td>
              
            </tr>
          </tbody>
        </table>
        <br></br>
        <table className='border border-secondary responsive w-100'>
  <tr className='border border-secondary responsive'>
  <td className='border border-secondary responsive' style={{fontSize:'12px',fontWeight: 600 }}>SR.NO.</td>
   <td className='border border-secondary responsive' style={{fontSize:'12px',fontWeight: 600 }}>Summary of Above </td>
   <td className='border border-secondary responsive' style={{fontSize:'12px',fontWeight: 600 }}>Order Quantity (KGS)</td>
   <td className='border border-secondary responsive' style={{fontSize:'12px',fontWeight: 600 }}>Order Quantity (PCS)</td>
   <td className='border border-secondary responsive' style={{fontSize:'12px',fontWeight: 600 }}>Total Amount</td>
  </tr>

  <tr className='border border-secondary responsive'>
  <td className='border border-secondary responsive'>1</td>
<td className='border border-secondary responsive' style={{ fontWeight: 600 }}>Box Product</td>
<td className='border border-secondary responsive'></td>
<td className='border border-secondary responsive'>{data.boxProductTotalQtyPcs}</td>
<td className='border border-secondary responsive'>{data.boxProductTotalprice}</td>

  </tr>
  
  <tr className='border border-secondary responsive'>
  <td className='border border-secondary responsive'>2</td>
<td className='border border-secondary responsive' style={{ fontWeight: 600 }}>kg Group</td>
<td className='border border-secondary responsive'>{data.kgProductTotalQtyKg}</td>
<td className='border border-secondary responsive'>{data.kgProductTotalQtyPcs}</td>
<td className='border border-secondary responsive'>{data.kgProductTotalprice}</td>

  </tr>
  
  <tr className='border border-secondary responsive'>
  <td className='border border-secondary responsive'>3</td>
<td className='border border-secondary responsive' style={{ fontWeight: 600 }}>Corporate Group :</td>
<td className='border border-secondary responsive'></td>
<td className='border border-secondary responsive'>{data.corporateProductTotalQtyPcs}</td>
<td className='border border-secondary responsive'>{data.corporateProductTotalprice}</td>

  </tr>
  
  <tr className='border border-secondary responsive'>
  <td className='border border-secondary responsive'>4</td>
<td className='border border-secondary responsive' style={{ fontWeight: 600 }}>PCB Group :  </td>
<td className='border border-secondary responsive'></td>
<td className='border border-secondary responsive'>{data.cookerProductTotalQtyPcs}</td>
<td className='border border-secondary responsive'>{data.cookerProductTotalprice}</td>

  </tr>

  
  <tr className='border border-secondary responsive'>
  <td className='border border-secondary responsive'>5</td>
<td className='border border-secondary responsive' style={{ fontWeight: 600 }}>Nosh Group :  </td>
<td className='border border-secondary responsive'></td>
<td className='border border-secondary responsive'>{data.noshProductTotalQtyPcs}</td>
<td className='border border-secondary responsive'>{data.noshProductTotalprice}</td>

  </tr>
  <tr className='border border-secondary responsive'>
  <td className='border border-secondary responsive'>. </td>
<td className='border border-secondary responsive'>.   </td>
<td className='border border-secondary responsive'>.</td>
<td className='border border-secondary responsive'>.</td>
<td className='border border-secondary responsive'>. </td>

  </tr>
  <tr className='border border-secondary responsive'>
  <td className='border border-secondary responsive'></td>
<td className='border border-secondary responsive' style={{ fontWeight: 600 }}>Net Total</td>
<td className='border border-secondary responsive'>{data.totalQtyKg}</td>
<td className='border border-secondary responsive'>{data.totalQtyPcs}</td>
<td className='border border-secondary responsive'>{data.grossTotal}</td>

  </tr>

</table>
      </div>
    </div>
    </>
  )
}

export default Print