import axios from 'axios'
import React, { useEffect, useState } from 'react'
import authHeader from '../services/auth-header'
import Select from 'react-select'

const Soreport = () => {

    const [disword, setdisword] = useState(encodeURIComponent(' '))
    const [dis,setdistributordata]=useState([])
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [retailerName, setRetailerName] = useState('');
const [data, setData] = useState([])
    const handleStartDateChange = (event) => {
      setStartDate(event.target.value);
    };
  
     const handleEndDateChange = (event) => {
      setEndDate(event.target.value);
    };
    const handleRetailerNameChange = (selectedOption) => {
      setRetailerName(selectedOption.value);
    };
  
    useEffect(() => {
    
      axios.get(`http://43.225.54.138:8080/scm/api/retailer/page/0/10/asc/id/${disword.length?disword:encodeURIComponent(' ')}`, { headers: authHeader() })
      .then(response => {
    // console.log(response.data,"mello")
    if(response.data.data==undefined){
      setdistributordata(response.data.Index.map(use => ({ value: use.id, label: use.retailername})));
   }  if(response.data.Index==undefined){
    setdistributordata(response.data.data.map(use => ({ value: use.id, label: use.retailername})));
  
   }
      
   })
  
  
  
  }, [ disword])
  

const handleclick=()=>{
console.log(startDate,endDate,retailerName)


axios
.get(
  `http://43.225.54.138:8080/scm/api/so/customer/pendingSoByRetailerId/${retailerName}/${startDate}/${endDate}`,
  { headers: authHeader() }
)
.then((response) => {
  // const endTime = new Date().getTime();
  // const elapsedTime = endTime - startTime;
  // console.log(`Request took ${elapsedTime} milliseconds`);
  setData(response.data)
  console.log(response.data)
  // setdata(response.data.data);
}) .catch((error) => {
  console.error(error);
})

}

  return (
    

<div className='card'>

    <div className='card-header flex'>
        <h1>Pending S.O By Retailer without Distributor</h1>
    </div>
         <div className='card-body'>
         <div className='row'>
  <div className='col'>
    <label className="form-label">Retailer Name: </label>
    {/* 
      <Select options={Reatilerg} onChange={(e) => { pinCodedata(e) }} /> 
      Uncomment and replace options={Reatilerg} with your desired options and pinCodedata function.
    */}
    <Select options={dis} onInputChange={(inputValue) => setdisword(inputValue)} onChange={handleRetailerNameChange}/>
  </div>
  <div className='col'>
        <label className="form-label">Start Date: </label>
        <input type='date' className='form-control' value={startDate} onChange={handleStartDateChange} />
      </div>
      <div className='col'>
        <label className="form-label">End Date: </label>
        <input type='date' className='form-control' value={endDate} onChange={handleEndDateChange} />
      </div>

      <div className='row'>
        <div className='col'>
          <button className='btn btn-primary' onClick={()=>handleclick()}>Submit</button>
        </div>
      </div>
</div>
<br></br>
<table className='table thrtable '>
  <tr>
    <th>ID</th>
    <th>Destination</th>
    <th>Terms of Delivery</th>
    <th>Buyer Order</th>
    <th>www</th>
    <th>Qty Status</th>
  </tr>

<tbody>
 { 

  data.map((item,Index)=>{
  return  <tr>
<td>{Index+1}</td>
<td>{item.destination}</td>
<td>{item.buyerorder}</td>
<td>{item.qtystatus}</td>
<td>{item.termsofdelivery}</td>
<td>{item.www}</td>
    </tr>
  })
}
</tbody>
  
</table>
                

     </div>
</div>

 
  )
}

export default Soreport