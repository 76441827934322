import { MDBCard } from "mdbreact";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../services/Api";
import axios from "axios";
import authHeader from "../services/auth-header";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";


export default function AttendanceCreate() {
    const [isLoading, setIsLoading] = useState(false);
    const applyFontSizeTransition = (elementId) => {
      const element = document.getElementById(elementId);
      element.style.transition = 'font-size 2s';
      element.style.fontSize = '30px';
      element.style.fontWeight = '600';
      // font-weight: 600;
      setTimeout(() => {
        //  
        document.getElementById("typer1").classList.add('promote');
        element.style.fontSize = '24px';
      }, 1000);
    };
    const [staff, setStaff] = useState('');
    const [status, setStatus] = useState('');
    const [status1, setStatus1] = useState(false);
    const [dayin, setdayin] = useState('');
    const [dayintime, setdayintime] = useState('');
    const [dayout, setdayout] = useState('');
    const [dayouttime, setdayouttime] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [data1, setdata1] = useState(10);
    const [data, setdata] = useState([]);
    const [data2, setdata2] = useState([]);
    const [search, setSearch] = useState('');
    const navigate = useNavigate();
    let Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
    useEffect(() => {
        axios.get(BASE_URL + 'staff/', { headers: authHeader() }).then(res => {
            setdata(res.data);
            setdata2(res.data);
        })
    }, []);

    const searchFilterFunction = (e) => {
        const input = e;
        if(input){
         
          const escapedInput = input.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escape symbols
          const regex = new RegExp(escapedInput.replace(/\s+/g, '\\s*'), 'i');
        const filterproducts =  data2.filter((product) => {
          // Remove symbols from product name before searching and make it space-insensitive
          return (
            regex.test(product.staffName) ||
            regex.test(product.staffName.replace(/\s+/g, '')) ||
            regex.test(product.staffName.replace(/[^a-zA-Z0-9]/g, ''))
    
    
          )
        });
        setdata(filterproducts);
        setSearch(input);
        
        }
        else{
            setdata(data2);
          setSearch(input);
        }
        
      };

    function getdayin(e){
        
        let a = new Date(e);

        // setdayin(e + " 00:00:00.000000")
        setdayin(a);
    }
    function getdayout(e){
        let a = new Date(e);
        // setdayout(e + " 00:00:00.000000")
        setdayout(a);
    }
    const handleChange = (e) => {
        setStatus1(true);
        const { name, checked } = e.target;
        if (name === "allSelect") {
            let tempUser = data.map((user) => {

                return { ...user, isChecked: checked,"sid":user.id,"dayin":dayin,"dayintime":dayintime,"dayout":dayout,"dayouttime":dayouttime,"staff":{"id":user.id},"status":status };

            });

            setdata(tempUser);
        } else {
            let tempUser = data.map((user) =>
                user.id == name ? { ...user, isChecked: checked,"sid":user.id,"dayin":dayin,"dayintime":dayintime,"dayout":dayout,"dayouttime":dayouttime,"staff":{"id":user.id},"status":status } : {...user,"clicked":'notChecked',}
            );
            setdata(tempUser);
        }
    };
    
    const recordsPerPage = data1;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;

    const tabledata = data.slice(firstIndex, lastIndex);
    const npage = Math.ceil(data.length / recordsPerPage);
    const numbers = [...Array(npage + 1).keys()].slice(1)

function prePage(){
if(currentPage !== 1){
    setCurrentPage(currentPage - 1);
}
}
function changeCPage(e){
setCurrentPage(e)
}
function nextPage(){
if(currentPage !== npage){
    setCurrentPage(currentPage + 1)
}
}
const submitdata = ( ) =>{
    let aa = [];
    const submit_data = data.map(user=>{
        if(user.isChecked === true){
            aa.push({"sid":user.sid,
            "dayin":user.dayin,
            "dayintime":user.dayintime,
            "dayout":user.dayout,
            "dayouttime":user.dayouttime,
            "staff":user.staff,
            "status":user.status})
        } 
    })
    
    if(dayin !== '' && dayintime !== '' && dayout !== '' && dayouttime !=='' && status !== '' && status1 === true){
        axios.post(BASE_URL + 'attendance/savebulk',aa,{
            headers:authHeader()
        }).then(res=>{
            navigate('/Attendance/Table/1');
            if(res.status==201||res.status==200){
                // alert("ee")
                Toast.fire({
                  icon: 'success',
                  title: 'Successfully added'
                })
              }
        })
    }
}
    return (
        <MDBCard className="company">
            <h1>Attendance Create</h1>
            <Form className="product1">
                <Row className="mb-1">
                    <Form.Group as={Col} md="4" controlId="formBasicName">
                        <Form.Label className="label">Day In</Form.Label>
                        <Form.Control type='date' name="dayin" onChange={(e) => getdayin(e.target.value)} autoComplete="off" />
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="formBasicName">
                        <Form.Label className="label">Day In Time</Form.Label>
                        <Form.Control type='time' name="dayintime" onChange={(e) => setdayintime(e.target.value)} autoComplete="off" />
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="formBasicName">
                        <Form.Label className="label">Day Out</Form.Label>
                        <Form.Control type='date' name="dayout" onChange={(e) => getdayout(e.target.value)} autoComplete="off" />
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="formBasicName">
                        <Form.Label className="label">Day Out Time</Form.Label>
                        <Form.Control type='time' name="dayouttime" onChange={(e) => setdayouttime(e.target.value)} autoComplete="off" />
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="formBasicName">
                        <Form.Label className="label">Status</Form.Label>
                        <Form.Select onChange={(e) => setStatus(e.target.value)}>
                            <option>Select</option>
                            <option value={'P'}>Present</option>
                            <option value={'HD'}>Half-Day</option>
                            <option value={'H'}>Holiday</option>
                            <option value={'HP'}>Holiday-Present</option>
                            <option value={'WO'}>Week-Off</option>
                            <option value={'WOP'}>Week-Off-Present</option>
                            <option value={'L'}>Leave</option>
                        </Form.Select>
                    </Form.Group>
                </Row>
                {/* <Button onClick={submitdata}>Submit</Button> */}

                <Button variant="primary" style={{width:'auto'}} onClick={submitdata} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Submit'}
        </Button>
        
            </Form><br></br>
            <div className="forentriesandaddbutton">
                <div className="bottole">
                    <p>Show entries</p>
                    <select
                        className="newselect"
                        onChange={(e) => { setdata1(e.target.value) }}
                    >
                        <option value={'10'}>10</option>
                        <option value={'20'}>20</option>
                        <option value={'30'}>30</option>
                        <option value={'40'}>40</option>
                        <option value={'50'}>50</option>
                        <option value={'200'}>200</option>
                    </select>
                </div>
                <div>
                <Row className="mb-1">
                <Form.Group as={Col} md="12"  controlId="formBasicName">
            <Form.Control type="search" placeholder="Search Here" autoComplete="off" value={search} onChange={(e)=>searchFilterFunction(e.target.value)}/>

          </Form.Group>
          </Row>
                </div>
            </div>
            <Table>
                <thead>
                    <tr>
                        
                        <th> <input
                            type="checkbox"
                            className="form-check-input"
                            name="allSelect"
                            checked={!data.some((user) => user?.isChecked !== true)}
                            onChange={handleChange}
                        /></th>
                        <th>Staff Name</th>
                    </tr>
                </thead>
                <tbody>
                    {tabledata.map((user, index) =>

                        <tr key={user.id}>
                            
                            <td> <input
                                type="checkbox"
                                className="form-check-input"
                                name={user.id}
                                checked={user?.isChecked || false}
                                onChange={handleChange}
                            /></td>
                            <td>{user.staffName}</td>
                        </tr>
                    )}
                </tbody>
            </Table>
            <div className="flex">
                <div className="showentri">
                    {data1 == 10 ? (
                        <p>
                            Showing {firstIndex + 1} to {lastIndex} of{" "}
                            entries
                        </p>
                    ) : (
                        ""
                    )}
                    {data1 == 20 ? (
                        <p>
                            Showing {firstIndex + 1} to {lastIndex} of{" "}
                            entries
                        </p>
                    ) : (
                        ""
                    )}
                    {data1 == 30 ? (
                        <p>
                            Showing {firstIndex + 1} to {lastIndex} of{" "}
                            entries
                        </p>
                    ) : (
                        ""
                    )}
                    {data1 == 40 ? (
                        <p>
                            Showing {firstIndex + 1} to {lastIndex} of{" "}
                            entries
                        </p>
                    ) : (
                        ""
                    )}
                    {data1 == 50 ? (
                        <p>
                            Showing {firstIndex + 1} to {lastIndex} of{" "}
                            entries
                        </p>
                    ) : (
                        ""
                    )}
                    {data1 == 200 ? (
                        <p>
                            Showing {firstIndex + 1} to {lastIndex} of{" "}
                            entries
                        </p>
                    ) : (
                        ""
                    )}
                </div>
                <div className="pagination">
                    <ul className="pagination">
                        <li 
                            onClick={prePage}
                        >
                            {"Previous"}
                        </li>
                        {numbers.map((user,i) => (
                            <li className={`page-item ${currentPage === user?'newway':''}`} onClick={()=>changeCPage(user)} key={i}>{user}</li>
                        ))}
                        <li 
                            onClick={nextPage}
                        >
                            {"Next"}
                        </li>
                    </ul>
                </div>
            </div>
        </MDBCard >
    )
}