import React, { useEffect, useState } from 'react'
import StaffService from '../../services/StaffService';
import DataTable from 'react-data-table-component';
import { Link, useNavigate } from 'react-router-dom';
import { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai';
import { CSVLink } from 'react-csv';
import axios from 'axios';
import Swal from 'sweetalert2';
import { MDBCard, MDBCardBody } from 'mdb-react-ui-kit';
import { MDBDataTable } from 'mdbreact';
import { FaUserEdit } from 'react-icons/fa';
import { BsFillEyeFill } from 'react-icons/bs';
import AuthService from '../../services/auth.service';
export default function STable() {

  const [posts, setPosts] = useState([]);
  const[datacsv,setcsv]=useState([])
  const getItem = (id) => {
    // StaffService.getDeleteStaff(id).then(res => {

    //   window.location.reload();

    // })

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
    
        StaffService.getDeleteStaff(id).then(res => {

            window.location.reload();
      
          })
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        )
      }
    })
  };

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    StaffService.getStaff().then((response) => {
      const aaaa = response.data;
      var x = 1;
      let rowsData = []
      for (var index = 0; index < aaaa.length; index++) {

        let rowItem = {}
        rowItem["id"] = aaaa[index].id
        rowItem["index"] = x
        rowItem["staffName"] = aaaa[index].staffName
        rowItem["mobileNumber"] = aaaa[index].mobileNumber
        rowItem["area"] = aaaa[index].area
        rowItem["email"] = aaaa[index].email
        rowItem["role"] = aaaa[index].roles[0].name
        rowItem["update"] = <div >
          <Link to={`/SEdit/${aaaa[index].id}`} className="pubutton" id='pb1'>
            <FaUserEdit />
          </Link> {user.roles == "ROLE_ADMIN"?<Link to={`/maptable/${aaaa[index].id}`} className="pubutton" >
            <BsFillEyeFill />
          </Link>:""}
              {user.roles == "ROLE_ADMIN"?<Link onClick={() => getItem(rowItem["id"])} className="pubutton" id='pb'>
            <AiOutlineDelete />
          </Link>:""}
          
        </div>


        rowsData.push(rowItem);
        x+=1
      }

      setPosts(rowsData);
      setcsv(response.data.map(data => {
        const { password ,stateZoneId, aseId,asmId
          
          ,rsmId
          
          ,nsmId
          ,roleId
          ,zoneId ,roles, ...rest } = data;
        const updatedData = {
          ...rest,
          roles: roles.map(role => role.name)
        };
      
      
        








    
      
        return updatedData;
      }))
      console.log(     (response.data.map(data => {
        const { password ,stateZoneId, aseId,asmId
          
         
          ,zoneId ,roles, ...rest } = data;
        const updatedData = {
          ...rest,
          roles: roles.map(role => role.name)
        };
      
      
        








    
      
        return updatedData;
      })))
    })

  }, []);

  const data = () => {
    return {
      columns: [
        {
          label: 'ID',
          field: 'index',
          width: 100,
        },
        {
          label: 'Staff Name',
          field: 'staffName',
          width: 100,
        },

        {
          label: 'Mobile Number',
          field: 'mobileNumber',
          width: 150,
        },
        {
          label: ' Area',
          field: 'area',
          width: 270,
        },
        {
          label: 'Email',
          field: 'email',
          width: 200,
        },
        {
          label: 'Role',
          field: 'role',
          sort: 'asc',
          width: 100,
        },

        {
          label: 'Action',
          field: 'update',
          sort: 'disabled',
          width: 100,
        },


      ],
      rows: posts
    };
  };
  return (
    <div data-aos="fade">
      <MDBCard>
        <h1>STAFF LIST</h1>
        <div className='d-flex bd-highlight'>
          <div className='p-2 w-100 bd-highlight'> <Link to='/screate'> <button class="btn btn-sm btn-secondary">Add Staff</button></Link></div>
          <div className='p-2 flex-shrink-1 bd-highlight'><CSVLink data={datacsv} class="btn btn-sm btn-primary">Export</CSVLink></div>
        </div>
        <MDBCardBody >
          <MDBDataTable
            hover
            entriesOptions={[5, 10, 15, 20, 25]}
            entries={10}
            pagesAmount={4}
            data={data()}
            materialSearch={true}
          />
        </MDBCardBody>
      </MDBCard>
    </div>

  )
}
