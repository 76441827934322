import axios from "axios";
import { MDBCard } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
// import { BASE_URL } from "../../services/Api";
// import authHeader from "../../services/auth-header";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import authHeader from "../../services/auth-header";
import { BASE_URL } from "../../services/Api"; 
 import { useDispatch, useSelector } from "react-redux";
import { setData12 } from "../../../redux/Action/Add1aPrintpagecartoon";

export default function Create(){
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
    const applyFontSizeTransition = (elementId) => {
      const element = document.getElementById(elementId);
      element.style.transition = 'font-size 2s';
      element.style.fontSize = '30px';
      element.style.fontWeight = '600';
      // font-weight: 600;
      setTimeout(() => {
         
        document.getElementById("typer1").classList.add('promote');
        element.style.fontSize = '24px';
      }, 1000);
    };
    const [disword, setdisword] = useState(encodeURIComponent(' '))
    const [dataomg, setDataomg] = useState([])
    const [data,setData] = useState([]);
    const [data1,setData1] = useState([]);
const[masterCartoonid,srtmastercartoonid]=useState([])
    const [masterCartoon,setmasterCartoon] = useState('');
    const [loosepacking,setloosepacking]=useState('');
    const data12 = useSelector(state => state.APrint1aprintcartonred.data);
console.log(data12)
    const navigate = useNavigate();
    let Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

        /* Get Master Carton data */
        const getbranddata = () => {
            axios.get(BASE_URL+`brand/page/0/10/asc/id/${disword.length?disword:encodeURIComponent(' ')}`, { headers: authHeader() })
            .then(response => {
          if(response.data.data==undefined){
            setData(response.data.Index.map(use => ({ value: use.id, label: use.brand_Name})));
         }  if(response.data.Index==undefined){
          setData(response.data.data.map(use => ({ value: use.id, label: use.brand_Name})));
        
         }
              
        })
        }

        useEffect(() => {
            getbranddata();
        }, [disword]);
// useEffect(() => {
//   if(masterCartoon!==0){
//     postdata(masterCartoon)
//   }
// }, [masterCartoon])

        const onChangeBrand = (e) => {
            let name = e.value;
            setmasterCartoon(name)
            postdata(name)
    // alert(e.value)
        }
        const [data11, setData11] = useState([])
    const OnchangeProdcut=(e)=>{
        srtmastercartoonid(e.value)

        axios.get(BASE_URL + `mastercartoon/getbyid/${e.value}`, {
          headers: authHeader()
        })
          .then(response => { 
            setMinWeight(response.data.minweight)
            setMaxWeight(response.data.maxweight)
            setData11(response.data)
              // console.log(response.data)
           })
          .catch(error => {
            console.error(error);
          });
    }

        const postdata = (iid) => {
            axios.get(BASE_URL+`mastercartoon/bybrand/${iid}`, { headers: authHeader() })
            .then(response => {
        console.log(response.data)
        setData1(response.data.map(use => ({ value: use.id, label: use.product.productName})));
        })
        }

        const [netWeight, setNetWeight] = useState('');
        const [minWeight, setMinWeight] = useState('');
        const [maxWeight, setMaxWeight] = useState('');
        const [error, setError] = useState('');
      
        const handleNetWeightChange = (e) => {
          const value = e.target.value;
          setNetWeight(value);
      
          // Convert to number for comparison
          const netWeightNum = parseFloat(value);
          const minWeightNum = parseFloat(minWeight);
          const maxWeightNum = parseFloat(maxWeight);
      
          // Reset error message
          let errorMsg = '';
      
          if (isNaN(netWeightNum)) {
            errorMsg = 'Please enter a valid number';
          } else if (minWeight && netWeightNum < minWeightNum) {
            errorMsg = `Net Weight cannot be less than ${minWeight}`;
          } else if (maxWeight && netWeightNum > maxWeightNum) {
            errorMsg = `Net Weight cannot be greater than ${maxWeight}`;
          }
      
          setError(errorMsg);
        };
      
        const handleMinWeightChange = (e) => {
          setMinWeight(e.target.value);
        };
      
        const handleMaxWeightChange = (e) => {
          setMaxWeight(e.target.value);
        };      

const findnav=()=>{
  
  setIsLoading(true); 
  const netweight=netWeight;

  if(loosepacking ==""){
    axios.post(`${BASE_URL}cartonbarcode/save`, {masterCartoon:{'id':masterCartoonid},netweight:netWeight}, { headers: authHeader() })
.then(response => {
  // setdata(response.data);
  dispatch(setData12(response.data));

  localStorage.setItem('responseData', JSON.stringify(response.data));
  navigate(`/Printpageone/${masterCartoonid}`)
  console.log(data)
  // setdata(response.data)
}).catch(error=>{
              
  if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong!",
      footer: `
        <div>
          <p id="issueText">Why do I have this issue?</p>
          <ul>
      
          <li id="typer1">You might have an internet issue</li>
          </ul>
        </div>
      `,
      didOpen: () => {
        applyFontSizeTransition('issueText');
      }
    });
  }else{
    Swal.fire({
        title: 'This Product is already Created',
        text: "You won't be able to Add!",
        icon: 'warning',
      })

  }
})
.finally(() => {
setIsLoading(false); 
});
  } else if(loosepacking !== ""){
    
    axios.post(`${BASE_URL}cartonbarcode/save`, {masterCartoon:{'id':masterCartoonid},loosepacking,netweight:netWeight}, { headers: authHeader() })
.then(response => {
  // setdata(response.data);
  dispatch(setData12(response.data));

  localStorage.setItem('responseData', JSON.stringify(response.data));
  navigate(`/Printpageone/${masterCartoonid}`)
  console.log("pl")
  // setdata(response.data)
}).catch(error=>{
              
  if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong!",
      footer: `
        <div>
          <p id="issueText">Why do I have this issue?</p>
          <ul>
      
          <li id="typer1">You might have an internet issue</li>
          </ul>
        </div>
      `,
      didOpen: () => {
        applyFontSizeTransition('issueText');
      }
    });
  }else{
    Swal.fire({
        title: 'This Product is already Created',
        text: "You won't be able to Add!",
        icon: 'warning',
      })

  }
})
.finally(() => {
setIsLoading(false); 
});
  }


}

        
useEffect(() => {
  console.log(localStorage.getItem("responseData"))

}, [ dataomg])



console.log(data1)
    return(
        <MDBCard className="company">
            <h1>Carton BarCode Create</h1>
            <Form className="product1">
                <Row className="mb-1">
                <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Brand Name</Form.Label>
                        <Select options={data} onChange={(e) => onChangeBrand(e)} onInputChange={(inputValue) => setdisword(inputValue)} />
                    </Form.Group>
                    {/* <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Loose Packing</Form.Label>
                        <Form.Control type="text" placeholder="Enter Loose Packing" onChange={(e)=>setloosepacking(e.target.value)}/>
                    </Form.Group> */}
                  { data1.length!==0? <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Master Carton Name</Form.Label>
                        <Select options={data1} onChange={(e) => OnchangeProdcut(e)} />
                    </Form.Group>:''}

                </Row>
                <Form.Group as={Col} md="6" controlId="formBasicNetWeight">
        <Form.Label className="label">Net Weight</Form.Label>
       <div className="row">
        <div className="col">
        <input 
          type="number" 
          className="form-control" 
          value={netWeight} 
          onChange={handleNetWeightChange} 
        />
        {error && <div className="text-danger">{error}</div>}
        </div>
        <div className="col">
          <label className=" "style={{    fontWeight: "600"}}>Std Qty</label>
          <input type="number" className="form-control" value={data11.stdqty}/>
        </div>
       </div>
      </Form.Group>
      
      <Form.Group as={Col} md="6" controlId="formBasicWeightRange">
        <div className="row">
          <div className="col">
            <Form.Label className="label">Min</Form.Label>
            <input 
              type="number" 
              className="form-control" 
              value={minWeight} 
              onChange={handleMinWeightChange}
            />
          </div>
          <div className="col">
            <Form.Label className="label">Max</Form.Label>
            <input 
              type="number" 
              className="form-control" 
              value={maxWeight} 
              onChange={handleMaxWeightChange}
            />
          </div>
        </div>
      </Form.Group>
                     
                {/* <Button variant="primary" type="button" onClick={findnav}>
                    Print
                </Button> */}

                <Button variant="primary" style={{width:'auto'}} onClick={()=>findnav()} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Print'}
        </Button>
                </Form>
        </MDBCard>
    )
}