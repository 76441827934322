import axios from "axios";
import { MDBCard } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { BASE_URL } from "../services/Api";
import authHeader from "../services/auth-header";

export default function Create() {

    const[warehouseAll,setwarehouseAll]=useState([]);

    const getwarehouseAll = () =>{
        axios.get(BASE_URL + 'warehouse/getAll',{
            headers:authHeader()
        }).then(res=>{
            console.log('mello',res.data)
        })
    };

    useEffect(()=>{
        getwarehouseAll()
    },[])

    return (
        <MDBCard className="company">
            <Form>
                <Row className="mb-1">
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Product Name</Form.Label>
                        <Select name='product' />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Warehouse Name</Form.Label>
                        <Select name='warehouse' />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Date</Form.Label>
                        <Form.Control type="date" name="date" autoComplete="off"  />
                        </Form.Group>
                </Row>
                <br></br>
                <Form.Group as={Col} md="12" controlId="formBasicName">
                        <Form.Label className="cname2">Product Select</Form.Label>
                        <Select name='product' />
                    </Form.Group>
            </Form>

        </MDBCard>
    )
}