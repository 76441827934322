import React from "react";
import { useEffect } from "react";
import { Row, Form, Col, Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import ProductService from "../../services/ProductService";
import { useState } from "react";
import BrandService from "../../services/Brand";
import { MDBCard } from "mdbreact";

export default function PView11(){

  /* Product Id */
   const { id } = useParams();
   
   const[productName,setproductName]=useState('');
   const[shortName,setshortName]=useState('');
   const[eanCode,seteanCode]=useState('');
   const[standardQtyPerBox,setstandardQtyPerBox]=useState('');
   const[group,setGroup]=useState('');
   const[category,setcategory]=useState('');
   const[uomPrimary,setuomPrimary]=useState('');
   const[uomSecondary,setuomSecondary]=useState('');
   const[mrp,setmrp]=useState('');
   const[dlp,setdlp]=useState('');
   const[capacity,setcapacity]=useState('');
   const[diameter,setdiameter]=useState('');
   const[hsnCode,sethsnCode]=useState('');
   const[igst,setigst]=useState('');
   const[cgst,setcgst]=useState('');
   const[sgst,setsgst]=useState('');
   const[brandName,setBrandName]=useState('');
   const[productType,setProductType]=useState('');
   const[imagedisplay,setdiplay]=useState()
   
    useEffect(()=>{
      /* product Data */
       ProductService.getProductEdit(id).then(res=>{
        console.log("mello",res.data)
        setproductName(res.data.productName);
        setshortName(res.data.shortName);
        seteanCode(res.data.eanCode);
        setstandardQtyPerBox(res.data.standardQtyPerBox);
        setGroup(res.data.productGroup);
        setcategory(res.data.category);
        setuomPrimary(res.data.uomPrimary);
        setuomSecondary(res.data.uomSecondary);
        setmrp(res.data.mrp);
        setdlp(res.data.dlp);
        setcapacity(res.data.capacity);
        setdiameter(res.data.diameter);
        sethsnCode(res.data.hsnCode);
        setigst(res.data.igst);
        setcgst(res.data.cgst);
        setsgst(res.data.sgst);
        setProductType(res.data.productType);
        setdiplay(res.data.image.location);
        /* Brand */

        BrandService.getBrandEdit(res.data.brand).then(respo=>{
          setBrandName(respo.data.brandName);
          
        })
      
       })
    },[])

    return(
     <>
     <div className="card">
     <div className="card-header card-title w-100">VIEW PRODUCTS</div>
<div className="card-body">
  <div className="row">
    <div className="col">
   <div className="row">
    <div className="col">
    <span className="form-label">Product Name :<br></br>{productName}</span>
    </div>
    <div className="col">
    <span className="form-label">Short Name :<br></br>{shortName}</span>
    </div>
    
   </div><br></br>
   <div className="row">
    <div className="col">
    <span className="form-label">Ean Code No :<br></br>{eanCode}</span>
    </div>
    <div className="col">
    <span className="form-label">Standard Qty Per Box :<br></br>{standardQtyPerBox}</span>
    </div>
    
   </div><br></br>
   <div className="row">
    <div className="col">
    <span className="form-label">Groups :<br></br>{group}</span>
    </div>
    <div className="col">
    <span className="form-label">Category:<br></br>{category}</span>
    </div>
    
   </div><br></br>
   <div className="row">
    <div className="col">
    <span className="form-label">Groups :<br></br>{group}</span>
    </div>
    <div className="col">
    <span className="form-label">UOM Primary:<br></br>{uomPrimary}</span>
    </div>
    
   </div>
   <br></br>
   <div className="row">
    <div className="col">
    <span className="form-label">UOM Secondary :<br></br> {uomSecondary}</span>
    </div>
    <div className="col">
    <span className="form-label">DLP :<br></br> {dlp}</span>
    </div>
    
   </div>
   <br></br>
   <div className="row">
    <div className="col">
    <span className="form-label">Capacity(ml) :<br></br>{capacity}</span>
    </div>
    <div className="col">
    <span className="form-label">Diameter :<br></br>{diameter}</span>
    </div>
    
   </div>
   <br></br>
   <div className="row">
    <div className="col">
    <span className="form-label">HSN Code :<br></br>{hsnCode}</span>    </div>
    <div className="col">
    <span className="form-label">Brand Name :<br></br>{brandName}</span>
    </div>
    
   </div>
   <br></br>
   <div className="row">
    <div className="col">
    <span className="form-label">Product Type :<br></br> {productType}</span>     </div>
    <div className="col">
    <span className="form-label">IGST% : - {igst}</span>&nbsp;&nbsp;&nbsp;
    <span className="form-label">CGST% : - {cgst}</span>&nbsp;&nbsp;&nbsp;

    <span className="form-label">SGST% : - {sgst}</span>

    </div>
    
   </div>
    </div>
 
    
      <div className="col">
      <img src={"http://43.225.54.138:8080/scm/" + imagedisplay} className="images" style={{ width: '200px', height: '150px' }} />

      </div>
  </div>
</div>
     </div>
     </>
    )
}