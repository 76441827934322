import axios from "axios";
import { MDBCard } from "mdbreact";
import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { BASE_URL } from "../../services/Api";
import authHeader from "../../services/auth-header";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

export default function Create() {
    const [date,setdate] = useState('');
    const [occasion,setoccasion] = useState('');

    let Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
      const navigate = useNavigate();

    const submitdata = () => {
        axios.post(BASE_URL + 'holiday/',{date,occasion},{
            headers:authHeader()
        }).then(res=>{
            navigate('/HolidayTable/1');
            if (res.status == 201 || res.status == 200) {
              // alert("ee")
              Toast.fire({
                icon: 'success',
                title: 'Successfully Created'
              })
            }
        })
    }
    return (
        <MDBCard className="company">
            <h1>Holiday Create</h1>
            <Form className="product1">
                <Row className="mb-1">
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Holiday Date</Form.Label>
                        <Form.Control type='date' name="date" onChange={(e) => setdate(e.target.value)} autoComplete="off" />
                    </Form.Group>
                    <Form.Group as={Col} md="12" controlId="formBasicName">
                        <Form.Label className="label">Occasion</Form.Label>
                        <Form.Control as="textarea" rows={3} name='occasion' onChange={(e) => setoccasion(e.target.value)} placeholder='Enter Occasion' />
                    </Form.Group>
                </Row>
                <Button onClick={submitdata}>Submit</Button>
            </Form>
        </MDBCard>
    )
}