import { MATERIAL_GET_DATA_TO_LOAD } from "../constant";
import { MATERIAL_ADD_TO_CART } from "../constant";
import { MATERIAL_EDIT_CHANGE_MEASUREMENT } from "../constant";
import { MATERIAL_EDIT_CHANGE_QTYPCS } from "../constant";
import { MATERIAL_EDIT_CHANGE_QTYKG } from "../constant";
import { CHANGE_COST_MATERIAL } from "../constant";
import { MATERIAL_EDIT_CHANGE_CAL_MEASUREMENT } from "../constant";
import { CHANGE_MATERIAL_DISCOUNT } from "../constant";
import { REMOVE_FROM_CART_FROM_MATERIAL } from "../constant";
import { CHANGE_MATERIAL_TEXT } from "../constant";
import { EMPTY_MATERIAL_DATA } from "../constant";



export const getmaterialdatatoload = (data) => {

    //  console.warn("action called", data);
    return {

        type : MATERIAL_GET_DATA_TO_LOAD,
        data : data
    }

}


export const materialaddtocart = (data) => {

    //  console.warn("action called", data);
    return {

        type : MATERIAL_ADD_TO_CART,
        data : data
    }

}



export const removefromcartfrommaterial = (data) => {

    // console.warn("action REMOVE_TO_CART called", data);
    return {

        type : REMOVE_FROM_CART_FROM_MATERIAL,
        data : data
    }

}

export const materialmeasurement = (data, item, data2) => {

    return {
        type: MATERIAL_EDIT_CHANGE_MEASUREMENT,
        data: data,
        item: item,
        data2: data2
    }
}

export const materialqtypcs = (data, item, data2) => {


    return {

        type: MATERIAL_EDIT_CHANGE_QTYPCS,
        data: data,
        item: item,
        data2: data2
    }

}

export const materialqtykgs = (data, item, data2) => {

    return {
        type: MATERIAL_EDIT_CHANGE_QTYKG,
        data: data,
        item: item,
        data2: data2

    }
}



export const changecostmaterial = (data, item, data2) => {

    // console.warn("cost change called", data);
    return {

        type : CHANGE_COST_MATERIAL,
        data : data,
        item : item,
        data2 : data2

    }

}

export const materialcalmeasurement = (data, item, data2 ) => {

    return{
        type:MATERIAL_EDIT_CHANGE_CAL_MEASUREMENT,
        data: data,
        item: item,
        data2: data2
    }
}


export const changematerialdiscount = (data, item, data2) => {

    // console.warn("discount change called", data);
    return {

        type : CHANGE_MATERIAL_DISCOUNT,
        data : data,
        item : item,
        data2 : data2

    }

}


export const changematerialtext = (data, item) => {

    // console.warn("text change called", data);
    return {

        type : CHANGE_MATERIAL_TEXT,
        data : data,
        item : item
    }

}


export const emptymaterialdata = () => {

    return {

        type : EMPTY_MATERIAL_DATA,
    }

}