import axios from "axios";
import Swal from "sweetalert2";

const API_URL = "http://43.225.54.138:8080/scm/api/auth/";

const register = (username, email, password) => {
  return axios.post(API_URL + "signup", {
    username,
    email,
    password,
  });
};

const login = (email, password) => {
  return axios
    .post(API_URL + "signin", {
      email,
      password,
    })
    .then((response) => {
      // console.log("sdfsdf",response);
      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const otplogin = ({"email":email,"otp":otp}) => {
  return axios
    .post(API_URL + "signinotp/otp", {
      "email":email,
      "otp":otp,
    })
    .then((response) => {
      // console.log("sdfsdf",response);
      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};
const logout = () => {

  Swal.fire({
    position: "top-end",
    icon: "error",
    title: "You are logged out",
    showConfirmButton: false,
    timer: 1400
  });
  localStorage.removeItem("user");
  // window.location.reload();

};
const logout1 = () => {

  localStorage.removeItem("user");
  // window.location.reload();

};
const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const AuthService = {
  register,
  login,logout1,
  logout,
  getCurrentUser,
  otplogin,
};

export default AuthService;