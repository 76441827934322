import axios from 'axios';
import React, { useEffect, useState } from 'react'
import authHeader from '../Component/services/auth-header';
import { numberToWords } from "amount-to-words";
import { useParams } from 'react-router-dom';

const Salesorder = () => {
  const [word, setword] = useState([34])
  const [sname, setsname] = useState([])
  const [stateid, setstateid] = useState(0);
  const [isLoadingkg, setIsLoading] = useState(true);
  const [adddis, setadddis] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState([]);
  const [data22, setData22] = useState([])
  const [data221, setData221] = useState([])
  const [cdeatils, setcdeatils] = useState([])
  const [jsonData, setjsondata1] = useState([])
  const [delv, setdelv] = useState([])
  const [totalAltsoqty, setTotalAltsoqty] = useState(0);
  const [totalSoqty, setTotalSoqty] = useState(0);
  const [totalGrossamount, setTotalGrossamount] = useState(0);
  const [totalDisAmt, setTotalDisAmt] = useState(0);
  const [totalDisAmt1, setTotalDisAmt1] = useState(0);
  const [sodate, setsodate] = useState(0);
  console.log(jsonData.length);
  const [statee, setstatee] = useState([])
  const [delivery, setdelivery] = useState([])
  const [pincodin, setcoding] = useState([])
  const [billing, setbilling] = useState([])

  const { id } = useParams();
  useEffect(() => {
    document.getElementById("qerteww").style.display = "none";

    document.getElementById("sidein").style.display = "none";

    //  setjsondata1(jsonData1)

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const updatedPageData = jsonData.slice(startIndex, endIndex);
    setCurrentPageData(updatedPageData);
  }, [currentPage]);
  const datemaker = (checkdate) => {
    const originalDateString = checkdate;
    const originalDate = new Date(originalDateString);

    // Options for formatting the date without time
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };

    // Format the date string
    const formattedDateString = originalDate.toLocaleDateString('en-US', options);
    return formattedDateString

  }





  let totalPages = Math.ceil(jsonData.length / 20);


  console.log(totalPages);
  // console.log(jsonData);

  function formatCurrency(amount, currencyCode = 'INR') {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR'
    }).format(amount);
  }
  console.log(formatCurrency(50000));
  useEffect(() => {
    const elements = ["naving", "1sidebar", "qw", "swift", "logoo", "logoo1", "menu"];

    elements.forEach(elementId => {
      const element = document.getElementById(elementId);
      if (element) {
        element.classList.add('hidden');
      }
    });

    const newElement = document.getElementById("new");
    if (newElement) {
      newElement.classList.add('fullWidth');
    }


    if (stateid == 0) {

      axios.get(`http://43.225.54.138:8080/scm/api/so/${id}`, { headers: authHeader() })
        .then(response => {
          const itemere = response.data.distributor.deliveryAddress;
          const yep = itemere.filter((item) => item.id == response.data.deliveryAddress)
          setData221(response.data.distributor);
          // setadddis(response.data.distributor);
          setadddis(yep)
          console.log(yep.map((item) => item.state))

          setstatee(yep.map((item) => item.state))
          setcoding(yep.map((item) => item.pincode))
          setdelivery(yep.map((item) => item.delivery_address))
          setbilling(response.data.distributor.billingAddress)

          console.log(response.data.distributor.deliveryAddress)
          // delivery_address, state, pincode
          // setData221(response.data.distributor)
          // console.log(`http://43.225.54.138:8080/scm/api/product/page/${Number(getid)}/${Number(limit)}/${sort}/${fieldnme}`);
          console.log(response.data);
          let stateid = response.data.distributor.stateid

          console.log(stateid)
          setstateid(stateid)
          // setData221(response.data.distributor)
          setcdeatils(response.data.customerSubContacts)
          setData22(response.data)
          const originalDateString = response.data.sodate;
          const originalDate = new Date(originalDateString);

          // Options for formatting the date without time
          const options = { year: 'numeric', month: '2-digit', day: '2-digit' };

          // Format the date string
          const formattedDateString = originalDate.toLocaleDateString('en-US', options);

          setsodate(formattedDateString);


          const extractedData = response.data.salesOrderItems.map((item) => ({
            no: item.salesorderitemId,
            AllQtApprox: item.altsoqty,
            Qty: item.soqty,
            Dis: item.discount,
            DisAmt: item.discountamount,
            Dis1: item.discount1,
            DisAmt1: item.discountamount1,
            GrossAmt: item.netAmount,
            Per: item.per,
            Amount: item.grossamount,
            DescriptionofGoods: item.product.productName,
            EANCode: item.product.eanCode,
            HSNSAC: item.product.hsnCode,
            GStRate: item.product.igst,
            Rate: item.product.mrp,
            uomSecondary: item.product.uomSecondary,
            check: item.product.productType,
            productType: item.product.productType
          }));


          response.data.salesOrderItems.map((item) => {
            setTotalAltsoqty((prevTotal) => prevTotal + Number(item.altsoqty));
            setTotalSoqty((prevTotal) => prevTotal + Number(item.soqty));
            setTotalGrossamount((prevTotal) => prevTotal + Number(item.netAmount));
            setTotalDisAmt((prevTotal) => prevTotal + Number(item.discountamount));
            setTotalDisAmt1((prevTotal) => prevTotal + Number(item.discountamount1));
            // setTotalNetAmount((prevTotal) => prevTotal + Number(item.netAmount));
          });





          setjsondata1(extractedData);
          console.log(extractedData);
          let sum1 = response.data.salesOrderItems.filter((item) => item.product.productType == "kgproduct").map((item) => item.product.uomSecondary * item.soqty)
          console.log(sum1)
          const sum = sum1.reduce((acc, val) => acc + val, 0);

          let roundedSum = sum.toFixed(2);
          console.log(roundedSum)
          setIsLoading(roundedSum)

          // setTotalSoqty((prevTotal) => prevTotal + Number(item.soqty));
          let sum2 = response.data.salesOrderItems.filter((item) => item.product.productType !== "kgproduct").map((item) => item.product.uomSecondary * item.soqty)
          console.log(sum2)


        })
        .catch(error => {
          console.error(error);
        });


    }
    axios.get(`http://43.225.54.138:8080/scm/api/state/${stateid}`, { headers: authHeader() })
      .then(response => {

        setsname(response.data.name)
        console.log(response.data.name)

      })
      .catch(error => {
        console.error(error);
      });



  }, [stateid])

  let removedElements
  let itemsPerPage = 18;
  const paragraphs = [];
  let i = 0;
  for (i; i < totalPages; i++) {
    let startIndex;
    let endIndex;
    let roman;
    let jsonDataSlice;
    // let remainingItems
    let lastPageDataSize


    // console.log(totalPages);

    startIndex = i * itemsPerPage;
    endIndex = Math.min((i + 1) * itemsPerPage, jsonData.length);
    jsonDataSlice = jsonData.slice(startIndex, endIndex);


    if (totalPages === i + 1) {
      let lastPageIndex = totalPages - 1;
      let lastPageStartIndex = lastPageIndex * itemsPerPage;
      let lastPageEndIndex = Math.min((lastPageIndex + 1) * itemsPerPage, jsonData.length);
      lastPageDataSize = lastPageEndIndex - lastPageStartIndex;

      if (lastPageDataSize > 12) {
        let newidea = lastPageDataSize - 12;
        console.log(newidea);
        jsonDataSlice = jsonData.slice(startIndex, endIndex - newidea);
        removedElements = jsonData.slice(endIndex - newidea, endIndex);
        console.log(removedElements);
      }


      if (totalPages == 1 && i == 0 && lastPageDataSize > 10) {
        let newidea = lastPageDataSize - 10;
        console.log(newidea);
        jsonDataSlice = jsonData.slice(startIndex, endIndex - newidea);
        removedElements = jsonData.slice(endIndex - newidea, endIndex);
        console.log(removedElements);
      }


    }


    // startIndex = i * itemsPerPage;
    // endIndex = Math.min((i + 1) * itemsPerPage, jsonData.length);
    // jsonDataSlice = jsonData.slice(startIndex, endIndex);





    // {array.map((item, i) => (
    //   <p className={i === array.length - 1 ? '' : 'jpmorgan'} key={i}>
    //     {item}
    //   </p>
    // ))}





    paragraphs.push(<p className={i === totalPages.length - 1 ? '' : 'jpmorgan'} key={i}><div className='border1'>



      <div className='row   center'>
        <div className='col'>
          <div className='border2 fontprint'>   NEELAM APPLIANCES LIMITED</div>
        </div>
      </div>

      <div className='row   center'>
        <div className='col  '>
          <div className='border2 fontprint1'> Sales Order</div>
        </div>
      </div>
      <div className='row  '>
        <div className='col-6 '>
          <div className='border2 ertrt12'><font>Buyer’s Order No.    </font>:<font1>{data22.buyerorder}</font1></div>
        </div>
        <div className='col-6 borderl border2'>
          <div ><font>  Voucher No :    </font>:<font1> {data22.voucherno}</font1>  <font>  Date:    </font><font1>{datemaker(sodate)}</font1>  </div>

        </div>
      </div>

      <div className='row  '>
        <div className='col-6 '>
          <div className='border2 ertrt12'><font>Dispatch Through</font>:<font1>{data22.dispatchedthrough}</font1></div>
        </div>
        <div className='col-6 borderl border2'>
          <div ><font>  Destination :    </font>:<font1>{data22.destination}</font1>  <font>  Date:    </font><font1> {(sodate)}</font1>  </div>

        </div>
      </div>

      <div className='row '>
        <div className='col'>
          <font className="border2 ertrt12">TERMS OF DELIVERY :{data22.termsofdelivery}</font>
        </div>
      </div>
      <div className='row  '>
        <div className='col-6 '>
          <div className='border2 ertrt12'><font>DELIVERY ADDRESS :</font></div>
        </div>
        <div className='col-6 borderl border2'>
          <div ><font>BILLING ADDRESS :</font></div>

        </div>
      </div>

      <div className='row  '>
        <div className='col-6 '>
          <div className='border2 ertrt12 probook'>
            <font>NAME:</font><font1>{data221.tradeName}   </font1><br></br>
            {(() => {
              if (i === 0) {
                return (
                  <>
                    <font>Address:</font>
                    <font1>{delivery}{pincodin}</font1>
                    <br />
                    <font>State Name :</font>
                    <font1>{statee}</font1>
                    <br />
                    <font>GSTIN/UIN : </font>
                    <font1>{data221.gstNumber}</font1>
                    <br />
                    <font>PAN/IT No : </font>
                    <font1>{data221.panNumber}</font1>
                    <br />
                    <font>Contact Person : </font>
                    <font1>{data221.alterMobileNumber}, {data221.perMobileNumber}, {cdeatils !== undefined && cdeatils !== null ? cdeatils.phoneno : ""}</font1>
                    <br />
                    <font>Email :</font>
                    <font1>{data221.perEmail} &nbsp; &nbsp;{data221.alterEmail}</font1>
                    <br />
                  </>
                );
              }
              return null;
            })()}
          </div>
        </div>
        <div className='col-6 borderl border2'>
          <div className='  ertrt12 probook'>
            <font>NAME:</font><font1>{data221.tradeName}   </font1><br></br>
            {(() => {
              if (i === 0) {
                return (
                  <>
                    <font>Address:</font>
                    <font1>{billing}</font1>
                    <br />
                    <font>State Name :</font>
                    <font1>  {sname}</font1>
                    <br />
                    <font>GSTIN/UIN : </font>
                    <font1>{data221.gstNumber}</font1>
                    <br />
                    <font>PAN/IT No : </font>
                    <font1>{data221.panNumber}</font1>
                    <br />
                    <font>Contact Person : </font>
                    <font1>{data221.alterMobileNumber}, {data221.perMobileNumber},{cdeatils !== undefined && cdeatils !== null ? cdeatils.phoneno : ""}</font1>
                    <br />
                    <font>Email :</font>
                    <font1>{data221.perEmail} &nbsp; &nbsp;{data221.alterEmail}</font1>
                    <br />
                  </>
                );
              }
              return null;
            })()}
          </div>

        </div>
      </div>
      <table id='rowcount' className='  bordered'>

        <tr className='thback'>
          <th className='thback' width="3%">Sr No.</th>
          <th className='thback' width="auto">Description of Goods <span className='white'>Goodsssssssssssssssssss</span></th>
          <th className='thback' width="3%">HSN/SAC</th>
          <th className='thback' width="3%">GST Rate</th>
          <th className='thback' width="3%">All Qt Approx</th>
          <th className='thback' width="3%">Qty</th>
          <th className='thback' width="3%">Rate</th>
          <th className='thback' width="3%">Per</th>
          <th className='thback' width="3%">Gross Amt</th>
          <th className='thback' width="3%">Dis%</th>
          <th className='thback' width="3%">Dis Amt</th>
          <th className='thback' width="3%">Dis%</th>
          <th className='thback' width="3%">Dis Amt</th>
          <th className='thback' width="3%">Amount</th>
        </tr>

        {

        }

        <tbody className='height'>
          {
            jsonDataSlice.map((item, index) => {
              return <tr>
                <td className='thback1'>{startIndex + index + 1}</td>
                <td className='thback1'>{item.DescriptionofGoods}<br></br> EAN Code : {item.EANCode}</td>
                <td className='thback1'>{item.HSNSAC}</td>
                <td className='thback1'>{item.GStRate}</td>
                {/* item.check=="kgproduct"?<td className='thback1'>{item.uomSecondary*item.Qty}</td>:<td className='thback1'></td> */}
                <td className='thback1'>{item.AllQtApprox}</td>
                <td className='thback1'>{item.Qty}</td>
                <td className='thback1'>{item.Rate}</td>
                {item.check == "kgproduct" ? <td className='thback1'>kg</td> : <td className='thback1'>Pcs</td>}
                <td className='thback1'>{item.GrossAmt}</td>
                <td className='thback1'>{item.Dis}</td>
                <td className='thback1'>{item.DisAmt}</td>
                <td className='thback1'>{item.Dis1}</td>
                <td className='thback1'>{item.DisAmt1}</td>
                <td className='thback1'>{formatCurrency(item.Amount)}</td>
              </tr>
            })

          }







        </tbody>


      </table>




      {totalPages === i + 1 && lastPageDataSize > 12 ? <p className='jpmorgon' key={i}></p> : ""}

      {(() => {
        if (totalPages === i + 1 && lastPageDataSize > 12) {
          return (
            <>
              <div className='row   center'>
                <div className='col'>
                  <div className='border2 fontprint'>   NEELAM APPLIANCES LIMITED</div>
                </div>
              </div>

              <div className='row   center'>
                <div className='col  '>
                  <div className='border2 fontprint1'> Sales Order</div>
                </div>
              </div>
              <div className='row  '>
                <div className='col-6 '>
                  <div className='border2 ertrt12'><font>Buyer’s Order No.    </font>:<font1>{data22.buyerorder}</font1></div>
                </div>
                <div className='col-6 borderl border2'>
                  <div ><font>  Voucher No :    </font>:<font1> {data22.voucherno}</font1>  <font>  Date:    </font><font1>{datemaker(sodate)}</font1>  </div>

                </div>
              </div>

              <div className='row  '>
                <div className='col-6 '>
                  <div className='border2 ertrt12'><font>Dispatch Through</font>:<font1>{data22.dispatchedthrough}</font1></div>
                </div>
                <div className='col-6 borderl border2'>
                  <div ><font>  Destination :    </font>:<font1>{data22.destination}</font1>  <font>  Date:    </font><font1>  {datemaker(sodate)}</font1>  </div>

                </div>
              </div>

              <div className='row '>
                <div className='col'>
                  <font className="border2 ertrt12">TERMS OF DELIVERY :</font>
                  <font>{data22.termsofdelivery}</font>
                </div>
              </div>
              <div className='row  '>
                <div className='col-6 '>
                  <div className='border2 ertrt12'><font>DELIVERY ADDRESS :</font></div>
                </div>
                <div className='col-6 borderl border2'>
                  <div ><font>BILLING ADDRESS :</font></div>

                </div>
              </div>

              <div className='row  '>
                <div className='col-6 '>
                  <div className='border2 ertrt12 probook'>
                    <font>NAME:</font><font1>{data221.tradeName}   </font1><br></br>
                    {(() => {
                      if (i === 0) {
                        return (
                          <>
                            <font>Address:</font>
                            <font1>{delivery}{pincodin}</font1>
                            <br />
                            <font>State Name :</font>
                            <font1>{statee}</font1>
                            <br />
                            <font>GSTIN/UIN : </font>
                            <font1>{data221.gstNumber}</font1>
                            <br />
                            <font>PAN/IT No : </font>
                            <font1>{data221.panNumber}</font1>
                            <br />
                            <font>Contact Person : </font>
                            <font1>{data221.alterMobileNumber}, {data221.perMobileNumber}, {cdeatils !== undefined && cdeatils !== null ? cdeatils.phoneno : ""}
                            </font1>
                            <br />
                            <font>Email :</font>
                            <font1>{data221.perEmail} &nbsp; &nbsp;{data221.alterEmail}</font1>
                            <br />
                          </>
                        );
                      }
                      return null;
                    })()}
                  </div>
                </div>
                <div className='col-6 borderl border2'>
                  <div className='  ertrt12 probook'>
                    <font>NAME:</font><font1>{data221.tradeName}   </font1><br></br>
                    {(() => {
                      if (i === 0) {
                        return (
                          <>
                            <font>Address:</font>
                            <font1>{billing}</font1>
                            <br />
                            <font>State Name :</font>
                            <font1>{sname}</font1>
                            <br />
                            <font>GSTIN/UIN : </font>
                            <font1>{data221.gstNumber}</font1>
                            <br />
                            <font>PAN/IT No : </font>
                            <font1>{data221.panNumber}</font1>
                            <br />
                            <font>Contact Person : </font>
                            <font1>{data221.alterMobileNumber}, {data221.perMobileNumber},{cdeatils !== undefined && cdeatils !== null ? cdeatils.phoneno : ""}</font1>
                            <br />
                            <font>Email :</font>
                            <font1>{data221.perEmail} &nbsp; &nbsp;{data221.alterEmail}</font1>
                            <br />
                          </>
                        );
                      }
                      return null;
                    })()}
                  </div>

                </div>
              </div>
              <table id='rowcount' className='  bordered'>

                <tr className='thback'>
                  <th className='thback' width="3%">Sr No.</th>
                  <th className='thback' width="auto">Description of Goods <span className='white'>Goodsssssssssssssssssss</span></th>
                  <th className='thback' width="3%">HSN/SAC</th>
                  <th className='thback' width="3%">GST Rate</th>
                  <th className='thback' width="3%">All Qt Approx</th>
                  <th className='thback' width="3%">Qty</th>
                  <th className='thback' width="3%">Rate</th>
                  <th className='thback' width="3%">Per</th>
                  <th className='thback' width="3%">Gross Amt</th>
                  <th className='thback' width="3%">Dis%</th>
                  <th className='thback' width="3%">Dis Amt</th>
                  <th className='thback' width="3%">Dis%</th>
                  <th className='thback' width="3%">Dis Amt</th>
                  <th className='thback' width="3%">Amount</th>
                </tr>

                {

                }

                <tbody className='height'>
                  {
                    removedElements.map((item, index) => {
                      return <tr>
                        <td className='thback1'>{jsonData.length - index}</td>
                        <td className='thback1'>{item.DescriptionofGoods}<br></br> EAN Code : {item.EANCode}</td>
                        <td className='thback1'>{item.HSNSAC}</td>
                        <td className='thback1'>{item.GStRate}</td>
                        <td className='thback1'>{item.AllQtApprox}</td>
                        <td className='thback1'>{item.Qty}</td>
                        <td className='thback1'>{item.Rate}</td>
                        <td className='thback1'>{item.Per}</td>
                        <td className='thback1'>{item.GrossAmt}</td>
                        <td className='thback1'>{item.Dis}</td>
                        <td className='thback1'>{item.DisAmt}</td>
                        <td className='thback1'>{item.Dis1}</td>
                        <td className='thback1'>{item.DisAmt1}</td>
                        <td className='thback1'>{item.Amount}</td>
                      </tr>
                    })

                  }







                </tbody>


              </table>
            </>
          )
        }

        if (totalPages == 1 && i == 0 && lastPageDataSize > 10) {
          return (
            <>
              <div className='row   center'>
                <div className='col'>
                  <div className='border2 fontprint'>   NEELAM APPLIANCES LIMITED</div>
                </div>
              </div>

              <div className='row   center'>
                <div className='col  '>
                  <div className='border2 fontprint1'> Sales Order</div>
                </div>
              </div>
              <div className='row  '>
                <div className='col-6 '>
                  <div className='border2 ertrt12'><font>Buyer’s Order No.    </font>:<font1>{data22.buyerorder}</font1></div>
                </div>
                <div className='col-6 borderl border2'>
                  <div ><font>  Voucher No :    </font>:<font1> {data22.voucherno}</font1>  <font>  Date:    </font><font1>{datemaker(sodate)}</font1>  </div>

                </div>
              </div>

              <div className='row  '>
                <div className='col-6 '>
                  <div className='border2 ertrt12'><font>Dispatch Through</font>:<font1>{data22.dispatchedthrough}</font1></div>
                </div>
                <div className='col-6 borderl border2'>
                  <div ><font>  Destination :    </font>:<font1>{data22.destination}</font1>  <font>  Date:    </font><font1>{datemaker(sodate)}</font1>  </div>

                </div>
              </div>

              <div className='row '>
                <div className='col'>
                  <font className="border2 ertrt12">TERMS OF DELIVERY :{data22.termsofdelivery}</font>

                </div>
              </div>
              <div className='row  '>
                <div className='col-6 '>
                  <div className='border2 ertrt12'><font>DELIVERY ADDRESS :</font></div>
                </div>
                <div className='col-6 borderl border2'>
                  <div ><font>BILLING ADDRESS :</font></div>

                </div>
              </div>

              <div className='row  '>
                <div className='col-6 '>
                  <div className='border2 ertrt12 probook'>
                    <font>NAME:</font><font1>{data221.tradeName}   </font1><br></br>


                  </div>
                </div>
                <div className='col-6 borderl border2'>
                  <div className=' ertrt12 probook'>
                    <font>NAME:</font><font1>{data221.tradeName}   </font1><br></br>


                  </div>

                </div>
              </div>
              <table id='rowcount' className='  bordered'>

                <tr className='thback'>
                  <th className='thback' width="3%">Sr No.</th>
                  <th className='thback' width="auto">Description of Goods <span className='white'>Goodsssssssssssssssssss</span></th>
                  <th className='thback' width="3%">HSN/SAC</th>
                  <th className='thback' width="3%">GST Rate</th>
                  <th className='thback' width="3%">All Qt Approx</th>
                  <th className='thback' width="3%">Qty</th>
                  <th className='thback' width="3%">Rate</th>
                  <th className='thback' width="3%">Per</th>
                  <th className='thback' width="3%">Gross Amt</th>
                  <th className='thback' width="3%">Dis%</th>
                  <th className='thback' width="3%">Dis Amt</th>
                  <th className='thback' width="3%">Dis%</th>
                  <th className='thback' width="3%">Dis Amt</th>
                  <th className='thback' width="3%">Amount</th>
                </tr>

                {

                }

                <tbody className='height'>
                  {
                    removedElements.map((item, index) => {
                      return <tr>
                        <td className='thback1'>{jsonData.length + index}</td>
                        <td className='thback1'>{item.DescriptionofGoods}<br></br> EAN Code : {item.EANCode}</td>
                        <td className='thback1'>{item.HSNSAC}</td>
                        <td className='thback1'>{item.GStRate}</td>
                        <td className='thback1'>{item.AllQtApprox}</td>
                        <td className='thback1'>{item.Qty}</td>
                        <td className='thback1'>{item.Rate}</td>
                        <td className='thback1'>{item.Per}</td>
                        <td className='thback1'>{item.GrossAmt}</td>
                        <td className='thback1'>{item.Dis}</td>
                        <td className='thback1'>{item.DisAmt}</td>
                        <td className='thback1'>{item.Dis1}</td>
                        <td className='thback1'>{item.DisAmt1}</td>
                        <td className='thback1'>{item.Amount}</td>
                      </tr>
                    })

                  }







                </tbody>


              </table>
            </>
          )
        }



      })()}


      {(() => {
        if (totalPages === i + 1) {
          return (
            <>
              <table id='rowcount' className='  bordered'>
                <tr className='rede'>
                  <th className='newhite' width="3%">Sr No.</th>
                  <th className='newhite' width="auto">Description of Goods <span className='white'>Goodsssssssssssssssssss</span></th>
                  <th className='newhite' width="3%">HSN/SAC</th>
                  <th className='newhite' width="3%">GST Rate</th>
                  <th className='newhite' width="3%">All Qt Approx</th>
                  <th className='newhite' width="3%">Qty</th>
                  <th className='newhite' width="3%">Rate</th>
                  <th className='newhite' width="3%">Per</th>
                  <th className='newhite' width="3%">Gross Amt</th>
                  <th className='newhite' width="3%">Dis%</th>
                  <th className='newhite' width="3%">Dis Amt</th>
                  <th className='newhite' width="3%">Dis%</th>
                  <th className='newhite' width="3%">Dis Amt</th>
                  <th className='newhite' width="3%">Amount</th>
                </tr>

                <tbody className='height'>

                  <tr className='thback  bordert'>
                    <td className='thback1'> </td>
                    <td className='thback1'></td>
                    <td className='thback1'> </td>
                    <td className='thback1'> </td>
                    <td className='thback1'>{parseFloat(totalAltsoqty.toFixed(2))}</td>
                    <td className='thback1'>{parseFloat(totalSoqty.toFixed(2))}</td>
                    <td className='thback1'></td>
                    <td className='thback1'></td>
                    <td className='thback1'>  {parseFloat(totalGrossamount.toFixed(2))}</td>
                    <td className='thback1'></td>
                    <td className='thback1'>{totalDisAmt}</td>
                    <td className='thback1'></td>
                    <td className='thback1'>{totalDisAmt1}</td>
                    <td className='thback1'>{formatCurrency((data22.grossamount).toFixed(2))}</td>
                  </tr>





                  {data22.igst !== 0 ? <tr className='thback  bordert'>
                    <td className='thback1'> </td>
                    <td className='thback1' >IGST OUTPUT Rounding Off </td>
                    <td className='thback1'> </td>
                    <td className='thback1'> </td>
                    <td className='thback1'> </td>
                    <td className='thback1'></td>
                    <td className='thback1'></td>
                    <td className='thback1'></td>
                    <td className='thback1'></td>
                    <td className='thback1'></td>
                    <td className='thback1'></td>
                    <td className='thback1'></td>
                    <td className='thback1'></td>
                    <td className='thback1'>{formatCurrency(data22.igst)}<br></br>(-){data22.roundingofvalue}</td>
                  </tr> : ""}
                  {data22.igst == 0 ? <>
                    <tr className='thback  bordert'>
                      <td className='thback1'> </td>
                      <td className='thback1' >SGST and CGST OUTPUT Rounding Off </td>
                      <td className='thback1'> </td>
                      <td className='thback1'> </td>
                      <td className='thback1'> </td>
                      <td className='thback1'></td>
                      <td className='thback1'></td>
                      <td className='thback1'></td>
                      <td className='thback1'></td>
                      <td className='thback1'></td>
                      <td className='thback1'></td>
                      <td className='thback1'></td>
                      <td className='thback1'></td>
                      <td className='thback1'>{data22.sgst + "+" + data22.cgst}<br></br>(-){data22.roundingofvalue}</td>
                    </tr></> : ""}

                  <tr className='thback  bordert'>
                    <td className='thback1'> </td>
                    <td className='thback1'>Total</td>
                    <td className='thback1'> </td>
                    <td className='thback1'> </td>
                    <td className='thback1'>{parseFloat(totalAltsoqty.toFixed(2))}</td>
                    <td className='thback1'>{parseFloat(totalSoqty.toFixed(2))}</td>
                    <td className='thback1'></td>
                    <td className='thback1'></td>
                    <td className='thback1'>{formatCurrency(totalGrossamount)}</td>
                    <td className='thback1'></td>
                    <td className='thback1'>{formatCurrency(totalDisAmt)}</td>
                    <td className='thback1'></td>
                    <td className='thback1'>{formatCurrency(totalDisAmt1)}</td>
                    <td className='thback1'>{formatCurrency(data22.grandtotal)}</td>
                  </tr>
                </tbody>
              </table>
              <div className='row     bordert'>
                <div className='col'>
                  <div className='  fontprint'>  Amount Chargeable (In Words):<br></br>
                    {numberToWords((data22.grandtotal).toFixed(2))}
  
                  </div>
                </div>
              </div>

              <div className='row     bordert'>
                <div className='col-2'>
                  <div className='  fontprint'> <img src='/images/salesQR.PNG' className='learnt' />
                  </div>
                  {/* "" */}
                </div>
                <div className='col  '>
                  <div className='  fontprint borderl border2'>Company’s Bank Details </div>
                  <div className='  fontprint  borderl'><font>Bank Name</font>:<font1>HDFC BANK LTD</font1></div>
                  <div className='  fontprint  borderl'><font>A/C No.</font>:<font1>00382790000064</font1></div>
                  <div className='  fontprint  borderl'><font>Branch Name</font>:<font1>EVERSHINECITY, VASAI EAST</font1></div>

                  <div className='  fontprint borderl border2'><font>IFSC Code</font>:<font1>HDFC0000038</font1></div>
                  <div className='  fontprint borderl '><font>Bank Instruction</font>:<font1> </font1></div>


                </div>
              </div>
              <div className='row bordert '>
                <div className='col-6 '>
                  <div className='border2 ertrt12'><font>DELIVERY ADDRESS :</font></div>
                </div>
                <div className='col-6 borderl  '>
                  {/* <div ><font>Total O/S :</font> <font>Total O/S :</font> <font>Total O/S :</font> </div> */}
                  <div className='row border2'>
                    <div className='col  '>
                      <font>Total O/S :</font>
                    </div>
                    <div className='col borderl '>
                      <font>Current O/S</font>
                    </div>
                    <div className='col  borderl'>
                      <font> > 45 Days</font>
                    </div>
                    <div className='col  borderl'>
                      <font>  >45 Days</font>
                    </div>
                  </div>
                  <div className='row border2'>
                    <div className='col  '>
                      <font>2,165.00 Dr</font>
                    </div>
                    <div className='col borderl'>
                      <font>2,165.00 Dr</font>
                    </div>
                    <div className='col borderl'>
                      <font> </font>
                    </div>
                    <div className='col  borderl'>
                      <font> </font>
                    </div>
                  </div>
                  <div className='left2'>
                    <font>NEELAM APPLIANCES LIMITED</font>
                    <br></br>
                    <br></br>

                    <font> Authorised Signatory</font>

                  </div>
                </div>
              </div>
              <div className='row   center'>
                <div className='col border1'>
                  <div className='  fontprint'>  Regd & Corporate Office : Neelam House, Fafadia Indl. Estate, Golani Naka, Waliv Village, Vasai East. Dist: Palghar-401208.
                    PH: 8080561321 / 22 / WhatsApp : 9321822426 EMAIL : mail@neelamappliances.com / PAN No: AABCN9540N / GST NO: 27AABCN9540N1ZY.</div>
                </div>
              </div>
              <div className='row   center'>
                <div className='col  '>
                  <div className='  fontprint'> Mumbai Depot Address : Neelam Appliances Limited G-75, 1st Panjarapole Lane, Sarvodaya Nagar, C.P.Tank, Mumbai-400004. PH: 8080561323.</div>
                </div>
              </div>
              <div className='row   center'>
                <div className='col border1'>
                  <div className='  fontprint'> Subject to Palghar Juridiction</div>
                </div>
              </div>
              <div className='row   center'>
                <div className='col  '>
                  <div className=' fontprint'> This is an Computer generated Document, hence does not require a signature.</div>
                </div>
              </div></>
          )
        }
      })()}

    </div></p>);


  }


  return (

    <div className='  '>
      <div>{paragraphs}</div>





    </div>
  )
}
// window.print()
export default Salesorder