import React from 'react'
import Myreportcomponent from './Myreportcomponent'

const DistributorStockrp = () => {


    const data = [{ "label": "Distributor", "id": "distid", "type": "select" },
    { "label": "Start Date", "id": "startDate", "type": "Date" },
    { "label": "End Date", "id": "endDate", "type": "Date" }]





  return (
    <Myreportcomponent
    title={"Distributor Stock Report"}
    data={data}
   axiostable={'distributor'} 
   tradeName={'tradeName'}
   link={'outdiststock/report'}
    />
  )
}

export default DistributorStockrp