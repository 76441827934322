import { numberToWords } from 'amount-to-words';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import authHeader from '../Component/services/auth-header';
import { useParams } from 'react-router-dom';

const ReceiptNote = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading1, setIsLoading1] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [altqty, setaltqty] = useState([])
  const [currentPageData, setCurrentPageData] = useState([]);
  const [data22, setdata22] = useState([])
const[dis,setsdis]=useState([])
const[csub,setsub]=useState([])
   const[com,setcom]=useState([""])
const[branch,setbranch]=useState([])
const[statename,setnane]=useState([])
const[statecode,setcode]=useState([])
const[supsate,setsup]=useState([])
const[supsate1,setsup1]=useState([])
const[scontact,setscontact]=useState([])
const[jsonData,setjsonData]=useState([])
const[igsttax,setigst]=useState([]) 
const[cgsttax,setcgst]=useState([]) 
const[sgsttax,setsgst]=useState([]) 
const[round,setround]=useState([])
const[gpt,setgpt]=useState([])  
 const [rty,setry]=useState([])
 const [gstvalue, setgstvalue] = useState([])
 const[ state_code,setstate_code]= useState([])
 
 const[ statname,setstatname]= useState([])

 const[roundtotal,setroundtotal]=useState([])
  useEffect(() => {
 
 
    document.getElementById("qerteww").style.display = "none";
    
    document.getElementById("sidein").style.display = "none";

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
   
    const updatedPageData = jsonData.slice(startIndex, endIndex);
    setCurrentPageData(updatedPageData);
  }, [currentPage]);

  let totalPages = Math.ceil(jsonData.length / 10);
  function formatCurrency(amount, currencyCode = 'INR') {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR'
    }).format(amount);
  }

  console.log(totalPages);
  // console.log(jsonData);
  let  data;
  data=[{"id":1,"name":"nihal","brand":"nike"},{"id":1,"name":"12","brand":"12"},{"id":1,"name":"13","brand":"13"},{"id":1,"name":"14","brand":"14"},{"id":1,"name":"15","brand":"15"},{"id":1,"name":"16","brand":"16"},{"id":1,"name":"17","brand":"17"},];
  let roundedSum;
  useEffect(() => {
    axios.get(`http://43.225.54.138:8080/scm/api/mrn/${id}`, { headers: authHeader() })
    .then(response => {
      setdata22(response.data)

//  if(response.data.supplier.states.name){
//         setstatname(response.data.supplier.states.name)
//         setstate_code(response.data.supplier.states.state_code)
//       }

      // console.log(`http://43.225.54.138:8080/scm/api/product/page/${Number(getid)}/${Number(limit)}/${sort}/${fieldnme}`);
      console.log(response.data);
      setsdis(response.data.supplier)
setsub(response.data.supplierSubContacts)
setcom(response.data.company)
setbranch(response.data.branch)
setnane(response.data.branch.states.name)
console.log(response.data.gstvalue);
setgstvalue(response.data.igst)
setsup(response.data.supplier.states.name)
setsup1(response.data.supplier.states.state_code)
setcode(response.data.branch.states.state_code)
setscontact(response.data.supplierSubContacts)
      const extractedData = response.data.materialReceiptNoteItems.map((item) => ({
        id: item.id,
        AllQtApprox: item.mrnaltquantity,
        Qty: item.mrnquantity,
        GrossAmt: item.amount,
        Per: item.product.productType,
        Amount: item.amount,
        DescriptionofGoods:item.product.productName,
        EANCode:item.product.eanCode,
        HSNSAC:item.product.hsnCode,
        GStRate:item.product.igst,
        Rate:item.rate,
        uomSecondary:item.product.uomSecondary,
        productType:item.product.productType,
porate:item.product.mrp,
      }));
      // const extractedData12 = response.data.map((item) => ({
      //   id: item.id,
      // }))
      console.log(response.data.igst);
setgpt(response.data.grandtotal)
setigst(response.data.igst)
setsgst(response.data.sgst)
setcgst(response.data.cgst)
setround(response.data.roundofvalue)
      setjsonData(extractedData)

let sum1=response.data.materialReceiptNoteItems.filter((item)=>item.product.productType=="kgproduct").map((item)=>item.product.uomSecondary*item.mrnquantity)

const sum = sum1.reduce((acc, val) => acc + val, 0);
 
 roundedSum = sum.toFixed(2);

 setIsLoading(roundedSum);
//  console.log();
 setroundtotal(response.data.materialReceiptNoteItems.map((item)=>item.amount).reduce((acc, val) => acc + val, 0))

const qty=response.data.materialReceiptNoteItems.map((item)=>item.mrnquantity);

const qty1 = qty.reduce((acc, val) => acc + val, 0);
 
setry(qty1.toFixed(2))

const qty111=response.data.materialReceiptNoteItems.map((item)=>item.mrnaltquantity);

const qty11 = qty111.reduce((acc, val) => acc + val, 0);

setaltqty(qty11)


    })
    .catch(error => {
      console.error(error);
    });


    const elements = ["naving", "1sidebar", "qw", "swift", "logoo", "logoo1", "menu","sidebar"];

    elements.forEach(elementId => {
      const element = document.getElementById(elementId);
      if (element) {
        element.classList.add('hidden');
      }
    });

    const newElement = document.getElementById("new");
    if (newElement) {
      newElement.classList.add('fullWidth');
    }
  }, [])
  let removedElements
  let itemsPerPage=10;
  const paragraphs = [];
  let i = 0;
  for (i; i < totalPages; i++) {
    let startIndex;
    let endIndex;
    let roman;
    let jsonDataSlice;
    // let remainingItems
    let lastPageDataSize

     
// console.log(totalPages);
 
      startIndex = i * itemsPerPage;
      endIndex = Math.min((i + 1) * itemsPerPage, jsonData.length);
      jsonDataSlice = jsonData.slice(startIndex, endIndex);
    
 
    if(totalPages===i+1){
      let lastPageIndex = totalPages - 1;
      let lastPageStartIndex = lastPageIndex * itemsPerPage;
      let lastPageEndIndex = Math.min((lastPageIndex + 1) * itemsPerPage, jsonData.length);
        lastPageDataSize = lastPageEndIndex - lastPageStartIndex;

  if (lastPageDataSize >7) {
    let newidea = lastPageDataSize-7;
    console.log(newidea);
      jsonDataSlice = jsonData.slice(startIndex, endIndex-newidea);
        removedElements = jsonData.slice(endIndex - newidea, endIndex);
      console.log(removedElements);
  }


  if (totalPages==1&&i==0&&lastPageDataSize >6) {
    let newidea = lastPageDataSize-6;
    console.log(newidea);
      jsonDataSlice = jsonData.slice(startIndex, endIndex-newidea);
        removedElements = jsonData.slice(endIndex - newidea, endIndex);
      console.log(removedElements);
  }


    } 


      // startIndex = i * itemsPerPage;
      // endIndex = Math.min((i + 1) * itemsPerPage, jsonData.length);
      // jsonDataSlice = jsonData.slice(startIndex, endIndex);




    





    paragraphs.push(<p className='jpmorgon' key={i}>
       {i===0? <p className='fontprint center '>Receipt Note</p>:""}
        <div className='border1'>
<div className='row border1'>
  <div className='col-9 border1'>
    <h1 className='para size9'><p><b>{com !== undefined && com !== null ?  com.cname: ""}</b></p><br></br>{com !== undefined && com !== null ?  com.address: ""}  <br></br>
  Contact :  {com !== undefined && com !== null ?  com.phone: ""}<br></br>
E-Mail :   {com !== undefined && com !== null ?  com.email: ""}
<br></br>www.neelamappliances.com<br></br>
GSTIN/UIN:   {com !== undefined && com !== null ?  com.gst: ""}
 <br></br>State Name : Maharashtra, Code : 27

</h1>
  </div>
  <div className='col '>
  <div className='row    borderhard'>
      <div className='col  borderight'>
      <b className='size9'>Receipt Note No</b>
      <font className="size9">{dis.receiptnoteno}</font>

      </div>
      <div className='col '>
      <b className='size9'>Dated:</b>
      <font className="size9">{dis.receiptdate}</font>
      </div>
      
    </div>
    <div className='row    borderhard'>
      <div className='col  borderight'>
      <b className='size9'>Reference No. & Date</b>
      <font className="size9">{dis.receiptnoteno}</font>

      </div>
      <div className='col '>
      <b className='size9'>Other References::</b>
      <font className="size9">{dis.refernceno}</font>
      </div>
      
    </div>
  </div>
  <div className='col-9 border1'>
    <h1 className='para size9 fizer'> <b>Consignee (Ship to)</b><br></br>
    {branch.branchname} <br></br>
    {branch.address}<br></br>
GSTIN/UIN :{branch.gstno}<br></br>
State Name:{statename}, Code : {statecode}

</h1>
  </div>
  <div className='col'>
    {/* <h1>hry</h1> */}
  </div>
  <div className='col-9 border1'>
    <h1 className='para'> 
    <b className='size9'>Supplier (Bill from)</b><br></br>
    <font className="size9">
    {dis.companyname},{dis.cities}
    {dis.address}{dis.zipcode} <br></br>
 
   <font className="size9">GSTIN/UIN: {dis && dis.gstno}</font><br></br>
  <font className="size9">PAN/IT No: AAFFB8197A</font><br></br>
  <font className="size9">State Name: {supsate}, Code: {supsate1}</font><br></br>
  <font className="size9">Contact person: {scontact && scontact.contactname ? scontact.contactname : ""}</font><br></br>
  <font className="size9">Contact: {dis && dis.phonenumber}, {scontact && scontact.phoneno}</font><br></br>
  <font className="size9">E-Mail: {dis && dis.email}</font>
 
</font>
</h1>
  </div>
  <div className='col'>
 
  </div>
</div>
     
      <table id='rowcount' className='  bordered'>

        <tr className='thback'>
          <th className='thback' width="3%">Sr No.</th>
          <th className='thback' width="auto">Description of Goods <span className='white'>GoodssssGoodssssssssssssssssssssssssssssssssss</span></th>
          <th className='thback' width="3%">Alt. Quantity<br></br>Shipped</th>
          <th className='thback' width="3%">Alt. Quantity<br></br> Billed</th>
          <th className='thback' width="3%">  Quantity<br></br>Shipped</th>
          <th className='thback' width="3%">  Quantity<br></br> Billed</th>
          <th className='thback' width="3%">Rate</th>
          <th className='thback' width="3%">Po Rate</th>
          <th className='thback' width="3%">Per</th>
          
          <th className='thback' width="3%">Amount</th>
        </tr>
     
   {
   
   }
        
    <tbody className='height'>
          {
            jsonDataSlice.map((item, index) => {
              return <tr>
                <td  className='thback1'>{item.id}</td>
                <td  className='thback1 sizeropt'>{item.DescriptionofGoods}<br></br> EAN Code : {item.EANCode}</td>
               <td  className='thback1 sizeropt'>{item.AllQtApprox}&nbsp;&nbsp;sets</td>             
                <td  className='thback1 sizeropt'>{item.AllQtApprox}&nbsp;&nbsp;sets</td>        
             <td  className='thback1 sizeropt'>{item.Qty}kg</td>
             <td  className='thback1 sizeropt'>{item.Qty}kg</td>   
                <td  className='thback1 sizeropt'>{item.Rate}</td>
                <td  className='thback1 sizeropt'>{item.porate}</td>
                <td  className='thback1 sizeropt'>{item.Per}</td>
                <td  className='thback1 sizeropt'>{item.Amount}</td>
               
              </tr>
            })

          }

 





        </tbody> 
        
       
      </table>




{totalPages===i+1&&lastPageDataSize>7?<p className='jpmorgon' key={i}></p>:""}

{(()=>{
if(totalPages===i+1&&lastPageDataSize>7){
return (
<div className='border1'><div className='row border1'>
  <div className='col-9 border1'>
  <h1 className='para size9'><p><b>{com !== undefined && com !== null ?  com.cname: ""}</b></p><br></br> {com.address}<br></br>
  Contact : {com.phone}<br></br>
E-Mail : {com.email}
<br></br>www.neelamappliances.com<br></br>
GSTIN/UIN: {com.gst}
 <br></br>State Name : Maharashtra, Code : 27

</h1>
  </div>
  <div className='col '>
  <div className='row    borderhard'>
      <div className='col  borderight'>
      <b className='size9'>Receipt Note No</b>
      <font className="size9">{dis.receiptnoteno}</font>

      </div>
      <div className='col '>
      <b className='size9'>Dated:</b>
      <font className="size9">{dis.receiptdate}</font>
      </div>
      
    </div>
    

    <div className='row    borderhard'>
      <div className='col  borderight'>
      <b className='size9'>Reference No. & Date</b>
      <font className="size9">{dis.receiptnoteno}</font>

      </div>
      <div className='col '>
      <b className='size9'>Other References::</b>
      <font className="size9">{dis.refernceno}</font>
      </div>
      
    </div>


  </div>
  <div className='col-9 border1'>
  <h1 className='para size9 fizer'> <b>Consignee (Ship to)</b><br></br>
    {branch.branchname} <br></br>
    {branch.address}<br></br>
GSTIN/UIN :{branch.gstno}<br></br>
State Name:{statename}, Code : {statecode}

</h1>
  </div>
  <div className='col'>
    {/* <h1>hry</h1> */}
  </div>
  <div className='col-9 border1'>
  <h1 className='para'> 
    <b className='size9'>Supplier (Bill from)</b><br></br>
    <font className="size9">
    {dis.companyname},{dis.cities}
    {dis.address}{dis.zipcode} <br></br>
 
<font  className="size9">GSTIN/UIN :   {dis.gstno}</font><br></br>
<font  className="size9">PAN/IT No  :AAFFB8197A</font><br></br>
<font  className="size9">State Name :{supsate} , Code :{supsate1}</font><br></br>
<font  className="size9">Contact person :{scontact.contactname}</font><br></br>
<font  className="size9">Contact :  {dis.phonenumber} ,{scontact.phoneno}</font><br></br>
<font  className="size9">E-Mail : {dis.email}</font>
</font>
</h1>
  </div>
  <div className='col'>
 
  </div>
  <table id='rowcount' className='  bordered'>
  
  <tr className='thback'>
    <th className='thback ' width="3%">Sr No.</th>
    <th className='thback' width="auto">Description of Goods <span className='white'>GoodsGoodsssssssssssssssssssGoodsssssssssssssssssssssssssssssssssssss</span></th>
    <th className='thback' width="3%">Alt. Quantity<br></br>Shipped</th>
    <th className='thback' width="3%">Alt. Quantity<br></br> Billed</th>
    <th className='thback' width="3%">  Quantity<br></br>Shipped</th>
    <th className='thback' width="3%">  Quantity<br></br> Billed</th>
    <th className='thback' width="3%">Rate</th>
    <th className='thback' width="3%">Po Rate</th>
    <th className='thback' width="3%">Per</th>
    
    <th className='thback' width="3%">Amount</th>
  </tr>
 
{

}
    
<tbody className='height'>
      {
        removedElements.map((item, index) => {
          return <tr>
              <td  className='thback1'>{item.id}</td>
                <td  className='thback1 sizeropt'>{item.DescriptionofGoods}<br></br> EAN Code : {item.EANCode}</td>
               <td  className='thback1 sizeropt'>{item.Qty}&nbsp;&nbsp;sets</td>             
                <td  className='thback1 sizeropt'>{item.Qty}&nbsp;&nbsp;sets</td> 
                {item.productType="kgproduct"?  <td  className='thback1 sizeropt'>{item.uomSecondary*item.Qty}kg</td>:  <td  className='thback1 sizeropt'></td>}
              {item.productType="kgproduct"?  <td  className='thback1 sizeropt'>{item.uomSecondary*item.Qty}kg</td>:  <td  className='thback1 sizeropt'></td>}            
                <td  className='thback1 sizeropt'>{item.Rate}</td>
                <td  className='thback1 sizeropt'>{item.porate}</td>
                <td  className='thback1 sizeropt'>{item.Per}</td>
                <td  className='thback1 sizeropt'>{item.Amount}</td>
            
          </tr>
        })

      }







    </tbody> 
    
   
  </table>
</div></div>)
}

if(totalPages===1&&i==0&&lastPageDataSize>6){
  return (
   <><p className='jpmorgon'></p>
  <div className='row border1'>
  <div className='col-9 border1'>
  <h1 className='para size9'>
    {/* <p><b>{com.cname}</b></p><br></br> {com.address} */}
  <br></br>
  Contact : {com.phone}<br></br>
E-Mail : {com.email}
<br></br>www.neelamappliances.com<br></br>
GSTIN/UIN: {com.gst}
{/* <br></br>State Name : {com.states.name}, Code : 27 */}
<br></br>State Name : Maharashtra, Code : 27

</h1>
  </div>
  <div className='col '>
  <div className='row    borderhard'>
      <div className='col  borderight'>
      <b className='size9'>Receipt Note No</b>
      <font className="size9">{dis.receiptnoteno}</font>

      </div>
      <div className='col '>
      <b className='size9'>Dated:</b>
      <font className="size9">{dis.receiptdate}</font>
      </div>
      
    </div>
    <div className='row    borderhard'>
      <div className='col  borderight'>
      <b className='size9'>Reference No. & Date</b>
      <font className="size9">{dis.receiptnoteno}</font>

      </div>
      <div className='col '>
      <b className='size9'>Other References::</b>
      <font className="size9">{dis.refernceno}</font>
      </div>
      
    </div>
    {/* <div className='row    bordertop'>
      <div className='col smallheight  borderight'>
      <b className='size9'>Reference No. & Date:</b><br></br>
<font className="size9">PO/VSI/675/October dt. 20-Nov-23 </font>

      </div>
      <div className='col '>
      <b className='size9'> Other References:-</b><br></br>

      </div>
      
    </div> */}
  </div>
  <div className='col-9 border1'>
  <h1 className='para size9 fizer'> <b>Consignee (Ship to)</b><br></br>
    {branch.branchname} <br></br>
    {branch.address}<br></br>
GSTIN/UIN :{branch.gstno}<br></br>
State Name:{statename}, Code : {statecode}

</h1>
  </div>
  <div className='col'>
    {/* <h1>hry</h1> */}
  </div>
  <div className='col-9 border1'>
  <h1 className='para'> 
    <b className='size9'>Supplier (Bill from)</b><br></br>
    <font className="size9">
    {dis.companyname},{dis.cities}
    {dis.address}{dis.zipcode} <br></br>
 
<font  className="size9">GSTIN/UIN :   {dis.gstno}</font><br></br>
<font  className="size9">PAN/IT No  :AAFFB8197A</font><br></br>
<font  className="size9">State Name :{supsate} , Code :{supsate1}</font><br></br>
<font  className="size9">Contact person :{scontact.contactname}</font><br></br>
<font  className="size9">Contact :  {dis.phonenumber} ,{scontact.phoneno}</font><br></br>
<font  className="size9">E-Mail : {dis.email}</font>
</font>
</h1>
  </div>
  <div className='col'>
 
  </div>
</div>
        <table id='rowcount' className='  bordered'>
  
        <tr className='thback'>
          <th className='thback' width="3%">Sr No.</th>
          <th className='thback sizeropt' width="auto">Description of Goods <span className='white'>GoodsssssGoodssssssssssssssssssssssssssssssssssssssssssssssssssssssssssss</span></th>
          <th className='thback sizeropt' width="3%">Alt. Quantity<br></br>Shipped</th>
          <th className='thback sizeropt' width="3%">Alt. Quantity<br></br> Billed</th>
          <th className='thback sizeropt' width="3%">  Quantity<br></br>Shipped</th>
          <th className='thback sizeropt' width="3%">  Quantity<br></br> Billed</th>
          <th className='thback sizeropt' width="3%">Rate</th>
          <th className='thback sizeropt' width="3%">Po Rate</th>
          <th className='thback sizeropt' width="3%">Per</th>
          
          <th className='thback' width="3%">Amount</th>
        </tr>
       
     {
     
     }
          
      <tbody className='height'>
            {
              removedElements.map((item, index) => {
                return <tr>
                 <td  className='thback1'>{item.id}</td>
                <td  className='thback1 sizeropt'>{item.DescriptionofGoods}<br></br> EAN Code : {item.EANCode}</td>
               <td  className='thback1 sizeropt'>{item.Qty}&nbsp;&nbsp;sets</td>             
                <td  className='thback1 sizeropt'>{item.Qty}&nbsp;&nbsp;sets</td>    
                {item.productType="kgproduct"?  <td  className='thback1 sizeropt'>{item.uomSecondary*item.Qty}kg</td>:  <td  className='thback1 sizeropt'></td>}
              {item.productType="kgproduct"?  <td  className='thback1 sizeropt'>{item.uomSecondary*item.Qty}kg</td>:  <td  className='thback1 sizeropt'></td>}         
                <td  className='thback1 sizeropt'>{item.Rate}</td>
                <td  className='thback1 sizeropt'>{item.porate}</td>
                <td  className='thback1 sizeropt'>{item.Per}</td>
                <td  className='thback1 sizeropt'>{item.Amount}</td>
                  </tr>
              })
  
            }
  
   
  
  
  
  
  
          </tbody> 
          
         
        </table>
   
 
   </>
  )
  }



 })()}


 {(()=>{
if(totalPages===i+1){
return (
  <>
  <table id='rowcount' className='  bordered'>
  <tr className='thback'>
          <th className='thback white' width="3%">Sr No.</th>
          <th className='thback white ' width="auto">GoodsGoodsssssssssssssssssssGosss<span className='white'>Gd</span></th>
          <th className='thback white  ' width="3%">Alt. Quantity<br></br>Shipped</th>
          <th className='thback white ' width="3%">Alt. Quantity<br></br> Billed</th>
          <th className='thback white ' width="3%">  Quantity<br></br>Shipped</th>
          <th className='thback white ' width="3%">  Quantity<br></br> Billed</th>
          <th className='thback white ' width="3%">Rate</th>
          <th className='thback  white' width="3%">Po Rate</th>
          <th className='thback white  ' width="3%">Persss</th>
          
          <th className='thback white' width="3%">Amount</th>
        </tr>

        <tbody className='height'>
          
        <tr className='thback bordert'>
    <td className='thback1' width="3%"> </td>
    <td className='thback1'></td>
    <td className='thback1' width="3%"> {altqty.toFixed(2)} </td>
    <td className='thback1' width="3%"> {altqty.toFixed(2)} </td>
    <td className='thback1' width="3%"> {Number(rty).toFixed(2)} </td>
    <td className='thback1' width="3%"> {Number(rty).toFixed(2)} </td>
    <td className='thback1' width="3%"> </td>
    <td className='thback1' width="3%"> </td>
    <td className='thback1' width="3%"> </td>
    <td className='thback1' width="3%"> {roundtotal.toFixed(2)} </td>
</tr>
    
               
                
{   gstvalue!==0?             <tr className='thback  bordert'>
            <td className='thback1' width="3%"> </td>
            <td className='thback1' >Igst INPUT</td>
            <td className='thback1'width="3%">    </td>
            <td className='thback1'width="3%"> </td>
            <td className='thback1'width="3%">   </td>
            <td className='thback1'width="3%">   </td>
            <td className='thback1'width="3%"> </td>
            <td className='thback1'width="3%"></td>
            <td className='thback1'width="3%"> </td>
            <td className='thback1'width="3%"> {Number(gstvalue).toFixed(2)} </td>
          </tr>:""}

          {gstvalue==0?<tr className='thback border  bordert'>
            <td  className='thback1 sizeropt'> </td>
            <td  className='thback1 sizeropt'>SGST INPUT</td>
            
            <td  className='thback1 sizeropt'>    </td>
            <td  className='thback1 sizeropt'> </td>
            <td  className='thback1 sizeropt'>   </td>
            <td  className='thback1 sizeropt'>   </td>
            <td  className='thback1 sizeropt'> </td>
            <td  className='thback1 sizeropt'></td>
            <td  className='thback1 sizeropt'> </td>
            <td  className='thback1 sizeropt'>  {Number(roundtotal*sgsttax/100).toFixed(2)}</td>
          </tr>:""}

          {igsttax==0?<tr className='thback  bordert'>
            <td className='thback1' width="3%"> </td>
            <td className='thback1' >CGST INPUT</td>
            
            <td className='thback1'width="3%">    </td>
            <td className='thback1'width="3%"> </td>
            <td className='thback1'width="3%">   </td>
            <td className='thback1'width="3%">   </td>
            <td className='thback1'width="3%"> </td>
            <td className='thback1'width="3%"></td>
            <td className='thback1'width="3%"> </td>
            <td className='thback1'width="3%"> {Number(roundtotal*cgsttax/100).toFixed(2)}</td>
          </tr>:""}

          <tr className='thback  bordert'>
            <td  className='thback1 sizeropt'> </td>
            <td  className='thback1 sizeropt'>Rounding Off</td>
            
            <td  className='thback1 sizeropt'>    </td>
            <td  className='thback1 sizeropt'> </td>
            <td  className='thback1 sizeropt'>   </td>
            <td  className='thback1 sizeropt'>   </td>
            <td  className='thback1 sizeropt'> </td>
            <td  className='thback1 sizeropt'></td>
            <td  className='thback1 sizeropt'> </td>
            <td  className='thback1 sizeropt'>(-){round}</td>
          </tr>
          <tr className='thback  bordert'>
            <td  className='thback1 sizeropt'> </td>
            <td  className='thback1 sizeropt'>Total</td>
            
            <td  className='thback1 sizeropt'>  {altqty}&nbsp;&nbsp;sets</td>
            <td  className='thback1 sizeropt'> {altqty}&nbsp;&nbsp;sets </td>
            <td  className='thback1 sizeropt'> {rty}kg   </td>
            <td  className='thback1 sizeropt'> {rty}kg  </td>
            <td  className='thback1 sizeropt'> </td>
            <td  className='thback1 sizeropt'></td>
            <td  className='thback1 sizeropt'> </td>
            <td  className='thback1 sizeropt'>{formatCurrency(gpt)} </td>
          </tr>
               
             
  
           
 
        </tbody>
      </table>

      <div className='row     bordert'>
        <div className='col'>
          <div className=' fontprint'>  Amount Chargeable (In Words):<br></br>
          {numberToWords(Math.round(data22.grandtotal))} </div>
        </div>
        <div className='col-1 right'>
          <div className='  fontprint'> E.& O.E</div>
        </div>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
       <div className='orgindisplay'>
         <h1></h1>
         <h1 className='ertrt'>
<p className='fontsize12'>
for NEELAM APPLIANCES LIMITED
</p>
<p className='right fontsize12'>Authorised Signatory</p>

         </h1>
       </div>
       
      </div>
 </>
)
}
 })()}

    </div></p>);


  }

 
 
  return (

    <div className='  '>
      <div>{paragraphs}</div>
 


     

    </div>
  )
}

export default ReceiptNote