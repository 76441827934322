import axios from "axios";
import { MDBCard } from "mdbreact";
import React, { useEffect, useState } from "react";
import authHeader from "../../services/auth-header";
import { BASE_URL } from "../../services/Api";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { Button, Col, Form, Row } from "react-bootstrap";

export default function Update(){
    const {id} = useParams();
    const [gname,setgname] = useState('');
    const [staff,setstaff] = useState('');
    const [staffdata,setstaffdata] = useState('');
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const applyFontSizeTransition = (elementId) => {
      const element = document.getElementById(elementId);
      element.style.transition = 'font-size 2s';
      element.style.fontSize = '30px';
      element.style.fontWeight = '600';
      // font-weight: 600;
      setTimeout(() => {
         
        document.getElementById("typer1").classList.add('promote');
        element.style.fontSize = '24px';
      }, 1000);
    };
    /* Product Id */
    let Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

    useEffect(()=>{
      axios.get(BASE_URL + 'galaprefix/getbyid/'+id,{
        headers:authHeader()
      }).then(res=>{
        setstaff({"id":res.data.staff.id});
        setstaffdata(res.data.staff);
        setgname(res.data.gname);
      })
    },[])

    const submitdata = () => {
        setIsLoading(true);
        axios.put(BASE_URL + 'galaprefix/update/'+id,{staff,gname},{
            headers:authHeader()
        }).then(res=>{
            navigate('/Gala/Table/1');
            if (res.status == 201 || res.status == 200) {
              // alert("ee")
              Toast.fire({
                icon: 'success',
                title: 'Successfully Updated'
              })
            }
        }).catch(error => {
            console.error('Error adding  :', error);
            if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `
                <div>
                  <p id="issueText">Why do I have this issue?</p>
                  <ul>
              
                  <li id="typer1">You might have an internet issue</li>
                  </ul>
                </div>
              `,
              didOpen: () => {
                applyFontSizeTransition('issueText');
              }
            });
          }
          }).finally(() => {
            setIsLoading(false); 
          });
    }

    return(
        <MDBCard className="company">
            <h1>Gala Update</h1>
            <Form className="product1">
                <Row className="mb-1">
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Staff Name</Form.Label>
                        <Form.Control type="text" name="staff" disabled value={staffdata.staffName} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Gala Name</Form.Label>
                        <Form.Control type="text" name="gname" disabled value={gname} placeholder="Enter Gala Name" onChange={(e) => setgname(e.target.value)} />
                    </Form.Group>

                </Row>
                <Button variant="primary" style={{width:'auto'}} onClick={submitdata} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Submit'}
        </Button>
            </Form>
        </MDBCard>
    )
}