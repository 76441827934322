import axios from 'axios';
import React, { useEffect } from 'react'
import { useState } from 'react'
import { Container, Row, Form, Col, Button } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom';
import authHeader from '../../services/auth-header';
import WareHouseService from '../../services/WareHouseService';
import { MDBCard } from 'mdbreact';
import { BASE_URL } from '../../services/Api';
import Select from 'react-select';
import Swal from 'sweetalert2';

export default function WareHouse() {
  const [name, setname] = useState('');
  const [location, setlocation] = useState('');
  const [type, settype] = useState('');
  const [branchdata, setBranchData] = useState([]);
  const [ branch, setbranch ] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const applyFontSizeTransition = (elementId) => {
    const element = document.getElementById(elementId);
    element.style.transition = 'font-size 2s';
    element.style.fontSize = '30px';
    element.style.fontWeight = '600';
    // font-weight: 600;
    setTimeout(() => {
       
      document.getElementById("typer1").classList.add('promote');
      element.style.fontSize = '24px';
    }, 1000);
  };
  const navigate = useNavigate();

  useEffect(() => {

    axios.get(BASE_URL + 'branch/getAll', {
      headers: authHeader()
    }).then(res => {
      // console.log("mello", res.data);
      setBranchData((res.data).map(user => ({ value: user.id, label: user.branchname })))
    })
  }, []);

  let onChangeBranch = (e) => {

    setbranch({"id":e.value});

  }

  const sendDataToAPI = () => {
    const warehouse = {
      name,
      location,
      type,
      branch
    }
    

    const typecheck = type;

    if (typecheck == 1) {
      WareHouseService.getwarehouse().then((response) => {

        const typevalue = [];
        response.data.forEach(user =>
          typevalue.push(user.type)
        );

        const data = typevalue.includes(typecheck);

        if (data == true) {
          alert("Primary Warehouse Already Exist In Database");
        }
        else { setIsLoading(true);
          WareHouseService.getwarehouseCreate(warehouse).then((response) => {

            navigate('/warehouse/1');
          }).catch(error => {
            console.error('Error adding  :', error);
            if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `
                <div>
                  <p id="issueText">Why do I have this issue?</p>
                  <ul>
              
                  <li id="typer1">You might have an internet issue</li>
                  </ul>
                </div>
              `,
              didOpen: () => {
                applyFontSizeTransition('issueText');
              }
            });
          }
          })
          .finally(() => {
            setIsLoading(false); 
          });
        }
      }) .catch(error => {
        console.error('Error adding  :', error);
        if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `
            <div>
              <p id="issueText">Why do I have this issue?</p>
              <ul>
          
              <li id="typer1">You might have an internet issue</li>
              </ul>
            </div>
          `,
          didOpen: () => {
            applyFontSizeTransition('issueText');
          }
        });
      }
      })
      .finally(() => {
        setIsLoading(false); 
      });
    }
    else { setIsLoading(true);
      WareHouseService.getwarehouseCreate(warehouse).then((response) => {

        navigate('/warehouse/1');
      }) .catch(error => {
        console.error('Error adding  :', error);
        if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `
            <div>
              <p id="issueText">Why do I have this issue?</p>
              <ul>
          
              <li id="typer1">You might have an internet issue</li>
              </ul>
            </div>
          `,
          didOpen: () => {
            applyFontSizeTransition('issueText');
          }
        });
      }
      })
      .finally(() => {
        setIsLoading(false); 
      });
    }

  }
  return (
    <MDBCard>

      <h1>ADD WAREHOUSE</h1>
      <Link className='btn btn-primary' to={`/warehouse/1`}>Back</Link>
      <Form className='product'>
        <Row className="mb-1">
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">WareHouse Name</Form.Label>
            <Form.Control type="text" onChange={(e) => setname(e.target.value)} placeholder="Enter WareHouse Name" />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Location</Form.Label>
            <Form.Control type="text" onChange={(e) => setlocation(e.target.value)} placeholder="Enter Your City" />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Branch</Form.Label>
            <Select options={branchdata} onChange={(e) => onChangeBranch(e)}/>
            </Form.Group>

          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">WareHouse Type</Form.Label>
            <Form.Select onChange={(e) => settype(e.target.value)}>
              <option >--Select--</option>
              <option value="1">Primary</option>
              <option value="2">Secondary</option>
            </Form.Select>
          </Form.Group>
        </Row>

        <Button variant="primary" style={{width:'auto'}} onClick={sendDataToAPI} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Submit'}
        </Button>
      </Form>

    </MDBCard>
  )
}
