import axios from "axios";
import { MDBCard } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Accordion, Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../services/Api";
import authHeader from "../services/auth-header";
import Swal from "sweetalert2";
import AuthService from "../services/auth.service";


export default function View() {
const navigate=useNavigate()
    const { id } = useParams();
    const { pointindex } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const applyFontSizeTransition = (elementId) => {
      const element = document.getElementById(elementId);
      element.style.transition = 'font-size 2s';
      element.style.fontSize = '30px';
      element.style.fontWeight = '600';
      // font-weight: 600;
      setTimeout(() => {
         
        document.getElementById("typer1").classList.add('promote');
        element.style.fontSize = '24px';
      }, 1000);
    };
    const [branchdata, setbranchdata] = useState('');
    const [warehousedata, setwarehousedata] = useState('');
    const [supplierdata, setsupplierdata] = useState('');
    const [supplierSubContactsdata, setsupplierSubContactsdata] = useState('');
    const [date, setDate] = useState('');
    const [date1, setDate1] = useState('');
    const [Data, setData] = useState('');
    const [purchaseReturnItems, setpurchaseReturnItems] = useState([]);
    const [anm, setanm] = useState({});
    const [version, setversion] = useState('');
    const [taxSch, settaxSch] = useState('');
    const [supTyp, setsupTyp] = useState('');
    const [regRev, setregRev] = useState('');
    const [igstOnIntra, setigstOnIntra] = useState('');
    const [typ, settyp] = useState('');
    const [no, setno] = useState('');
    const [dt, setdt] = useState('');
    const [distance, setdistance] = useState('');
    const [transMode, settransMode] = useState('');
    const [transId, settransId] = useState('');
    const [transName, settransName] = useState('');
    const [transDocDt, settransDocDt] = useState('');
    const [transDocNo, settransDocNo] = useState('');
    const [vehNo, setvehNo] = useState('');
    const [vehType, setvehType] = useState('');
    const [sellerDtls, setseller] = useState({});
    const [buyerDtls, setbuyer] = useState({});
    const [valDtls, setvaldtls] = useState({});
    const [dt1, setdt1] = useState('');

    const getpurchasereturndata = () => {

        axios.get(BASE_URL + 'pr/' + id, {
            headers: authHeader()
        }).then(res => {
            console.log("mello", res.data);
            setbranchdata(res.data.branch);
            setdt1(res.data.dt1)
            setwarehousedata(res.data.warehouse);
            setsupplierdata(res.data.supplier);
            setsupplierSubContactsdata(res.data.supplierSubContacts);
            setDate(res.data.purchasereturndate);
            setDate1(res.data.originalinvoicenodate);
            setData(res.data);
            setpurchaseReturnItems(res.data.purchaseReturnItems);

            setseller({ "gstin": res.data.gstin, "lglNm": res.data.lglNm, "trdNm": res.data.trdNm, "addr1": res.data.addr1, "addr2": res.data.addr2, "loc": res.data.loc, "pin": res.data.pin, "stcd": res.data.stcd, "ph": res.data.ph, "em": res.data.em })
            setbuyer({ "gstin": res.data.bgstin, "pos": res.data.pos, "lglNm": res.data.blglNm, "trdNm": res.data.btrdNm, "addr1": res.data.baddr1, "addr2": res.data.baddr2, "loc": res.data.bloc, "pin": res.data.bpin, "stcd": res.data.bstcd, "ph": res.data.bph, "em": res.data.bem })
           setversion(res.data.version)
           setanm({
            "nm": res.data.nm, "val": res.data.val, "assVal": res.data.assVal, "cgstVal": res.data.cgstVal, "sgstVal": res.data.sgstVal, 'igstVal': res.data.igstVal, "cesVal": res.data.cesVal,
            "stCesVal": res.data.stCesVal, "discount": res.data.discount, "othChrg": res.data.othChrg, "rndOffAmt": res.data.rndOffAmt, "totInvVal": res.data.totInvVal, "totInvValFc": res.data.totInvValFc
        });
        setvaldtls({
            "assVal": res.data.assVal, "cgstVal": res.data.cgstVal === 0 ? 0 : (res.data.cgstVal).toFixed(2), "sgstVal": res.data.sgstVal === 0 ? 0 : (res.data.sgstVal).toFixed(2), "igstVal": res.data.igstVal === 0 ? 0 : res.data.igstVal, "cesVal": res.data.cesVal, "stCesVal": res.data.stCesVal,
            "othChrg": res.data.vothChrg, "totInvVal": res.data.totInvVal,
        });
        settyp(res.data.typ);
        setno(res.data.no);
        const date = new Date(res.data.dt);
const day = String(date.getDate()).padStart(2, '0');
const month = String(date.getMonth() + 1).padStart(2, '0'); 
const year = date.getFullYear();

const formattedDate = `${day}/${month}/${year}`;
        setdt(formattedDate);
        settaxSch(res.data.taxSch);
        setsupTyp(res.data.supTyp);
        setregRev(res.data.regRev);
        setigstOnIntra(res.data.igstOnIntra);
        })
        // })
    };
    let date_value = new Date(date);
    const defaultValue = date_value.toLocaleDateString('en-CA');
    let date_value1 = new Date(date1);
    const defaultValue1 = date_value1.toLocaleDateString('en-CA');
    useEffect(() => {
        getpurchasereturndata();
    }, []);
    const getItem = (id) => {
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.isConfirmed) {
            setIsLoading(true); 
            axios.delete( BASE_URL + 'pr/' + id, {
                headers:authHeader()
            }).then(res=>{
                // window.location.reload();
                navigate(`/PurchaseReturnTable/${pointindex}`)
                Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                  )
            }).catch(error => {
                console.error('Error adding  :', error);
                if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `
                    <div>
                      <p id="issueText">Why do I have this issue?</p>
                      <ul>
                  
                      <li id="typer1">You might have an internet issue</li>
                      </ul>
                    </div>
                  `,
                  didOpen: () => {
                    applyFontSizeTransition('issueText');
                  }
                });
              }
              })
          
              .finally(() => {
                setIsLoading(false); 
              });
            
           
          }
        })
      };
    const user = AuthService.getCurrentUser();

    // const generateinvoice = () => {
    //     let attribDtls = [anm];
    //     let tranDtls = { taxSch, supTyp, regRev, igstOnIntra }
    //     const docDtls = { typ, no, dt, }
    //     let da_ta = salesreturnItems.map((item) => ({
    //         "slNo": item.slNo            ,
    //         "prdDesc": item.prdDesc,
    //         "isServc": item.isServc,
    //         "qty": item.qty,
    //         "freeQty": item.freeQty,
    //         "unit": item.unitofmeasurement,
    //         "totAmt": item.totAmt,
    //         "discount": item.discount,
    //         "assAmt": item.assAmt,
    //         "gstRt": item.gstRt,
    //         "igstAmt": Number(item.igstAmt),
    //         "cgstAmt": Number(item.cgstAmt),
    //         "sgstAmt": Number(item.sgstAmt),
    //         "totItemVal": item.total,
    //         "hsnCd": item.product.hsnCode,

    //     }))
    //     let itemList = da_ta;
 
    //     // let ewbDtls = { distance, transId, transName, transDocDt, transDocNo, vehNo, vehType, transMode }
    //      axios.post("http://43.225.54.138:8080/scm/gst/post/encryptedeinvoice/" + id, { version, tranDtls, docDtls, sellerDtls, buyerDtls, itemList, valDtls }, {
    //         headers: authHeader()
    //     }).then(res => {
    //         console.log('mello', res.data)
    //     })
    // }
    const [Loading,   setLoading]=useState(false)

    const generateinvoice = () => {
        setLoading(true)
        let attribDtls = [anm];
        let tranDtls = { taxSch, supTyp, regRev, igstOnIntra }
        const docDtls = { typ, no, dt, }
        let da_ta = purchaseReturnItems.map((item) => ({
            "slNo": item.puritemid            ,
            "prdDesc": item.prdDesc,
            "isServc": item.isServc,
            "qty": item.qty,
            "freeQty": item.freeQty,
            "unit": item.unitofmeasurement,
            "totAmt": item.totAmt,
            "discount": item.discount,
            "assAmt": item.assAmt,
            "gstRt": item.gstRt,
            "igstAmt": Number(item.igstAmt),
            "cgstAmt": Number(item.cgstAmt),
            "sgstAmt": Number(item.sgstAmt),
            "totItemVal": item.total,
            "hsnCd": item.product.hsnCode,

        }))
        let itemList = da_ta;
        // /post/purchaseencryptedeinvoice/
        // let ewbDtls = { distance, transId, transName, transDocDt, transDocNo, vehNo, vehType, transMode }
         axios.post("http://43.225.54.138:8080/scm/gst/post/purchaseencryptedeinvoice/" + id, { version, tranDtls, docDtls, sellerDtls, buyerDtls, itemList, valDtls }, {
            headers: authHeader()
        }).then(res => {
            console.log('mello', res.data)
            Swal.fire({
                title: 'Success!',
                text: ' Generated successfully',
                icon: 'success',
                confirmButtonText: 'OK'
              });
           
              window.location.reload();
        }).finally(() => {
            setLoading(false)
          });
    }
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [show, setShow] = useState(false);
    const [cnlRem, setcnlRem] = useState([])
const [cnlRsn, setcnlRsn] = useState([])
const cancelinvoice=()=>{
    axios.post(`http://43.225.54.138:8080/scm/gst/get/cancelpurchasereturn/${id}`, { 
   "irn":Data.irnno,
   "cnlRsn":Number(cnlRsn),
   "cnlRem":cnlRem
    }, {
      headers: authHeader()
    }).then(res => {
      console.log('mello', res.data)
      
      Swal.fire({
        title: 'Success!',
        text: 'Canceled successfully',
        icon: 'success',
        confirmButtonText: 'OK'
      });
   
      window.location.reload();
   
   
    }) .finally(() => {
      setLoading(false)
    });
    // window.location.reload();

    
   }
    return (
        <MDBCard className="company">
             <Modal  show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Cancel Debit Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        {/* "ewbNo":"241817689082",
"cancelRsnCode":2,
"cancelRmrk":"Cancelled the order" */}
<label className='form-control form-label'>IRN NO</label>

<input type="text" value={Data.irnno} className="form-control"/>
 <label className='form-label'>Cancel Reason code</label>
<input type='number' onChange={(e)=>setcnlRsn(e.target.value)} className='form-control'/>
<label className='form-label'>Cancel Reason</label>
 <textarea className='form-control' onChange={(e)=>setcnlRem(e.target.value)}>

 </textarea>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className='btn-sm' onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" className='btn-sm' onClick={()=>{handleClose();cancelinvoice()}}>
          Cancel Debit Note
          </Button>
        </Modal.Footer>
      </Modal>

            {Data.status == 'converted'?(
                             <div className='d-flex bd-highlight'>
                             <div className="card-header card-title w-100">   <h1>View Purchase Return</h1></div>
                             {/* <Link className='p-2 w-200 card-header card-title bd-highlight' to={`/PurchaseEdit/${id}/${pointindex}`}><Button>Edit</Button></Link> */}
                             <Link className='p-2 w-200 card-header card-title bd-highlight' to={`/PurchaseReturnTable/${pointindex}`}><Button>Back</Button></Link>
                     
                        
                           </div>
            ):(
                <div className='d-flex bd-highlight'>
                <div className="card-header card-title w-100">   <h1>View Purchase Return</h1></div>
                {/* <Link className='p-2 w-200 card-header card-title bd-highlight' to={`/PurchaseEdit/${id}/${pointindex}`}><Button>Edit</Button></Link> */}
                {user.roles[0] === 'ROLE_ADMIN' &&
                //  <a className=' delete  btn' onClick={()=>getItem(id)}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"/><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"/><line x1="10" y1="11" x2="10" y2="17"/><line x1="14" y1="11" x2="14" y2="17"/></svg></a>
            <> 
                          
             { Data.invoice_status == "ACT"?"":    <Link className='p-2 w-200 card-header card-title bd-highlight' variant="primary" style={{width:'auto'}} onClick={()=>getItem(id)} disabled={isLoading}>
        
                <Button className="btn-sm">  {isLoading ? 'Loading...' : 'Delete'}</Button>
              </Link>}
                 
              </>
                 
                 }
        
                <Link className='p-2 w-200 card-header card-title bd-highlight' to={`/PurchaseReturnTable/${pointindex}`}><Button>Back</Button></Link>
                <Link className='p-2 w-200 card-header card-title bd-highlight' to={`/PurchaseReturenUpdate/${id}/${pointindex}`}><Button>Edit</Button></Link>
                { Data.invoice_status == "ACT"?<Link className='p-2 w-200 card-header card-title bd-highlight' variant="primary" style={{ width: 'auto' }} >   <Button  onClick={()=>handleShow()} style={{width:'184px'}}>Cancel Credit Note</Button></Link>
                :""}
                 
                          
{             Data.invoice_status == "InACT"?    <button className="btn btn-sm btn-primary" onClick={()=>generateinvoice()}>E-invoice</button>
:""}
              </div>
            )}
            <Form className="product1">

            

{
              Data.invoice_status == "ACT"?<>
              <div className="row">
              <div className="col-8">
                  <b className="form-control">IRN-{Data.irnno}</b>
              </div>
             
          </div>
          <br></br>
          <div className="row">
              <div className="col ">
              <b className="form-control">ACK NO-{Data.ackno}</b>   
               </div>
              <div className="col ">
              <b className="form-control">ACK Date-{Data.ackdate}</b>    </div>
              <div className="col">
              {/* <b>ACK NO-{Data.ackno}</b> */}
              <b className="form-control">Invoice-Status -{Data.invoice_status}</b>
              </div>
          </div>
          
              </>:""
          }
            {Data.invoice_status=='CNL'?  <b className="form-control form-label">DebitNote-Status-Canceled</b>:""}
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Purchase Return Data</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Branch Name</Form.Label>
                                    <Form.Control type="text" value={branchdata.branchname} readOnly />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">WareHouse Name</Form.Label>
                                    <Form.Control type="text" value={warehousedata.name} readOnly />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Supplier Company</Form.Label>
                                    <Form.Control type="text" value={supplierdata.companyname} readOnly />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Contact Name</Form.Label>
                                    <Form.Control type="text" value={supplierSubContactsdata == null?"":supplierSubContactsdata.contactname} readOnly />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Date</Form.Label>
                                    <Form.Control type="date" value={defaultValue} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Terms of Payment</Form.Label>
                                    <Form.Control type='text' value={Data.paymentTerms} autoComplete="off" />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Purchase Return Note No</Form.Label>
                                    <Form.Control type="text" value={Data.debitnoteno} readOnly />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Ewaybill No</Form.Label>
                                    <Form.Control type="text" value={Data.ewaybillno} readOnly />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Original Invoice No</Form.Label>
                                    <Form.Control type="text" value={Data.originalinvoiceno} readOnly />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Original Invoice Date</Form.Label>
                                    <Form.Control type="date" value={defaultValue1} readOnly />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Other Reference</Form.Label>
                                    <Form.Control type="text" value={Data.otherrefernce} readOnly />
                                </Form.Group>
                                {/* <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Type</Form.Label>
                                    <Form.Select value={Data.type} readOnly>
                                        <option value="b2b">B2B</option>
                                        <option value="b2c">B2C</option>
                                    </Form.Select>
                                </Form.Group> */}
                                {/* <Form.Group as={Col} md="8" controlId="formBasicName">
                                    <Form.Label className="label">Owner</Form.Label>
                                    <Form.Control type="text" autoComplete="off" />
                                </Form.Group> */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Status</Form.Label>
                                    <Form.Select value={Data.status} readOnly>
                                        <option value='true'>Open</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Tax Type</Form.Label>
                                    <Form.Select value={Data.taxtype} readOnly>
                                        <option>--Select--</option>
                                        <option value='withtax'>With Tax</option>
                                        <option value='withouttax'>Without Tax</option>
                                    </Form.Select>
                                </Form.Group>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    {/* <Accordion.Item eventKey="1">
                        <Accordion.Header>Billing Address</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Supplier Address</Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Supplier Details</Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Address : -</Form.Label>
                                    <span>&nbsp;{supplierdata.address}</span> 
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Name : -</Form.Label>
                                    <span>&nbsp;{supplierSubContactsdata.contactname}</span> 
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">City : -</Form.Label>
                                    <span>&nbsp;{supplierdata.cities}</span> 
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Phone No : -</Form.Label>
                                    <span>&nbsp;{supplierSubContactsdata.phoneno}</span> 
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Country : -</Form.Label>
                                    <span>{"India"}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Email Id : -</Form.Label>
                                    <span>{supplierSubContactsdata.email}</span> 
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">State : -</Form.Label>
                                    <span>{supplierstate}</span> 
                                </Form.Group>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item> */}
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>Shipping Address</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="12" controlId="formBasicName">
                                    <Form.Label className="label">Address</Form.Label>
                                    <Form.Control as="textarea" value={Data.shippingaddress} rows={3} name='address' placeholder='Enter Shipping Address' />
                                </Form.Group>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>Details</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="12" controlId="formBasicName">
                                    <Form.Label className="label">Remarks</Form.Label>
                                    <Form.Control as="textarea" value={Data.remarks} rows={3} name='address' placeholder='Enter Remarks' />
                                </Form.Group>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="4">
                        <Accordion.Header>GST Details</Accordion.Header>
                        <Accordion.Body>
                        <Row className="mb-1">
                                <h1>Transport Details</h1>
                                {/* <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Tax Type</Form.Label>
                                    <Form.Control type="text" autoComplete="off" placeholder="Enter Tax Type" name="taxSch" onChange={(e) => settaxSch(e.target.value)} />
                                </Form.Group> */}
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Type of supply:{supTyp}</Form.Label>
 
                                    {/* <Form.Control type="text" autoComplete="off" placeholder="Enter Type of supply" onChange={(e) => setsupTyp(e.target.value)} /> */}
                                </Form.Group>
                                <h1>Document Details</h1>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Document No</Form.Label>
                                    <Form.Control type="text" value={no} disabled autoComplete="off" placeholder="Enter Document No"   />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Document Date</Form.Label>
                                    <Form.Control type="text" disabled value={dt} autoComplete="off"   />
                                </Form.Group>
                                <h1>Seller Details</h1>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">GSTIN of Seller </Form.Label>
                                    <Form.Control type="text" value={[sellerDtls].map((item) => item.gstin)} disabled autoComplete="off"  name="gstin" placeholder="Enter GSTIN of Supplier"   />
                                </Form.Group>
                                {/* value={[setsellindata].map((item) => item.gstin)} disabled */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Legal Name</Form.Label>
                                    <Form.Control type="text" autoComplete="off" value={[sellerDtls].map((item) => item.lglNm)} disabled name="lglNm" placeholder="Enter Legal Name"  />
                                </Form.Group>
                                {/* value={[setsellindata].map((item) => item.lglNm)} disabled  */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Address1</Form.Label>
                                    <Form.Control type="text"  autoComplete="off" value={[sellerDtls].map((item) => item.addr1)} disabled name="addr1" placeholder="Enter Address1"  />
                                </Form.Group>
                                {/* value={[setsellindata].map((item) => item.addr1)} disabled */}
                                {/* <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Address2</Form.Label>
                                    <Form.Control type="text" autoComplete="off" name="addr2" placeholder="Enter Address2"  />
                                </Form.Group> */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Location</Form.Label>
                                    <Form.Control type="text" disabled placeholder="Enter Location"  value={[sellerDtls].map((item) => item.loc)}   autoComplete="off" name="loc"  />
                                </Form.Group>
                                {/* value={[setsellindata].map((item) => item.loc)} disabled */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">PinCode</Form.Label>
                                    <Form.Control type="text" disabled placeholder="Enter PinCode" name="pin" value={[sellerDtls].map((item) => item.pin)}   autoComplete="off"  />
                                </Form.Group>
                                {/* value={[setsellindata].map((item) => item.pin)} disabled */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">State Code</Form.Label>
                                    <Form.Control type="text" disabled placeholder="Enter State Code"  value={[sellerDtls].map((item) => item.stcd)}   autoComplete="off" name="stcd"  />
                                </Form.Group>
                                {/* value={[setsellindata].map((item) => item.stcd)} disabled */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Phone Number</Form.Label>
                                    <Form.Control type="text" disabled placeholder="Enter Phone Number"  value={[sellerDtls].map((item) => item.ph)}   name="ph" autoComplete="off"  />
                                </Form.Group>
                                {/* value={[setsellindata].map((item) => item.ph)} disabled */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Email Id</Form.Label>
                                    <Form.Control type="text" disabled placeholder="Enter Email Id"    value={[sellerDtls].map((item) => item.em)}  name="em" autoComplete="off"  />
                                </Form.Group>
                                {/* disabled value={[setsellindata].map((item) => item.em)} */}
                                <h1>Buyer Details</h1>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">GSTIN of Buyer</Form.Label>
                                    <Form.Control type="text" disabled placeholder="Enter GSTIN of Buyer" value={buyerDtls.gstin || ""} name="gstin" autoComplete="off"  />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Legal Name</Form.Label>
                                    <Form.Control type="text" disabled placeholder="Enter Legal Name" value={buyerDtls.lglNm || ""} name="lglNm" autoComplete="off"  />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">State Code of Place of Supply</Form.Label>
                                    <Form.Control type="text" disabled placeholder="Enter State Code of Place of Supply" value={buyerDtls.pos || ""} name="pos" autoComplete="off"  />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Address1</Form.Label>
                                    <Form.Control type="text" disabled placeholder="Enter Address1" value={buyerDtls.addr1 || ""} name="addr1" autoComplete="off"  />
                                </Form.Group>
                                {/* <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Address2</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Address2" value={buyer.addr2 || ""} name="addr2" autoComplete="off"  />
                                </Form.Group> */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Location</Form.Label>
                                    <Form.Control type="text" disabled placeholder="Enter Location" value={buyerDtls.loc || ""} name="loc" autoComplete="off"  />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">PinCode </Form.Label>
                                    <Form.Control type="text" disabled placeholder="Enter PinCode" value={buyerDtls.pin || ""} name="pin" autoComplete="off"  />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">State Code</Form.Label>
                                    <Form.Control type="text" disabled placeholder="Enter State Code" value={buyerDtls.stcd || ""} name="stcd" autoComplete="off"  />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Phone No</Form.Label>
                                    <Form.Control type="text" disabled placeholder="Enter Phone No" value={buyerDtls.ph || ""} name="ph" autoComplete="off"  />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Email ID</Form.Label>
                                    <Form.Control type="text" disabled placeholder="Enter Email ID" value={buyerDtls.em || ""} name="em" autoComplete="off"  />
                                </Form.Group>
                               
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <br></br>

                <Table striped responsive hover size="sm">
                    <thead className='p-3 mb-2 bg-success text-white'>
                        <tr>
                            <th style={{ width: '20%' }}>PRODUCT&nbsp;NAME</th>
                            <th style={{ width: '9%' }}>HSN&nbsp;Code</th>
                            <th style={{ width: '8%' }}>GST</th>
                            <th style={{ width: '8%' }}>UOM</th>
                            <th style={{ width: '8%' }}>QTY(PCS)</th>
                            <th style={{ width: '8%' }}>QTY(KG)</th>
                            <th style={{ width: '8%' }}>RATE (₹)</th>
                            <th style={{ width: '8%' }}>UOM</th>
                            <th style={{ width: '8%' }}>GROSS AMOUNT(₹)</th>
                            <th style={{ width: '8%' }}>DISCOUNT %</th>
                            <th style={{ width: '8%' }}>NET AMOUNT (₹)</th>
                            {/* <th>Per&nbsp;Unit&nbsp;Rate</th> */}
                            <th style={{ width: '8%' }}>GST VALUE&nbsp;(₹)</th>
                            <th style={{ width: '8%' }}>TOTAL&nbsp;(₹)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                            {/* <th>View</th> */}
                            {/* <th>Action&nbsp;&nbsp;</th> */}
                        </tr>
                    </thead>
                    <tbody style={{ overflow: "scroll" }}>
                        {purchaseReturnItems.map(item =>
                            <tr key={item.product.id}>
                                <td>
                                    <Form.Group as={Col} md="12" >
                                        {/* <Form.Control type="text" value={item.productName} size="sm" /> */}
                                        <textarea type="text" className="form-control textcontrol" value={item.productName} size="sm" ></textarea>

                                    </Form.Group>
                                    <Form.Control as="textarea" value={item.text} rows={2} className='form-control' />
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" size="sm" value={item.hsncode || ""} />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" size="sm" value={item.igst || ""} />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" size="sm" value={item.unitofmeasurement || ""} />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" size="sm" value={item.purquantity || ""} />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" size="sm" value={item.purquantitykgs || ""} />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" size="sm"  value={item.dlp || ""} />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" size="sm" value={item.calunitofmeasurement || ""} />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" size="sm" value={item.grossamount || ""} />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" value={item.tradeDiscount || ''} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text"  value={Number(item.total)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text"  value={Number(item.gstvalue)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text"  value={Number(item.amount)} size="sm" />
                                    </Form.Group>
                                </td>
                                {/* <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type='button' className='btn btn-danger' value="X" size="sm" />
                                    </Form.Group>
                                </td> */}
                            </tr>
                        )}
                    </tbody>
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Packing&nbsp;&&nbsp;Forwarding</td>
                            <td><Form.Group as={Col} md="12">
                                <Form.Control type="number" value={Data.shippingcharge} style={{ height: 30 }} />
                            </Form.Group></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Net&nbsp;Amount</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={Data.grossAmount} style={{ height: 30 }} readOnly />
                                </Form.Group>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>IGST</td>
                            <td><Form.Group as={Col} md="12">
                                <Form.Control type="number" value={Data.igst} requied style={{ height: 30 }} />
                            </Form.Group></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>SGST</td>
                            <td> <Form.Group as={Col} md="12">
                                <Form.Control type="number" value={Data.sgst} requied style={{ height: 30 }} />
                            </Form.Group></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>CGST</td>
                            <td><Form.Group as={Col} md="12">
                                <Form.Control type="number" value={Data.cgst} requied style={{ height: 30 }} />
                            </Form.Group></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Round&nbsp;off&nbsp;Value</td>
                            <td><Form.Group as={Col} md="12">
                                <Form.Control type="number" value={Data.roundvalue} requied style={{ height: 30 }} />
                            </Form.Group></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Grand&nbsp;Total</td>
                            <td><Form.Group as={Col} md="12">
                                <Form.Control type="number" value={Data.grandtotal} readOnly style={{ height: 30 }} />
                            </Form.Group></td>
                            <td></td>
                        </tr>
                    </tbody>
                </Table>
                {/* <Button variant="primary" type="button"  onClick={submitdata}>
            Submit
        </Button> */}
            </Form>
        </MDBCard>
    )
}