import { SALESORDER_EDIT_GET_DATA_TO_LOAD } from "../constant";
import { SALESORDER_EDIT_ADD_TO_CART } from "../constant";
import { CHANGE_SALESORDER_EDIT_QTY } from "../constant";
import { REMOVE_FROM_CART_FROM_SALESORDER_EDIT } from "../constant";
import { CHANGE_COST_SALESORDER_EDIT } from "../constant";
import { CHANGE_SALESORDER_EDIT_DISCOUNT } from "../constant";
import { CHANGE_SALESORDER_EDIT_TEXT } from "../constant";
import { EMPTY_SALESORDER_EDIT_DATA } from "../constant";
import { CHANGE_MEASUREMENT_EDIT_SALESORDER } from "../constant";
import { CHANGE_SALESORDER_EDIT_MEASUREMENT } from "../constant";
import { CHANGE_SALESORDER_SQPB_EDIT_MEASUREMENT } from "../constant";



export const getsalesordereditdatatoload = (data) => {

    //  console.warn("action called", data);
    return {

        type : SALESORDER_EDIT_GET_DATA_TO_LOAD,
        data : data
    }

}


export const salesordereditaddtocart = (data,data2) => {

    //  console.warn("action called", data);
    return {

        type : SALESORDER_EDIT_ADD_TO_CART,
        data : data,
        data2 : data2
    }

}

export const changeunitofMeasurementsalesorderedit = (data, item, data2) => {

    // console.warn("qty change called66", data2);
    return {

        type: CHANGE_MEASUREMENT_EDIT_SALESORDER,
        data: data,
        item: item,
        data2: data2
    }

}


export const removefromcartfromsalesoderedit = (data) => {

    // console.warn("action REMOVE_TO_CART called", data);
    return {

        type : REMOVE_FROM_CART_FROM_SALESORDER_EDIT,
        data : data
    }

}


export const changesalesordereditmeasurement = (data, item, data2, data3) => {
    return {
        type: CHANGE_SALESORDER_EDIT_MEASUREMENT,
        data: data,
        item: item,
        data2: data2,
        data3: data3
    }
}

export const changesalesordereditsqpbmeasurement = (data, item, data2, data3) => {
    return {
        type: CHANGE_SALESORDER_SQPB_EDIT_MEASUREMENT,
        data: data,
        item: item,
        data2: data2,
        data3: data3
    }
}

export const changesalesordereditqty = (data, item, data2,data3) => {

    // console.warn("qty change called", data);
    return {

        type : CHANGE_SALESORDER_EDIT_QTY,
        data : data,
        item : item,
        data2 : data2,
        data3 : data3
    }

}


export const changecostsalesorderedit = (data, item, data2) => {

    // console.warn("cost change called", data);
    return {

        type : CHANGE_COST_SALESORDER_EDIT,
        data : data,
        item : item,
        data2 : data2

    }

}


export const changesalesordereditdiscount = (data, item, data2) => {

    // console.warn("discount change called", data);
    return {

        type : CHANGE_SALESORDER_EDIT_DISCOUNT,
        data : data,
        item : item,
        data2 : data2

    }

}


export const changesalesorderedittext = (data, item) => {

    // console.warn("text change called", data);
    return {

        type : CHANGE_SALESORDER_EDIT_TEXT,
        data : data,
        item : item
    }

}


export const emptysalesordereditdata = () => {

    return {

        type : EMPTY_SALESORDER_EDIT_DATA,
    }

}