import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// import { BASE_URL } from '../Component/services/Api';
import authHeader from '../../services/auth-header';
import QRCode from 'react-qr-code';
 import { useBarcode } from 'next-barcode';
import { useSelector } from 'react-redux';

const Print = () => {
  const data12 = useSelector(state => state.APrint1aprintcartonred.data);
console.log(data12)
  const { id } = useParams();
  const[data,setdata]=useState([])
  useEffect(() => {
    document.getElementById("qerteww").style.display = "none";
    document.getElementById("sidein").style.display = "none";

    const elements = ["naving", "1sidebar", "qw", "swift", "logoo", "logoo1", "menu"];
    elements.forEach(elementId => {
      const element = document.getElementById(elementId);
      if (element) {
        element.classList.add('hidden');
      }
    });

    const newElement = document.getElementById("new");
    if (newElement) {
      newElement.classList.add('fullWidth');
    }
  }, []);
  // const [currentTime, setCurrentTime] = useState('');
  const [imageSrc, setImageSrc] = useState(null);
  const [imageWidth, setImageWidth] = useState(null);
  const [imageHeight, setImageHeight] = useState(null);
  const [currentTime, setCurrentTime] = useState('');
// const [galaseriesname, setGalaseriesname] = useState([])
const [qrcode, setQrcode] = useState([])

const { inputRef } = useBarcode({
  value:[data12].map(item => item.galaseriesname), 
  options: {
    background: '#ffffff',
    fontSize: 20,
    margin: 30, 
     displayValue: false,
    fontOptions: "bold",
    width: 1,
    height:70
  }
});
const [eanCode, setEanCode] = useState([])

   useEffect(() => {
 setdata([data12])

// setdata(localStorage.getItem("responseData"))



const now = new Date();
const hours = String(now.getHours()).padStart(2, '0');
const minutes = String(now.getMinutes()).padStart(2, '0');
setCurrentTime(`${hours}:${minutes}`);


// const productname= data.map(item => item.product.eanCode).join(', ')
// const mrp=data.map(item => item.pcs).join(', ')
// const galaseriesname=data.map(item => item.galaseriesname).join(', ')
// setGalaseriesname(galaseriesname)

 setEanCode(`${productname}&${mrp}kg==${mrp}kg &${galaseriesname}`);

 }, [ ])
 const handlePrint = () => {
  window.print();
  // setTimeout(() => {
  //   window.location.href = '/dashboard';  
  // }, 1000);
};

const handleprint12=()=>{
  window.location.href = '/CartonBarCode/Table/1';
}
 console.log( data)

// console.log(data.map(item => item.galaseriesname)[0])
// const tyt=data.map(item => item.galaseriesname)[0]
// alert(tyt)
 
 
// galaseriesname
// console.log([productname,mrp].join(', '))
// console.log(qrCodeValue)
const productname= data.map(item => item.product.eanCode).join(', ')
const mrp=data.map(item => item.pcs).join(', ')
const galaseriesname=data.map(item => item.galaseriesname).join(', ')

const val=`${productname}&${mrp}kg==${mrp}kg &${galaseriesname}`
// console.log(data)
const formatDate = (timestamp) => {
  const date = new Date(timestamp);

 const day = date.getDate().toString().padStart(2, '0');
const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
const year = date.getFullYear();

 return `${day}/${month}/${year}`;
};

   return (
    <>   <button className='btnmedia' onClick={handlePrint}>Print</button>
     <button className='btnmedia' onClick={handleprint12}>Back</button>
 <div id="print" >
  {/* <QRCode
    size={256}
    style={{ height: "auto",  width: "17mm" }}
    value={qrCodeValue}
        viewBox={`0 0 256 256`}
  /> */}
        <div className="column" style={{ padding: '11px', marginLeft: '10px',background:"white",border: '0.5px solid black', borderRadius: '1px', marginTop: '8px', width: '100mm',height:'125mm' }}>
          <div className=''>
            <div className='row'>
              <div className='col' style={{ borderBottom: '1px solid', padding: '8px' }}>
                <img src='https://d3k81ch9hvuctc.cloudfront.net/company/SdRp39/images/b1fbeef6-74ab-49f1-8bb4-3da89443aa48.png' alt="Uploaded" style={{ width: '54mm',position:'relative', top: '10px', left: '21px' }} />
              </div>
              <div className='col-3' style={{ borderBottom: '1px solid', padding: '8px' }}>
              <QRCode
    size={256}
    style={{ height: "auto",  width: "17mm" }}
    value={val}
        viewBox={`0 0 256 256`}
  />
                {/* <img src='https://randomqr.com/assets/images/rickroll-qrcode.webp' alt="Uploaded" style={{ width: '19mm' }} /> */}
              </div>
            </div>

            <div className='row'  >
           <div className='col' style={{   padding: '8px', lineHeight: '56px',   fontSize: '33px',    fontWeight: '700',border: '0.5px solid black',height: '127px'}}>
           <h2 style={{ fontWeight: '700', lineHeight: '34px'}}>{data.map((item)=>item.product.productName)}</h2>
              {/* <h2 style={{ fontWeight: '700'}}></h2> */}
              {/* <hr className='hr3'></hr> */}
           </div>
            </div>
            {/* <br></br> */}
<div className='row'>
 <div className='col-7 border'>
 <div style={{height: '54px',overflow:'hidden'}}>
<yy className="yyzindex ontsize" style={{ position: 'relative',fontSize: '15px',marginLeft: '8px'}}>U.ID:</yy>

 <canvas className='barcodeshail' ref={inputRef} /> 
 {/* <Barcode
className="Barcode"
        value='G1000007'
        style={{ height: '138px', padding: '5px' }}
      /> */}
      {/* <dd>G1000007</dd> */}
      {/* </div> */}
     
</div>
<erwe>{galaseriesname}</erwe>
{/* <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSGFcuOaNusOnIhx64aW6g1RHTLePONzgNsWViBX_crWg&s' alt="Uploaded" style={{     width: '38mm',padding: '5px' }} /> */}

<div className='row'style={{    borderTop: '2px solid'}}>
  <div className='col-4 border-right'style={{borderRight: '2px solid'}}>
  <mym> Units:</mym>
  <div className='row'style={{    borderTop: '2px solid'}}>
  <div className='col-4  'style={{    textAlign: 'center'}} >
  <yy style={{    textAlign: 'center',fontSize:'24px'}}>{data.map((item)=>item.stdqty)}</yy><br></br>
  <yy style={{    textAlign: 'center',fontSize:'24px'}}> PCS</yy>
  </div>
  </div>
  <div className='row'style={{    borderTop: '2px solid'}}>
  <div className='col   'style={{    textAlign: 'center'}} >
  <mym style={{  textAlign: 'left',position: 'relative',left:' -8px'
  }}>Operator:</mym><br></br>
  <mym style={{    }}>{data.map((item)=>item.operatorname)}</mym>
  </div>
  </div>
  </div>
  <div className='col'>
  <mym> Net Weight (KGS):</mym>
  <div className='row'style={{ height: '74px',   borderTop: '2px solid'}}>
  <div className='col-4  'style={{    textAlign: 'center'}} >
  <yy style={{    textAlign: 'center',fontSize:'33px'}}>{data.map((item)=>item.netweight.toFixed(3))}</yy> 
  {/* <yy style={{    textAlign: 'center',fontSize:'21px'}}> PCS</yy> */}
  </div>
  </div>
  <div className='col'>
  
  <div className='row'style={{    textAlign: 'center',  borderTop: '2px solid'}}>
  <div className='col   'style={{    }} >
  <mym style={{    textAlign: 'center'}}>Brand Name:</mym> <br></br>
  <mym style={{    textAlign: 'center'}}>Neelam</mym> 

  {/* <yy style={{    textAlign: 'center',fontSize:'21px'}}> PCS</yy> */}
  </div>
  </div>
  
  </div>
  </div>
</div>
 </div>
  <div className='col border' style={{textAlign:'center'}}>
  <yy>MRP.PER BOX:</yy><br></br>
  <yy style={{textAlign:'center',fontSize:'20px'}}>{Number(data.map((item)=>item.product.mrp)*data.map((item)=>item.stdqty)).toFixed(2)}</yy>


  <div className='row'style={{    borderTop: '2px solid'}}>
  
  <div className='col'style={{marginTop: '0px'}} >
  <mym> Gross Weight (KGS):</mym>
  <div className='row'style={{    borderTop: '2px solid',borderBottom: '2px solid'}}>
  <div className='col-4  'style={{    textAlign: 'center'}} >
  <yy style={{    textAlign: 'center',fontSize:'15px'}}> { Number(data.map((item)=>item.emptymasterctnweight+item.netweight)).toFixed(2)}(Approx)</yy><br></br>
   </div>
  </div>
  <div className='row'style={{   }}>
  <div className='col  'style={{    textAlign: 'center',borderRight:'1px solid'}} >
  <yy style={{    textAlign: 'left',fontSize:'15px'}}>Date:</yy><br></br>
  <yy style={{    textAlign: 'left',fontSize:'10px',position: 'relative',top: '-7px'}}>{formatDate(data.map((item)=>item.createddate))}</yy>
   </div>
   <div className='col-5'style={{    textAlign: 'center'}} >
   <yy style={{    textAlign: 'center',fontSize:'15px'}}>Time:</yy><br></br>
   <yy style={{    textAlign: 'left',fontSize:'10px',position: 'relative',top: '-7px'}}>{currentTime}</yy>

   </div>
  </div>
  <div className='row'style={{    borderTop: '2px solid',position:'relative',top:'-2px'}}>
  <div className='col   'style={{    }} >
  <mym style={{    textAlign: 'center'}}>L&nbsp;*&nbsp;B&nbsp;*&nbsp;H&nbsp;(INCH)</mym> <br></br>
  <mym style={{    textAlign: 'center',fontSize:'10px'}}>{data.map((item)=>item.length.toFixed(2))}&nbsp;x&nbsp;{data.map((item)=>item.width.toFixed(2))}&nbsp;x&nbsp;{data.map((item)=>item.height.toFixed(2))}</mym> 

  {/* <yy style={{    textAlign: 'center',fontSize:'21px'}}> PCS</yy> */}
  </div>
  </div>

  </div>
</div>

 </div> 
 <yy   style={{textAlign:'center',fontSize:'16px',position: 'relative',top: '9px'}}> This Lable is for Internal Use Only.</yy>

</div>

{/* <div className='row'>
  <div className='col-2'>
   <mym> Units:</mym>
  </div>
  <div className='col-5'>
   <mym> Net Weight (KGS):</mym>
  </div>
  <div className='col-5'>
  <mym> Gross Weight (KGS):</mym>
  </div>
</div> */}
{/* <table style={{width: '364px', marginLeft: '-12px'}}>
  <tr>
    <td className='htrt'>Units:</td>
    <td className='htrt'> Net Weight (KGS):</td>
    <td className='htrt'>Gross Weight (KGS):</td>
  </tr>
</table> */}
{/* <tyt>This Label is for Internal Use Only</tyt> */}


          

          </div>
        </div>
      </div> 
    </>
  );
}

export default Print;
