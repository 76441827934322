import { MDBCard, MDBCardBody } from 'mdb-react-ui-kit'
import { MDBDataTable } from 'mdbreact'
import React, { useEffect, useState } from 'react'
import { CSVLink } from 'react-csv'
import BrandService from '../../services/Brand'
import { FaUserEdit } from 'react-icons/fa'
import { AiOutlineDelete } from 'react-icons/ai'
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal';
import axios from 'axios'
import authHeader from '../../services/auth-header'
import Select from "react-select";
import AuthService from '../../services/auth.service'
import { BASE_URL } from '../../services/Api'

// import Select from 'react-select/dist/declarations/src/Select'
// import Select from 'react-select/dist/declarations/src/Select'

const OpenCreate = () => {  const [isLoading, setIsLoading] = useState(false);
  const applyFontSizeTransition = (elementId) => {
    const element = document.getElementById(elementId);
    element.style.transition = 'font-size 2s';
    element.style.fontSize = '30px';
    element.style.fontWeight = '600';
    // font-weight: 600;
    setTimeout(() => {
       
      document.getElementById("typer1").classList.add('promote');
      element.style.fontSize = '24px';
    }, 1000);
  };
  const [show, setShow] = useState(false);
  const [error, setError] = useState(encodeURIComponent(' '))
  const [disword, setdisword] = useState(encodeURIComponent(' '))
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [posts, setPosts] = useState([]);
  const [datacsv, setcsv] = useState([])
  const [plus, setplus] = useState([])
  const [product2, setproduct2] = useState([])
  const [distributor1, setdistributordata] = useState([])
  const [quantity, setquantity] = useState([])
  const [user_date, setuser_date] = useState([])
  const [distributor, setdistributor] = useState([])
  const [product, setproduct] = useState([])
  const[dist_roles,setdist_roles]=useState('');

   const currentuser = AuthService.getCurrentUser();
 
  const getItem = (id) => {

    
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        BrandService.getDelete(id).then(res => {



          setplus(1 + plus)




          // window.location.reload();
        })
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        )
      }
    })


  };

  const handlesupersubmit = () => {
    const postData = {
      user_date: user_date,
      quantity: quantity,
      product: {
        id: product
      },
      distributor: {
        id: distributor
      }
    };





    // const headers = authHeader();
    setIsLoading(true); 

    axios.post('http://43.225.54.138:8080/scm/api/distributor/openingstock/post', postData, { headers: authHeader() })
      .then(response => {
        console.log('POST request successful');
        console.log('Response:', response.data);
        window.location.reload();
      })
      .catch(error => {
        console.error('Error adding  :', error);
        if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `
            <div>
              <p id="issueText">Why do I have this issue?</p>
              <ul>
          
              <li id="typer1">You might have an internet issue</li>
              </ul>
            </div>
          `,
          didOpen: () => {
            applyFontSizeTransition('issueText');
          }
        });
      }
      })
      .finally(() => {
        setIsLoading(false); 
      });

  }

  function getdistributorbyroles(){
    axios.get(BASE_URL + 'distributor/getById/' + currentuser.id,{headers:authHeader()}).then(res=>{
      setdist_roles(res.data.tradeName)
      setdistributor(res.data.id)

    })
  }
  useEffect(() => {
    {currentuser.roles[0] == 'ROLE_DISTRIBUTOR' && getdistributorbyroles()}

    axios.get(`http://43.225.54.138:8080/scm/api/product/page/0/10/asc/id/${error.length?error:encodeURIComponent(' ')}`, { headers: authHeader() })
      .then(response => {

        if (response.data.Index==undefined) {
          setproduct2(response.data.data.map(use => ({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code })));

        } if(response.data.data==undefined) {
          setproduct2(response.data.Index.map(use => ({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code })));

        }


        console.log(response)
      })
      .catch(error => {
        console.error(error);
      });

    axios.get(`http://43.225.54.138:8080/scm/api/distributor/page/0/10/asc/id/${disword.length?disword:encodeURIComponent(' ')}`, { headers: authHeader() })
      .then(response => {

        if (response.data.Index==undefined) {
          setdistributordata(response.data.data.map(use => ({ value: use.id, label: use.trade_Name })));

        } if(response.data.data==undefined) {
          setdistributordata(response.data.Index.map(use => ({ value: use.id, label: use.trade_Name })));
        }
        console.log(response)
      })
      .catch(error => {
        console.error(error);
      });



  }, [error, disword,]);


  const data = () => {
    return {
      columns: [

        {
          label: 'NO',
          field: 'index',
          width: 100,
        },

        {
          label: 'Brand Name',
          field: 'name',
          width: 150,
          attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'username',
          },

        },
        {
          label: 'Action',
          field: 'update',
          sort: 'disabled',
          width: 100,
        },


      ],
      rows: posts
    };
  };

  const disid = (val) => {
    console.log(val)
    setdistributor(val)
  }

  const productid = (val) => {
    console.log(val)
    setproduct(val)
  }








  return (
    <div>



      <div className='d-flex bd-highlight'>
        <div className='p-2 w-100 bd-highlight'> <Link  > <button onClick={handleShow} class="btn btn-sm btn-primary">Add DOS</button></Link></div>
        <div className='p-2 flex-shrink-1 bd-highlight'><CSVLink data={datacsv} class="btn btn-sm btn-primary">Export</CSVLink></div>  </div>




      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className='col'>
              <label className='form-label'>Distributor</label>
              {currentuser.roles[0] == 'ROLE_DISTRIBUTOR'?<input type='text' className='form-control' value={dist_roles} readOnly />
              :<Select options={distributor1} onInputChange={(inputValue) => setdisword(inputValue)} onChange={(options) => disid(options.value)} />}
              

            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <label className='form-label'>Product</label>
              {/* <select className='form-select' onChange={(e)=>setError(e.target.value)} >
    <option>Select Product</option>
 </select> */}
              <Select options={product2} onInputChange={(inputValue) => setError(inputValue)} onChange={(options) => productid(options.value)} />

              {/* <input type='text' className='form-control'  onChange={(e)=>setError(e.target.value)}  /> */}
            </div>
          </div>

          <div className='row'>
            <div className='col'>
              <label className='form-label' >Date</label>
              <input type='Date' className='form-control' onChange={(e) => setuser_date(e.target.value)} />
            </div>
            <div className='col'>
              <label className='form-label'>Qty</label>
              <input type='number' className='form-control' onChange={(e) => setquantity(e.target.value)} />
            </div>
          </div>



        </Modal.Body>
        <Modal.Footer>
          <a className="btn btn-sm btn-secondary" variant="secondary" onClick={handleClose}>
            Close
          </a>
          <Button variant="primary" style={{width:'auto'}} onClick={() => { handleClose(); handlesupersubmit() }} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Submit'}
        </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default OpenCreate