import React from 'react'
import Myreportcomponent from './Myreportcomponent'
// import RetailerReport from './RetailerReport'

const DistributorReport = () => {

    const data = [{ "label": "Distributor", "id": "distid", "type": "select" },
    { "label": "Start Date", "id": "startDate", "type": "Date" },
    { "label": "End Date", "id": "endDate", "type": "Date" }]





  return (
     <Myreportcomponent
     title={"Distributor Report"}
     data={data}
    axiostable={'distributor'} 
    tradeName={'tradeName'}
    link={'DistributorId'}
     />
  )
}

export default DistributorReport