import { DELIVERYCHALLAN_GET_DATA_TO_LOAD } from "../constant";
import { DELIVERYCHALLAN_ADD_TO_CART } from "../constant";
import { CHANGE_DELIVERYCHALLAN_MEASUREMENT } from "../constant";
import { DELIVERYCHALLAN_SQPB_MEASUREMENT } from "../constant";
import { DELIVERYCHALLAN_SQPBKG_MEASUREMENT } from "../constant";
import { REMOVE_FROM_CART_FROM_DELIVERYCHALLAN } from "../constant";
import { CHANGE_COST_DELIVERYCHALLAN } from "../constant";
import { CHANGE_DELIVERYCHALLAN_DISCOUNT } from "../constant";
import { CHANGE_DELIVERYCHALLAN_TEXT } from "../constant";
import { EMPTY_DELIVERYCHALLAN_DATA } from "../constant";
import { DELIVERYCHALLAN_CHANGE_CAL_MEASUREMENT } from "../constant";



export const getdeliverychallandatatoload = (data,data2) => {

    //  console.warn("action called", data);
    return {

        type : DELIVERYCHALLAN_GET_DATA_TO_LOAD,
        data : data,
        data2:data
    }

}

export const deliverychallanaddtocart = (data, data2) => {

    //  console.warn("action called", data2);
    return {

        type: DELIVERYCHALLAN_ADD_TO_CART,
        data: data,
        data2: data2
    }

}

export const changeunitofMeasurementdeliverychallan = (data, item, data2) => {

    // console.warn("qty change called66", data2);
    return {

        type: CHANGE_DELIVERYCHALLAN_MEASUREMENT,
        data: data,
        item: item,
        data2: data2
    }

}

export const removefromcartfromdeliverychallan = (data) => {

    // console.warn("action REMOVE_TO_CART called", data);
    return {

        type : REMOVE_FROM_CART_FROM_DELIVERYCHALLAN,
        data : data
    }

}



export const changedeliverychallansqpbqty = (data, item, data2,data3) => {

    // console.warn("qty change called", data);
    return {

        type : DELIVERYCHALLAN_SQPB_MEASUREMENT,
        data : data,
        item : item,
        data2 : data2,
        data3 : data3
    }

}

export const changedeliverychallansqpbqtykg = (data, item, data2, data3) => {

    return {
        type : DELIVERYCHALLAN_SQPBKG_MEASUREMENT,
        data : data,
        item : item,
        data2 : data2,
        data3 : data3
    }
}


export const changecostdeliverychallan = (data, item, data2) => {

    // console.warn("cost change called", data);
    return {

        type : CHANGE_COST_DELIVERYCHALLAN,
        data : data,
        item : item,
        data2 : data2

    }

}

export const deliverychallancalmeasurement = (data, item, data2 ) => {

    return{
        type: DELIVERYCHALLAN_CHANGE_CAL_MEASUREMENT,
        data: data,
        item: item,
        data2: data2
    }
}

export const changedeliverychallandiscount = (data, item, data2) => {

    // console.warn("discount change called", data);
    return {

        type : CHANGE_DELIVERYCHALLAN_DISCOUNT,
        data : data,
        item : item,
        data2 : data2

    }

}


export const changedeliverychallantext = (data, item) => {

    // console.warn("text change called", data);
    return {

        type : CHANGE_DELIVERYCHALLAN_TEXT,
        data : data,
        item : item
    }

}


export const emptydeliverychallandata = () => {

    return {

        type : EMPTY_DELIVERYCHALLAN_DATA,
    }

}