import axios from "axios";
import { MDBCard } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { BASE_URL } from "../../services/Api";
import authHeader from "../../services/auth-header";
import Swal from "sweetalert2";

export default function Create() {
  const [title, settitle] = useState('');
  const [group1, setgroup1] = useState('');
  const [group1data, setgroup1data] = useState([]);
  const [group2, setgroup2] = useState('');
  const [group2data, setgroup2data] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [disword, setdisword] = useState(encodeURIComponent(' '));
  /** Navigation */
  const navigate = useNavigate();

  const applyFontSizeTransition = (elementId) => {
    const element = document.getElementById(elementId);
    element.style.transition = 'font-size 2s';
    element.style.fontSize = '30px';
    element.style.fontWeight = '600';
    // font-weight: 600;
    setTimeout(() => {

      document.getElementById("typer1").classList.add('promote');
      element.style.fontSize = '24px';
    }, 1000);
  };

  let Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })


  /** get Group1 & Group2 */
  useEffect(() => {
    /** Group1*/
    axios.get(BASE_URL + `group1/page/0/10/asc/id/${disword.length ? disword : encodeURIComponent(' ')}`, {
      headers: authHeader()
    }).then(res => {
      if (res.data.data == undefined) {
        setgroup1data(res.data.Index.map(use => ({ value: use.id, label: use.title })));
      } if (res.data.Index == undefined) {
        setgroup1data(res.data.data.map(use => ({ value: use.id, label: use.title })));

      }
    });

    /** Group2*/
    axios.get(BASE_URL + `group2/page/0/10/asc/id/${disword.length ? disword : encodeURIComponent(' ')}`, {
      headers: authHeader()
    }).then(res => {
      if (res.data.data == undefined) {
        setgroup2data(res.data.Index.map(use => ({ value: use.id, label: use.title })));
      } if (res.data.Index == undefined) {
        setgroup2data(res.data.data.map(use => ({ value: use.id, label: use.title })));

      }
    });
  }, [disword])

  /** Group1 Change*/
  const group1change = (e) => {
    setgroup1({ "id": e.value })
  }
  /** Group2 Change*/
  const group2change = (e) => {
    setgroup2({ "id": e.value })
  }

  const submitdata = () => {

    setIsLoading(true);

    axios.post(BASE_URL + 'group3/save', { group1, group2, title }, {
      headers: authHeader()
    }).then(res => {
      navigate('/Group3Table/1');
      if (res.status == 201 || res.status == 200) {
        
        Toast.fire({
          icon: 'success',
          title: 'Successfully added'
        })
      }
      
    }).catch(error => {

      if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `
                  <div>
                    <p id="issueText">Why do I have this issue?</p>
                    <ul>
                
                    <li id="typer1">You might have an internet issue</li>
                    </ul>
                  </div>
                `,
          didOpen: () => {
            applyFontSizeTransition('issueText');
          }
        });
      } else {
        Swal.fire({
          title: 'This Product is already Created',
          text: "You won't be able to Add!",
          icon: 'warning',
        })

      }
    })
      .finally(() => {
        setIsLoading(false);
      });
  }
  return (
    <MDBCard className="company">
      <h1>Create Group3</h1>
      <Form className="product1">
        <Row className="mb-1">
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="label">Group1</Form.Label>
            <Select options={group1data} onChange={(e) => group1change(e)} onInputChange={(inputValue) => setdisword(inputValue)} />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="label">Group2</Form.Label>
            <Select options={group2data} onChange={(e) => group2change(e)} onInputChange={(inputValue) => setdisword(inputValue)} />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="label">Title</Form.Label>
            <Form.Control type="text" name="title" onChange={(e) => settitle(e.target.value)} placeholder="Enter Title" />
          </Form.Group>
        </Row>
        <Button variant="primary" style={{ width: 'auto' }} onClick={submitdata} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Submit'}
        </Button>
      </Form>
    </MDBCard>
  )
}