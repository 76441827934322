import React, { useEffect } from "react";
import { GoogleMap,useLoadScript,MarkerF, useJsApiLoader } from "@react-google-maps/api";
import { useState } from "react";
import { useRef } from "react";
import { useParams } from "react-router-dom";
import StaffService from "../../services/StaffService";
export default function Map(){
    const {id} = useParams();
// const[selectedLocation,setselectedLocation]=useState({
//   lat:19.028461,
//   lng:72.851631
// });
const[latitude,setLatitude]=useState('');
const[longitude,setlongitude]=useState('');
  // const {isLoaded,loadError} = useLoadScript({});
  const { isLoaded,loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyAw-D12JcvRDetmdvsZFXPu79V4gWMudio"
  })
  const mapRef = useRef();
  const onMapLoad = React.useCallback((map)=>{
    mapRef.current = map;
  },[]);

  useEffect(()=>{
     StaffService.getmapgetdata(id).then(res=>{
      setLatitude(parseFloat(res.data.checkinLocationLatitude));
      setlongitude(parseFloat(res.data.checkinLocationLongitude));
      const redirectToGoogleMaps = () => {
        const url = `https://www.google.com/maps/search/?api=1&query=${res.data.checkinLocationLatitude},${res.data.checkinLocationLongitude}`;
        window.open(url, '_blank');
      };
   redirectToGoogleMaps()
    })

   
    
  },[]);
  if(loadError) return "Error";
  if(!isLoaded) return isLoaded;
  return(
    <div style={{margin:"10px"}}>
      {/* <GoogleMap
      mapContainerStyle={{height:"700px"}}
      center={{lat:latitude,lng:longitude}}
      zoom={22}
      onLoad={onMapLoad}
       >
       <MarkerF
        position={{lat:latitude,lng:longitude}}
       />
      </GoogleMap> */}
      
    </div>
  )
}