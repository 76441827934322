import React from 'react'
import { Mydatatable } from '../Product/Mydatatable';

const Newwarehouse = () => {

    let header = [
        { "label": "Id", "field": "id" },
        { "label": "Location", "field": "location" },
    
        { "label": "  Name  ", "field":"name" },
        // { "label": "delivery_location", "field": "delivery_location" },
        { "label": "Type", "field": "type" },
        { "label": "Created Date", "field": "createddate" },
        { "label": "Created Time", "field": "createdtime" },
    //  { "label": "Remarks", "field": "remarks" },
    { "label": "Created By", "field": "createbyname" },
    { "label": "Updated Date", "field": "updateddate" },
    { "label": "Updated Time", "field": "updatedtime" },
    { "label": "Updated By", "field": "updatedbyname" },
        { "label": "Action", "field": "action","subaction1":<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"/><circle cx="12" cy="12" r="3"/></svg> }
      ]; 


    const sumofkeys=[{"first":'grandtotal'} ]

    // http://43.225.54.138:8080/scm/api/warehouse/page/0/2/desc/id/
    // <Route path="/wareEdit/:id/:pointindex" element={<WareEdit />} />
    // <Route path="/wareview/:id/:pointindex" element={<WareView />} />
  return (

    // id
    // : 
    // 1
    // location
    // : 
    // "mumbai"
    // name
    // : 
    // "Matunga"
    // type
    // : 
    // "1"

    <Mydatatable
    head={header}
    title={"Warehouse"}
    axiostabledata={'warehouse'}
    Add={"Add warehouse"}
    Create={'/wareCreate'}
    Edit={"/wareview"}
    View={"/wareview"}
    Sumofkeys={sumofkeys}
    />


    )
}

export default Newwarehouse