import { SALES_RETURN_UPDATE_DATA_LOAD } from "../constant";
import { SALES_RETURN_UPDATE_AAD_TO_CART } from "../constant";
import { SALES_RETURN_UPDATE_REMOVE_FROM_CART } from "../constant";
import { SALES_RETURN_UPDATE_MEASUREMENT } from "../constant";
import { SALES_RETURN_UPDATE_PCS } from "../constant";
import { SALES_RETURN_UPDATE_QTY } from "../constant";
import { SALES_RETURN_UPDATE_COST } from "../constant";
import { SALES_RETURN_UPDATE_CALMEASUREMENT } from "../constant";
import { SALES_RETURN_UPDATE_DISCOUNT } from "../constant";
import { SALES_RETURN_UPDATE_TEXT } from "../constant";

export const salesreturnupdategetdata = (data) => {
    return {

        type : SALES_RETURN_UPDATE_DATA_LOAD,
        data : data
    }
};

export const salesreturnupdateaddtocart = (data,data2) => {
    return {

        type : SALES_RETURN_UPDATE_AAD_TO_CART,
        data : data,
        data2:data2
    }
};

export const removefromcartfromsalesreturnupdate = (data) => {

    // console.warn("action REMOVE_TO_CART called", data);
    return {

        type : SALES_RETURN_UPDATE_REMOVE_FROM_CART,
        data : data
    }

};

export const salesreturnupdatemeasurement = (data, item, data2) => {

    return {
        type: SALES_RETURN_UPDATE_MEASUREMENT,
        data: data,
        item: item,
        data2: data2
    }
};

export const salesreturnupdateqtypcs = (data, item, data2) => {


    return {

        type: SALES_RETURN_UPDATE_PCS,
        data: data,
        item: item,
        data2: data2
    }

};

export const salesreturnupdateqtykgs = (data, item, data2) => {

    return {
        type: SALES_RETURN_UPDATE_QTY,
        data: data,
        item: item,
        data2: data2

    }
};

export const changecostsalesreturnupdate = (data, item, data2) => {

    // console.warn("cost change called", data);
    return {

        type : SALES_RETURN_UPDATE_COST,
        data : data,
        item : item,
        data2 : data2

    }

};

export const salesreturnupdatecalmeasurement = (data, item, data2) => {

    return {
        type: SALES_RETURN_UPDATE_CALMEASUREMENT,
        data: data,
        item: item,
        data2: data2
    }
};


export const changesalesreturnupdatediscount = (data, item, data2) => {

    // console.warn("discount change called", data);
    return {

        type : SALES_RETURN_UPDATE_DISCOUNT,
        data : data,
        item : item,
        data2 : data2

    }

};

export const changesalesreturnupdatetext = (data, item) => {

    // console.warn("text change called", data);
    return {

        type : SALES_RETURN_UPDATE_TEXT,
        data : data,
        item : item
    }

}