import axios from "axios";
import { MDBCard } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { BASE_URL } from "../../services/Api";
import authHeader from "../../services/auth-header";
import Select from "react-select";

export default function Update(){
    const {id} = useParams();
    const[title,settitle] = useState('');
    const[group1,setgroup1] = useState('');
    const[group1data,setgroup1data] = useState([]);
    const [group1_data,setgroup1_data] = useState([]);
    const [disword, setdisword] = useState(encodeURIComponent(' '))

    const [isLoading, setIsLoading] = useState(false);
    const applyFontSizeTransition = (elementId) => {
      const element = document.getElementById(elementId);
      element.style.transition = 'font-size 2s';
      element.style.fontSize = '30px';
      element.style.fontWeight = '600';
      // font-weight: 600;
      setTimeout(() => {
         
        document.getElementById("typer1").classList.add('promote');
        element.style.fontSize = '24px';
      }, 1000);
    };
    const navigate = useNavigate();
    let Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

      const getdata = () => {
        axios.get(BASE_URL + 'group2/getById/'+id,{
            headers:authHeader()
        }).then(res=>{
            console.warn(res.data)
            settitle(res.data.title);
            setgroup1({"id":res.data.group1.id})
            setgroup1data({value:res.data.group1.id,label:res.data.group1.title})
        })
    };


          /** get Group1 */
          useEffect(()=>{
            axios.get(BASE_URL + `group1/page/0/10/asc/id/${disword.length?disword:encodeURIComponent(' ')}`,{
                headers:authHeader()
            }).then(res=>{
                if(res.data.data==undefined){
                    setgroup1_data(res.data.Index.map(use => ({value:use.id,label:use.title})));
                 }  if(res.data.Index==undefined){
                    setgroup1_data(res.data.data.map(use => ({value:use.id,label:use.title})));
                
                 }
                
            })
          },[disword])

          

    useEffect(()=>{
        getdata();
    },[]);
    const group1change = (e) =>{
        setgroup1({"id":e.value});
        setgroup1data({value:e.value,label:e.label})
    };

    const submitdata = () => {
        setIsLoading(true); 
        axios.put(BASE_URL + 'group2/update/'+id,{title,group1},{
            headers:authHeader()
        }).then(res=>{
            navigate('/Group2Table/1');
            if(res.status==201||res.status==200){
              // alert("ee")
              Toast.fire({
                icon: 'success',
                title: 'Successfully Updated'
              })
            }
        }).catch(error=>{
              
            if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `
                  <div>
                    <p id="issueText">Why do I have this issue?</p>
                    <ul>
                
                    <li id="typer1">You might have an internet issue</li>
                    </ul>
                  </div>
                `,
                didOpen: () => {
                  applyFontSizeTransition('issueText');
                }
              });
            }else{
              Swal.fire({
                  title: 'This Product is already Created',
                  text: "You won't be able to Add!",
                  icon: 'warning',
                })
          
            }
          })
          .finally(() => {
          setIsLoading(false); 
          });
    }
    return(
        <MDBCard className="company">
           <h1>Group2 Update</h1>
           <Form className="product1">
            <Row className="mb-1">
            <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Group1</Form.Label>
                        <Select value={group1data} options={group1_data} onChange={(e)=>group1change(e)} onInputChange={(inputValue) => setdisword(inputValue)}/>
                    </Form.Group>
                <Form.Group as={Col} md="6" controlId="formBasicName">
                    <Form.Label className="label">Title</Form.Label>
                    <Form.Control type="text" name="title" value={title} onChange={(e) => settitle(e.target.value)} placeholder="Enter Title" />
                </Form.Group>
                </Row>
                <Button variant="primary" style={{width:'auto'}} onClick={submitdata} disabled={isLoading}>
      {isLoading ? 'Loading...' : 'Submit'}
    </Button>
        </Form>
        </MDBCard>
    )
}