import { SALES_EDIT_GET_DATA_TO_LOAD } from "../constant";
import { SALES_EDIT_ADD_TO_CART } from "../constant";
import { CHANGE_SALES_EDIT_MEASUREMENT } from "../constant";
import { REMOVE_FROM_CART_FROM_SALES_EDIT } from "../constant";
import { CHANGE_SALES_EDIT_SQPB_MEASUREMENT } from "../constant";
import { CHANGE_COST_SALES_EDIT } from "../constant";
import { CHANGE_SALES_EDIT_DISCOUNT } from "../constant";
import { CHANGE_SALES_EDIT_TEXT } from "../constant";
import { EMPTY_SALES_EDIT_DATA } from "../constant";
import { CHANGE_SALES_EDIT_CAL_MEASUREMENT } from "../constant";
import { CHANGE_SALES_EDIT_SQPBKG_MEASUREMENT } from "../constant";



export const getsaleseditdatatoload = (data) => {

    //  console.warn("action called", data);
    return {

        type: SALES_EDIT_GET_DATA_TO_LOAD,
        data: data
    }

}


export const saleseditaddtocart = (data, data2) => {

    //  console.warn("action called", data2);
    return {

        type: SALES_EDIT_ADD_TO_CART,
        data: data,
        data2: data2
    }

}

export const changeunitofMeasurementsalesedit = (data, item, data2) => {

    // console.warn("qty change called66", data2);
    return {

        type: CHANGE_SALES_EDIT_MEASUREMENT,
        data: data,
        item: item,
        data2: data2
    }

}

export const removefromcartfromsalesedit = (data) => {

    // console.warn("action REMOVE_TO_CART called", data);
    return {

        type: REMOVE_FROM_CART_FROM_SALES_EDIT,
        data: data
    }

}

export const changesaleseditsqpbmeasurement = (data, item, data2, data3) => {
    return {
        type: CHANGE_SALES_EDIT_SQPB_MEASUREMENT,
        data: data,
        item: item,
        data2: data2,
        data3: data3
    }
}


export const changesaleseditsqpbkgmeasurement = (data, item, data2, data3) => {
    return {
        type: CHANGE_SALES_EDIT_SQPBKG_MEASUREMENT,
        data: data,
        item: item,
        data2: data2,
        data3: data3
    }
}


export const changecostsalesedit = (data, item, data2) => {

    // console.warn("cost change called", data);
    return {

        type: CHANGE_COST_SALES_EDIT,
        data: data,
        item: item,
        data2: data2

    }

}

export const changecalunitofMeasurementsalesedit = (data, item, data2) => {

    // console.warn("qty change called66", data2);
    return {

        type: CHANGE_SALES_EDIT_CAL_MEASUREMENT,
        data: data,
        item: item,
        data2: data2
    }

}

export const changesaleseditdiscount = (data, item, data2) => {

    // console.warn("discount change called", data);
    return {

        type: CHANGE_SALES_EDIT_DISCOUNT,
        data: data,
        item: item,
        data2: data2

    }

}


export const changesalesedittext = (data, item) => {

    // console.warn("text change called", data);
    return {

        type: CHANGE_SALES_EDIT_TEXT,
        data: data,
        item: item
    }

}


export const emptysaleseitdata = () => {

    return {

        type: EMPTY_SALES_EDIT_DATA,
    }

}