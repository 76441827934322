import axios from 'axios';
import React, { useEffect, useState } from 'react'
import authHeader from '../Component/services/auth-header';

const InvoiceFormat = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading1, setIsLoading1] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState([]);
  const [data22, setdata22] = useState([])
const[dis,setsdis]=useState([])
const[csub,setsub]=useState([])
const [jsonData,setjsondata1]=useState([])


  console.log(jsonData.length);

  useEffect(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const updatedPageData = jsonData.slice(startIndex, endIndex);
    setCurrentPageData(updatedPageData);
  }, [currentPage]);

  let totalPages = Math.ceil(jsonData.length / 14);


  console.log(totalPages);
  // console.log(jsonData);


  useEffect(() => {

    axios.get(`http://43.225.54.138:8080/scm/api/sales/1`, { headers: authHeader() })
      .then(response => {
        setdata22(response.data)
        // console.log(`http://43.225.54.138:8080/scm/api/product/page/${Number(getid)}/${Number(limit)}/${sort}/${fieldnme}`);
        console.log(response.data);
        setsdis(response.data.distributor)
setsub(response.data.customersubContacts)
        const extractedData = response.data.salesItems.map((item) => ({
          no: item.id,
          AllQtApprox: item.salesaltqty,
          Qty: item.sales_quantity,
          check:item.product.productType,
          Dis: item.schemeDiscount,
          DisAmt: item.discountamount,
          Dis1: item.tradediscount,
          DisAmt1: item.discountamount1,
          GrossAmt: item.grossamount,
          Per: item.product.productType,
          Amount: item.amount,
          DescriptionofGoods:item.product.productName,
          EANCode:item.product.eanCode,
          HSNSAC:item.product.hsnCode,
          GStRate:item.igst,
          Rate:item.mrp, 
           uomSecondary:item.product.uomSecondary
        }));



        console.log(extractedData);
        setjsondata1(extractedData);








      })
      .catch(error => {
        console.error(error);
      });


    //     document.getElementById("naving").style.display = "none";
    //     document.getElementById("1sidebar").style.display = "none";
    //     document.getElementById("qw").style.display = "none";
    //     document.getElementById("swift").style.display = "none";
    //     document.getElementById("logoo").style.display = "none";
    //     document.getElementById("logoo1").style.display = "none";
    //    document.getElementById("menu").style.display = "none";
    //     document.getElementById("new").style.width = "100%";
    //     document.getElementById("new").style.marginLeft = "1px";
    //     document.getElementById("new").style.padding = "1px";

    const elements = ["naving", "1sidebar", "qw", "swift", "logoo", "logoo1", "menu"];

    elements.forEach(elementId => {
      const element = document.getElementById(elementId);
      if (element) {
        element.classList.add('hidden');
      }
    });

    const newElement = document.getElementById("new");
    if (newElement) {
      newElement.classList.add('fullWidth');
    }
  }, [])
  let removedElements
  let itemsPerPage = 14;
  const paragraphs = [];
  let i = 0;
  for (i; i < totalPages; i++) {
    let startIndex;
    if (i == 0) {
      itemsPerPage = 12;
    } else {
      itemsPerPage = 14;
    }
    let endIndex;
    let roman;
    let jsonDataSlice;
    // let remainingItems
    let lastPageDataSize


    // console.log(totalPages);

    startIndex = i * itemsPerPage;
    endIndex = Math.min((i + 1) * itemsPerPage, jsonData.length);
    jsonDataSlice = jsonData.slice(startIndex, endIndex);


    if (totalPages === i + 1) {
      let lastPageIndex = totalPages - 1;
      let lastPageStartIndex = lastPageIndex * itemsPerPage;
      let lastPageEndIndex = Math.min((lastPageIndex + 1) * itemsPerPage, jsonData.length);
      lastPageDataSize = lastPageEndIndex - lastPageStartIndex;

      if (lastPageDataSize > 6) {
        let newidea = lastPageDataSize - 6;
        console.log(newidea);
        jsonDataSlice = jsonData.slice(startIndex, endIndex - newidea);
        removedElements = jsonData.slice(endIndex - newidea, endIndex);
        console.log(removedElements);
      }


      if (totalPages == 1 && i == 0 && lastPageDataSize > 6) {
        let newidea = lastPageDataSize - 6;
        console.log(newidea);
        jsonDataSlice = jsonData.slice(startIndex, endIndex - newidea);
        removedElements = jsonData.slice(endIndex - newidea, endIndex);
        console.log(removedElements);
      }


    }


    // startIndex = i * itemsPerPage;
    // endIndex = Math.min((i + 1) * itemsPerPage, jsonData.length);
    // jsonDataSlice = jsonData.slice(startIndex, endIndex);










    paragraphs.push(<p className='jpmorgon' key={i}><div className='border1'>



      <div className='row   center'>
        <div className='col'>
          <div className='border2 fontprint'>   NEELAM APPLIANCES LIMITED</div>
        </div>
      </div>

      {i === 0 ? <>
        <div className='row    '>
          <div className='col  '>
            <div className='border2 space22 fontprint1'>IRN NO. :{data22.irnno}</div>
          </div>
        </div>
        <div className='row    '>
          <div className='col  '>
            <div className='border2 space22 fontprint1'>ACK NO. :{data22.ackno}</div>
          </div>
        </div>
        <div className='row    '>
          <div className='col  '>
            <div className='border2 space22 fontprint1'>ACK Date :{data22.ackdate}</div>
          </div>
        </div>
        <div className='row    '>
          <div className='col  '>
            <div className='border2 space22 fontprint1'>GOODS DISPATCHED FROM Neelam House, Fafadia Indl. Estate, Golani Naka, Waliv Village, Vasai East. Dist: Palghar. PIN- 401208.</div>
          </div>
        </div>
        <div className='row    '>
          <div className='col  '>
            <div className='border2 space22 fontprint1'>UDYAM No: {data22.udyamno}&nbsp;&nbsp;&nbsp; MSME No: {data22.msmeno}&nbsp;&nbsp;&nbsp; TAX INVOICE PAN No: {data22.panno}&nbsp;&nbsp;&nbsp; GST No:{data22.gstno}.</div>
          </div>
        </div>
      </> : <> </>}

      <div className='row  '>
        <div className='col-6 '>
          <div className='border2 ertrt12'>
          <font>Delivery Note No :{data22.deliverynoteno} </font>&nbsp;
                    <font1>Date : {data22.ackdate}</font1></div>
        </div>
        <div className='col-6 borderl border2'>
          <div ><font>Invoice No:    </font>:<font1>{data22.invoiceno}</font1>  <font>  Date:    </font><font1> {data22.invoicedate}</font1>  </div>

        </div>
      </div>

      {/* ///////////////////////// */}
      <div className='row  '>
        <div className='col-6 '>
          <div className='border2 ertrt12'>
            <font>Packing List No : {data22.packaginglistno} </font>&nbsp;
            <font1>Total PKGS : 23</font1></div>
        </div>
        <div className='col-6 borderl border2'>
          <div ><font>E-way BIll No:    </font>:<font1>{data22.ewaybillno}</font1>  <font>  Date:    </font><font1>{data22.invoicedate}</font1>  </div>

        </div>
      </div>
      <div className='row border2 '>
        <div className='col-6 '>
        <div className='  ertrt12  '><font>Buyer’s Order No :{data22.buyerorderno} </font>&nbsp;&nbsp;<font1>Date : {data22.ackdate}</font1></div>
        </div>
        <div className='col-6 borderl  '>
          <div ><font>Despatch Through   </font>:<font1>{data22.dispatchedthrough}</font1> &nbsp;&nbsp; <font>  Destination:    </font><font1> {data22.destination}</font1>  </div>

        </div>
      </div>
      <div className='row  '>
        <div className='col-6 '>
          <div className='border2 ertrt12'>
          <font>GRN No : {data22.grnno}   </font>&nbsp; &nbsp; &nbsp;
                    <font1>Date : {data22.ackdate} </font1></div>
        </div>
        <div className='col-6 borderl border2'>
          <div ><font>L.R. No:    </font>:<font1> </font1> &nbsp; &nbsp; &nbsp; <font>  Date:    </font><font1>  </font1>  </div>

        </div>
      </div>
      {/* ///////////////////// */}

      <div className='row '>
        <div className='col'>
        <font className="border2 ertrt12">TERMS OF DELIVERY :{data22.termsofdelivery}</font>
        </div>
      </div>
      <div className='row  '>
        <div className='col-6 '>
          <div className='border2 ertrt12'><font>DELIVERY ADDRESS :</font></div>
        </div>
        <div className='col-6 borderl border2'>
          <div ><font>BILLING ADDRESS :</font></div>

        </div>
      </div>

      <div className='row  '>
        <div className='col-6 '>
          <div className='border2 ertrt12 probook'>
            <font>NAME:</font><font1>{dis.tradeName}</font1><br></br>
            {(() => {
              if (i === 0) {
                return (
                  <>
                    <font>Address:</font>
                    <font1>{dis.deliveryAddress}</font1>
                    <br />
                    <font>State Name :</font>
                    <font1>West Bengal</font1>
                    <br />
                    <font>GSTIN/UIN : </font>
                    <font1>{dis.gstNumber}</font1>
                    <br />
                    <font>PAN/IT No : </font>
                    <font1>{dis.panNumber}</font1>
                    <br />
                    <font>Contact Person : </font>
                    <font1>{dis.perMobileNumber},{dis.alterMobileNumber},{csub.phoneno}</font1><br></br>
                    <br />
                    <font>Email :</font>
                    <font1>{dis.email1}{dis.email2}</font1>
                    <br />
                  </>
                );
              }
              return null;
            })()}
          </div>
        </div>
        <div className='col-6 borderl border2'>
          <div className=' ertrt12 probook'>
            <font>NAME:</font><font1>{dis.tradeName}</font1><br></br>
            {(() => {
              if (i === 0) {
                return (
                  <>  <font>Address:</font><font1>{dis.billingAddress}</font1><br></br>
                    <font>State Name :</font><font1>West Bengal</font1><br></br>
                    <font>GSTIN/UIN : </font><font1>{dis.gstNumber}</font1><br></br>
                    <font>PAN/IT No : </font><font1>{dis.panNumber}</font1><br></br>

                    <font1>{dis.perMobileNumber},{dis.alterMobileNumber},{csub.phoneno}</font1><br></br>
                    <font> Email :</font><font1>{dis.email1}{dis.email2}</font1><br></br>
                  </>
                );
              }
              return null;
            })()}
          </div>

        </div>
      </div>
      <table id='rowcount' className='  bordered'>

        <tr className='thback'>
          <th className='thback' width="3%">Sr No.</th>
          <th className='thback' width="auto">Description of Goods <span className='white'>Goodsssssssssssssssssss</span></th>
          <th className='thback' width="3%">HSN/SAC</th>
          <th className='thback' width="3%">GST Rate</th>
          <th className='thback' width="3%">All Qt Approx</th>
          <th className='thback' width="3%">Qty</th>
          <th className='thback' width="3%">Rate</th>
          <th className='thback' width="3%">Per</th>
          <th className='thback' width="3%">Gross Amt</th>
          <th className='thback' width="3%">Dis%</th>
          <th className='thback' width="3%">Dis Amt</th>
          <th className='thback' width="3%">Dis%</th>
          <th className='thback' width="3%">Dis Amt</th>
          <th className='thback' width="3%">Amount</th>
        </tr>

        {

        }

        <tbody className='height'>
          {
            jsonDataSlice.map((item, index) => {
              return <tr>
                <td className=' thback34'>{startIndex + index + 1}</td>
                <td className=' thback34 ggggggg'><p>{item.DescriptionofGoods}</p><p>EAN Code : {item.EANCode}</p>
                  <p>L12 X W12 X H11 ={item.length1}</p>
                  <p>Unique ID :&nbsp;{item.UniqueID}</p>

                </td>
                <td className=' thback34'>{item.HSNSAC}</td>
                <td className=' thback34'>{item.GStRate}</td>
                {item.check=="kgproduct" ? <td className=' thback34'>{item.Qty*item.uomSecondary}</td> : <td className=' thback34'></td>}
                <td className=' thback34'>{item.Qty}</td>
                <td className=' thback34'>{item.Rate}</td>
                <td className=' thback34'>{item.Per}</td>
                <td className=' thback34'>{item.GrossAmt}</td>
                <td className=' thback34'>{item.Dis}</td>
                <td className=' thback34'>{item.DisAmt}</td>
                <td className=' thback34'>{item.Dis1}</td>
                <td className=' thback34'>{item.DisAmt1}</td>
                <td className=' thback34'>{item.Amount}</td>
              </tr>
            })

          }







        </tbody>


      </table>




      {totalPages === i + 1 && lastPageDataSize > 6 ? <p className='jpmorgon' key={i}></p> : ""}

      {(() => {
        if (totalPages === i + 1 && lastPageDataSize > 6) {
          return (
            <>

              <div className='row  '>
                <div className='col-6 '>
                  <div className='border2 ertrt12'>
                    <font>Delivery Note No : {data22.deliverynoteno} </font>&nbsp;
                    <font1>Date : {data22.ackdate}</font1></div>
                </div>
                <div className='col-6 borderl border2'>
                <div ><font>Invoice No:    </font>:<font1> {dis.invoiceno}</font1>  <font>  Date:    </font><font1> {dis.invoicedate}</font1>  </div>

                </div>
              </div>

              {/* ///////////////////////// */}
              <div className='row  '>
                <div className='col-6 '>
                  <div className='border2 ertrt12'>
                    <font>Packing List No : {data22.packaginglistno}  </font>&nbsp;
                    <font1>Total PKGS : 23</font1></div>
                </div>
                <div className='col-6 borderl border2'>
                  <div ><font>E-way BIll No:    </font>:<font1>{data22.ewaybillno}</font1>  <font>  Date:    </font><font1>{data22.invoicedate}</font1>  </div>

                </div>
              </div>
              <div className='row border2 '>
                <div className='col-6 '>
                <div className='  ertrt12  '><font>Buyer’s Order No :{data22.buyerorderno} </font>&nbsp;&nbsp;<font1>Date : {data22.ackdate}</font1></div>
                </div>
                <div className='col-6 borderl  '>
                  <div ><font>Despatch Through   </font>:<font1> {data22.dispatchedthrough} </font1>&nbsp;&nbsp;<font>  Destination: {data22.destination}   </font><font1> </font1>  </div>

                </div>
              </div>
              <div className='row  '>
                <div className='col-6 '>
                  <div className='border2 ertrt12'>
                  <font>GRN No : {data22.grnno}   </font>&nbsp; &nbsp; &nbsp;
                    <font1>Date : {data22.ackdate} </font1></div>
                </div>
                <div className='col-6 borderl border2'>
                  <div ><font>L.R. No:    </font>:<font1> </font1> &nbsp; &nbsp; &nbsp; <font>  Date:    </font><font1>  </font1>  </div>

                </div>
              </div>
              {/* ///////////////////// */}

              <div className='row '>
                <div className='col'>
                  <font className="border2 ertrt12">TERMS OF DELIVERY :{data22.termsofdelivery}</font>
                </div>
              </div>
              <div className='row  '>
                <div className='col-6 '>
                  <div className='border2 ertrt12'><font>DELIVERY ADDRESS :</font></div>
                </div>
                <div className='col-6 borderl border2'>
                  <div ><font>BILLING ADDRESS :</font></div>

                </div>
              </div>

              <div className='row  '>
                <div className='col-6 '>
                <div className='border2 ertrt12 probook'>
            <font>NAME:</font><font1>{dis.tradeName}</font1><br></br>
            {(() => {
              if (i === 0) {
                return (
                  <>
                    <font>Address:</font>
                    <font1>{dis.deliveryAddress}</font1>
                    <br />
                    <font>State Name :</font>
                    <font1>West Bengal</font1>
                    <br />
                    <font>GSTIN/UIN : </font>
                    <font1>{dis.gstNumber}</font1>
                    <br />
                    <font>PAN/IT No : </font>
                    <font1>{dis.panNumber}</font1>
                    <br />
                    <font>Contact Person : </font>
                    <font1>{dis.perMobileNumber},{dis.alterMobileNumber},{csub.phoneno}</font1><br></br>
                    <br />
                    <font>Email :</font>
                    <font1>{dis.email1}{dis.email2}</font1>
                    <br />
                  </>
                );
              }
              return null;
            })()}
          </div>
                </div>
                <div className='col-6 borderl border2'>
                <div className='  ertrt12 probook'>
            <font>NAME:</font><font1>{dis.tradeName}</font1><br></br>
            {(() => {
              if (i === 0) {
                return (
                  <>
                    <font>Address:</font>
                    <font1>{dis.deliveryAddress}</font1>
                    <br />
                    <font>State Name :</font>
                    <font1>West Bengal</font1>
                    <br />
                    <font>GSTIN/UIN : </font>
                    <font1>{dis.gstNumber}</font1>
                    <br />
                    <font>PAN/IT No : </font>
                    <font1>{dis.panNumber}</font1>
                    <br />
                    <font>Contact Person : </font>
                    <font1>{dis.perMobileNumber},{dis.alterMobileNumber},{csub.phoneno}</font1><br></br>
                    <br />
                    <font>Email :</font>
                    <font1>{dis.email1}{dis.email2}</font1>
                    <br />
                  </>
                );
              }
              return null;
            })()}
          </div>

                </div>
              </div>
              <table id='rowcount' className='  bordered'>

                <tr className='thback'>
                  <th className='thback' width="3%">Sr No.</th>
                  <th className='thback' width="auto">Description of Goods <span className='white'>Goodsssssssssssssssssss</span></th>
                  <th className='thback' width="3%">HSN/SAC</th>
                  <th className='thback' width="3%">GST Rate</th>
                  <th className='thback' width="3%">All Qt Approx</th>
                  <th className='thback' width="3%">Qty</th>
                  <th className='thback' width="3%">Rate</th>
                  <th className='thback' width="3%">Per</th>
                  <th className='thback' width="3%">Gross Amt</th>
                  <th className='thback' width="3%">Dis%</th>
                  <th className='thback' width="3%">Dis Amt</th>
                  <th className='thback' width="3%">Dis%</th>
                  <th className='thback' width="3%">Dis Amt</th>
                  <th className='thback' width="3%">Amount</th>
                </tr>

                {

                }

                <tbody className='height'>
                  {
                    removedElements.map((item, index) => {
                      return <tr>
                        <td className=' thback34'>{jsonData.length - index}</td>
                        <td className=' thback34 ggggggg'><p>{item.DescriptionofGoods}</p><p>EAN Code : {item.EANCode}</p>
                          <p>L12 X W12 X H11 ={item.length1}</p>
                          <p>Unique ID :&nbsp;{item.UniqueID}</p>

                        </td>
                        <td className=' thback34'>{item.HSNSAC}</td>
                        <td className=' thback34'>{item.GStRate}</td>
                        {item.check=="kgproduct" ? <td className=' thback34'>{item.Qty*item.uomSecondary}</td> : <td className=' thback34'></td>}

                        <td className=' thback34'>{item.Qty}</td>
                        <td className=' thback34'>{item.Rate}</td>
                        <td className=' thback34'>{item.Per}</td>
                        <td className=' thback34'>{item.GrossAmt}</td>
                        <td className=' thback34'>{item.Dis}</td>
                        <td className=' thback34'>{item.DisAmt}</td>
                        <td className=' thback34'>{item.Dis1}</td>
                        <td className=' thback34'>{item.DisAmt1}</td>
                        <td className=' thback34'>{item.Amount}</td>
                      </tr>
                    })

                  }







                </tbody>


              </table>
            </>
          )
        }

        if (totalPages === 1 && i == 0 && lastPageDataSize > 6) {
          return (
            <><p className='jpmorgon'></p>

              <div className='row  '>
                <div className='col-6 '>
                  <div className='border2 ertrt12'>
                    <font>Delivery Note No :{data22.deliverynoteno} </font>&nbsp;
                    <font1>Date : {data22.ackdate}</font1></div>
                </div>
                <div className='col-6 borderl border2'>
                  <div ><font>Invoice No:    </font>:<font1> {dis.invoiceno}</font1>  <font>  Date:    </font><font1> {dis.invoicedate}</font1>  </div>

                </div>
              </div>

              {/* ///////////////////////// */}
              <div className='row  '>
                <div className='col-6 '>
                  <div className='border2 ertrt12'>
                    <font>Packing List No : {data22.packaginglistno}  </font>&nbsp;
                    <font1>Total PKGS : 23</font1></div>
                </div>
                <div className='col-6 borderl border2'>
                  <div ><font>E-way BIll No:    </font>:<font1>{data22.ewaybillno}</font1> &nbsp; <font>  Date:    </font><font1> {data22.invoicedate}</font1>  </div>

                </div>
              </div>
              <div className='row border2 '>
                <div className='col-6 '>
                  <div className='  ertrt12  '><font>Buyer’s Order No :{data22.buyerorderno} </font>&nbsp;&nbsp;<font1>Date : {data22.ackdate}</font1></div>
                </div>
                <div className='col-6 borderl  '>
                  <div ><font>Despatch Through   </font>:<font1> {data22.dispatchedthrough} </font1>  <font>  Destination: {data22.destination}   </font><font1> </font1>  </div>

                </div>
              </div>
              <div className='row  '>
                <div className='col-6 '>
                  <div className='border2 ertrt12'>
                    <font>GRN No : {data22.grnno}   </font>&nbsp; &nbsp; &nbsp;
                    <font1>Date : {data22.ackdate} </font1></div>
                </div>
                <div className='col-6 borderl border2'>
                  <div ><font>L.R. No:    </font>:<font1> </font1> &nbsp; &nbsp; &nbsp; <font>  Date:    </font><font1>  </font1>  </div>

                </div>
              </div>
              {/* ///////////////////// */}

              <div className='row '>
                <div className='col'>
                <font className="border2 ertrt12">TERMS OF DELIVERY :{data22.termsofdelivery}</font>
                </div>
              </div>
              <div className='row  '>
                <div className='col-6 '>
                  <div className='border2 ertrt12'><font>DELIVERY ADDRESS :</font></div>
                </div>
                <div className='col-6 borderl border2'>
                  <div ><font>BILLING ADDRESS :</font></div>

                </div>
              </div>

              <div className='row  '>
                <div className='col-6 '>
        <div className='border2 ertrt12 probook'>
            <font>NAME:</font><font1>{dis.tradeName}</font1><br></br>
            {(() => {
              if (i === 0) {
                return (
                  <>
                    <font>Address:</font>
                    <font1>{dis.deliveryAddress}</font1>
                    <br />
                    <font>State Name :</font>
                    <font1>West Bengal</font1>
                    <br />
                    <font>GSTIN/UIN : </font>
                    <font1>{dis.gstNumber}</font1>
                    <br />
                    <font>PAN/IT No : </font>
                    <font1>{dis.panNumber}</font1>
                    <br />
                    <font>Contact Person : </font>
                    <font1>{dis.perMobileNumber},{dis.alterMobileNumber},{csub.phoneno}</font1><br></br>
                    <br />
                    <font>Email :</font>
                    <font1>{dis.email1}{dis.email2}</font1>
                    <br />
                  </>
                );
              }
              return null;
            })()}
          </div>
                </div>
                <div className='col-6 borderl border2'>
                <div className='  ertrt12 probook'>
            <font>NAME:</font><font1>{dis.tradeName}</font1><br></br>
            {(() => {
              if (i === 0) {
                return (
                  <>
                    <font>Address:</font>
                    <font1>{dis.deliveryAddress}</font1>
                    <br />
                    <font>State Name :</font>
                    <font1>West Bengal</font1>
                    <br />
                    <font>GSTIN/UIN : </font>
                    <font1>{dis.gstNumber}</font1>
                    <br />
                    <font>PAN/IT No : </font>
                    <font1>{dis.panNumber}</font1>
                    <br />
                    <font>Contact Person : </font>
                    <font1>{dis.perMobileNumber},{dis.alterMobileNumber},{csub.phoneno}</font1><br></br>
                    <br />
                    <font>Email :</font>
                    <font1>{dis.email1}{dis.email2}</font1>
                    <br />
                  </>
                );
              }
              return null;
            })()}
          </div>

                </div>
              </div>
              <table id='rowcount' className='  bordered'>

                <tr className='thback'>
                  <th className='thback' width="3%">Sr No.</th>
                  <th className='thback' width="auto">Description of Goods <span className='white'>Goodsssssssssssssssssss</span></th>
                  <th className='thback' width="3%">HSN/SAC</th>
                  <th className='thback' width="3%">GST Rate</th>
                  <th className='thback' width="3%">All Qt Approx</th>
                  <th className='thback' width="3%">Qty</th>
                  <th className='thback' width="3%">Rate</th>
                  <th className='thback' width="3%">Per</th>
                  <th className='thback' width="3%">Gross Amt</th>
                  <th className='thback' width="3%">Dis%</th>
                  <th className='thback' width="3%">Dis Amt</th>
                  <th className='thback' width="3%">Dis%</th>
                  <th className='thback' width="3%">Dis Amt</th>
                  <th className='thback' width="3%">Amount</th>
                </tr>

                {

                }

                <tbody className='height'>
                  {
                    removedElements.map((item, index) => {
                      return <tr>
                        <td className=' thback34'>{jsonData.length + index}</td>
                        <td className=' thback34 ggggggg'><p>{item.DescriptionofGoods}</p><p>EAN Code : {item.EANCode}</p>
                          <p>L12 X W12 X H11 ={item.length1}</p>
                          <p>Unique ID :&nbsp;{item.UniqueID}</p>

                        </td>
                        <td className=' thback34'>{item.HSNSAC}</td>
                        <td className=' thback34'>{item.GStRate}</td>
                        {item.check=="kgproduct" ? <td className=' thback34'>{item.Qty*item.uomSecondary}</td> : <td className=' thback34'></td>}

                        <td className=' thback34'>{item.Qty}</td>
                        <td className=' thback34'>{item.Rate}</td>
                        <td className=' thback34'>{item.Per}</td>
                        <td className=' thback34'>{item.GrossAmt}</td>
                        <td className=' thback34'>{item.Dis}</td>
                        <td className=' thback34'>{item.DisAmt}</td>
                        <td className=' thback34'>{item.Dis1}</td>
                        <td className=' thback34'>{item.DisAmt1}</td>
                        <td className=' thback34'>{item.Amount}</td>
                      </tr>
                    })

                  }







                </tbody>


              </table>
            </>
          )
        }



      })()}


      {(() => {
        if (totalPages === i + 1) {
          return (
            <>
              <table id='rowcount' className='  bordered'>
                <tr className='rede'>
                  <th className='newhite' width="3%">Sr No.</th>
                  <th className='newhite' width="auto">Description of Goods <span className='white'>Goodsssssssssssssssssss</span></th>
                  <th className='newhite' width="3%">HSN/SAC</th>
                  <th className='newhite' width="3%">GST Rate</th>
                  <th className='newhite' width="3%">All Qt Approx</th>
                  <th className='newhite' width="3%">Qty</th>
                  <th className='newhite' width="3%">Rate</th>
                  <th className='newhite' width="3%">Per</th>
                  <th className='newhite' width="3%">Gross Amt</th>
                  <th className='newhite' width="3%">Dis%</th>
                  <th className='newhite' width="3%">Dis Amt</th>
                  <th className='newhite' width="3%">Dis%</th>
                  <th className='newhite' width="3%">Dis Amt</th>
                  <th className='newhite' width="3%">Amount</th>
                </tr>

                <tbody className='height'>







                  <tr className='thback  bordert'>
                    <td className=' thback1'> </td>
                    <td className=' thback1' >IGST OUTPUT Rounding Off </td>
                    <td className=' thback1'> </td>
                    <td className=' thback1'> </td>
                    <td className=' thback1'> </td>
                    <td className=' thback1'></td>
                    <td className=' thback1'></td>
                    <td className=' thback1'></td>
                    <td className=' thback1'></td>
                    <td className=' thback1'></td>
                    <td className=' thback1'></td>
                    <td className=' thback1'></td>
                    <td className=' thback1'></td>
                    <td className=' thback1'>5,234.00<br></br>(-)0.49</td>
                  </tr>
                  <tr className='thback  bordert'>
                    <td className=' thback1'> </td>
                    <td className=' thback1'>Total</td>
                    <td className=' thback1'> </td>
                    <td className=' thback1'> </td>
                    <td className=' thback1'>1,122 </td>
                    <td className=' thback1'>1,212.50</td>
                    <td className=' thback1'></td>
                    <td className=' thback1'></td>
                    <td className=' thback1'>5,96,897.70</td>
                    <td className=' thback1'></td>
                    <td className=' thback1'>2,80,806.74</td>
                    <td className=' thback1'></td>
                    <td className=' thback1'>24,059.27</td>
                    <td className=' thback1'>3,27,075.00</td>
                  </tr>
                </tbody>
              </table>
              <div className='row     bordert'>
                <div className='col'>
                  <div className='  fontprint'>  Amount Chargeable (In Words):<br></br>
                    Indian Rupees Three Lakh Twenty Seven Thousand Seventy Five</div>
                </div>
              </div>
              <div className='border1 row'>
                <div className='col-8 center '>
                  <font>HSN/SAC </font>
                </div>
                <div className='col borderl'>
                  <font>Tax Value </font>
                </div>
                <div className='col borderl'>
                  <font>Igst%</font>
                </div>
                <div className='col borderl'>
                  <font>Igst Amount</font>
                </div>
                <div className='col borderl right2'>
                  <font>Total<span className='smallingfont'>(Taxamount)</span></font>
                </div>
              </div>

              <div className='border1 row'>
                <div className='col-8 center '>
                  <font>73239390</font>
                </div>
                <div className='col borderl'>
                  <font>1,38,178.0</font>
                </div>
                <div className='col borderl'>
                  <font>12%</font>
                </div>
                <div className='col borderl'>
                  <font>16,581.36</font>
                </div>
                <div className='col borderl right2'>
                  <font>16,581.36</font>
                </div>
              </div>
              <div className='border1 row'>
                <div className='col-8 right '>
                  <font>Total</font>
                </div>
                <div className='col borderl'>
                  <font>1,38,178.00</font>
                </div>
                <div className='col borderl'>
                  <font> </font>
                </div>
                <div className='col borderl'>
                  <font>16,581.36</font>
                </div>
                <div className='col borderl right2'>
                  <font>16,581.36</font>
                </div>
              </div>
              <div className='row     bordert'>
                <div className='col-2'>
                  <div className='  fontprint'>    </div>
                </div>
                <div className='col  '>
                  <div className='  fontprint borderl border2'>Company’s Bank Details </div>
                  <div className='  fontprint  borderl'><font>Bank Name</font>:<font1>HDFC BANK LTD</font1></div>
                  <div className='  fontprint  borderl'><font>A/C No.</font>:<font1>00382790000064</font1></div>
                  <div className='  fontprint  borderl'><font>Branch Name</font>:<font1>EVERSHINECITY, VASAI EAST</font1></div>

                  <div className='  fontprint borderl border2'><font>IFSC Code</font>:<font1>HDFC0000038</font1></div>
                  <div className='  fontprint borderl '><font>Bank Instruction</font>:<font1> </font1></div>


                </div>
              </div>
              <div className='row bordert '>
                <div className='col-6 '>
                  <div className='border2 ertrt12'><font>DELIVERY ADDRESS :</font></div>
                </div>
                <div className='col-6 borderl  '>
                  {/* <div ><font>Total O/S :</font> <font>Total O/S :</font> <font>Total O/S :</font> </div> */}
                  <div className='row border2'>
                    <div className='col  '>
                      <font>Total O/S :</font>
                    </div>
                    <div className='col borderl '>
                      <font>Current O/S</font>
                    </div>
                    <div className='col  borderl'>
                      <font>  45 Days</font>
                    </div>
                    <div className='col  borderl'>
                      <font>  45 Days</font>
                    </div>
                  </div>
                  <div className='row border2'>
                    <div className='col  '>
                      <font>2,165.00 Dr</font>
                    </div>
                    <div className='col borderl'>
                      <font>2,165.00 Dr</font>
                    </div>
                    <div className='col borderl'>
                      <font> </font>
                    </div>
                    <div className='col  borderl'>
                      <font> </font>
                    </div>
                  </div>
                  <div className='left2'>
                    <font>NEELAM APPLIANCES LIMITED</font>
                    <br></br>
                    <br></br>

                    <font> Authorised Signatory</font>

                  </div>
                </div>
              </div>
              <div className='row   center'>
                <div className='col border1'>
                  <div className='  fontprint'>  Regd & Corporate Office : Neelam House, Fafadia Indl. Estate, Golani Naka, Waliv Village, Vasai East. Dist: Palghar-401208.
                    PH: 8080561321 / 22 / WhatsApp : 9321822426 EMAIL : mail@neelamappliances.com / PAN No: AABCN9540N / GST NO: 27AABCN9540N1ZY.</div>
                </div>
              </div>
              <div className='row   center'>
                <div className='col  '>
                  <div className='  fontprint'> Mumbai Depot Address : Neelam Appliances Limited G-75, 1st Panjarapole Lane, Sarvodaya Nagar, C.P.Tank, Mumbai-400004. PH: 8080561323.</div>
                </div>
              </div>
              <div className='row   center'>
                <div className='col border1'>
                  <div className='  fontprint'> Subject to Palghar Juridiction</div>
                </div>
              </div>
              <div className='row   center'>
                <div className='col  '>
                  <div className=' fontprint'> This is an Computer generated Document, hence does not require a signature.</div>
                </div>
              </div></>
          )
        }
      })()}

    </div></p>);


  }


  return (

    <div className='  '>
      <div>{paragraphs}</div>





    </div>
  )
}

export default InvoiceFormat