import axios from "axios";
import { MDBCard } from "mdbreact";
import React from "react";
import { Accordion, Button, Col, Form, Row, Table } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { BASE_URL } from "../services/Api";
import authHeader from "../services/auth-header";
import { useEffect } from "react";
import { useState } from "react";
import ProductService from "../services/ProductService";
import { useDispatch, useSelector } from "react-redux";
import { changecostsupplierdeliveryedit, changesupplierdeliveryeditdiscount, changesupplierdeliveryeditqty, changesupplierdeliveryedittext, getsupplierdeliveryeditdatatoload, removefromcartfromsupplierdeliveryedit, supplierdeliveryeditaddtocart } from "../../redux/Action/supplierdeliveryedit";

export default function Edit(){
const{pointindex}=useParams();
    const { id } = useParams();
    const [ warehousedata, setwarehousedata ] = useState('');
    const [ supplierdata, setsupplierdata ] = useState('');
    const [ supplierSubContactsdata, setsupplierSubContactsdata ] = useState('');
    const [ sdndate, setsdndate ] = useState('');
    const [ type, settype ] = useState('');
    const [ status, setstatus ] = useState('');
    const [ shippingaddress, setshippingaddress ] = useState('');
    const [ remarks, setremarks ] = useState('');
    const [productdata, setproductdata] = useState([]);
    const [taxtype, settaxtype] = useState("");

    const dispatch = useDispatch();
    const supplierDeliverNoteItems = useSelector((state) => state.supplierdeliveryeditdata);

   const navigate = useNavigate();

    const getsupplierdeliverydata = ( ) => {

        axios.get( BASE_URL + 'sdn/' + id ,{
            headers:authHeader()
        }).then(res=>{
            // console.log("mello",res.data);
            setwarehousedata(res.data.warehouse);
            setsupplierdata(res.data.supplier);
            setsupplierSubContactsdata(res.data.supplierSubContacts);
            settype(res.data.type);
            setstatus(res.data.status);
            setshippingaddress(res.data.shippingaddress);
            setremarks(res.data.remarks);
            settaxtype(res.data.taxtype);
            setsdndate(res.data.sdndate);
        })
    };

    let date_value = new Date(sdndate);
    const defaultValue = date_value.toLocaleDateString('en-CA');

    /* get Product All */
    const getproductdata = () => {
        axios.get( BASE_URL + 'product/page/0/100/asc/id/%20',{
            headers:authHeader()
        }).then(res=>{

            setproductdata((res.data.Index).map(use => ({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code + ' - ' + use.short_Name })));
        })
    };

    const customFilter = (e) => {
        let name = e;
        axios.get( BASE_URL + 'product/page/0/100/asc/id/'+name,{
            headers:authHeader()
        }).then(res=>{
         setproductdata((res.data.data).map(use=>({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code + ' - ' + use.short_Name })))
        })
       };

    useEffect(() =>{
        getsupplierdeliverydata();
        getproductdata();
        dispatch(getsupplierdeliveryeditdatatoload(id))
    },[]);

    const addData = ( e ) =>{
        dispatch(supplierdeliveryeditaddtocart(e.value))

    }

    const submitdata = ( ) => {
        let warehouse = { "id" : Number(warehousedata.id)};
        let supplier = { "id" : Number(supplierdata.id)};
        let supplierSubContacts = {"id":Number(supplierSubContactsdata.id)}
       let po_id = id;
       const supplierdeliverysubmitdata = {
           warehouse,supplier,sdndate,status,shippingaddress,remarks,supplierSubContacts,supplierDeliverNoteItems,taxtype,po_id
       };

       axios.put( BASE_URL + 'sdn/' + id,supplierdeliverysubmitdata,{
           headers:authHeader()
       }).then(res=>{
           navigate('/SupplierDeliveryNoteTable/1')
       })
   }
    return(
        <MDBCard className="company">
            <h1>Edit Supplier Delivery Note</h1>
            <Link  to={`/SupplierDeliveryNoteTable/${pointindex}`}><Button className='btn btn-sm btn-secondary'> back</Button></Link>

            <Form className="product1">
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Supplier Delivery Data</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">WareHouse Name</Form.Label>
                                    <Form.Control type="text" value={warehousedata.name} disabled />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Supplier Company</Form.Label>
                                    <Form.Control type="text"  value={supplierdata.companyname} readOnly/>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Contact Name</Form.Label>
                                    <Form.Control type="text" value={supplierSubContactsdata == null?"":supplierSubContactsdata.contactname} readOnly />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Date</Form.Label>
                                    <Form.Control type="date" value={defaultValue} onChange={(e) => setsdndate(e.target.value)} />
                                </Form.Group>
                                {/* <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Type</Form.Label>
                                    <Form.Select value={type} disabled>
                                        <option value="b2b">B2B</option>
                                        <option value="b2c">B2C</option>
                                    </Form.Select>
                                </Form.Group> */}
                                <Form.Group as={Col} md="8" controlId="formBasicName">
                                    <Form.Label className="label">Owner</Form.Label>
                                    <Form.Control type="text" autoComplete="off" />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Status</Form.Label>
                                    <Form.Select value={status} disabled>
                                        <option value='true'>Open</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Tax Type</Form.Label>
                                    <Form.Select value={taxtype} disabled>
                                        <option>--Select--</option>
                                        <option value='withtax'>With Tax</option>
                                        <option value='withouttax'>Without Tax</option>
                                    </Form.Select>
                                </Form.Group>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Billing Address</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Supplier Address</Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Supplier Details</Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Address : -</Form.Label>
                                    <span>&nbsp;{supplierdata.address}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Name : -</Form.Label>
                                    <span>&nbsp;{supplierSubContactsdata == null?"":supplierSubContactsdata.contactname}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">City : -</Form.Label>
                                    <span>&nbsp;{supplierdata.cities}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Phone No : -</Form.Label>
                                    <span>&nbsp;{supplierSubContactsdata == null ? "":supplierSubContactsdata.phoneno}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Country : -</Form.Label>
                                    <span>{"India"}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Email Id : -</Form.Label>
                                    <span>{supplierSubContactsdata == null ? "":supplierSubContactsdata.email}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">State : -</Form.Label>
                                    {/* <span>{supplierstate}</span> */}
                                </Form.Group>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>Shipping Address</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="12" controlId="formBasicName">
                                    <Form.Label className="label">Address</Form.Label>
                                    <Form.Control as="textarea" value={shippingaddress} rows={3} name='address' placeholder='Enter Shipping Address' />
                                </Form.Group>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>Details</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="12" controlId="formBasicName">
                                    <Form.Label className="label">Remarks</Form.Label>
                                    <Form.Control as="textarea" value={remarks} rows={3} name='address' placeholder='Enter Remarks' />
                                </Form.Group>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <br></br>
                <Row className="mb-1">
                    <Form.Group as={Col} md="12" controlId="formBasicName">
                        <Form.Label className="label">Product</Form.Label>
                        <Select options={productdata} onChange={(e) => addData(e)} onInputChange={(e) => customFilter(e) }  />
                    
                    </Form.Group>
                </Row>
                <br></br>
                <Table striped responsive hover size="sm">
                <thead className='p-3 mb-2 bg-success text-white'>
                    <tr>
                        <th>PRODUCT&nbsp;NAME</th>
                        <th >QTY</th>
                        <th >Rate</th>
                        <th >Discount</th>
                        <th >Total</th>
                        <th >GST Value</th>
                        <th >Amount</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody style={{ overflow: "scroll" }}>
                    {supplierDeliverNoteItems.map(item =>
                        <tr key={item.id}>
                            <td>
                                <Form.Group as={Col} md="12" >
                                    {/* <Form.Control type="text" value={(item.productName?item.productName:item.product.productName)} size="sm" /> */}
                                    <textarea type="text" className="form-control textcontrol" value={(item.productName?item.productName:item.product.productName)} size="sm" ></textarea>
                            
                                </Form.Group>
                                <Form.Control as="textarea" onChange={(e) => { dispatch(changesupplierdeliveryedittext(e.target.value, item)) }} value={item.text} rows={2} className='form-control' />
                            </td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="text" size="sm" onChange={(e) => { dispatch(changesupplierdeliveryeditqty(e.target.value, item, taxtype)) }} value={item.sdn_quantity || "" }/>
                                </Form.Group>
                            </td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="text" size="sm" onChange={(e) => { dispatch(changecostsupplierdeliveryedit(e.target.value, item, taxtype)) }} value={item.mrp || "" } />
                                </Form.Group>
                            </td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="text" onChange={(e) => { dispatch(changesupplierdeliveryeditdiscount(e.target.value, item, taxtype)) }} value={item.discount || ''} size="sm" />
                                </Form.Group>
                            </td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="text" value={Number(item.total)} size="sm" />
                                </Form.Group>
                            </td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="text" value={Number(item.gstvalue)} size="sm" />
                                </Form.Group>
                            </td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="text" value={Number(item.amount)} size="sm" />
                                </Form.Group>
                            </td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type='button' className='btn btn-danger' onClick={() => dispatch(removefromcartfromsupplierdeliveryedit(item.id))} value="X" size="sm" />
                                </Form.Group>
                            </td>
                        </tr>
                    )}
                </tbody>
                <tbody>
                    <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th style={{ fontSize: 13 }}>Gross&nbsp;Amount</th>
                        {/* <th>{grossAmount}</th> */}
                        <th></th>
                    </tr>
                    <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th style={{ fontSize: 13 }}>Shipping&nbsp;Charge</th>
                        <th></th>
                        <th></th>
                    </tr>
                    <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th style={{ fontSize: 13 }}>IGST</th>
                        <th></th>
                        <th></th>
                    </tr>
                    <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th style={{ fontSize: 13 }}>SGST</th>
                        <th></th>
                        <th></th>
                    </tr>
                    <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th style={{ fontSize: 13 }}>CGST</th>
                        <th></th>
                        <th></th>
                    </tr>
                    <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th style={{ fontSize: 13 }}>Round&nbsp;off&nbsp;Value</th>
                        <th></th>
                        <th></th>
                    </tr>
                    <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th style={{ fontSize: 13 }}>Grand&nbsp;Total</th>
                        <th></th>
                        <th></th>
                    </tr>
                </tbody>
            </Table>
                <Button variant="primary" type="button"  onClick={submitdata}>
                Submit
            </Button>
            </Form>
        </MDBCard>
    )
}