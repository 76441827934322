export const addToCart = (item) => {
    return {
        type: 'ADD_TO_CART_JSHEET_INWARD',
        payload: item
    };
};


export const updateItem = (id, type1, jobsheet_qty,dlp,jobsheet_qty_kg,amount ) => {
    return {
      type: 'UPDATE_ITEM',
      payload: { id, type1, jobsheet_qty,dlp,jobsheet_qty_kg,amount}
    };
  };
  

  export const removeFromCart = (id) => {
    return {
        type: 'REMOVE_FROM_CART_JSHEET_INWARD',
        payload: id
    };
};

export const emptyCart = () => ({
  type: 'EMPTY_CART_JSHEET_INWARD'
});
// export const setData12 = (data) => {
//   return {
//       type: 'SET_DATA12',
//       payload: data
//   };
// };