import React, { useEffect, useState } from 'react';

const Logout = ({ timeout, onLogout }) => {
  const [idleTimer, setIdleTimer] = useState(null);

  const resetIdleTimer = () => {
    clearTimeout(idleTimer);
    const newIdleTimer = setTimeout(() => {
      onLogout(); // Call the logout function when the user is inactive for the specified time
    }, timeout);
    setIdleTimer(newIdleTimer);
  };

  useEffect(() => {
    // resetIdleTimer();

    // Reset the timer when there's user activity
    const handleUserActivity = () => {
      resetIdleTimer();
    };

    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);

    return () => {
      // Cleanup: remove event listeners and clear the timer
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
      clearTimeout(idleTimer);
    };
  }, [idleTimer, timeout, onLogout]);

  return <></>;
};

export default Logout;