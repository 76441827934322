import React from "react";
import { useEffect } from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import WareHouseService from "../../services/WareHouseService";
import { useState } from "react";
import AuthService from "../../services/auth.service";
import Swal from "sweetalert2";

export default function View() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const applyFontSizeTransition = (elementId) => {
      const element = document.getElementById(elementId);
      element.style.transition = 'font-size 2s';
      element.style.fontSize = '30px';
      element.style.fontWeight = '600';
      // font-weight: 600;
      setTimeout(() => {
         
        document.getElementById("typer1").classList.add('promote');
        element.style.fontSize = '24px';
      }, 1000);
    };
const{pointindex}=useParams();
    const { id } = useParams();
  const[data,setData]=useState('');
  const user = AuthService.getCurrentUser();

    useEffect(() => {
    if(data.length==0){    WareHouseService.getWareEdit(id).then(res=>{
            setData(res.data);

        })}
    }, [])

    // const getItem = (id) => {

    //     WareHouseService.getDelete(id).then(res => {
    //     //   window.location.reload()
    //     navigate(`/warehouse/${pointindex}`);
    //     })
    //   }
    

    const getItem = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
                setIsLoading(true); 
        WareHouseService.getDelete(id).then(res => {
        //   window.location.reload()
        // alert('deleted')
        Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          )
        navigate(`/warehouse/${pointindex}`);
        }).catch(error => {
            console.error('Error adding  :', error);
            if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `
                <div>
                  <p id="issueText">Why do I have this issue?</p>
                  <ul>
              
                  <li id="typer1">You might have an internet issue</li>
                  </ul>
                </div>
              `,
              didOpen: () => {
                applyFontSizeTransition('issueText');
              }
            });
          }
          })
          .finally(() => {
            setIsLoading(false); 
          });
        }

    })
      }

    return (

        <div>
            <div className="card">
               
           
                <div className='d-flex bd-highlight'>
        <div className="card-header card-title w-100">VIEW WAREHOUSE</div>
        {/* <button className='prox btn' onClick={()=>handleChange12()} ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"/><polyline points="12 19 5 12 12 5"/></svg></button> */}
        {/* <a className=' delete btn' onClick={()=>handledelete(id)}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"/><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"/><line x1="10" y1="11" x2="10" y2="17"/><line x1="14" y1="11" x2="14" y2="17"/></svg></a> */}
        {user.roles[0] === 'ROLE_ADMIN' &&
        // <Link className='p-2 w-200 card-header card-title bd-highlight' onClick={()=>getItem(id)}  ><Button className="btn-sm">Delete</Button></Link>
        
        <Link className='p-2 w-200 card-header card-title bd-highlight' variant="primary" style={{width:'auto'}} onClick={()=>getItem(id)} disabled={isLoading}>
        
        <Button className="btn-sm">  {isLoading ? 'Loading...' : 'Delete'}</Button>
      </Link>
      
        }
        
        <Link className='p-2 w-200 card-header card-title bd-highlight' to={`/wareEdit/${id}/${pointindex}`}><Button className="btn-sm">edit</Button></Link>

        <Link className='p-2 w-200 card-header card-title bd-highlight' to={`/warehouse/${pointindex}`}><Button  className="btn-sm">Back</Button></Link>
     
      </div>






                {/* <Route path="/wareEdit/:id/:pointindex" element={<WareEdit />} /> */}


                <div className='card card-body'>
                <Row className="mb-1">
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2"><b>WareHouse Name : -</b></Form.Label>
                        <span>&nbsp;{data.name}</span>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2"><b>Location : -</b></Form.Label>
                        <span>&nbsp;{data.location}</span>
                    </Form.Group>
                </Row>

                <Row className="mb-1">
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2"><b>WareHouse Type : -</b></Form.Label>
                        <span>&nbsp;{data.type == 1 ? "Primary":'Secondary'}</span>
                    </Form.Group>
                </Row>


           </div>
           </div>
        </div>
    )
}