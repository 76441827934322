import React from 'react'
import { Link } from 'react-router-dom';
import { Mydatatable } from '../../Master/Product/Mydatatable';

const Mastercartoontable = () => {

    
    let header = [
        { "label": "Id", "field": "id" },
        { "label": "Product Name", "field": "pname" },
        { "label": "Brand", "field": "bname" },
        { "label": "Min Weight", "field": "minweight" },
        { "label": "Ean Code", "field": "eancode" },
        { "label": "Created Date", "field": "createddate" },
        { "label": "Created Time", "field": "createdtime" },
        { "label": "Created By", "field": "createbyname" },
        // { "label": "Updated By", "field": "updatedbyname" },
        { "label": "Action", "field": "action","subaction1":<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"/><circle cx="12" cy="12" r="3"/></svg> }
      ];


      const sumofkeys=[{"first":'mrp'} ]

  return (
  <>
  {/* <Link to='/Cartooncreate'><button className='btn btn-primary' style={{width:'auto'}}>Cartoon barcode</button></Link> */}
  <Mydatatable
    head={header}
    title={"Master Carton"}
    axiostabledata={'mastercartoon'}
    Add={"Add"}
    Create={'/MasterCarton/Create'}
    Edit={"/Mastercartoonview"}
    View={"/Mastercartoonview"}
    Sumofkeys={sumofkeys}
    />
  </>
  )
}

export default Mastercartoontable