import React, { useEffect, useState } from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import ReatilerService from "../../services/Reatiler";
import UserService from "../../services/user.service";
import { MDBCard } from "mdbreact";
import Swal from "sweetalert2";
import AuthService from "../../services/auth.service";

export default function View() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const applyFontSizeTransition = (elementId) => {
      const element = document.getElementById(elementId);
      element.style.transition = 'font-size 2s';
      element.style.fontSize = '30px';
      element.style.fontWeight = '600';
      // font-weight: 600;
      setTimeout(() => {
         
        document.getElementById("typer1").classList.add('promote');
        element.style.fontSize = '24px';
      }, 1000);
    };
    /* Retailer Id */
    const { id } = useParams();
const{pointindex}=useParams();
    const [data, setData] = useState('');
    const [Zone, setZone] = useState('');
    const [state, setstate] = useState('');
    const[deliveryAddress,setdeliveryAddress] = useState([]);
    const [latitude, setLatitude] = useState([]);
    const [longitude, setLongitude] = useState([])
    useEffect(() => {

        ReatilerService.getRetailerGet(id).then(res => {
            
            const { delivery_address, ...cleanData } = res.data;
            setData(cleanData);
           
            UserService.getZoneDataEdit1(res.data.zonesid).then(res1 => {
               console.log(res.data.zonesid)
                setZone(res1.data.zoneName);
                (res1.data.state_zone).map(user=>{
                    if(user.id == (res.data.stateid)){
                        setstate(user.state_name)
                    }
                })
            })
            setdeliveryAddress(res.data.deliveryAddress);
        })
    }, [])
    const getItem = (id) => {

        // ReatilerService.getDelete(id).then(res => {
        //   window.location.reload();
        // })
    
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.isConfirmed) {
            setIsLoading(true); 

            ReatilerService.getDelete(id).then(res => {
            //   window.location.reload();
            Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
              )
            navigate(`/Newretailer/${pointindex}`)
            }).catch(error => {
                console.error('Error adding  :', error);
                if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `
                    <div>
                      <p id="issueText">Why do I have this issue?</p>
                      <ul>
                  
                      <li id="typer1">You might have an internet issue</li>
                      </ul>
                    </div>
                  `,
                  didOpen: () => {
                    applyFontSizeTransition('issueText');
                  }
                });
              }
              })
              .finally(() => {
                setIsLoading(false); 
              });
         
          }
        })
      };
      const user = AuthService.getCurrentUser();


    return (
    <MDBCard data-aos="fade">
     <div className='d-flex bd-highlight'>
        <div className="card-header card-title w-100">VIEW RETAILER</div>
        {/* <button className='prox btn' onClick={()=>handleChang/e12()} ></button> */}
        {/* <Link className='p-2 w-200 card-header  btn-sm card-title bd-highlight    ' onClick={()=>getItem(id)}><Button className="delete btn-sm"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"/><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"/><line x1="10" y1="11" x2="10" y2="17"/><line x1="14" y1="11" x2="14" y2="17"/></svg></Button></Link> */}
        {user.roles[0] === 'ROLE_ADMIN' &&
        
        <Link className='p-2 w-200 card-header card-title bd-highlight' variant="primary" style={{width:'auto'}} onClick={()=>getItem(id)} disabled={isLoading}>
        
        <Button className="btn-sm">  {isLoading ? 'Loading...' : 'Delete'}</Button>
      </Link>  
        // <Link className='p-2 w-200 card-header  btn-sm card-title bd-highlight'  ><Button className="btn-sm" onClick={()=>getItem(id)}>Delete</Button></Link>
        }
        <Link className='p-2 w-200 card-header  btn-sm card-title bd-highlight' to={`/retaileredit/${id}/1`}><Button className="btn-sm">Edit</Button></Link>

        
        <Link className='p-2 w-200 card-header btn-sm card-title bd-highlight' to={`/Newretailer/${pointindex}`}><Button className="btn-sm">Back</Button></Link>
      </div>
        <div><br></br>
            <div className="card">
             
                <div className='card card-body'>
                    <Row className="mb-1">
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="cname2"><b>Trade Name : -</b></Form.Label>
                            <span>&nbsp;{data.tradeName}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="cname2"><b>Transporter Name : -</b></Form.Label>
                            <span>&nbsp;{data.transporterName}</span>
                        </Form.Group>
                    </Row>
                    <Row className="mb-1">
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="cname2"><b>Billing Address : -</b></Form.Label>
                            <span>&nbsp;{data.billingAddress}</span>
                        </Form.Group>
                        {deliveryAddress!==undefined?(deliveryAddress.map(user=>(
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2"><b>Delivery Address : -</b></Form.Label>
                        <span>&nbsp;{user.delivery_address}</span>
                        &nbsp;&nbsp;
                        <Form.Label className="cname2"><b> Statename : -</b></Form.Label>
                        <span>&nbsp;{user.state}</span>&nbsp;&nbsp;
                        <Form.Label className="cname2"><b> Pin-Code : -</b></Form.Label>
                        <span>&nbsp;{user.pincode}</span>
                    </Form.Group>
                        ))):null}

                    </Row>
                    <Row className="mb-1">
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="cname2"><b>GST No : -</b></Form.Label>
                            <span>&nbsp;{data.gstNumber}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="cname2"><b>Pan Number : -</b></Form.Label>
                            <span>&nbsp;{data.panNumber}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="cname2"><b>Aadhar card Number : -</b></Form.Label>
                            <span>&nbsp;{data.aadharcard}</span>
                        </Form.Group>
                    </Row>
                    <Row className="mb-1">
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="cname2"><b>Country : -</b></Form.Label>
                            <span>&nbsp;{data.country == "101"?"India":""}</span>

                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="cname2"><b>Zone : -</b></Form.Label>
                            <span>&nbsp;{Zone}</span>
                        </Form.Group>
                    </Row>

                    <Row className="mb-1">
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="cname2"><b>State : -</b></Form.Label>
                            <span>&nbsp;{state}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="cname2"><b>City : -</b></Form.Label>
                            <span>&nbsp;{data.city}</span>
                        </Form.Group>
                    </Row>
                    <Row className="mb-1">
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="cname2"><b>Zip Code : -</b></Form.Label>
                            <span>&nbsp;{data.pinCode}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="cname2"><b>Retailer : -</b></Form.Label>
                            <span>&nbsp;{data.colourtype}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="cname2"><b>GstType : -</b></Form.Label>
                            <span>&nbsp;{data.gstType}</span>
                        </Form.Group>
                    </Row>
                    <Row className="mb-1">
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="cname2"><b>Mobile Number : -</b></Form.Label>
                            <span>&nbsp;{data.perMobileNumber}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="cname2"><b>Alter Mobile Number : -</b></Form.Label>
                            <span>&nbsp;{data.alterMobileNumber}</span>
                        </Form.Group>
                    </Row>
                    <Row className="mb-1">
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="cname2"><b>Email : -</b></Form.Label>
                            <span>&nbsp;{data.perEmail}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="cname2"><b>Alter Email : -</b></Form.Label>
                            <span>&nbsp;{data.alterEmail}</span>
                        </Form.Group>
                    </Row>
                    <Row className="mb-1">
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="cname2"><b>Credit Limit : -</b></Form.Label>
                            <span>&nbsp;{data.creditLimit}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="cname2"><b>Credit Days : -</b></Form.Label>
                            <span>&nbsp;{data.creditDays}</span>
                        </Form.Group>
                    </Row>
                    <Row className="mb-1">
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="cname2"><b>Latitude : -</b></Form.Label>
                            <span>&nbsp;{data.latitude}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="cname2"><b>Longitude : -</b></Form.Label>
                            <span>&nbsp;{data.longitude}</span>
                        </Form.Group>
                    </Row>
                    <Row className="mb-1">
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="cname2"><b>Delivery Location : -</b></Form.Label>
                            <span>&nbsp;{data.deliveryLocation}</span>
                        </Form.Group>
                        {/* <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="cname2"><b>Discount Structure : -</b></Form.Label>
                            <span>&nbsp;{data.discountStructure}</span>
                        </Form.Group> */}
                    </Row>
                    <Row>

                    </Row>

                </div>
            </div>
        </div>
        </MDBCard>
    )
}