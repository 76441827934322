import axios from "axios";
import { MDBCard } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../services/Api";
import authHeader from "../../services/auth-header";
import AuthService from "../../services/auth.service";
import Swal from "sweetalert2";

export default function View(){
    const {id} = useParams();
    const { pointindex } = useParams();
    const [data,setdata] = useState('');
    const user = AuthService.getCurrentUser();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const applyFontSizeTransition = (elementId) => {
      const element = document.getElementById(elementId);
      element.style.transition = 'font-size 2s';
      element.style.fontSize = '30px';
      element.style.fontWeight = '600';
      // font-weight: 600;
      setTimeout(() => {
         
        document.getElementById("typer1").classList.add('promote');
        element.style.fontSize = '24px';
      }, 1000);
    };

    const getdata = () => {
        axios.get(BASE_URL + 'group1/getById/'+id,{
            headers:authHeader()
        }).then(res=>{
            // console.warn(res.data)
            setdata(res.data)
        })
    }
    useEffect(()=>{
        getdata()
    },[]);
    
    const handledelete=(id)=>{
  
     
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  }).then((result) => {
    if (result.isConfirmed) {
      setIsLoading(true); 
      axios.delete(BASE_URL + 'group1/delete/'+id,{
        headers:authHeader()
      }).then(res=>{


        navigate(`/Group1Table/${pointindex}`)
       
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        )



        // window.location.reload();
      }).catch(error => {
        console.error('Error adding  :', error);
        if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `
            <div>
              <p id="issueText">Why do I have this issue?</p>
              <ul>
          
              <li id="typer1">You might have an internet issue</li>
              </ul>
            </div>
          `,
          didOpen: () => {
            applyFontSizeTransition('issueText');
          }
        });
      }
      })
      .finally(() => {
        setIsLoading(false); 
      });
    
    }
  })
        }
    return(
        <MDBCard className="company">
            
            <div className='d-flex bd-highlight'>
        <div className="card-header card-title w-100">View Group1</div>
        {user.roles[0] === 'ROLE_ADMIN' &&      
        
        <Link className='p-2 w-200 card-header card-title bd-highlight' variant="primary" style={{width:'auto'}} onClick={()=>handledelete(id)} disabled={isLoading}>
        
        <Button className="btn-sm">  {isLoading ? 'Loading...' : 'Delete'}</Button>
      </Link>
        }
        
        <Link className='p-2 w-200 card-header card-title bd-highlight' to={`/group1/Update/${id}/${pointindex}`}><Button className="btn-sm">Update</Button></Link>

        <Link className='p-2 w-200 card-header card-title bd-highlight' to={`/Group1Table/${pointindex}`}><Button  className="btn-sm">Back</Button></Link>
     
      </div>
            <Form className="product1">
                <Row className="mb-1">
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Title</Form.Label>
                        <Form.Control type="text" name="title" value={data.title} readOnly />
                    </Form.Group>
                    </Row>
                    <Button variant="primary" style={{width:'auto'}} disabled>
          Submit
        </Button>
            </Form>
        </MDBCard>
    )
}