import axios from "axios";
import { MDBCard } from "mdbreact";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../../services/Api";
import authHeader from "../../services/auth-header";
import { Col, Form, Row } from "react-bootstrap";

export default function View(){
    const {id} = useParams();
    const [gname,setgname] = useState('');
    const [staff,setstaff] = useState('');
    useEffect(()=>{
      axios.get(BASE_URL + 'galaprefix/getbyid/'+id,{
        headers:authHeader()
      }).then(res=>{
        console.log('mello',res.data)
        setstaff(res.data.staff.staffName);
        setgname(res.data.gname);
      })
    },[])
    return(
        <MDBCard className="company">
            <h1>Gala View</h1>
            <Form className="product1">
                <Row className="mb-1">
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Staff Name</Form.Label>
                        <Form.Control type="text" name="staff" value={staff}/>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Gala Name</Form.Label>
                        <Form.Control type="text" name="gname" value={gname} />
                    </Form.Group>

                </Row>
            </Form>
        </MDBCard>
    )
}