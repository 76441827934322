import axios from 'axios'
import React, { useEffect, useState } from 'react'
// import Select from 'react-select/dist/declarations/src/Select'
import { BASE_URL } from '../../services/Api'
import authHeader from '../../services/auth-header'
import Select from "react-select";
import { useNavigate } from 'react-router-dom';
import DistrbutorService from '../../services/Distrbutor';

const Meetingcreate = () => {
    const navigate = useNavigate();
    const [disword1, setdisword1] = useState(encodeURIComponent(' '))
    const [disword2, setdisword2] = useState(encodeURIComponent(' '))

    const [disword, setdisword] = useState(encodeURIComponent(' '))
    const [dis, setdistributordata1] = useState([])
    const [dis1, setdistributordata11] = useState([])
    const [dis2, setdistributordata22] = useState([])

const [data, setData] = useState([])

const [meetingTitle, setMeetingTitle] = useState("");
const [meetingDate, setMeetingDate] = useState(null);
const [docName, setDocName] = useState("");
const [doc, setdoc] = useState("");

 const handleFileChange = (event) => {
    console.log(event.target.value)
//   setDocLocation(event.target.value);
};
const [staff, setStaff] = useState({ name: "", position: "" });


const[multiplestaff,setstaff]=useState([])
    useEffect(() => {

        axios.get(`${BASE_URL}staff/page/0/200/asc/id/${disword.length?disword:encodeURIComponent(' ')}`, { headers: authHeader() })
            .then(response => {
                console.log(response)
              
                if (response.data.data == undefined) {
                    setdistributordata1(response.data.Index.map(use => ({ value: use.id, label: use.staff_Name })));
                    setData(response.data.Index)
                } if (response.data.Index == undefined) {
                    setData(response.data.data)

                    setdistributordata1(response.data.data.map(use => ({ value: use.id, label: use.staff_Name })));

                }

                // console.log(response.data.data.map(use => ({ value: use.id, label: use.product_Name })))
            })




    }, [ disword])
    useEffect(() => {

        axios.get(`${BASE_URL}retailer/page/0/100/asc/id/${disword1.length?disword1:encodeURIComponent(' ')}`, { headers: authHeader() })
            .then(response => {
                console.log(response)
              
                if (response.data.data == undefined) {
                    setdistributordata11(response.data.Index.map(use => ({ value: use.id, label: use.retailername })));
                    setData(response.data.Index)
                } if (response.data.Index == undefined) {
                    setData(response.data.data)

                    setdistributordata11(response.data.data.map(use => ({ value: use.id, label: use.retailername })));

                }

                // console.log(response.data.data.map(use => ({ value: use.id, label: use.product_Name })))
            })




    }, [ disword1])
    useEffect(() => {

        axios.get(`${BASE_URL}distributor/page/0/100/asc/id/${disword2.length?disword2:encodeURIComponent(' ')}`, { headers: authHeader() })
            .then(response => {
                console.log(response)
              
                if (response.data.data == undefined) {
                    setdistributordata22(response.data.Index.map(use => ({ value: use.id, label: use.trade_Name })));
                    setData(response.data.Index)
                } if (response.data.Index == undefined) {
                    setData(response.data.data)

                    setdistributordata22(response.data.data.map(use => ({ value: use.id, label: use.trade_Name })));

                }

                // console.log(response.data.data.map(use => ({ value: use.id, label: use.product_Name })))
            })




    }, [ disword2])
    const handlesumbit = () => {
        // const staff = 15; // Assuming staff is an ID or similar
        const staff=[multiplestaff.map((item)=>({id:item.value}))][0];
        const ret=[ret1.map((item)=>({id:item.value}))][0];     
        const dist=[dist1.map((item)=>({id:item.value}))][0];
        // const ret=[]
        // Adjust the file path accordingly
        // const doc = "C:\\Users\\lnv111\\Pictures\\steel.jpeg";
        const emptyBlob = new Blob([""], { type: "application/octet-stream" });

        // Creating FormData object
        const formData = new FormData();
        formData.append('meetingtitle', meetingTitle);
        // formData.append('doc', doc); 
        formData.append('meetingdate',meetingDate ); 
        formData.append('st', JSON.stringify(staff));
        formData.append('ret', JSON.stringify(ret));
        formData.append('dist', JSON.stringify(dist));
        doc ? formData.append('doc', doc) : formData.append('doc', emptyBlob, 'empty-file.txt');

        formData.append('description',docName ); 

        // Sending docName  data as FormData
        axios.post(BASE_URL + "meeting/", formData, {
            headers: authHeader()
        })
        .then(response => {
            console.log('Post request successful:', response.data);
            // Handle success
            navigate("/Meettable/1"); 
        })
        .catch(error => {
            console.error('Error:', error);
            // Handle error
        });
        
    }
    
// const handlesumbit=()=>{
//     // const staff=[multiplestaff.map((item)=>({id:item.id}))][0]
//     const staff=15
//     console.log({meetingTitle: meetingTitle, meetingDate: meetingDate, docName: docName, docLocation: docLocation, staff:15});
// // const doc="C:\Users\lnv111\Pictures\steel.jpeg"
// const doc = "C:\\Users\\lnv111\\Pictures\\steel.jpeg";

//     // Creating FormData object
//     const formData = new FormData();
//     formData.append('meetingtitle', meetingTitle);
//     // formData.append('meetingdate', meetingDate);
//     // formData.append('docName', docName);
//     formData.append('doc', doc);
//     formData.append('staff', staff);
 
//     // Assuming multiplestaff is an array of staff members, you may need to loop through them and append to FormData accordingly
//     // staff.forEach((staff, index) => {
//     //     formData.append(`staff[${index}]`, staff);
//     // });
    
//     // Sending data as FormData
//     axios.post(BASE_URL + "meeting/", formData,  {
//         headers: authHeader()
//       })
//     .then(response => {
//         console.log('Post request successful:', response.data);
//         // dispatch(emptyCart());
//         // navigate(`/Inwardtable/0`);
//     })
//     .catch(error => {
//         // Handle error
//     });
    

// }


    const [showAllStaff, setShowAllStaff] = useState(false);

    // Assuming multiplestaff is your array of staff members
    const maxColumns = 3; // Maximum number of columns
    const visibleStaff = showAllStaff ? multiplestaff : multiplestaff.slice(0, maxColumns);

    const handleToggleShowAllStaff = () => {
        setShowAllStaff(prevShowAllStaff => !prevShowAllStaff);
    };
    const disid = (e) => {
     if(multiplestaff.filter((item)=>item.id==e).map((item)=>item.id)[0]!==e)
     {    const staffDetails = data.filter((item) => item.id === e);
        console.log(staffDetails);

         setstaff(prevStadddetails => [...prevStadddetails, ...staffDetails]);}else{
            alert("Already Exist");
        }
    }



// console.log()
const handlestaff=(e)=>{
    setstaff(e)
}

const handle=(e)=>{
    // alert('delete'+e)
    // setstaff( multiplestaff.filter(item => item.id !== e))
}
const [ret1, setRet] = useState([])
const handleretailer=(e)=>{
console.log(e)
setRet(e)
}
const [dist1, setDist] = useState([])
const handledis=(e)=>{
    console.log(e)
    setDist(e)
}
  return (
    <div>
        <div className=' card'>
            <div className='card-body'>
                <div className='card-header card-title'>
                    <h1>Minutes of Meetings</h1>
                </div>
            <div className='row'>
                
                <div className='col'>
                    <label className='form-label'>Meeting Title</label>
                <input type='text' placeholder='Title'onChange={(e) => setMeetingTitle(e.target.value)} className='form-control'/>
                </div>
             </div>
             <br></br>
             <div className='row'>
                <div className='col-3'>
                <label className='form-label'>Date</label>
                <input type='Date' placeholder='Date' onChange={(e) => setMeetingDate(e.target.value)} className='form-control'/>
                </div>
                <br></br>
                <div className='col-12'>
                    <label className='form-label'>Add Staff  </label>
                    <Select options={dis} isMulti onInputChange={(inputValue) => setdisword(inputValue)} onChange={(e) => handlestaff(e)} />
                </div>
                <div className='col-12'>
                    <label className='form-label'>Add Distrbutor  </label>
                    <Select options={dis2} isMulti onInputChange={(inputValue) => setdisword2(inputValue)} onChange={(e) => handledis(e)}/>
                </div>
                <div className='col-12'>
                    <label className='form-label'>Add Retailer  </label>
                    <Select options={dis1} isMulti onInputChange={(inputValue) => setdisword1(inputValue)}  onChange={(e) => { handleretailer(e) }} />

                    {/* <Select options={dis1} onInputChange={(inputValue) => setdisword1(inputValue)} onChange={(options) => disid(options.value)}/> */}
                </div>
             </div><br></br>
           
             <div className='row'>
              
                <div className='col-3'>
                {/* <input type='text' placeholder='Staff' className='form-control'/> */}
                </div>
             </div><br></br>

<textarea className='form-control' onChange={(e)=>setDocName(e.target.value)} placeholder='Desription'></textarea><br></br>
<div className='row'>
    
   
  
 
 
                                                     
     
    
</div>
<br></br>
<div className='row'>
    <div className='col-3'>
    <input 
        type='file' 
        className='form-control' 
        onChange={e => setdoc(e.target.files[0])}
      />
          </div>
</div>

<button className='btn btn-primary' onClick={()=>handlesumbit( )}>Submit</button>
            </div>
        </div>

    </div>
  )
}

export default Meetingcreate