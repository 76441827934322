import { ESTIMATED_DELIVERY_DATA, ESTIMATED_DELIVERY_VALUE } from "../constant";

export const primaryorderview = (data = [], action) => {

    switch(action.type){

        case ESTIMATED_DELIVERY_DATA:
            
            return[...action.data.primaryOrderItems]
        default:
            return data;
    }
}