import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import CompanyService from '../../services/CompanyService';
import UserService from '../../services/user.service';
import { MDBCard } from 'mdbreact';
import axios from 'axios';
import { BASE_URL } from '../../services/Api';
import authHeader from '../../services/auth-header';
import Swal from 'sweetalert2';
export default function Company() {

  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [cname, setCname] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [gst, setGst] = useState('');
  const [address, setAddress] = useState('');
  const [country, setCountry] = useState('');
  const [states, setStates] = useState('');
  const [statesvalue, setStatesvalue] = useState([]);
  const [statecode, setStateCode] = useState('');
  const [cities, setcities] = useState('');
  const [zipcode, setZipCode] = useState('');
  const [statedata, setStatedata] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const applyFontSizeTransition = (elementId) => {
    const element = document.getElementById(elementId);
    element.style.transition = 'font-size 2s';
    element.style.fontSize = '30px';
    element.style.fontWeight = '600';
    // font-weight: 600;
    setTimeout(() => {
       
      document.getElementById("typer1").classList.add('promote');
      element.style.fontSize = '24px';
    }, 1000);
  };
  let getcompanydata = () => {
    const id = 1;
    CompanyService.getcompany(id).then((res) => {
      // console.log("mello", res.data)
      setName(res.data.name);
      setCname(res.data.cname);
      setPhone(res.data.phone);
      setEmail(res.data.email);
      setGst(res.data.gst);
      setAddress(res.data.address);
      setCountry({ "id": res.data.country.id });
      setStates({ "id": res.data.states.id });
      setStatesvalue({value:res.data.states.id,label:res.data.states.name})
      //  setStateCode(res.data.statecode);
      setcities(res.data.cities);
      setZipCode(res.data.zipcode);
    })

  };

  let getStateData = () => {

    axios.get(BASE_URL + 'state/', {
      headers: authHeader()
    }).then(res => {
      // console.log("mello", res.data);
      setStatedata((res.data).map(user => ({ value: user.id, label: user.name })));
    })
  }
  useEffect(() => {
    getcompanydata();
    getStateData();
  }, []);




let onChangeState = ( e ) => {
  setStates({"id":e.value});
  setStatesvalue({value:e.value,label:e.label})

}
let Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

  const submitform = () => {
    setIsLoading(true); 

    const id = 1;
    const company = {
      name,
      cname,
      phone,
      email,
      gst,
      address,
      country,
      states,
      cities,
      zipcode,
      // statecode
    }
    CompanyService.getcompanyEdit(id, company).then(res => {
      console.log(res.data);
      Toast.fire({
        icon: 'success',
        title: 'Successfully Updated'
      })
      navigate("/company")
    }).catch(error => {
      console.error('Error adding  :', error);
      if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `
          <div>
            <p id="issueText">Why do I have this issue?</p>
            <ul>
        
            <li id="typer1">You might have an internet issue</li>
            </ul>
          </div>
        `,
        didOpen: () => {
          applyFontSizeTransition('issueText');
        }
      });
    }
    })
      .finally(() => {
        setIsLoading(false); 
      });
  }
  return (
    <MDBCard>

      <h1>Update Company</h1>
      <Form className='product'>

        <Row className="mb-1">
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Name</Form.Label>
            <Form.Control type="text" name='name' value={name} onChange={(e) => setName(e.target.value)} />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Company Name</Form.Label>
            <Form.Control type="text" name='cname' value={cname} onChange={(e) => setCname(e.target.value)} />
          </Form.Group>
    
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Phone No.</Form.Label>
            <Form.Control type="text" name='phone' value={phone} onChange={(e) => setPhone(e.target.value)} />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Email</Form.Label>
            <Form.Control type="text" name='email' value={email} onChange={(e) => setEmail(e.target.value)} />
          </Form.Group>
     
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">GST No.</Form.Label>
            <Form.Control type="text" name='gst' value={gst} onChange={(e) => setGst(e.target.value)} />
          </Form.Group>
  
          <Form.Group as={Col} md="12" controlId="formBasicName">
            <Form.Label className="cname2">Address</Form.Label>
            <Form.Control as="textarea" rows={3} name='address' value={address} onChange={(e) => setAddress(e.target.value)} />
          </Form.Group>
       
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Country</Form.Label>
            <Form.Control type='text' value={"India"} readOnly />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">State</Form.Label>
            <Select value={statesvalue} options={statedata} onChange={(e) => onChangeState(e)}/>
          </Form.Group>
      

      
          {/* <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">State Code</Form.Label>
            <Form.Control type="text" value={statecode} onChange={(e) => setStateCode(e.target.value)} />
          </Form.Group> */}
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">City</Form.Label>
            <Form.Control type="text" name='cities' value={cities} onChange={(e) => setcities(e.target.value)} />
          </Form.Group>
       
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">ZipCode</Form.Label>
            <Form.Control type="text" name='zipcode' value={zipcode} onChange={(e) => setZipCode(e.target.value)} />
          </Form.Group>
        </Row>
{/* 
        <Button variant="primary" type="button" onClick={submitform}>
          Submit
        </Button> */}
        <Button variant="primary" style={{width:'auto'}} onClick={submitform} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Submit'}
        </Button>
      </Form>

    </MDBCard>
  )
}
