import axios from "axios";
import authHeader from "./auth-header";

const API_URL = "http://43.225.54.138:8080/scm/api/retailer/";


const getReatilerTable = () => {
    return axios.get(API_URL +"getAll", { headers: authHeader() });
  };

  const getReatilerCreate = (reatiler) => {
    return axios.post(API_URL +"create",reatiler, { headers: authHeader() });
  };
const getcompanyEdit = (id,Retailer) => {
  return axios.put(API_URL + "update/"+id, Retailer,{ headers: authHeader() });
};

const getRetailerGet = (id) => {
  return axios.get(API_URL + "getById/"+id,{ headers: authHeader() });
};

const getDelete = ( id ) => {
    return axios.delete(API_URL + "delete/"+id, { headers: authHeader() });
  };
const ReatilerService = {
    getReatilerTable,
    getReatilerCreate,
    getcompanyEdit,
    getRetailerGet,
    getDelete

};

export default ReatilerService;