import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../../services/Api'
import authHeader from '../../services/auth-header'
import Select from "react-select";
import ProductService from '../../services/ProductService';
import Addcarinjobsheet from '../../../redux/Reducer/Addcarinjobsheet';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart, emptyCart, removeFromCart, updateItem } from '../../../redux/Action/Addforinwardjobsheet';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Accordion, Button } from 'react-bootstrap';
// import state from 'sweetalert/typings/modules/state';

const Jobsheetoutwardcreate = () => {
    const [isLoading, setIsLoading] = useState(false);
  const applyFontSizeTransition = (elementId) => {
    const element = document.getElementById(elementId);
    element.style.transition = 'font-size 2s';
    element.style.fontSize = '30px';
    element.style.fontWeight = '600';
    // font-weight: 600;
    setTimeout(() => {
       
      document.getElementById("typer1").classList.add('promote');
      element.style.fontSize = '24px';
    }, 1000);
  };

const datasupplier=[
  { "id": 1, "label": "Supply" },
  { "id": 2, "label": "Import" },
  { "id": 3, "label": "Export" },
  { "id": 4, "label": "Job Work" },
  { "id": 5, "label": "For Own Use" },
  { "id": 6, "label": "Job Work Returns" },
  { "id": 7, "label": "Sales Return" },
  { "id": 8, "label": "Others" },
  { "id": 9, "label": "SKD/CKD" },
  { "id": 10, "label": "Line Sales" },
  { "id": 11, "label": "Recipient Not Known" },
  { "id": 12, "label": "Exhibition or Fairs" }
]

const doctype=[
  { "id": "INV", "label": "Tax Invoice" },
  { "id": "BIL", "label": "Bill of Supply" },
  { "id": "BOE", "label": "Bill of Entry" },
  { "id": "CHL", "label": "Delivery Challan" },
  { "id": "CNT", "label": "Credit Note" },
  { "id": "OTH", "label": "Others" }
]

const [subSupplyType, setSubSupplyType] = useState('');
const [docType, setDocType] = useState('');
const [docNo, setDocNo] = useState('');
const [docDate, setDocDate] = useState('');
const [fromGstin, setFromGstin] = useState('');
const [fromTrdName, setFromTrdName] = useState('');
const [fromAddr1, setFromAddr1] = useState('');
const [fromAddr2, setFromAddr2] = useState('');
const [fromPlace, setFromPlace] = useState('');
const [fromPincode, setFromPincode] = useState(0);
const [actFromStateCode, setActFromStateCode] = useState(0);
const [fromStateCode, setFromStateCode] = useState(0);
const [toGstin, setToGstin] = useState('');
const [toTrdName, setToTrdName] = useState('');
const [toAddr1, setToAddr1] = useState('');
const [toAddr2, setToAddr2] = useState('');
const [toPlace, setToPlace] = useState('');
const [toPincode, setToPincode] = useState(0);
const [actToStateCode, setActToStateCode] = useState(0);
const [toStateCode, setToStateCode] = useState(0);
const [transactionType, setTransactionType] = useState(0);
const [transporterId, setTransporterId] = useState('');
const [transporterName, setTransporterName] = useState('');
const [transDocNo, setTransDocNo] = useState('');
const [transMode, setTransMode] = useState('');
const [transDistance, setTransDistance] = useState('');
const [transDocDate, setTransDocDate] = useState('');
const [vehicleNo, setVehicleNo] = useState('');
const [vehicleType, setVehicleType] = useState('');
const [ewayBillNo, setEwayBillNo] = useState(0);
const [ewayBillDate, setEwayBillDate] = useState('');
const [validUpto, setValidUpto] = useState('');
const [alert, setAlert] = useState('');
const [ewayStatus, setEwayStatus] = useState('');

    const [disword, setdisword] = useState(encodeURIComponent(' '))
    const [dis, setdistributordata1] = useState([])
    const dispatch = useDispatch();
    const [jobsheetno, setJobsheetno] = useState([])
    const [jobsheetdate, setJobsheetdate] = useState([])
    const [jobtype, setJobtype] = useState([])
    const [remarks, setRemarks] = useState([])
    const [grandtotal, setGrandtotal] = useState([])
    const [warehouse, setWarehouse] = useState([])
    const [supplier, setSupplier] = useState([])
    const [jobsheetItems, setJobsheetItems] = useState([])
    const items = useSelector(state => state.Addcarinjobsheet.items);
// console.log((items.map((item)=>Number(item.amt))))
// const checkqty=useSelector(state=>state.Addcarinjobsheet)
console.log(items)
let sum
if(items){
   sum = items.reduce((acc, item) => acc + Number(item.amount), 0);
console.log(sum)
}
const [comapny, setComapny] = useState([])
const companydetails=()=>{
  
  axios.get(`${BASE_URL}company/getAll`, { headers: authHeader() })
  .then(response => {
      console.log(response.data[0].cname)
      setComapny(response.data)
setFromTrdName(response.data[0].cname)
setFromGstin(response.data[0].gst)
setFromAddr1(response.data[0].address)
setFromPlace(response.data[0].cities)
setFromPincode(response.data[0].zipcode)
setFromStateCode(27)
      // console.log(response.data.data.map(use => ({ value: use.id, label: use.product_Name })))
  })
}

 useEffect(() => {
  dispatch(emptyCart());
  companydetails();
 }, [])
 
    useEffect(() => {

        axios.get(`${BASE_URL}product/page/0/200/asc/id/${disword.length?disword:encodeURIComponent(' ')}`, { headers: authHeader() })
            .then(response => {
                console.log(response)
                if (response.data.data == undefined) {
                    setdistributordata1(response.data.Index.map(use => ({ value: use.id, label: use.product_Name })));
                } if (response.data.Index == undefined) {
                    setdistributordata1(response.data.data.map(use => ({ value: use.id, label: use.product_Name })));

                }

                // console.log(response.data.data.map(use => ({ value: use.id, label: use.product_Name })))
            })




    }, [ disword])
    const[data11,setdata11]=useState([])
    const [disword11, setdisword11] = useState(encodeURIComponent(' '))
const [distributordata111, setdistributordata111] = useState([])
useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}transporter/page/0/200/asc/id/${disword11.length ? disword11 : encodeURIComponent(' ')}`,
        { headers: authHeader() }
      );

      let options = [];
      if (response.data.Index) {
        setdata11(response.data.Index)
        options = response.data.Index.map(item => ({
          value: item.id,
          label: item.transporter_Gstin,
        }));
      } else if (response.data.data) {
        setdata11(response.data.data)
        options = response.data.data.map(item => ({
          value: item.id,
          label: item.transporter_Gstin,
        }));
      } else {
        console.warn('Unexpected response structure:', response.data);
      }

      // Add a placeholder option at the beginning
      const optionsWithPlaceholder = [
        // { value: '', label: 'Select an option' }, // Placeholder option
        ...options,
      ];

      setdistributordata111(optionsWithPlaceholder);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, [disword11]);


    const disid=(val)=>{
        console.log(typeof val)
        // if(typeof val=='number'){
// alert('its number')
ProductService.getProductEdit(val).then(res=>{
    console.log([res.data])
  const productdetails=[res.data]
  console.log(productdetails.map((item)=>item.id))
  const extractedData = productdetails.map(item => ({
    id:item.id,
    productName: item.productName,
    dlp: item.dlp,
    type:item.productType,
    jobsheet_qty:0,
    amount:0,
    product: {
        "id": val
    },
    type1:'box',
    
    jobsheet_qty_kg:0,
    uomSecondary:Number(item.uomSecondary)
}));

// console.log(extractedData[0])
dispatch(addToCart(extractedData[0]));

   })
   // setdistributor(val)
    }
    let onChangeSupplierData = (e) => {

        let name = e.value;

     
    };
    const [disword1, setdisword1] = useState(encodeURIComponent(' '))
    const [dis1,setdistributordata11]=useState([])
    const handleDelete = (id) => {
        dispatch(removeFromCart(id));
    };  
    useEffect(() => {
    
      axios.get(`http://43.225.54.138:8080/scm/api/supplier/page/0/10/asc/id/${disword1.length?disword1:encodeURIComponent(' ')}`, { headers: authHeader() })
      .then(response => {
    console.log(response)
    if(response.data.data==undefined){
        setdistributordata11(response.data.Index.map(use => ({ value: use.id, label: use.suppliername})));
   }  if(response.data.Index==undefined){
    setdistributordata11(response.data.data.map(use => ({ value: use.id, label: use.suppliername})));
  
   }
      
//   console.log(response.data.data.map(use => ({ value: use.id, label: use.suppliername})))  
  })
  
  
  
  }, [ disword1])
                         
  const [disword2, setdisword2] = useState(encodeURIComponent(' '))
  const [dis2,setdistributordata2]=useState([])
  

const navigate = useNavigate();

let data=[{value:'Cutting',label:'Cutting'},{value:'Shaping',label:'Shaping'},{value:'Moulding ',label:'Moulding'},{value:'Finishing',label:'Finishing'}]
const modes1=[
  { "id": 1, "label": "Road" },
  { "id": 2, "label": "Rail" },
  { "id": 3, "label": "Air" },
  { "id": 4, "label": "Ship" },
 ]

//  function formatDate(dateStr) {
//   const [year, month, day] = dateStr.split('-');
//   // Return the formatted string
//   return `${day}/${month}/${year}`;
// }

const handleclikc=()=>{
    setIsLoading(true); 

    // items
    // items
    let extractedData = items.map(item => ({
        // id:item.id,
        amount:item.amount,
        dlp: item.dlp,
      jobsheet_qty:item.jobsheet_qty,
      productType:item.type1!=='kg'?'pcs':'kg',
         product: {
            "id": item.id
        },
       jobsheet_qty_kg:item.jobsheet_qty_kg,
        // uomSecondary:Number(item.uomSecondary)
    }));

   

//    let d2ata=[
//     {
//         "productType": "pcs",
//         "jobsheet_qty": 20,
//         "jobsheet_qty_kg": 10,
//         "amount": 100,
//         "rate": 20,
//         "mrp": 10,
//         "product": {
//             "id": 6
//         }
//     }
// ]
// console.log(d2ata)
    // console.log([{jobsheetno,jobsheetdate,jobtype,remarks,grandtotal:sum,warehouse:{'id':warehouse},supplier:{'id':supplier},jobsheetItems:items}])
   
    const data = [{
      // jobsheetno: '',
      jobsheetdate:jobsheetdate,
      jobtype: jobtype,
      remarks: remarks,
      grandtotal: sum,
      warehouse: { id: Number(warehouse) },
      supplier: { id: Number(supplier) },
      jobworkoutwarditems: extractedData,   
      // subSupplyType: subSupplyType,
      // docType: docType,
      // docNo: jobsheetno,
      docDate: jobsheetdate,
      fromGstin: fromGstin,
      fromTrdName: fromTrdName,
      fromAddr1: fromAddr1,
      fromAddr2: fromAddr2,
      fromPlace: fromPlace,
      fromPincode: fromPincode,
       fromStateCode: fromStateCode,
      toGstin: toGstin,
      toTrdName: toTrdName,
      toAddr1: toAddr1,
      toAddr2: toAddr2,
      toPlace: toPlace,
      toPincode: toPincode,
       toStateCode: toStateCode,
      transactionType: transactionType,
      transporterId: transporterId,
      transporterName: transporterName,
      transDocNo: transDocNo,
      transMode: transMode,
      transDistance: transDistance,
      transDocDate: transDocDate,
      vehicleNo: vehicleNo,
      vehicleType: vehicleType,
      ewayBillNo: ewayBillNo,
      ewayBillDate: ewayBillDate,
      validUpto: validUpto,
      alert: alert,
      // eway_status: eway_status
    }];
    
    axios.post(BASE_URL+"jobworkoutward/", data[0], {
        headers: authHeader()
      })
        .then(response => {
          console.log('Post request successful:', response.data);
          dispatch(emptyCart());
          navigate(`/Outwardtable/1`)

        //   Inwardtable
        //   alert('sent')
        }) .catch(error => {
            console.error('Error adding  :', error);
            if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `
                <div>
                  <p id="issueText">Why do I have this issue?</p>
                  <ul>
              
                  <li id="typer1">You might have an internet issue</li>
                  </ul>
                </div>
              `,
              didOpen: () => {
                applyFontSizeTransition('issueText');
              }
            });
          }
          })
          .finally(() => {
            setIsLoading(false); 
          });
}

// "jobsheetno": "101",
// "jobsheetdate": "2024-05-10",
// "jobtype": "cutting",
// "remarks": "cut",
// "grandtotal": 100,
// "warehouse": {
//     "id": 1
// },
// "supplier": {
//     "id": 10
// },
// "jobsheetItems"
const handleDocTypeChange=(selectedOption)=>{
setDocType(selectedOption.id)
console.log(selectedOption.id)
}
const handlesupplytype=(selectedOption)=>{
  setSubSupplyType(selectedOption.id)
}
const handlemodes=(selectedOption)=>{
  setTransMode(selectedOption.id)
}
const handletype=(selectedOption)=>{
setTransactionType(selectedOption.id)
}
{/* <Select options={modes1} className='sett' onChange={handlemodes}  /> */}
const transtype=[
  { "id": 1, "label": "Regular" },
  { "id": 2, "label": "Bill To - Ship To" },
  { "id": 3, "label": "Bill From - Dispatch From" },
  { "id": 4, "label": "Combination of 2 and 3" }
]
const vechiletype=[
  { "id": "R", "label": "Regular" },
  { "id": "O", "label": "Over Dimensional Cargo" }
]
const handlevtype=(selectedOption)=>{
  setVehicleType(selectedOption.id)
}

const handletrans=(e)=>{
setTransporterId(e.label)
console.log(e)
console.log((data11.filter((item)=>item.id==e.value).map((item)=>item.transporter_Name))
)
setTransporterName((data11.filter((item)=>item.id==e.value).map((item)=>item.transporter_Name))[0])
} 

useEffect(() => {
  
  axios.get(`http://43.225.54.138:8080/scm/api/warehouse/page/0/10/asc/id/${disword2.length?disword2:encodeURIComponent(' ')}`, { headers: authHeader() })
  .then(response => {
console.log(response)
if(response.data.data==undefined){
  setdistributordata2(response.data.Index.map(use => ({ value: use.id, label: use.name})));
}  if(response.data.Index==undefined){
  setdistributordata2(response.data.data.map(use => ({ value: use.id, label: use.name})));
}  
// console.log(response.data.data.map(use => ({ value: use.id, label: use.suppliername})))  
})
}, [ disword2])

const finsupplier=(e)=>{
  setSupplier(e)
  const name=e
axios.get(`${BASE_URL}supplier/getById/${name}`,{headers:authHeader()}).then(response=>{
  console.log(response.data)
  setToTrdName(response.data.companyname)
  setToGstin(response.data.gstno)
setToPlace(response.data.cities)
setToPincode(response.data.zipcode)
setToStateCode(Number(response.data.statecode))
})


}

return (
        <div>
            <div className='card'>
                <div className='card-body'>
                    <div className='card-title card-header'>
                        Jobsheet Outward
                    </div>
                    <div className='row'>
                        {/* <div className='col'> */}
                            {/* <label className='form-label'>Jobsheet Number</label> */}
                            {/* <input type='text' className='form-control' onChange={(e)=>setJobsheetno(e.target.value)}/> */}
                        {/* </div> */}
                        <div className='col'>
                            <label className='form-label'>  Date</label>
                            <input type='Date' className='form-control' onChange={(e)=>setJobsheetdate(e.target.value)}/>
                        </div>
                        
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <label className='form-label'>Supplier</label>
                            <input type='text' style={{border:'none',background:'white'}} disabled/>
                            <Select options={dis1} className='sett'  onInputChange={(inputValue) => setdisword1(inputValue)} onChange={(options) => finsupplier(options.value)}/>
                        </div>
                        <div className='col'>
                            <label className='form-label'>Warehouse</label>
                            <Select options={dis2} className='sett'  onInputChange={(inputValue) => setdisword2(inputValue)} onChange={(options) => setWarehouse(options.value)}/>
                        </div>
                        <div className='col'>
                            <label className='form-label'>Job Type</label>
                            <Select options={data} className='sett'  onInputChange={(inputValue) => setdisword1(inputValue)} onChange={(options) => setJobtype(options.value)}/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <label className='form-label'>Remarks</label>
                            <textarea className='form-control' onChange={(e)=>setRemarks(e.target.value)}>

                            </textarea>
                        </div>

                    </div><br></br>
                    <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
                <Accordion.Header className='form-label'>Delivery Note</Accordion.Header>
                <br></br>
                {/* <br></br> */}
                <Accordion.Body>
                <div className='row'>
                       
                        
                <div className='col'>
    <label className='form-label'>Transaction Type</label>
    <Select options={transtype} className='sett' onChange={handletype}  />

    {/* <input type="text" className='form-control' onChange={(e)=>setTransactionType(e.target.value)}/> */}
  </div>
  <br></br>
{/* fromGstin neelam gst number */}
                        
                    </div>
                  <h1>Delivery From</h1>
<div className='row'>
  <div className='col'>

  <label className='form-label'>Trade Name</label>
                            {/* <Select options={doctype} className='sett'   /> */}
                            <input type='TEXT' value={fromTrdName} className='form-control'  onChange={(e)=>setFromTrdName(e.target.value)}/>

  </div>

  <div className='col'>

<label className='form-label'>GSTIN/UIN</label>
                          {/* <Select options={doctype} className='sett'   /> */}
                          <input type='TEXT'value={fromGstin} className='form-control' onChange={(e)=>setFromGstin(e.target.value)}/>

</div>

</div>
<div className='row'>
<div className='col'>

<label className='form-label'>Address 1</label>
                          {/* <Select options={doctype} className='sett'   /> */}
                          <input type='TEXT' value={fromAddr1} className='form-control' onChange={(e)=>setFromAddr1(e.target.value)}/>

</div>
<div className='col'>

<label className='form-label'>Address 2</label>
                          {/* <Select options={doctype} className='sett'   /> */}
                          <input type='TEXT' className='form-control' onChange={(e)=>setFromAddr2(e.target.value)}/>

</div>

</div>
<div className='row'>
  <div className='col'>
    <div className='form-group'>
      <label htmlFor='city' className='form-label'>City</label>
      <input type='text'   className='form-control'value={fromPlace } onChange={(e)=>setFromPlace(e.target.value)}/>
    </div>
  </div>
  <div className='col'>
    <div className='form-group'>
      <label  className='form-label'>Pin-Code</label>
      <input type='text'   className='form-control' value={fromPincode} onChange={(e)=>setFromPincode(e.target.value)}/>
    </div>
  </div>
  {/* <div className='col'>
    <div className='form-group'>
      <label className='form-label'>Act-From-State-Code</label>
      <input type='text'  className='form-control' onChange={(e)=>setActFromStateCode(e.target.value)}/>
    </div>
  </div> */}
  <div className='col'>
    <div className='form-group'>
      <label  className='form-label'>From-State-Code</label>
      <input type='text'  className='form-control' value={fromStateCode} onChange={(e)=>setFromStateCode(e.target.value)}/>
    </div>
   
  </div>






</div>
<br></br>
<h1>Delivery To</h1>

<div className='row'>
  
  <div className='col'>
    <label className='form-label'>To TradeName</label>
    <input className='form-control' value={toTrdName} type='text' onChange={(e)=>setToTrdName(e.target.value)}/>
  </div>
  <div className='col'>
    <label className='form-label'>To GST</label>
    <input className='form-control' value={toGstin} type="text" onChange={(e)=>setToGstin(e.target.value)}/>
  </div>
</div>
<div className='row'>
  <div className='col'>
    <label className='form-label'>To Address 1</label>
    <input className='form-control' value={toAddr1} type='text' onChange={(e)=>setToAddr1(e.target.value)}/>
  </div>
  <div className='col'>
    <label className='form-label'>To Address 2</label>
    <input className='form-control' type='text' onChange={(e)=>setToAddr2(e.target.value)}/>
  </div>


</div>
<br></br>
<div className='row'>
  <div className='col'>
    <label className='form-label'>To Place</label>
    <input type='text'  className='form-control' value={toPlace} onChange={(e)=>setToPlace(e.target.value)}/>
  </div>
  <div className='col'>
    <label className='form-label'>To Pincode</label>
    <input type='text' className='form-control' value={toPincode} onChange={(e)=>setToPincode(e.target.value)}/>
  </div>
  {/* <div className='col'>
    <label className='form-label'>Act To State</label>
    <input type='text' className='form-control' onChange={(e)=>setActToStateCode(e.target.value)}/>
  </div> */}
  <div className='col'>
    <label className='form-label'>To StateCode</label>
    <input type='text' className='form-control' value={toStateCode} onChange={(e)=>setToStateCode(e.target.value)}/>
  </div>

</div>
<br></br>
<h1>Transporter Details</h1>
<div className='row'>
  <div className='col-6'>
    <label className='form-label'>Transporter Id</label>
    {/* <input className='form-control' type='text' onChange={(e)=>setTransporterId(e.target.value)}/> */}
    <Select options={distributordata111} className='sett'  onInputChange={(inputValue) => setdisword11(inputValue)} onChange={handletrans}/>

  </div>
  <div className='col-6'>
    <label className='form-label'>Transporter Name</label>
    <input className='form-control' type='text' value={transporterName} onChange={(e)=>setTransporterName(e.target.value)}/>

  </div>
  <br></br>
  <h1>Part B Details</h1>
  <div className='col'>
    <label className='form-label'>Transporter Doc No  </label>
    <input className='form-control' type='text' onChange={(e)=>setTransDocNo(e.target.value)}/>

  </div>

  <div className='col'>
    <label className='form-label'>Transporter Mode</label>
    {/* <input className='form-control' type='text' onChange={(e)=>setTransMode(e.target.value)}/> */}
    <Select options={modes1} className='sett' onChange={handlemodes}  />
  </div>
</div>
<div className='row'>
  <div className='col'>
  <label className='form-label'>Transporter Distance</label>
  <input className='form-control' type='text' onChange={(e)=>setTransDistance(e.target.value)}/>
  </div>
  {/* width: 147px; */}
    {/* height: 33px; */}
    {/* margin-top: 44px; */}
    <a 
  href="https://einvoice1.gst.gov.in/Others/GetPinCodeDistance" 
  className='btn btn-primary' 
  style={{ 
    display: 'inline-block', 
    width: '147px', 
    height: '33px', 
    marginTop: '44px', 
    textDecoration: 'none' ,
    background:"#1A42B7!important"
  }}
  target="_blank"
  rel="noopener noreferrer"
>
  <span style={{ position: 'relative', top: '-4px' }}>Distance</span>
</a>

  {/* <button className='btn btn-primary'style={{width:'147px',height:"33px",marginTop:'44px'}}><span style={{position:'relative',top:'-4px'}}>Distance</span></button> */}
  <div className='col'>
  <label className='form-label'>Transporter Doc Date</label>
  <input className='form-control' type='date' onChange={(e)=>setTransDocDate(e.target.value)}/>
  </div>
  <div className='col'>
    <label className='form-label'>Vehicle No</label>
    <input className='form-control' type='text' onChange={(e)=>setVehicleNo(e.target.value)}/>

  </div>

  <div className='col'>
    <div className='form-label'>Vehicle Type</div>
    {/* <input className='form-control' type='text' onChange={(e)=>setVehicleType(e.target.value)}/> */}
    <Select options={vechiletype}   className='sett' onChange={handlevtype}  />

  </div>
</div>

 
                 </Accordion.Body>
            </Accordion.Item>
        </Accordion>

        <br></br>

                    {/* <input type='text' /> */}
                    <Select options={dis} onInputChange={(inputValue) => setdisword(inputValue)} onChange={(options) => disid(options.value)}/>
                    <br></br>
                    <table className='table thrtable '>
                        <thead>
                            <tr>
                                <th>Product Name</th>
                                <th>Product Type</th>
                                <th>Qty  </th>
                                <th>Qty kg</th>
                                <th>Rate  </th>
                                <th>Amount</th>
                                <th  width='50' height='12'>Action</th>
                            </tr>
                        </thead>
<tbody>
    {items.map((item)=>{
return<>
<tr>
{/* {item.type} */}
<td>{item.productName}</td>
<td width='150' height='12'><select  className='form-select'  onChange={(e) => {
    dispatch(updateItem(item.id, e.target.value, item.jobsheet_qty, item.dlp,item.jobsheet_qty_kg,item.amount));
  }}>
    <option>Select</option>
    <option value='box'>Box</option>
   {item.type=='kgproduct'?<option value='kg'>KG</option>:''}
    </select></td>
<td width='150' height='12'><input type='number' min='0' value={Number(item.jobsheet_qty)} disabled={item.type1=='kg'?true:false} onChange={(e) => {
    dispatch(updateItem(item.id, item.type1, Number(e.target.value), item.dlp,item.jobsheet_qty_kg,item.amount));
  }} className='form-control'/></td>
  {/* <td>{item.qtykg}</td> */}
  <td width='150' height='12'><input type='number'  min='0' value={Number(item.jobsheet_qty_kg)} disabled={item.type1=='box'?true:false} onChange={(e) => {
    dispatch(updateItem(item.id, item.type1,item.jobsheet_qty, item.dlp, e.target.value,item.amount));
  }}className='form-control'/></td>
<td width='150' height='12'><input type='number'  min='0' value={Number(item.dlp)} onChange={(e) => {
    dispatch(updateItem(item.id, item.type1,item.jobsheet_qty,Number(e.target.value), item.jobsheet_qty_kg,item.amount));
  }}className='form-control'/></td>
<td width='150' height='12'><input type='number'  min='0' value={Number(item.amount)} className='form-control'/></td>
<td>    <button onClick={() => handleDelete(item.id)}>Delete</button></td>
</tr>

</>

    })}
</tbody>

                    </table>
                    <div className='row'>
                        <div className='col tomforgrandtotal'>
                            Total:{sum.toFixed(2)}
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col'>
                        <Button variant="primary" style={{width:'auto'}} onClick={()=>handleclikc()} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Submit'}
        </Button>
                       
                            {/* <button className='btn btn-primary' onClick={()=>handleclikc()}>Submit</button> */}
                        </div>
                    </div>
                </div>

            </div>



        </div>
    )
}

export default Jobsheetoutwardcreate