import axios from "axios";
import { MDBCard } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../services/Api";
import authHeader from "../services/auth-header";
import Swal from "sweetalert2";

export default function Update(){
    const {id} = useParams();
    const [voucherseries,setvoucherseries] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const applyFontSizeTransition = (elementId) => {
      const element = document.getElementById(elementId);
      element.style.transition = 'font-size 2s';
      element.style.fontSize = '30px';
      element.style.fontWeight = '600';
      // font-weight: 600;
      setTimeout(() => {
         
        document.getElementById("typer1").classList.add('promote');
        element.style.fontSize = '24px';
      }, 1000);
    };
    
    let Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
    useEffect(()=>{
        axios.get(BASE_URL + 'voucher/getbyid/'+id,{
            headers:authHeader()
        }).then(res=>{
            setvoucherseries(res.data.voucherseries);
        })
    },[]);

    const submitdata = () => {
        setIsLoading(true); 
        axios.put(BASE_URL + 'voucher/update/'+id,{voucherseries},{
            headers:authHeader()
        }).then(res=>{
            navigate('/Voucher/Table/1');
            if(res.status==201||res.status==200){
              // alert("ee")
              Toast.fire({
                icon: 'success',
                title: 'Successfully Updated'
              })
            }
        }).catch(error=>{
              
            if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `
                  <div>
                    <p id="issueText">Why do I have this issue?</p>
                    <ul>
                
                    <li id="typer1">You might have an internet issue</li>
                    </ul>
                  </div>
                `,
                didOpen: () => {
                  applyFontSizeTransition('issueText');
                }
              });
            }else{
              Swal.fire({
                  title: 'This Product is already Created',
                  text: "You won't be able to Add!",
                  icon: 'warning',
                })
          
            }
          })
          .finally(() => {
          setIsLoading(false); 
          });
    }

    return(
        <MDBCard className="company">
            <h1>Voucher Update</h1>
            <Link className='p-2 w-200 card-header card-title bd-highlight' to={`/Voucher/Table/1`}><Button>Back</Button></Link>

            <Form className="product1">
            <Row className="mb-1">
                <Form.Group as={Col} md="6" controlId="formBasicName">
                    <Form.Label className="label">Voucher Series</Form.Label>
                    <Form.Control type="text" name="voucherseries" value={voucherseries} autoComplete="off" placeholder="Enter Voucher Series" onChange={(e)=>setvoucherseries(e.target.value)} />
                </Form.Group>
                </Row>
                <Button variant="primary" style={{width:'auto'}} onClick={submitdata} disabled={isLoading}>
      {isLoading ? 'Loading...' : 'Submit'}
    </Button>

            </Form>
        </MDBCard>
    )
}