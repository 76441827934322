import axios from 'axios';
import { MDBCard } from 'mdbreact';
import React, { useEffect, useState } from 'react';
import { Accordion, Button, Col, Form, Row, Table } from 'react-bootstrap';
import Select from 'react-select';
import { BASE_URL } from '../services/Api';
import authHeader from '../services/auth-header';
import ProductService from '../services/ProductService';
import { changecostdeliverychallan, changedeliverychallandiscount, changedeliverychallansqpbqty, changedeliverychallansqpbqtykg, changedeliverychallantext, changeunitofMeasurementdeliverychallan, deliverychallanaddtocart, deliverychallancalmeasurement, emptydeliverychallandata, removefromcartfromdeliverychallan } from '../../redux/Action/DeliveryChallan';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import Swal from 'sweetalert2';

export default function Create1() {
    const [voucherid, setvoucher] = useState([])
    const Onchangevoucher = (e) => {
        setvoucher(e.label)
    }
    const [dcdate, setdcdate] = useState(new Date());
    const [type, settype] = useState('b2b');
    const [status, setstatus] = useState('true');
    const [taxtype, settaxtype] = useState('withtax');
    const [buyerorderno, setbuyerorderno] = useState('');
    const [buyerorderdate, setbuyerorderdate] = useState('');
    const [totalnopkg, settotalnopkg] = useState('');
    const [deliverynotno, setdeliverynotno] = useState('');
    const [dispatchedthrough, setdispatchedthrough] = useState('');
    const [destination, setdestination] = useState('');
    const [termsofdelivery, settermsofdelivery] = useState('');
    const [irnno, setirnno] = useState('');
    const [ackno, setackno] = useState('');
    const [ackdate, setackdate] = useState('');
    const [msmeno, setmsmeno] = useState('');
    const [productdata, setproductdata] = useState([]);
    const [branchdata, setbranchdata] = useState([]);
    const [branch, setbranch] = useState('');
    const [customerData, setCustomerData] = useState([]);
    const [customer, setcustomer] = useState('');
    const [distributor, setdistributor] = useState('');
    const [retailer, setretailer] = useState('');
    const [warehousehdata, setwarehousehdata] = useState([]);
    const [warehousehvalue, setwarehousehvalue] = useState([]);
    const [warehouse, setwarehouse] = useState('');
    const [bstateid, setbstateid] = useState('');
    const [dstateid, setdstateid] = useState('');
    const [boxProductDiscount, setboxProductDiscount] = useState('');
    const [schemeboxProductDiscount, setschemeboxProductDiscount] = useState('');
    const [kgProductDiscount, setkgProductDiscount] = useState('');
    const [schemekgProductDiscount, setschemekgProductDiscount] = useState('');
    const [corporaetProductDiscount, setcorporaetProductDiscount] = useState('');
    const [schemecorporateProductDiscount, setschemecorporateProductDiscount] = useState('');
    const [cookerProductDiscount, setcookerProductDiscount] = useState('');
    const [schemecookerProductDiscount, setschemecookerProductDiscount] = useState('');
    const [noshProductDiscount, setnoshProductDiscount] = useState('');
    const [schemenoshProductDisocunt, setschemenoshProductDisocunt] = useState('');
    const [shippingcharge, setShipping] = useState(0);
    const [shippingfortax, setshippingfortax] = useState('');
    const [round, setRound] = useState(0);
    const [paymentTerms, setpaymentTerms] = useState('');
    const [deliveryAddress, setdelval] = useState([]);
    const [deliveryAddress1, setthedel] = useState([]);
    const [disdel, setdel] = useState([]);
    const [udyamno, setudyamno] = useState('');
    const [gstno, setgstno] = useState('');
    const [igstoutput, setigstoutput] = useState('');
    const [bankname,setbankname] = useState('');
    const [accountno,setaccountno] = useState('');
    const [branchname,setbranchname] = useState('');
    const [ifsccode,setifsccode] = useState('');
    // const [deliveryAddress1,setthedel]=useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const applyFontSizeTransition = (elementId) => {
      const element = document.getElementById(elementId);
      element.style.transition = 'font-size 2s';
      element.style.fontSize = '30px';
      element.style.fontWeight = '600';
      // font-weight: 600;
      setTimeout(() => {
         
        document.getElementById("typer1").classList.add('promote');
        element.style.fontSize = '24px';
      }, 1000);
    };

    const dispatch = useDispatch();
    const dcItems = useSelector((state) => state.deliverychallandata);
    // console.log("mello", dcItems)
    let gross_Amount = dcItems.length && dcItems.map(item => Math.round(item.total)).reduce((prev, next) => Math.round(prev - (-next)));
    let gst = dcItems.length && dcItems.map(item => (item.gstvalue)).reduce((prev, next) => (prev - (-next)));

    let aa = [];
    dcItems.map(user => {
        let igst_value = user.igst;
        let total_value = user.total;
        let total_amou = ((Number(shippingcharge) / Number(gross_Amount)) * Number(total_value));
        let add_amou = Number(total_amou) - (-Number(total_value));
        let final_amou = ((Number(add_amou) / 100) * Number(igst_value)).toFixed(2);
        aa.push(final_amou)

        // console.log("mello",((Number(add_amou)/100)*Number(igst_value)).reduce((prev, next) => Math.round(prev - (-next))) );
    })
    let total_per = aa.length && aa.map(user => user).reduce((prev, next) => (prev - (-next)));


    let grossAmount = Number(gross_Amount) + Number(shippingcharge);
    let grandTotal = Number(gross_Amount) + Number(shippingcharge) + Number(round) + Number(total_per);



    let igstTax = total_per == "" ? Number(gst) : Number(total_per);


    let cgstTax = total_per == "" ? (Number(gst) / 2) : (Number(total_per)) / 2;
    let sgstTax = total_per == "" ? (Number(gst) / 2) : (Number(total_per)) / 2;

    const navigate = useNavigate();

    function roundData(e) {
        if (e) {

            let gross_Amount = dcItems.length && dcItems.map(item => Math.round(item.total)).reduce((prev, next) => Math.round(prev - (-next)));
            let gst = dcItems.length && dcItems.map(item => (item.gstvalue)).reduce((prev, next) => (prev - (-next)));

            let aa = [];
            dcItems.map(user => {
                let igst_value = user.igst;
                let total_value = user.total;
                let total_amou = ((Number(shippingcharge) / Number(gross_Amount)) * Number(total_value));
                let add_amou = Number(total_amou) - (-Number(total_value));
                let final_amou = ((Number(add_amou) / 100) * Number(igst_value)).toFixed(2);
                aa.push(final_amou)

                // console.log("mello",((Number(add_amou)/100)*Number(igst_value)).reduce((prev, next) => Math.round(prev - (-next))) );
            })
            let total_per = aa.length && aa.map(user => user).reduce((prev, next) => (prev - (-next)));


            let grossAmount = Number(gross_Amount) + Number(shippingcharge);
            let grandTotal = Number(gross_Amount) + Number(shippingcharge) + Number(total_per);
            let grand_Total = Math.ceil(grandTotal);

            if (grand_Total > grandTotal) {
                let rou_nd = (Number(grand_Total) - Number(grandTotal)).toFixed(2);
                setRound(rou_nd);
            } else {
                setRound(0)
            }


        } else if (e == '') {
            setRound(0)
        }
    }
    /* Get Branch Data */

    const getbranchdata = () => {

        axios.get(BASE_URL + 'branch/getAll', {
            headers: authHeader()
        }).then(res => {
            // console.log("mello",res.data)
            setbranchdata((res.data).map(user => ({ value: user.id, label: user.branchname })));
        })
    };


    const getProductAll = () => {

        axios.get(BASE_URL + 'product/page/0/100/asc/id/%20', {
            headers: authHeader()
        }).then(res => {

            setproductdata((res.data.Index).map(use => ({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code + ' - ' + use.short_Name })));
        })
    };

    const customFilter = (e) => {
        let name = e;
        axios.get(BASE_URL + 'product/page/0/100/asc/id/' + name, {
            headers: authHeader()
        }).then(res => {
            setproductdata((res.data.data).map(use => ({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code + ' - ' + use.short_Name })))
        })
    };
    useEffect(() => {
        getbranchdata();
        getProductAll();
        dispatch(emptydeliverychallandata());
    }, []);

    /* Change Distributor */
    const onChangeCustomer = (e) => {
        // console.log("mello",e);
        let name = e.value;
        setdistributor({ "id": e.value });

        axios.get(BASE_URL + 'distributor/getById/' + name, {
            headers: authHeader()
        }).then(res => {
            setdel(res.data.deliveryAddress)

            // console.log("mello",res.data)
            setdstateid(res.data.stateid);
            setboxProductDiscount(res.data.boxProductDiscount);
            setschemeboxProductDiscount(res.data.schemeboxProductDiscount);
            setkgProductDiscount(res.data.kgProductDiscount);
            setschemekgProductDiscount(res.data.schemekgProductDiscount);
            setcorporaetProductDiscount(res.data.corporaetProductDiscount);
            setschemecorporateProductDiscount(res.data.schemecorporateProductDiscount);
            setcookerProductDiscount(res.data.cookerProductDiscount);
            setschemecookerProductDiscount(res.data.schemecookerProductDiscount);
            setnoshProductDiscount(res.data.noshProductDiscount);
            setschemenoshProductDisocunt(res.data.schemenoshProductDisocunt);
        });


    };

        /* Change Retailer */
        const onChangeCustomer1 = (e) => {
            // console.log("mello",e);
            let name = e.value;
            setretailer({ "id": e.value });
    
            axios.get(BASE_URL + 'retailer/getById/' + name, {
                headers: authHeader()
            }).then(res => {
                setdel(res.data.deliveryAddress)
    
                // console.log("mello",res.data)
                setdstateid(res.data.stateid);
                setboxProductDiscount(res.data.boxProductDiscount);
                setschemeboxProductDiscount(res.data.schemeboxProductDiscount);
                setkgProductDiscount(res.data.kgProductDiscount);
                setschemekgProductDiscount(res.data.schemekgProductDiscount);
                setcorporaetProductDiscount(res.data.corporaetProductDiscount);
                setschemecorporateProductDiscount(res.data.schemecorporateProductDiscount);
                setcookerProductDiscount(res.data.cookerProductDiscount);
                setschemecookerProductDiscount(res.data.schemecookerProductDiscount);
                setnoshProductDiscount(res.data.noshProductDiscount);
                setschemenoshProductDisocunt(res.data.schemenoshProductDisocunt);
            });
    
    
        };

    /* Change Branch  */
    let onChangeBranch = (e) => {
        let name = e.value;
        setbranch({ "id": e.value });
        setwarehousehvalue("");

        axios.get(BASE_URL + 'branch/' + name, {
            headers: authHeader()
        }).then(res => {
            // console.log("mello",res.data)
            setbstateid(res.data.states.id)
        })

        axios.get(BASE_URL + 'warehouse/getAll', {
            headers: authHeader()
        }).then(res => {
            let branch_data = [];
            (res.data).map(user => {
                // let branchid = user.branch.id;
                if (user.branch !== null) {
                    if (user.branch.id === e.value) {
                        branch_data.push({ value: user.id, label: user.name })
                    }
                }

            })

            setwarehousehdata(branch_data)

        })
    };

    /* Change WareHouse */
    const onChangewarehouse = (e) => {
        setwarehouse({ "id": e.value })
        setwarehousehvalue({ value: e.value, label: e.label })
    };


    /* Send Action in Product ID */
    let addData = (e) => {
        let name = e.value;
        //  console.log("mello",customerdata)
        let filteredItems = dcItems.filter(item => item.product.id == name);
        if (branch == "" || distributor == "" && retailer == '') {
            Swal.fire({
                title: 'Please Branch or Distributor First Select',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonColor: 'red'
            })
        } else {
            if (filteredItems.length == "0") {
                dispatch(deliverychallanaddtocart(name, ({
                    "schemeboxProductDiscount": schemeboxProductDiscount,
                    "schemekgProductDiscount": schemekgProductDiscount,
                    "schemecorporateProductDiscount": schemecorporateProductDiscount,
                    "schemecookerProductDiscount": schemecookerProductDiscount,
                    "schemenoshProductDisocunt": schemenoshProductDisocunt,
                    "kgProductDiscount": kgProductDiscount,
                    "boxProductDiscount": boxProductDiscount,
                    "corporaetProductDiscount": corporaetProductDiscount,
                    "cookerProductDiscount": cookerProductDiscount,
                    "noshProductDiscount": noshProductDiscount
                })));
                setShipping("0");
            } else {
                Swal.fire({
                    title: 'This Product is Already Added',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showConfirmButton: false,
                    showCancelButton: true,
                    cancelButtonColor: 'red'
                })
            }
        }
    }

    let date_value = new Date(dcdate);
    const defaultValue = date_value.toLocaleDateString('en-CA');

    /* get Shipping Charges */
    let settingship = (e) => {
        if (e == "") {

            setShipping("");
        } else {

            setShipping(e);
        }

    };

    /* get Round Value */
    const settinground = (e) => {

        if (e == "") {

            setRound("");
        } else {


            setRound(e);
        }

    };

    const SGSTcheck = () => {
        let aa = 0

        if (taxtype == "withtax") {

            if (bstateid === dstateid) {

                aa = sgstTax;

            } else {
                aa = 0;
            }

        } else {
            aa = 0;
        }

        return aa;

    }

    const cgstcheck = () => {

        let bb = 0

        if (taxtype == "withtax") {

            if (bstateid === dstateid) {

                bb = cgstTax;

            } else {

                bb = 0;

            }

        } else {

            bb = 0;

        }
        return bb;
    }

    const igstcheck = () => {
        let cc = 0;

        if (taxtype == "withtax") {
            if (bstateid === dstateid) {
                cc = 0;
            } else {
                cc = igstTax;
            }
        } else {
            cc = 0;
        }
        return cc;
    }

    const [distributordata12, setdistributordata12] = useState([])
    const [disword12, setdisword12] = useState(encodeURIComponent(' '))

    const submitdata = () => {
        setIsLoading(true);
        let igst = igstTax;
        let cgst = cgstTax;
        let sgst = sgstTax;
        let grandtotal = grandTotal;
        let roundofvalue = round;
        let deliveryAddress = deliveryAddress1
       if(customer === 'distributor'){
        axios.post(BASE_URL + 'dc/', {
            warehouse, distributor, status, taxtype, buyerorderno, buyerorderdate, totalnopkg, termsofdelivery, deliverynotno, dcdate, dispatchedthrough,
            destination, irnno, ackno, ackdate, msmeno, dcItems, udyamno, gstno, igstoutput,bankname,accountno,branchname,ifsccode,
            igst, cgst, deliveryAddress, sgst, grandtotal,voucherid, grossAmount, shippingcharge, roundofvalue, branch, paymentTerms
        }, {
            headers: authHeader()
        }).then(res => {
            navigate('/DeliveryChallanTable/1')
            // console.log('mello', res.data);
        }).catch(error => {
            console.error('Error adding  :', error);
            if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `
                <div>
                  <p id="issueText">Why do I have this issue?</p>
                  <ul>
              
                  <li id="typer1">You might have an internet issue</li>
                  </ul>
                </div>
              `,
              didOpen: () => {
                applyFontSizeTransition('issueText');
              }
            });
          }
          }).finally(() => {
            setIsLoading(false); 
          });
       } else if(customer === 'retailer'){
        axios.post(BASE_URL + 'dc/', {
            warehouse, retailer, status, taxtype, buyerorderno, buyerorderdate, totalnopkg, termsofdelivery, deliverynotno, dcdate, dispatchedthrough,
            destination, irnno, ackno, ackdate, msmeno, dcItems, udyamno, gstno, igstoutput,bankname,accountno,branchname,ifsccode,
            igst, cgst, deliveryAddress, sgst,voucherid, grandtotal, grossAmount, shippingcharge, roundofvalue, branch, paymentTerms
        }, {
            headers: authHeader()
        }).then(res => {
            navigate('/DeliveryChallanTable/1')
            // console.log('mello', res.data);
        }).catch(error => {
            console.error('Error adding  :', error);
            if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `
                <div>
                  <p id="issueText">Why do I have this issue?</p>
                  <ul>
              
                  <li id="typer1">You might have an internet issue</li>
                  </ul>
                </div>
              `,
              didOpen: () => {
                applyFontSizeTransition('issueText');
              }
            });
          }
          }).finally(() => {
            setIsLoading(false); 
          });
       }
        dispatch(emptydeliverychallandata());
    }
    const [disword, setdisword] = useState(encodeURIComponent(' '))
    const [dis, setdistributordata1] = useState([])

    useEffect(() => {
        if (customer === "distributor") {
            axios.get(`http://43.225.54.138:8080/scm/api/distributor/page/0/10/asc/id/${disword.length?disword:encodeURIComponent(' ')}`, { headers: authHeader() })
                .then(response => {
                    console.log(response)
                    if (response.data.data == undefined) {
                        setdistributordata1(response.data.Index.map(use => ({ value: use.id, label: use.trade_Name })));
                    } if (response.data.Index == undefined) {
                        setdistributordata1(response.data.data.map(use => ({ value: use.id, label: use.trade_Name })));

                    }

                    // console.log(response.data.data.map(use => ({ value: use.id, label: use.trade_Name })))
                })
        } else if (customer === 'retailer') {
            axios.get(`http://43.225.54.138:8080/scm/api/retailer/page/0/10/asc/id/${disword.length?disword:encodeURIComponent(' ')}`, { headers: authHeader() })
                .then(response => {
                    //   console.log(response,"mello")
                    if (response.data.data == undefined) {
                        setdistributordata1(response.data.Index.map(use => ({ value: use.id, label: use.retailername })));
                    } if (response.data.Index == undefined) {
                        setdistributordata1(response.data.data.map(use => ({ value: use.id, label: use.retailername })));

                    }

                 })
        }

        axios.get(BASE_URL + `voucher/page/0/100/desc/id/${disword12.length ? disword12 : encodeURIComponent(' ')}`, {
            headers: authHeader()
        }).then(response => {
            if (response.data.data == undefined) {
                setdistributordata12(response.data.Index.map(use => ({ value: use.voucherseries, label: use.voucherseries })));
            } if (response.data.Index == undefined) {
                setdistributordata12(response.data.data.map(use => ({ value: use.voucherseries, label: use.voucherseries })));

            }
            // setproductdata((res.data.Index).map(use => ({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code + ' - ' + use.short_Name })));
        })


    }, [customer,disword,disword12])
    return (
        <MDBCard className="company">
            <h1>DC Create</h1>
            <Link className='p-2 w-200 card-header card-title bd-highlight' to={`/DeliveryChallanTable/1`}><Button>Back</Button></Link>

            <Form className="product1">
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>DC Data</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Choose Customer</Form.Label>
                                    <Form.Select onChange={(e) => setcustomer(e.target.value)}>
                                        <option>Select</option>
                                        <option value={'retailer'}>Retailer</option>
                                        <option value={'distributor'}>Distributor</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Branch Name</Form.Label>
                                    <Select options={branchdata} onChange={(e) => onChangeBranch(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Warehouse Name</Form.Label>
                                    <Select value={warehousehvalue} options={warehousehdata} onChange={(e) => onChangewarehouse(e)} />
                                </Form.Group>
                                {customer === 'distributor' &&
                                    <Form.Group as={Col} md="4" controlId="formBasicName">
                                        <Form.Label className="label">Customer Name</Form.Label>
                                        {/* <Select options={customerData} onChange={(e) => onChangeCustomer(e)} /> */}
                                        <Select options={dis} onInputChange={(inputValue) => setdisword(inputValue)} onChange={(options) => onChangeCustomer(options)} />

                                    </Form.Group>}
                                {customer === 'retailer' &&
                                    <Form.Group as={Col} md="4" controlId="formBasicName">
                                        <Form.Label className="label">Customer Name</Form.Label>
                                        {/* <Select options={customerData} onChange={(e) => onChangeCustomer(e)} /> */}
                                        <Select options={dis} onInputChange={(inputValue) => setdisword(inputValue)} onChange={(options) => onChangeCustomer1(options)} />

                                    </Form.Group>}

                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Date</Form.Label>
                                    <Form.Control type="date" value={defaultValue} onChange={(e) => setdcdate(e.target.value)} />
                                </Form.Group>
                                {/* <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Type</Form.Label>
                                    <Form.Select value={type} onChange={(e) => settype(e.target.value)}>
                                        <option value="b2b">B2B</option>
                                        <option value="b2c">B2C</option>
                                    </Form.Select>
                                </Form.Group> */}
                                  <Form.Group as={Col} md="4" controlId="formBasicName">
                                <Form.Label className="label">Vouchers Series</Form.Label>
                                {/* <Select options={customerData} onChange={(e) => onChangeCustomer(e)} /> */}
                                    <Select options={distributordata12} onInputChange={(inputValue) => setdisword12(inputValue)} onChange={(options) => Onchangevoucher(options)} />

                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Terms of Payment</Form.Label>
                                    <Form.Control type='text' name="paymentTerms" onChange={(e) => setpaymentTerms(e.target.value)} autoComplete="off" />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Status</Form.Label>
                                    <Form.Select value={status} onChange={(e) => setstatus(e.target.value)}>
                                        <option value='true'>Open</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Tax Type</Form.Label>
                                    <Form.Select value={taxtype} onChange={(e) => settaxtype(e.target.value)} disabled>
                                        <option>--Select--</option>
                                        <option value='withtax'>With Tax</option>
                                        <option value='withouttax'>Without Tax</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Buyer’s Order No</Form.Label>
                                    <Form.Control type="text" onChange={(e) => setbuyerorderno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Buyer’s Order Date</Form.Label>
                                    <Form.Control type="date" onChange={(e) => setbuyerorderdate(e.target.value)} />

                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Total No. of PKGs</Form.Label>
                                    <Form.Control type="text" onChange={(e) => settotalnopkg(e.target.value)} />
                                </Form.Group>
                                
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Delivery Note No</Form.Label>
                                    <Form.Control type="text" onChange={(e) => setdeliverynotno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Dispatch Through</Form.Label>
                                    <Form.Control type="text" onChange={(e) => setdispatchedthrough(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Destination</Form.Label>
                                    <Form.Control type="text" onChange={(e) => setdestination(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">TERMS OF DELIVERY</Form.Label>
                                    <Form.Control type="text" onChange={(e) => settermsofdelivery(e.target.value)} />
                                </Form.Group>

                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    {/* <Accordion.Item eventKey="1">
                        <Accordion.Header>DELIVERY ADDRESS</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Name</Form.Label>
                                    <Form.Control type="text" />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Address</Form.Label>
                                    <Form.Control type="text" />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">GST/UIN</Form.Label>
                                    <Form.Control type="text" />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">State Name</Form.Label>
                                    <Form.Control type="text" />
                                </Form.Group>



                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Phone No : -</Form.Label>
                                    <span>&nbsp;{customerSubContactsdata == "" ? customerAll.phonenumber : customerSubContactsdata.phoneno}</span>
                                </Form.Group>

                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Email Id : -</Form.Label>
                                    <span> {customerSubContactsdata == "" ? customerAll.email : customerSubContactsdata.email}</span>
                                </Form.Group>

                            </Row>
                        </Accordion.Body>
                    </Accordion.Item> */}
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>BILLING ADDRESS</Accordion.Header>
                        <Accordion.Body>
                            {/* <Row className="mb-1">
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Name</Form.Label>
                                    <Form.Control type="text" />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Address</Form.Label>
                                    <Form.Control type="text" />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">GST/UIN</Form.Label>
                                    <Form.Control type="text" />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">State Name</Form.Label>
                                    <Form.Control type="text" />
                                </Form.Group>

                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Phone No : -</Form.Label>
                                    <span>&nbsp;{customerSubContactsdata == "" ? customerAll.phonenumber : customerSubContactsdata.phoneno}</span>
                                </Form.Group>

                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Email Id : -</Form.Label>
                                    <span> {customerSubContactsdata == "" ? customerAll.email : customerSubContactsdata.email}</span>
                                </Form.Group>
                            </Row> */}

                            <b><span className="">Delivery Address</span></b> <br></br> <br></br>
                            <Row className="mb-1">
                                {/* <select className="form-select dd">
                                <option className="extrvagnze">select Address</option>
                               
                                {
                                    disdel.map((item)=>{
                                        return<>
                                          <option className="extrvagnze">Address:{item.delivery_address} ,State:-{item.state} &nbsp;,Pin-code:-{item.pincode}</option>
                                        </>
                                    })
                                }
                               </select> */}
                                {/* <Select
      options={options}
      onChange={handleSelectChange}
      filterOption={customFilter11}
      placeholder="Select Address"
      value={selectedOption}
    /> */}
                                <select className='form-select' onChange={(e) => setthedel(e.target.value)}>
                                    <option >Select address</option>
                                    {disdel.map(item => {
                                        return <>
                                            <option value={item.id}>Address:-{item.delivery_address}</option>
                                        </>
                                    })
                                    }

                                </select>

                            </Row>



                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header>Details</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">IRN NO</Form.Label>
                                    <Form.Control type="text" onChange={(e) => setirnno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">ACK NO</Form.Label>
                                    <Form.Control type="text" onChange={(e) => setackno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">ACK DATE</Form.Label>
                                    <Form.Control type="date" onChange={(e) => setackdate(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">MSME NO</Form.Label>
                                    <Form.Control type="text" onChange={(e) => setmsmeno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">UDYAM NO</Form.Label>
                                    <Form.Control type="text" onChange={(e) => setudyamno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">GST NO</Form.Label>
                                    <Form.Control type="text" onChange={(e) => setgstno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">IGST OUTPUT</Form.Label>
                                    <Form.Control type="text" onChange={(e) => setigstoutput(e.target.value)} />
                                </Form.Group>
                                {/* <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Rounding off</Form.Label>
                                    <Form.Control type="text" />
                                </Form.Group> */}

                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="5">
                        <Accordion.Header>Account</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">

                                <h1>Company Bank Details</h1>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Bank Name</Form.Label>
                                    <Form.Control type="text" name="bankname" onChange={(e)=>setbankname(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Account Amount</Form.Label>
                                    <Form.Control type="text" name="accountno" onChange={(e)=>setaccountno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Branch Name</Form.Label>
                                    <Form.Control type="text" name="branchname" onChange={(e)=>setbranchname(e.target.value)}/>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">IFSC Code</Form.Label>
                                    <Form.Control type="text" name="ifsccode" onChange={(e)=>setifsccode(e.target.value)}/>
                                </Form.Group>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <br></br>
                <Row className="mb-1">
                    <Form.Group as={Col} md="12" controlId="formBasicName">
                        <Form.Label className="label">Product</Form.Label>
                        <Select options={productdata} onChange={(e) => addData(e)} onInputChange={(e) => customFilter(e)} />
                    </Form.Group>
                </Row>
                <br></br>
                <Table striped responsive hover size="sm">
                    <thead className='p-3 mb-2 bg-success text-white'>
                        <tr>
                            <th>PRODUCT&nbsp;NAME</th>
                            <th>UOM</th>
                            <th >QTY(PCS)</th>
                            <th >QTY(KG)</th>
                            <th >Rate</th>
                            <th >UOM</th>
                            <th >Trade&nbsp;Discount</th>
                            <th>Gross&nbsp;Amount&nbsp;(₹)</th>
                            <th >Scheme&nbsp;Discount&nbsp;%</th>
                            <th >NET&nbsp;AMOUNT&nbsp;(₹)</th>
                            <th >GST&nbsp;Value</th>
                            <th >Amount</th>
                            <th >Action</th>
                        </tr>
                    </thead>
                    <tbody style={{ overflow: "scroll" }}>
                        {dcItems.map(item =>
                            <tr key={item.product.id}>
                                <td>
                                    <Form.Group as={Col} md="12" >
                                        {/* <Form.Control type="text" value={(item.productName ? item.productName : item.product.productName)} size="sm" /> */}
                                        <textarea type="text" className="form-control textcontrol" value={(item.productName ? item.productName : item.product.productName)} size="sm" ></textarea>
                                    </Form.Group>
                                    <Form.Control as="textarea" onChange={(e) => { dispatch(changedeliverychallantext(e.target.value, item)) }} value={item.text} rows={2} className='form-control' />
                                </td>
                                <td><Form.Group as={Col} md="12">
                                    <Form.Select disabled={item.productType !== "kgproduct" ? true : false} onChange={(e) => { dispatch(changeunitofMeasurementdeliverychallan(e.target.value, item, item.unitofmeasurement)); }} size="sm">
                                        <option>{item.productType !== "kgproduct" ? 'PCS' : "Select"}</option>
                                        <option value="pcs">PCS</option>
                                        <option value="kg">KG</option>
                                    </Form.Select>
                                </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" size="sm" onChange={(e) => { dispatch(changedeliverychallansqpbqty(e.target.value, item, taxtype, item.unitofmeasurement)); roundData(e.target.value) }} disabled={item.unitofmeasurement == "kg" ? true : false||item.unitofmeasurement == "" ? true : false} value={item.dcquantity_placed} />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" onChange={(e) => { dispatch(changedeliverychallansqpbqtykg(e.target.value, item, item.unitofmeasurement, taxtype)); roundData(e.target.value) }} disabled={item.unitofmeasurement == "box"||item.unitofmeasurement == "pcs" ? true : false || item.unitofmeasurement == "" ? true : false} value={Number(item.dcquantity_placed_kg)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" size="sm" onChange={(e) => { dispatch(changecostdeliverychallan(e.target.value, item, taxtype, item.unitofmeasurement)); roundData(e.target.value) }} value={item.dlp} />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Select disabled={item.productType !== "kgproduct" ? true : false} onChange={(e) => { dispatch(deliverychallancalmeasurement(e.target.value, item, item.calunitofmeasurement)); roundData(e.target.value) }} size="sm">
                                            <option>{item.productType !== "kgproduct" ? 'PCS' : "Select"}</option>
                                            <option value="pcs">PCS</option>
                                            <option value="kg">KG</option>
                                        </Form.Select>
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" value={item.tradediscount || ''} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" value={Number(item.grossamount)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" onChange={(e) => { dispatch(changedeliverychallandiscount(e.target.value, item, taxtype)); roundData(e.target.value) }} value={Number(item.schemeDiscount)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" value={Number(item.netAmount)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" value={Number(item.gstvalue)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" value={Number(item.total)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type='button' className='btn btn-danger' onClick={() => { dispatch(removefromcartfromdeliverychallan(item.product.id)); roundData('') }} value="X" size="sm" />
                                    </Form.Group>
                                </td>
                                
                            </tr>

                        )}
                    </tbody>
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Packing&nbsp;&&nbsp;Forwarding</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={shippingcharge} onChange={(e) => settingship(e.target.value)} style={{ height: 30 }} />
                                </Form.Group>
                            </td>

                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Net&nbsp;Amount</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={grossAmount ? ((grossAmount).toFixed(2)) : "0"} style={{ height: 30 }} readOnly />
                                </Form.Group>
                            </td>

                        </tr>

                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>IGST</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={igstcheck()} style={{ height: 30 }} requied />
                                </Form.Group>
                            </td>

                        </tr>
                        <tr>

                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>SGST</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={SGSTcheck()} style={{ height: 30 }} requied />
                                </Form.Group>
                            </td>

                        </tr>
                        <tr>

                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>CGST</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={cgstcheck()} style={{ height: 30 }} requied />
                                </Form.Group>
                            </td>

                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Round&nbsp;off&nbsp;Value</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={round} onChange={(e) => settinground(e.target.value)} style={{ height: 30 }} />
                                </Form.Group>
                            </td>

                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Grand&nbsp;Total</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={grandTotal ? ((grandTotal).toFixed(2)) : "0"} style={{ height: 30 }} readOnly />
                                </Form.Group>
                            </td>

                        </tr>
                    </tbody>
                </Table>
                <Button variant="primary" style={{width:'auto'}} onClick={submitdata} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Submit'}
        </Button>
            </Form>
        </MDBCard>
    )
}