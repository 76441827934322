import { SUPPLIER_EDIT_GET_DATA_TO_LOAD } from "../constant";
import { SUPPLIER_EDIT_ADD_TO_CART } from "../constant";
import { CHANGE_SUPPLIER_EDIT_QTY } from "../constant";
import { REMOVE_FROM_CART_FROM_SUPPLIER_EDIT } from "../constant";
import { CHANGE_COST_SUPPLIER_EDIT } from "../constant";
import { CHANGE_SUPPLIER_EDIT_DISCOUNT } from "../constant";
import { CHANGE_SUPPLIER_EDIT_TEXT } from "../constant";
import { EMPTY_SUPPLIER_EDIT_DATA } from "../constant";



export const getsupplierdeliveryeditdatatoload = (data) => {

    //  console.warn("action called", data);
    return {

        type : SUPPLIER_EDIT_GET_DATA_TO_LOAD,
        data : data
    }

}


export const supplierdeliveryeditaddtocart = (data) => {

    //  console.warn("action called", data);
    return {

        type : SUPPLIER_EDIT_ADD_TO_CART,
        data : data
    }

}



export const removefromcartfromsupplierdeliveryedit = (data) => {

    // console.warn("action REMOVE_TO_CART called", data);
    return {

        type : REMOVE_FROM_CART_FROM_SUPPLIER_EDIT,
        data : data
    }

}



export const changesupplierdeliveryeditqty = (data, item, data2) => {

    // console.warn("qty change called", data);
    return {

        type : CHANGE_SUPPLIER_EDIT_QTY,
        data : data,
        item : item,
        data2 : data2
    }

}


export const changecostsupplierdeliveryedit = (data, item, data2) => {

    // console.warn("cost change called", data);
    return {

        type : CHANGE_COST_SUPPLIER_EDIT,
        data : data,
        item : item,
        data2 : data2

    }

}


export const changesupplierdeliveryeditdiscount = (data, item, data2) => {

    // console.warn("discount change called", data);
    return {

        type : CHANGE_SUPPLIER_EDIT_DISCOUNT,
        data : data,
        item : item,
        data2 : data2

    }

}


export const changesupplierdeliveryedittext = (data, item) => {

    // console.warn("text change called", data);
    return {

        type : CHANGE_SUPPLIER_EDIT_TEXT,
        data : data,
        item : item
    }

}


export const emptysupplierdeliveryeditdata = () => {

    return {

        type : EMPTY_SUPPLIER_EDIT_DATA,
    }

}