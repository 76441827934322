import axios from "axios";
import { MDBCard } from "mdbreact";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../services/Api";
import authHeader from "../../services/auth-header";
import { Button, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { useNavigate } from "react-router-dom";

export default function PrintSetbarcode() {
    const [productAll, setProductAll] = useState([]);
    const [BrandAll, setBrandAll] = useState([]);
    const [brand, setbrand] = useState('');
    const [product, setproduct] = useState('');
    const [productname, setproductname] = useState('');
    const [size, setsize] = useState('');
    const [data, setData] = useState('');
    const [status, setstatus] = useState(false);
    const navigate = useNavigate();

    /* Get Brand data */
    const getbranddata = () => {
        axios.get(BASE_URL + 'brand/getAll', {
            headers: authHeader()
        }).then(res => {
            setBrandAll(res.data.map(user => ({ value: user.id, label: user.brandName })))
        })
    }

    useEffect(() => {
        getbranddata();
    }, []);

    const onChangeBrand = (e) => {
        let name = e.value;

        setbrand(e.value);
        /* Product Get all */
        axios.get(BASE_URL + 'setbarcode/product/' + e.value, {
            headers: authHeader()
        }).then(res => {
            // console.log('mello',res.data)
            setProductAll(res.data.map(use => ({ value: use.id, label: use.product_name })));

 
        })

    }

    const onChangeProduct = (e) => {
        let name = e.value;
        setproduct(e.value);
       
    };

    const submitdata = () => {
        // setstatus(true)
        axios.get(BASE_URL + 'setbarcode/setbarcodebypb/' + product + '/' + brand, {
            headers: authHeader()
        }).then(res => {
            if (res.data !== null) {
                // 
                navigate(`/SetPrint/${res.data.id}/${size}`)

                //  console.log('mello',res.data)
                setstatus(true);
                setData(res.data);
                setproductname(res.data.product)
                
            }
        })
    }

    const [printingdata, setprintingdata] = useState([]);
    const getsizedata = (e) => {
        let name = e;
        let aa = [];
        for (var i = 0; i < name; i++) {
            aa.push(i)
        }
        setprintingdata(aa)
    }

    const Print1 = () => {

        //console.log('print');  

        if(status === true){

            let printContents = document.getElementById('Print').innerHTML;
            let originalContents = document.body.innerHTML;
            document.body.innerHTML = printContents;
            window.print();
            document.body.innerHTML = originalContents;
        }


    }
    // useEffect(()=>{
    //       Print1()
    // },[status]);
    
    return (
        <MDBCard className="company">
            <h1>Set BarCode Print</h1>
            <Form className="product1">
                <Row className="mb-1">
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Brand Name</Form.Label>
                        <Select options={BrandAll} onChange={(e) => onChangeBrand(e)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Product Name</Form.Label>
                        <Select options={productAll} onChange={(e) => onChangeProduct(e)} />
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Number of Print Pages</Form.Label>
                        <input type="number" className="form-control" onChange={(e)=>setsize(e.target.value)}/>
                     </Form.Group>
                    {/* <Form.Group as={Col} md="4" controlId="formBasicName">
                        <Form.Label className="label">Printing QTY</Form.Label>
                        <Form.Control type="text" autoComplete="off" onChange={(e) => getsizedata(e.target.value)} />
                    </Form.Group> */}
                </Row>
                <Button variant="primary" type="button" onClick={submitdata}>
                    Submit
                </Button>
                {/* {status === true && 
                <div id="Print" >
                    {printingdata.map(user => <div className="column" style={{ padding: '8px', border: '0.5px solid gray', borderRadius: '20px', marginTop: '8px', width: '60mm',height:"45mm" }}>
                        <div class='rotate' >

                            <div style={{ display: 'flex'}}>
                                <div style={{fontSize:'22px',width:'80%',fontWeight:600}}>
                                   <span onClick={()=>Print1()} style={{borderBottom:'4px solid black'}}>Kindly Scan QR Code For Company Details & Consumer Care Detail.</span>
                                </div>
                                <div>
                                    <img src={"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAclBMVEX///8AAAD5+fn8/Pympqafn5/o6OgODg7b29stLS2AgICSkpJ9fX3R0dE+Pj7x8fEdHR1GRkabm5vW1tbHx8eNjY0nJyfMzMy7u7tQUFCqqqpAQEAJCQmIiIhra2v09PQWFhZfX1+0tLQzMzN2dnZMTEw3l43MAAAEuUlEQVR4nO2d63KiQBBGEdSoiBcu3l1N4r7/K27Vsll7Ip/0yIBgvvMzzExz0KowTdN6HiGEEEII6RqBM3Qhmg7uJYO+IwYJCmPEEIMCl8Hh5R30nBH7IIgRQwzyY3fBB+jyBn2HQZChEUMaOry8fRq6gIY5NHwQGjqBhjk0fBAa/uPNGmg4ujIMVYbVgusM36KhJekYGPrj2ZWVxnCc2gaPpKLScAhGQUbQ8Pu3qNxwZBt8SMO/0LAEGtKQhnehYQ4NS+i2oSb3WpuhJnhVw3UWFpKl1obbqUCcCTZMUfC1O8MgQx/C1NpwB4JjwykKngmRqobh98W/mFgb9q0NJyh4SEMa0pCGNKQhDWlIQxrSkIY0pCENaUhDGnbO8OWziV46nRQy3WgMA3POlYkqI7xBwWU6+rnPLQx28lNo0XMLDTpDVcUQDW+hYQ4NS6BhDg1pCKBhzk8zTEeWbGRxbDXD1cY2eGpv2Bvbsjo4MzysrKPLZVnJ7gQa5tDwQWjoBBrm0PBBaOiElho20XEAGjbRccBL4oEj4kg2hJB53FAOk4Mid8Fh1wgv8J0hr6KR1R94YlQDwRtB9WSm09Cw+9Cw+9Cw+9Cw+7TIUHfjZL3UHBo6vDlT3bXpdk+zSGMIi2Ck4RwN0mHU0xgxqu0PXRou3BnKhPtrGj7w3IKGNKQhDWlIQxpi9jgbqTGUNcKnaoYVa4QPsejM+y4OrI47RS/fUHQFNlLpv8Ts+CwObDXLGu2Cq3aNmIsjsJIdMpALq2YcNV8NSG21+thQ1dtEMrk9IRrSkIY0pCENaVi34eHlDXtPNTzNizkZ9QHi7yOVoZnzhgeq5aNVM5LteVHEeSu3bhNxYJkExUu1lGgJPuil3E/IVy9n1rXvzwUbys+Qhm2Ghjk0bDM0zKFhm6ntP35dJZ7WMx75DFV3bXXVCKumyB4QhuH+eK0wjuVbkdJwddTUCNdV563bW8gmIoYh3FbBliCN/7Kc/e7JMJzenGiZYeNvI9Awh4Y0FNDQCTS8hYY/0fCClmq8p4IPOyQgw9H7dcpqmkT/Gck0bghWXXw03hcDzVgvgKEvR032yy/2v+RN9RCtK4ss7hjW1NtEckKGBrI0x9gf2tO44dzeUFWQBaFhCTS8hYY5NLSAhiXQ8JYfYCjvZOENtjTc12Xoro+w0fzXF02FJ3KpYSwDyt+iKz6Pb2cFb+0a6QWtesMymoEYySc6EyNGK/t5m4Zof2io05CGNKQhDWlIw24bJq9iGETFJDt012YYrpbFzJQZ4QYMT8tZIftPWdMMDS/r4gu0htuixg3n554CaBgiEUjzhqp3SLGhdT03DWlIQxrSkIY0fEI20cgIQy7SUGYTM3eG3joLC8lkftfe0C9e9U6MYV8ckInf+fGaNb7IGUpDLwDIMQ/sntCyMIYxRf75ub8z00RXQRrm0LAEGtKwEjTMoWEJNGydYTS0JFUZxnPbdWU9rI8GRQ/0xXizRs6GhgfrVTNxislvdF7G96SdleyQup5bVISGFtDwFho6gYYW0PAWGjqhpYZP7TgAgRlhyJ1flhuUd+9V9vhNZNeIamsZGeEPVXAkqMzQ2qdxm14KCxJCCCGEkJbyB6Dh47qElMQGAAAAAElFTkSuQmCC"} style={{ width: '100px', height: '100px', marginTop: '-7px' }} />
                                </div>
                            </div>
                            <div>
                                <span style={{fontSize:'22px',fontWeight:500}}>Product Name : </span>
                                <span style={{fontSize:'25px',fontWeight:500}}>tt</span>
                            </div>
                            <div style={{ display: 'flex',justifyContent:'space-around' }}>
                                <div>
                                    <span style={{fontSize:'20px',fontWeight:500}}>EAN CODE:</span><br></br>
                                    <img src={"http://43.225.54.138:8080/scm/BarcodeImages/SetBarcode/eancode/"+data.qrname }  style={{ width: '100px', height: '100px' }} />

                                </div>
                                <div>
                                    <span style={{fontSize:'20px',fontWeight:500}}>Quantity Approx:</span><br></br>
                                    <img src={"http://43.225.54.138:8080/scm/BarcodeImages/SetBarcode/qty/"+data.qrqtyname } style={{ width: '120px', height: '100px',  }} />

                                </div>
                            </div>
                            <div style={{width:"110%"}}>
                                <span style={{fontSize:'13px',fontWeight:800,borderBottom:'4px solid black'}}>*THIS LABEL IS USED FOR INTERNAL PURPOSE ONLY.*</span>
                            </div>

                        </div></div>)}</div>
                 } */}
            </Form>
        </MDBCard>
    )
}