import axios from "axios";
import { MDBCard } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { BASE_URL } from "../services/Api";
import authHeader from "../services/auth-header";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

export default function Create() {

    const [supplierAll, setSupplierAll] = useState([]);
    const [fromdate,setfromdate]=useState('');
    const [todate,settodate]=useState('');
    const [remarks,setremarks]=useState('');
    const [taxableamount,settaxableamount]=useState('');
    const [taxamount,settaxamount]=useState('');
    const [totalamount,settotalamount]=useState('');
    const [supplier,setsupplier]=useState('');
    const [contracttype,setcontracttype] = useState('');
    const [pdf,setpdf]=useState('');

    let Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
    const [isLoading, setIsLoading] = useState(false);
    const applyFontSizeTransition = (elementId) => {
      const element = document.getElementById(elementId);
      element.style.transition = 'font-size 2s';
      element.style.fontSize = '30px';
      element.style.fontWeight = '600';
      // font-weight: 600;
      setTimeout(() => {
         
        document.getElementById("typer1").classList.add('promote');
        element.style.fontSize = '24px';
      }, 1000);
    };

    const navigate = useNavigate();
    const getsupllierdata = () => {
        axios.get(BASE_URL + 'supplier/getAll', {
            headers: authHeader()
        }).then(res => {
            setSupplierAll((res.data).map(user => ({ value: user.id, label: user.companyname })));
        })
    }

    useEffect(() => {
        getsupllierdata()
    }, []);
    const onchangesupplier = (e) =>{
        setsupplier(e.value);
    }

    const submitdata = () => {
      const emptyBlob = new Blob([""], { type: "application/octet-stream" });

        setIsLoading(true);
        const formdata = new FormData();
        formdata.append('supplier', supplier);
        formdata.append('fromdate', fromdate);
        formdata.append('todate', todate);
        formdata.append('remarks', remarks);
        formdata.append('taxableamount', taxableamount);
        formdata.append('taxamount', taxamount);
        formdata.append('totalamount', totalamount);
        formdata.append('contracttype', contracttype);
        // formdata.append('pdf', pdf);
        pdf ? formdata.append('pdf', pdf) : formdata.append('pdf', emptyBlob, 'empty-file.txt');

        axios.post(BASE_URL + 'cm/',formdata,{
            headers:authHeader()
        }).then(res=>{
            navigate('/ContractManagement/Table/1')
            if(res.status==201||res.status==200){
                // alert("ee")
                Toast.fire({
                  icon: 'success',
                  title: 'Successfully added'
                })
              }
        }).catch(error => {
            
            if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `
                <div>
                  <p id="issueText">Why do I have this issue?</p>
                  <ul>
              
                  <li id="typer1">You might have an internet issue</li>
                  </ul>
                </div>
              `,
              didOpen: () => {
                applyFontSizeTransition('issueText');
              }
            });
          }
          }).finally(() => {
            setIsLoading(false); 
          });
    }
    return (
        <MDBCard className="company">
            <h1>Add Contract Management</h1>
            <Form className="product1">
                <Row className="mb-1">
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Supplier Name</Form.Label>
                        <Select options={supplierAll} onChange={(e)=>onchangesupplier(e)}/>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">From Date</Form.Label>
                        <Form.Control type="date" onChange={(e)=>setfromdate(e.target.value)} autoComplete="off"/>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">To Date</Form.Label>
                        <Form.Control type="date" onChange={(e)=>settodate(e.target.value)} autoComplete="off"/>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Document</Form.Label>
                        <Form.Control type="file" accept=".pdf" onChange={(e) => setpdf(e.target.files[0])}/>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Taxable Amount</Form.Label>
                        <Form.Control type="text" placeholder="Enter Taxable Amount" onChange={(e)=>settaxableamount(e.target.value)} autoComplete="off"/>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Tax Amount</Form.Label>
                        <Form.Control type="text" placeholder="Enter Tax Amount" onChange={(e)=>settaxamount(e.target.value)} autoComplete="off"/>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Total Amount</Form.Label>
                        <Form.Control type="text" placeholder="Enter Total Amount" onChange={(e)=>settotalamount(e.target.value)} autoComplete="off"/>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Contract Type</Form.Label>
                        <Form.Control type="text" placeholder="Enter Contract Type" name="contracttype" onChange={(e)=>setcontracttype(e.target.value)} autoComplete="off"/>
                    </Form.Group>
                    <Form.Group as={Col} md="12" controlId="formBasicName">
                        <Form.Label className="label">Remarks</Form.Label>
                        <Form.Control as="textarea" rows={3} name='address' onChange={(e)=>setremarks(e.target.value)} placeholder='Enter Remarks' autoComplete="off" />
                    </Form.Group>
                </Row>
                <Button variant="primary" style={{width:'auto'}} onClick={submitdata} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Submit'}
        </Button>
            </Form>
        </MDBCard>
    )
}