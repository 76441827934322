import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// import { BASE_URL } from '../Component/services/Api';
import authHeader from '../../services/auth-header';
import QRCode from 'react-qr-code';
 import { useBarcode } from 'next-barcode';
import { useSelector } from 'react-redux';
import { BASE_URL } from '../../services/Api';

const Loseprint = () => {
  const data12 = useSelector(state => state.APrint1aprintcartonred.data);
console.log(data12)
  const { id } = useParams();
  const[data,setdata]=useState([])
  useEffect(() => {

   

    document.getElementById("qerteww").style.display = "none";
    document.getElementById("sidein").style.display = "none";

    const elements = ["naving", "1sidebar", "qw", "swift", "logoo", "logoo1", "menu"];
    elements.forEach(elementId => {
      const element = document.getElementById(elementId);
      if (element) {
        element.classList.add('hidden');
      }
    });

    const newElement = document.getElementById("new");
    if (newElement) {
      newElement.classList.add('fullWidth');
    }
  }, []);
  // const [currentTime, setCurrentTime] = useState('');
  const [imageSrc, setImageSrc] = useState(null);
  const [imageWidth, setImageWidth] = useState(null);
  const [imageHeight, setImageHeight] = useState(null);
  const [currentTime, setCurrentTime] = useState('');
// const [galaseriesname, setGalaseriesname] = useState([])
const [qrcode, setQrcode] = useState([])

// const { inputRef } = useBarcode({
//   value:[data12].map(item => item.galaseriesname), 
//   options: {
//     background: '#ffffff',
//     fontSize: 20,
//     margin: 30, 
//      displayValue: false,
//     fontOptions: "bold",
//     width: 1,
//     height:70
//   }
// });

const formatDateToIndianFormat = (dateString) => {
  // Convert the dateString to a Date object
  const date = new Date(dateString);

  // Extract day, month, and year
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = date.getFullYear();

  // Format the date in DD/MM/YYYY format
  return `${day}/${month}/${year}`;
};
const [eanCode, setEanCode] = useState([])
const printere=()=>{
    axios.get(BASE_URL + 'loosepacking/'+id, {
        headers: authHeader()
    }).then(res => {
        console.log(res.data)
        setdata(res.data)
     })
    
}
   useEffect(() => {
//  setdata([data12])
printere()
// setdata(localStorage.getItem("responseData"))




const now = new Date();
const hours = String(now.getHours()).padStart(2, '0');
const minutes = String(now.getMinutes()).padStart(2, '0');
setCurrentTime(`${hours}:${minutes}`);


// const productname= data.map(item => item.product.eanCode).join(', ')
// const mrp=data.map(item => item.pcs).join(', ')
// const galaseriesname=data.map(item => item.galaseriesname).join(', ')
// setGalaseriesname(galaseriesname)

//  setEanCode(`${productname}&${mrp}kg==${mrp}kg &${galaseriesname}`);

 }, [ ])
 const handlePrint = () => {
  window.print();
  // setTimeout(() => {
  //   window.location.href = '/dashboard';  
  // }, 1000);
};

const handleprint12=()=>{
  window.location.href = '/Loseetable/1';
}
 
// console.log(data.map(item => item.galaseriesname)[0])
// const tyt=data.map(item => item.galaseriesname)[0]
// alert(tyt)
const quantity = parseFloat(data.qty);
const roundedQuantity = !isNaN(quantity) ? quantity.toFixed(2) : 'N/A';
 
// galaseriesname
// console.log([productname,mrp].join(', '))
// console.log(qrCodeValue)
// const productname= data.map(item => item.product.eanCode).join(', ')
// const mrp=data.map(item => item.pcs).join(', ')
// const galaseriesname=data.map(item => item.galaseriesname).join(', ')

// const val=`${productname}&${mrp}kg==${mrp}kg &${galaseriesname}`
// console.log(data)


   return (
    <>   <button className='btnmedia' onClick={handlePrint}>Print</button>
     <button className='btnmedia' onClick={handleprint12}>Back</button>
 <div id="print" >
  {/* <QRCode
    size={256}
    style={{ height: "auto",  width: "17mm" }}
    value={qrCodeValue}
        viewBox={`0 0 256 256`}
  /> */}
        <div className="column" style={{ padding: '11px', marginLeft: '10px',background:"white",border: '0.5px solid black', borderRadius: '1px', marginTop: '8px', width: '100mm',height:'125mm' }}>
          <div className=''>
            <div className='row' style={{textAlign:'center'}}>
              <div className='col' style={{ borderBottom: '1px solid', padding: '8px' }}>
                <img src='https://d3k81ch9hvuctc.cloudfront.net/company/SdRp39/images/b1fbeef6-74ab-49f1-8bb4-3da89443aa48.png' alt="Uploaded" style={{ width: '54mm',position:'relative', top: '-7px', left: '21px' }} />
              </div>
              <div className='col-3' style={{ borderBottom: '1px solid', padding: '8px' }}>
              {/* <QRCode
    size={256}
    style={{ height: "auto",  width: "17mm" }}
    value={val}
        viewBox={`0 0 256 256`}
  /> */}
                {/* <img src='https://randomqr.com/assets/images/rickroll-qrcode.webp' alt="Uploaded" style={{ width: '19mm' }} /> */}
              </div>
            </div>

            <div className='row'  >
           <div className='col' style={{   padding: '8px', lineHeight: '56px',   fontSize: '33px',    fontWeight: '700',border: '0.5px solid black',height: '127px'}}>
           <h2 style={{ fontWeight: '700', lineHeight: '34px'}}>{data.product && data.product.productName ? data.product.productName : ''}</h2>
        {/* </h2> */}
              {/* <h2 style={{ fontWeight: '700'}}></h2> */}
              {/* <hr className='hr3'></hr> */}
           </div>
           
            </div>
            <div className='row'  >
           <div className='col' style={{ textAlign:"center",  padding: '8px', lineHeight: '46px',   fontSize: '33px',    fontWeight: '700',border: '0.5px solid black',height: 'auto'}}>
           <h2 style={{ fontWeight: '800', lineHeight: '36px'}}>Units :{data.pcs?data.pcs:""} Pcs</h2>
              {/* <h2 style={{ fontWeight: '700'}}></h2> */}
              {/* <hr className='hr3'></hr> */}
           </div>
           
            </div>
            <div className='row'  >
           <div className='col' style={{ textAlign:"center",  padding: '8px', lineHeight: '46px',   fontSize: '23px',    fontWeight: '700',border: '0.5px solid black',height: 'auto'}}>
           <h2 style={{ fontWeight: '800',fontSize: '23px',  }}> Net Weight:&nbsp;&nbsp;&nbsp;&nbsp;{roundedQuantity}</h2>
           <h2 style={{ fontWeight: '800',fontSize: '23px',  }}> (KG)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;KGS</h2>
              {/* <h2 style={{ fontWeight: '700'}}></h2> */}
              {/* <hr className='hr3'></hr> */}
           </div>
           
            </div>
            <div className='row'>
            <div className='col' style={{ textAlign:"center",  padding: '8px',    fontSize: '11px',    fontWeight: '700',border: '0.5px solid black',height: 'auto'}}>
            {/* <h4  style={{ fontWeight: '700',fontSize: '13px',  }}>Operator:<br></br>Parin cheada</h4> */}
            <span>Operator:<br></br> {data.operatorname}</span>
              </div>
              <div className='col' style={{ textAlign:"center",  padding: '8px',     fontSize: '11px',    fontWeight: '700',border: '0.5px solid black',height: 'auto'}}>
            {/* <h4  style={{ fontWeight: '700',fontSize: '13px',  }}>Operator:<br></br>Parin cheada</h4> */}
            <span>Brand Name:<br></br>{data.brand && data.brand.brandName ? data.brand.brandName : ''}</span>
              </div>
              <div className='col' style={{ textAlign:"center",  padding: '8px',    fontSize: '11px',    fontWeight: '700',border: '0.5px solid black',height: 'auto'}}>
            {/* <h4  style={{ fontWeight: '700',fontSize: '13px',  }}>Operator:<br></br>Parin cheada</h4> */}
            <span>Date: <br></br>{formatDateToIndianFormat(data.createddate)}</span>
              </div>
              <div className='col' style={{ textAlign:"center",  padding: '8px',     fontSize: '11px',    fontWeight: '700',border: '0.5px solid black',height: 'auto'}}>
            {/* <h4  style={{ fontWeight: '700',fontSize: '13px',  }}>Operator:<br></br>Parin cheada</h4> */}
            <span>Time:<br></br> {data.createdtime}</span>
              </div>
            </div>
            <yy  style={{textAlign:'center',fontSize:'16px',position: 'relative',top: '19px',left:"33px"}}> This Lable is for Internal Use Only.</yy>

            {/* <br></br> */}
 

{/* <div className='row'>
  <div className='col-2'>
   <mym> Units:</mym>
  </div>
  <div className='col-5'>
   <mym> Net Weight (KGS):</mym>
  </div>
  <div className='col-5'>
  <mym> Gross Weight (KGS):</mym>
  </div>
</div> */}
{/* <table style={{width: '364px', marginLeft: '-12px'}}>
  <tr>
    <td className='htrt'>Units:</td>
    <td className='htrt'> Net Weight (KGS):</td>
    <td className='htrt'>Gross Weight (KGS):</td>
  </tr>
</table> */}
{/* <tyt>This Label is for Internal Use Only</tyt> */}


          

          </div>
        </div>
      </div> 
    </>
  );
}

export default Loseprint;
