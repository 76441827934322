import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../services/Api";
import authHeader from "../services/auth-header";
import { changecostpurchaseedit, changepurchaseeditdiscount, changepurchaseeditqty, changepurchaseedittext, getpurchaseeditdatatoload, purchaseeditaddtocart, purchaseeditcalmeasurement, purchaseeditmeasurement, purchaseeditqtykgs, purchaseeditqtypcs, removefromcartfrompurchaseedit } from "../../redux/Action/PurchaseEdit";
import { MDBCard } from "mdbreact";
import { Accordion, Col, Form, Row, Table, Button } from "react-bootstrap";
import Select from "react-select";
import ProductService from "../services/ProductService";
import Swal from "sweetalert2";


export default function Edit() {

    const { id } = useParams();
const{pointindex}=useParams();
    const [warehousename, setwarehousename] = useState('');
    const [supplierdata, setSupplierdata] = useState('');
    const [contactname, setcontactname] = useState('');
    const [supplierSubContacts_data, setsupplierSubContacts_data] = useState([]);
    const [supplier_SubContactsdata, setsupplier_SubContactsdata] = useState([]);
    const [invoiceno, setinvoiceno] = useState('');
    const [purchasedate, setpurchasedate] = useState('');
    const [type, settype] = useState('');
    const [remarks, setremarks] = useState('');
    const [status, setstatus] = useState('');
    const [shippingaddress, setshippingaddress] = useState('');
    const [ewaybillno, setewaybillno] = useState('');
    const [subContacts1, setsubContacts1] = useState('');
    const [otherreference, setotherreference] = useState('');
    const [supplierinvoiceno, setsupplierinvoiceno] = useState('');
    const [supplierinvoicedate, setsupplierinvoicedate] = useState('');
    const [taxtype, settaxtype] = useState('');
    const [productdata, setproductdata] = useState([]);
    const [branch, setbranch] = useState('');
    const [branchdata, setbranchdata] = useState('');
    const [shippingcharge, setShipping] = useState(0);
    const [round, setRound] = useState(0);
    const [bstateid, setbstateid] = useState('');
    const [sstateid, setsstateid] = useState('');
    const [paymentTerms, setpaymentTerms] = useState('');
    const [voucherid, setvoucherid] = useState('');
    
    const [isLoading, setIsLoading] = useState(false);
    const applyFontSizeTransition = (elementId) => {
      const element = document.getElementById(elementId);
      element.style.transition = 'font-size 2s';
      element.style.fontSize = '30px';
      element.style.fontWeight = '600';
      // font-weight: 600;
      setTimeout(() => {
         
        document.getElementById("typer1").classList.add('promote');
        element.style.fontSize = '24px';
      }, 1000);
    };
    
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let purchaseItems = useSelector((state) => state.purchaseeditdata);
    let net_Amount = purchaseItems.length && purchaseItems.map(item => Math.round(Number(item.total))).reduce((prev, next) => Math.round(prev - (-next)));
    let gst = purchaseItems.length && purchaseItems.map(item => (item.gstvalue)).reduce((prev, next) => (prev - (-next)));
    //  console.log("mello",gst);

    let aa = [];
    purchaseItems.map(user=>{
        let igst_value = user.igst;
        let total_value = user.total;
        let total_amou = ((Number(shippingcharge)/Number(net_Amount))* Number(total_value));
        let add_amou = Number(total_amou) - (-Number(total_value));
        let final_amou = ((Number(add_amou)/100)*Number(igst_value)).toFixed(2); 
        aa.push(final_amou)

        // console.log("mello",((Number(add_amou)/100)*Number(igst_value)).reduce((prev, next) => Math.round(prev - (-next))) );
    })
    let total_per = aa.length && aa.map(user=>user).reduce((prev, next) => (prev - (-next)));


    let netAmount = Number(net_Amount) + Number(shippingcharge);
    let grandTotal = Number(net_Amount)  + Number(shippingcharge) + Number(round) +  Number(total_per);

    let igstTax = Number(total_per);
    let cgstTax = (Number(total_per)/2);
    let sgstTax = (Number(total_per)/2);

    function roundData(e){
        if(e){
            let net_Amount = purchaseItems.length && purchaseItems.map(item => Number(item.total)).reduce((prev, next) => (prev - (-next)).toFixed(2))
            let gst = purchaseItems.length && purchaseItems.map(item => (item.gstvalue)).reduce((prev, next) => (prev - (-next)));
           
            let aa = [];
            purchaseItems.map(user=>{
                let igst_value = user.igst;
                let total_value = user.total;
                let total_amou = ((Number(shippingcharge)/Number(net_Amount))* Number(total_value));
                let add_amou = Number(total_amou) - (-Number(total_value));
                let final_amou = ((Number(add_amou)/100)*Number(igst_value)).toFixed(2); 
                aa.push(final_amou)
        
                // console.log("mello",((Number(add_amou)/100)*Number(igst_value)).reduce((prev, next) => Math.round(prev - (-next))) );
            })
            let total_per = aa.length && aa.map(user=>user).reduce((prev, next) => (prev - (-next)));
            
            
            let netAmount = Number(net_Amount) + Number(shippingcharge);
            let grandTotal = Number(net_Amount)  + Number(shippingcharge)  + Number(total_per);
      
             let grand_Total = Math.ceil(grandTotal);
        
             if(grand_Total > grandTotal){
                 let rou_nd = (Number(grand_Total) - Number(grandTotal)).toFixed(2);
                 setRound(rou_nd);
             } else{
                 setRound(0)
             }
               
              
        } else if(e==''){
            setRound(0)
        }
    }

    /* get Purchase DATA */
    const getpurchasedata = () => {
        axios.get(BASE_URL + 'purchase/' + id, {
            headers: authHeader()
        }).then(res => {
            // console.log(res.data, "mello");
            setwarehousename(res.data.warehouse);
            setSupplierdata(res.data.supplier);
            setinvoiceno(res.data.invoiceno);
            setcontactname(res.data.supplierSubContacts == null?null:res.data.supplierSubContacts.id);
            setsupplierSubContacts_data(res.data.supplierSubContacts == null?null:{value:res.data.supplierSubContacts.id,label:res.data.supplierSubContacts.contactname})
            setsupplier_SubContactsdata((res.data.supplier.supplierSubContacts).map(user=>({value:user.id,label:user.contactname})))
            setpurchasedate(res.data.purchasedate);
            setvoucherid(res.data.voucherseries);
            setpaymentTerms(res.data.paymentTerms);
            settype(res.data.type);
            setremarks(res.data.remarks);
            setstatus(res.data.status);
            settaxtype(res.data.taxtype);
            setshippingaddress(res.data.shippingaddress);
            setewaybillno(res.data.ewaybillno);
            setotherreference(res.data.otherreference);
            setsupplierinvoiceno(res.data.supplierinvoiceno);
            setsupplierinvoicedate(res.data.supplierinvoicedate);
            settaxtype(res.data.taxtype);
            setbranch({ "id": res.data.branch.id });
            setbranchdata(res.data.branch.branchname);
            setShipping(res.data.shippingcharge);
            setRound(res.data.roundofvalue);
            setbstateid(res.data.branch.states.id);
            setsstateid(res.data.supplier.states.id);
        })
    };

    let date_value = new Date(purchasedate);
    const defaultValue = date_value.toLocaleDateString('en-CA');
    let date_value1 = new Date(supplierinvoicedate);
    const defaultValue1 = date_value1.toLocaleDateString('en-CA');

    /* get Product All */
    const getproductdata = () => {
        axios.get( BASE_URL + 'product/page/0/100/asc/id/%20',{
            headers:authHeader()
        }).then(res=>{

            setproductdata((res.data.Index).map(use => ({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code + ' - ' + use.short_Name })));
        })
    };

    const customFilter = (e) => {
        let name = e;
        axios.get( BASE_URL + 'product/page/0/100/asc/id/'+name,{
            headers:authHeader()
        }).then(res=>{
         setproductdata((res.data.data).map(use=>({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code + ' - ' + use.short_Name })))
        })
    };

    useEffect(() => {
        getpurchasedata();
        dispatch(getpurchaseeditdatatoload(id));
        getproductdata();
    }, []);


    let addData = (e) => {
        const filteredItems = purchaseItems.filter(item => item.product.id === e.value);
        if (filteredItems.length == "0") {
            dispatch(purchaseeditaddtocart(e.value));
            setShipping("0");
        } else {
            Swal.fire({
                title: 'This Product is Already Added',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonColor: 'red'
            })
        }
    };
    /* get Shipping Charges */
    const settingship = (e) => {

        if (e == "") {

            setShipping("");
        } else {


            setShipping(e);
        }

    }

    /* get Round Value */
    const settinground = (e) => {
        if (e == "") {

            setRound("");
        } else {

            setRound(e);
        }

    }

    const sgstcheck = () => {

        let aa = 0

        if (taxtype == "withtax") {

            if (bstateid === sstateid) {

                aa = sgstTax;

            } else {

                aa = 0;

            }

        } else {

            aa = 0;

        }
        return aa;
    };

    const cgstcheck = () => {

        let bb = 0
        if (taxtype == "withtax") {

            if (bstateid === sstateid) {

                bb = cgstTax;

            } else {

                bb = 0;

            }
        } else {

            bb = 0;

        }
        return bb;
    }

    const igstcheck = () => {

        let cc = 0
        if (taxtype == "withtax") {

            if (bstateid === sstateid) {

                cc = 0;

            } else {

                cc = igstTax;

            }
        } else {

            cc = 0;

        }
        return cc;
    }

    const onChangesubcontact = ( e ) => {
        setcontactname(e.value);
        setsupplierSubContacts_data({value:e.value,label:e.label})

    }

    const submitdata = () => {
        setIsLoading(true); 
        let warehouse = { "id": warehousename.id };
        let supplier = { "id": supplierdata.id };
        let supplierSubContacts
        if(contactname !== null){
             supplierSubContacts = { "id": Number(contactname) }
        }
        let igst = igstcheck();
        let cgst = cgstcheck();
        let sgst = sgstcheck();
        let grandtotal = grandTotal;
        let roundingofvalue = round;
      
        axios.put(BASE_URL + 'purchase/' + id, {
            warehouse, supplier, invoiceno, contactname, purchasedate, status, taxtype, shippingaddress, ewaybillno,
            purchaseItems, supplierSubContacts,remarks,
            igst, cgst, sgst, grandtotal, roundingofvalue, shippingcharge, netAmount, branch,paymentTerms
        }, {
            headers: authHeader()
        }).then(res => {
            navigate('/Newpurchase/1')
        }).catch(error => {
            console.error('Error adding  :', error);
            if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `
                <div>
                  <p id="issueText">Why do I have this issue?</p>
                  <ul>
              
                  <li id="typer1">You might have an internet issue</li>
                  </ul>
                </div>
              `,
              didOpen: () => {
                applyFontSizeTransition('issueText');
              }
            });
          }
          }).finally(() => {
            setIsLoading(false); 
          });
    }
    return (
        <MDBCard className="company">
         <div className='d-flex bd-highlight'>
        <div className="card-header card-title w-100">   <h1>Edit Purchase</h1></div>
  
        <Link className='p-2 w-200 card-header card-title bd-highlight' to={`/Newpurchase/${pointindex}`}><Button>Back</Button></Link>

   
      </div>
            <Form className="product1">
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Purchase Data</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Branch Name</Form.Label>
                                    <Form.Control type="text" value={branchdata} disabled />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">WareHouse Name</Form.Label>
                                    <Form.Control type="text" value={warehousename.name} readOnly />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Supplier Company</Form.Label>
                                    <Form.Control type="text" value={supplierdata.companyname} readOnly />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Contact Name</Form.Label>
                                    <Select options={supplier_SubContactsdata} value={supplierSubContacts_data == null ?"":supplierSubContacts_data} onChange={(e) => onChangesubcontact(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Date</Form.Label>
                                    <Form.Control type="date" value={defaultValue} name="purchasedate" onChange={(e) => setpurchasedate(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Terms of Payment</Form.Label>
                                    <Form.Control type='text' value={paymentTerms} name="paymentTerms" onChange={(e) => setpaymentTerms(e.target.value)} autoComplete="off" />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Invoice No</Form.Label>
                                    <Form.Control type="text" value={invoiceno} name="invoiceno" onChange={(e) => setinvoiceno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Ewaybill No</Form.Label>
                                    <Form.Control type="text" value={ewaybillno} name="ewaybillno" onChange={(e) => setewaybillno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Other Reference</Form.Label>
                                    <Form.Control type="text" value={otherreference} name="otherreference" onChange={(e) => setotherreference(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Supplier Invoice No</Form.Label>
                                    <Form.Control type="text" value={supplierinvoiceno} name="supplierinvoiceno" onChange={(e) => setsupplierinvoiceno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Supplier Invoice Date</Form.Label>
                                    <Form.Control type="date" value={defaultValue1} name="supplierinvoicedate" onChange={(e) => setsupplierinvoicedate(e.target.value)} />
                                </Form.Group>
                                {/* <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Type</Form.Label>
                                    <Form.Select value={type} onChange={(e) => settype(e.target.value)}>
                                        <option value="b2b">B2B</option>
                                        <option value="b2c">B2C</option>
                                    </Form.Select>
                                </Form.Group> */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Voucher</Form.Label>
                                    <Form.Control type='text' value={voucherid} name="voucherid" readOnly autoComplete="off" />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Voucher Name</Form.Label>
                                    <Form.Control type='text' value={voucherid} name="voucherid" readOnly autoComplete="off" />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Status</Form.Label>
                                    <Form.Select value={status} onChange={(e) => setstatus(e.target.value)}>
                                        <option value='true'>Open</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Tax Type</Form.Label>
                                    <Form.Select value={taxtype} onChange={(e) => settaxtype(e.target.value)} disabled>
                                        <option>--Select--</option>
                                        <option value='withtax'>With Tax</option>
                                        <option value='withouttax'>Without Tax</option>
                                    </Form.Select>
                                </Form.Group>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    {/* <Accordion.Item eventKey="1">
                        <Accordion.Header>Billing Address</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Supplier Address</Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Supplier Details</Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Address : -</Form.Label>
                                    <span>&nbsp;{supplierdata.address}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Name : -</Form.Label>
                                    <span>&nbsp;{supplierSubContactsdata.contactname}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">City : -</Form.Label>
                                    <span>&nbsp;{supplierdata.cities}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Phone No : -</Form.Label>
                                    <span>&nbsp;{supplierSubContactsdata.phoneno}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Country : -</Form.Label>
                                    <span>{"India"}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Email Id : -</Form.Label>
                                    <span>{supplierSubContactsdata.email}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">State : -</Form.Label>
                                    <span>{supplierstate}</span>
                                </Form.Group>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item> */}
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>Shipping Address</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="12" controlId="formBasicName">
                                    <Form.Label className="label">Address</Form.Label>
                                    <Form.Control as="textarea" value={shippingaddress}onChange={(e) => setshippingaddress(e.target.value)} rows={3} name='shippingaddress' placeholder='Enter Shipping Address' />
                                </Form.Group>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>Details</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="12" controlId="formBasicName">
                                    <Form.Label className="label">Remarks</Form.Label>
                                    <Form.Control as="textarea" value={remarks}  rows={3} onChange={(e) => setremarks(e.target.value)} name='remarks' placeholder='Enter Remarks' />
                                </Form.Group>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <br></br>
                <Row className="mb-1">
                    <Form.Group as={Col} md="12" controlId="formBasicName">
                        <Form.Label className="label">Product</Form.Label>
                        <Select options={productdata} onChange={(e) => addData(e)} onInputChange={(e) => customFilter(e) }  />
                        
                    </Form.Group>
                </Row>
                <br></br>
                <Table striped responsive hover size="sm">
                    <thead className='p-3 mb-2 bg-success text-white'>
                        <tr>
                            <th style={{ width: '20%' }}>PRODUCT&nbsp;NAME</th>
                            <th style={{ width: '9%' }}>HSN&nbsp;Code</th>
                            <th style={{ width: '8%' }}>GST</th>
                            <th style={{ width: '8%' }}>UOM</th>
                            <th style={{ width: '8%' }}>QTY(PCS)</th>
                            <th style={{ width: '8%' }}>QTY(KG)</th>
                            <th style={{ width: '8%' }}>RATE (₹)</th>
                            <th style={{ width: '8%' }}>UOM</th>
                            <th style={{ width: '8%' }}>GROSS AMOUNT(₹)</th>
                            <th style={{ width: '8%' }}>DISCOUNT %</th>
                            <th style={{ width: '8%' }}>NET AMOUNT (₹)</th>
                            {/* <th>Per&nbsp;Unit&nbsp;Rate</th> */}
                            <th style={{ width: '8%' }}>GST VALUE&nbsp;(₹)</th>
                            <th style={{ width: '8%' }}>TOTAL&nbsp;(₹)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                            {/* <th>View</th> */}
                            <th>Action&nbsp;&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody style={{ overflow: "scroll" }}>
                        {purchaseItems.map(item =>
                            <tr key={item.product.id}>                       
                            
                                <td>
                                    <Form.Group as={Col} md="12" >
                                        {/* <Form.Control type="text" value={item.productName} size="sm" /> */}
                                        <textarea type="text" value={item.productName} className="form-control textcontrol" size="sm"></textarea>

                                    </Form.Group>
                                    <Form.Control as="textarea" onChange={(e) => { dispatch(changepurchaseedittext(e.target.value, item)) }} value={item.text} rows={2} className='form-control' />
                                </td>
                                <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number"  value={item.hsncode} size="sm" readOnly/>
                                </Form.Group>
                                </td>
                                <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number"  value={item.igst} size="sm" readOnly/>
                                </Form.Group>
                                </td>
                                <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Select disabled={item.unitofmeasurement == "box" ? true:item.productType !== "kgproduct" ? true:false } value={item.unitofmeasurement} onChange={(e) => { dispatch(purchaseeditmeasurement(e.target.value, item, item.unitofmeasurement)); }} size="sm">
                                        <option>{item.unitofmeasurement == "box" ? "PCS":item.productType !== "kgproduct" ? 'PCS':"Select"}</option>
                                        <option value="pcs">PCS</option>
                                        <option value="kg">KG</option>
                                    </Form.Select>
                                </Form.Group>
                                </td>
                                <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" min="0" onChange={(e) => { dispatch(purchaseeditqtypcs(e.target.value, item, item.unitofmeasurement));roundData(e.target.value)}} disabled={item.unitofmeasurement == "kg" ? true : false} value={item.puquantity} size="sm" />
                                </Form.Group>
                                </td>
                                <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" min="0" onChange={(e) => { dispatch(purchaseeditqtykgs(e.target.value, item, item.unitofmeasurement)); roundData(e.target.value)}} disabled={item.unitofmeasurement == "pcs" ? true:item.productType == "boxproduct" ? true:false || item.unitofmeasurement==""?true:false}  value={item.unitofmeasurement == "box" ? "" : item.puquantitykgs} size="sm" />
                                </Form.Group>
                                </td>
                                <td>
                                <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" size="sm" onChange={(e) => { dispatch(changecostpurchaseedit(e.target.value, item, taxtype));roundData(e.target.value) }} value={item.dlp} />
                                    </Form.Group>
                                </td>
                                <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Select disabled={item.calunitofmeasurement == "box" ? true:item.productType !== "kgproduct" ? true:false } value={item.calunitofmeasurement} onChange={(e) => { dispatch(purchaseeditcalmeasurement(e.target.value, item, item.calunitofmeasurement)); roundData(e.target.value)}} size="sm">
                                        <option>{item.calunitofmeasurement == "box" ? "PCS":item.productType !== "kgproduct" ? 'PCS':"Select"}</option>
                                        <option value="pcs">PCS</option>
                                        <option value="kg">KG</option>
                                    </Form.Select>
                                </Form.Group>
                                </td>
                                <td>
                                <Form.Group as={Col} md="12">
                                        <Form.Control type="text" value={Number(item.grossamount)} size="sm" readOnly/>
                                    </Form.Group>
                                </td>
                                <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" min="0" onChange={(e) => { dispatch(changepurchaseeditdiscount(e.target.value, item, taxtype));roundData(e.target.value) }} value={item.tradeDiscount || ''} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                <Form.Group as={Col} md="12">
                                        <Form.Control type="text" value={Number(item.total)} size="sm" readOnly/>
                                    </Form.Group>
                                </td>
                                <td>
                                <Form.Group as={Col} md="12">
                                        <Form.Control type="text" value={Number(item.gstvalue)} size="sm" readOnly/>
                                    </Form.Group>
                                </td>
                                <td>
                                <Form.Group as={Col} md="12">
                                        <Form.Control type="text" value={Number(item.amount)} size="sm" readOnly/>
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type='button' className='btn btn-danger' onClick={() => {dispatch(removefromcartfrompurchaseedit(item.mrn_id));roundData('')}} value="X" size="sm" />
                                    </Form.Group>
                                </td>
                            </tr>
                        )}
                    </tbody>
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Packing&nbsp;&&nbsp;Forwarding</td>
                            <td>                                <Form.Group as={Col} md="12">
                                <Form.Control type="number" value={shippingcharge} onChange={(e) => settingship(e.target.value)} style={{ height: 30 }} />
                            </Form.Group>
                            </td>
                            <td></td>

                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Net&nbsp;Amount</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                <Form.Control type="number" value={netAmount?((netAmount).toFixed(2)):"0"}  style={{ height: 30 }} />
                            </Form.Group>
                            </td>
                            <td></td>

                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>IGST</td>
                            <td><Form.Group as={Col} md="12">
                                <Form.Control type="number" value={igstcheck()} requied style={{ height: 30 }}/>
                            </Form.Group></td>
                            <td></td>

                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>SGST</td>
                            <td> <Form.Group as={Col} md="12">
                                <Form.Control type="number" value={sgstcheck()} requied style={{ height: 30 }}/>
                            </Form.Group></td>
                            <td></td>

                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>CGST</td>
                            <td><Form.Group as={Col} md="12">
                                <Form.Control type="number" value={cgstcheck()} requied style={{ height: 30 }}/>
                            </Form.Group></td>
                            <td></td>

                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Round&nbsp;off&nbsp;Value</td>
                            <td><Form.Group as={Col} md="12">
                                <Form.Control type="number" value={round} onChange={(e) => settinground(e.target.value)} style={{ height: 30 }} />
                            </Form.Group></td>
                            <td></td>

                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Grand&nbsp;Total</td>
                            <td><Form.Group as={Col} md="12">
                                <Form.Control type="number" value={grandTotal?((grandTotal).toFixed(2)):"0"} readOnly style={{ height: 30 }}/>
                            </Form.Group></td>
                            <td></td>

                        </tr>
                    </tbody>
                </Table>
                <Button variant="primary" style={{width:'auto'}} onClick={submitdata} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Submit'}
        </Button>
            </Form>
        </MDBCard>
    )
}