import { MDBCard } from "mdbreact";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function Print() {
    const { state } = useLocation();
    const { Printdata } = state;
     let itemdata = Printdata.repackingItems;
     console.log('mello',Printdata)
     const Print1 = () => {
        
        //console.log('print');  
        // let printContents = document.getElementById('repackingprint').innerHTML;
        let originalContents = document.body.innerHTML;
        // document.body.innerHTML = printContents;
        window.print();
        // document.body.innerHTML = originalContents;
      
    }
    const extractHoursAndMinutes = (timestamp) => {
        // Create a Date object from the timestamp string
        const date = new Date(`1970-01-01T${timestamp}Z`);
        
        // Extract hours and minutes
        const hours = date.getUTCHours().toString().padStart(2, '0');
        const minutes = date.getUTCMinutes().toString().padStart(2, '0');
        
        // Format as HH:mm
        return `${hours}:${minutes}`;
      };
    useEffect(()=>{
        

    document.getElementById("qerteww").style.display = "none";
    document.getElementById("sidein").style.display = "none";


    const elements = ["naving", "1sidebar", "qw", "swift", "logoo", "logoo1", "menu"];
    elements.forEach(elementId => {
      const element = document.getElementById(elementId);
      if (element) {
        element.classList.add('hidden');
      }
    });


    const newElement = document.getElementById("new");
    if (newElement) {
      newElement.classList.add('fullWidth');
    }
        // Print1()
    },[]);
    let totalpcs = itemdata.length && itemdata.map(user=>(user.pcs)).reduce((prev, next) => (prev - (-next)));
    let totalkg = itemdata.length && itemdata.map(user=>(user.qty)).reduce((prev, next) => (prev - (-next)));
    const formatDate = (timestamp) => {
          const date = new Date(timestamp);
        
         const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
        const year = date.getFullYear();
        
         return `${day}/${month}/${year}`;
      };
      const handleprint12=()=>{
        window.location.href = '/Repackingtable/1';
      }
       
    return (
        <MDBCard className="company">
            <button className="btnmedia btn btn-primary"onClick={()=>Print1()}>Print</button>
            <button className='btnmedia  btn btn-primary' onClick={handleprint12}>Back</button>

            <div id="repackingprint" style={{padding:'8px'}}>
                <div style={{width:'98%',border:'2px solid black',borderRadius:'5px',marginLeft:'5px',marginTop:'3px'}}>
                <div className="neelamfontsize" style={{  fontSize: '8px',fontWeight: 500,textAlign: 'center'}} >
                <img 
        src="https://neelamsteel.com/cdn/shop/files/neelamlogo_e7ec5d58-3289-4693-8039-f545a45d9cd2.png?v=1686029549&width=140" 
        alt="Neelam Steel Logo" 
        width="140"  
        height="auto"  
      />
                </div>
                <div className="neelamfontsize" style={{  fontSize: '8px',textAlign: 'center',fontWeight: 600}}>
                    <span style={{borderBottom: '2px solid black'}}>This Master Carton Contains:</span>
                </div>
                <table style={{border:'1px solid black',width:'100%',padding:'8px'}}>
                    <tbody style={{fontWeight:800}}>
                        <tr>
                            <td className="neelamfontsize" style={{border:'1px solid black',borderLeft:'hidden',width:'100%',padding:'13px',textAlign: 'left'}}><span className="neelamfontsize" style={{fontSize: '8px',fontWeight: 600 }}>Product Name:</span></td>
                            <td className="neelamfontsize" style={{border:'1px solid black',width:'100%',padding:'13px'}}><span className="neelamfontsize" style={{fontSize: '8px',fontWeight: 600 }}>QTY&nbsp;PCS:</span></td>
                            <td className="neelamfontsize" style={{border:'1px solid black',borderRight:'hidden',width:'100%',padding:'13px'}}><span className="neelamfontsize" style={{fontSize: '8px',fontWeight: 600 }}>QTY&nbsp;KGS:</span></td>
                        </tr>
                    </tbody>
                    <tbody>
                        {itemdata.map((user, index) =>
                            <tr key={index}>
                               <td className="neelamfontsize" style={{border:'1px solid black',textAlign: 'left', borderLeft:'hidden',width:'100%',padding:'13px',borderCollapse: 'collapse'}}><span className="neelamfontsize" style={{fontSize: '8px',fontWeight: 600 }}>{user.productname}</span></td>
                               <td className="neelamfontsize" style={{border:'1px solid black',width:'100%',padding:'13px',borderCollapse: 'collapse'}}><span className="neelamfontsize" style={{fontSize: '8px',fontWeight: 600 }}>{user.pcs.toFixed(2)}PCS</span></td>
                               <td className="neelamfontsize" style={{border:'1px solid black',borderRight:'hidden',width:'100%',padding:'13px',borderCollapse: 'collapse'}}><span className="neelamfontsize" style={{fontSize: '8px',fontWeight: 600 }}>{user.qty.toFixed(2) }KG</span></td>
                            </tr>
                        )}
                          <tr>
                            <td className="neelamfontsize" style={{border:'1px solid black',borderLeft:'hidden',width:'100%',padding:'13px',borderCollapse: 'collapse',textAlign: 'left',}}><span className="neelamfontsize" style={{fontSize: '8px',fontWeight: 600 }}>Total QTY</span></td>
                            <td className="neelamfontsize" style={{border:'1px solid black',width:'100%',padding:'13px',borderCollapse: 'collapse'}}><span className="neelamfontsize" style={{fontSize: '8px',fontWeight: 600 }}>{totalpcs.toFixed(2)}PCS</span></td>
                            <td className="neelamfontsize" style={{border:'1px solid black',borderRight:'hidden',width:'100%',padding:'13px',borderCollapse: 'collapse'}}><span className="neelamfontsize" style={{fontSize: '8px',fontWeight: 600 }}>{totalkg.toFixed(2)}KG</span></td>
                          </tr>
                    </tbody>
                </table>
                <table style={{border:'1px solid black',width:'100%',padding:'8px'}}>
                    <tr>
                        <td  style={{border:'1px solid black',borderLeft:'hidden',borderTop:'hidden',padding:'8px'}}>
                        <span className="neelamfontsize" style={{fontSize: '8px',borderTop:'hidden',fontWeight:600}}>Operator:</span><br></br><br></br>
                        <span className="neelamfontsize" style={{fontSize: '8px',fontWeight:600}}>{Printdata.operatorname}</span>
                        </td>
                        <td style={{border:'1px solid black',borderTop:'hidden',padding:'8px'}}>
                        <span className="neelamfontsize" style={{fontSize: '8px',fontWeight:600}}>Brand Name:</span><br></br><br></br>
                        <span className="neelamfontsize" style={{fontSize: '8px',fontWeight:600}}>{Printdata.brandname}</span>
                        </td>
                        <td style={{border:'1px solid black',borderTop:'hidden',padding:'8px'}}>
                        <span className="neelamfontsize" style={{fontSize: '8px',fontWeight:600,textAlign:'center'}}>Date:</span><br></br><br></br>
                        <span className="neelamfontsize" style={{fontSize: '8px',fontWeight:600}}>{formatDate(Printdata.createddate)}</span>
                        </td>
                        <td style={{border:'1px solid black',borderRight:'hidden',borderTop:'hidden',padding:'8px'}}>
                        <span className="neelamfontsize" style={{fontSize: '8px',fontWeight:600}}>Time:</span><br></br><br></br>
                        <span className="neelamfontsize" style={{fontSize: '8px',fontWeight:600}}>{extractHoursAndMinutes(Printdata.createdtime)}</span>
                        </td>
                    </tr>
                </table>
                <div style={{textAlign:'center',padding:'8px'}}>
                    <span className="neelamfontsize" style={{fontSize: '8px',fontWeight:'700'}}>This Lable is for Neelam Internal Use Only.</span>
                </div>
                </div>
            </div>
        </MDBCard>
    )
}