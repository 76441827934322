import { PR_UPDATE_GET_DATA } from "../constant";
import { PR_UPDATE_ADD_TO_CART } from "../constant";
import { REMOVE_FROM_CART_FROM_PR_UPDATE } from "../constant";
import { PR_UPDATE_CHANGE_MEASUREMENT } from "../constant";
import { PR_UPDATE_CHANGE_QTYPCS } from "../constant";
import { PR_UPDATE_CHANGE_QTYKG } from "../constant";
import { CHANGE_COST_PR_UPDATE } from "../constant";
import { PR_UPDATE_CHANGE_CAL_MEASUREMENT } from "../constant";
import { CHANGE_PR_UPDATE_DISCOUNT } from "../constant";
import { CHANGE_PR_UPDATE_TEXT } from "../constant";

export const purchasereturnupdategetdata = (data) => {
    return {

        type : PR_UPDATE_GET_DATA,
        data : data
    }
};

export const purchasereturnupdateaddtocart = (data,data2) => {
    return {

        type : PR_UPDATE_ADD_TO_CART,
        data : data,
        data2:data2
    }
};

export const removefromcartfrompurchasereturnupdate = (data) => {

    // console.warn("action REMOVE_TO_CART called", data);
    return {

        type : REMOVE_FROM_CART_FROM_PR_UPDATE,
        data : data
    }

};

export const purchasereturnupdatemeasurement = (data, item, data2) => {

    return {
        type: PR_UPDATE_CHANGE_MEASUREMENT,
        data: data,
        item: item,
        data2: data2
    }
};

export const purchasereturnupdateqtypcs = (data, item, data2) => {


    return {

        type: PR_UPDATE_CHANGE_QTYPCS,
        data: data,
        item: item,
        data2: data2
    }

};

export const purchasereturnupdateqtykgs = (data, item, data2) => {

    return {
        type: PR_UPDATE_CHANGE_QTYKG,
        data: data,
        item: item,
        data2: data2

    }
};

export const changecostpurchasereturnupdate = (data, item, data2) => {

    // console.warn("cost change called", data);
    return {

        type : CHANGE_COST_PR_UPDATE,
        data : data,
        item : item,
        data2 : data2

    }

};

export const purchasereturnupdatecalmeasurement = (data, item, data2 ) => {

    return{
        type: PR_UPDATE_CHANGE_CAL_MEASUREMENT,
        data: data,
        item: item,
        data2: data2
    }
};

export const changepurchasereturnupdatediscount = (data, item, data2) => {

    // console.warn("discount change called", data);
    return {

        type : CHANGE_PR_UPDATE_DISCOUNT,
        data : data,
        item : item,
        data2 : data2

    }

};

export const changepurchasereturnupdatetext = (data, item) => {

    // console.warn("text change called", data);
    return {

        type : CHANGE_PR_UPDATE_TEXT,
        data : data,
        item : item
    }

}