import React, { useEffect, useState } from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import ReatilerService from "../../services/Reatiler";
import UserService from "../../services/user.service";
import TemporaryReatilerService from "../../services/TemporaryRetailer";
import axios from "axios";
import { BASE_URL } from "../../services/Api";
import authHeader from "../../services/auth-header";
import Swal from "sweetalert2";

export default function View() {
    const navigate = useNavigate();

    /* Retailer Id */
    const { id } = useParams();
const{pointindex}=useParams();
    const [data, setData] = useState('');
    const [Zone, setZone] = useState('');
    const [state, setstate] = useState('');
    const[delivery_address,setdelivery_address]=useState('');
    const[deliveryAddress,setdeliveryAddress] = useState([]);

    useEffect(() => {
        TemporaryReatilerService.gettemporaryRetailerGet(id).then(res => {
            console.log("mello", res.data.deliveryAddress);
            setData(res.data);
            if (res.data.deliveryAddress && res.data.deliveryAddress.length > 0) {
                setdelivery_address(res.data.deliveryAddress[0].deliveryAddress);
                setdeliveryAddress(res.data.deliveryAddress);
            } else {
                 console.log("Delivery address not found.");
            }
            UserService.getZoneDataEdit1(res.data.zonesid).then(res1 => {
                setZone(res1.data.zoneName);
                res1.data.state_zone.forEach(user => {
                    if (user.id === res.data.stateid) {
                        setstate(user.state_name);
                    }
                });
            });
        });
    }, []);

    // const handledelete=()=>{
   const [isLoading, setIsLoading] = useState(false);
    const applyFontSizeTransition = (elementId) => {
      const element = document.getElementById(elementId);
      element.style.transition = 'font-size 2s';
      element.style.fontSize = '30px';
      element.style.fontWeight = '600';
      // font-weight: 600;
      setTimeout(() => {
         
        document.getElementById("typer1").classList.add('promote');
        element.style.fontSize = '24px';
      }, 1000);
    };
    const handledelete = ( ) => {
        setIsLoading(true); 
        axios.delete( BASE_URL + `retailertemporary/delete/${id}`,{
          headers:authHeader()
        }).then(res=>{

        //   window.location.reload()
        navigate(`/temporaryretailer/${pointindex}`)
        // Alert("Deleted")
        }).catch(error => {
            console.error('Error adding  :', error);
            if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `
                <div>
                  <p id="issueText">Why do I have this issue?</p>
                  <ul>
              
                  <li id="typer1">You might have an internet issue</li>
                  </ul>
                </div>
              `,
              didOpen: () => {
                applyFontSizeTransition('issueText');
              }
            });
          }
          })
      
          .finally(() => {
            setIsLoading(false); 
          });
      };
    // }
    return (

        <div data-aos="fade"> 
            <div className="card">
                <div className='d-flex bd-highlight'>
                <div className="card-header card-title w-100">VIEW TEMPORARY RETAILER</div>
                <Link className='p-2 w-200 card-header card-title bd-highlight' onClick={()=>handledelete()}><Button>Delete</Button></Link>

                <Link className='p-2 w-200 card-header card-title bd-highlight' to={`/temporaryretailer/${pointindex}`}><Button>Back</Button></Link>
                <Link className='p-2 w-200 card-header card-title bd-highlight' to={'/temporarycreate/'+id}><Button className="convertbutton">Convert  </Button></Link>
                </div>
                <div className='card card-body'>
                    <Row className="mb-1">
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="cname2"><b>Referred By: -</b></Form.Label>
                            <span>&nbsp;{data.referredBy}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="cname2"><b>Trade Name : -</b></Form.Label>
                            <span>&nbsp;{data.tradeName}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="cname2"><b>TransporterName : -</b></Form.Label>
                            <span>&nbsp;{data.transporterName}</span>
                        </Form.Group>
                   
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="cname2"><b>Billing Address : -</b></Form.Label>
                            <span>&nbsp;{data.billingAddress}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="cname2"><b>Delivery Address : -</b></Form.Label>
                            <span>&nbsp;{delivery_address}</span>
                        </Form.Group>
                   
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="cname2"><b>GST No : -</b></Form.Label>
                            <span>&nbsp;{data.gstNumber}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="cname2"><b>Pan Number : -</b></Form.Label>
                            <span>&nbsp;{data.panNumber}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="cname2"><b>Aadhar card Number : -</b></Form.Label>
                            <span>&nbsp;{data.aadharcard}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="cname2"><b>Country : -</b></Form.Label>
                            <span>&nbsp;{data.country == "101"?"India":""}</span>

                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="cname2"><b>Zone : -</b></Form.Label>
                            <span>&nbsp;{Zone}</span>
                        </Form.Group>
                   
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="cname2"><b>State : -</b></Form.Label>
                            <span>&nbsp;{state}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="cname2"><b>City : -</b></Form.Label>
                            <span>&nbsp;{data.city}</span>
                        </Form.Group>
                    
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="cname2"><b>Zip Code : -</b></Form.Label>
                            <span>&nbsp;{data.pinCode}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="cname2"><b>GstType : -</b></Form.Label>
                            <span>&nbsp;{data.gstType}</span>
                        </Form.Group>
                   
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="cname2"><b>MobileNumber : -</b></Form.Label>
                            <span>&nbsp;{data.perMobileNumber}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="cname2"><b>Alter Mobile Number : -</b></Form.Label>
                            <span>&nbsp;{data.alterMobileNumber}</span>
                        </Form.Group>
                   
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="cname2"><b>Email : -</b></Form.Label>
                            <span>&nbsp;{data.perEmail}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="cname2"><b>Alter Email : -</b></Form.Label>
                            <span>&nbsp;{data.alterEmail}</span>
                        </Form.Group>
                  
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="cname2"><b>Credit Limit : -</b></Form.Label>
                            <span>&nbsp;{data.creditLimit}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="cname2"><b>Credit Days : -</b></Form.Label>
                            <span>&nbsp;{data.creditDays}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="cname2"><b>Latitude   : -</b></Form.Label>
                            <span>&nbsp;{data.latitude}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                            <Form.Label className="cname2"><b>Longitude   : -</b></Form.Label>
                            <span>&nbsp;{data.longitude}</span>
                        </Form.Group>
                        {deliveryAddress!==undefined?(deliveryAddress.map(user=>(
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2"><b>Delivery Address : -</b></Form.Label>
                        <span>&nbsp;{user.delivery_address}</span>
                        &nbsp;&nbsp;
                        <Form.Label className="cname2"><b> Statename : -</b></Form.Label>
                        <span>&nbsp;{user.state}</span>&nbsp;&nbsp;
                        <Form.Label className="cname2"><b> Pin-Code : -</b></Form.Label>
                        <span>&nbsp;{user.pincode}</span>
                    </Form.Group>
                        ))):null}
                    
                    </Row>
           

                </div>
            </div>
        </div>
    )
}