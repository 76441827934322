import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../services/Api";
import authHeader from "../services/auth-header";
import { MDBCard } from "mdbreact";
import { Button, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import Swal from "sweetalert2";

export default function Update() {
  const { id } = useParams();
  const { pointindex } = useParams();

  const [supplierAll, setSupplierAll] = useState([]);
  const [fromdate, setfromdate] = useState('');
  const [todate, settodate] = useState('');
  const [remarks, setremarks] = useState('');
  const [taxableamount, settaxableamount] = useState('');
  const [taxamount, settaxamount] = useState('');
  const [totalamount, settotalamount] = useState('');
  const [supplier, setsupplier] = useState('');
  const [suppliervalue, setsuppliervalue] = useState([]);
  const [pdf, setpdf] = useState('');
  const [pdf2, setpdf2] = useState("");
  const [contracttype,setcontracttype] = useState('');
  const [status, setstatus] = useState(false);
  let Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })
  const [isLoading, setIsLoading] = useState(false);
  const applyFontSizeTransition = (elementId) => {
    const element = document.getElementById(elementId);
    element.style.transition = 'font-size 2s';
    element.style.fontSize = '30px';
    element.style.fontWeight = '600';
    // font-weight: 600;
    setTimeout(() => {

      document.getElementById("typer1").classList.add('promote');
      element.style.fontSize = '24px';
    }, 1000);
  };
  const navigate = useNavigate();

  const getsupllierdata = () => {
    axios.get(BASE_URL + 'supplier/getAll', {
      headers: authHeader()
    }).then(res => {
      setSupplierAll((res.data).map(user => ({ value: user.id, label: user.companyname })));
    })
  };

  const getcontractmanagementdata = () => {
    axios.get(BASE_URL + 'cm/' + id, {
      headers: authHeader()
    }).then(res => {
      // console.log('mello', res.data.supplier.id);
      setsupplier(res.data.supplier.id);
      setsuppliervalue({ value: res.data.supplier.id, label: res.data.supplier.companyname });
      setfromdate(res.data.fromdate);
      settodate(res.data.todate);
      setremarks(res.data.remarks);
      settaxableamount(res.data.taxableamount);
      settaxamount(res.data.taxamount);
      settotalamount(res.data.totalamount);
      setcontracttype(res.data.contracttype);
      setpdf(res.data.doclocation);

    })
  }

  useEffect(() => {
    getsupllierdata();
    getcontractmanagementdata();
  }, []);

  const onchangesupplier = (e) => {
    setsupplier(e.value);
    setsuppliervalue({ value: e.value, label: e.label })
  }
  const submitdata = () => {
    const emptyBlob = new Blob([""], { type: "application/octet-stream" });

    setIsLoading(true);
    const formdata = new FormData();
    formdata.append('supplier', supplier);
    formdata.append('fromdate', fromdate);
    formdata.append('todate', todate);
    formdata.append('remarks', remarks);
    formdata.append('taxableamount', taxableamount);
    formdata.append('taxamount', taxamount);
    formdata.append('totalamount', totalamount);
    formdata.append('contracttype', contracttype);
    pdf2 ? formdata.append('pdf', pdf2) : formdata.append('pdf', emptyBlob, 'empty-file.txt');


    axios.put(BASE_URL + 'cm/' + id, formdata, {
      headers: authHeader()
    }).then(res => {
      navigate('/ContractManagement/Table/1')
      if (res.status == 201 || res.status == 200) {
        // alert("ee")
        Toast.fire({
          icon: 'success',
          title: 'Successfully Updated'
        })
      }
    }).catch(error => {

      if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `
                <div>
                  <p id="issueText">Why do I have this issue?</p>
                  <ul>
              
                  <li id="typer1">You might have an internet issue</li>
                  </ul>
                </div>
              `,
          didOpen: () => {
            applyFontSizeTransition('issueText');
          }
        });
      }
    }).finally(() => {
      setIsLoading(false);
    });
  }
  return (
    <MDBCard className="company">
      <h1>Update Contract Management</h1>
      <Form className="product1">
        <Row className="mb-1">
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="label">Supplier Name</Form.Label>
            <Select options={supplierAll} value={suppliervalue} onChange={(e) => onchangesupplier(e)} />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="label">From Date</Form.Label>
            <Form.Control type="date" value={fromdate} name="fromdate" onChange={(e) => setfromdate(e.target.value)} />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="label">To Date</Form.Label>
            <Form.Control type="date" value={todate} name="todate" onChange={(e) => settodate(e.target.value)} />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="label">Taxable Amount</Form.Label>
            <Form.Control type="text" placeholder="Enter Taxable Amount" value={taxableamount} name="taxableamount" onChange={(e) => settaxableamount(e.target.value)} autoComplete="off" />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="label">Tax Amount</Form.Label>
            <Form.Control type="text" placeholder="Enter Tax Amount" value={taxamount} name="taxamount" onChange={(e) => settaxamount(e.target.value)} autoComplete="off" />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="label">Total Amount</Form.Label>
            <Form.Control type="text" placeholder="Enter Total Amount" value={totalamount} name="totalamount" onChange={(e) => settotalamount(e.target.value)} autoComplete="off" />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="label">Document : &nbsp;</Form.Label>
            {status === false ?
              <>
                <a style={{ color: 'white' }} href={`http://43.225.54.138:8080/scm/${pdf}`} download target="_blank" ><span style={{ color: 'white', border: '0.5px solid #ededed', padding: '6px', background: '#007bff', borderRadius: '6px', cursor: 'pointer' }}>Download PDF</span></a>&nbsp;
                <span style={{ color: 'white', border: '0.5px solid #e2e0e0', padding: '6px', background: 'red', borderRadius: '5px', cursor: 'pointer' }} onClick={() => setstatus(true)}>Update</span></>
              : <Form.Control type="file" accept=".pdf" onChange={(e) => setpdf2(e.target.files[0])} />}
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="label">Contract Type</Form.Label>
            <Form.Control type="text" placeholder="Enter Contract Type" name="contracttype" value={contracttype} onChange={(e) => setcontracttype(e.target.value)} autoComplete="off" />
          </Form.Group>
          <Form.Group as={Col} md="12" controlId="formBasicName">
            <Form.Label className="label">Remarks</Form.Label>
            <Form.Control as="textarea" rows={3} name='remarks' value={remarks} onChange={(e) => setremarks(e.target.value)} placeholder='Enter Remarks' autoComplete="off" />
          </Form.Group>
        </Row>
        <Button variant="primary" style={{ width: 'auto' }} onClick={submitdata} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Submit'}
        </Button>
      </Form>
    </MDBCard>
  )
}