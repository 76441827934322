import { MDBCard, MDBCardBody } from 'mdb-react-ui-kit'
import { MDBDataTable } from 'mdbreact'
import React, { useEffect, useState } from 'react'
import { CSVLink } from 'react-csv'
import BrandService from '../../services/Brand'
import { FaUserEdit } from 'react-icons/fa'
import { AiOutlineDelete } from 'react-icons/ai'
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal';
import axios from 'axios'
import authHeader from '../../services/auth-header'
import Select from "react-select";
import { BASE_URL } from '../../services/Api'
import AuthService from '../../services/auth.service'


const Create = () => {
    const [show, setShow] = useState(false);
    const [error, setError] = useState(encodeURIComponent(' '))
    const [disword, setdisword] = useState(encodeURIComponent(' '))
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [product2, setproduct2] = useState([])
    const [distributor1, setdistributordata] = useState([])
    const [user_date, setuser_date] = useState([])
    const [distributor, setdistributor] = useState([])
    const [product, setproduct] = useState([]);
    const [inwardDistributorStockItems, setinwardDistributorStockItems] = useState([]);
    const [dist_roles, setdist_roles] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const applyFontSizeTransition = (elementId) => {
        const element = document.getElementById(elementId);
        element.style.transition = 'font-size 2s';
        element.style.fontSize = '30px';
        element.style.fontWeight = '600';
        setTimeout(() => {

            document.getElementById("typer1").classList.add('promote');
            element.style.fontSize = '24px';
        }, 1000);
    };
    const currentuser = AuthService.getCurrentUser();

    let Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    const onChangeproduct = (e, pid) => {

        let name = e;
        let value = pid;
        let dist_item = [];
        axios.get(BASE_URL + 'product/getById/' + value, { headers: authHeader() }).then(res => {
            let distitem = {
                "rate": res.data.dlp,
                "amount": (res.data.dlp) * name,
                "product": { "id": res.data.id },
                'inwardqty': name
            }
            dist_item.push(distitem)

        })
        setinwardDistributorStockItems(dist_item);

    };


    const handlesupersubmit = () => {
        let indate = user_date;
        const postData = {
            indate,
            distributorid: distributor,
            inwardDistributorStockItems
        };

        setIsLoading(true);

        axios.post(BASE_URL + 'indiststock/', postData, { headers: authHeader() })
            .then(response => {
                window.location.reload();

                Toast.fire({
                    icon: 'success',
                    title: 'Successfully added'
                })
            }).catch(error => {
                console.error('Error adding  :', error);
                if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `
            <div>
              <p id="issueText">Why do I have this issue?</p>
              <ul>
          
              <li id="typer1">You might have an internet issue</li>
              </ul>
            </div>
          `,
                        didOpen: () => {
                            applyFontSizeTransition('issueText');
                        }
                    });
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    function getdistributorbyroles() {
        axios.get(BASE_URL + 'distributor/getById/' + currentuser.id, { headers: authHeader() }).then(res => {
            setdist_roles(res.data.tradeName)
            setdistributor(res.data.id);

        })
    }
    useEffect(() => {

        { currentuser.roles[0] == 'ROLE_DISTRIBUTOR' && getdistributorbyroles() }

        axios.get(BASE_URL + `product/page/0/10/asc/id/${error.length ? error : encodeURIComponent(' ')}`, { headers: authHeader() })
            .then(response => {
                if (response.data.data == undefined) {
                    setproduct2(response.data.Index.map(use => ({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code })));

                }
                if (response.data.Index == undefined) {
                    setproduct2(response.data.data.map(use => ({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code })));

                }

            })
            .catch(error => {
                console.error(error);
            });

        axios.get(BASE_URL + `distributor/page/0/10/asc/id/${disword.length ? disword : encodeURIComponent(' ')}`, { headers: authHeader() })
            .then(response => {
                
                if (response.data.data == undefined) {
                    setdistributordata(response.data.Index.map(use => ({ value: use.id, label: use.trade_Name })));
                } if (response.data.Index == undefined) {
                    setdistributordata(response.data.data.map(use => ({ value: use.id, label: use.trade_Name })));

                }

            });

    }, [error, disword]);

    const disid = (val) => {
  
        setdistributor(val)
    }

    const productid = (val) => {
       
        setproduct(val)
    }

    return (
        <div>
            <div className='d-flex bd-highlight'>
                <div className='p-2 w-100 bd-highlight'> <Link  > <button onClick={handleShow} class="btn btn-sm btn-primary">Add DIW</button></Link></div>
                  </div>

            <Modal show={show} onHide={handleClose}  size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Create</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className='col'>
                            <label className='form-label'>Distributor</label>
                            {currentuser.roles[0] == 'ROLE_DISTRIBUTOR' ? <input type='text' className='form-control' value={dist_roles} readOnly /> :
                                <Select options={distributor1} onInputChange={(inputValue) => setdisword(inputValue)} onChange={(options) => disid(options.value)} />}

                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <label className='form-label'>Product</label>
                            <Select options={product2} onInputChange={(inputValue) => setError(inputValue)} onChange={(options) => productid(options.value)} />

                        </div>
                    </div>

                    <div className='row'>
                        <div className='col'>
                            <label className='form-label' >Date</label>
                            <input type='Date' className='form-control' onChange={(e) => setuser_date(e.target.value)} />
                        </div>
                        <div className='col'>
                            <label className='form-label'>Qty</label>
                            <input type='number' className='form-control' onChange={(e) => { onChangeproduct(e.target.value, product) }} />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <a className="btn btn-sm btn-secondary" variant="secondary" onClick={handleClose}>
                        Close
                    </a>
                    <Button variant="primary" style={{ width: 'auto' }} onClick={() => { handleClose(); handlesupersubmit() }} disabled={isLoading}>
                        {isLoading ? 'Loading...' : 'Submit'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Create