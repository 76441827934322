import React, { useEffect, useState } from "react";
import WareHouseService from "../services/WareHouseService";
import { SecondaryOrderComponent } from "./SecondaryOrderComponent";
function SecondaryOrderReports() {

  let header = [
    { label: "Product Name", field: "productname" },
    { label: "Secondary Order Id", field: "wokorderid" },
    { label: "Unit of Measurement", field: "unitomeasurement" },
    { label: "QTY(PCS)", field: "measurement" },
    { label: "QTY(KG)", field: "qty" },
    { label: "Rate", field: "dlp" },

    { label: "Gross Amount", field: "grossamount" },
    { label: "Trade Discount", field: "discount" },
    { label: "Scheme Discount", field: "discount1" },
    { label: "Net Amount", field: "amount" },


    { label: "GST Value", field: "gstvalue" },
    { label: "Total", field: "total" },
 
  ];
  const sumofkeys = [{ first: "closingstock" }];
  return (
    <SecondaryOrderComponent
      head={header}
      title={"Secondary Order Reports"}
      axiostabledata={""}
      Add={""}
      Create={""}
      Edit={""}
      View={""}
      Sumofkeys={sumofkeys}
    />
  );
}

export default SecondaryOrderReports;
