import axios from "axios";
import { MDBCard } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
// import { BASE_URL } from "../../services/Api";
// import authHeader from "../../services/auth-header";
import Select from "react-select";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import authHeader from "../../services/auth-header";
import { BASE_URL } from "../../services/Api";

export default function Mastercreate(){
    const [isLoading, setIsLoading] = useState(false);
    const applyFontSizeTransition = (elementId) => {
      const element = document.getElementById(elementId);
      element.style.transition = 'font-size 2s';
      element.style.fontSize = '30px';
      element.style.fontWeight = '600';
      // font-weight: 600;
      setTimeout(() => {
         
        document.getElementById("typer1").classList.add('promote');
        element.style.fontSize = '24px';
      }, 1000);
    };
    const [productAll, setProductAll] = useState([]);
    const [BrandAll, setBrandAll] = useState([]);
    const [brand, setbrand] = useState('');
    const [product, setproduct] = useState('');
    const [productname1, setproductname1] = useState('');
    const [productname2, setproductname2] = useState('');
    const [qty,setqty]= useState('');
    const [emptymasterqty,setemptymasterqty] = useState('');
    const [minweight,setminweight] = useState('');
    const [maxweight,setmaxweight] = useState('');
    const [length,setlength] = useState('');
    const [width,setwidth] = useState('');
    const [height,setheight] = useState('');
    const [eancode,seteancode] = useState('');
    const [stdqty,setstdqty] = useState('');
const[pcs,setPCS]=useState([])
    const navigate = useNavigate();
    let Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

        /* Get Brand data */
        const getbranddata = () => {
            axios.get(BASE_URL + 'brand/getAll', {
                headers: authHeader()
            }).then(res => {
                setBrandAll(res.data.map(user => ({ value: user.id, label: user.brandName })))
            })
        }

        useEffect(() => {
            getbranddata();
        }, []);

        const onChangeBrand = (e) => {
            let name = e.value;
    
            setbrand({ "id": e.value });
            /* Product Get all */
            axios.get(BASE_URL + 'mastercartoon/bomproduct/' + name, {
                headers: authHeader()
            }).then(res => {
                // console.log('mello',res.data)
                setProductAll(res.data.map(use => ({ value: use.id, label: use.product_name })));
            })
    
        }
    
        const onChangeProduct = (e) => {
            let name = e.value;
            setproduct({ "id": e.value });

        };

        const submitdata = ( ) => {
            setIsLoading(true)

            axios.post(BASE_URL + 'mastercartoon/save',{
                brand,product,emptymasterqty,productname1,productname2,minweight,maxweight,length,width,height,eancode,stdqty,
            },{
                headers:authHeader()
            }).then(res=>{
                navigate('/MasterCarton/Table/1')
                if(res.status==201||res.status==200){
                 // alert("ee")
                 Toast.fire({
                   icon: 'success',
                   title: 'Successfully added'
                 })
               }
            }).catch(error=>{
              
                if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `
                      <div>
                        <p id="issueText">Why do I have this issue?</p>
                        <ul>
                    
                        <li id="typer1">You might have an internet issue</li>
                        </ul>
                      </div>
                    `,
                    didOpen: () => {
                      applyFontSizeTransition('issueText');
                    }
                  });
                }else{
                  Swal.fire({
                      title: 'This Product is already Create',
                      text: "You won't be able to revert this!",
                      icon: 'warning',
                    })
  
                }
          })
          .finally(() => {
              setIsLoading(false); 
            });
        }
    return(
        <MDBCard className="company">
            <h1>Master Carton Create</h1>
            <Form className="product1">
            <Row className="mb-1">
                <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Brand Name</Form.Label>
                        <Select options={BrandAll} onChange={(e) => onChangeBrand(e)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Product Name</Form.Label>
                        <Select options={productAll} onChange={(e) => onChangeProduct(e)} />
                    </Form.Group>
                  
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Empty Master QTY</Form.Label>
                        <Form.Control type="text" name="emptymasterqty" placeholder="Enter Empty Master QTY" autoComplete="off" onChange={(e) => setemptymasterqty(e.target.value)} />
                    </Form.Group>
                   
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Product Name1</Form.Label>
                        <Form.Control type="text" name="productname1" placeholder="Enter Product Name1" autoComplete="off" onChange={(e) => setproductname1(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Product Name2</Form.Label>
                        <Form.Control type="text" name="productname2" placeholder="Enter Product Name2" autoComplete="off" onChange={(e) => setproductname2(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Min Weight</Form.Label>
                        <Form.Control type="number" name="minweight" placeholder="Enter min Weight" autoComplete="off" onChange={(e) => setminweight(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Max Weight</Form.Label>
                        <Form.Control type="number" name="maxweight" placeholder="Enter Max Weight" autoComplete="off" onChange={(e) => setmaxweight(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Length</Form.Label>
                        <Form.Control type="number" name="length" placeholder="Enter Length" autoComplete="off" onChange={(e) => setlength(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Width</Form.Label>
                        <Form.Control type="number" name="width" placeholder="Enter Width" autoComplete="off" onChange={(e) => setwidth(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Height</Form.Label>
                        <Form.Control type="number" name="height" placeholder="Enter Height" autoComplete="off" onChange={(e) => setheight(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">EAN Code</Form.Label>
                        <Form.Control type="number" name="eancode" placeholder="Enter EAN Code" autoComplete="off" onChange={(e) => seteancode(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">STD QTY</Form.Label>
                        <Form.Control type="number" name="stdqty" placeholder="Enter STD QTY" autoComplete="off" onChange={(e) => setstdqty(e.target.value)} />
                    </Form.Group>
                </Row>
                {/* <Button variant="primary" type="button" onClick={submitdata}>
                    Submit
                </Button> */}

                <Button variant="primary" style={{width:'auto'}} onClick={()=>submitdata()} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Submit'}
        </Button>
            </Form>
            </MDBCard>
    )
}