import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../services/Api";
import authHeader from "../services/auth-header";
import { MDBCard } from "mdbreact";
import { Button, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import Swal from "sweetalert2";
import AuthService from "../services/auth.service";

export default function View(){
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const applyFontSizeTransition = (elementId) => {
      const element = document.getElementById(elementId);
      element.style.transition = 'font-size 2s';
      element.style.fontSize = '30px';
      element.style.fontWeight = '600';
      // font-weight: 600;
      setTimeout(() => {
         
        document.getElementById("typer1").classList.add('promote');
        element.style.fontSize = '24px';
      }, 1000);
    };

    const { id } = useParams();
    const { pointindex } = useParams();
   const [ data, setData ] = useState('');
   const [ state, setState ] = useState('');
   const user = AuthService.getCurrentUser();
   const getItem = (id) => {

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true); 

        axios.delete( BASE_URL + 'branch/' + id, {
            headers:authHeader()
        }).then(res=>{
            Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
              )
              navigate(`/Branch/Table/${pointindex}`)
            // window.location.reload();
        }).catch(error => {
            console.error('Error adding  :', error);
            if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `
                <div>
                  <p id="issueText">Why do I have this issue?</p>
                  <ul>
              
                  <li id="typer1">You might have an internet issue</li>
                  </ul>
                </div>
              `,
              didOpen: () => {
                applyFontSizeTransition('issueText');
              }
            });
          }
          })
          .finally(() => {
            setIsLoading(false); 
          });
        
       
      }
    })
    
    
  };
    let getBranchData = ( ) => {
        axios.get( BASE_URL + 'branch/'+id,{
            headers:authHeader()
        }).then(res=>{
            setData(res.data);
            setState(res.data.states)
        })
    };

    useEffect(() => {
        getBranchData()
    },[])

    return(
        <MDBCard className="company">
     


      <div className='d-flex bd-highlight'>
        <div className="card-header card-title w-100">VIEW Branch</div>
        {/* <button className='prox btn' onClick={()=>handleChange12()} ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"/><polyline points="12 19 5 12 12 5"/></svg></button> */}
        {/* <a className=' delete btn' onClick={()=>handledelete(id)}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"/><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"/><line x1="10" y1="11" x2="10" y2="17"/><line x1="14" y1="11" x2="14" y2="17"/></svg></a> */}
        {user.roles[0] === 'ROLE_ADMIN' && 

<Link className='p-2 w-200 card-header card-title bd-highlight' variant="primary" style={{width:'auto'}} onClick={()=>getItem(id)} disabled={isLoading}>
        
<Button className="btn-sm">  {isLoading ? 'Loading...' : 'Delete'}</Button>
</Link>        
        }
        
        <Link className='p-2 w-200 card-header card-title bd-highlight' to={`/Branch/Edit/${id}/${pointindex}`}><Button className="btn-sm">edit</Button></Link>

        <Link className='p-2 w-200 card-header card-title bd-highlight' to={`/Branch/Table/${pointindex}`}><Button  className="btn-sm">Back</Button></Link>
     
      </div>





        <Form className="product1">
            <Row className="mb-1">
                <Form.Group as={Col} md="6" controlId="formBasicName">
                    <Form.Label className="label">Branch Name</Form.Label>
                    <Form.Control type="text" value={data.branchname} />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="formBasicName">
                    <Form.Label className="label">Phone No</Form.Label>
                    <Form.Control type="text" value={data.phoneno} />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="formBasicName">
                    <Form.Label className="label">GST No</Form.Label>
                    <Form.Control type="text" value={data.gstno} />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="formBasicName">
                    <Form.Label className="label">Email</Form.Label>
                    <Form.Control type="text" value={data.email} />
                </Form.Group>
                <Form.Group as={Col} md="12" controlId="formBasicName">
                    <Form.Label className="label">Address</Form.Label>
                    <Form.Control as="textarea" value={data.address} rows={3} name='address' placeholder='Address'  />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="formBasicName">
                    <Form.Label className="label">Country</Form.Label>
                    <Form.Control type="text"  value={"India"} disabled/>
                </Form.Group>                    
                <Form.Group as={Col} md="6" controlId="formBasicName">
                    <Form.Label className="label">State</Form.Label>
                    <Form.Control type="text"  value={state.name} readOnly  />
                </Form.Group>                    
                <Form.Group as={Col} md="6" controlId="formBasicName">
                    <Form.Label className="label">City</Form.Label>
                    <Form.Control type="text" value={data.cities} />
                </Form.Group>                  
                  <Form.Group as={Col} md="6" controlId="formBasicName">
                    <Form.Label className="label">Account No</Form.Label>
                    <Form.Control type="text"  value={data.accountno}/>
                </Form.Group>                  
                  <Form.Group as={Col} md="6" controlId="formBasicName">
                    <Form.Label className="label">Bank Name</Form.Label>
                    <Form.Control type="text" value={data.bankname}  />
                </Form.Group>                   
                 <Form.Group as={Col} md="6" controlId="formBasicName">
                    <Form.Label className="label">Branch Name</Form.Label>
                    <Form.Control type="text" value={data.branch} />
                </Form.Group>               
                     <Form.Group as={Col} md="6" controlId="formBasicName">
                    <Form.Label className="label">IFSC Code</Form.Label>
                    <Form.Control type="text" value={data.ifsc} />
                </Form.Group>
            </Row>
        </Form>
    </MDBCard>
    )
}