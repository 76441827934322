import axios from "axios";
import authHeader from "./auth-header";

const API_URL = "http://43.225.54.138:8080/scm/api/distributor/";


const getDistributorTable = () => {
  return axios.get(API_URL + "getAll", { headers: authHeader() });
};

const getReatilerTable = () => {
    return axios.get(API_URL +"fetch", { headers: authHeader() });
  };

  const getReatilerCreate = (distrbutor) => {
    return axios.post(API_URL +"create",distrbutor, { headers: authHeader() });
  };
const getdistributorEdit = (id,distrbutor) => {
  return axios.put(API_URL + "update/"+id, distrbutor,{ headers: authHeader() });
};

const getstaffforret = (id) => {
  return axios.get('http://43.225.54.138:8080/scm/api/' + 'staff/staffs/'+id,{ headers: authHeader() });
};



const getdistributorget = (id) => {
  return axios.get(API_URL + "getById/"+id,{ headers: authHeader() });
};

const getDelete = ( id ) => {
    return axios.delete(API_URL + "delete/"+id, { headers: authHeader() });
  };

 
const DistrbutorService = {
  
    getReatilerTable,
    getReatilerCreate,
    getdistributorEdit,
    getdistributorget,
    getDelete,
    getstaffforret,
    getDistributorTable,

};

export default DistrbutorService;