import React from 'react';
import { useState } from 'react';

import {
  AiOutlineDoubleLeft,
  AiOutlineDoubleRight,
} from "react-icons/ai";
import { NavLink, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { getroles } from '../Login/Login';

export const SideBar = ({ children }) => {
  // let 0=1
  const [isOpen, setIsOpen] = useState(true);
  const [isOpenhh, setIsOpenhh] = useState(true);
  const [idmenu, setidformeenu] = useState([{ name: "Dashboard", path: `/DashBoard` }, { name: "Product", path: `/product/${1}` }, { name: "Brand", path: "/brand/1" }, { name: "Branch", path: "/Branch/Table/1" }, { name: "Warehouse", path: "/warehouse/1" }, { name: "Company", path: "/company" },{ name: "Contract Management", path: "/ContractManagement/Table/1" },{ name: "Assets", path: "/Assest/Table/1" },{ name: "Asset Request Form", path: "/Asset Request Form/Table/1" },{ name: "Assign To Staff", path: "/AssignToStaffTable/1" }])
  const [Admin, setadmin] = useState([]);
  const [Dis, setdis] = useState(1)
  const [message, setMessage] = useState([]);

  let ram
  let yummy

  const location = useLocation();

  useEffect(() => {

    handleidformenu()
    setadmin(getroles());

    ram = getroles();
    setadmin(ram.toString());

    const timeoutId = setTimeout(() => {
      setMessage('Delayed message after 2 seconds!');
    }, 2000);

    return () => clearTimeout(timeoutId);

  }, []);


  useEffect(() => {
    if (location.pathname.includes('create') ||location.pathname.includes('Create') || location.pathname.includes('edit') || location.pathname.includes('PrimaryOrderUpdate') || location.pathname.includes('SalesOrderCreate') || location.pathname.includes('PrimaryOrderCreate')) {
      setIsOpenhh(false);
    } else {
      setIsOpenhh(true);
    }
    if(location.pathname.includes('Repacking/Create')||location.pathname.includes('OpenCreate')){
      setIsOpenhh(true);
    }
  }, [location.pathname]);


  const handle122 = () => {

    setIsOpenhh(!isOpenhh);
  }

  const menuIconClick = () => {

    setIsOpenhh(!isOpenhh);
  };

  const MenuItem = [
    {
      id: "one1",
      path: `/product/${1}`,
      name: "Product",
      sublabel: 'Master',
      primer: 'samer',
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trello"><rect x="3" y="3" width="18" height="18" rx="2" ry="2" /><rect x="7" y="7" width="3" height="9" /><rect x="14" y="7" width="3" height="5" /></svg>
      ,
      mod: [{ name: "Dashboard", path: `/DashBoard` }, { name: "Product", path: `/product/${1}` }, { name: "Brand", path: "/brand/1" }, { name: "Branch", path: "/Branch/Table/1" }, { name: "Warehouse", path: "/warehouse/1" }, { name: "Company", path: "/company" },{ name: "Contract Management", path: "/ContractManagement/Table/1" },{ name: "Assets", path: "/Assest/Table/1" },{ name: "Asset Request Form", path: "/Asset Request Form/Table/1" },{ name: "Assign To Staff", path: "/AssignToStaffTable/1" }]
    },
    {
      id: "3",
      path: `/Stafftable/${0}`,
      name: "Staff",
      sublabel: 'usermaster',
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user-plus"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" /><circle cx="8.5" cy="7" r="4" /><line x1="20" y1="8" x2="20" y2="14" /><line x1="23" y1="11" x2="17" y2="11" /></svg>,

      mod: [{ name: "Staff", path: `/Stafftable/${1}` },{name:'Transport',path:'/Transporttable/1'},{ name: "Gala", path: "/Gala/Table/1" },{name:'Minutes of the Meetings',path:'/Meettable/1'}, { name: "Distributor", path: "/Newdis/1" }, { name: "Distributor Invoice", path: "/Distable/1" }, { name: "Retailer", path: "/Newretailer/1" }, { name: "Temporary Retailer", path: "/temporaryretailer/1" }, { name: "Supplier", path: `/SupplierTable/1` }, { name: "Distributor Opening Stock", path: `/OpenCreate/1` },{name:"Distributor Minimum  Stock",path:'/Dismintable/1'}
      ]
    },

    {
      id: "4",
      path: "/Newretailer/1",
      name: "Retailer",
      sublabel: 'Purchase',
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-package"><line x1="16.5" y1="9.4" x2="7.5" y2="4.21" /><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z" /><polyline points="3.27 6.96 12 12.01 20.73 6.96" /><line x1="12" y1="22.08" x2="12" y2="12" /></svg>
      , mod: [{ name: "Purchase Order", path: `/potable/1` }, { name: "Supplier Delivery Note", path: `/SupplierDeliveryNoteTable/1` }, { name: "Material Receipt Note", path: `/MaterialReceiptNoteTable/1` },
      { name: "Purchase", path: `/Newpurchase/1` }, { name: "Purchase Return", path: `/PurchaseReturnTable/1` },{ name: "Voucher", path: `/Voucher/Table/1` },]
    },
    {
      id: "2",
      path: "/brand/1",
      name: "Brand",
      sublabel: 'Orders',
      primer: 'samer',
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-globe"><circle cx="12" cy="12" r="10" /><line x1="2" y1="12" x2="22" y2="12" /><path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z" /></svg>
      ,
      mod: [{ name: "Secondary Order", path: "/Newoo/1" }, { name: "Primary Order", path: "/Newpo/1" },]

    },
    {
      id: "5",
      path: "/temporaryretailer/1",
      name: "ROLE_ADMIN",
      sublabel: 'Sales',
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-bag"><path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z" /><line x1="3" y1="6" x2="21" y2="6" /><path d="M16 10a4 4 0 0 1-8 0" /></svg>
      , mod: [{ name: "Sales Order", path: "/SalesOrderTable/1" }, { name: "Delivery Challan", path: "/DeliveryChallanTable/1" }, { name: "Sales", path: "/Sales/Table/1" },

      { name: "Sales Return", path: "salesreturn/1" }, { name: "Distributor Outward", path: `/Distributor/Outward/Table/1` },{ name: "Distributor Inward", path: `/Distributor/Inward/Table/1` }]
    },
    {
      id: "6",
      path: "/Newdis/1",
      name: "Distributor",
      sublabel: 'Reports',
      primer: 'samer',
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-credit-card"><rect x="1" y="4" width="22" height="16" rx="2" ry="2" /><line x1="1" y1="10" x2="23" y2="10" /></svg>
      , mod: [{ name: "Retailer Pending Sales Order Report", path: "/RetailerReport" } ,{name:"Pending S.O By Retailer",path:"/Soreport"},{name:"Excel Warehouse Report",path:"/Warehousestockreport/1"}, {name:"Contract Management",path:"/Contract/1"}, { name: "Distributor Pending Sales Order Report", path: "/DistributorReport" }, { name: " Distributor Stock Report ", path: "/DistributorStockrp" }, { name: " Transport Report", path: "/TransportReport/1" }, { name: "Retailer Month Report", path: "/RetailerMonthReport/1" }, { name: "Distributor Month Report ", path: "/DistributorMonthReport/1" }, { name: "Minimum Stock Report ", path: "/MininmumStockReport/1" }, { name: "Godown Stock Report", path: "/GodownStockReport/1" }, { name: "Secondary Order Reports", path: "/SecondaryOrderReports/1" }, { name: "Primary Order Reports", path: "/PrimaryOrderReports/1" }, { name: "Sales Expense Reports", path: "/SalesExpenseReports/1" },{ name: "Pending Order Vs Godown Stock Reports", path: "/PendingOrderVsGodownStockComponents/1" },{name:"Distributor Minimum Stock",path:'distributorminimumstockreport/1'}]
    },



    {
      id: "7",
      path: "/Newoo/1",
      name: "Secondary Order",
      sublabel: 'MSL',
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clipboard"><path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2" /><rect x="8" y="2" width="8" height="4" rx="1" ry="1" /></svg>
      , mod: [{ name: "Minimum Stock Level", path: "/Minstockindex/1" }]
    },
    {
      id: "8",
      path: "/Newpo/1",
      name: "Primary Order",
      sublabel: 'Launch',
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clipboard"><path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2" /><rect x="8" y="2" width="8" height="4" rx="1" ry="1" /></svg>
      , mod: [{ name: "Product  launch", path: "/Erpmobilebanner345" }, { name: "Product of the Month", path: "/Arealaunchmobile" }]
    },
    {
      id: "9",
      path: "/Newpo/1",
      name: "Assembly",
      sublabel: 'Assembly',
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clipboard"><path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2" /><rect x="8" y="2" width="8" height="4" rx="1" ry="1" /></svg>
      , mod: [ { name: "Create BOM", path: "/BOM/Table/1" },{ name:"Create Master Carton", path: "/MasterCarton/Table/1" },{ name: "Create Product Barcode", path: "/ProductBarCode/Table/1" }, { name: "Create Set Barcode", path: "/SetProductBarCode/Table/1" },{ name: "Carton Bar Code", path: "/CartonBarCode/Table/1" },{ name: "Print Product Barcode", path: "/PrintProductBarcode/1" },{ name: "Print Set Barcode", path: "/PrintSetbarcode/1" },{ name: "Repacking", path: "/Repackingtable/1" },{name:"Loose packing",path:"/Loseetable/1"}]
    },
  {
    id:'11',
    path:'/',
    name:'Production',
    sublabel:'Production',
    icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clipboard"><path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2" /><rect x="8" y="2" width="8" height="4" rx="1" ry="1" /></svg>,
  mod:[{name:'Jobsheet Inward',path:'/Inwardtable/1'},{name:'Jobsheet Outward',path:'/Outwardtable/1'}]
  },  
    {
      id: "10",
      path: "/Newpo/1",
      name: "Attendance",
      sublabel: 'Attendance',
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clipboard"><path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2" /><rect x="8" y="2" width="8" height="4" rx="1" ry="1" /></svg>
      , mod: [ { name: "Attendance", path: "/Attendance/Table/1" },{ name: "Attendance Report ", path: "/Attendance/Page/1" },{ name: "Holiday ", path: "HolidayTable/1" }]
    },
    // {
    //   id: "10",
    //   path: "/Newpo/1",
    //   name: "Grouping",
    //   sublabel: 'Grouping',
    //   icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clipboard"><path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2" /><rect x="8" y="2" width="8" height="4" rx="1" ry="1" /></svg>
    //   , mod: [{ name: "Group1", path: "/Group1Table/1" },{ name: "Group2", path: "/group2Table/1" },{ name: "Group3", path: "/group3Table/1" },{ name: "Group4", path: "/group4Table/1" },{ name: "Group5", path: "/group5Table/1" },{ name: "Group6", path: "/group6Table/1" },{ name: "Group7", path: "/group7Table/1" },{ name: "Group8", path: "/group8Table/1" },{ name: "Group9", path: "/group9Table/1" },{ name: "Group10", path: "/group10Table/1" },]
    // },
    {
      id: "16",
      path: "/Newpo/1",
      name: "Grouping",
      sublabel: 'Grouping',
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clipboard"><path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2" /><rect x="8" y="2" width="8" height="4" rx="1" ry="1" /></svg>
      , mod: [{ name: "Group1", path: "/Group1Table/1" },{ name: "Group2", path: "/group2Table/1" },{ name: "Group3", path: "/group3Table/1" },{ name: "Group4", path: "/group4Table/1" },{ name: "Group5", path: "/group5Table/1" },{ name: "Group6", path: "/group6Table/1" },{ name: "Group7", path: "/group7Table/1" },{ name: "Group8", path: "/group8Table/1" },{ name: "Group9", path: "/group9Table/1" },{ name: "Group10", path: "/group10Table/1" },]
    }

  ]
  const Fordretailer = [
    {
      id: "6",
      path: "/DashBoard",
      name: "Dashboard",
      Role: "helper"
      // icon:<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"   stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-bag"><path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"/><line x1="3" y1="6" x2="21" y2="6"/><path d="M16 10a4 4 0 0 1-8 0"/></svg>
    },

    {
      id: "9",
      path: "/product/1",
      name: "Products",
      Role: "helper"
      // icon:<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"   stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-bag"><path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"/><line x1="3" y1="6" x2="21" y2="6"/><path d="M16 10a4 4 0 0 1-8 0"/></svg>
    },
    {
      id: "7",
      path: "/Newoo/1",
      name: "Secondary Order",
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-credit-card"><rect x="1" y="4" width="22" height="16" rx="2" ry="2" /><line x1="1" y1="10" x2="23" y2="10" /></svg>
    },
    {
      id: "7",
      path: "/Meettable/1",
      name: "Minutes of the Meetings  ",
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-credit-card"><rect x="1" y="4" width="22" height="16" rx="2" ry="2" /><line x1="1" y1="10" x2="23" y2="10" /></svg>
    },
    // {name:'Minutes of the Meetings',path:'/Meettable/1'}

  ]

  const Fordis = [
    // dash, dist pending sales order, dist stock report,
    {
      id: "6",
      path: "/DashBoard",
      name: "Dashboard",
      Role: "helper"
      // icon:<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"   stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-bag"><path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"/><line x1="3" y1="6" x2="21" y2="6"/><path d="M16 10a4 4 0 0 1-8 0"/></svg>
    }, {
      id: "9",
      path: "/Distable/1",
      name: "Distributor Invoice",
      // icon:  
    },
    {
      id: "9",
      path: "/Warehousestockreport/1",
      name: "Excel Warehouse Report",
      // icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-credit-card"><rect x="1" y="4" width="22" height="16" rx="2" ry="2" /><line x1="1" y1="10" x2="23" y2="10" /></svg>
    },

  
    {
      id: "5",
      path: "/Newretailer/1",
      name: "Retailer",
      Role: "helper",
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-bag"><path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z" /><line x1="3" y1="6" x2="21" y2="6" /><path d="M16 10a4 4 0 0 1-8 0" /></svg>
      ,
    },
    {
      id: "7",
      path: "/Newoo/1",
      name: "Secondary Order",
      // icon:<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"   stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-credit-card"><rect x="1" y="4" width="22" height="16" rx="2" ry="2"/><line x1="1" y1="10" x2="23" y2="10"/></svg>
    },

    {
      id: "8",
      path: "/Newpo/1",
      name: "Primary Order",
      Role: "helper",
      // icon:<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"   stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-bag"><path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"/><line x1="3" y1="6" x2="21" y2="6"/><path d="M16 10a4 4 0 0 1-8 0"/></svg>
    },
    {
      id: "9",
      path: "/OpenCreate/1",
      name: "Distributor Opening Stock",
      Role: "helper"
      // icon:<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"   stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-bag"><path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"/><line x1="3" y1="6" x2="21" y2="6"/><path d="M16 10a4 4 0 0 1-8 0"/></svg>
    },
    {
      id: "10",
      path: "/product/1",
      name: "Products",
      Role: "helper"
      // icon:<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"   stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-bag"><path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"/><line x1="3" y1="6" x2="21" y2="6"/><path d="M16 10a4 4 0 0 1-8 0"/></svg>
    },
    {
      id: "7",
      path: "/Meettable/1",
      name: "Minutes of the Meetings  ",
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-credit-card"><rect x="1" y="4" width="22" height="16" rx="2" ry="2" /><line x1="1" y1="10" x2="23" y2="10" /></svg>
    },
    
    { id: "11", name: "Sales Order", path: "/SalesOrderTable/1" },
    { id: "12", name: "Delivery Challan", path: "/DeliveryChallanTable/1" },
    { id: "13", name: "Sales", path: "/Sales/Table/1" },

    { id: "14", name: "Sales Return", path: "salesreturn/1" },
    { id: "15", name: "Distributor Outward", path: `/Distributor/Outward/Table/1` },
    { id: "16", name: " Distributor Stock Report ", path: "/DistributorStockrp" }

  ]

  const Forstaff = [
    {
      id: "6",
      path: "/DashBoard",
      name: "Dashboard",
      Role: "helper"
      // icon:<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"   stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-bag"><path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"/><line x1="3" y1="6" x2="21" y2="6"/><path d="M16 10a4 4 0 0 1-8 0"/></svg>
    },
    {
      id: "7",
      path: "/Meettable/1",
      name: "Minutes of the Meetings  ",
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-credit-card"><rect x="1" y="4" width="22" height="16" rx="2" ry="2" /><line x1="1" y1="10" x2="23" y2="10" /></svg>
    },
    {
      id: "5",
      path: "/Newretailer/1",
      name: "Retailer",
      Role: "helper",
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-bag"><path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z" /><line x1="3" y1="6" x2="21" y2="6" /><path d="M16 10a4 4 0 0 1-8 0" /></svg>
      ,
    },

    {
      id: "6",
      path: "/Newdis/1",
      Role: "helper",
      name: "Distributor",
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-credit-card"><rect x="1" y="4" width="22" height="16" rx="2" ry="2" /><line x1="1" y1="10" x2="23" y2="10" /></svg>
    },
    {
      id: "7",
      path: "/Newoo/1",
      name: "Secondary Order",
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-credit-card"><rect x="1" y="4" width="22" height="16" rx="2" ry="2" /><line x1="1" y1="10" x2="23" y2="10" /></svg>
    },
    // {
    //   id: "9",
    //   path: "/Assest/Table/1",
    //   name: "Assest",
    //  },
     {
      id: "9",
      path: "/Asset%20Request%20Form/Table/1",
      name: "Assest Request Form",
     }, {
      id: "9",
      path: "/AssignToStaffTable/1/",
      name: "Assign to Staff ",
     },
    //AssignToStaffTable/1/  
    {
      id: "8",
      path: "/product/1",
      name: "Products",
      Role: "helper"
      // icon:<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"   stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-bag"><path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"/><line x1="3" y1="6" x2="21" y2="6"/><path d="M16 10a4 4 0 0 1-8 0"/></svg>
    },
    {
      id: "9",
      path: "/Stafftable/1",
      name: "Staff",
      Role: "helper"
      // icon:<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"   stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-bag"><path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"/><line x1="3" y1="6" x2="21" y2="6"/><path d="M16 10a4 4 0 0 1-8 0"/></svg>
    },
    // getroles() != "ROLE_ASE" ? { id: "8", name: "Staff", path: `/Stafftable/${1}` } : ""

  ]

  const Forsupplier = [
    {
      id: "6",
      path: "/DashBoard",
      name: "Dashboard",
      Role: "helper"
      // icon:<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"   stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-bag"><path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"/><line x1="3" y1="6" x2="21" y2="6"/><path d="M16 10a4 4 0 0 1-8 0"/></svg>
    },
    {
      id: "5",
      path: "/potable/1",
      name: "Purchase Order",
      Role: "helper",
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-bag"><path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z" /><line x1="3" y1="6" x2="21" y2="6" /><path d="M16 10a4 4 0 0 1-8 0" /></svg>
      ,
    },

    {
      id: "6",
      path: "SupplierDeliveryNoteTable/1",
      Role: "helper",
      name: "Supplier Delivery Note",
      // icon:<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"   stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-credit-card"><rect x="1" y="4" width="22" height="16" rx="2" ry="2"/><line x1="1" y1="10" x2="23" y2="10"/></svg>
    },
    // {  id:"7",
    // path:"/Newoo/1",
    // name:"Secondary Order",
    // icon:<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"   stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-credit-card"><rect x="1" y="4" width="22" height="16" rx="2" ry="2"/><line x1="1" y1="10" x2="23" y2="10"/></svg>
    // },

  ]

  const handleidformenu = (id) => {
    const trt = id;
    setdis(id);

    if (trt) {

      var element = document.getElementById(id);

      var elements = document.getElementsByClassName("newClass");
      for (var i = 0; i < elements.length; i++) {
        elements[i].classList.remove("newClass");

      }

      element.classList.add("newClass");
      element.classList.add("newClass123");

      yummy = MenuItem.filter((item) => item.id == id).map((item) => item.mod);
      setidformeenu(yummy[0]);

      setTimeout(function () {
        element.classList.remove("newClass123");
      }, 1000);
    }


  };

  return (

    <div className='hhh' style={{ display: 'flex' }} >
      <div className='upperbar' id="roman" style={{ width: isOpenhh ? "247px" : "70px" }}>
        <img src='../Login/v4.png' className='prrer' style={{ width: '67%', display: isOpenhh ? "none" : "block" }} />


        <div className='bars ' style={{ marginLeft: isOpenhh ? "216px" : "45px", marginTop: isOpenhh ? "-47px" : "29px" }}>
          <div className="closemenu" onClick={() => { menuIconClick() }}  >

            {isOpenhh ? (
              <AiOutlineDoubleLeft className='color' />
            ) : (
              <AiOutlineDoubleRight className='left' />
            )}
          </div>
        </div>
      </div>

      <div className='qw er pre ' id='qerteww' style={{ background: isOpenhh ? "0px" : "linear-gradient(228deg, hsla(227, 74%, 49%, 1) 0%, hsla(220, 74%, 33%, 1) 49%, hsla(226, 74%, 35%, 1) 89%)" }} >
        <div className='top_section' >
          <h3 className='logo' style={{ display: isOpenhh ? "block" : "none" }}>

          </h3>
          <div className='bars' onClick={menuIconClick} style={{ marginLeft: isOpenhh ? "199px" : "0px" }}>
            <div className="closemenu" onClick={menuIconClick} style={{ display: "none" }}>

              {isOpenhh ? (
                <AiOutlineDoubleLeft />
              ) : (
                <AiOutlineDoubleRight />
              )}
            </div>
          </div>
        </div>
        {

          Admin == "ROLE_ADMIN" && (
            MenuItem.map((item, index) => {

              if (2 == 2) {
                return (
                  <>
                    <li
                      id={item.id}
                      onClick={() => {
                        handleidformenu(item.id);
                        if (isOpenhh === false) {
                          handle122();
                        }
                      }}
                      // className='link2 j2j shimmer1'
                      className={` ${message.length == 0 ? ' shimmer1 link j2j' : 'link j2j'}`}

                    >
                      <div className='flexir'>
                        {item.icon}

                      </div>
                    </li>
                    {item.primer == 'samer' ? <span className={` ${item.primer == 'samer' ? 'mastersert' : ''}`}  >{item.sublabel}</span> : ""}

                    {item.sublabel == 'Sales' ? <span className={` ${item.sublabel == 'Sales' ? 'sales' : ''}`}  >{item.sublabel}</span> : ""}

                    {item.sublabel == 'Purchase' ? <span className='Purchaseing'>{item.sublabel}</span> : ""}

                    {item.sublabel == 'MSL' ? <span className='msl'   >{item.sublabel}</span> : ""}

                    {item.sublabel == 'Launch' ? <span className='mastersert'   >{item.sublabel}</span> : ""}

                    {item.sublabel == 'Assembly' ? <span className='mastersert'   >{item.sublabel}</span> : ""}

                    {item.sublabel == 'Production' ? <span className='mastersert'   >{item.sublabel}</span> : ""}
                    
                    {item.sublabel == 'Attendance' ? <span className='mastersert'   >{item.sublabel}</span> : ""}

                    {item.sublabel == 'Grouping' ? <span className='mastersert'   >{item.sublabel}</span> : ""}
                    
                    {item.sublabel == 'usermaster' ? <div className='fftt'>

                      <span className='mastersert lefttt'>User</span>
                      <span className='mastersert'>Master</span>
                    </div> : ""}
                  </>
                );
              } else {
                console.log("sorry not working")
              }

            })
          )


        }
        {
          //  Forstaff Fordis      
          Admin == "ROLE_RETAILER" && (
            Fordretailer.map((item, index) => {

              if (2 == 2) {
                return (
                  <li
                    id={item.id}
                    onClick={() => {
                      handleidformenu(item.id);
                      if (isOpenhh === false) {
                        handle122();
                      }
                    }}
                    // className='link2 j2j shimmer1'
                    className={` ${message.length == 0 ? ' shimmer1 link j2j' : 'link j2j'}`}

                  >
                    {item.icon}
                  </li>
                );
              } else {
                console.log("sorry not working")
              }

            })
          )


        }

        {
          //  Forstaff Fordis      
          Admin == "ROLE_SUPPLIER" && (
            Forsupplier.map((item, index) => {

              if (2 == 2) {
                return (
                  <li
                    id={item.id}
                    onClick={() => {
                      handleidformenu(item.id);
                      if (isOpenhh === false) {
                        handle122();
                      }
                    }}
                    // className='link2 j2j shimmer1'
                    className={` ${message.length == 0 ? ' shimmer1 link j2j' : 'link j2j'}`}

                  >
                    {item.icon}
                  </li>
                );
              } else {
                console.log("sorry not working")
              }

            })
          )


        }

        {
          //  Forstaff Fordis      
          Admin == "ROLE_DISTRIBUTOR" && (
            Fordis.map((item, index) => {

              if (2 == 2) {
                return (
                  <li
                    id={item.id}
                    onClick={() => {
                      handleidformenu(item.id);
                      if (isOpenhh === false) {
                        handle122();
                      }
                    }}
                    // className='link2 j2j shimmer1'
                    className={` ${message.length == 0 ? ' shimmer1 link j2j' : 'link j2j'}`}

                  >
                    {item.icon}
                  </li>
                );
              } else {
                console.log("sorry not working")
              }

            })
          )


        }
      </div>

      <img src={require('../Login/v4.png')} id='swift' className='logoopen' style={{ marginTop: '-8px' }} />
      <g className='logoopen' id='logoo' onClick={handle122}>   <AiOutlineDoubleRight style={{ display: isOpenhh ? "none" : "block" }} id='logoo1' className='color' /></g>
      <div className='sidebar  ' id="sidein" style={{ width: isOpenhh ? "200px" : "0px", left: '61px',overflow:"scroll" }} >
        <div className='top_section' >
          <h3 className='logo' style={{ display: isOpenhh ? "block" : "none" }}>

          </h3>
          <div className='bars' onClick={menuIconClick} style={{ marginLeft: isOpenhh ? "199px" : "0px" }}>
            <div className="closemenu" onClick={menuIconClick} style={{ display: "none" }}>

              {isOpenhh ? (
                <AiOutlineDoubleLeft />
              ) : (
                <AiOutlineDoubleRight />
              )}
            </div>
          </div>
        </div>
        <div className='timer '>  <div>{

          Admin == "ROLE_ADMIN" && (

            idmenu.map((item) => {
              return (
                <>

                  <NavLink
                    to={item.path}
                    className={` ${location.pathname.startsWith(item.path) ? 'active link' : 'link'}, ${message.length == 0 ? ' shimmer  link' : ' link'}`}>
                    {item.name}
                  </NavLink>
                </>
              );



            })
          )


        }

          {

            Admin == "ROLE_RETAILER" && (

              Fordretailer.map((item) => {
                return (
                  <>

                    <NavLink
                      to={item.path}
                      className={` ${location.pathname.startsWith(item.path) ? 'active link' : 'link'}, ${message.length == 0 ? ' shimmer  link' : ' link'}`}>
                      {item.name}
                    </NavLink>
                  </>
                );}))
          }

          {

            Admin == "ROLE_SUPPLIER" && (

              Forsupplier.map((item) => {
                return (
                  <>

                    <NavLink
                      to={item.path}
                      className={` ${location.pathname.startsWith(item.path) ? 'active link' : 'link'}, ${message.length == 0 ? ' shimmer  link' : ' link'}`}>
                      {item.name}
                    </NavLink>
                  </>
                );})
            )
          }

          {

            Admin == "ROLE_DISTRIBUTOR" && (

              Fordis.map((item) => {
                return (
                  <>

                    <NavLink
                      to={item.path}
                      className={` ${location.pathname.startsWith(item.path) ? 'active link' : 'link'}, ${message.length == 0 ? ' shimmer  link' : ' link'}`}>
                      {item.name}
                    </NavLink>
                  </>
                );})
            )
          }
          {

            (Admin == "ROLE_RSM" || Admin == "ROLE_ASM" || Admin == "ROLE_ASE" || Admin == "ROLE_NSM") && (

              Forstaff.map((item) => {
                return (
                  <>

                    <NavLink
                      to={item.path}
                      className={` ${location.pathname.startsWith(item.path) ? 'active link' : 'link'}, ${message.length == 0 ? ' shimmer  link' : ' link'}`}>
                      {item.name}
                    </NavLink>
                  </>
                );



              })
            )


          }

        </div>

          <div className='container '>
            <div className='card yty' style={{ top: Admin == "ROLE_ADMIN" ? '74%' : '-150px' }}>    <p><p className='fontwhite edit fontweight ert'>7</p>

            </p>
              <div className='card-body'>
                <p className='FONT11 fontwhite'>Today Visits</p>
                <ul>
                  <li><button class="btn btn-primary thh"> <span class="add">Schedule</span>  </button></li>
                </ul>
                <div className='erer'>
                  <p className='green1'><span className='two'>2</span>:Completed</p>
                  <p className='red1'><span className='five'  >5</span>:Completed</p>
                </div>
              </div>
            </div>
          </div>
        </div>


        {

          (Admin == "ROLE_RSM" || Admin == "ROLE_ASM" || Admin == "ROLE_ASE" || Admin == "ROLE_NSM") && (
            idmenu.map((item, index) => {
              if (item.name == 'Secondary Order' || item.name == 'Primary Order' || item.name == "Plroduct" || item.name == "Retailer" || item.name == "Staff" || item.name == "Distributor") {
                return <NavLink to={item.path} className="link" activeclassName="active1" >
                  {item.name}
                </NavLink>
              } else {
                console.log("sorry not working")
              }

            })
          )


        }
        {Admin == "ROLE_DISTRIBUTOR" && (
          idmenu.map((item, index) => {
            if (item.name == 'Secondary Order' || item.name == 'Primary Order' || item.name == "Distributor Invoice" || item.name == "Retailer") {
              return <NavLink to={item.path} key={index} className="link" activeclassName="active1" >

                {item.name}
              </NavLink>
            } else {
              console.log("sorry not working")
            }

          })
        )}
        {Admin == "ROLE_RETAILER" && (
          idmenu.map((item, index) => {
            if (item.name == 'Secondary Order' || item.name == "0Product") {
              return <NavLink to={item.path} key={index} className="link" activeclassName="active1" >


              </NavLink>
            } else {
              console.log("sorry not working")
            }

          })
        )}




      </div>
      <div className='children' id='new' style={{ width: isOpenhh ? 'calc(100% - 200px)' : '100%', marginLeft: isOpenhh ? '253px' : 'calc(100% - 97%)', display: isOpen ? "block" : "block", padding: '20px 30px', marginTop: '4%' }} data-aos="fade">{children}</div>

    </div>
  )
}
