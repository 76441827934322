import { DELIVERYCHALLAN_PUT_DATA_TO_LOAD } from "../constant";
import { PUT_NEW_DELIVERYCHALLAN_ADD_TO_CART } from "../constant";
import { DELIVERYCHALLAN_SQPB_MEASUREMENT } from "../constant";
import { CHANGE_DELIVERYCHALLAN_MEASUREMENT } from "../constant";
import { DELIVERYCHALLAN_CHANGE_CAL_MEASUREMENT } from "../constant";
import { REMOVE_FROM_CART_FROM_DELIVERYCHALLAN } from "../constant";
import { CHANGE_COST_DELIVERYCHALLAN } from "../constant";
import { CHANGE_DELIVERYCHALLAN_DISCOUNT } from "../constant";
import { CHANGE_DELIVERYCHALLAN_TEXT } from "../constant";
import { EMPTY_DELIVERYCHALLAN_DATA } from "../constant";
import { DELIVERYCHALLAN_SQPBKG_MEASUREMENT } from "../constant";


export const deliverychallandata = (data = [], action) => {

    switch (action.type) {
        case DELIVERYCHALLAN_PUT_DATA_TO_LOAD:

            // console.log("mello", action.data)
            let getcurrentdata = [];
            (action.data).map(user => {
                let data = {
                    soid: action.data2,
                    dlp: user.dlp,
                    grossamount: user.grossamount,
                    igst: user.igst,
                    product: { "id": user.product.id },
                    productName: user.productName,
                    productType: user.productType,
                    salesorderitemId: user.salesorderitemId,
                    schemeDiscount: user.schemeDiscount,
                    tradediscount: user.tradediscount,
                    dcmeasurement: user.someasurement,
                    dcquantity: user.soqty,
                    dcquantity_placed: user.someasurement,
                    dcquantity_placed_kg: user.soqty,
                    total: user.total,
                    unitofmeasurement: user.unitofmeasurement,
                    calunitofmeasurement: user.calunitofmeasurement,
                    uomPrimary: user.uomPrimary,
                    uomSecondary: user.uomSecondary,
                    standardQtyPerBox: user.standardQtyPerBox,
                    gstvalue: user.gstvalue,
                    netAmount: user.netAmount,
                    soquantity_placed: user.someasurement,
                    dcitemspending: user.dcitemspending,
                    dcitemsplaced: user.dcitemsplaced,
                    dcstatus: user.dcstatus,



                    // mrp:user.mrp,
                    // amount:user.amount,
                    // cgst:user.cgst,
                    // discount:user.discount,
                    // gstvalue:user.gstvalue,
                    // dcquantity:user.dcquantity,
                    // someasurement:user.measurement,
                    // product:user.product,
                    // sgst:user.sgst,
                    // total:user.total,

                }
                getcurrentdata.push(data);
            });


            return [...getcurrentdata];

        case PUT_NEW_DELIVERYCHALLAN_ADD_TO_CART:
            let newData3 = action.data2;
            let dataNew = action.data;

            function discountData1() {
                if (dataNew.productType == "boxproduct") {
                    return ({ "ProductDiscount": newData3.boxProductDiscount, "schemeDiscount": newData3.schemeboxProductDiscount });
                }
                else if (dataNew.productType == "kgproduct") {
                    return ({ "ProductDiscount": newData3.kgProductDiscount, "schemeDiscount": newData3.schemekgProductDiscount });
                }
                else if (dataNew.productType == "corporateproduct") {
                    return ({ "ProductDiscount": newData3.corporaetProductDiscount, "schemeDiscount": newData3.schemecorporateProductDiscount });
                }
                else if (dataNew.productType == "cookerproduct") {
                    return ({ "ProductDiscount": newData3.cookerProductDiscount, "schemeDiscount": newData3.schemecookerProductDiscount });
                }
                else if (dataNew.productType == "noshproduct") {
                    return ({ "ProductDiscount": newData3.noshProductDiscount, "schemeDiscount": newData3.schemenoshProductDisocunt });
                }
            }

            let aa = discountData1();

            let currentData = {
                // soid: action.data.id,
                brand: action.data.brand,
                capacity: action.data.capacity,
                category: action.data.category,
                cgst: action.data.cgst,
                cgstLedger: action.data.cgstLedger,
                diameter: action.data.diameter,
                eanCode: action.data.eanCode,
                hsnCode: action.data.hsnCode,
                igstLedger: action.data.igstLedger,
                dlp: action.data.dlp,
                standardQtyPerBox: action.data.standardQtyPerBox,
                sgst: action.data.sgst,
                sgstLedger: action.data.sgstLedger,
                shortName: action.data.shortName,
                uomPrimary: action.data.uomPrimary,
                uomSecondary: action.data.uomSecondary,
                tradediscount: aa.ProductDiscount,
                schemeDiscount: aa.schemeDiscount,
                product: { "id": action.data.id },
                igst: action.data.igst,
                mrp: action.data.mrp,
                productName: action.data.productName,
                productType: action.data.productType,
                unitofmeasurement: action.data.unitofmeasurement == undefined ? action.data.productType !== "kgproduct" ? "box" : '' : action.data.unitofmeasurement,
                calunitofmeasurement: action.data.calunitofmeasurement == undefined ? action.data.productType !== "kgproduct" ? "box" : '' : action.data.calunitofmeasurement
            }

            return [currentData, ...data];

        case CHANGE_DELIVERYCHALLAN_MEASUREMENT:

            let newCart6 = [...data]
            let itemIndex6 = newCart6.findIndex(obj => obj.product.id === action.item.product.id)
            let currItem6 = data[itemIndex6]
            currItem6.unitofmeasurement = action.data
            data[itemIndex6] = currItem6

            return [...data];

        case DELIVERYCHALLAN_CHANGE_CAL_MEASUREMENT:
            let newCart4 = [...data]
            let itemIndex4 = newCart4.findIndex(obj => obj.product.id === action.item.product.id)
            let currItem4 = data[itemIndex4]

            currItem4.calunitofmeasurement = action.data

            if (currItem4.calunitofmeasurement === "pcs") {
                if (currItem4.tradediscount > 0 || currItem4.schemeDiscount > 0) {

                    let discount1 = Number(currItem4.dcquantity_placed) * Number(currItem4.dlp) * (Number(currItem4.tradediscount) / 100)
                    let totaAmount = (Number(currItem4.dcquantity_placed) * Number(currItem4.dlp)) - Number(discount1);
                    currItem4.grossamount = (Number(totaAmount)).toFixed(2);
                    let schemediscount = ((Number(totaAmount) * Number(currItem4.schemeDiscount)) / 100);
                    let NetAmount = (Number(totaAmount) - Number(schemediscount));
                    currItem4.netAmount = (Number(NetAmount)).toFixed(2);
                    currItem4.gstvalue = ((Number(currItem4.netAmount) / 100) * Number(currItem4.igst)).toFixed(2);
                    currItem4.total = (Number(currItem4.gstvalue) + Number(currItem4.netAmount)).toFixed(2);
                    data[itemIndex4] = currItem4

                } else {
                    currItem4.grossamount = (Number(currItem4.dcquantity_placed) * Number(currItem4.dlp))
                    currItem4.netAmount = (Number(currItem4.grossamount)).toFixed(2);
                    currItem4.gstvalue = ((Number(currItem4.netAmount) / 100) * Number(currItem4.igst)).toFixed(2);
                    currItem4.total = ((Number(currItem4.gstvalue)) + (Number(currItem4.netAmount))).toFixed(2);
                    data[itemIndex4] = currItem4
                }
            }
            if (currItem4.calunitofmeasurement === "kg") {
                if (currItem4.tradediscount > 0 || currItem4.schemeDiscount > 0) {
                    let discount1 = Number(currItem4.dcquantity_placed_kg) * Number(currItem4.dlp) * (Number(currItem4.tradediscount) / 100)
                    let totaAmount = (Number(currItem4.dcquantity_placed_kg) * Number(currItem4.dlp)) - Number(discount1);
                    currItem4.grossamount = (Number(totaAmount)).toFixed(2);
                    let schemediscount = ((Number(totaAmount) * Number(currItem4.schemeDiscount)) / 100);
                    let NetAmount = (Number(totaAmount) - Number(schemediscount));
                    currItem4.netAmount = (Number(NetAmount)).toFixed(2);
                    currItem4.gstvalue = ((Number(currItem4.netAmount) / 100) * Number(currItem4.igst)).toFixed(2);
                    currItem4.total = (Number(currItem4.gstvalue) + Number(currItem4.netAmount)).toFixed(2);
                    data[itemIndex4] = currItem4

                } else {
                    currItem4.grossamount = (Number(currItem4.dcquantity_placed_kg) * Number(currItem4.dlp)).toFixed(2);
                    currItem4.netAmount = (Number(currItem4.grossamount)).toFixed(2);
                    currItem4.gstvalue = ((Number(currItem4.netAmount) / 100) * Number(currItem4.igst)).toFixed(2);
                    currItem4.total = ((Number(currItem4.gstvalue)) + (Number(currItem4.netAmount))).toFixed(2);
                    data[itemIndex4] = currItem4
                }
            }
            return [...data];

        case DELIVERYCHALLAN_SQPB_MEASUREMENT:

            let newCart = [...data]
            let itemIndex = newCart.findIndex(obj => obj.product.id === action.item.product.id)
            let currItem = data[itemIndex]

            if (currItem.unitofmeasurement == "box") {
                if (currItem.tradediscount > 0 || currItem.schemeDiscount > 0) {
                    if (action.data === "") {
                        currItem.dcquantity_placed = ""
                    } else {
                        currItem.dcquantity_placed = Number(action.data)
                    }

                    if (currItem.soquantity_placed > action.data) {
                        currItem.dcitemspending = Number(currItem.soquantity_placed) - Number(action.data)
                        currItem.dcitemsplaced = Number(action.data)
                        currItem.dcstatus = "partial"
                    }
                    else {
                        currItem.dcitemspending = "0"
                        currItem.dcitemsplaced = Number(action.data)
                        currItem.dcstatus = "completed"
                    }

                    let discount2 = Number(currItem.dcquantity_placed) * Number(currItem.dlp) * (Number(currItem.tradediscount) / 100);
                    let totaAmount = (Number(currItem.dcquantity_placed) * Number(currItem.dlp)) - Number(discount2);
                    currItem.grossamount = (Number(totaAmount)).toFixed(2);
                    let schemediscount = ((Number(totaAmount) * Number(currItem.schemeDiscount)) / 100);
                    let NetAmount = (Number(totaAmount) - Number(schemediscount));
                    currItem.netAmount = (Number(NetAmount)).toFixed(2);
                    currItem.gstvalue = ((Number(currItem.netAmount) / Number(100)) * (Number(currItem.igst))).toFixed(2);
                    currItem.total = (Number(currItem.gstvalue) + Number(currItem.netAmount)).toFixed(2);
                    data[itemIndex] = currItem


                } else {
                    if (action.data === "") {
                        currItem.dcquantity_placed = ""
                    } else {
                        currItem.dcquantity_placed = Number(action.data)
                    }

                    if (currItem.soquantity_placed > action.data) {
                        currItem.dcitemspending = Number(currItem.soquantity_placed) - Number(action.data)
                        currItem.dcitemsplaced = Number(action.data)
                        currItem.dcstatus = "partial"
                    }
                    else {
                        currItem.dcitemspending = "0"
                        currItem.dcitemsplaced = Number(action.data)
                        currItem.dcstatus = "completed"
                    }
                    currItem.tradediscount = Number(0);
                    currItem.grossamount = (Number(currItem.dcquantity_placed) * Number(currItem.dlp)).toFixed(2);
                    let NetAmount = (Number(currItem.dcquantity_placed) * Number(currItem.dlp));
                    currItem.netAmount = (Number(NetAmount)).toFixed(2);
                    currItem.gstvalue = ((Number(currItem.netAmount) / Number(100)) * (Number(currItem.igst))).toFixed(2)
                    currItem.total = (Number(currItem.gstvalue) + Number(currItem.netAmount)).toFixed(2);
                    data[itemIndex] = currItem
                }
            }

            if (currItem.unitofmeasurement === "pcs") {

                if (action.data === "") {
                    currItem.dcquantity_placed = "";
                    currItem.dcquantity_placed_kg = "";
                } else {
                    currItem.dcquantity_placed = Number(action.data)
                    let aa = Number(currItem.dcquantity_placed)
                    currItem.dcquantity_placed_kg = (Number(aa) * Number(currItem.uomSecondary)).toFixed(3);

                }
            }

            if (currItem.unitofmeasurement === "kg") {

                if (action.data === "") {
                    currItem.dcquantity_placed = "";

                } else {
                    currItem.dcquantity_placed = Number(action.data)

                }
            }

            if (currItem.calunitofmeasurement === "pcs") {
                if (currItem.unitofmeasurement === 'pcs') {
                    if (currItem.tradediscount > 0 || currItem.schemeDiscount > 0) {
                        if (action.data === "") {
                            currItem.dcquantity_placed = "";
                            currItem.dcquantity_placed_kg = '';
                        } else {
                            currItem.dcquantity_placed = Number(action.data)
                            let aa = Number(currItem.dcquantity_placed)
                            currItem.dcquantity_placed_kg = (Number(aa) * Number(currItem.uomSecondary)).toFixed(3);
                        }
                        /* So Pending */
                        if (currItem.soquantity_placed > action.data) {
                            currItem.dcitemspending = Number(currItem.soquantity_placed) - Number(action.data)
                            currItem.dcitemsplaced = Number(action.data)
                            currItem.dcstatus = "partial"
                        }
                        else {
                            currItem.dcitemspending = "0"
                            currItem.dcitemsplaced = Number(action.data)
                            currItem.dcstatus = "completed"
                        }

                        let discount2 = Number(currItem.dcquantity_placed) * Number(currItem.dlp) * (Number(currItem.tradediscount) / 100);
                        let totaAmount = (Number(currItem.dcquantity_placed) * Number(currItem.dlp)) - Number(discount2);
                        currItem.grossamount = (Number(totaAmount)).toFixed(2);
                        let schemediscount = ((Number(totaAmount) * Number(currItem.schemeDiscount)) / 100)
                        let NetAmount = (Number(totaAmount) - Number(schemediscount))
                        currItem.netAmount = (Number(NetAmount)).toFixed(2);
                        currItem.gstvalue = ((Number(currItem.netAmount) / Number(100)) * (Number(currItem.igst))).toFixed(2);
                        currItem.total = (Number(currItem.gstvalue) + Number(currItem.netAmount)).toFixed(2);
                        data[itemIndex] = currItem

                    } else {
                        if (action.data === "") {
                            currItem.dcquantity_placed = "";
                            currItem.dcquantity_placed_kg = '';
                        } else {
                            currItem.dcquantity_placed = Number(action.data)
                            let aa = Number(currItem.dcquantity_placed)
                            currItem.dcquantity_placed_kg = (Number(aa) * Number(currItem.uomSecondary)).toFixed(3);
                        }
                        /* So Pending */
                        if (currItem.soquantity_placed > action.data) {
                            currItem.dcitemspending = Number(currItem.soquantity_placed) - Number(action.data)
                            currItem.dcitemsplaced = Number(action.data)
                            currItem.dcstatus = "partial"
                        }
                        else {
                            currItem.dcitemspending = "0"
                            currItem.dcitemsplaced = Number(action.data)
                            currItem.dcstatus = "completed"
                        }

                        currItem.tradediscount = Number(0)
                        currItem.grossamount = (Number(currItem.dcquantity_placed) * Number(currItem.dlp)).toFixed(2);
                        let NetAmount = (Number(currItem.dcquantity_placed) * Number(currItem.dlp))
                        currItem.netAmount = (Number(NetAmount)).toFixed(2);
                        currItem.gstvalue = ((Number(currItem.netAmount) / Number(100)) * (Number(currItem.igst))).toFixed(2)
                        currItem.total = (Number(currItem.gstvalue) + Number(currItem.netAmount)).toFixed(2)
                        data[itemIndex] = currItem

                    }
                } else if (currItem.unitofmeasurement === 'kg') {
                    if (currItem.tradediscount > 0 || currItem.schemeDiscount > 0) {
                        if (action.data === "") {
                            currItem.dcquantity_placed = "";

                        } else {
                            currItem.dcquantity_placed = Number(action.data)

                        }
                        /* So Pending */
                        if (currItem.soquantity_placed > action.data) {
                            currItem.dcitemspending = Number(currItem.soquantity_placed) - Number(action.data)
                            currItem.dcitemsplaced = Number(action.data)
                            currItem.dcstatus = "partial"
                        }
                        else {
                            currItem.dcitemspending = "0"
                            currItem.dcitemsplaced = Number(action.data)
                            currItem.dcstatus = "completed"
                        }

                        let discount2 = Number(currItem.dcquantity_placed) * Number(currItem.dlp) * (Number(currItem.tradediscount) / 100);
                        let totaAmount = (Number(currItem.dcquantity_placed) * Number(currItem.dlp)) - Number(discount2);
                        currItem.grossamount = (Number(totaAmount)).toFixed(2);
                        let schemediscount = ((Number(totaAmount) * Number(currItem.schemeDiscount)) / 100)
                        let NetAmount = (Number(totaAmount) - Number(schemediscount))
                        currItem.netAmount = (Number(NetAmount)).toFixed(2);
                        currItem.gstvalue = ((Number(currItem.netAmount) / Number(100)) * (Number(currItem.igst))).toFixed(2);
                        currItem.total = (Number(currItem.gstvalue) + Number(currItem.netAmount)).toFixed(2);
                        data[itemIndex] = currItem

                    } else {
                        if (action.data === "") {
                            currItem.dcquantity_placed = "";
                            currItem.dcquantity_placed_kg = '';
                        } else {
                            currItem.dcquantity_placed = Number(action.data)

                        }
                        /* So Pending */
                        if (currItem.soquantity_placed > action.data) {
                            currItem.dcitemspending = Number(currItem.soquantity_placed) - Number(action.data)
                            currItem.dcitemsplaced = Number(action.data)
                            currItem.dcstatus = "partial"
                        }
                        else {
                            currItem.dcitemspending = "0"
                            currItem.dcitemsplaced = Number(action.data)
                            currItem.dcstatus = "completed"
                        }

                        currItem.tradediscount = Number(0)
                        currItem.grossamount = (Number(currItem.dcquantity_placed) * Number(currItem.dlp)).toFixed(2);
                        let NetAmount = (Number(currItem.dcquantity_placed) * Number(currItem.dlp))
                        currItem.netAmount = (Number(NetAmount)).toFixed(2);
                        currItem.gstvalue = ((Number(currItem.netAmount) / Number(100)) * (Number(currItem.igst))).toFixed(2)
                        currItem.total = (Number(currItem.gstvalue) + Number(currItem.netAmount)).toFixed(2)
                        data[itemIndex] = currItem

                    }
                }
            }
            if (currItem.calunitofmeasurement === "kg") {
                if (currItem.unitofmeasurement === 'pcs') {
                    if (currItem.tradediscount > 0 || currItem.schemeDiscount > 0) {
                        if (action.data === "") {
                            currItem.dcquantity_placed = "";
                            currItem.dcquantity_placed_kg = '';
                        } else {
                            currItem.dcquantity_placed = Number(action.data)
                            let aa = Number(currItem.dcquantity_placed)
                            currItem.dcquantity_placed_kg = (Number(aa) * Number(currItem.uomSecondary)).toFixed(3);
                        }
                        /* So Pending */
                        if (currItem.soquantity_placed > action.data) {
                            currItem.dcitemspending = Number(currItem.soquantity_placed) - Number(action.data)
                            currItem.dcitemsplaced = Number(action.data)
                            currItem.dcstatus = "partial"
                        }
                        else {
                            currItem.dcitemspending = "0"
                            currItem.dcitemsplaced = Number(action.data)
                            currItem.dcstatus = "completed"
                        }

                        let discount2 = Number(currItem.dcquantity_placed_kg) * Number(currItem.dlp) * (Number(currItem.tradediscount) / 100);
                        let totaAmount = (Number(currItem.dcquantity_placed_kg) * Number(currItem.dlp)) - Number(discount2);
                        currItem.grossamount = (Number(totaAmount)).toFixed(2);
                        let schemediscount = ((Number(totaAmount) * Number(currItem.schemeDiscount)) / 100)
                        let NetAmount = (Number(totaAmount) - Number(schemediscount))
                        currItem.netAmount = (Number(NetAmount)).toFixed(2);
                        currItem.gstvalue = ((Number(currItem.netAmount) / Number(100)) * (Number(currItem.igst))).toFixed(2);
                        currItem.total = (Number(currItem.gstvalue) + Number(currItem.netAmount)).toFixed(2);
                        data[itemIndex] = currItem

                    } else {
                        if (action.data === "") {
                            currItem.dcquantity_placed = "";
                            currItem.dcquantity_placed_kg = '';
                        } else {
                            currItem.dcquantity_placed = Number(action.data)
                            let aa = Number(currItem.dcquantity_placed)
                            currItem.dcquantity_placed_kg = (Number(aa) * Number(currItem.uomSecondary)).toFixed(3);
                        }
                        /* So Pending */
                        if (currItem.soquantity_placed > action.data) {
                            currItem.dcitemspending = Number(currItem.soquantity_placed) - Number(action.data)
                            currItem.dcitemsplaced = Number(action.data)
                            currItem.dcstatus = "partial"
                        }
                        else {
                            currItem.dcitemspending = "0"
                            currItem.dcitemsplaced = Number(action.data)
                            currItem.dcstatus = "completed"
                        }

                        currItem.tradediscount = Number(0)
                        currItem.grossamount = (Number(currItem.dcquantity_placed_kg) * Number(currItem.dlp)).toFixed(2);
                        let NetAmount = (Number(currItem.dcquantity_placed_kg) * Number(currItem.dlp))
                        currItem.netAmount = (Number(NetAmount)).toFixed(2);
                        currItem.gstvalue = ((Number(currItem.netAmount) / Number(100)) * (Number(currItem.igst))).toFixed(2)
                        currItem.total = (Number(currItem.gstvalue) + Number(currItem.netAmount)).toFixed(2)
                        data[itemIndex] = currItem

                    }
                } else if (currItem.unitofmeasurement === 'kg') {
                    if (currItem.tradediscount > 0 || currItem.schemeDiscount > 0) {
                        if (action.data === "") {
                            currItem.dcquantity_placed = "";

                        } else {
                            currItem.dcquantity_placed = Number(action.data)

                        }
                        /* So Pending */
                        if (currItem.soquantity_placed > action.data) {
                            currItem.dcitemspending = Number(currItem.soquantity_placed) - Number(action.data)
                            currItem.dcitemsplaced = Number(action.data)
                            currItem.dcstatus = "partial"
                        }
                        else {
                            currItem.dcitemspending = "0"
                            currItem.dcitemsplaced = Number(action.data)
                            currItem.dcstatus = "completed"
                        }

                        let discount2 = Number(currItem.dcquantity_placed_kg) * Number(currItem.dlp) * (Number(currItem.tradediscount) / 100);
                        let totaAmount = (Number(currItem.dcquantity_placed_kg) * Number(currItem.dlp)) - Number(discount2);
                        currItem.grossamount = (Number(totaAmount)).toFixed(2);
                        let schemediscount = ((Number(totaAmount) * Number(currItem.schemeDiscount)) / 100)
                        let NetAmount = (Number(totaAmount) - Number(schemediscount))
                        currItem.netAmount = (Number(NetAmount)).toFixed(2);
                        currItem.gstvalue = ((Number(currItem.netAmount) / Number(100)) * (Number(currItem.igst))).toFixed(2);
                        currItem.total = (Number(currItem.gstvalue) + Number(currItem.netAmount)).toFixed(2);
                        data[itemIndex] = currItem

                    } else {
                        if (action.data === "") {
                            currItem.dcquantity_placed = "";
                            currItem.dcquantity_placed_kg = '';
                        } else {
                            currItem.dcquantity_placed = Number(action.data)

                        }
                        /* So Pending */
                        if (currItem.soquantity_placed > action.data) {
                            currItem.dcitemspending = Number(currItem.soquantity_placed) - Number(action.data)
                            currItem.dcitemsplaced = Number(action.data)
                            currItem.dcstatus = "partial"
                        }
                        else {
                            currItem.dcitemspending = "0"
                            currItem.dcitemsplaced = Number(action.data)
                            currItem.dcstatus = "completed"
                        }

                        currItem.tradediscount = Number(0)
                        currItem.grossamount = (Number(currItem.dcquantity_placed_kg) * Number(currItem.dlp)).toFixed(2);
                        let NetAmount = (Number(currItem.dcquantity_placed_kg) * Number(currItem.dlp))
                        currItem.netAmount = (Number(NetAmount)).toFixed(2);
                        currItem.gstvalue = ((Number(currItem.netAmount) / Number(100)) * (Number(currItem.igst))).toFixed(2)
                        currItem.total = (Number(currItem.gstvalue) + Number(currItem.netAmount)).toFixed(2)
                        data[itemIndex] = currItem

                    }
                }
            }
            return [...data];

        case DELIVERYCHALLAN_SQPBKG_MEASUREMENT:
            let newCart7 = [...data]
            let itemIndex7 = newCart7.findIndex(obj => obj.product.id === action.item.product.id)
            let currItem7 = data[itemIndex7]

            if (currItem7.unitofmeasurement === "kg") {
                if (action.data === "") {
                    currItem7.dcquantity_placed = "";
                    currItem7.dcquantity_placed_kg = "";
                } else {
                    currItem7.dcquantity_placed_kg = Number(action.data)
                    let aa = Number(currItem7.dcquantity_placed_kg)
                    let ab = (Number(aa) / Number(currItem7.uomSecondary))
                    currItem7.dcquantity_placed = Math.round(ab);
                }
            }

            if (currItem7.unitofmeasurement === "pcs") {
                if (action.data === "") {
                    currItem7.dcquantity_placed_kg = "";
                } else {
                    currItem7.dcquantity_placed_kg = Number(action.data)
                }
            }


            if (currItem7.calunitofmeasurement === "kg") {
                if (currItem7.unitofmeasurement === 'kg') {
                    if (currItem7.tradediscount > 0 || currItem7.schemeDiscount > 0) {
                        if (action.data === "") {
                            currItem7.dcquantity_placed_kg = "";
                        } else {
                            currItem7.dcquantity_placed_kg = Number(action.data)
                            let aa = Number(currItem7.dcquantity_placed_kg)
                            let ab = (Number(aa) / Number(currItem7.uomSecondary))
                            currItem7.dcquantity_placed = Math.round(ab);
                        }
                        /* So Pending */
                        if (currItem7.soquantity_placed > currItem7.dcquantity_placed) {
                            currItem7.dcitemspending = Number(currItem7.soquantity_placed) - Number(currItem7.dcquantity_placed)
                            currItem7.dcitemsplaced = Number(currItem7.dcquantity_placed)
                            currItem7.dcstatus = "partial"
                        }
                        else {
                            currItem7.dcitemspending = "0"
                            currItem7.dcitemsplaced = Number(currItem7.dcquantity_placed)
                            currItem7.dcstatus = "completed"
                        }

                        let discount2 = Number(currItem7.dcquantity_placed_kg) * Number(currItem7.dlp) * (Number(currItem7.tradediscount) / 100);
                        let totaAmount = Number(currItem7.dcquantity_placed_kg) * Number(currItem7.dlp) - Number(discount2);
                        currItem7.grossamount = (Number(totaAmount)).toFixed(2);
                        let schemediscount = ((Number(totaAmount) * Number(currItem7.schemeDiscount)) / 100)
                        let NetAmount = (Number(totaAmount) - Number(schemediscount))
                        currItem7.netAmount = (Number(NetAmount)).toFixed(2);
                        currItem7.gstvalue = ((Number(currItem7.netAmount) / Number(100)) * (Number(currItem7.igst))).toFixed(2);
                        currItem7.total = (Number(currItem7.gstvalue) + Number(currItem7.netAmount)).toFixed(2);
                        data[itemIndex7] = currItem7
                    } else {
                        if (action.data === "") {
                            currItem7.dcquantity_placed_kg = "";
                        } else {
                            currItem7.dcquantity_placed_kg = Number(action.data)
                            let aa = Number(currItem7.dcquantity_placed_kg)
                            let ab = (Number(aa) / Number(currItem7.uomSecondary))
                            currItem7.dcquantity_placed = Math.round(ab);
                        }
                        /* So Pending */
                        if (currItem7.soquantity_placed > currItem7.dcquantity_placed) {
                            currItem7.dcitemspending = Number(currItem7.soquantity_placed) - Number(currItem7.dcquantity_placed)
                            currItem7.dcitemsplaced = Number(currItem7.dcquantity_placed)
                            currItem7.dcstatus = "partial"
                        }
                        else {
                            currItem7.dcitemspending = "0"
                            currItem7.dcitemsplaced = Number(currItem7.dcquantity_placed)
                            currItem7.dcstatus = "completed"
                        }
                        currItem7.grossamount = (Number(currItem7.dcquantity_placed_kg) * Number(currItem7.dlp))
                        currItem7.netAmount = (Number(currItem7.grossamount)).toFixed(2);
                        currItem7.gstvalue = ((Number(currItem7.netAmount) / 100) * Number(currItem7.igst)).toFixed(2);
                        currItem7.total = ((Number(currItem7.gstvalue)) + (Number(currItem7.netAmount))).toFixed(2);
                        data[itemIndex7] = currItem7
                    }
                } else if (currItem7.unitofmeasurement === 'pcs') {
                    if (currItem7.tradediscount > 0 || currItem7.schemeDiscount > 0) {
                        if (action.data === "") {
                            currItem7.dcquantity_placed_kg = "";
                        } else {
                            currItem7.dcquantity_placed_kg = Number(action.data)

                        }
                        /* So Pending */
                        if (currItem7.soquantity_placed > currItem7.dcquantity_placed) {
                            currItem7.dcitemspending = Number(currItem7.soquantity_placed) - Number(currItem7.dcquantity_placed)
                            currItem7.dcitemsplaced = Number(currItem7.dcquantity_placed)
                            currItem7.dcstatus = "partial"
                        }
                        else {
                            currItem7.dcitemspending = "0"
                            currItem7.dcitemsplaced = Number(currItem7.dcquantity_placed)
                            currItem7.dcstatus = "completed"
                        }

                        let discount2 = Number(currItem7.dcquantity_placed_kg) * Number(currItem7.dlp) * (Number(currItem7.tradediscount) / 100);
                        let totaAmount = Number(currItem7.dcquantity_placed_kg) * Number(currItem7.dlp) - Number(discount2);
                        currItem7.grossamount = (Number(totaAmount)).toFixed(2);
                        let schemediscount = ((Number(totaAmount) * Number(currItem7.schemeDiscount)) / 100)
                        let NetAmount = (Number(totaAmount) - Number(schemediscount))
                        currItem7.netAmount = (Number(NetAmount)).toFixed(2);
                        currItem7.gstvalue = ((Number(currItem7.netAmount) / Number(100)) * (Number(currItem7.igst))).toFixed(2);
                        currItem7.total = (Number(currItem7.gstvalue) + Number(currItem7.netAmount)).toFixed(2);
                        data[itemIndex7] = currItem7
                    } else {
                        if (action.data === "") {
                            currItem7.dcquantity_placed_kg = "";
                        } else {
                            currItem7.dcquantity_placed_kg = Number(action.data)

                        }
                        if (currItem7.soquantity_placed > currItem7.dcquantity_placed) {
                            currItem7.dcitemspending = Number(currItem7.soquantity_placed) - Number(currItem7.dcquantity_placed)
                            currItem7.dcitemsplaced = Number(currItem7.dcquantity_placed)
                            currItem7.dcstatus = "partial"
                        }
                        else {
                            currItem7.dcitemspending = "0"
                            currItem7.dcitemsplaced = Number(currItem7.dcquantity_placed)
                            currItem7.dcstatus = "completed"
                        }
                        currItem7.grossamount = (Number(currItem7.dcquantity_placed_kg) * Number(currItem7.dlp))
                        currItem7.netAmount = (Number(currItem7.grossamount)).toFixed(2);
                        currItem7.gstvalue = ((Number(currItem7.netAmount) / 100) * Number(currItem7.igst)).toFixed(2);
                        currItem7.total = ((Number(currItem7.gstvalue)) + (Number(currItem7.netAmount))).toFixed(2);
                        data[itemIndex7] = currItem7
                    }
                }
            }

            if (currItem7.calunitofmeasurement === "pcs") {
                if (currItem7.unitofmeasurement === 'kg') {

                    if (currItem7.tradediscount > 0 || currItem7.schemeDiscount > 0) {
                        if (action.data === "") {
                            currItem7.dcquantity_placed_kg = "";
                        } else {
                            currItem7.dcquantity_placed_kg = Number(action.data)
                            let aa = Number(currItem7.dcquantity_placed_kg)
                            let ab = (Number(aa) / Number(currItem7.uomSecondary))
                            currItem7.dcquantity_placed = Math.round(ab);
                        }
                        if (currItem7.soquantity_placed > currItem7.dcquantity_placed) {
                            currItem7.dcitemspending = Number(currItem7.soquantity_placed) - Number(currItem7.dcquantity_placed)
                            currItem7.dcitemsplaced = Number(currItem7.dcquantity_placed)
                            currItem7.dcstatus = "partial"
                        }
                        else {
                            currItem7.dcitemspending = "0"
                            currItem7.dcitemsplaced = Number(currItem7.dcquantity_placed)
                            currItem7.dcstatus = "completed"
                        }
                        let discount2 = Number(currItem7.dcquantity_placed) * Number(currItem7.dlp) * (Number(currItem7.tradediscount) / 100);
                        let totaAmount = Number(currItem7.dcquantity_placed) * Number(currItem7.dlp) - Number(discount2);
                        currItem7.grossamount = (Number(totaAmount)).toFixed(2);
                        let schemediscount = ((Number(totaAmount) * Number(currItem7.schemeDiscount)) / 100)
                        let NetAmount = (Number(totaAmount) - Number(schemediscount))
                        currItem7.netAmount = (Number(NetAmount)).toFixed(2);
                        currItem7.gstvalue = ((Number(currItem7.netAmount) / Number(100)) * (Number(currItem7.igst))).toFixed(2);
                        currItem7.total = (Number(currItem7.gstvalue) + Number(currItem7.netAmount)).toFixed(2);
                        data[itemIndex7] = currItem7
                    } else {
                        if (action.data === "") {
                            currItem7.dcquantity_placed_kg = "";
                        } else {
                            currItem7.dcquantity_placed_kg = Number(action.data)
                            let aa = Number(currItem7.dcquantity_placed_kg)
                            let ab = (Number(aa) / Number(currItem7.uomSecondary))
                            currItem7.dcquantity_placed = Math.round(ab);
                        }
                        if (currItem7.soquantity_placed > currItem7.dcquantity_placed) {
                            currItem7.dcitemspending = Number(currItem7.soquantity_placed) - Number(currItem7.dcquantity_placed)
                            currItem7.dcitemsplaced = Number(currItem7.dcquantity_placed)
                            currItem7.dcstatus = "partial"
                        }
                        else {
                            currItem7.dcitemspending = "0"
                            currItem7.dcitemsplaced = Number(currItem7.dcquantity_placed)
                            currItem7.dcstatus = "completed"
                        }
                        currItem7.grossamount = (Number(currItem7.dcquantity_placed) * Number(currItem7.dlp))
                        currItem7.netAmount = (Number(currItem7.grossamount)).toFixed(2);
                        currItem7.gstvalue = ((Number(currItem7.netAmount) / 100) * Number(currItem7.igst)).toFixed(2);
                        currItem7.total = ((Number(currItem7.gstvalue)) + (Number(currItem7.netAmount))).toFixed(2);
                        data[itemIndex7] = currItem7
                    }

                } else if (currItem7.unitofmeasurement === 'pcs') {

                    if (currItem7.tradediscount > 0 || currItem7.schemeDiscount > 0) {
                        if (action.data === "") {
                            currItem7.dcquantity_placed_kg = "";
                        } else {
                            currItem7.dcquantity_placed_kg = Number(action.data)

                        }
                        if (currItem7.soquantity_placed > currItem7.dcquantity_placed) {
                            currItem7.dcitemspending = Number(currItem7.soquantity_placed) - Number(currItem7.dcquantity_placed)
                            currItem7.dcitemsplaced = Number(currItem7.dcquantity_placed)
                            currItem7.dcstatus = "partial"
                        }
                        else {
                            currItem7.dcitemspending = "0"
                            currItem7.dcitemsplaced = Number(currItem7.dcquantity_placed)
                            currItem7.dcstatus = "completed"
                        }
                        let discount2 = Number(currItem7.dcquantity_placed) * Number(currItem7.dlp) * (Number(currItem7.tradediscount) / 100);
                        let totaAmount = Number(currItem7.dcquantity_placed) * Number(currItem7.dlp) - Number(discount2);
                        currItem7.grossamount = (Number(totaAmount)).toFixed(2);
                        let schemediscount = ((Number(totaAmount) * Number(currItem7.schemeDiscount)) / 100)
                        let NetAmount = (Number(totaAmount) - Number(schemediscount))
                        currItem7.netAmount = (Number(NetAmount)).toFixed(2);
                        currItem7.gstvalue = ((Number(currItem7.netAmount) / Number(100)) * (Number(currItem7.igst))).toFixed(2);
                        currItem7.total = (Number(currItem7.gstvalue) + Number(currItem7.netAmount)).toFixed(2);
                        data[itemIndex7] = currItem7
                    } else {
                        if (action.data === "") {
                            currItem7.dcquantity_placed_kg = "";
                        } else {
                            currItem7.dcquantity_placed_kg = Number(action.data)

                        }
                        if (currItem7.soquantity_placed > currItem7.dcquantity_placed) {
                            currItem7.dcitemspending = Number(currItem7.soquantity_placed) - Number(currItem7.dcquantity_placed)
                            currItem7.dcitemsplaced = Number(currItem7.dcquantity_placed)
                            currItem7.dcstatus = "partial"
                        }
                        else {
                            currItem7.dcitemspending = "0"
                            currItem7.dcitemsplaced = Number(currItem7.dcquantity_placed)
                            currItem7.dcstatus = "completed"
                        }
                        currItem7.grossamount = (Number(currItem7.dcquantity_placed) * Number(currItem7.dlp))
                        currItem7.netAmount = (Number(currItem7.grossamount)).toFixed(2);
                        currItem7.gstvalue = ((Number(currItem7.netAmount) / 100) * Number(currItem7.igst)).toFixed(2);
                        currItem7.total = ((Number(currItem7.gstvalue)) + (Number(currItem7.netAmount))).toFixed(2);
                        data[itemIndex7] = currItem7
                    }

                }
            }

            return [...data];
        case REMOVE_FROM_CART_FROM_DELIVERYCHALLAN:

            const remainingitem = data.filter((item) => item.product.id !== action.data);
            return [...remainingitem];


        case CHANGE_COST_DELIVERYCHALLAN:

            let newCart1 = [...data]
            let itemIndex1 = newCart1.findIndex(obj => obj.product.id === action.item.product.id)
            let currItem1 = data[itemIndex1]

            currItem1.dlp = action.data
            if (currItem1.calunitofmeasurement === "box") {
                if (currItem1.tradediscount > 0 || currItem1.schemeDiscount > 0) {
                    if (action.data === "") {
                        currItem1.dlp = ""
                    } else {
                        currItem1.dlp = Number(action.data)
                    }

                    /* So Pending */
                    if (currItem1.soquantity_placed > currItem1.dcquantity_placed) {
                        currItem1.dcitemspending = Number(currItem1.soquantity_placed) - Number(currItem1.dcquantity_placed)
                        currItem1.dcitemsplaced = Number(currItem1.dcquantity_placed)
                        currItem1.dcstatus = "partial"
                    }
                    else {
                        currItem1.dcitemspending = "0"
                        currItem1.dcitemsplaced = Number(currItem1.dcquantity_placed)
                        currItem1.dcstatus = "completed"
                    }

                    let discount3 = Number(currItem1.dcquantity_placed) * Number(currItem1.dlp) * (Number(currItem1.tradediscount) / 100)
                    let totaAmount = Number(currItem1.dcquantity_placed) * Number(currItem1.dlp) - Number(discount3)
                    currItem1.grossamount = (Number(totaAmount)).toFixed(2);
                    let schemediscount = ((Number(totaAmount) * Number(currItem1.schemeDiscount)) / 100)
                    let NetAmount = (Number(totaAmount) - Number(schemediscount))
                    currItem1.netAmount = (Number(NetAmount)).toFixed(2);
                    // currItem1.NetAmount = parseInt(currItem1.measurement) * parseInt(currItem1.dlp)
                    currItem1.gstvalue = ((Number(currItem1.netAmount) / Number(100)) * (Number(currItem1.igst))).toFixed(2);
                    currItem1.total = (Number(currItem1.gstvalue) + Number(currItem1.netAmount)).toFixed(2);
                    data[itemIndex1] = currItem1
                } else {
                    if (action.data === "") {
                        currItem1.dlp = ""
                    } else {
                        currItem1.dlp = Number(action.data)
                    }

                    /* So Pending */
                    if (currItem1.soquantity_placed > currItem1.dcquantity_placed) {
                        currItem1.dcitemspending = Number(currItem1.soquantity_placed) - Number(currItem1.dcquantity_placed)
                        currItem1.dcitemsplaced = Number(currItem1.dcquantity_placed)
                        currItem1.dcstatus = "partial"
                    }
                    else {
                        currItem1.dcitemspending = "0"
                        currItem1.dcitemsplaced = Number(currItem1.dcquantity_placed)
                        currItem1.dcstatus = "completed"
                    }

                    currItem1.tradediscount = Number(0);
                    currItem1.grossamount = (Number(currItem1.dcquantity_placed) * Number(currItem1.dlp)).toFixed(2);
                    let NetAmount = (Number(currItem1.dcquantity_placed) * Number(currItem1.dlp));
                    currItem1.netAmount = (Number(NetAmount)).toFixed(2);
                    currItem1.gstvalue = ((Number(currItem1.netAmount) / Number(100)) * (Number(currItem1.igst))).toFixed(2);
                    currItem1.total = (Number(currItem1.gstvalue) + Number(currItem1.netAmount)).toFixed(2);
                    data[itemIndex1] = currItem1
                    return [...data];
                }
            }

            if (currItem1.calunitofmeasurement === 'pcs') {

                if (currItem1.tradediscount > 0 || currItem1.schemeDiscount > 0) {
                    if (action.data === "") {
                        currItem1.dlp = ""
                    } else {
                        currItem1.dlp = Number(action.data)
                    }

                    /* So Pending */
                    if (currItem1.soquantity_placed > currItem1.dcquantity_placed) {
                        currItem1.dcitemspending = Number(currItem1.soquantity_placed) - Number(currItem1.dcquantity_placed)
                        currItem1.dcitemsplaced = Number(currItem1.dcquantity_placed)
                        currItem1.dcstatus = "partial"
                    }
                    else {
                        currItem1.dcitemspending = "0"
                        currItem1.dcitemsplaced = Number(currItem1.dcquantity_placed)
                        currItem1.dcstatus = "completed"
                    }


                    let discount3 = Number(currItem1.dcquantity_placed) * Number(currItem1.dlp) * (Number(currItem1.tradediscount) / 100)
                    let totaAmount = Number(currItem1.dcquantity_placed) * Number(currItem1.dlp) - Number(discount3)
                    currItem1.grossamount = (Number(totaAmount)).toFixed(2);
                    let schemediscount = ((Number(totaAmount) * Number(currItem1.schemeDiscount)) / 100)
                    let NetAmount = (Number(totaAmount) - Number(schemediscount))
                    currItem1.netAmount = (Number(NetAmount)).toFixed(2);
                    // currItem1.NetAmount = parseInt(currItem1.measurement) * parseInt(currItem1.dlp)
                    currItem1.gstvalue = ((Number(currItem1.netAmount) / Number(100)) * (Number(currItem1.igst))).toFixed(2);
                    currItem1.total = (Number(currItem1.gstvalue) + Number(currItem1.netAmount)).toFixed(2);
                    data[itemIndex1] = currItem1
                } else {
                    if (action.data === "") {
                        currItem1.dlp = ""
                    } else {
                        currItem1.dlp = Number(action.data)
                    }

                    /* So Pending */
                    if (currItem1.soquantity_placed > currItem1.dcquantity_placed) {
                        currItem1.dcitemspending = Number(currItem1.soquantity_placed) - Number(currItem1.dcquantity_placed)
                        currItem1.dcitemsplaced = Number(currItem1.dcquantity_placed)
                        currItem1.dcstatus = "partial"
                    }
                    else {
                        currItem1.dcitemspending = "0"
                        currItem1.dcitemsplaced = Number(currItem1.dcquantity_placed)
                        currItem1.dcstatus = "completed"
                    }

                    currItem1.tradediscount = Number(0);
                    currItem1.grossamount = (Number(currItem1.dcquantity_placed) * Number(currItem1.dlp)).toFixed(2);
                    let NetAmount = (Number(currItem1.dcquantity_placed) * Number(currItem1.dlp));
                    currItem1.netAmount = (Number(NetAmount)).toFixed(2);
                    currItem1.gstvalue = ((Number(currItem1.netAmount) / Number(100)) * (Number(currItem1.igst))).toFixed(2);
                    currItem1.total = (Number(currItem1.gstvalue) + Number(currItem1.netAmount)).toFixed(2);
                    data[itemIndex1] = currItem1
                    return [...data];
                }
            }

            if (currItem1.calunitofmeasurement === 'kg') {
                if (currItem1.tradediscount > 0 || currItem1.schemeDiscount > 0) {
                    if (action.data === "") {
                        currItem1.dlp = ""
                    } else {
                        currItem1.dlp = Number(action.data)
                    }

                    /* So Pending */
                    if (currItem1.soquantity_placed > currItem1.dcquantity_placed) {
                        currItem1.dcitemspending = Number(currItem1.soquantity_placed) - Number(currItem1.dcquantity_placed)
                        currItem1.dcitemsplaced = Number(currItem1.dcquantity_placed)
                        currItem1.dcstatus = "partial"
                    }
                    else {
                        currItem1.dcitemspending = "0"
                        currItem1.dcitemsplaced = Number(currItem1.dcquantity_placed)
                        currItem1.dcstatus = "completed"
                    }

                    let discount3 = Number(currItem1.dcquantity_placed_kg) * Number(currItem1.dlp) * (Number(currItem1.tradediscount) / 100)
                    let totaAmount = (Number(currItem1.dcquantity_placed_kg) * Number(currItem1.dlp)) - Number(discount3)
                    currItem1.grossamount = (Number(totaAmount)).toFixed(2);
                    let schemediscount = ((Number(totaAmount) * Number(currItem1.schemeDiscount)) / 100)
                    let NetAmount = (Number(totaAmount) - Number(schemediscount))
                    currItem1.netAmount = (Number(NetAmount)).toFixed(2);
                    // currItem1.NetAmount = parseInt(currItem1.qty) * parseInt(currItem1.dlp)
                    currItem1.gstvalue = ((Number(currItem1.netAmount) / Number(100)) * (Number(currItem1.igst))).toFixed(2);
                    currItem1.total = (Number(currItem1.gstvalue) + Number(currItem1.netAmount)).toFixed(2);
                    data[itemIndex1] = currItem1
                    return [...data];
                } else {
                    if (action.data === "") {
                        currItem1.dlp = ""
                    } else {
                        currItem1.dlp = Number(action.data)
                    }

                    /* So Pending */
                    if (currItem1.soquantity_placed > currItem1.dcquantity_placed) {
                        currItem1.dcitemspending = Number(currItem1.soquantity_placed) - Number(currItem1.dcquantity_placed)
                        currItem1.dcitemsplaced = Number(currItem1.dcquantity_placed)
                        currItem1.dcstatus = "partial"
                    }
                    else {
                        currItem1.dcitemspending = "0"
                        currItem1.dcitemsplaced = Number(currItem1.dcquantity_placed)
                        currItem1.dcstatus = "completed"
                    }

                    currItem1.tradediscount = Number(0);
                    currItem1.grossamount = (Number(currItem1.dcquantity_placed_kg) * Number(currItem1.dlp)).toFixed(2);
                    let NetAmount = (Number(currItem1.dcquantity_placed_kg) * Number(currItem1.dlp))
                    currItem1.netAmount = (Number(NetAmount)).toFixed(2);
                    currItem1.gstvalue = ((Number(currItem1.netAmount) / Number(100)) * (Number(currItem1.igst))).toFixed(2);
                    currItem1.total = (Number(currItem1.gstvalue) + Number(currItem1.netAmount)).toFixed(2);
                    data[itemIndex1] = currItem1
                    return [...data];

                }
            }
            return [...data];
        case CHANGE_DELIVERYCHALLAN_DISCOUNT:

            let newCart2 = [...data]
            let itemIndex2 = newCart2.findIndex(obj => obj.product.id === action.item.product.id)
            let currItem2 = data[itemIndex2]


            if (currItem2.calunitofmeasurement === "box") {
                if (action.data == "") {
                    currItem2.schemeDiscount = 0
                } else {
                    currItem2.schemeDiscount = Number(action.data)
                }
                
                    /* So Pending */
                    if (currItem2.soquantity_placed > currItem2.dcquantity_placed) {
                        currItem2.dcitemspending = Number(currItem2.soquantity_placed) - Number(currItem2.dcquantity_placed)
                        currItem2.dcitemsplaced = Number(currItem2.dcquantity_placed)
                        currItem2.dcstatus = "partial"
                    }
                    else {
                        currItem2.dcitemspending = "0"
                        currItem2.dcitemsplaced = Number(currItem2.dcquantity_placed)
                        currItem2.dcstatus = "completed"
                    }

                    let discount1 = Number(currItem2.dcquantity_placed) * Number(currItem2.dlp) * (Number(currItem2.tradediscount) / 100);
                    let totaAmount = (Number(currItem2.dcquantity_placed) * Number(currItem2.dlp)) - Number(discount1)
                    currItem2.grossamount = (Number(totaAmount)).toFixed(2);
                    let schemediscount = ((Number(totaAmount) * Number(currItem2.schemeDiscount)) / 100)
                    let NetAmount = (Number(totaAmount) - Number(schemediscount))
                    currItem2.netAmount = (Number(NetAmount)).toFixed(2);
                    // currItem1.NetAmount = parseInt(currItem1.measurement) * parseInt(currItem1.dlp)
                    currItem2.gstvalue = ((Number(currItem2.netAmount) / Number(100)) * (Number(currItem2.igst))).toFixed(2);
                    currItem2.total = (Number(currItem2.gstvalue) + Number(currItem2.netAmount)).toFixed(2);
                    data[itemIndex2] = currItem2
            }
            if (currItem2.calunitofmeasurement == "pcs") {
                if (action.data == "") {
                    currItem2.schemeDiscount = 0
                } else {
                    currItem2.schemeDiscount = Number(action.data)
                }
                
                    /* So Pending */
                    if (currItem2.soquantity_placed > currItem2.dcquantity_placed) {
                        currItem2.dcitemspending = Number(currItem2.soquantity_placed) - Number(currItem2.dcquantity_placed)
                        currItem2.dcitemsplaced = Number(currItem2.dcquantity_placed)
                        currItem2.dcstatus = "partial"
                    }
                    else {
                        currItem2.dcitemspending = "0"
                        currItem2.dcitemsplaced = Number(currItem2.dcquantity_placed)
                        currItem2.dcstatus = "completed"
                    }
                    let discount1 = Number(currItem2.dcquantity_placed) * Number(currItem2.dlp) * (Number(currItem2.tradediscount) / 100);
                    let totaAmount = (Number(currItem2.dcquantity_placed) * Number(currItem2.dlp)) - Number(discount1)
                    currItem2.grossamount = (Number(totaAmount)).toFixed(2);
                    let schemediscount = ((Number(totaAmount) * Number(currItem2.schemeDiscount)) / 100)
                    let NetAmount = (Number(totaAmount) - Number(schemediscount))
                    currItem2.netAmount = (Number(NetAmount)).toFixed(2);
                    // currItem1.NetAmount = parseInt(currItem1.measurement) * parseInt(currItem1.dlp)
                    currItem2.gstvalue = ((Number(currItem2.netAmount) / Number(100)) * (Number(currItem2.igst))).toFixed(2);
                    currItem2.total = (Number(currItem2.gstvalue) + Number(currItem2.netAmount)).toFixed(2);
                    data[itemIndex2] = currItem2
            }

            if (currItem2.calunitofmeasurement == "kg") {
                if (action.data == "") {
                    currItem2.schemeDiscount = 0
                } else {
                    currItem2.schemeDiscount = Number(action.data)
                }
                
                    /* So Pending */
                    if (currItem2.soquantity_placed > currItem2.dcquantity_placed) {
                        currItem2.dcitemspending = Number(currItem2.soquantity_placed) - Number(currItem2.dcquantity_placed)
                        currItem2.dcitemsplaced = Number(currItem2.dcquantity_placed)
                        currItem2.dcstatus = "partial"
                    }
                    else {
                        currItem2.dcitemspending = "0"
                        currItem2.dcitemsplaced = Number(currItem2.dcquantity_placed)
                        currItem2.dcstatus = "completed"
                    }

                    let discount1 = Number(currItem2.dcquantity_placed_kg) * Number(currItem2.dlp) * (Number(currItem2.tradediscount) / 100);
                    let totaAmount = (Number(currItem2.dcquantity_placed_kg) * Number(currItem2.dlp)) - Number(discount1)
                    currItem2.grossamount = (Number(totaAmount)).toFixed(2);
                    let schemediscount = ((Number(totaAmount) * Number(currItem2.schemeDiscount)) / 100)
                    let NetAmount = (Number(totaAmount) - Number(schemediscount))
                    currItem2.netAmount = (Number(NetAmount)).toFixed(2);
                    // currItem1.NetAmount = parseInt(currItem1.measurement) * parseInt(currItem1.dlp)
                    currItem2.gstvalue = ((Number(currItem2.netAmount) / Number(100)) * (Number(currItem2.igst))).toFixed(2);
                    currItem2.total = (Number(currItem2.gstvalue) + Number(currItem2.netAmount)).toFixed(2);
                    data[itemIndex2] = currItem2

            }

            return [...data];


        case CHANGE_DELIVERYCHALLAN_TEXT:

            let newCart5 = [...data]
            let itemIndex5 = newCart5.findIndex(obj => obj.product.id === action.item.product.id)
            let currItem5 = data[itemIndex5]

            currItem5.text = action.data
            data[itemIndex5] = currItem5
            return [...data];


        case EMPTY_DELIVERYCHALLAN_DATA:
            // console.warn("reducer called",action)
            return [];



        default:
            return data;
    }


}
