import React, { useEffect, useState } from "react";
import WareHouseService from "../services/WareHouseService";
import { MininmumStockReportComponent } from "./MininmumStockReportComponent";
function MininmumStockReport() {
  const [warehousename, setWarehousename] = useState("");

  useEffect(() => {
    WareHouseService.getwarehouse().then((response) => {
      setWarehousename(
        response.data.map((user) => ({ value: user.id, label: user.name }))
      );
    });
  }, []);

  let header = [
    { label: "Date", field: "date" },
    { label: "Outward Qty", field: "outwardqty" },
    { label: "Total Sri qty", field: "totalpriqty" },
    { label: "Total Sri qty", field: "totalsriqty" },
    { label: "Total Dci qty ", field: "totaldciqty" },
    { label: " Ms Qty  ", field: "msqty" },
    { label: "Total Os Qty", field: "totalosqty" },
    { label: "Product Name", field: "product_name" },
    { label: "Total Mrni Qty", field: "totalmrniqty" },
    { label: "Inward Qty", field: "inwardqty" },
    { label: "Closing Stock", field: "closingstock" },
    // {
    //   label: "Action",
    //   field: "action",
    //   subaction1: (
    //     <svg
    //       xmlns="http://www.w3.org/2000/svg"
    //       width="24"
    //       height="24"
    //       viewBox="0 0 24 24"
    //       fill="none"
    //       stroke="currentColor"
    //       stroke-width="2"
    //       stroke-linecap="round"
    //       stroke-linejoin="round"
    //       class="feather feather-eye"
    //     >
    //       <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
    //       <circle cx="12" cy="12" r="3" />
    //     </svg>
    //   ),
    // },
  ];
  const sumofkeys = [{ first: "closingstock" }];
  return (
    <MininmumStockReportComponent
      head={header}
      title={"Mininmum Stock"}
      axiostabledata={"mininmumstock"}
      Add={""}
      Create={""}
      // Edit={""}
      // View={""}
      warehousehdata={warehousename}
      Sumofkeys={sumofkeys}
    />
  );
}

export default MininmumStockReport;
