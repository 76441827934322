import axios from "axios";
import { MDBCard } from "mdbreact";
import React, { useState } from "react";
import { Accordion, Button, Col, Form, Row, Table } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { BASE_URL } from "../services/Api";
import authHeader from "../services/auth-header";
import { useEffect } from "react";
import ProductService from "../services/ProductService";
import { useDispatch, useSelector } from "react-redux";
import { changecostsalesorderedit, changesalesordereditdiscount, changesalesordereditmeasurement, changesalesordereditqty, changesalesordereditsqpbmeasurement, changesalesorderedittext, changeunitofMeasurementsalesorderedit, getsalesordereditdatatoload, removefromcartfromsalesoderedit, salesordereditaddtocart } from "../../redux/Action/salesorderedit";
import Swal from "sweetalert2";

export default function Edit() {
    const { id } = useParams();
    const [deliveryAddress,setdelval]=useState([]);
      const[disdel,setdel]=useState([]);

    const { pointindex } = useParams();
    const [customerdata, setcustomerdata] = useState('');
    const [warehouseData, setwarehousedata] = useState('');
    const [sodate, setsodate] = useState('');
    const [quotationstatus, setquotationstatus] = useState('');
    const [taxtype, settaxtype] = useState('');
    const [customerSubContactsdata, setcustomerSubContactsdata] = useState('');
    const [remarks, setremarks] = useState('');
    const [productdata, setproductdata] = useState([]);
    const [primaryorderId, setprimaryorderId] = useState('');
    const [buyerorder, setbuyerorder] = useState('');
    const [voucherno, setvoucherno] = useState('');
    const [dispatchedthrough, setdispatchedthrough] = useState('');
    const [destination, setdestination] = useState('');
    const [termsofdelivery, settermsofdelivery] = useState('');
    const [branchdata, setbranchdata] = useState([]);
    const [branch, setbranch] = useState('');
    const [shippingcharge, setShipping] = useState(0);
    const [shippingfortax, setshippingfortax] = useState('');
    const [round, setRound] = useState(0);
    const [bstateid, setbstateid] = useState('');
    const [dstateid, setdstateid] = useState('');
    const [paymentTerms, setpaymentTerms] = useState('');
    const [retailerstatus, setretailerstatus] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const applyFontSizeTransition = (elementId) => {
      const element = document.getElementById(elementId);
      element.style.transition = 'font-size 2s';
      element.style.fontSize = '30px';
      element.style.fontWeight = '600';
      // font-weight: 600;
      setTimeout(() => {
         
        document.getElementById("typer1").classList.add('promote');
        element.style.fontSize = '24px';
      }, 1000);
    };

    const dispatch = useDispatch();
    const salesOrderItems = useSelector((state) => state.salesordereditdata);
    let gross_Amount = salesOrderItems.length && salesOrderItems.map(item => Math.round(item.total)).reduce((prev, next) => Math.round(prev - (-next)));
    let gst = salesOrderItems.length && salesOrderItems.map(item => (item.gstvalue)).reduce((prev, next) => (prev - (-next)));

    let aa = [];
    salesOrderItems.map(user => {
        let igst_value = user.igst;
        let total_value = user.total;
        let total_amou = ((Number(shippingcharge) / Number(gross_Amount)) * Number(total_value));
        let add_amou = Number(total_amou) - (-Number(total_value));
        let final_amou = ((Number(add_amou) / 100) * Number(igst_value)).toFixed(2);
        aa.push(final_amou)

        // console.log("mello",((Number(add_amou)/100)*Number(igst_value)).reduce((prev, next) => Math.round(prev - (-next))) );
    })
    let total_per = aa.length && aa.map(user => user).reduce((prev, next) => (prev - (-next)));

    let grossAmount = Number(gross_Amount) + Number(shippingcharge);
    let grandTotal = Number(gross_Amount) + Number(shippingcharge) + Number(round) + Number(total_per);

    let igstTax = Number(total_per);
    let cgstTax = (Number(total_per) / 2);
    let sgstTax = (Number(total_per) / 2);

    const navigate = useNavigate();

    function roundData(e){
        if(e){

            let gross_Amount = salesOrderItems.length && salesOrderItems.map(item => Math.round(item.total)).reduce((prev, next) => Math.round(prev - (-next)));
            let gst = salesOrderItems.length && salesOrderItems.map(item => (item.gstvalue)).reduce((prev, next) => (prev - (-next)));
            // console.log("mello",salesOrderItems)
            let aa = [];
            salesOrderItems.map(user=>{
                let igst_value = user.igst;
                let total_value = user.total;
                let total_amou = ((Number(shippingcharge)/Number(gross_Amount))* Number(total_value));
                let add_amou = Number(total_amou) - (-Number(total_value));
                let final_amou = ((Number(add_amou)/100)*Number(igst_value)).toFixed(2); 
                aa.push(final_amou)
        
                // console.log("mello",((Number(add_amou)/100)*Number(igst_value)).reduce((prev, next) => Math.round(prev - (-next))) );
            })
            let total_per = aa.length && aa.map(user=>user).reduce((prev, next) => (prev - (-next)));
        
         
            let grandTotal = Number(gross_Amount)  + Number(shippingcharge) + Number(total_per);
             let grand_Total = Math.ceil(grandTotal);
        
             if(grand_Total > grandTotal){
                 let rou_nd = (Number(grand_Total) - Number(grandTotal)).toFixed(2);
                 setRound(rou_nd);
             } else{
                 setRound(0)
             }
               
              
        } else if(e==''){
            setRound(0)
        }
    }

    const [distributordata12, setdistributordata12] = useState([])
    const [disword12, setdisword12] = useState(encodeURIComponent(' '))



    useEffect(() => {
      

        axios.get(BASE_URL + `voucher/page/0/100/desc/id/${disword12.length ? disword12 : encodeURIComponent(' ')}`, {
            headers: authHeader()
        }).then(response => {
            if (response.data.data == undefined) {
                setdistributordata12(response.data.Index.map(use => ({ value: use.voucherseries, label: use.voucherseries })));
            } if (response.data.Index == undefined) {
                setdistributordata12(response.data.data.map(use => ({ value: use.voucherseries, label: use.voucherseries })));

            }
            // setproductdata((res.data.Index).map(use => ({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code + ' - ' + use.short_Name })));
        })


    }, [  disword12])


    const [voucherid, setvoucher] = useState([])



    const getsalesorderdata = () => {

        axios.get(BASE_URL + 'so/' + id, {
            headers: authHeader()
        }).then(res => {
           (res.data.retailerstatus === 'customer'?setdel(res.data.retailer.deliveryAddress):setdel(res.data.distributor.deliveryAddress))
            
            setSelectedOption(res.data.deliveryAddress)

            setdelval(res.data.deliveryAddress)
            // console.log("mello",res.data);
            setretailerstatus(res.data.retailerstatus);
            (res.data.retailerstatus === 'customer'?
            setcustomerdata(res.data.retailer):setcustomerdata(res.data.distributor))
            setwarehousedata(res.data.warehouse);
            setsodate(res.data.sodate);
            setquotationstatus(res.data.quotationstatus);
            setpaymentTerms(res.data.paymentTerms);
            settaxtype(res.data.taxtype);
            setremarks(res.data.remarks);
            setprimaryorderId(res.data.primaryorderId);
            setbuyerorder(res.data.buyerorder);
            setvoucherno(res.data.voucherno);
            setvoucher(res.data.voucherid);
            setdispatchedthrough(res.data.dispatchedthrough);
            setdestination(res.data.destination);
            settermsofdelivery(res.data.termsofdelivery);
            setbranch({ "id": res.data.branch.id });
            setbranchdata(res.data.branch.branchname);
            setbstateid(res.data.branch.states.id);
            setShipping(res.data.shippingcharge);
            setRound(res.data.roundingofvalue);
            (res.data.retailerstatus === 'customer'?
            setdstateid(res.data.retailer.stateid):setdstateid(res.data.distributor.stateid))
        })
    };
    let date_value = new Date(sodate);
    const defaultValue = date_value.toLocaleDateString('en-CA');
    const getProductAll = () => {

        axios.get( BASE_URL + 'product/page/0/100/asc/id/%20',{
            headers:authHeader()
        }).then(res=>{

            setproductdata((res.data.Index).map(use => ({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code + ' - ' + use.short_Name })));
        })
    };

    const customFilter = (e) => {
        let name = e;
        axios.get( BASE_URL + 'product/page/0/100/asc/id/'+name,{
            headers:authHeader()
        }).then(res=>{
         setproductdata((res.data.data).map(use=>({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code + ' - ' + use.short_Name })))
        })
    };

    useEffect(() => {

        getsalesorderdata();
        getProductAll();
        dispatch(getsalesordereditdatatoload(id));

    }, []);
    const [selectedOption, setSelectedOption] = useState(null);

    const handleSelectChange = (selectedOption) => {
      setSelectedOption(selectedOption);

      setdelval(selectedOption)
    };
    const addData = (e) => {
        let name = e.value;
        const filteredItems = salesOrderItems.filter(item => item.product.id === name);
        // console.log("mello",customerdata)
        if (filteredItems.length == "0") {
            dispatch(salesordereditaddtocart(name, ({
                "schemeboxProductDiscount": customerdata.schemeboxProductDiscount,
                "schemekgProductDiscount": customerdata.schemekgProductDiscount,
                "schemecorporateProductDiscount": customerdata.schemecorporateProductDiscount,
                "schemecookerProductDiscount": customerdata.schemecookerProductDiscount,
                "schemenoshProductDisocunt": customerdata.schemenoshProductDisocunt,
                "kgProductDiscount": customerdata.kgProductDiscount,
                "boxProductDiscount": customerdata.boxProductDiscount,
                "corporaetProductDiscount": customerdata.corporaetProductDiscount,
                "cookerProductDiscount": customerdata.cookerProductDiscount,
                "noshProductDiscount": customerdata.noshProductDiscount
            })));
            setShipping("0");
        } else {
            Swal.fire({
                title: 'This Product is Already Added',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonColor: 'red'
            })
        }

    }

    /* get Shipping Charges */
    let settingship = (e) => {
        if (e == "") {

            setShipping("");
        } else {
            setShipping(e);
        }

    };

    /* get Round Value */
    const settinground = (e) => {

        if (e == "") {

            setRound("");
        } else {


            setRound(e);
        }

    };

    const SGSTcheck = () => {
        let aa = 0

        if (taxtype == "withtax") {

            if (bstateid === dstateid) {

                aa = sgstTax;
                // sgstTax=0;
            } else {
                aa = 0;
                sgstTax=0;
            }

        } else {
            aa = 0;  
            sgstTax=0;
        }

        return aa;

    }

    const cgstcheck = () => {

        let bb = 0

        if (taxtype == "withtax") {

            if (bstateid === dstateid) {

                bb = cgstTax;
              
            } else {

                bb = 0;
                cgstTax=0;
            }

        } else {

            bb = 0;
            cgstTax=0;
        }
        return bb;
    }

    const igstcheck = () => {
        let cc = 0;

        if (taxtype == "withtax") {
            if (bstateid === dstateid) {
                cc = 0;
                igstTax=0;
            } else {
                cc = igstTax;
            }
        } else {
            cc = 0;
        }
        return cc;
    }


    const submitdata = () => {
        setIsLoading(true);
        const distributor = { "id": customerdata.id };
        const retailer = {"id": customerdata.id}
        const warehouse = { "id": warehouseData.id };
        let igst = igstcheck();
        let cgst = cgstcheck();
        let sgst = SGSTcheck();
        let grandtotal = grandTotal;
        let roundingofvalue = round;
        let grossamount = grossAmount;
        if(retailerstatus === 'customer'){
            axios.put(BASE_URL + 'so/' + id, {
                sodate, quotationstatus, taxtype, remarks, buyerorder,voucherid, voucherno, dispatchedthrough,
                destination, termsofdelivery, retailer, warehouse, salesOrderItems, primaryorderId,
                igst, cgst, sgst, grandtotal, grossamount, shippingcharge, roundingofvalue, branch, paymentTerms,deliveryAddress
            }, {
                headers: authHeader()
            }).then(res => {
                navigate(`/SalesOrderTable/${pointindex}`)
            }).catch(error => {
                console.error('Error adding  :', error);
                if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `
                    <div>
                      <p id="issueText">Why do I have this issue?</p>
                      <ul>
                  
                      <li id="typer1">You might have an internet issue</li>
                      </ul>
                    </div>
                  `,
                  didOpen: () => {
                    applyFontSizeTransition('issueText');
                  }
                });
              }
              }).finally(() => {
                setIsLoading(false); 
              });
        } else{
        axios.put(BASE_URL + 'so/' + id, {
            sodate, quotationstatus, taxtype, remarks, buyerorder,voucherid, voucherno, dispatchedthrough,
            destination, termsofdelivery, distributor, warehouse, salesOrderItems, primaryorderId,
            igst, cgst, sgst, grandtotal, grossamount, shippingcharge, roundingofvalue, branch, paymentTerms,deliveryAddress
        }, {
            headers: authHeader()
        }).then(res => {
            navigate(`/SalesOrderTable/${pointindex}`)
        }).catch(error => {
            console.error('Error adding  :', error);
            if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `
                <div>
                  <p id="issueText">Why do I have this issue?</p>
                  <ul>
              
                  <li id="typer1">You might have an internet issue</li>
                  </ul>
                </div>
              `,
              didOpen: () => {
                applyFontSizeTransition('issueText');
              }
            });
          }
          }).finally(() => {
            setIsLoading(false); 
          });
    }
    }


    const Onchangevoucher = (e) => {
        setvoucher(e.label)
        console.log(e.label)
    }

    return (
        <MDBCard className="company">
            <h1>Sales Order Edit</h1>
            <Link className='p-2 w-200 card-header card-title bd-highlight' to={`/SalesOrderTable/${pointindex}`}><Button>Back</Button></Link>

            <Form className="product1">
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Sales Order Data</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Branch Name</Form.Label>
                                    <Form.Control type="text" value={branchdata} disabled />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">WareHouse Name</Form.Label>
                                    <Form.Control type="text" value={warehouseData.name} disabled />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Date</Form.Label>
                                    <Form.Control type="date" value={defaultValue} onChange={(e) => setsodate(e.target.value)} />

                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Terms of Payment</Form.Label>
                                    <Form.Control type='text' value={paymentTerms} name="paymentTerms" onChange={(e) => setpaymentTerms(e.target.value)} autoComplete="off" />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Customer Name</Form.Label>
                                    <Form.Control type="text" value={customerdata.tradeName} readOnly />

                                </Form.Group>
                                {/* <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Contact Name</Form.Label>
                                   
                                </Form.Group> */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Buyer Order</Form.Label>
                                    <Form.Control type="text" value={buyerorder} name="buyerorder" onChange={(e) => setbuyerorder(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">

                                {/* <label className='form-label'>Supplier</label>
              <input type='text' style={{ border: 'none', background: 'white' }} disabled />
              <Select 
  options={dis1} 
  className='sett'
  value={[{ value:  voucherid, label:voucherid }]} 
  onInputChange={(inputValue) => setdisword12(inputValue)} 
  onChange={(options) =>{ setSupplier(options.value);setSupplierDetails([options])}} 
/>
</Form.Group> */}

<Form.Label className="label">Vouchers Series</Form.Label>
                                {/* <Select options={customerData} onChange={(e) => onChangeCustomer(e)} /> */}
 <Select      value={[{ value:  voucherid, label:voucherid }]} 
 onInputChange={(inputValue) => setdisword12(inputValue)}   onChange={(options) => Onchangevoucher(options)} />

                                </Form.Group>                    


                                
                                {/* <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Voucher No</Form.Label>
                                    <Form.Control type="text" value={voucherno} name="vocherno" onChange={(e) => setvoucherno(e.target.value)} />
                                </Form.Group> */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Dispatched Through</Form.Label>
                                    <Form.Control type="text" value={dispatchedthrough} name="dispatchedthrough" onChange={(e) => setdispatchedthrough(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Destination</Form.Label>
                                    <Form.Control type="text" value={destination} name="destination" onChange={(e) => setdestination(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Terms Of Delivery</Form.Label>
                                    <Form.Control type="text" value={termsofdelivery} name="termsofdelivery" onChange={(e) => settermsofdelivery(e.target.value)} />
                                </Form.Group>
                                {/* <Form.Group as={Col} md="8" controlId="formBasicName">
                                    <Form.Label className="label">Owner</Form.Label>
                                    <Form.Control type="text" autoComplete="off" />
                                </Form.Group> */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Quotation Status</Form.Label>
                                    <Form.Select value={quotationstatus} disabled>
                                        <option value='true'>Open</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Tax Type</Form.Label>
                                    <Form.Select value={taxtype} disabled>
                                        <option>--Select--</option>
                                        <option value='withtax'>With Tax</option>
                                        <option value='withouttax'>Without Tax</option>
                                    </Form.Select>
                                </Form.Group>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    {/* <Accordion.Item eventKey="1">
                        <Accordion.Header>Billing Address</Accordion.Header>
                        <Accordion.Body> */}
                            {/* <Row className="mb-1">
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Customer Address</Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Customer Details</Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Address : -</Form.Label>
                                    <span>&nbsp;{customerdata.address}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Name : -</Form.Label>
                                    <span>&nbsp;{customerSubContactsdata == "" ? customerdata.cname : customerSubContactsdata.contactname}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">City : -</Form.Label>
                                    <span>&nbsp;{customerdata.city}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Phone No : -</Form.Label>
                                    <span>&nbsp;{customerSubContactsdata == "" ? customerdata.phonenumber : customerSubContactsdata.phoneno}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Country : -</Form.Label>
                                    <span>{"India"}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Email Id : -</Form.Label>
                                    <span> {customerSubContactsdata == "" ? customerdata.email : customerSubContactsdata.email}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">State : -</Form.Label>
                                    <span>{supplierstate}</span>
                                </Form.Group>
                            </Row> */}

                            {/* <b><span className="">Delivery Address</span></b> <br></br> <br></br>
                                <Row className="mb-1"> */}
                               {/* <select className="form-select dd">
                                <option className="extrvagnze">select Address</option>
                               
                                {
                                    disdel.map((item)=>{
                                        return<>
                                          <option className="extrvagnze">Address:{item.delivery_address} ,State:-{item.state} &nbsp;,Pin-code:-{item.pincode}</option>
                                        </>
                                    })
                                }
                               </select> */}
                               {/* <Select
      options={options}
      onChange={handleSelectChange}
      filterOption={customFilter11}
      placeholder="Select Address"
      value={selectedOption}
    /> */}
{/* <select className="form-control" value={selectedOption}   onChange={(e)=>handleSelectChange(e.target.value
    )}>
    <option>Select Delivery Address</option>
    {
        disdel.map((item)=>{
            return<>
            <option value={item.id}>{item.delivery_address}</option>
            </>
        })
    }
</select>
                                
                            </Row>         
                        </Accordion.Body>
                    </Accordion.Item> */}
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>Details</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="12" controlId="formBasicName">
                                    <Form.Label className="label">Remarks</Form.Label>
                                    <Form.Control as="textarea" value={remarks} rows={3} name='address' onChange={(e)=>setremarks(e.target.value)} placeholder='Enter Remarks' />
                                </Form.Group>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <br></br>
                <Row className="mb-1">
                    <Form.Group as={Col} md="12" controlId="formBasicName">
                        <Form.Label className="label">Product</Form.Label>
                        <Select options={productdata} onChange={(e) => addData(e)} onInputChange={(e) => customFilter(e) } />
                    </Form.Group>
                </Row>
                <br></br>
                <Table striped responsive hover size="sm">
                    <thead className='p-3 mb-2 bg-success text-white'>
                        <tr>
                            <th>PRODUCT&nbsp;NAME</th>
                            <th >UOM</th>
                            <th >QTY(PCS)</th>
                            <th >QTY(KG)</th>
                            <th>SQ</th>
                            <th>SQData</th>
                            <th >Rate</th>
                            <th >Trade&nbsp;Discount</th>
                            <th>GROSS AMOUNT (₹)</th>
                            <th >SCHEME DISCOUNT %</th>
                            <th >NET AMOUNT (₹)</th>
                            <th >GST Value</th>
                            <th >Amount</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody style={{ overflow: "scroll" }}>
                        {salesOrderItems.map(item =>
                            item.salesorderitemId == null ? <tr key={item.product.id} >
                                <td>
                                    <Form.Group as={Col} md="12" >
                                        {/* <Form.Control type="text" value={(item.productName ? item.productName : item.product.productName)} size="sm" /> */}
                                        <textarea type="text"   className="form-control textcontrol" value={(item.productName ? item.productName : item.product.productName)} size="sm" ></textarea>

                                    </Form.Group>
                                    <Form.Control as="textarea" onChange={(e) => { dispatch(changesalesorderedittext(e.target.value, item)) }} value={item.text} rows={2} className='form-control' />
                                </td>
                                <td><Form.Group as={Col} md="12">
                                    <Form.Select disabled={item.productType !== "kgproduct" ? true : false} onChange={(e) => { dispatch(changeunitofMeasurementsalesorderedit(e.target.value, item, item.unitofmeasurement)); }} size="sm">
                                        <option>{item.productType !== "kgproduct" ? 'PCS' : "Select"}</option>
                                        <option value="pcs">PCS</option>
                                        <option value="kg">KG</option>
                                    </Form.Select>
                                </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" size="sm" onChange={(e) => { dispatch(changesalesordereditmeasurement(e.target.value, item, taxtype, item.unitofmeasurement));roundData(e.target.value) }} disabled={item.unitofmeasurement == "kg" ? true : false || item.unitofmeasurement == "" ? true : false} value={item.someasurement} />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" size="sm" onChange={(e) => { dispatch(changesalesordereditqty(e.target.value, item, taxtype, item.unitofmeasurement));roundData(e.target.value) }} disabled={item.unitofmeasurement == "pcs" || item.unitofmeasurement == "box" ? true : false || item.unitofmeasurement == "" ? true : false} value={item.unitofmeasurement == "box" ? "" : item.soqty} />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" size="sm" onChange={(e) => { dispatch(changesalesordereditsqpbmeasurement(e.target.value, item, taxtype, item.unitofmeasurement));roundData(e.target.value) }} step={item.standardQtyPerBox} value={item.soquantity_placed} disabled={item.unitofmeasurement == "" ? true : false} />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" value={Number(item.soquantity_placed_kg)} disabled={item.unitofmeasurement == "box" ? true : false || item.unitofmeasurement == "" ? true : false} size="sm" requied />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" size="sm" onChange={(e) => { dispatch(changecostsalesorderedit(e.target.value, item, taxtype));roundData(e.target.value) }} value={Number(item.dlp)} />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" min="0" size="sm" value={Number(item.tradediscount)} />
                                    </Form.Group>
                                </td>
                                <td><Form.Group as={Col} md="12">
                                    <Form.Control type="number" min="0" value={Number(item.grossamount)} size="sm" readOnly requied />
                                </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" onChange={(e) => { dispatch(changesalesordereditdiscount(e.target.value, item, taxtype));roundData(e.target.value) }} value={Number(item.schemediscount)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td><Form.Group as={Col} md="12">
                                    <Form.Control type="number" min="0" value={Number(item.netAmount)} size="sm" readOnly requied />
                                </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" value={Number(item.gstvalue)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" value={Number(item.total)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type='button' className='btn btn-danger' onClick={() => {dispatch(removefromcartfromsalesoderedit(item.product.id));roundData('')}} value="X" size="sm" />
                                    </Form.Group>
                                </td>
                            </tr>
                                : <tr key={item.id}>
                                    <td>
                                        <Form.Group as={Col} md="12" >
                                        <textarea type="text"   className="form-control textcontrol" value={(item.productName ? item.productName : item.product.productName)} size="sm" ></textarea>

                                            {/* <Form.Control type="text" value={(item.productName ? item.productName : item.product.productName)} size="sm" /> */}
                                        </Form.Group>
                                        <Form.Control as="textarea" onChange={(e) => { dispatch(changesalesorderedittext(e.target.value, item)) }} value={item.text} rows={2} className='form-control' />
                                    </td>
                                    <td>
                                        <Form.Group as={Col} md="12">
                                            <Form.Control type="text" size="sm" value={item.unitofmeasurement !== "kg" ? "PCS" : item.unitofmeasurement} disabled />
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group as={Col} md="12">
                                            <Form.Control type="number" min="0" size="sm" onChange={(e) => { dispatch(changesalesordereditmeasurement(e.target.value, item, taxtype, item.unitofmeasurement));roundData(e.target.value) }} disabled={item.unitofmeasurement == "kg" ? true : false || item.unitofmeasurement == "" ? true : false} value={item.someasurement} />
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group as={Col} md="12">
                                            <Form.Control type="number" min="0" size="sm" onChange={(e) => { dispatch(changesalesordereditqty(e.target.value, item, taxtype, item.unitofmeasurement));roundData(e.target.value) }} disabled={item.unitofmeasurement == "pcs" || item.unitofmeasurement == "box" ? true : false || item.unitofmeasurement == "" ? true : false} value={item.unitofmeasurement == "box" ? "" : item.soqty} />
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group as={Col} md="12">
                                            <Form.Control type="number" min="0" size="sm" onChange={(e) => { dispatch(changesalesordereditsqpbmeasurement(e.target.value, item, taxtype, item.unitofmeasurement));roundData(e.target.value) }} step={item.standardQtyPerBox} value={item.soquantity_placed} disabled={item.unitofmeasurement == "" ? true : false} />
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group as={Col} md="12">
                                            <Form.Control type="number" min="0" value={Number(item.soquantity_placed_kg)} disabled={item.unitofmeasurement == "box" ? true : false || item.unitofmeasurement == "" ? true : false} size="sm" requied />
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group as={Col} md="12">
                                            <Form.Control type="number" min="0" size="sm" onChange={(e) => { dispatch(changecostsalesorderedit(e.target.value, item, taxtype));roundData(e.target.value) }} value={Number(item.dlp)} />
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group as={Col} md="12">
                                            <Form.Control type="number" min="0" size="sm" value={Number(item.tradediscount)} />
                                        </Form.Group>
                                    </td>
                                    <td><Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" value={Number(item.grossamount)} size="sm" readOnly requied />
                                    </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group as={Col} md="12">
                                            <Form.Control type="number" min="0" onChange={(e) => { dispatch(changesalesordereditdiscount(e.target.value, item, taxtype));roundData(e.target.value) }} value={item.schemediscount || ''} size="sm" />
                                        </Form.Group>
                                    </td>
                                    <td><Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" value={Number(item.netAmount)} size="sm" readOnly requied />
                                    </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group as={Col} md="12">
                                            <Form.Control type="number" min="0" value={Number(item.gstvalue)} size="sm" />
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group as={Col} md="12">
                                            <Form.Control type="number" min="0" value={Number(item.total)} size="sm" />
                                        </Form.Group>
                                    </td>
                                    <td></td>
                                    {/* <td>
                                        <Form.Group as={Col} md="12">
                                            <Form.Control type='button' className='btn btn-danger' onClick={() => dispatch(removefromcartfromsalesorder(item.id))} value="X" size="sm" />
                                        </Form.Group>
                                    </td> */}
                                </tr>
                        )}
                    </tbody>
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Packing&nbsp;&&nbsp;Forwarding</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={shippingcharge} onChange={(e) => settingship(e.target.value)} style={{ height: 30 }} />
                                </Form.Group>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Net&nbsp;Amount</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={grossAmount?((grossAmount).toFixed(2)):"0"} style={{ height: 30 }} readOnly />
                                </Form.Group>
                            </td>
                            <td></td>
                        </tr>

                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>IGST</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={igstcheck()} style={{ height: 30 }} requied />
                                </Form.Group>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>SGST</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={SGSTcheck()} style={{ height: 30 }} requied />
                                </Form.Group>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>CGST</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={cgstcheck()} style={{ height: 30 }} requied />
                                </Form.Group>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Round&nbsp;off&nbsp;Value</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={round} onChange={(e) => settinground(e.target.value)} style={{ height: 30 }} />
                                </Form.Group>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Grand&nbsp;Total</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={grandTotal?((grandTotal).toFixed(2)):"0"} style={{ height: 30 }} readOnly />
                                </Form.Group>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </Table>
                <Button variant="primary" style={{width:'auto'}} onClick={submitdata} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Submit'}
        </Button>
            </Form>
        </MDBCard>
    )
}