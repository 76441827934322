import { GET_PRIMARYORDER_UPDATE } from "../constant";
import { GET_PRODUCTDATA_ADD_PRIMARYORDER_UPDATE } from "../constant";
import { CHANGE_UOM_PRIMARYORDER_UPDATE } from "../constant";
import { CHANGE_QTYPCS_PRIMARYORDER_UPDATE } from "../constant";
import { CHANGE_QTYKG_PRIMARYORDER_UPDATE } from "../constant";
import { REMOVE_TO_CART_PRIMARYORDER_UPDATE } from "../constant";
import { CHANGE_COST_PRIMARYORDER_UPDATE } from "../constant";
import { CHANGE_DISCOUNT1_PRIMARYORDER_UPDATE } from "../constant";
import { CHANGE_TEXT_PRIMARYORDER_UPDATE } from "../constant";
import { CHANGE_SQPBPCS_PRIMARYORDER_UPDATE } from "../constant";

export const primaryorderupdate = (data = [], action) => {

    switch (action.type) {

        case GET_PRIMARYORDER_UPDATE:
            return [...action.data];

        case GET_PRODUCTDATA_ADD_PRIMARYORDER_UPDATE:
            let newData3 = action.data2;
            let dataNew = action.data;

            function discountData1() {
                if (dataNew.productType == "boxproduct") {
                    return ({ "ProductDiscount": newData3.boxProductDiscount, "schemeDiscount": newData3.schemeboxProductDiscount });
                }
                else if (dataNew.productType == "kgproduct") {
                    return ({ "ProductDiscount": newData3.kgProductDiscount, "schemeDiscount": newData3.schemekgProductDiscount });
                }
                else if (dataNew.productType == "corporateproduct") {
                    return ({ "ProductDiscount": newData3.corporaetProductDiscount, "schemeDiscount": newData3.schemecorporateProductDiscount });
                }
                else if (dataNew.productType == "cookerproduct") {
                    return ({ "ProductDiscount": newData3.cookerProductDiscount, "schemeDiscount": newData3.schemecookerProductDiscount });
                }
                else if (dataNew.productType == "noshproduct") {
                    return ({ "ProductDiscount": newData3.noshProductDiscount, "schemeDiscount": newData3.schemenoshProductDisocunt });
                }
            }

            let aa = discountData1();
            const item = {
                id: action.data.id,
                brand: action.data.brand,
                capacity: action.data.capacity,
                category: action.data.category,
                cgst: action.data.cgst,
                cgstLedger: action.data.cgstLedger,
                diameter: action.data.diameter,
                eanCode: action.data.eanCode,
                hsnCode: action.data.hsnCode,
                igst: action.data.igst,
                igstLedger: action.data.igstLedger,
                dlp: action.data.dlp,
                productName: action.data.productName,
                productType: action.data.productType,
                productId: action.data.id,
                standardQtyPerBox: action.data.standardQtyPerBox,
                sgst: action.data.sgst,
                sgstLedger: action.data.sgstLedger,
                shortName: action.data.shortName,
                uomPrimary: action.data.uomPrimary,
                uomSecondary: action.data.uomSecondary,
                discount: aa.ProductDiscount,
                discount1: aa.schemeDiscount,
                unitofmeasurement: action.data.unitofmeasurement == undefined ? action.data.productType !== "kgproduct" ? "box" : '' : action.data.unitofmeasurement
            }

            return [item, ...data];

        case CHANGE_UOM_PRIMARYORDER_UPDATE:
            let newCart = [...data]
            let itemIndex = newCart.findIndex(obj => obj.productId === action.item.productId)
            let currItem = data[itemIndex]
            currItem.unitofmeasurement = action.data
            data[itemIndex] = currItem
            return [...data];

        case CHANGE_QTYPCS_PRIMARYORDER_UPDATE:
            let newCart1 = [...data]
            let itemIndex1 = newCart1.findIndex(obj => obj.productId === action.item.productId)
            let currItem1 = data[itemIndex1]
            if (action.data2 == "pcs") {
                if (currItem1.discount > 0 || currItem1.discount1 > 0) {
                    if (action.data == "") {
                        currItem1.measurement = Number(0)
                        currItem1.qty = currItem1.measurement
                    } else {
                        currItem1.measurement = Number(action.data)
                        let aa = Number(currItem1.measurement)
                        currItem1.qty = (Number(aa) * Number(currItem1.uomSecondary))

                        let a = (Number(action.data) / Number(currItem1.standardQtyPerBox));
                        if ((Number(Math.trunc(a)) * Number(currItem1.standardQtyPerBox)) == Number(action.data)) {
                            currItem1.quantity_placed = Number(Number(currItem1.measurement))
                        } else {
                            currItem1.quantity_placed = (Number(Math.trunc(a) + 1) * Number(currItem1.standardQtyPerBox))
                        }
                        currItem1.quantity_placed_kg = (Number(currItem1.quantity_placed) * Number(currItem1.uomSecondary)).toFixed(2)
                    }

                    let discount2 = Number(currItem1.quantity_placed_kg) * Number(currItem1.dlp) * (Number(currItem1.discount) / 100)
                    let totaAmount = Number(currItem1.quantity_placed_kg) * Number(currItem1.dlp) - Number(discount2);
                    currItem1.grossamount = (Number(totaAmount)).toFixed(2);
                    let schemediscount = ((Number(totaAmount) * Number(currItem1.discount1)) / 100)
                    let NetAmount = (Number(totaAmount) - Number(schemediscount))
                    currItem1.netAmount = (Number(NetAmount)).toFixed(2)
                    // currItem.NetAmount = parseInt(currItem.quantity_placed_kg) * parseInt(currItem.dlp)
                    currItem1.gstvalue = ((Number(currItem1.netAmount) / Number(100)) * (Number(currItem1.igst))).toFixed(2)
                    currItem1.total = (Number(currItem1.gstvalue) + Number(currItem1.netAmount)).toFixed(2)
                    data[itemIndex1] = currItem1

                } else {

                    if (action.data == "") {
                        currItem1.measurement = Number(0)
                        currItem1.qty = currItem1.measurement
                    } else {
                        currItem1.measurement = Number(action.data)
                        let aa = Number(currItem1.measurement)
                        currItem1.qty = (Number(aa) * (Number(currItem1.uomSecondary)));

                        let a = (Number(action.data) / Number(currItem1.standardQtyPerBox));
                        if ((Number(Math.trunc(a)) * Number(currItem1.standardQtyPerBox)) == Number(action.data)) {
                            currItem1.quantity_placed = Number(Number(currItem1.measurement))
                        } else {
                            currItem1.quantity_placed = (Number(Math.trunc(a) + 1) * Number(currItem1.standardQtyPerBox))
                        }
                        currItem1.quantity_placed_kg = (Number(currItem1.quantity_placed) * Number(currItem1.uomSecondary)).toFixed(2)

                    }
                    currItem1.discount = Number(0)
                    currItem1.grossamount = (Number(currItem1.quantity_placed_kg) * Number(currItem1.dlp)).toFixed(2);
                    let NetAmount = (Number(currItem1.quantity_placed_kg) * Number(currItem1.dlp))
                    currItem1.netAmount = (Number(NetAmount)).toFixed(2)
                    currItem1.gstvalue = ((Number(currItem1.netAmount) / Number(100)) * (Number(currItem1.igst))).toFixed(2)
                    currItem1.total = (Number(currItem1.gstvalue) + Number(currItem1.netAmount)).toFixed(2)
                    data[itemIndex1] = currItem1


                }
            }
            if (currItem1.unitofmeasurement == "box") {

                if (currItem1.discount > 0 || currItem1.discount1 > 0) {
                    if (action.data == "") {
                        currItem1.measurement = Number(0)
                    } else {

                        currItem1.measurement = Number(action.data)

                        let a = (Number(action.data) / Number(currItem1.standardQtyPerBox));
                        if ((Number(Math.trunc(a)) * Number(currItem1.standardQtyPerBox)) == Number(action.data)) {
                            currItem1.quantity_placed = Number(Number(action.data))
                        } else {
                            currItem1.quantity_placed = (Number(Math.trunc(a) + 1) * Number(currItem1.standardQtyPerBox))
                        }
                        currItem1.quantity_placed_kg = '';
                    }

                    let discount2 = Number(currItem1.quantity_placed) * Number(currItem1.dlp) * (Number(currItem1.discount) / 100)
                    let totaAmount = Number(currItem1.quantity_placed) * Number(currItem1.dlp) - Number(discount2);
                    currItem1.grossamount = (Number(totaAmount)).toFixed(2);
                    let schemediscount = ((Number(totaAmount) * Number(currItem1.discount1)) / 100)
                    let NetAmount = (Number(totaAmount) - Number(schemediscount))
                    currItem1.netAmount = (Number(NetAmount)).toFixed(2)
                    // console.log("mello5",currItem.grossamount)
                    // currItem.NetAmount = parseInt(currItem.measurement) * parseInt(currItem.dlp)
                    currItem1.gstvalue = ((Number(currItem1.netAmount) / Number(100)) * (Number(currItem1.igst))).toFixed(2)
                    currItem1.total = (Number(currItem1.gstvalue) + Number(currItem1.netAmount)).toFixed(2)
                    data[itemIndex1] = currItem1

                } else {

                    if (action.data == "") {
                        currItem1.measurement = Number(0)
                    } else {
                        currItem1.measurement = Number(action.data)

                        let a = (Number(action.data) / Number(currItem1.standardQtyPerBox));
                        if ((Number(Math.trunc(a)) * Number(currItem1.standardQtyPerBox)) == Number(action.data)) {
                            currItem1.quantity_placed = Number(Number(action.data))
                        } else {
                            currItem1.quantity_placed = (Number(Math.trunc(a) + 1) * Number(currItem1.standardQtyPerBox))
                        }
                        currItem1.quantity_placed_kg = '';

                    }
                    currItem1.discount = Number(0)
                    currItem1.grossamount = (Number(currItem1.quantity_placed) * Number(currItem1.dlp)).toFixed(2);
                    let NetAmount = (Number(currItem1.quantity_placed) * Number(currItem1.dlp))
                    currItem1.netAmount = (Number(NetAmount)).toFixed(2)
                    currItem1.gstvalue = ((Number(currItem1.netAmount) / Number(100)) * (Number(currItem1.igst))).toFixed(2)
                    currItem1.total = (Number(currItem1.gstvalue) + Number(currItem1.netAmount)).toFixed(2)
                    data[itemIndex1] = currItem1


                }

            }


            return [...data];

        case CHANGE_QTYKG_PRIMARYORDER_UPDATE:

            let newCart2 = [...data]
            let itemIndex2 = newCart2.findIndex(obj => obj.productId === action.item.productId)
            let currItem2 = data[itemIndex2]
            // currItem2.measurement = Number(action.data)

            if (action.data2 == "kg") {

                if (currItem2.discount > 0 || currItem2.discount1 > 0) {
                    if (action.data == "") {
                        currItem2.qty = '';
                        currItem2.measurement = '';
                    } else {

                        currItem2.qty = (action.data)
                        let aa = Number(currItem2.qty);
                        let ab = (Number(aa) / currItem2.uomSecondary)
                        currItem2.measurement = Math.round(ab);

                        let a = (Number(currItem2.measurement) / Number(currItem2.standardQtyPerBox));
                        if ((Number(Math.trunc(a)) * Number(currItem2.standardQtyPerBox)) == Number(currItem2.measurement)) {
                            currItem2.quantity_placed = Number(Number(currItem2.measurement))
                        } else {
                            currItem2.quantity_placed = (Number(Math.trunc(a) + 1) * Number(currItem2.standardQtyPerBox))
                        }
                        currItem2.quantity_placed_kg = (Number(currItem2.quantity_placed) * Number(currItem2.uomSecondary)).toFixed(2)
                    }
                    let discount2 = Number(currItem2.quantity_placed_kg) * Number(currItem2.dlp) * (Number(currItem2.discount) / 100)
                    let totaAmount = (Number(currItem2.quantity_placed_kg) * Number(currItem2.dlp) - Number(discount2))
                    currItem2.grossamount = (Number(totaAmount)).toFixed(2);
                    let schemediscount = ((Number(totaAmount) * Number(currItem2.discount1)) / 100)
                    let NetAmount = (Number(totaAmount) - Number(schemediscount));
                    currItem2.netAmount = (Number(NetAmount)).toFixed(2)
                    // console.log("mello5",netAmount,currItem2.NetAmount)
                    currItem2.productId = currItem2.id
                    // currItem2.NetAmount = parseInt(currItem2.quantity_placed_kg) * parseInt(currItem2.dlp)
                    currItem2.gstvalue = ((Number(currItem2.netAmount) / Number(100)) * (Number(currItem2.igst))).toFixed(2)
                    currItem2.total = (Number(currItem2.gstvalue) + Number(currItem2.netAmount)).toFixed(2)
                    data[itemIndex2] = currItem2
                } else {

                    if (action.data == "") {
                        currItem2.qty = '';
                        currItem2.measurement = '';
                    } else {
                        currItem2.qty = (action.data)
                        let aa = Number(currItem2.qty);
                        let ab = (Number(aa) / currItem2.uomSecondary)
                        currItem2.measurement = Math.round(ab);

                        let a = (Number(currItem2.measurement) / Number(currItem2.standardQtyPerBox));
                        if ((Number(Math.trunc(a)) * Number(currItem2.standardQtyPerBox)) == Number(currItem2.measurement)) {
                            currItem2.quantity_placed = Number(Number(currItem2.measurement))
                        } else {
                            currItem2.quantity_placed = (Number(Math.trunc(a) + 1) * Number(currItem2.standardQtyPerBox))
                        }
                        currItem2.quantity_placed_kg = (Number(currItem2.quantity_placed) * Number(currItem2.uomSecondary)).toFixed(2)

                    }
                    currItem2.discount = Number(0)
                    currItem2.productId = currItem2.id
                    currItem2.grossamount = (Number(currItem2.quantity_placed_kg) * Number(currItem2.dlp)).toFixed(2);
                    let NetAmount = (Number(currItem2.quantity_placed_kg) * Number(currItem2.dlp))
                    currItem2.netAmount = (Number(NetAmount)).toFixed(2)
                    currItem2.gstvalue = ((Number(currItem2.netAmount) / Number(100)) * (Number(currItem2.igst))).toFixed(2)
                    currItem2.total = (Number(currItem2.gstvalue) + Number(currItem2.netAmount)).toFixed(2)
                    data[itemIndex2] = currItem2


                }
            }



            return [...data];

        case REMOVE_TO_CART_PRIMARYORDER_UPDATE:

            const remainingitem = data.filter((item) => item.productId !== action.data);
            return [...remainingitem];

        case CHANGE_COST_PRIMARYORDER_UPDATE:

            let newCart3 = [...data]
            let itemIndex3 = newCart3.findIndex(obj => obj.productId === action.item.productId)
            let currItem3 = data[itemIndex3]

            if (currItem3.sqpb == null) {
                if (currItem3.unitofmeasurement == "box") {
                    if (currItem3.discount > 0 || currItem3.discount1 > 0) {

                        if (action.data == "") {
                            currItem3.dlp = ""
                        } else {
                            currItem3.dlp = Number(action.data)
                        }
                        // currItem3.dlp = parseInt(action.data)

                        let a = (Number(currItem3.measurement) / Number(currItem3.standardQtyPerBox));
                        if ((Number(Math.trunc(a)) * Number(currItem3.standardQtyPerBox)) == Number(currItem3.measurement)) {
                            currItem3.quantity_placed = Number(Number(currItem3.measurement))
                        } else {
                            currItem3.quantity_placed = (Number(Math.trunc(a) + 1) * Number(currItem3.standardQtyPerBox))
                        }
                        currItem3.quantity_placed_kg = '';

                        let discount3 = Number(currItem3.quantity_placed) * Number(currItem3.dlp) * (Number(currItem3.discount) / 100)
                        let totaAmount = Number(currItem3.quantity_placed) * Number(currItem3.dlp) - Number(discount3)
                        currItem3.grossamount = (Number(totaAmount)).toFixed(2);
                        let schemediscount = ((Number(totaAmount) * Number(currItem3.discount1)) / 100)
                        let NetAmount = (Number(totaAmount) - Number(schemediscount))
                        currItem3.netAmount = (Number(NetAmount)).toFixed(2)
                        // currItem3.NetAmount = parseInt(currItem3.quantity_placed_kg) * parseInt(currItem3.dlp)
                        currItem3.gstvalue = ((Number(currItem3.netAmount) / Number(100)) * (Number(currItem3.igst))).toFixed(2)
                        currItem3.total = (Number(currItem3.gstvalue) + Number(currItem3.netAmount)).toFixed(2)
                        data[itemIndex3] = currItem3
                        return [...data];

                    } else {

                        if (action.data == "") {
                            currItem3.dlp = ""
                        } else {
                            currItem3.dlp = Number(action.data)
                        }
                        // currItem3.dlp = parseInt(action.data)

                        let a = (Number(currItem3.measurement) / Number(currItem3.standardQtyPerBox));
                        if ((Number(Math.trunc(a)) * Number(currItem3.standardQtyPerBox)) == Number(currItem3.measurement)) {
                            currItem3.quantity_placed = Number(Number(currItem3.measurement))
                        } else {
                            currItem3.quantity_placed = (Number(Math.trunc(a) + 1) * Number(currItem3.standardQtyPerBox))
                        }
                        currItem3.quantity_placed_kg = '';


                        currItem3.discount = Number(0)
                        currItem3.grossamount = (Number(currItem3.quantity_placed) * Number(currItem3.dlp)).toFixed(2);
                        let NetAmount = (Number(currItem3.quantity_placed) * Number(currItem3.dlp))
                        currItem3.netAmount = (Number(NetAmount)).toFixed(2)
                        currItem3.gstvalue = ((Number(currItem3.netAmount) / Number(100)) * (Number(currItem3.igst))).toFixed(2)
                        currItem3.total = (Number(currItem3.gstvalue) + Number(currItem3.netAmount)).toFixed(2)
                        data[itemIndex3] = currItem3
                        return [...data];

                    }
                } /*end Box Product */
                else {
                    if (currItem3.discount > 0 || currItem3.discount1 > 0) {

                        if (action.data == "") {
                            currItem3.dlp = ""
                        } else {
                            currItem3.dlp = Number(action.data)
                        }
                        // currItem3.dlp = parseInt(action.data)

                        let a = (Number(currItem3.measurement) / Number(currItem3.standardQtyPerBox));
                        if ((Number(Math.trunc(a)) * Number(currItem3.standardQtyPerBox)) == Number(currItem3.measurement)) {
                            currItem3.quantity_placed = Number(Number(currItem3.measurement))
                        } else {
                            currItem3.quantity_placed = (Number(Math.trunc(a) + 1) * Number(currItem3.standardQtyPerBox))
                        }
                        currItem3.quantity_placed_kg = (Number(currItem3.quantity_placed) * Number(currItem3.uomSecondary)).toFixed(2)

                        let discount3 = Number(currItem3.quantity_placed_kg) * Number(currItem3.dlp) * (Number(currItem3.discount) / 100)
                        let totaAmount = Number(currItem3.quantity_placed_kg) * Number(currItem3.dlp) - Number(discount3)
                        currItem3.grossamount = (Number(totaAmount)).toFixed(2);
                        let schemediscount = ((Number(totaAmount) * Number(currItem3.discount1)) / 100)
                        let NetAmount = (Number(totaAmount) - Number(schemediscount))
                        currItem3.netAmount = (Number(NetAmount)).toFixed(2)
                        // currItem3.NetAmount = parseInt(currItem3.quantity_placed_kg) * parseInt(currItem3.dlp)
                        currItem3.gstvalue = ((Number(currItem3.netAmount) / Number(100)) * (Number(currItem3.igst))).toFixed(2)
                        currItem3.total = (Number(currItem3.gstvalue) + Number(currItem3.netAmount)).toFixed(2)
                        data[itemIndex3] = currItem3
                        return [...data];

                    } else {

                        if (action.data == "") {
                            currItem3.dlp = ""
                        } else {
                            currItem3.dlp = Number(action.data)
                        }
                        // currItem3.dlp = parseInt(action.data)

                        let a = (Number(currItem3.measurement) / Number(currItem3.standardQtyPerBox));
                        if ((Number(Math.trunc(a)) * Number(currItem3.standardQtyPerBox)) == Number(currItem3.measurement)) {
                            currItem3.quantity_placed = Number(Number(currItem3.measurement))
                        } else {
                            currItem3.quantity_placed = (Number(Math.trunc(a) + 1) * Number(currItem3.standardQtyPerBox))
                        }
                        currItem3.quantity_placed_kg = (Number(currItem3.quantity_placed) * Number(currItem3.uomSecondary)).toFixed(2)

                        currItem3.discount = Number(0)
                        currItem3.grossamount = (Number(currItem3.quantity_placed_kg) * Number(currItem3.dlp)).toFixed(2);
                        let NetAmount = (Number(currItem3.quantity_placed_kg) * Number(currItem3.dlp))
                        currItem3.netAmount = (Number(NetAmount)).toFixed(2)
                        currItem3.gstvalue = ((Number(currItem3.netAmount) / Number(100)) * (Number(currItem3.igst))).toFixed(2)
                        currItem3.total = (Number(currItem3.gstvalue) + Number(currItem3.netAmount)).toFixed(2)
                        data[itemIndex3] = currItem3
                        return [...data];

                    }
                } /* end KG Product */
            } else {

                if (currItem3.unitofmeasurement == "box") {
                    if (currItem3.discount > 0 || currItem3.discount1 > 0) {

                        if (action.data == "") {
                            currItem3.dlp = ""
                        } else {
                            currItem3.dlp = Number(action.data)
                        }
                        // currItem3.dlp = parseInt(action.data)
                        let discount3 = Number(currItem3.sqpb) * Number(currItem3.dlp) * (Number(currItem3.discount) / 100)
                        currItem3.quantity_placed_kg = '';
                        let totaAmount = Number(currItem3.sqpb) * Number(currItem3.dlp) - Number(discount3)
                        currItem3.grossamount = (Number(totaAmount)).toFixed(2);
                        let schemediscount = ((Number(totaAmount) * Number(currItem3.discount1)) / 100)
                        let NetAmount = (Number(totaAmount) - Number(schemediscount))
                        currItem3.netAmount = (Number(NetAmount)).toFixed(2)
                        // currItem3.NetAmount = parseInt(currItem3.measurement) * parseInt(currItem3.dlp)
                        currItem3.gstvalue = ((Number(currItem3.netAmount) / Number(100)) * (Number(currItem3.igst))).toFixed(2)
                        currItem3.total = (Number(currItem3.gstvalue) + Number(currItem3.netAmount)).toFixed(2)
                        data[itemIndex3] = currItem3
                        return [...data];

                    } else {

                        if (action.data == "") {
                            currItem3.dlp = ""
                        } else {
                            currItem3.dlp = Number(action.data)
                        }
                        // currItem3.dlp = parseInt(action.data)
                        currItem3.discount = Number(0)
                        currItem3.grossamount = (Number(currItem3.sqpb) * Number(currItem3.dlp)).toFixed(2);
                        currItem3.quantity_placed_kg = '';
                        let NetAmount = (Number(currItem3.sqpb) * Number(currItem3.dlp))
                        currItem3.netAmount = (Number(NetAmount)).toFixed(2)
                        currItem3.gstvalue = ((Number(currItem3.netAmount) / Number(100)) * (Number(currItem3.igst)))
                        currItem3.total = (Number(currItem3.gstvalue) + Number(currItem3.netAmount))
                        data[itemIndex3] = currItem3
                        return [...data];

                    }
                } else {
                    if (currItem3.discount > 0 || currItem3.discount1 > 0) {

                        if (action.data == "") {
                            currItem3.dlp = ""
                        } else {
                            currItem3.dlp = Number(action.data)
                        }
                        // currItem3.dlp = parseInt(action.data)
                        let qtydata = (Number(currItem3.sqpb) * Number(currItem3.uomSecondary))
                        currItem3.quantity_placed_kg = Number(qtydata).toFixed(2);
                        let discount3 = Number(qtydata) * Number(currItem3.dlp) * (Number(currItem3.discount) / 100)
                        let totaAmount = Number(qtydata) * Number(currItem3.dlp) - Number(discount3)
                        currItem3.grossamount = (Number(totaAmount)).toFixed(2);
                        let schemediscount = ((Number(totaAmount) * Number(currItem3.discount1)) / 100)
                        let NetAmount = (Number(totaAmount) - Number(schemediscount))
                        currItem3.netAmount = (Number(NetAmount)).toFixed(2)
                        // currItem3.NetAmount = parseInt(currItem3.qty) * parseInt(currItem3.dlp)
                        currItem3.gstvalue = ((Number(currItem3.netAmount) / Number(100)) * (Number(currItem3.igst))).toFixed(2)
                        currItem3.total = (Number(currItem3.gstvalue) + Number(currItem3.netAmount)).toFixed(2)
                        data[itemIndex3] = currItem3
                        return [...data];

                    } else {

                        if (action.data == "") {
                            currItem3.dlp = ""
                        } else {
                            currItem3.dlp = Number(action.data)
                        }
                        // currItem3.dlp = parseInt(action.data)
                        let qtydata = (Number(currItem3.sqpb) * Number(currItem3.uomSecondary))
                        currItem3.quantity_placed_kg = Number(qtydata).toFixed(2);
                        currItem3.discount = Number(0)
                        currItem3.grossamount = (Number(qtydata) * Number(currItem3.dlp)).toFixed(2);
                        let NetAmount = (Number(qtydata) * Number(currItem3.dlp))
                        currItem3.netAmount = (Number(NetAmount)).toFixed(2)
                        currItem3.gstvalue = ((Number(currItem3.netAmount) / Number(100)) * (Number(currItem3.igst))).toFixed(2)
                        currItem3.total = (Number(currItem3.gstvalue) + Number(currItem3.netAmount)).toFixed(2)
                        data[itemIndex3] = currItem3
                        return [...data];

                    }
                }

            }

            case CHANGE_DISCOUNT1_PRIMARYORDER_UPDATE:

            let newCart4 = [...data]
            let itemIndex4 = newCart4.findIndex(obj => obj.productId === action.item.productId)
            let currItem4 = data[itemIndex4]

                if(currItem4.sqpb == null){
            if (currItem4.unitofmeasurement == "box") {
            if (action.data == "") {
                currItem4.discount1 = 0
            } else {
                currItem4.discount1 = Number(action.data)
            }

            // currItem1.dlp = parseInt(action.data)

            let a = (Number(currItem4.measurement)/Number(currItem4.standardQtyPerBox));
                        if( (Number(Math.trunc(a))* Number(currItem4.standardQtyPerBox)) == Number(currItem4.measurement)){
                         currItem4.quantity_placed= Number(Number(currItem4.measurement))
                        } else{
                            currItem4.quantity_placed = (Number(Math.trunc(a)+1)* Number(currItem4.standardQtyPerBox))
                        }
                        currItem4.quantity_placed_kg = '';

            let discount1 = Number(currItem4.quantity_placed) * Number(currItem4.dlp) * (Number(currItem4.discount) / 100)
            let totaAmount = Number(currItem4.quantity_placed) * Number(currItem4.dlp) - Number(discount1)
            currItem4.grossamount = (Number(totaAmount)).toFixed(2);
                let schemediscount = ((Number(totaAmount) * Number(currItem4.discount1)) / 100)
                let NetAmount = (Number(totaAmount) - Number(schemediscount))
                currItem4.netAmount = (Number(NetAmount)).toFixed(2)
                // currItem1.NetAmount = parseInt(currItem1.quantity_placed_kg) * parseInt(currItem1.dlp)
                currItem4.gstvalue = ((Number(currItem4.netAmount)/Number(100))*(Number(currItem4.igst))).toFixed(2)
            currItem4.total = (Number(currItem4.gstvalue) + Number(currItem4.netAmount)).toFixed(2)
            data[itemIndex4] = currItem4

        } /* BOX Product End */
        else{
            if (action.data == "") {
                currItem4.discount1 = 0
            } else {
                currItem4.discount1 = Number(action.data)
            }

            // currItem1.dlp = parseInt(action.data)

            let a = (Number(currItem4.measurement)/Number(currItem4.standardQtyPerBox));
                    if( (Number(Math.trunc(a))* Number(currItem4.standardQtyPerBox)) == Number(currItem4.measurement)){
                     currItem4.quantity_placed= Number(Number(currItem4.measurement))
                    } else{
                        currItem4.quantity_placed = (Number(Math.trunc(a)+1)* Number(currItem4.standardQtyPerBox))
                    }
                    currItem4.quantity_placed_kg = (Number(currItem4.quantity_placed) * Number(currItem4.uomSecondary)).toFixed(2)

            let discount1 = Number(currItem4.quantity_placed_kg) * Number(currItem4.dlp) * (Number(currItem4.discount) / 100)
            let totaAmount = Number(currItem4.quantity_placed_kg) * Number(currItem4.dlp) - Number(discount1)
            currItem4.grossamount = (Number(totaAmount)).toFixed(2);
                let schemediscount = ((Number(totaAmount) * Number(currItem4.discount1)) / 100)
                let NetAmount = (Number(totaAmount) - Number(schemediscount))
                currItem4.netAmount = (Number(NetAmount)).toFixed(2)
                // currItem1.NetAmount = parseInt(currItem1.quantity_placed_kg) * parseInt(currItem1.dlp)
                currItem4.gstvalue = ((Number(currItem4.netAmount)/Number(100))*(Number(currItem4.igst))).toFixed(2)
            currItem4.total = (Number(currItem4.gstvalue) + Number(currItem4.netAmount)).toFixed(2)
            data[itemIndex4] = currItem4
        }
    } else {

        if (currItem4.unitofmeasurement == "box") {
            if (action.data == "") {
                currItem4.discount1 = 0
            } else {
                currItem4.discount1 = Number(action.data)
            }

            // currItem1.dlp = parseInt(action.data)
            
            let discount1 = Number(currItem4.sqpb) * Number(currItem4.dlp) * (Number(currItem4.discount) / 100);
            currItem4.quantity_placed_kg = '';
            let totaAmount = Number(currItem4.sqpb) * Number(currItem4.dlp) - Number(discount1)
            currItem4.grossamount = (Number(totaAmount)).toFixed(2) ;
                let schemediscount = ((Number(totaAmount) * Number(currItem4.discount1)) / 100)
                let NetAmount = (Number(totaAmount) - Number(schemediscount))
                currItem4.netAmount = (Number(NetAmount)).toFixed(2)
                // currItem1.NetAmount = parseInt(currItem1.measurement) * parseInt(currItem1.dlp)
                currItem4.gstvalue = ((Number(currItem4.netAmount)/Number(100))*(Number(currItem4.igst))).toFixed(2)
            currItem4.total = Number(currItem4.gstvalue) + Number(currItem4.netAmount)
            data[itemIndex4] = currItem4

        } else{
            if (action.data == "") {
                currItem4.discount1 = 0
            } else {
                currItem4.discount1 = Number(action.data)
            }

            // currItem1.dlp = parseInt(action.data)
            // console.log("mello",currItem4.uomSecondary)
            let qtydata = (Number(currItem4.sqpb) * Number(currItem4.uomSecondary))
            currItem4.quantity_placed_kg =Number(qtydata).toFixed(2);
            let discount1 = Number(qtydata) * Number(currItem4.dlp) * (Number(currItem4.discount) / 100)
            let totaAmount = Number(qtydata) * Number(currItem4.dlp) - Number(discount1)
            currItem4.grossamount = (Number(totaAmount)).toFixed(2) ;
                let schemediscount = ((Number(totaAmount) * Number(currItem4.discount1)) / 100)
                let NetAmount = (Number(totaAmount) - Number(schemediscount))
                currItem4.netAmount = (Number(NetAmount)).toFixed(2)
                // currItem1.NetAmount = parseInt(currItem1.qtydata) * parseInt(currItem1.dlp)
                currItem4.gstvalue = ((Number(currItem4.netAmount)/Number(100))*(Number(currItem4.igst))).toFixed(2)
            currItem4.total = Number(currItem4.gstvalue) + Number(currItem4.netAmount)
            data[itemIndex4] = currItem4
        }

    }

            return [...data];

            case CHANGE_TEXT_PRIMARYORDER_UPDATE:

            let newCart5 = [...data]
            let itemIndex5 = newCart5.findIndex(obj => obj.productId === action.item.productId)
            let currItem5 = data[itemIndex5]

            currItem5.text = action.data
            data[itemIndex5] = currItem5
            return [...data];

            case CHANGE_SQPBPCS_PRIMARYORDER_UPDATE:
                let newCart6 = [...data]
            let itemIndex6 = newCart6.findIndex(obj => obj.productId === action.item.productId)
            let currItem6 = data[itemIndex6]
            currItem6.sqpb = action.data
            
            if(currItem6.unitofmeasurement == "box"){
             if(currItem6.quantity_placed){

               currItem6.quantity_placed = action.data
            if( currItem6.uomSecondary == "" || currItem6.uomSecondary == "0"){

                if (currItem6.discount > 0 || currItem6.discount1 > 0) {

                    let discount2 = Number(currItem6.sqpb) * Number(currItem6.dlp) * (Number(currItem6.discount) / 100);
                    currItem6.quantity_placed_kg = '';
                    let totaAmount = Number(currItem6.sqpb) * Number(currItem6.dlp) - Number(discount2);
                    currItem6.grossamount = (Number(totaAmount)).toFixed(2);
                    let schemediscount = ((Number(totaAmount) * Number(currItem6.discount1)) / 100);
                    let NetAmount = (Number(totaAmount) - Number(schemediscount));
                    currItem6.netAmount= (Number(NetAmount)).toFixed(2)
                    currItem6.gstvalue = ((Number(currItem6.netAmount)/Number(100))*(Number(currItem6.igst))).toFixed(2)
                    currItem6.total = (Number(currItem6.gstvalue) + Number(currItem6.netAmount)).toFixed(2)
                    data[itemIndex6] = currItem6
                    
                } else {
                    currItem6.discount = Number(0);
                    currItem6.quantity_placed_kg = '';
                    currItem6.grossamount = (Number(currItem6.sqpb) * Number(currItem6.dlp)).toFixed(2);
                    let NetAmount = (Number(currItem6.sqpb) * Number(currItem6.dlp));
                    currItem6.netAmount = (Number(NetAmount)).toFixed(2);
                    currItem6.gstvalue = ((Number(currItem6.netAmount)/Number(100))*(Number(currItem6.igst))).toFixed(2)
                    currItem6.total = (Number(currItem6.gstvalue) + Number(currItem6.netAmount)).toFixed(2)
                    data[itemIndex6] = currItem6
                    // console.log("mello",totaAmount,NetAmount)
                }
            }
            else{
                if (currItem6.discount > 0 || currItem6.discount1 > 0) {
                    let qtydata = (Number(currItem6.sqpb) * Number(currItem6.uomSecondary))
                    currItem6.quantity_placed_kg ='';
                    let discount2 = Number(qtydata) * Number(currItem6.dlp) * (Number(currItem6.discount) / 100);
                    let totaAmount = Number(qtydata) * Number(currItem6.dlp) - Number(discount2);
                    currItem6.grossamount = (Number(totaAmount)).toFixed(2) ;
                    let schemediscount = ((Number(totaAmount) * Number(currItem6.discount1)) / 100);
                    let NetAmount = (Number(totaAmount) - Number(schemediscount));
                    currItem6.netAmount= (Number(NetAmount)).toFixed(2);
                    currItem6.gstvalue = ((Number(currItem6.netAmount)/Number(100))*(Number(currItem6.igst))).toFixed(2)
                    currItem6.total = (Number(currItem6.gstvalue) + Number(currItem6.netAmount)).toFixed(2)
                    data[itemIndex6] = currItem6
                    
                }  else {
                    let qtydata = (Number(currItem6.sqpb) * Number(currItem6.uomSecondary))
                    currItem6.quantity_placed_kg ='';
                    currItem6.discount = Number(0);
                    currItem6.grossamount = (Number(qtydata) * Number(currItem6.dlp)).toFixed(2);
                    let NetAmount = (Number(qtydata) * Number(currItem6.dlp));
                    currItem6.netAmount = (Number(NetAmount)).toFixed(2)
                    currItem6.gstvalue = ((Number(currItem6.netAmount)/Number(100))*(Number(currItem6.igst))).toFixed(2) 
                    currItem6.total = (Number(currItem6.gstvalue) + Number(currItem6.netAmount)).toFixed(2)
                    data[itemIndex6] = currItem6
                    // console.log("mello",totaAmount,NetAmount)
                }
            }
        }
    } /* Box Product End */
    else {
        if(currItem6.quantity_placed){

            currItem6.quantity_placed = action.data
         if( currItem6.uomSecondary == "" || currItem6.uomSecondary == "0"){

             if (currItem6.discount > 0 || currItem6.discount1 > 0) {

                 let discount2 = Number(currItem6.sqpb) * Number(currItem6.dlp) * (Number(currItem6.discount) / 100);
                 currItem6.quantity_placed_kg = currItem6.sqpb;
                 let totaAmount = Number(currItem6.sqpb) * Number(currItem6.dlp) - Number(discount2);
                 currItem6.grossamount = (Number(totaAmount)).toFixed(2) ;
                 let schemediscount = ((Number(totaAmount) * Number(currItem6.discount1)) / 100);
                 let NetAmount = (Number(totaAmount) - Number(schemediscount));
                 currItem6.netAmount= (Number(NetAmount)).toFixed(2)
                 currItem6.gstvalue = ((Number(currItem6.netAmount)/Number(100))*(Number(currItem6.igst))).toFixed(2)
                 currItem6.total = (Number(currItem6.gstvalue) + Number(currItem6.netAmount)).toFixed(2)
                 data[itemIndex6] = currItem6
                 
             } else {
                 currItem6.discount = Number(0);
                 currItem6.quantity_placed_kg = currItem6.sqpb;
                 currItem6.grossamount = (Number(currItem6.sqpb) * Number(currItem6.dlp)).toFixed(2);
                 let NetAmount = (Number(currItem6.sqpb) * Number(currItem6.dlp));
                 currItem6.netAmount = (Number(NetAmount)).toFixed(2);
                 currItem6.gstvalue = ((Number(currItem6.netAmount)/Number(100))*(Number(currItem6.igst))).toFixed(2)
                 currItem6.total = (Number(currItem6.gstvalue) + Number(currItem6.netAmount)).toFixed(2)
                 data[itemIndex6] = currItem6
                 // console.log("mello",totaAmount,NetAmount)
             }
         }
         else{
             if (currItem6.discount > 0 || currItem6.discount1 > 0) {
                 let qtydata = (Number(currItem6.sqpb) * Number(currItem6.uomSecondary))
                 currItem6.quantity_placed_kg =Number(qtydata).toFixed(2);
                 let discount2 = Number(qtydata) * Number(currItem6.dlp) * (Number(currItem6.discount) / 100);
                 let totaAmount = Number(qtydata) * Number(currItem6.dlp) - Number(discount2);
                 currItem6.grossamount = (Number(totaAmount)).toFixed(2) ;
                 let schemediscount = ((Number(totaAmount) * Number(currItem6.discount1)) / 100);
                 let NetAmount = (Number(totaAmount) - Number(schemediscount));
                 currItem6.netAmount= (Number(NetAmount)).toFixed(2);
                 currItem6.gstvalue = ((Number(currItem6.netAmount)/Number(100))*(Number(currItem6.igst))).toFixed(2)
                 currItem6.total = (Number(currItem6.gstvalue) + Number(currItem6.netAmount)).toFixed(2)
                 data[itemIndex6] = currItem6
                 
             }  else {
                 let qtydata = (Number(currItem6.sqpb) * Number(currItem6.uomSecondary))
                 currItem6.quantity_placed_kg =Number(qtydata).toFixed(2);
                 currItem6.discount = Number(0);
                 currItem6.grossamount = (Number(qtydata) * Number(currItem6.dlp)).toFixed(2);
                 let NetAmount = (Number(qtydata) * Number(currItem6.dlp));
                 currItem6.netAmount = (Number(NetAmount)).toFixed(2)
                 currItem6.gstvalue = ((Number(currItem6.netAmount)/Number(100))*(Number(currItem6.igst))).toFixed(2) 
                 currItem6.total = (Number(currItem6.gstvalue) + Number(currItem6.netAmount)).toFixed(2)
                 data[itemIndex6] = currItem6
                 // console.log("mello",totaAmount,NetAmount)
             }
         }
     }
    }


            return[...data]



        default:
            return data;
    }
}