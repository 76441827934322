import React, { useEffect, useState } from "react";
import WareHouseService from "../services/WareHouseService";
import { SalesExpenseComponent } from "./SalesExpenseComponent";


function SalesExpenseReports() {

  let header = [
    { label: "Product Name", field: "staffname" },
    { label: "Unit of Measurement", field: "createddate" },
    { label: "QTY(PCS)", field: "claimexpense" },
    { label: "QTY(KG)", field: "approvedexpense" },

  ];
  const sumofkeys = [{ first: "closingstock" }];
  return (
    <SalesExpenseComponent
      head={header}
      title={"Sales Expense Reports"}
      axiostabledata={""}
      Add={""}
      Create={""}
      Edit={""}
      View={""}
      Sumofkeys={sumofkeys}
    />
  );
}

export default SalesExpenseReports;
