import { SALES_RETURN_AAD_TO_CART } from "../constant";
import { SALES_RETURN_MEASUREMENT } from "../constant";
import { SALES_RETURN_PCS } from "../constant";
import { SALES_RETURN_QTY } from "../constant";
import { SALES_RETURN_COST } from "../constant";
import { SALES_RETURN_CALMEASUREMENT } from "../constant";
import { SALES_RETURN_DISCOUNT } from "../constant";
import { SALES_RETURN_REMOVE_FROM_CART } from "../constant";
import { SALES_RETURN_TEXT } from "../constant";
import { SALES_RETURN_EMPTY_DATA } from "../constant";

export const salesreturnaddtocart = (data, data2) => {

    return {
        type: SALES_RETURN_AAD_TO_CART,
        data: data,
        data2: data2
    }
}

export const salesreturnmeasurement = (data, item, data2) => {

    return {
        type: SALES_RETURN_MEASUREMENT,
        data: data,
        item: item,
        data2: data2
    }
}
export const salesreturnsqp = (data, item, data2, data3) => {

    return {
        type: SALES_RETURN_PCS,
        data: data,
        item: item,
        data2: data2,
        data3: data3
    }
}
export const salesreturnqty = (data, item, data2, data3) => {

    return {
        type: SALES_RETURN_QTY,
        data: data,
        item: item,
        data2: data2,
        data3: data3
    }
}
export const salesreturncost = (data, item, data2) => {

    return {
        type: SALES_RETURN_COST,
        data: data,
        item: item,
        data2: data2
    }
}
export const salesreturncalmeasurement = (data, item, data2 ) => {

    return{
        type: SALES_RETURN_CALMEASUREMENT,
        data: data,
        item: item,
        data2: data2
    }
}
export const salesreturndiscount = (data, item, data2) => {

    return {
        type: SALES_RETURN_DISCOUNT,
        data: data,
        item: item,
        data2: data2
    }
}
export const salesreturnremovefromcart = (data) => {

    return {
        type: SALES_RETURN_REMOVE_FROM_CART,
        data: data,
    }
}

export const salesreturntext = (data, item ) => {

    return {
        type: SALES_RETURN_TEXT,
        data: data,
        item: item,
        
    }
}

export const emptysalesreturndata = () => {

    return {

        type: SALES_RETURN_EMPTY_DATA,
    }

}