import { MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { TiArrowSortedUp } from "react-icons/ti";
import { TiArrowSortedDown } from "react-icons/ti"; 
import { CSVLink } from "react-csv";
import axios from "axios";
import authHeader from "../services/auth-header";
import Swal from "sweetalert2";
export default function New2({Addpage,Status, head,tdata,View,Edit,numberofpages,totalelemnts,totalsize,Export,pagination,name,addbutton,count,newentry,handlinks,sotreddata,searching}){
const[brandName,setbrandName]=useState('');
const [error,setError]=useState([]);
const [currentPage, setCurrentPage] = useState(1);
const[superid,setsuperid]=useState(0)
// 
const [message, setMessage] = useState([]);
const [message1, setMessage1] = useState([]);
const [backpage, setbackpage] = useState([])
const[sort,setsort]=useState(false)
const[data,setdata]=useState(10)
const navigate = useNavigate();

const { pointindex } = useParams();
console.log(pointindex)
let Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})
// console.log(prop.head);
// console.log(prop.text);
// console.log('headings:', head);
// console.log('text:',  head.map((head, index) =>text[head.field]),text)

const handlePageChange = (page) => {
  setCurrentPage(page);
  // console.log(page-1);
  setsuperid(page-1);
  pagination(page-1);
 };

useEffect(() => {
  
  // http://43.225.54.138:8080/scm/api/primaryworkorder/export/

  axios.get(`http://43.225.54.138:8080/scm/api/${Export}/export`, { headers: authHeader() })
  .then(response => {
     
// console.log(`http://43.225.54.138:8080/scm/api/product/page/${Number(getid)}/${Number(limit)}/${sort}/${fieldnme}`);
    console.log(response.data);
    if(tdata){
  
      
      setMessage1('Delayed data loading ');
      
    }else{
       setMessage1('');
    }
  if(response.status >= 200 && response.status < 300){
    setError(response.data)
  }
  })
  .catch(error => {
    console.error(error);
  });
 
}, [ ])

useEffect(() => {
  // setCurrentPage(Number(pointindex))
  // handlePageChange(Number(pointindex))
 
  const timeoutId = setTimeout(() => {
    setMessage('Delayed message after 2 seconds!');
  }, 1000);
  
  return () => clearTimeout(timeoutId);
  

}, [ ])





const searchhandle=(word)=>{
  searching(word)
}




 const handlechange=(entry)=>{
   const entryy=entry
   setdata(entry)
  //  alert("h")
   newentry(entry)
 }




//  useEffect(() => {
//   setdata(tdata)
// }, [ ]);

// const renderPageNumbers = () => {
//   const pages = [];
//   for (let i = 1; i <= numberofpages; i++) {
//     pages.push(
//       <li key={i} className={i === currentPage ? 'active' : ''} onClick={() => handlePageChange(i)}>
//         {i}
//       </li>
//     );
//   }
//   return pages;
// };
// const renderPageNumbers = () => {
//   const pages = [];
//   const maxPagesToShow = 3;  

//   for (let i = 1; i <= numberofpages; i++) {
//      if (i >= currentPage - 1 && i <= currentPage + 1) {
//       pages.push(
//         <li key={i} className={i === currentPage ? 'newway' : ''} onClick={() => handlePageChange(i)}>
//           {i}
//         </li>
//       );
//     }
//      else if (
//       (currentPage > 2 && i === 1) ||
//       (currentPage < numberofpages - 1 && i === numberofpages)
//     ) {
//       pages.push(
//         <li key={i} className="ellipsis">
//           {numberofpages-1}
//         </li>
//       );
//     }
//   }

//   return pages;
// };

const renderPageNumbers = () => {
  const pages = [];
  const maxPagesToShow = 3;
  let currentPagecpy=currentPage;
let yummu= currentPagecpy==backpage

 

  for (let i = Math.max(0, currentPage - 2); i <= Math.min(numberofpages - 1, currentPage + 1); i++) {
    pages.push(
      <li key={i} className={i === currentPage ? 'newway' : ''} onClick={() => handlePageChange(i)}>
        {i}
      </li>
    );
  }
  // pages+1
  return pages ;
};
 
const handle=(id)=>{
   handlinks([{"view":id}])
   
  //  console.log(object);
   if(id){
    // navigate(`/pedit/${id}`)
   }
}


const handlesort=(field)=>{
 setsort(!sort)
// console.log(sort?{"sort":"asc","field":field}:{"sort":"dsc","field":field})
sotreddata(sort?{"sort":"asc","field":field}:{"sort":"desc","field":field})


// console.log(field,sort);

}

// console.log( sort);


    // const bruce = tdata.map((item, rowIndex) => (
    //     <tr className="the"  >
    //       {head.map((item2) =>  
    //         // <td className="datatabletd" id={item2.field=="action"?"":item.id}  onClick={()=>handle(item2.field=="action"?"":item.id)}>{item[item2.field]}</td>
    //         <td className="datatabletd"    onClick={()=>{handle(item2.field=="action"?{"view":item.id}:item.id);   }}>{item2.field=="action"?item2.subaction1:item[item2.field]}</td>
    //     //     {if(item.action){
    //     //  return   <td className="datatabletd"     >{item[item2.field]}  </td>
    //     //   }
    //     // else{
    //     //   return   <td className="datatabletd"  id={item.id}   >{item[item2.field]}  </td>
    //     // }
        
        
      
    //      )}
    //     </tr>
    //   ));
    
    // navigate('/retailertable')
    const bruce = tdata.map((item, rowIndex) => (
      
      <tr className="the"  >
        {head.map((item2) =>  
          // <td className="datatabletd" id={item2.field=="action"?"":item.id}  onClick={()=>handle(item2.field=="action"?"":item.id)}>{item[item2.field]}</td>
          // <td className="datatabletd"    onClick={()=>{item2.field=="action"? navigate(`/view/${id}`: navigate(`/pedit/${id}`)  }}>{item2.field=="action"?item2.subaction1:item[item2.field]}</td>
          <td
          className="datatabletd"
          onClick={() => {
            item2.field === "action"
              ? navigate(`/${View}/${item.id}`)
              : navigate(`/${Edit}/${item.id}/${currentPage}`);
          }}
        >
          {item2.field === "action" ? item2.subaction1 : item[item2.field]}
        </td>
        
    
    
          //     {if(item.action){
      //  return   <td className="datatabletd"     >{item[item2.field]}  </td>
      //   }
      // else{
      //   return   <td className="datatabletd"  id={item.id}   >{item[item2.field]}  </td>
      // }
      
      
    
       )}
      </tr>
    ));






      // const handlesort = () => {
    


      //   setIsOpenhh(!isOpenhh);
      //    };





    
// const limit=count.map((item)=>{
 
//   <option>{item.value}</option>
   
// })
// console.log(text);

// console.log(count.map((item)=>item.value));


    return(
     <>
     {
     tdata.length==0||message.length==0?<div className="pilt">
<div class="loader"></div>
</div>:
<>
  <MDBCard className={`${message.length==0 ? 'displaynone' : '  '},${Export=="product"?"productfix":""}`} data-aos="fade" >    
  {/* <CSVLink data={datacsv} class="btn btn-sm btn-primary">Export</CSVLink>                                                                                                                                                                   */}
    <div className="card-header flex">
    <h1>{name} </h1>
  
<div className="flex">
<Link to={Addpage}><button className="btn btn-primary thh"> <span className="add">{addbutton}</span>  </button></Link> 
   { error.length!==0?   <CSVLink className="whitefont pk" data={error}>Export</CSVLink>  :"" }
</div>

    </div>
   
     <MDBCardBody >
   <div className="forentriesandaddbutton">
     <div className="bottole">


<p>Show entries  </p>
<select className="newselect" onChange={(e)=>handlechange(e.target.value)}>
{count.map((item)=>{
 
 return <option>{item.value}</option>
  
})}

</select>

     </div>
<div className="up2 ">
<input type="text" className="form-control" onChange={(e)=>searchhandle(e.target.value)}/>

</div>
       
   </div>
     <table className="table thrtable">
       
      <thead>
        
        <tr>
          {/* {head.map((head, index) => (
            <th onClick={()=>{handlesort( head.field=="action"?"id":head.field )}} key={index}>{head.label}</th>
          ))} */}

{head.map((head, index) => (
                    <th  onClick={()=>{handlesort( head.field=="action"?"id":head.field )}} key={index}>{head.label}{sort?<TiArrowSortedUp />:  <TiArrowSortedDown  className=""/>}</th>
          ))}
        </tr>
      </thead>
    
      <tbody  className={`  ${message1.length==0 ? ' shimmer  ' : '  '}`}>
    
      {/* {head.map((head, index) => (
    <td key={index}>{text[head.field]}</td>
))} */}
   {bruce}
      </tbody>
    </table>

    <div className="flex">
        <div className="showentri">
<p>Showing {superid*data+1} to {superid*data+10} of {totalsize} entries </p>
        </div>
        <div className="pagination">
           <ul className="pagination">
         
          
          {/* <li onClick={() => handlePageChange(currentPage - 1)}>{'Previous'}</li> */}
        
          {/* <li onClick={() => handlePageChange(currentPage - 1)}>
          {'Previous'}
        </li> */}
<li onClick={() => {handlePageChange(currentPage > 1 ? currentPage - 1 : 1);setbackpage(0)}}>
  {'Previous'}
</li>
        {renderPageNumbers()}
        {/* <li onClick={() => handlePageChange(currentPage + 1)}>{'Next'}</li> */}
        {numberofpages}
        <li onClick={() =>{ handlePageChange(currentPage==numberofpages?numberofpages:currentPage+1);setbackpage(0)}}>{'Next'}</li>
    
  </ul>

  {/* <button onClick={()=>timerbymp(superid)} >Check</button> */}
             </div>
    </div>

     </MDBCardBody>
</MDBCard>
</>}
     </>

    )
}