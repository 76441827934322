import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { BASE_URL } from '../Component/services/Api';
import axios from 'axios';
import authHeader from '../Component/services/auth-header';
import { Alert } from 'react-bootstrap';
import Swal from 'sweetalert2';

export const Arealaunchmobile = () => {
    const [show, setShow] = useState(false);
    const [file, setfile] = useState(null);
    const [data, setdata] = useState([]);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [loading, setLoading] = useState(false);

    const getItem = (id) => {

      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
  
          axios.delete( BASE_URL + 'area/delete/' + id, {
              headers:authHeader()
          }).then(res=>{
              window.location.reload();
          })
          
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          )
        }
      })
    };

    const handleSaveChanges = () => {
      setLoading(true);

        const formData = new FormData();
        // formData.append('productName', productName);
        // formData.append('shortName', shortName);
        formData.append('file', file);
    
        axios.post('http://43.225.54.138:8080/scm/api/area/upload', formData,{ headers: authHeader() })
          .then(response => {
            console.log('Product added successfully:', response.data);
            handleClose(); 
            Alert('Product added successfully:') 
            window.location.reload();
          }).catch((error) => {
            console.error(error);
          })
          .finally(() => {
            setLoading(false); 
          });
      };
      console.log(data)
   useEffect(() => {
     
    
        const formData = new FormData();
        // formData.append('productName', productName);
        // formData.append('shortName', shortName);
        formData.append('file', file);
    
        axios.get('http://43.225.54.138:8080/scm/api/area/get', { headers: authHeader() })
          .then(response => {
            setdata(response.data)
            console.log(  response.data)
          })
          .catch(error => {
            console.error('Error adding product:', error);
          });
 
   }, [ ])
    
  return (
    <div className='card'>
        <div className='card-header'>
    <h1>  Product of the month  </h1>
    <a className='btn btn-sm btn-primary' onClick={handleShow}> +  </a>

        </div>
{/* <a className='btn btn-sm btn-primary'>Create launch</a> */}


        <div className='card-body'>
<table className='table thrtable '>
    <tr>
        <th>Id</th>
        <th>Product of Months</th>
      
        {/* <th>Product Image</th> */}
        <th>Action</th>
    </tr>

    {
  data.map((item) => (
    <tr key={item.id}>
      <td>{item.id}</td>
      <td>{item.name}</td>
      {/* <td>{item.location}</td> */}
      <td onClick={()=>getItem(item.id)}>Delete</td>
    </tr>
  ))
}

</table>
        </div>
        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Product launch</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {/* <label className='form-label'>Product Name</label>
            <input type='text' className='form-control'/>
            <label className='form-label'>Product Short Name</label>
            <input type='text' className='form-control'/> */}
            <label className='form-label'>Product Image</label>
            <input type='file' onChange={e => setfile(e.target.files[0])} className='form-control'/>
        </Modal.Body>
        <Modal.Footer>
          <Button className='btn btn-sm btn-primary' onClick={handleClose}>
            Close
          </Button>
          <Button className='btn btn-sm btn-primary' onClick={()=>{handleClose();handleSaveChanges();}}>
            {/* Save Changes */}

            <p>{loading?"loading":"Submit"}</p>

          </Button>
        </Modal.Footer>
      </Modal>
        </div>
  )
}
