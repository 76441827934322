import React, { useEffect, useState } from "react";
import DistrbutorService from "../services/Distrbutor";
import { DistributorMonthReportComponents } from "./DistributorMonthReportComponents";
function DistributorMonthReport() {
  const [distributor, setDistributor] = useState("");

  useEffect(() => {
    DistrbutorService.getDistributorTable().then((response) => {
      
      setDistributor(
        response.data.map((user) => ({ value: user.id, label: user.tradeName }))
      );
    });
  }, []);

  let header = [
    { label: "Month", field: "month" },
    { label: "Order Qty Kgs", field: "order_qty_kg" },
    { label: "Order Qty Kgs / Pcs", field: "order_qty_kg_pcs" },
    { label: "Rate", field: "rate" },
    { label: "Order Value", field: "order_value" },
    { label: "Order Qty Pcs / Set", field: "order_qty_pcs_set" },
    { label: "Rate", field: "rate_set" },
    { label: "Order Value", field: "order_value" },
    { label: "Dispatch Qty Kgs", field: "dispatch_qty_kgs" },
    { label: "Dispatch Qty Kgs / Pcs", field: "dispatch_qty_pcs_set" },
    { label: "Rate", field: "rate_dispatch" },
    { label: "Dispatch Value", field: "dispatch_value" },
    { label: "Dispatch Qty Pcs / Set", field: "dispatch_qty_pcs_set" },
    { label: "Rate", field: "rate_dispatch_set" },
    { label: "Dispatch Value", field: "dispatch_value" },
    { label: "Achievement % Qty Kgs", field: "achievmnet_qty_kgs" },
    { label: "Achievement % Qty Kgs / Pcs", field: "achievmnet_qty_kgs_pcs" },
    { label: "Achievement % Value", field: "achievment_kg_value" },
    { label: "Achievement Qty Pcs/Set", field: "achievment_qty_pcs_set" },
    { label: "Achievement % Value", field: "achievment_set_value" },
  ];
  const sumofkeys = [{ first: "primaryorderqty" }];

  return (
    <DistributorMonthReportComponents
      head={header}
      title={"Distributor Month Report"}
      axiostabledata={"dist/report/get"}
      Add={" "}
      Create={" "}
      Edit={""}
      View={""}
      distributordata={distributor}
      Sumofkeys={sumofkeys}
    />
  );
}

export default DistributorMonthReport;
