import { PURCHASE_EDIT_GET_DATA_TO_LOAD } from "../constant";
import { PURCHASE_EDIT_ADD_TO_CART } from "../constant";
import { PURCHASE_EDIT_CHANGE_MEASUREMENT } from "../constant";
import { PURCHASE_EDIT_CHANGE_QTYPCS } from "../constant";
import { PURCHASE_EDIT_CHANGE_QTYKG } from "../constant";
import { CHANGE_COST_PURCHASE_EDIT } from "../constant";
import { PURCHASE_EDIT_CHANGE_CAL_MEASUREMENT } from "../constant";
import { CHANGE_PURCHASE_EDIT_DISCOUNT } from "../constant";
import { REMOVE_FROM_CART_FROM_PURCHASE_EDIT } from "../constant";
import { CHANGE_PURCHASE_EDIT_TEXT } from "../constant";
import { EMPTY_PURCHASE_EDIT_DATA } from "../constant";



export const getpurchaseeditdatatoload = (data) => {

    //  console.warn("action called", data);
    return {

        type : PURCHASE_EDIT_GET_DATA_TO_LOAD,
        data : data
    }

}


export const purchaseeditaddtocart = (data) => {

    //  console.warn("action called", data);
    return {

        type : PURCHASE_EDIT_ADD_TO_CART,
        data : data
    }

}



export const removefromcartfrompurchaseedit = (data) => {

    // console.warn("action REMOVE_TO_CART called", data);
    return {

        type : REMOVE_FROM_CART_FROM_PURCHASE_EDIT,
        data : data
    }

}

export const purchaseeditmeasurement = (data, item, data2) => {

    return {
        type: PURCHASE_EDIT_CHANGE_MEASUREMENT,
        data: data,
        item: item,
        data2: data2
    }
}


export const purchaseeditqtypcs = (data, item, data2) => {


    return {

        type: PURCHASE_EDIT_CHANGE_QTYPCS,
        data: data,
        item: item,
        data2: data2
    }

}

export const purchaseeditqtykgs = (data, item, data2) => {

    return {
        type: PURCHASE_EDIT_CHANGE_QTYKG,
        data: data,
        item: item,
        data2: data2

    }
}


export const changecostpurchaseedit = (data, item, data2) => {

    // console.warn("cost change called", data);
    return {

        type : CHANGE_COST_PURCHASE_EDIT,
        data : data,
        item : item,
        data2 : data2

    }

}

export const purchaseeditcalmeasurement = (data, item, data2 ) => {

    return{
        type: PURCHASE_EDIT_CHANGE_CAL_MEASUREMENT,
        data: data,
        item: item,
        data2: data2
    }
}

export const changepurchaseeditdiscount = (data, item, data2) => {

    // console.warn("discount change called", data);
    return {

        type : CHANGE_PURCHASE_EDIT_DISCOUNT,
        data : data,
        item : item,
        data2 : data2

    }

}


export const changepurchaseedittext = (data, item) => {

    // console.warn("text change called", data);
    return {

        type : CHANGE_PURCHASE_EDIT_TEXT,
        data : data,
        item : item
    }

}


export const emptypurchaseeditdata = () => {

    return {

        type : EMPTY_PURCHASE_EDIT_DATA,
    }

}