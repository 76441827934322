import axios from "axios";
import { MDBCard } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Accordion, Button, Col, Form,Modal, Row, Table } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from '../../services/Api';
import authHeader from "../../services/auth-header";
import Select from "react-select";
import ProductService from "../../services/ProductService";
import { useDispatch, useSelector } from "react-redux";
import { addprimaryorderproductupdate, changecostprimaryorderupdate, changediscountprimaryorderupdate, changeqtykgprimaryorderupdate, changeqtypcsprimaryorderupdate, changesqpbpcsprimaryorderupdate, changetextprimaryorderupdate, changeuomprimaryorderupdate, getprimarydataupdate, removefromcartprimaryorderupdate } from "../../../redux/Action/PrimaryOrderUpdate";
import Swal from "sweetalert2";
import { BsFillEyeFill } from "react-icons/bs";

const Update = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { pointindex } = useParams();
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);
    const [distributorData, setDistributorData] = useState('');
    const [distributor, setdistributor] = useState('');
    const [deliveryAddress, setDeliveryAddress] = useState([]);
    const [distributorAddress, setdistributorAddress] = useState('');
    const [dist_Address, setdist_Address] = useState([]);
    const [from_date, setfrom_date] = useState('');
    const [to_Date, setto_Date] = useState('');
    const [remarks, setremarks] = useState('');
    const [productdata, setproductdata] = useState([]);
    const [boxProductDiscount, setboxProductDiscount] = useState('');
    const [schemeboxProductDiscount, setschemeboxProductDiscount] = useState('');
    const [kgProductDiscount, setkgProductDiscount] = useState('');
    const [schemekgProductDiscount, setschemekgProductDiscount] = useState('');
    const [corporaetProductDiscount, setcorporaetProductDiscount] = useState('');
    const [schemecorporateProductDiscount, setschemecorporateProductDiscount] = useState('');
    const [cookerProductDiscount, setcookerProductDiscount] = useState('');
    const [schemecookerProductDiscount, setschemecookerProductDiscount] = useState('');
    const [noshProductDiscount, setnoshProductDiscount] = useState('');
    const [schemenoshProductDisocunt, setschemenoshProductDisocunt] = useState('');
    //////////////////////////////
    ///////////////////////////

    const [totalPcsQuantity, setTotalPcsQuantity] = useState(0);
    const [totalnet, settotalnet] = useState();
    const [grossfull, setgrossfull] = useState();
    const [gstvalue, setgstvalue] = useState();
    const [amount, setamount] = useState();
    const [kg, setkg] = useState();
    //////////////////
    const [sumbox, setsumbox] = useState(0);
    const [sumofcorp, setcorp] = useState(0);
    const [sumofcorpcs, setcorpc] = useState(0);
    const [boxamt, setboxsmt] = useState(0);
    const [amt, setamt] = useState(0);
    const [kg1, setkg1] = useState(0);
    const [kg2, setkg2] = useState(0);
    const [kg3, setkg3] = useState(0);

    const [c1, setc1] = useState(0);
    const [c2, setc2] = useState(0);
    const [c3, setc3] = useState(0);

    const [n1, setn1] = useState(0);
    const [n2, setn2] = useState(0);
    const [n3, setn3] = useState(0);

    const [isLoading, setIsLoading] = useState(false);
    const applyFontSizeTransition = (elementId) => {
      const element = document.getElementById(elementId);
      element.style.transition = 'font-size 2s';
      element.style.fontSize = '30px';
      element.style.fontWeight = '600';
      // font-weight: 600;
      setTimeout(() => {
         
        document.getElementById("typer1").classList.add('promote');
        element.style.fontSize = '24px';
      }, 1000);
    };
    const [productdata1, setProductdata1] = useState('');

    const[imagedisplay,setdiplay]=useState([])

    const productdetails = (e) => {
      ProductService.getProductEdit(e).then(res => {
        setProductdata1(res.data);
        setdiplay(res.data.productImages);

      })
    }
  
    const dispatch = useDispatch();
    const primaryOrderItems = useSelector((state) => state.primaryorderupdate);
    // console.log("mello",primaryOrderItems)
    // get primary order data

    //////////////////

    // console.log("mello",primaryOrderItems)
    const stdkgs = primaryOrderItems.length && primaryOrderItems.map(user => (Number(user.quantity_placed_kg))).reduce((prev, next) => (prev - (-next)));
    const stdpcs = primaryOrderItems.length && primaryOrderItems.map(user => (Number(user.quantity_placed))).reduce((prev, next) => (prev - (-next)));
    // console.log("mello",stdkgs)
    //  console.log("Product:",product);



    ////////////
    const getPrimaryOrderData = () => {

        axios.get(BASE_URL + 'primaryworkorder/getById/' + id, {
            headers: authHeader()
        }).then(res => {
            // console.log("mello", res.data);
            setDistributorData(res.data.distributor);
            setdistributor({ "id": res.data.distributor.id });
            setfrom_date(res.data.from_date);
            setto_Date(res.data.to_Date);
            setremarks(res.data.remarks);
            let disaddress = res.data.distributor.deliveryAddress;
            if (disaddress.length == 0) {
                setDeliveryAddress([]);
            } else {
                // setDeliveryAddress(disaddress.map(use => ({ value: use.id, label: use.delivery_address })));
                setDeliveryAddress(res.data.deliveryAddress);
            }
            // setdist_Address({ value: "1", label: res.data.retailerAddress });

            setdist_Address(res.data.distributor.deliveryAddress);


            setdistributorAddress(res.data.distributor.retailerAddress);
            setboxProductDiscount(res.data.distributor.boxProductDiscount);
            setschemeboxProductDiscount(res.data.distributor.schemeboxProductDiscount);
            setkgProductDiscount(res.data.distributor.kgProductDiscount);
            setschemekgProductDiscount(res.data.distributor.schemekgProductDiscount);
            setcorporaetProductDiscount(res.data.distributor.corporaetProductDiscount);
            setschemecorporateProductDiscount(res.data.distributor.schemecorporateProductDiscount);
            setcookerProductDiscount(res.data.distributor.cookerProductDiscount);
            setschemecookerProductDiscount(res.data.distributor.schemecookerProductDiscount);
            setnoshProductDiscount(res.data.distributor.noshProductDiscount);
            setschemenoshProductDisocunt(res.data.distributor.schemenoshProductDisocunt);
        })
    };

    /* Get Product Data */
    const getproductdata = () => {
        let product1 = [];
        ProductService.getProductAll().then(res => {
            (res.data).map(user => {
                product1.push(user.product)
                // console.log("mello",product1)
                setproductdata(product1.map(use => ({ value: use.id, label: use.productName + ' - ' + use.eanCode + ' - ' + use.shortName })));
            })
        })
    };

    const customFilter = (option, input) => {
        const escapedInput = input.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escape symbols
        const regex = new RegExp(escapedInput.replace(/\s+/g, '\\s*'), 'i');
        return (regex.test(option.label) ||
            regex.test(option.label.replace(/\s+/g, '')) ||
            regex.test(option.label.replace(/[^a-zA-Z0-9]/g, ''))
        )
    }
    // Distributor Address OnChange
    let getDistributorAddressChange = (e) => {
        setdistributorAddress(e.label);
        setdist_Address({ value: e.value, label: e.label });
    }

    useEffect(() => {
        getPrimaryOrderData();
        getproductdata();
        dispatch(getprimarydataupdate(id));
    }, []);

    const datevalue = new Date(from_date);
    const defaultValue = datevalue.toLocaleDateString('en-CA');
    const datevalue1 = new Date(from_date);
    const defaultValue1 = datevalue1.toLocaleDateString('en-CA');

    /* Send In Redux */
    const addData = (e) => {
        dispatch(addprimaryorderproductupdate(e.value, ({
            "schemeboxProductDiscount": schemeboxProductDiscount,
            "schemekgProductDiscount": schemekgProductDiscount,
            "schemecorporateProductDiscount": schemecorporateProductDiscount,
            "schemecookerProductDiscount": schemecookerProductDiscount,
            "schemenoshProductDisocunt": schemenoshProductDisocunt,
            "kgProductDiscount": kgProductDiscount,
            "boxProductDiscount": boxProductDiscount,
            "corporaetProductDiscount": corporaetProductDiscount,
            "cookerProductDiscount": cookerProductDiscount,
            "noshProductDiscount": noshProductDiscount
        })))
    };

    const submitData = () => {
        // alert(distributorAddress)
        setIsLoading(true); 
        // axios.put(BASE_URL + 'primaryworkorder/update/' + id, {
        //     distributor, distributorAddress, from_date, to_Date, remarks, primaryOrderItems
        // }).then(res => {
        //     console.log("mello", res.data);
        // })
        let kgProductTotalQtyKg = kg1;
        let kgProductTotalQtyPcs = kg2;
        let kgProductTotalprice = kg3;
        let boxProductTotalQtyPcs = sumbox;
        let boxProductTotalprice = boxamt;
        let corporateProductTotalQtyPcs = sumofcorp;
        let corporateProductTotalprice = amt;
        let cookerProductTotalQtyPcs = c1;
        let cookerProductTotalprice = c3;
        let noshProductTotalQtyPcs = n1;
        let noshProductTotalprice = n2;
        let totalQtyKg = kg;
        let totalQtyPcs = totalPcsQuantity;
        let grossTotal = amount;
        let netValue = totalnet;
        let taxAmount = gstvalue;
        let deliveryAddress=distributorAddress;

        let primaryorder = {
            distributor, from_date, to_Date, primaryOrderItems, remarks, kgProductTotalQtyKg,
            kgProductTotalQtyPcs,
            kgProductTotalprice,
            boxProductTotalQtyPcs,
            boxProductTotalprice,
            corporateProductTotalQtyPcs,
            corporateProductTotalprice,
            cookerProductTotalQtyPcs,
            cookerProductTotalprice,
            noshProductTotalQtyPcs,
            noshProductTotalprice,
            totalQtyKg,
            totalQtyPcs,
            grossTotal,
            netValue,
            taxAmount,
            deliveryAddress,
            distributorAddress:distributorAddress
          }
        axios.put(BASE_URL + 'primaryworkorder/update/' + id, primaryorder, { headers: authHeader() }).then(res => {
            console.log("mello", res.data);
            navigate("/Newpo/1");
        }).catch(error => {
            console.error('Error adding  :', error);
            if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `
                <div>
                  <p id="issueText">Why do I have this issue?</p>
                  <ul>
              
                  <li id="typer1">You might have an internet issue</li>
                  </ul>
                </div>
              `,
              didOpen: () => {
                applyFontSizeTransition('issueText');
              }
            });
          }
          }).finally(() => {
            setIsLoading(false); 
          });      
    }

    useEffect(() => {
        calculateSum()
    }, [primaryOrderItems]);

    const calculateSum = () => {
        console.log(primaryOrderItems)
        let prmy = primaryOrderItems.map((order) => {
            const measurement = parseFloat(order.measurement);
            return isNaN(measurement) || !isFinite(measurement) ? 0 : measurement;
        }).reduce((prev, curr) => prev + curr, 0);

        console.log(prmy);

        prmy = parseFloat(prmy.toFixed(2));
        setTotalPcsQuantity(prmy);
        ///////////////
        let totalnet = primaryOrderItems.map((order) => parseFloat(order.netAmount))
            .reduce((prev, curr) => prev + curr, 0);
        totalnet = parseFloat(totalnet.toFixed(2));
        settotalnet(totalnet)
        /////////////////////////////////
        let grossnet = primaryOrderItems.map((order) => parseFloat(order.grossamount))
            .reduce((prev, curr) => prev + curr, 0);

        grossnet = parseFloat(grossnet.toFixed(2));
        setgrossfull(grossnet)
        //////////////////////////////////////
        let gst = primaryOrderItems.map((order) => parseFloat(order.gstvalue))
            .reduce((prev, curr) => prev + curr, 0);
        let gt = parseFloat(gst.toFixed(2))
        setgstvalue(gt)
        ///////////////////////////////////////////
        let amt = primaryOrderItems.map((order) => parseFloat(order.total))
            .reduce((prev, curr) => prev + curr, 0);
        const tt = parseFloat(amt.toFixed(2))
        setamount(tt)
        /////////////////////////////////////
        let kg1 = primaryOrderItems
            .map((order) => {
                const parsedQty = parseFloat(order.qty);
                return isNaN(parsedQty) || !isFinite(parsedQty) ? 0 : parsedQty;
            })
            .reduce((prev, curr) => prev + curr, 0);

        const tt1 = parseFloat(kg1.toFixed(2));
        setkg(tt1);
        ///////////////////////////
        let amt12 = primaryOrderItems
            .map((order) => parseFloat(order.netAmount))
            .reduce((prev, curr) => prev + curr, 0);




        ///////box//////
        const kgCorporateProducts = primaryOrderItems.filter((order) => order.productType === "boxproduct")
        setsumbox(kgCorporateProducts.map((order) => parseFloat(order.measurement))
            .reduce((prev, curr) => prev + curr, 0))

        setboxsmt(primaryOrderItems.filter((order) => order.productType === "boxproduct").map((order) => parseFloat(order.total))
            .reduce((prev, curr) => prev + curr, 0))
        ////////corp/////////////////
        const sumkgCor = primaryOrderItems.filter((order) => order.productType === "corporateproduct")
        setcorp(sumkgCor.map((order) => parseFloat(order.measurement))
            .reduce((prev, curr) => prev + curr, 0))

        const sumpccor = primaryOrderItems.filter((order) => order.productType === "corporateproduct")
        setcorpc(sumpccor.map((order) => parseFloat(order.qty))
            .reduce((prev, curr) => prev + curr, 0))

        setamt(sumpccor.map((order) => parseFloat(order.total))
            .reduce((prev, curr) => prev + curr, 0))

        //////////kg/////////////

        const kgproductpc = primaryOrderItems.filter((order) => order.productType === "kgproduct")
        const time = kgproductpc.map((order) => parseFloat(order.qty))
            .reduce((prev, curr) => prev + curr, 0)
        const time2 = kgproductpc.map((order) => parseFloat(order.measurement))
            .reduce((prev, curr) => prev + curr, 0)
        const totalkgg = kgproductpc.map((order) => parseFloat(order.total))
            .reduce((prev, curr) => prev + curr, 0)
        setkg1(parseFloat(time).toFixed(2))
        setkg2(time2)
        setkg3(totalkgg)
        ////////////////cooker/////////////////
        const cooker = primaryOrderItems.filter((order) => order.productType === "cookerproduct")
        const pcs = cooker.map((order) => parseFloat(order.measurement))
            .reduce((prev, curr) => prev + curr, 0)
        setc1(pcs)
        const c3total = cooker.map((order) => parseFloat(order.total))
            .reduce((prev, curr) => prev + curr, 0)
        setc3(c3total)
        //////////////////nosh//////////////////////
        const noshing = primaryOrderItems.filter((order) => order.productType === "noshproduct")
        const nospcs = noshing.map((order) => {
            const measurement = parseFloat(order.measurement);
            return isNaN(measurement) || !isFinite(measurement) ? 0 : measurement;
        }).reduce((prev, curr) => prev + curr, 0);
        setn1(nospcs)
        const nospcs1 = noshing.map((order) => {
            const measurement1 = parseFloat(order.qty);
            return isNaN(measurement1) || !isFinite(measurement1) ? 0 : measurement1;
        }).reduce((prev, curr) => prev + curr, 0);
        setn3(nospcs1)

        const nospcs11 = noshing.map((order) => {
            const measurement1 = parseFloat(order.total);
            return isNaN(measurement1) || !isFinite(measurement1) ? 0 : measurement1;
        }).reduce((prev, curr) => prev + curr, 0);
        setn2(nospcs11)

        // const nostotal=noshing.map((order) => {parseFloat(order.total)})
        // .reduce((prev, curr) => prev + curr, 0)
        // setn2(nostotal)
        // console.log(nostotal)



    }





    return (
        <MDBCard style={{ overflow: "scroll" }}>
                      <Link className='prox btn' to={`/Newpo/${pointindex}`}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"/><polyline points="12 19 5 12 12 5"/></svg></Link>

            <div className='card-body'>
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Body>
                            <Row md={2}  >
                                <Col>
                                    <label class="form-label">Distributor Name:</label>
                                    <Form.Control type="text" value={distributorData.tradeName} readOnly />
                                </Col>
                                <Col>
                                    <label class="form-label">Distributor Address: </label>
                                    {/* <Select value={dist_Address} options={deliveryAddress} onChange={(e) => { getDistributorAddressChange(e) }} /> */}
                             <select className="form-select" value={deliveryAddress} onChange={(e)=>{setDeliveryAddress(e.target.value);setdistributorAddress(e.target.value)}} >
                                <option>Select select</option>
                                {dist_Address.length!== undefined ? 
    dist_Address.map((item) => (
        <option key={item.id} value={item.id}>{item.delivery_address}</option>
    ))
    : "NO address Attached to distributor"
}

                             </select>
                                </Col>
                                <Col>
                                    <label class="form-label">From Date: </label>
                                    <input type="date" class="form-control" value={defaultValue} disabled />
                                </Col>
                                <Col>
                                    <label class="form-label">To Date: </label>
                                    <input type="date" class="form-control" value={defaultValue1} readOnly />
                                </Col>

                            </Row>
                            <Row >
                                <Col>
                                    <label class="form-label">Remarks </label>
                                    <textarea class="form-control" value={remarks} onChange={(e) => setremarks(e.target.value)}></textarea>
                                </Col>
                            </Row>
                            <br></br>

                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <br></br>
                <Row>
                    <label class="form-label"><b>Add&nbsp;Product</b> </label>
                    <Select options={productdata} onChange={(e) => addData(e)} filterOption={customFilter} />
                </Row>
                <br></br>
                <Table striped responsive hover size="sm">
                    <thead className='p-3 mb-2 bg-success text-white'>
                        <tr>
                            <th style={{ width: "20%" }}>PRODUCT NAME</th>
                            <th style={{ width: "6%" }}>UOM</th>
                            <th style={{ width: "6%" }}>QTY (PCS)</th>
                            <th style={{ width: "6%" }}>QTY (KG)</th>
                            <th style={{ width: 'auto' }}>SQ</th>
                            <th style={{ width: "6%" }}>SQ Data</th>
                            <th style={{ width: "8%" }}>RATE (₹)</th>
                            <th style={{ width: "2%" }}>TRADE DISCOUNT %</th>
                            <th style={{ width: "8%" }}>GROSS AMOUNT (₹)</th>
                            <th style={{ width: "2%" }}>SCHEME DISCOUNT %</th>
                            <th style={{ width: "10%" }}>NET AMOUNT (₹)</th>
                            {/* <th>Per&nbsp;Unit&nbsp;Rate</th> */}
                            <th style={{ width: 'auto' }}>GST VALUE (₹)</th>
                            <th style={{ width: "10%" }}>TOTAL (₹)</th>
                            <th style={{ width: "1%" }}>View</th>
                            <th style={{ width: "1%" }}>Action</th>
                        </tr>
                    </thead>
                    <tbody style={{ overflow: "scroll" }}>
                        {primaryOrderItems.map(item =>
                            <tr key={item.productId} >
                                <td>
                                    <Form.Group as={Col} md="12" >
                                        {/* <Form.Control type="text" value={item.productName} size="sm" /> */}
                                        <textarea type="text"   className="form-control textcontrol" value={item.productName} size="sm" ></textarea>

                                    </Form.Group>
                                    <Form.Control as="textarea" rows={2} onChange={(e) => dispatch(changetextprimaryorderupdate(e.target.value, item))} className='form-control' />
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Select disabled={item.unitofmeasurement == "box" ? true : item.productType !== "kgproduct" ? true : false} value={item.unitofmeasurement} onChange={(e) => { dispatch(changeuomprimaryorderupdate(e.target.value, item, item.unitofmeasurement)); }} size="sm" >
                                            <option>{item.unitofmeasurement == "box" ? "PCS" : item.productType !== "kgproduct" ? 'PCS' : "Select"}</option>
                                            <option value="pcs">PCS</option>
                                            <option value="kg">KG</option>
                                        </Form.Select>
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min='0' onChange={(e) => { dispatch(changeqtypcsprimaryorderupdate(e.target.value, item, item.unitofmeasurement)); }} disabled={item.unitofmeasurement == "kg" ? true : false || item.unitofmeasurement == "" ? true : false} value={item.measurement} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min='0' onChange={(e) => { dispatch(changeqtykgprimaryorderupdate(e.target.value, item, item.unitofmeasurement)); }} disabled={item.unitofmeasurement == "pcs" || item.unitofmeasurement == "box" ? true : false || item.unitofmeasurement == "" ? true : false} value={item.unitofmeasurement == "box" ? "" : item.qty} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min='0' disabled={item.unitofmeasurement == "" ? true : false} onChange={(e) => { dispatch(changesqpbpcsprimaryorderupdate(e.target.value, item)); }} step={item.standardQtyPerBox} value={item.quantity_placed} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min='0' value={Number(item.quantity_placed_kg)} disabled={item.unitofmeasurement == "box" ? true : false || item.unitofmeasurement == "" ? true : false} size="sm" requied />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min='0' disabled={item.unitofmeasurement == "" ? true : false} onChange={(e) => { dispatch(changecostprimaryorderupdate(e.target.value, item, item.unitofmeasurement)); }} value={item.dlp} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min='0' disabled={item.unitofmeasurement == "" ? true : false} value={item.discount} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min='0' value={Number(item.grossamount)} size="sm" disabled={item.unitofmeasurement == "box" ? true : false || item.unitofmeasurement == "" ? true : false} requied />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min='0' disabled={item.unitofmeasurement == "" ? true : false} onChange={(e) => { dispatch(changediscountprimaryorderupdate(e.target.value, item, item.unitofmeasurement)) }} value={Number(item.discount1)} size="sm" requied />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min='0' disabled={item.unitofmeasurement == "" ? true : false} value={Number(item.netAmount)} size="sm" readOnly requied />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min='0' disabled={item.unitofmeasurement == "" ? true : false} value={Number(item.gstvalue)} size="sm" readOnly requied />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min='0' disabled={item.unitofmeasurement == "" ? true : false} value={Number(item.total)} size="sm" readOnly requied />
                                    </Form.Group>
                                </td>
                                <td>
                                <div onClick={(e) => { handleShow1(e); productdetails(item.productId) }} style={{ textAlign: 'center', cursor: 'pointer', padding: '4px 8px' }} > <BsFillEyeFill style={{ color: 'var(--bs-link-color)' }} /></div>
                  <Modal
                    show={show1}
                    onHide={handleClose1}
                    backdrop="static"
                    keyboard={false}
                    size='lg'
                  >
                    <Modal.Header closeButton style={{ background: 'white', color: 'white' }}>
                    <Modal.Title style={{color:"#164194"}}>Product Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    {imagedisplay.map((user,index) => (
                <img src={"http://43.225.54.138:8080/scm/Images/" + user.name} className="images" style={{ width: '200px', height: '150px' }} />
              ))}
                      <Row className="mb-1">
                        <Form.Group as={Col} md="12" controlId="formBasicName">
                          <span style={{ fontSize: '18px', fontWeight: 600 }}>Product Name : - {productdata1.productName}</span>

                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>Short Name : - {productdata1.shortName}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>Ean Code No : - {productdata1.eanCode}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>Standard Qty Per Box : - {productdata1.standardQtyPerBox}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>Groups : - {productdata1.productGroup}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>Category : - {productdata1.category}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>UOM Primary : - {productdata1.uomPrimary}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>UOM Secondary : - {productdata1.uomSecondary}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>DLP(₹) : - {productdata1.dlp}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>MRP(₹) : - {productdata1.mrp}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>Capacity(ml) : - {productdata1.capacity}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>Diameter : - {productdata1.diameter}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>HSN Code : - {productdata1.hsnCode}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>Brand Name : - {productdata1.brand ? "Neelam" : ''}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>Product Type : - {productdata1.productType}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>IGST% : - {productdata1.igst}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>CGST% : - {productdata1.cgst}</span>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="formBasicName">
                          <span style={{ fontSize: '15px', fontWeight: 600 }}>SGST% : - {productdata1.sgst}</span>
                        </Form.Group>
                      </Row>
                    </Modal.Body>
                  </Modal>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type='button' className='btn btn-danger' value="X" onClick={() => dispatch(removefromcartprimaryorderupdate(item.productId))} size="sm" />
                                    </Form.Group>
                                </td>
                            </tr>
                        )}
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            {/* <td> <span className="cname">Gross&nbsp;amount&nbsp;<b>₹</b></span></td>
                <td>
                  <Form.Group as={Col} md="12">
                    <Form.Control type="text" value={grossAmount} size="sm" />
                  </Form.Group>
                </td> */}
                            <td></td>
                        </tr>
                        {primaryOrderItems.length !== 0 &&
                            (
                                <tr>
                                    <td className="bold"></td>
                                    <td></td>
                                    <th className="bold">{totalPcsQuantity}</th>
                                    <th className="bold">{kg ? kg.toFixed(2) : ''}</th>
                                    <td>{stdpcs ? stdpcs : ''}</td>
                                    <td>{stdkgs ? stdkgs : ''}</td>
                                    <td></td>
                                    <td></td>
                                    <th className="bold">{grossfull ? grossfull.toFixed(2) : ''}</th>
                                    <td></td>
                                    <th className="bold">{totalnet ? totalnet.toFixed(2) : ''}</th>
                                    <th className="bold">{gstvalue ? gstvalue.toFixed(2) : ''}</th>
                                    <th className="bold">{amount ? amount.toFixed(2) : ''}</th>

                                    <th></th>
                                    <th></th>
                                    {/* <td>Total Amount</td> */}
                                </tr>
                            )}


                    </tbody>
                </Table>
                <table>
                    <tr>
                        {primaryOrderItems.length !== 0 && (
                            <>
                                <tr>
                                    <th className='namee'>Summary</th>
                                    <th className='namee'> Total Qty(KG)</th>
                                    <th className='namee'>Total Qty(Pcs)</th>
                                    <th className='namee'>Total price</th>
                                </tr>
                                <tr>
                                    <th>Box Product</th>
                                    <th></th>
                                    <th>{sumbox}</th>
                                    <th>{boxamt.toFixed(2)}</th>
                                </tr>
                                <tr>
                                    <th>KG Product</th>
                                    <th>{kg1}</th>
                                    <th>{kg2}</th>
                                    <th>{kg3.toFixed(2)}</th>
                                </tr>
                                <tr>
                                    <th>Corporate Product</th>
                                    <th></th>
                                    <th>{sumofcorp}</th>
                                    <th>{amt.toFixed(2)}</th>
                                </tr>
                                <tr>
                                    <th>Cooker Product</th>
                                    <th></th>
                                    <th>{c1}</th>
                                    <th>{c3.toFixed(2)}</th>
                                </tr>
                                <tr>
                                    <th>Nosh Product</th>
                                    <th>{n3}</th>
                                    <th>{n1}</th>
                                    <th>{n2.toFixed(2)}</th>
                                </tr>
                                <tr>
                                    <th>Total</th>
                                    <th>{kg ? kg.toFixed(2) : ''}</th>
                                    <th>{totalPcsQuantity}</th>
                                    <th>{amount?amount.toFixed(2):''}</th>
                                </tr>
                            </>
                        )}
                    </tr>



                </table>

                <br></br>
                <Button variant="primary" style={{width:'auto'}} onClick={submitData} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Submit'}
        </Button>
            </div>

        </MDBCard>
    )
}

export default Update;