import { PO_GET_EDIT_DATA_TO_LOAD } from "../constant";
import { PO_EDIT_ADD_TO_CART } from "../constant";
import { PO_EDIT_CHANGE_MEASUREMENT } from "../constant";
import { PO_EDIT_CHANGE_QTYPCS } from "../constant";
import { PO_EDIT_CHANGE_QTYKG } from "../constant";
import { CHANGE_COST_PO_EDIT } from "../constant";
import { PO_EDIT_CHANGE_CAL_MEASUREMENT } from "../constant";
import { CHANGE_PO_EDIT_DISCOUNT1 } from "../constant";
import { REMOVE_FROM_CART_FROM_PO_EDIT } from "../constant";
import { CHANGE_PO_EDIT_TEXT } from "../constant";
import { EMPTY_PO_EDIT_DATA } from "../constant";



export const getpoeditdatatoload = (data) => {

    //  console.warn("action called", data);
    return {

        type : PO_GET_EDIT_DATA_TO_LOAD,
        data : data
    }

}


export const poeditaddtocart = (data) => {

    //  console.warn("action called", data);
    return {

        type : PO_EDIT_ADD_TO_CART,
        data : data
    }

}



export const removefromcartfrompoedit = (data) => {

    // console.warn("action REMOVE_TO_CART called", data);
    return {

        type : REMOVE_FROM_CART_FROM_PO_EDIT,
        data : data
    }

}

export const poeditchangemeasurement = (data, item, data2) => {

    return {
        type: PO_EDIT_CHANGE_MEASUREMENT,
        data: data,
        item: item,
        data2: data2
    }
}

export const poeditchangeqtypcs = (data, item, data2) => {


    return {

        type: PO_EDIT_CHANGE_QTYPCS,
        data: data,
        item: item,
        data2: data2
    }

}

export const poeditchangeqtykgs = (data, item, data2) => {

    return {
        type: PO_EDIT_CHANGE_QTYKG,
        data: data,
        item: item,
        data2: data2

    }
}

export const changecostpoedit = (data, item, data2) => {

    // console.warn("cost change called", data);
    return {

        type : CHANGE_COST_PO_EDIT,
        data : data,
        item : item,
        data2 : data2

    }

}

export const poeditchangecalmeasurement = (data, item, data2 ) => {

    return{
        type:PO_EDIT_CHANGE_CAL_MEASUREMENT,
        data: data,
        item: item,
        data2: data2
    }
}

export const changepoeditdiscount = (data, item, data2) => {

    // console.warn("discount change called", data);
    return {

        type : CHANGE_PO_EDIT_DISCOUNT1,
        data : data,
        item : item,
        data2 : data2

    }

}


export const changepoedittext = (data, item) => {

    // console.warn("text change called", data);
    return {

        type : CHANGE_PO_EDIT_TEXT,
        data : data,
        item : item
    }

}


export const emptypoeditdata = () => {

    return {

        type : EMPTY_PO_EDIT_DATA,
    }

}