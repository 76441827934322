import axios from "axios";
import { MDBCard } from "mdbreact";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../services/Api";
import authHeader from "../../services/auth-header";
import { Button, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { useNavigate } from "react-router-dom";

export default function Losecreate() {
    const navigate = useNavigate();

    const [productAll, setProductAll] = useState([]);
    const [BrandAll, setBrandAll] = useState([]);
    const [brand, setbrand] = useState(''); 
    const [pcs, setpcs] = useState("")
    const [qty, setqty] = useState("")
    const [product, setproduct] = useState('');
    const [productname, setproductname] = useState('');
    const [size, setsize] = useState('');
    const [data, setData] = useState('');
    const [status, setstatus] = useState(false);
const[remarks,setremarks]=useState([])
    /* Get Brand data */
    const getbranddata = () => {
        axios.get(BASE_URL + 'brand/getAll', {
            headers: authHeader()
        }).then(res => {
            setBrandAll(res.data.map(user => ({ value: user.id, label: user.brandName })))
        })
    }

    useEffect(() => {
        getbranddata();
    }, []);

    const onChangeBrand = (e) => {
        let name = e.value;
console.log(brand,product,remarks)
        setbrand(e.value);
        axios.get(BASE_URL + `loosepacking/brand/${ e.value}`, { headers: authHeader() })
        .then(response => {
            console.log(response)
            if (response.data.data == undefined) {
                setProductAll(response.data.map(use => ({ value: use.id, label: use.product_name })));
            } if (response.data.Index == undefined) {
                setProductAll(response.data.map(use => ({ value: use.id, label: use.product_name })));

            }

        })
        /* Product Get all */
        // axios.get(BASE_URL + 'product/productsByBrand/' + name, {
        //     headers: authHeader()
        // }).then(res => {
            // loosepacking/ brand /id 

        //     // console.log('mello',res.data)
        //     setProductAll(res.data.map(use => ({ value: use.id, label: use.productName })));
        // })

    }



    const [disword, setdisword] = useState(encodeURIComponent(' '))
    const [dis, setdistributordata1] = useState([])

    // useEffect(() => {
    //    if(brand){
      
    //         }    
    // }, [ brand])








    const onChangeProduct = (e) => {
        let name = e.value;
        setproduct(e.value);

    };

    const submitdata = () => {
        const requestData = {
            product: { id: product },
            brand: { id: brand },
            qty:qty,
            pcs:pcs,
            // remarks: remarks,
            
        };
        console.log(requestData)
         axios.post(`${BASE_URL}loosepacking/`, requestData, {
            headers: authHeader()
        }).then(res => {
            navigate(`/Loseprint/${res.data.id}`)

            if (res.data !== null) {
                // "Loseprint"
                console.log(res.data)
               console.log(res.status)
            }
        }).catch(error => {
             console.error('Error making POST request:', error);
        });
    }

    const [printingdata, setprintingdata] = useState([]);
    const getsizedata = (e) => {
        let name = e;
        let aa = [];
        for (var i = 0; i < name; i++) {
            aa.push(i)
        }
        setprintingdata(aa)
    }

    const Print1 = () => {

        //console.log('print');  

        if(status === true){

            let printContents = document.getElementById('Print').innerHTML;
            let originalContents = document.body.innerHTML;
            document.body.innerHTML = printContents;
            window.print();
            document.body.innerHTML = originalContents;
        }


    }
    // useEffect(()=>{
    //       Print1()
    // },[status]);
    
    return (
        <MDBCard className="company">
            <h1>Loose packing</h1>
            <Form className="product1">
                <Row className="mb-1">
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Brand Name</Form.Label>
                        <Select options={BrandAll} onChange={(e) => onChangeBrand(e)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Product Name</Form.Label>
                        <Select options={productAll} onChange={(e) => onChangeProduct(e)} />
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Pcs</Form.Label>
                     <input type="number" className="form-control" onChange={(e)=>setpcs(e.target.value)}/>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Qty</Form.Label>
                     <input type="number" className="form-control" onChange={(e)=>setqty(e.target.value)}/>
                    </Form.Group>
                   

                    {/* <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Remarks
                        </Form.Label>
                      <textarea onChange={(e) => setremarks(e.target.value)}  className="form-control" >

                      </textarea>
                    </Form.Group> */}
                </Row>
                <Button variant="primary" type="button" onClick={submitdata}>
                    Submit
                </Button>
              
            </Form>
        </MDBCard>
    )
}