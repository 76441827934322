import React, { useEffect } from "react";
import { useState } from "react";
import { Row, Form, Col, Button } from "react-bootstrap";
import Select from "react-select";
import UserService from "../../services/user.service";
import ReatilerService from "../../services/Reatiler";
import { useNavigate } from "react-router-dom";
import DistrbutorService from "../../services/Distrbutor";
import { MDBCard } from "mdbreact";

export default function Create1() {

    const [tradeName, settradeName] = useState('');
    const [transporterName, settransporterName] = useState('');
    const [billingAddress, setbillingAddress] = useState('');
    const [deliveryAddress, setdeliveryAddress] = useState('');
    const [gstNumber, setgstNumber] = useState('');
    const [panNumber, setpanNumber] = useState('');
    const [countryoptions, setCountryoptions] = useState({ value:"101", label: "India" });
    const [statedata, setStatedata] = useState([]);
    const [city, setcity] = useState('');
    const [gstType, setgstType] = useState('register');
    const [pinCode, setpinCode] = useState('');
    const [perMobileNumber, setperMobileNumber] = useState('');
    const [alterMobileNumber, setalterMobileNumber] = useState('');
    const [perEmail, setperEmail] = useState('');
    const [alterEmail, setalterEmail] = useState('');
    const [creditLimit, setcreditLimit] = useState('');
    const [creditDays, setcreditDays] = useState('');
    const [deliveryLocation, setdeliveryLocation] = useState('')
    const [boxProductDiscount, setboxProductDiscount] = useState('');
    const [schemeboxProductDiscount, setschemeboxProductDiscount] = useState('0');
    const [kgProductDiscount, setkgProductDiscount] = useState('');
    const [schemekgProductDiscount, setschemekgProductDiscount] = useState('0');
    const [corporaetProductDiscount, setcorporaetProductDiscount] = useState('');
    const [schemecorporateProductDiscount, setschemecorporateProductDiscount] = useState('0');
    const [cookerProductDiscount, setcookerProductDiscount] = useState('');
    const [schemecookerProductDiscount, setschemecookerProductDiscount] = useState('0');
    const [noshProductDiscount, setnoshProductDiscount] = useState('');
    const [schemenoshProductDisocunt, setschemenoshProductDisocunt] = useState('0');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('');
    const [state, setState] = useState('');
    const [distributorName1, setdistributorName1] = useState('');
    const [distributorName2, setdistributorName2] = useState('');
    const [dob1, setdob1] = useState('');
    const [dob2, setdob2] = useState('');
    const [doa1, setdoa1] = useState('');
    const [doa2, setdoa2] = useState('');
    const [mobNo1, setmobNo1] = useState('');
    const [mobNo2, setmobNo2] = useState('');
    const [email1, setemail1] = useState('');
    const [email2, setemail2] = useState('');
    const [aseid, setaseid] = useState('');
    const [asmid, setasmid] = useState('');
    const [rsmid, setrsmid] = useState('');
    const [nsmid, setnsmid] = useState(0);
    const [zonesdata, setzonesdata] = useState([]);
    const [zonesvalue, setzonesvalue] = useState([]);
    const [rsm, setRsm] = useState([]);
    const [nsm, setNsm] = useState([]);
    const [zonesid, setzonesid] = useState('');
    const [stateid, setstateid] = useState('');
    const [asm, setasm] = useState([]);
    const [ase, setase] = useState([]);

    /* Navigator */
    const navigate = useNavigate();

    useEffect(() => {

        /* Zone data */
        UserService.getZoneData().then(res => {

            let zone = res.data;
            setzonesdata(zone.map(user => ({ value: user.id, label: user.zoneName })))
            setzonesvalue(res.data);

        });

        /* NSM Data */
        UserService.getNSMData().then(res => {
            setNsm((res.data).map(user => ({ value: user.id, label: user.staffName })))

        });

    }, []);

    /* Zone Change */
    const getstatecodedata = (e) => {

        let name = e.value;

        setzonesid(name);

        /* State Data */
        zonesvalue.map(user => {
            if (user.id == name) {
               
                setStatedata((user.state_zone).map(item => ({ value: item.id, label: item.state_name })))
            }
        });

        /* RSM Data */
        UserService.getRSMData(e.value).then(res => {
            setRsm((res.data).map(user => ({ value: user.id, label: user.staffName })))
        });

    };

    /* State Change */
    const onSelect = (e) => {
        setstateid(e.value);

    };

    /* NSM Change */
    const onNsm = (e) => {
        setnsmid(e.value);
    };

    /* RSM Change */
    const onRsm = (e) => {

        let name = e.value;
        setrsmid(e.value);

        /* ASM Data */
        UserService.getASMData(name).then(res => {
            setasm((res.data).map(user => ({ value: user.id, label: user.staffName })))
        });

    };
    
    /* ASM Change */
    const onAsm = (e) => {
        setasmid(e.value);

        /* ASE Data */
        UserService.getASEData(e.value).then(res=>{
            setase((res.data).map(user=>({value:user.id,label:user.staffName})))
        });

    };
   
    /* ASE Change */
    const onAse = (e) => {
        setaseid(e.value)
    };

     /* Check Box same as billing Address */
    const[sameDeliveryAdd,setsameDeliveryAdd]=useState('');
   const[isChecked,setisChecked]=useState(false);
    const onCheck = (e) => {
        setsameDeliveryAdd(e);
        setisChecked(!isChecked);
    }
    
    const SubmitForm = () => {
console.log('dwe')
        const country = countryoptions.value;
        let roles = [{ "id": role }]

        const reatiler = {
            tradeName,
            transporterName,
            billingAddress,
            deliveryAddress,
            gstNumber,
            panNumber,
            country,
            zonesid,
            stateid,
            city,
            gstType,
            pinCode,
            perMobileNumber,
            alterMobileNumber,
            perEmail,
            alterEmail,
            creditLimit,
            creditDays,
            deliveryLocation,
            boxProductDiscount,
            schemeboxProductDiscount,
            kgProductDiscount,
            schemekgProductDiscount,
            corporaetProductDiscount,
            schemecorporateProductDiscount,
            cookerProductDiscount,
            schemecookerProductDiscount,
            noshProductDiscount,
            schemenoshProductDisocunt,
            distributorName1,
            distributorName2,
            dob1,
            dob2,
            doa1,
            doa2,
            mobNo1,
            mobNo2,
            email1,
            email2,
            aseid,
            asmid,
            rsmid,
            nsmid,
            password,
            roles,

        }

        DistrbutorService.getReatilerCreate(reatiler).then(res => {
            navigate('/Newdis/1')
        })
    }

    return (
        <MDBCard>
            <h1>ADD DISTRIBUTOR</h1>
            <Form className='product1'>
                <Row className="mb-1">
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Trade Name</Form.Label>
                        <Form.Control type="text" autoComplete="off" onChange={(e) => settradeName(e.target.value)} placeholder="Enter Trade Name" />
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Billing Address</Form.Label>
                        <Form.Control type="text" autoComplete="off" disabled={isChecked == true?true:false} onChange={(e) => setbillingAddress(e.target.value)} placeholder="Enter Billing Address" />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Delivery Address</Form.Label>
                        <Form.Check label="Same as Billing Address"  onClick={()=>onCheck(billingAddress)}/>
                        <Form.Control type="text" autoComplete="off" disabled={isChecked == true?true:false} value={isChecked == true?sameDeliveryAdd:''} onChange={(e) => setdeliveryAddress(e.target.value)} placeholder="Enter DeliveryAddress" />
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">GST No</Form.Label>
                        <Form.Control type="text" autoComplete="off" onChange={(e) => setgstNumber(e.target.value)} placeholder="Enter GST NO" />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">PAN Number</Form.Label>
                        <Form.Control type="text" autoComplete="off" onChange={(e) => setpanNumber(e.target.value)} placeholder="Enter PAN Number" />
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Country</Form.Label>
                        <Select value={countryoptions} />

                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Zone</Form.Label>
                        <Select options={zonesdata} onChange={(e) => { getstatecodedata(e) }} />

                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">State</Form.Label>
                        <Select options={statedata} onChange={(e) => { onSelect(e) }} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">City</Form.Label>
                        <Form.Control type="text" autoComplete="off" onChange={(e) => setcity(e.target.value)} placeholder="Enter City"/>
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Zip Code</Form.Label>
                        <Form.Control type="number" autoComplete="off" onChange={(e) => setpinCode(e.target.value)} placeholder="Enter Zip Code"/>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">GST Type</Form.Label>
                        <Form.Select value={gstType} onChange={(e) => setgstType(e.target.value)}>
                            <option>Select</option>
                            <option value='unregister'>UnRegister</option>
                            <option value='register'>Register</option>
                            <option value='composition'>Composition</option>
                            <option value='consumer'>Consumer</option>
                        </Form.Select>
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">MobileNumber</Form.Label>
                        <Form.Control type="number" autoComplete="off" onChange={(e) => setperMobileNumber(e.target.value)} placeholder="Enter Product Name" />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Alter Mobile Number</Form.Label>
                        <Form.Control type="number" autoComplete="off" onChange={(e) => setalterMobileNumber(e.target.value)} placeholder="Enter Product Name" />
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Email</Form.Label>
                        <Form.Control type="email" autoComplete="off" onChange={(e) => setperEmail(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Alter Email</Form.Label>
                        <Form.Control type="email" autoComplete="off" onChange={(e) => setalterEmail(e.target.value)} />
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Credit Limit</Form.Label>
                        <Form.Control type="number" autoComplete="off" onChange={(e) => setcreditLimit(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Credit Days</Form.Label>
                        <Form.Control type="number" autoComplete="off" onChange={(e) => setcreditDays(e.target.value)} />
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">TransporterName</Form.Label>
                        <Form.Control type="text" autoComplete="off" onChange={(e) => settransporterName(e.target.value)} placeholder="Enter TransporterName" />
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Delivery Location</Form.Label>
                        <Form.Control type="text" autoComplete="off" onChange={(e) => setdeliveryLocation(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="formBasicName">
                        <Form.Label className="cname2">Box&nbsp;Product&nbsp;Trade&nbsp;Discount</Form.Label>
                        <Form.Control type="number" autoComplete="off" onChange={(e) => setboxProductDiscount(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="formBasicName">
                        <Form.Label className="cname2">Box&nbsp;Product&nbsp;Scheme&nbsp;Discount</Form.Label>
                        <Form.Control type="number" autoComplete="off" onChange={(e) => setschemeboxProductDiscount(e.target.value)} />
                    </Form.Group>


                    <Form.Group as={Col} md="3" controlId="formBasicName">
                        <Form.Label className="cname2">KG&nbsp;Product&nbsp;Trade&nbsp;Discount</Form.Label>
                        <Form.Control type="number" autoComplete="off" onChange={(e) => setkgProductDiscount(e.target.value)} />
                    </Form.Group>

                    <Form.Group as={Col} md="3" controlId="formBasicName">
                        <Form.Label className="cname2">KG&nbsp;Product&nbsp;Scheme&nbsp;Discount</Form.Label>
                        <Form.Control type="number" autoComplete="off" onChange={(e) => setschemekgProductDiscount(e.target.value)} />
                    </Form.Group>

                    <Form.Group as={Col} md="3" controlId="formBasicName">
                        <Form.Label className="cname2">Corporate&nbsp;Product&nbsp;Trade&nbsp;Discount</Form.Label>
                        <Form.Control type="number" autoComplete="off" onChange={(e) => setcorporaetProductDiscount(e.target.value)} />
                    </Form.Group>

                    <Form.Group as={Col} md="3" controlId="formBasicName">
                        <Form.Label className="cname2">Corporate&nbsp;Product&nbsp;Scheme&nbsp;Discount</Form.Label>
                        <Form.Control type="number" autoComplete="off" onChange={(e) => setschemecorporateProductDiscount(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="formBasicName">
                        <Form.Label className="cname2">Cooker&nbsp;Product&nbsp;Trade&nbsp;Discount</Form.Label>
                        <Form.Control type="number" autoComplete="off" onChange={(e) => setcookerProductDiscount(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="formBasicName">
                        <Form.Label className="cname2">Cooker&nbsp;Product&nbsp;Scheme&nbsp;Discount</Form.Label>
                        <Form.Control type="number" autoComplete="off" onChange={(e) => setschemecookerProductDiscount(e.target.value)} />
                    </Form.Group>

                    <Form.Group as={Col} md="3" controlId="formBasicName">
                        <Form.Label className="cname2">NOSH&nbsp;Product&nbsp;Trade&nbsp;Discount</Form.Label>
                        <Form.Control type="number" autoComplete="off" onChange={(e) => setnoshProductDiscount(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="formBasicName">
                        <Form.Label className="cname2">NOSH&nbsp;Product&nbsp;Scheme&nbsp;Discount</Form.Label>
                        <Form.Control type="number" autoComplete="off" onChange={(e) => setschemenoshProductDisocunt(e.target.value)} />
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">DistrbutorName&nbsp;1</Form.Label>
                        <Form.Control type="text" autoComplete="off" onChange={(e) => setdistributorName1(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">DistrbutorName&nbsp;2</Form.Label>
                        <Form.Control type="text" autoComplete="off" onChange={(e) => setdistributorName2(e.target.value)} />
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Date&nbsp;of&nbsp;Brith&nbsp;1</Form.Label>
                        <Form.Control type="date" onChange={(e) => setdob1(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Date&nbsp;of&nbsp;Brith&nbsp;2</Form.Label>
                        <Form.Control type="date" onChange={(e) => setdob2(e.target.value)} />
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Date&nbsp;of&nbsp;Anniversary&nbsp;1</Form.Label>
                        <Form.Control type="date" onChange={(e) => setdoa1(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Date&nbsp;of&nbsp;Anniversary&nbsp;2</Form.Label>
                        <Form.Control type="date" onChange={(e) => setdoa2(e.target.value)} />
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Mobile No.&nbsp;1</Form.Label>
                        <Form.Control type="number" autoComplete="off" onChange={(e) => setmobNo1(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Mobile No.&nbsp;2</Form.Label>
                        <Form.Control type="number" autoComplete="off" onChange={(e) => setmobNo2(e.target.value)} />
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Email&nbsp;1</Form.Label>
                        <Form.Control type="email" autoComplete="off" onChange={(e) => setemail1(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Email&nbsp;2</Form.Label>
                        <Form.Control type="email" autoComplete="off" onChange={(e) => setemail2(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">NSM</Form.Label>
                        <Select options={nsm} onChange={(e) => { onNsm(e) }} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">RSM</Form.Label>
                        <Select options={rsm} onChange={(e) => { onRsm(e) }} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">ASM</Form.Label>
                        <Select options={asm} onChange={(e)=>{onAsm(e)}}/>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">ASE</Form.Label>
                        <Select options={ase} onChange={(e)=>{onAse(e)}}/>
                    </Form.Group>
                    
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Password</Form.Label>
                        <Form.Control type="text" autoComplete="off" onChange={(e) => setPassword(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Roles</Form.Label>
                        <Form.Select onChange={(e) => setRole(e.target.value)}>
                            <option>Select</option>
                            <option value='8'>Distributor</option>
                        </Form.Select>
                    </Form.Group>
                </Row>

                <Button variant="primary" type="button" onClick={()=>SubmitForm()} >
                    Submit
                </Button>
            </Form>
        </MDBCard>
    )
}