import axios from "axios";
import { MDBCard } from "mdbreact";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { BASE_URL } from "../services/Api";
import authHeader from "../services/auth-header";
import ProductService from "../services/ProductService";
import { useEffect } from "react";
import { Accordion, Button, Col, Form, Row, Table } from "react-bootstrap";
import Select from "react-select";
import { changecostpurchasereturn, changepurchasereturndiscount, changepurchasereturnqty, changepurchasereturntext, emptypurchasereturndata, purchasereturnaddtocart, purchasereturncalmeasurement, purchasereturnmeasurement, purchasereturnqtykgs, purchasereturnqtypcs, removefromcartfrompurchasereturn } from "../../redux/Action/PurchaseReturn";
import Swal from "sweetalert2";
import CompanyService from "../services/CompanyService";

export default function Create() {

    const [warehousehdata, setwarehousehdata] = useState([]);
    const [warehousehvalue, setwarehousehvalue] = useState([]);
    const [warehouse, setwarehouse] = useState('');
    const [supplierAll, setSupplierAll] = useState([]);
    const [supplierAllData, setSupplierAllData] = useState([]);
    const [supplier, setSupplier] = useState('');
    const [suppliervalue, setSuppliervalue] = useState([]);
    const [contactnamedata, setcontactnamedata] = useState([]);
    const [supplierDetails, setsupplierDetails] = useState('');
    const [subContacts, setsubContacts] = useState('');
    const [contactdetails, setcontactdetails] = useState('');
    const [supTyp, setsupTyp] = useState('');
    const [no, setno] = useState('');
    const [transId, settransId] = useState('');
    const [transName, settransName] = useState('');
    const [transDocDt1, settransDocDt1] = useState('');
    const [transDocNo, settransDocNo] = useState('');
    const [dt1, setdt1] = useState('');
    const [vehNo, setvehNo] = useState('');
    const [vehType, setvehType] = useState('');
    const [setsellindata, setSetsellindata] = useState([])
    const handleChange = (e) => setSetsellindata({ ...setsellindata, [e.target.name]: e.target.value });
    const handleChange1 = (e) => setbuyer({ ...buyer, [e.target.name]: e.target.value });
    const [seller, setseller] = useState({});
    const [buyer, setbuyer] = useState({});
    const [distance, setdistance] = useState('');
    const modes1=[
        { "id": 1, "label": "Road" },
        { "id": 2, "label": "Rail" },
        { "id": 3, "label": "Air" },
        { "id": 4, "label": "Ship" },
        { "id": 5, "label": "In Transit" }
      ]
      const [transMode, settransMode] = useState('');
      const[data11,setdata11]=useState([])
      const [disword11, setdisword11] = useState(encodeURIComponent(' '))
  const [distributordata111, setdistributordata111] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}transporter/page/0/200/asc/id/${disword11.length ? disword11 : encodeURIComponent(' ')}`,
          { headers: authHeader() }
        );
  
        let options = [];
        if (response.data.Index) {
          setdata11(response.data.Index)
          options = response.data.Index.map(item => ({
            value: item.id,
            label: item.transporter_Gstin,
          }));
        } else if (response.data.data) {
          setdata11(response.data.data)
          options = response.data.data.map(item => ({
            value: item.id,
            label: item.transporter_Gstin,
          }));
        } else {
          console.warn('Unexpected response structure:', response.data);
        }
  
        // Add a placeholder option at the beginning
        const optionsWithPlaceholder = [
          // { value: '', label: 'Select an option' }, // Placeholder option
          ...options,
        ];
  
        setdistributordata111(optionsWithPlaceholder);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, [disword11]);
  const handletrans=(e)=>{
    settransId(e.label)
    console.log(e)
    console.log((data11.filter((item)=>item.id==e.value).map((item)=>item.transporter_Name))
    )
    settransName((data11.filter((item)=>item.id==e.value).map((item)=>item.transporter_Name))[0])
    } 
      const vechiletype=[
        { "id": "R", "label": "Regular" },
        { "id": "O", "label": "ODC" }
      ]
    const [shippingaddress, setshippingaddress] = useState('');
    const [remarks, setremarks] = useState('');
    const [productdata, setproductdata] = useState([]);
    const [type, settype] = useState('b2b');
    const [status, setstatus] = useState('true');
    const [taxtype, settaxtype] = useState("withtax");
    const [purchasereturndate, setpurchasereturndate] = useState('');
    const [debitnoteno, setdebitnoteno] = useState('');
    const [ewaybillno, setewaybillno] = useState('');
    const [originalinvoiceno, setoriginalinvoiceno] = useState('');
    const [originalinvoicenodate, setoriginalinvoicenodate] = useState('');
    const [otherrefernce, setotherrefernce] = useState('');
    const [branchdata, setbranchdata] = useState([]);
    const [branch, setbranch] = useState('');
    const [bstateid, setbstateid] = useState('');
    const [sstateid, setsstateid] = useState('');
    const [shippingcharge, setShipping] = useState(0);
    const [shippingfortax,setshippingfortax] = useState('');
    const [round, setRound] = useState(0);
    const [paymentTerms, setpaymentTerms] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const applyFontSizeTransition = (elementId) => {
      const element = document.getElementById(elementId);
      element.style.transition = 'font-size 2s';
      element.style.fontSize = '30px';
      element.style.fontWeight = '600';
      // font-weight: 600;
      setTimeout(() => {
         
        document.getElementById("typer1").classList.add('promote');
        element.style.fontSize = '24px';
      }, 1000);
    };

    const dispatch = useDispatch();
    const purchaseReturnItems = useSelector((state) => state.purchasereturndata);
    let net_Amount = purchaseReturnItems.length && purchaseReturnItems.map(item => Math.round(Number(item.total))).reduce((prev, next) => Math.round(prev - (-next)));
    let gst = purchaseReturnItems.length && purchaseReturnItems.map(item => (item.gstvalue)).reduce((prev, next) => (prev - (-next)));
    let cgstVal = purchaseReturnItems.length && purchaseReturnItems.map(item => (item.gstvalue)).reduce((prev, next) => (prev - (-next)));
let completetotalwithoutshipping=  purchaseReturnItems.length && purchaseReturnItems.map(item => Math.round(Number(item.amount))).reduce((prev, next) => Math.round(prev - (-next)));
console.log(purchaseReturnItems)



    let aa = [];
    purchaseReturnItems.map(user=>{
        let igst_value = user.igst;
        let total_value = user.total;
        let total_amou = ((Number(shippingcharge)/Number(net_Amount))* Number(total_value));
        let add_amou = Number(total_amou) - (-Number(total_value));
        let final_amou = ((Number(add_amou)/100)*Number(igst_value)).toFixed(2); 
        aa.push(final_amou)

        // console.log("mello",((Number(add_amou)/100)*Number(igst_value)).reduce((prev, next) => Math.round(prev - (-next))) );
    })
    let total_per = aa.length && aa.map(user=>user).reduce((prev, next) => (prev - (-next)));
  

    let netAmount = Number(net_Amount) + Number(shippingcharge);
    let grandTotal = Number(net_Amount)  + Number(shippingcharge) + Number(round) + Number(total_per);

    let igstTax = total_per == ""?Number(gst): Number(total_per);


    let cgstTax = total_per == ""?(Number(gst)/2):(Number(total_per)) / 2;
    let sgstTax = total_per == ""?(Number(gst)/2):(Number(total_per)) / 2;
    const navigate = useNavigate();
    let da_ta = purchaseReturnItems.length && purchaseReturnItems.map((user) => ({
        "brand": user.brand,
        "calunitofmeasurement": user.calunitofmeasurement,
        "capacity": user.capacity,
        "category": user.category,
        "cgst": user.cgst,
        // "cgstLedger": user.cgstLedger,
        "diameter": user.diameter,
        "dlp": user.dlp,
        "eanCode": user.eanCode,
        "grossamount": user.grossamount,
        "gstvalue": user.gstvalue,
        "hsnCode": user.hsnCode,
        "igst": user.igst,
        // "igstLedger": user.igstLedger,
        "netAmount": user.total,
        "product": { "id": user.product.id },
        "productName": user.productName,
        "productType": user.productType,
        "purquantity": user.purquantity,
        "purquantitykgs": user.purquantitykgs,
        // "schemeDiscount": user.schemeDiscount,
        "sgst": user.sgst,
        "sgstLedger": user.sgstLedger,
        "shortName": user.shortName,
        // "slNo": user.soid,
        "standardQtyPerBox": user.standardQtyPerBox,
        "total": user.total,
        "tradeDiscount": user.tradeDiscount,
        "unitofmeasurement": user.unitofmeasurement,
        "uomPrimary": user.uomPrimary,
        "uomSecondary": user.uomSecondary,
        // "SlNo":user.soid,
        "prdDesc": user.PrdDesc,
        "isServc": "N",
        "qty": user.purquantitykgs,
        "freeQty": user.purquantity,
        "unit": user.unitofmeasurement,
        "totAmt":Number(user.grossamount),
        "discount": ((Number(user.grossamount) / 100) * Number(user.tradeDiscount)).toFixed(2),
        "assAmt": user.total,
        "gstRt": user.igst,
        "igstAmt": seller.stcd === buyer.stcd ? 0 : Number(user.gstvalue),
        "cgstAmt": seller.stcd === buyer.stcd ? ((user.gstvalue) / 2) : 0,
        "sgstAmt": seller.stcd === buyer.stcd ? ((user.gstvalue) / 2) : 0,
        "totItemVal": user.amount


    }));
    
console.log(da_ta)
    function roundData(e){
        if(e){
            let net_Amount = purchaseReturnItems.length && purchaseReturnItems.map(item => Math.round(Number(item.total))).reduce((prev, next) => Math.round(prev - (-next)));
            let gst = purchaseReturnItems.length && purchaseReturnItems.map(item => (item.gstvalue)).reduce((prev, next) => (prev - (-next)));
            // console.log("mello", purchaseReturnItems);
            let aa = [];
            purchaseReturnItems.map(user=>{
                let igst_value = user.igst;
                let total_value = user.total;
                let total_amou = ((Number(shippingcharge)/Number(net_Amount))* Number(total_value));
                let add_amou = Number(total_amou) - (-Number(total_value));
                let final_amou = ((Number(add_amou)/100)*Number(igst_value)).toFixed(2); 
                aa.push(final_amou)
        
                // console.log("mello",((Number(add_amou)/100)*Number(igst_value)).reduce((prev, next) => Math.round(prev - (-next))) );
            })
            let total_per = aa.length && aa.map(user=>user).reduce((prev, next) => (prev - (-next)));
          
        
            let netAmount = Number(net_Amount) + Number(shippingcharge);
            let grandTotal = Number(net_Amount)  + Number(shippingcharge) + Number(total_per);
      
             let grand_Total = Math.ceil(grandTotal);
        
             if(grand_Total > grandTotal){
                 let rou_nd = (Number(grand_Total) - Number(grandTotal)).toFixed(2);
                 setRound(rou_nd);
             } else{
                 setRound(0)
             }
               
              
        } else if(e==''){
            setRound(0)
        }
    }
    const handlesuptype=(e)=>{
        setsupTyp(e.id)
     }
    /* Get Branch Data */
    const stype = [
        { "id": "B2B", "label": "Business to Business" },
        { "id": "SEZWP", "label": "SEZ with payment" },
        { "id": "SEZWOP", "label": "SEZ without payment" },
        { "id": "EXPWP", "label": "Export with Payment" },
        { "id": "EXPWOP", "label": "Export without payment" },
        { "id": "DEXP", "label": "Deemed Export" }
    ]
    const getbranchdata = () => {

        axios.get(BASE_URL + 'branch/getAll', {
            headers: authHeader()
        }).then(res => {
            // console.log("mello",res.data)
            setbranchdata((res.data).map(user => ({ value: user.id, label: user.branchname })));
        })
    };
    console.log("mello", purchaseReturnItems);

    /* get Supplier */
    const getSupplierdata = () => {

        axios.get(BASE_URL + 'supplier/getAll', {
            headers: authHeader()
        }).then(res => {
            setSupplierAll((res.data).map(user => ({ value: user.id, label: user.companyname })));
            setSupplierAllData(res.data);
        })
    };

    /* Get Product Data */
    const getproductdata = () => {
        axios.get( BASE_URL + 'product/page/0/100/asc/id/%20',{
            headers:authHeader()
        }).then(res=>{

            setproductdata((res.data.Index).map(use => ({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code + ' - ' + use.short_Name })));
        })
    };

    const customFilter = (e) => {
        let name = e;
        axios.get( BASE_URL + 'product/page/0/100/asc/id/'+name,{
            headers:authHeader()
        }).then(res=>{
         setproductdata((res.data.data).map(use=>({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code + ' - ' + use.short_Name })))
        })
    }

    /* Current Date */
    const datevalue = new Date();
    const defaultValue = datevalue.toLocaleDateString('en-CA');

    useEffect(() => {
        getbranchdata();
        getSupplierdata();
        getproductdata();
        getcompanydata();
        setpurchasereturndate(defaultValue);
        dispatch(emptypurchasereturndata());
    }, []);

    /* Supplier Company Change */

    const onChangeSupplier = (e) => {
        setSupplier({ "id": e.value });
        setSuppliervalue({ value: e.value, label: e.label })
        const selectedUser = supplierAllData.find(user => user.id === e.value);

        if (selectedUser) {
             console.log(selectedUser);
    
             setcontactnamedata(selectedUser.supplierSubContacts);
            setsupplierDetails(selectedUser);
            setsstateid(selectedUser.states.id);
    
             setbuyer({
                gstin: selectedUser.gstno,
                loc: selectedUser.cities,
                pos: selectedUser.statecode,
                stcd: selectedUser.states.id,
                addr1: selectedUser.address,
                lglNm: selectedUser.suppliername,
                pin: selectedUser.zipcode,
                ph: selectedUser.phonenumber,
                em: selectedUser.email
            });
        } else {
            // Handle the case where no user is found
            console.warn('No user found with id:', e.value);
        }
        
    };

    const onChnagecontactname = (e) => {
        // console.log("mello",e)
        setsubContacts(e)
        axios.get(BASE_URL + 'suppliersubcontacts/' + e, {
            headers: authHeader()
        }).then(res => {
            setcontactdetails(res.data);

        })
    };

    let onChangeBranch = (e) => {
        let name = e.value;
        setbranch({ "id": e.value });
        setwarehousehvalue("");

        axios.get(BASE_URL + 'branch/' + name, {
            headers: authHeader()
        }).then(res => {
            // console.log("mello",res.data)
            setbstateid(res.data.states.id)
        })

        axios.get(BASE_URL + 'warehouse/getAll', {
            headers: authHeader()
        }).then(res => {
            let branch_data = [];
            (res.data).map(user => {
                // let branchid = user.branch.id;
                if (user.branch !== null) {
                    if (user.branch.id === e.value) {
                        branch_data.push({ value: user.id, label: user.name })
                    }
                }

            })

            setwarehousehdata(branch_data)

        })
    };
    const onChangewarehouse = (e) => {
        setwarehouse({ "id": e.value })
        setwarehousehvalue({ value: e.value, label: e.label })
    }

    /* Send In Redux */
    const addData = (e) => {
        const filteredItems = purchaseReturnItems.filter(item => item.id === e.value);
        if(branch == "" || supplier == ""){
            Swal.fire({
                title: 'Please Branch or Supplier First Select',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonColor: 'red'
            })
        }else{
        if (filteredItems.length == "0") {
            dispatch(purchasereturnaddtocart(e.value));
            setShipping("0");
        } else {
            Swal.fire({
                title: 'This Product is Already Added',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonColor: 'red'
            })
        }
    }
    }

    /* get Shipping Charges */
    let settingship = (e) => {
        if (e == "") {

            setShipping("");
        } else {

            setShipping(e);
        }

    };

    /* get Round Value */
    const settinground = (e) => {

        if (e == "") {

            setRound("");
        } else {


            setRound(e);
        }

    };

    const SGSTcheck = () => {
        let aa = 0

        if (taxtype == "withtax") {

            if (bstateid === sstateid) {

                aa = sgstTax;

            } else {
                aa = 0;
            }

        } else {
            aa = 0;
        }

        return aa;

    }

    const cgstcheck = () => {

        let bb = 0

        if (taxtype == "withtax") {

            if (bstateid === sstateid) {

                bb = cgstTax;

            } else {

                bb = 0;

            }

        } else {

            bb = 0;

        }
        return bb;
    }

    const igstcheck = () => {
        let cc = 0;

        if (taxtype == "withtax") {
            if (bstateid === sstateid) {
                cc = 0;
            } else {
                cc = igstTax;
            }
        } else {
            cc = 0;
        }
        return cc;
    }
    let getcompanydata = () => {
        const id = 1;
        CompanyService.getcompany(id).then((res) => {
            const transformedData = {
                "gstin": res.data.gst,
                "lglNm": res.data.name,
                "addr1": res.data.address,
                "loc": res.data.cities,
                "pin": parseInt(res.data.zipcode),
                "stcd": res.data.states.state_code,
                "ph": res.data.phone,
                "em": res.data.email
            };
            setSetsellindata(transformedData)
            console.log("mello", [transformedData].map((item) => item.gstin));
        });
    };



    const submitdata = () => {
        const tranDtls={supTyp}
        let docDtls={"dt":dt1,"no":no}
        let valDtls = { "assVal": net_Amount, "cgstVal": Number(seller.stcd) === Number(buyer.stcd) ? (cgstVal / 2) : 0, "sgstVal": seller.stcd === buyer.stcd ? (cgstVal / 2) : 0, "igstVal": seller.stcd === buyer.stcd ? 0 : cgstVal, "othChrg": shippingcharge, "totInvVal": completetotalwithoutshipping, "cesVal": 0, "stCesVal": 0 }

        setIsLoading(true); 
        let supplierSubContacts 
        if(subContacts !== ''){
            supplierSubContacts = { "id": Number(subContacts) }
        }
        let igst = igstcheck();
        let cgst = cgstcheck();
        let sgst = SGSTcheck();
        let grandtotal = grandTotal;
        let roundvalue = round;

        const prsubmitdata = {
            warehouse, supplier, debitnoteno, ewaybillno, originalinvoiceno, originalinvoicenodate, otherrefernce, purchasereturndate,
            status, shippingaddress, remarks, supplierSubContacts, purchaseReturnItems:da_ta, taxtype,
            igst, cgst, sgst, grandtotal,valDtls:valDtls,tranDtls,docDtls,sellerDtls:setsellindata,buyerDtls:buyer, netAmount, shippingcharge, roundvalue, branch, paymentTerms
        };

        axios.post(BASE_URL + 'pr/', prsubmitdata, {
            headers: authHeader()
        }).then(res => {
            navigate('/PurchaseReturnTable/1')
        }).catch(error => {
            console.error('Error adding  :', error);
            if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `
                <div>
                  <p id="issueText">Why do I have this issue?</p>
                  <ul>
              
                  <li id="typer1">You might have an internet issue</li>
                  </ul>
                </div>
              `,
              didOpen: () => {
                applyFontSizeTransition('issueText');
              }
            });
          }
          }).finally(() => {
            setIsLoading(false); 
          });
        dispatch(emptypurchasereturndata())
    }
    const [disword, setdisword] = useState(encodeURIComponent(' '))
    const [dis,setdistributordata1]=useState([])
    
    useEffect(() => {
    
      axios.get(`http://43.225.54.138:8080/scm/api/supplier/page/0/10/asc/id/${disword.length?disword:encodeURIComponent(' ')}`, { headers: authHeader() })
      .then(response => {
    console.log(response)
    if(response.data.data==undefined){
      setdistributordata1(response.data.Index.map(use => ({ value: use.id, label: use.companyname})));
   }  if(response.data.Index==undefined){
    setdistributordata1(response.data.data.map(use => ({ value: use.id, label: use.companyname})));
  
   }
      
   })
  
  
  
  }, [ disword])
 
 
    return (
        <MDBCard className="company">
            <h1>Create Purchase Return </h1>
            {/* PurchaseReturnTable */}
            <div className='p-2 w-100 bd-highlight'><Link to={`/PurchaseReturnTable/1`}><button class="btn btn-sm btn-primary">back</button></Link></div>

            <Form className="product1">
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Purchase Data</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Branch Name</Form.Label>
                                    <Select options={branchdata} onChange={(e) => onChangeBranch(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">WareHouse Name</Form.Label>
                                    <Select value={warehousehvalue} options={warehousehdata} onChange={(e) => onChangewarehouse(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Supplier Company</Form.Label>
                                    {/* <Select value={suppliervalue} options={supplierAll} onChange={(e) => onChangeSupplier(e)} /> */}

                                    <Select options={dis}  onInputChange={(inputValue) => setdisword(inputValue)} onChange={(options) => onChangeSupplier(options)}/>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Contact Name</Form.Label>
                                    <Form.Select onChange={(e) => { onChnagecontactname(e.target.value) }}>
                                        <option>--Select--</option>
                                        {contactnamedata.map(user =>
                                            <option key={user.id} value={user.id}>{user.contactname}</option>
                                        )
                                        }
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Date</Form.Label>
                                    <Form.Control type="date" value={purchasereturndate} onChange={(e) => setpurchasereturndate(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Terms of Payment</Form.Label>
                                    <Form.Control type='text'  name="paymentTerms" onChange={(e) => setpaymentTerms(e.target.value)} autoComplete="off" />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label"> Purchase Return Note No</Form.Label>
                                    <Form.Control type="text" onChange={(e) => setdebitnoteno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Ewaybill No</Form.Label>
                                    <Form.Control type="text" onChange={(e) => setewaybillno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Original Invoice No</Form.Label>
                                    <Form.Control type="text" onChange={(e) => setoriginalinvoiceno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Original Invoice No Date</Form.Label>
                                    <Form.Control type="date" onChange={(e) => setoriginalinvoicenodate(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Other Reference</Form.Label>
                                    <Form.Control type="text" onChange={(e) => setotherrefernce(e.target.value)} />
                                </Form.Group>
                                {/* <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Type</Form.Label>
                                    <Form.Select onChange={(e) => settype(e.target.value)}>
                                        <option value="b2b">B2B</option>
                                        <option value="b2c">B2C</option>
                                    </Form.Select>
                                </Form.Group> */}
                                {/* <Form.Group as={Col} md="8" controlId="formBasicName">
                                    <Form.Label className="label">Owner</Form.Label>
                                    <Form.Control type="text" autoComplete="off" />
                                </Form.Group> */}
                                {/* <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Status</Form.Label>
                                    <Form.Select onChange={(e) => setstatus(e.target.value)}>
                                        <option value='true'>Open</option>
                                    </Form.Select>
                                </Form.Group> */}
                                {/* <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Tax Type</Form.Label>
                                    <Form.Select value={taxtype} onChange={(e) => settaxtype(e.target.value)} disabled>
                                        <option>--Select--</option>
                                        <option value='withtax'>With Tax</option>
                                        <option value='withouttax'>Without Tax</option>
                                    </Form.Select>
                                </Form.Group> */}
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    {/* <Accordion.Item eventKey="1">
                        <Accordion.Header>Billing Address</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Supplier Address</Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Supplier Details</Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Address : -</Form.Label>
                                    <span>&nbsp;{supplierDetails.address}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Name : -</Form.Label>
                                    <span>&nbsp;{contactdetails == "" ? supplierDetails.suppliername : contactdetails.contactname}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">City : -</Form.Label>
                                    <span>&nbsp;{supplierDetails.cities}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Phone No : -</Form.Label>
                                    <span>&nbsp;{contactdetails == "" ? supplierDetails.phonenumber : contactdetails.phoneno}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Country : -</Form.Label>
                                    <span>{"India"}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Email Id : -</Form.Label>
                                    <span>{contactdetails == "" ? supplierDetails.email : contactdetails.email}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">State : -</Form.Label>
                                    <span>{supplierstate}</span>
                                </Form.Group>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item> */}
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>Shipping Address</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="12" controlId="formBasicName">
                                    <Form.Label className="label">Address</Form.Label>
                                    <Form.Control as="textarea" onChange={(e) => setshippingaddress(e.target.value)} rows={3} name='address' placeholder='Enter Shipping Address' />
                                </Form.Group>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>Details</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="12" controlId="formBasicName">
                                    <Form.Label className="label">Remarks</Form.Label>
                                    <Form.Control as="textarea" onChange={(e) => setremarks(e.target.value)} rows={3} name='address' placeholder='Enter Remarks' />
                                </Form.Group>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header>GST Details</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <h1>Transport Details</h1>
                                {/* <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Tax Type</Form.Label>
                                    <Form.Control type="text" autoComplete="off" placeholder="Enter Tax Type" name="taxSch" onChange={(e) => settaxSch(e.target.value)} />
                                </Form.Group> */}
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Type of supply</Form.Label>
                                    <Select options={stype} className='sett' onChange={handlesuptype}/>

                                    {/* <Form.Control type="text" autoComplete="off" placeholder="Enter Type of supply" onChange={(e) => setsupTyp(e.target.value)} /> */}
                                </Form.Group>
                                <h1>Document Details</h1>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Document No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" placeholder="Enter Document No" onChange={(e) => setno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Document Date</Form.Label>
                                    <Form.Control type="date" autoComplete="off" onChange={(e) => setdt1(e.target.value)} />
                                </Form.Group>
                                <h1>Seller Details</h1>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">GSTIN of Seller </Form.Label>
                                    <Form.Control type="text" value={[setsellindata].map((item) => item.gstin)} disabled autoComplete="off"  name="gstin" placeholder="Enter GSTIN of Supplier" onChange={(e) => {handleChange(e); }} />
                                </Form.Group>
                                {/* value={[setsellindata].map((item) => item.gstin)} disabled */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Legal Name</Form.Label>
                                    <Form.Control type="text" autoComplete="off" value={[setsellindata].map((item) => item.lglNm)} disabled name="lglNm" placeholder="Enter Legal Name" onChange={(e) => handleChange(e)} />
                                </Form.Group>
                                {/* value={[setsellindata].map((item) => item.lglNm)} disabled  */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Address1</Form.Label>
                                    <Form.Control type="text"  autoComplete="off" value={[setsellindata].map((item) => item.addr1)} disabled name="addr1" placeholder="Enter Address1" onChange={(e) => handleChange(e)} />
                                </Form.Group>
                                {/* value={[setsellindata].map((item) => item.addr1)} disabled */}
                                {/* <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Address2</Form.Label>
                                    <Form.Control type="text" autoComplete="off" name="addr2" placeholder="Enter Address2" onChange={(e) => handleChange(e)} />
                                </Form.Group> */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Location</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Location"  value={[setsellindata].map((item) => item.loc)} disabled autoComplete="off" name="loc" onChange={(e) => handleChange(e)} />
                                </Form.Group>
                                {/* value={[setsellindata].map((item) => item.loc)} disabled */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">PinCode</Form.Label>
                                    <Form.Control type="text"  placeholder="Enter PinCode" name="pin" value={[setsellindata].map((item) => item.pin)} disabled autoComplete="off" onChange={(e) => handleChange(e)} />
                                </Form.Group>
                                {/* value={[setsellindata].map((item) => item.pin)} disabled */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">State Code</Form.Label>
                                    <Form.Control type="text"  placeholder="Enter State Code"  value={[setsellindata].map((item) => item.stcd)} disabled autoComplete="off" name="stcd" onChange={(e) => handleChange(e)} />
                                </Form.Group>
                                {/* value={[setsellindata].map((item) => item.stcd)} disabled */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Phone Number</Form.Label>
                                    <Form.Control type="text"  placeholder="Enter Phone Number"  value={[setsellindata].map((item) => item.ph)} disabled name="ph" autoComplete="off" onChange={(e) => handleChange(e)} />
                                </Form.Group>
                                {/* value={[setsellindata].map((item) => item.ph)} disabled */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Email Id</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Email Id"  disabled value={[setsellindata].map((item) => item.em)}  name="em" autoComplete="off" onChange={(e) => handleChange(e)} />
                                </Form.Group>
                                {/* disabled value={[setsellindata].map((item) => item.em)} */}
                                <h1>Buyer Details</h1>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">GSTIN of Buyer</Form.Label>
                                    <Form.Control type="text" placeholder="Enter GSTIN of Buyer" value={buyer.gstin || ""} name="gstin" autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Legal Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Legal Name" value={buyer.lglNm || ""} name="lglNm" autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">State Code of Place of Supply</Form.Label>
                                    <Form.Control type="text" placeholder="Enter State Code of Place of Supply" value={buyer.pos || ""} name="pos" autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Address1</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Address1" value={buyer.addr1 || ""} name="addr1" autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group>
                                {/* <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Address2</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Address2" value={buyer.addr2 || ""} name="addr2" autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group> */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Location</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Location" value={buyer.loc || ""} name="loc" autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">PinCode </Form.Label>
                                    <Form.Control type="text" placeholder="Enter PinCode" value={buyer.pin || ""} name="pin" autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">State Code</Form.Label>
                                    <Form.Control type="text" placeholder="Enter State Code" value={buyer.stcd || ""} name="stcd" autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Phone No</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Phone No" value={buyer.ph || ""} name="ph" autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Email ID</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Email ID" value={buyer.em || ""} name="em" autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group>
                               
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <br></br>
                <Row className="mb-1">
                    <Form.Group as={Col} md="12" controlId="formBasicName">
                        <Form.Label className="label">Product</Form.Label>
                        <Select options={productdata} onChange={(e) => addData(e)} onInputChange={(e) => customFilter(e) } />
                    </Form.Group>
                </Row>
                <br></br>
                <Table striped responsive hover size="sm">
                    <thead className='p-3 mb-2 bg-success text-white'>
                        <tr>
                            <th style={{ width: '20%' }}>PRODUCT&nbsp;NAME</th>
                            <th style={{ width: '9%' }}>HSN&nbsp;Code</th>
                            <th style={{ width: '8%' }}>GST</th>
                            <th style={{ width: '8%' }}>UOM</th>
                            <th style={{ width: '8%' }}>QTY(PCS)</th>
                            <th style={{ width: '8%' }}>QTY(KG)</th>
                            <th style={{ width: '8%' }}>RATE (₹)</th>
                            <th style={{ width: '8%' }}>UOM</th>
                            <th style={{ width: '8%' }}>GROSS AMOUNT(₹)</th>
                            <th style={{ width: '8%' }}>DISCOUNT %</th>
                            <th style={{ width: '8%' }}>NET AMOUNT (₹)</th>
                            {/* <th>Per&nbsp;Unit&nbsp;Rate</th> */}
                            <th style={{ width: '8%' }}>GST VALUE&nbsp;(₹)</th>
                            <th style={{ width: '8%' }}>TOTAL&nbsp;(₹)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                            {/* <th>View</th> */}
                            <th>Action&nbsp;&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody style={{ overflow: "scroll" }}>
                        {purchaseReturnItems.map(item =>
                            <tr key={item.product.id}>
                                <td>
                                    <Form.Group as={Col} md="12" >
                                        {/* <Form.Control type="text" value={item.productName} size="sm" /> */}
                                        <textarea type="text" className="form-control textcontrol" value={item.productName} size="sm" ></textarea>

                                    </Form.Group>
                                    <Form.Control as="textarea" onChange={(e) => { dispatch(changepurchasereturntext(e.target.value, item)) }} value={item.text} rows={2} className='form-control' />
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" value={item.hsncode} size="sm" readOnly />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" value={item.igst} size="sm" readOnly />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Select disabled={item.productType !== "kgproduct" ? true : false} onChange={(e) => { dispatch(purchasereturnmeasurement(e.target.value, item, item.unitofmeasurement)); }} size="sm">
                                            <option>{item.productType !== "kgproduct" ? 'PCS' : "Select"}</option>
                                            <option value="pcs">PCS</option>
                                            <option value="kg">KG</option>
                                        </Form.Select>
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" onChange={(e) => { dispatch(purchasereturnqtypcs(e.target.value, item, item.unitofmeasurement));roundData(e.target.value) }} disabled={item.unitofmeasurement == "kg" ? true : false} value={item.purquantity} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" onChange={(e) => { dispatch(purchasereturnqtykgs(e.target.value, item, item.unitofmeasurement));roundData(e.target.value) }} disabled={item.unitofmeasurement == "box"||item.unitofmeasurement=='pcs' ? true : false || item.unitofmeasurement == "" ? true : false} value={item.unitofmeasurement == "box" ? "" : item.purquantitykgs} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" size="sm" onChange={(e) => { dispatch(changecostpurchasereturn(e.target.value, item, taxtype));roundData(e.target.value) }} value={item.dlp} />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Select disabled={item.productType !== "kgproduct" ? true : false} onChange={(e) => { dispatch(purchasereturncalmeasurement(e.target.value, item, item.calunitofmeasurement));roundData(e.target.value) }} size="sm">
                                            <option>{item.productType !== "kgproduct" ? 'PCS' : "Select"}</option>
                                            <option value="pcs">PCS</option>
                                            <option value="kg">KG</option>
                                        </Form.Select>
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" value={Number(item.grossamount)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" onChange={(e) => { dispatch(changepurchasereturndiscount(e.target.value, item, taxtype));roundData(e.target.value) }} value={item.tradeDiscount || ''} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" value={Number(item.total)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" value={Number(item.gstvalue)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" value={Number(item.amount)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type='button' className='btn btn-danger' onClick={() => {dispatch(removefromcartfrompurchasereturn(item.product.id));roundData('')}} value="X" size="sm" />
                                    </Form.Group>
                                </td>
                            </tr>
                        )}
                    </tbody>
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Packing&nbsp;&&nbsp;Forwarding</td>
                            <td>  
                                <Form.Group as={Col} md="12">
                                <Form.Control type="number" value={shippingcharge} onChange={(e) => settingship(e.target.value)} style={{ height: 30 }} />
                            </Form.Group>
                            </td>
                            <td></td>

                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Net&nbsp;Amount</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                <Form.Control type="number" value={netAmount?((netAmount).toFixed(2)):"0"}  style={{ height: 30 }} />
                            </Form.Group>
                            </td>
                            <td></td>

                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>IGST</td>
                            <td><Form.Group as={Col} md="12">
                                <Form.Control type="number" value={igstcheck()} requied style={{ height: 30 }}/>
                            </Form.Group></td>
                            <td></td>


                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>SGST</td>
                            <td> <Form.Group as={Col} md="12">
                                <Form.Control type="number" value={SGSTcheck()} requied style={{ height: 30 }}/>
                            </Form.Group></td>
                            <td></td>

                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>CGST</td>
                            <td><Form.Group as={Col} md="12">
                                <Form.Control type="number" value={cgstcheck()} requied style={{ height: 30 }}/>
                            </Form.Group></td>
                            <td></td>


                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Round&nbsp;off&nbsp;Value</td>
                            <td><Form.Group as={Col} md="12">
                                <Form.Control type="number" value={round} onChange={(e) => settinground(e.target.value)} style={{ height: 30 }} />
                            </Form.Group></td>
                            <td></td>

                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Grand&nbsp;Total</td>
                            <td><Form.Group as={Col} md="12">
                                <Form.Control type="number" value={grandTotal?((grandTotal).toFixed(2)):"0"} readOnly style={{ height: 30 }}/>
                            </Form.Group></td>
                            <td></td>

                        </tr>
                    </tbody>
                </Table>
                <Button variant="primary" style={{width:'auto'}} onClick={submitdata} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Submit'}
        </Button>
            </Form>
        </MDBCard>
    )
}