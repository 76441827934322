import React from 'react';
import { Mydatatable } from '../../Master/Product/Mydatatable';
import AuthService from '../../services/auth.service';

 
const Distable = () => {
  const currentuser = AuthService.getCurrentUser();
console.log(currentuser.roles[0])
  // Define the table headers
  let header = [
    { "label": "Id", "field": "id" },
    { "label": "Trade name", "field": "trade_name" },
    { "label": "Voucher date", "field": "voucherdate" },
    { "label": "Voucher No", "field": "voucherno" },
    { "label": "PDF", "field": "pdflocation", "subaction1": "Download" },
    { "label": "Image", "field": "imglocation", "subaction1": "Download" },
    { "label": "Amount", "field": "amount" },
    { "label": "Created Time", "field": "createdtime" },
    { "label": "Created Date", "field": "createddate" },
    { "label": "Created by Name", "field": "createbyname" }
  ];

   if (currentuser.roles[0]=='ROLE_ADMIN') {
    header.push({
      "label": "Action",
      "field": "action",
      "subaction1": (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash-2">
          <polyline points="3 6 5 6 21 6" />
          <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
          <line x1="10" y1="11" x2="10" y2="17" />
          <line x1="14" y1="11" x2="14" y2="17" />
        </svg>
      )
    });
  }

  // Define the keys to sum
  const sumofkeys = [{ "first": 'id' }];

  return (
    <Mydatatable
      head={header}
      title={"Invoice"}
      axiostabledata={'invoice'}
      Add={"Add"}
      Create={'/Discreate'}
      Sumofkeys={sumofkeys}
    />
  );
};

export default Distable;
