import { DC_ADD_TO_CART } from "../constant";
import { DC_CHANGE_TEXT } from "../constant";
import { DC_CHANGE_MEASUREMENT } from "../constant";
import { DC_SQPB_MEASUREMENT } from "../constant";
import { DC_SQPBKG_MEASUREMENT } from "../constant";
import { DC_CHANGE_COST } from "../constant";
import { DC_CHANGE_CAL_MEASUREMENT } from "../constant";
import { DC_CHANGE_DISCOUNT } from "../constant";
import { DC_REMOVE_FROM_CART } from "../constant";
import { DC_EMPTY_DATA } from "../constant";


export const getAddDatadc = (data, data2) => {

    return {

        type: DC_ADD_TO_CART,
        data: data,
        data2: data2
    }
}

export const changedctext = (data, item) => {

    return {
        type: DC_CHANGE_TEXT,
        data: data,
        item: item
    }
}

export const changedcmeasurement = (data, item, data2) => {

    return {
        type: DC_CHANGE_MEASUREMENT,
        data: data,
        item: item,
        data2: data2
    }
}

export const changesqpbqtydc = (data, item, data2, data3) => {
    //   console.log("mello",data)
    return {
        type: DC_SQPB_MEASUREMENT,
        data: data,
        item: item,
        data2: data2,
        data3: data3
    }
}

export const changesqpbqtykgdc = (data, item, data2, data3) => {
    //   console.log("mello",data)
    return {
        type: DC_SQPBKG_MEASUREMENT,
        data: data,
        item: item,
        data2: data2,
        data3: data3
    }
}

export const changedccost = (data, item, data2) => {

    return {
        type: DC_CHANGE_COST,
        data: data,
        item: item,
        data2: data2

    }
}

export const dccalmeasurement = (data, item, data2 ) => {

    return{
        type: DC_CHANGE_CAL_MEASUREMENT,
        data: data,
        item: item,
        data2: data2
    }
}

export const changedcdiscount = (data, item, data2) => {

    return {
        type: DC_CHANGE_DISCOUNT,
        data: data,
        item: item,
        data2: data2

    }
}

export const removedccart = (data) => {
    return {
        type: DC_REMOVE_FROM_CART,
        data: data
    }
}


export const emptydcdata = () => {

    return {
        type: DC_EMPTY_DATA
    }
}
