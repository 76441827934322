import React from "react";
import { Mydatatable } from "../Product/Mydatatable";
import Create from "./Create";
export default function Table(){
    let header = [
        { "label": "Id", "field": "id" },
        { "label": "Distributor", "field": "distributorname" },
        // { "label": "Product Name", "field": "product_name" },
    
        // { "label": "Ean COde", "field": "peancode" },
        // { "label": "Quantity", "field": "inwardqty" },
    
        // { "label": "Rate", "field": "rate" },
        // { "label": "Amount", "field": "amount" },
        { "label": "Created Date", "field": "createddate" },
        { "label": "Created Time", "field": "createdtime" },
        { "label": "Created By", "field": "createbyname" },
        { "label": "Updated Date", "field": "updateddate" },
        { "label": "Updated Time", "field": "updatedtime" },
        { "label": "Updated By", "field": "updatedbyname" },
        { "label": "Action", "field": "action", "subaction1": <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" /><circle cx="12" cy="12" r="3" /></svg> }
      ];
      const sumofkeys = [{ "first": 'id' }]
    return(
        <>
        {/* <Create/> */}
        <Mydatatable
        head={header}
        title={"Distributor In Ward"}
        axiostabledata={'indiststock'}
        Add={"Add"}
        Create={"/Disinwardcreate"}
        Edit={"/DIsinwardupdate"}
        View={"/DIsinwardview"}
        Sumofkeys={sumofkeys}
      />
        </>
    )
}