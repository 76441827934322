import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import ReatilerService from "../../services/Reatiler";
import WorkOrderService from "../../services/WorkOrderService";
import ProductService from "../../services/ProductService";
import { Accordion, Button, Col, Row, Table, Form } from "react-bootstrap";
import Select from "react-select";
import { changecostworkorderedit, changeworkordereditdiscount, changeworkordereditdiscount1, changeworkorderedittext, changeworkordereditunitOfMeasurement, geteditdatatoloadworkorder, perworkorderedit, qtyworkorderedit, removefromcartfromworkorderedit, workordereditaddtocart } from "../../../redux/Action/workorderEdit";
import { MDBCard } from "mdbreact";
import Swal from "sweetalert2";
import AuthService from "../../services/auth.service";

export default function Edit() {

  const [Reatilerg, setret] = useState([]);
  const [retailerpin, setRetailerPin] = useState([]);
  const [pinCode, setPinCode] = useState('');
  const [workOrderDate, setworkOrderDate] = useState('');
  const [retailerId, setretailerId] = useState('');
  const [retailername, setretailername] = useState('');
  const [orderStatus, setorderStatus] = useState('');
  const [remarks, setremarks] = useState('');
  const [product, setproduct] = useState([]);
  const [boxProductDiscount, setboxProductDiscount] = useState('');
  const [schemeboxProductDiscount, setschemeboxProductDiscount] = useState('');
  const [kgProductDiscount, setkgProductDiscount] = useState('');
  const [schemekgProductDiscount, setschemekgProductDiscount] = useState('');
  const [corporaetProductDiscount, setcorporaetProductDiscount] = useState('');
  const [schemecorporateProductDiscount, setschemecorporateProductDiscount] = useState('');
  const [cookerProductDiscount, setcookerProductDiscount] = useState('');
  const [schemecookerProductDiscount, setschemecookerProductDiscount] = useState('');
  const [noshProductDiscount, setnoshProductDiscount] = useState('');
  const [schemenoshProductDisocunt, setschemenoshProductDisocunt] = useState('');
  const [distributorId, setDistributorId] = useState('');

  /////////////////////////////////////////////////////
  const [totalPcsQuantity, setTotalPcsQuantity] = useState(0);
  const [totalnet, settotalnet] = useState();
  const [grossfull, setgrossfull] = useState();
  const [gstvalue, setgstvalue] = useState();
  const [amount, setamount] = useState();
  const [kg, setkg] = useState();
  /////////////////////////////////////////////
const[onemptied,setid]=useState([])
  const [sumbox, setsumbox] = useState(0);
  const [sumofcorp, setcorp] = useState(0);
  const [sumofcorpcs, setcorpc] = useState(0);
  const [boxamt, setboxsmt] = useState(0);
  const [amt, setamt] = useState(0);
  const [kg1, setkg1] = useState(0);
  const [kg2, setkg2] = useState(0);
  const [kg3, setkg3] = useState(0);

  const [c1, setc1] = useState(0);
  const [c2, setc2] = useState(0);
  const [c3, setc3] = useState(0);

  const [n1, setn1] = useState(0);
  const [n2, setn2] = useState(0);
  const [n3, setn3] = useState(0);




  ///////////////////////////
  const { id } = useParams();
  
const { pointindex } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = AuthService.getCurrentUser();

  const workorderItem = useSelector((state) => state.workordereditdata);
  let grossAmount = workorderItem.length && workorderItem.map(item => Math.round(Number(item.total))).reduce((prev, next) => Math.round(prev - (-next)));
  // console.log("mello", workorderItem)
  const [second, setSecond] = useState([])
  useEffect(() => {
    /* workorder get data */
   
   
    WorkOrderService.getWorkOrder(id).then(res => {
      setSecond(res.data.workOrderId);
      console.log( res.data)
      setret(res.data);
      setretailerId(res.data.ret_id);
      setPinCode(res.data.retailerPinCode);
      setorderStatus(res.data.orderStatus);
      setworkOrderDate(res.data.workOrderDate);
      setremarks(res.data.remarks);
      setDistributorId(res.data.dist_id);
      let aa = res.data.ret_id;

      ReatilerService.getRetailerGet(aa).then(res1 => {

        setretailername(res1.data.tradeName);
        setboxProductDiscount(res1.data.boxProductDiscount);
        setschemeboxProductDiscount(res1.data.schemeboxProductDiscount);
        setkgProductDiscount(res1.data.kgProductDiscount);
        setschemekgProductDiscount(res1.data.schemekgProductDiscount);
        setcorporaetProductDiscount(res1.data.corporaetProductDiscount);
        setschemecorporateProductDiscount(res1.data.schemecorporateProductDiscount);
        setcookerProductDiscount(res1.data.cookerProductDiscount);
        setschemecookerProductDiscount(res1.data.schemecookerProductDiscount);
        setnoshProductDiscount(res1.data.noshProductDiscount);
        setschemenoshProductDisocunt(res1.data.schemenoshProductDisocunt);

      })
    })


    /* Retailer Data */
    // ReatilerService.getReatilerTable().then((res) => {
    //   let aa = res.data;
    //   setret(aa.map(user => ({ value: user.id, label: user.tradeName })));
    //   setRetailerPin(res.data);
    // });

    /* Product Data */
    let product1 = [];
    let cid = 1;
    WorkOrderService.getWorkOrderProduct(cid).then(res => {
      let aa = res.data;

  //  if(product.length==0){   aa.map(user => {
  //       ProductService.getProductEdit(user.pid).then(res1 => {
  //         let ab = res1.data;
  //         if (ab !== "") {
  //           product1.push(ab);
  //         }

  //         setproduct(product1.map(use => ({ value: use.id, label: use.productName + ' - ' + use.eanCode + ' - ' + use.shortName })));
  //       })
  //     })}

  
    })

    dispatch(geteditdatatoloadworkorder(id));
  }, []);

  function pinCodedata(e) {
    const name = e.value;
    setretailerId(name)
    retailerpin.map(user => {
      if (user.id == name) {
        setPinCode(user.pinCode);
        setboxProductDiscount(user.boxProductDiscount);
        setkgProductDiscount(user.kgProductDiscount);
        setcorporaetProductDiscount(user.corporaetProductDiscount);
        setcookerProductDiscount(user.cookerProductDiscount);
        setnoshProductDiscount(user.noshProductDiscount);
        setschemeboxProductDiscount(user.schemeboxProductDiscount);
        setschemekgProductDiscount(user.schemekgProductDiscount);
        setschemecorporateProductDiscount(user.schemecorporateProductDiscount);
        setschemecookerProductDiscount(user.schemecookerProductDiscount);
        setschemenoshProductDisocunt(user.schemenoshProductDisocunt);
      }
    })
  }
  useEffect(() => {

    calculateSum()

  }, [workorderItem])


  const calculateSum = () => {
    // console.log("calledsum")

    let workOderItems = workorderItem.map((order) => {
      const measurement = parseFloat(order.measurement);
      return isNaN(measurement) || !isFinite(measurement) ? 0 : measurement;
    }).reduce((prev, curr) => prev + curr, 0);

    console.log(workorderItem);
    if (workorderItem) {
      // window.location.reload();
    }
    workOderItems = parseFloat(workOderItems.toFixed(2));
    setTotalPcsQuantity(workOderItems);



    ///////////////////////////////////////////////////
    let totalnet = workorderItem.map((order) => parseFloat(order.total))
      .reduce((prev, curr) => prev + curr, 0);
    totalnet = parseFloat(totalnet.toFixed(2));
    settotalnet(totalnet)
    ////////////////////////////////////////////////////////////////
    let grossnet = workorderItem.map((order) => parseFloat(order.grossamount))
      .reduce((prev, curr) => prev + curr, 0);

    grossnet = parseFloat(grossnet.toFixed(2));
    setgrossfull(grossnet)
    ////////////////////////////////////////

    let gst = workorderItem.map((order) => parseFloat(order.gstvalue))
      .reduce((prev, curr) => prev + curr, 0);
    let gt = parseFloat(gst.toFixed(2))
    setgstvalue(gt)
    ////////////////////////////////////////////////////////////////
    let amt = workorderItem.map((order) => parseFloat(order.amount))
      .reduce((prev, curr) => prev + curr, 0);
    const tt = parseFloat(amt.toFixed(2))
    setamount(tt)
    // workOderItems.map((item) => {
    //   setTotalPcsQuantity(Number(totalPcsQuantity) + Number(item.measurement))
    //   console.log(totalPcsQuantity)
    // })
    //////////////////////////
    let kg1 = workorderItem
      .map((order) => {
        const parsedQty = parseFloat(order.qty);
        return isNaN(parsedQty) || !isFinite(parsedQty) ? 0 : parsedQty;
      })
      .reduce((prev, curr) => prev + curr, 0);

    const tt1 = parseFloat(kg1.toFixed(2));
    setkg(tt1);
    /////////////////////
    /////////////////////////////////////////////////
    let amt12 = workorderItem
      .map((order) => parseFloat(order.amount))
      .reduce((prev, curr) => prev + curr, 0);


    const kgCorporateProducts = workorderItem.filter((order) => order.productType === "boxproduct")
    setsumbox(kgCorporateProducts.map((order) => parseFloat(order.measurement))
      .reduce((prev, curr) => prev + curr, 0))



    setboxsmt(workorderItem.filter((order) => order.productType === "boxproduct").map((order) => parseFloat(order.amount))
      .reduce((prev, curr) => prev + curr, 0))




    const sumkgCor = workorderItem.filter((order) => order.productType === "corporateproduct")
    setcorp(sumkgCor.map((order) => parseFloat(order.measurement))
      .reduce((prev, curr) => prev + curr, 0))


    const sumpccor = workorderItem.filter((order) => order.productType === "corporateproduct")
    setcorpc(sumpccor.map((order) => parseFloat(order.qty))
      .reduce((prev, curr) => prev + curr, 0),)
    setamt(sumpccor.map((order) => parseFloat(order.amount))
      .reduce((prev, curr) => prev + curr, 0),)


    const kgproductpc = workorderItem.filter((order) => order.productType === "kgproduct")
    const time = kgproductpc.map((order) => parseFloat(order.qty))
      .reduce((prev, curr) => prev + curr, 0)
    const time2 = kgproductpc.map((order) => parseFloat(order.measurement))
      .reduce((prev, curr) => prev + curr, 0)
    const totalkgg = kgproductpc.map((order) => parseFloat(order.amount))
      .reduce((prev, curr) => prev + curr, 0)
    setkg1(parseFloat(time).toFixed(2))
    setkg2(time2)
    setkg3(totalkgg)

    const cooker = workorderItem.filter((order) => order.productType === "cookerproduct")
    const pcs = cooker.map((order) => parseFloat(order.measurement))
      .reduce((prev, curr) => prev + curr, 0)
    setc1(pcs)
    const c3total = cooker.map((order) => parseFloat(order.amount))
      .reduce((prev, curr) => prev + curr, 0)
    setc3(c3total)


    const noshing = workorderItem.filter((order) => order.productType === "noshproduct")
    const nospcs = noshing.map((order) => {
      const measurement = parseFloat(order.measurement);
      return isNaN(measurement) || !isFinite(measurement) ? 0 : measurement;
    }).reduce((prev, curr) => prev + curr, 0);
    setn1(nospcs)
    const nospcs1 = noshing.map((order) => {
      const measurement1 = parseFloat(order.qty);
      return isNaN(measurement1) || !isFinite(measurement1) ? 0 : measurement1;
    }).reduce((prev, curr) => prev + curr, 0);
    setn3(nospcs1)


    const nostotal = noshing.map((order) => parseFloat(order.amount))
      .reduce((prev, curr) => prev + curr, 0)
    setn2(nostotal)
    console.log(nostotal)



  }




  function RetailerName1(e) {
    setretailername(e);
  }

  const datevalue_1 = new Date(workOrderDate);
  const defaultValue_1 = datevalue_1.toLocaleDateString('en-CA');

  function deleteItem(e) {
    dispatch(workordereditaddtocart(e, ({
      "schemeboxProductDiscount": schemeboxProductDiscount,
      "schemekgProductDiscount": schemekgProductDiscount,
      "schemecorporateProductDiscount": schemecorporateProductDiscount,
      "schemecookerProductDiscount": schemecookerProductDiscount,
      "schemenoshProductDisocunt": schemenoshProductDisocunt,
      "kgProductDiscount": kgProductDiscount,
      "boxProductDiscount": boxProductDiscount,
      "corporaetProductDiscount": corporaetProductDiscount,
      "cookerProductDiscount": cookerProductDiscount,
      "noshProductDiscount": noshProductDiscount
    })));
  }

  const SubmitForm = () => {
    let retailerPinCode = pinCode;
    let workOderItems = workorderItem;
    let ret_id = retailerId;
    let dist_id = distributorId;
    const workorder = {
      workOrderDate,
      ret_id,
      dist_id,
      retailerPinCode,
      orderStatus,
      remarks,
      workOderItems
    }

    WorkOrderService.getWorkOrderEdit(id, workorder).then(res => {
      navigate(`/Newoo/${pointindex}`)
    })

  }
  const changeinplan = () => {
    // console.log("hey");
    navigate(`/Newoo/${pointindex}`)
  }


  const getItem = (id) => {

    // WorkOrderService.getDeleteWorkOrder(id).then(res => {
    //   window.location.reload();
    // })
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        WorkOrderService.getDeleteWorkOrder(id).then(res => {
          // window.location.reload();
          navigate(`/Newoo/${pointindex}`)
        })
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        )
      }
    })






  };


  return (
    <MDBCard data-aos="fade">
      {Reatilerg.orderStatus == true?(
              <div className='d-flex bd-highlight'>
              <div className="card-header card-title w-100">View Secondary Order </div>                 
                  <Link  to={`/Newoo/${pointindex}`}><Button className='btn btn-sm btn-secondary'>Back</Button></Link>
             
            </div>
      ):(
        <div className='d-flex bd-highlight'>
        <div className="card-header card-title w-100">View Secondary Order </div>
        {user.roles[0] === 'ROLE_DISTRIBUTOR'?"":
        <Link className='cwf prox btn'to={'/WorkOrderToSalesOrder/' + id}>SO</Link>}
        {/* <Link className='p-2 w-200 card-header card-title bd-highlight' to={'/workordertable'}><Button>Back</Button></Link> */}
        <Link  to={`/workorderedit/${id}/${pointindex}`}><Button className='btn btn-sm btn-secondary'> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="svgbuttonheight  feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"/><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"/></svg></Button></Link>
        {user.roles[0] === 'ROLE_ADMIN' &&<a class="delete btn btn-sm  red btn"  onClick={()=>getItem(id)}  ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class=" feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg></a>}
           
            <Link  to={`/Newoo/${pointindex}`}><Button className='btn btn-sm btn-secondary'>Back</Button></Link>
       
      </div>
      )}

      <div className='card-body'>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header style={{ color: " #164194!important" }}> Order
              <span>{second} </span> </Accordion.Header>
            <Accordion.Body>
              <Row md={3}>
                <Col>
                  <label class="form-label">Order Date: </label>
                  <input disabled type="date" name="workOrderDate" value={defaultValue_1} class="form-control" onChange={(e) => setworkOrderDate(e.target.value)} />
                </Col>
                <Col>
                  <label class="form-label">Retailer Name: </label>
                  <input disabled type="text" className='form-control' value={retailername} readOnly />
                </Col>
                <Col>
                  <label class="form-label">Retailer Pin Code: </label>
                  <input disabled type="text" value={pinCode} name="retailerPinCode" class="form-control" />
                </Col>
              </Row>
              <Row md={2}>
                <Col>
                  {/* <label className='form-label'>Sales id</label>
                    <input disabled type="number" value={1} className='form-control' /> */}
                  {/* <label className='form-label'>Order-Status</label>
                      <select class="form-select" value={orderStatus} name="orderStatus" onChange={(e) => setorderStatus(e.target.value)}>
                      <option >Choose the status</option>
                      <option value="true">Active</option>
                      <option value="false">UnActive</option>
                    </select> */}
                </Col>
                {/* <Col>
                  <label className='form-label'>Created by</label>
                    <input disabled type="text" className='form-control' value={"Admin"} />
                  </Col> */}

                <Col>

                </Col>
              </Row>
              <Row md={10}>
                <Col>
                  <label className='form-label'>Remarks</label>
                  <textarea disabled className='form-control' name="remarks" value={remarks} onChange={(e) => setremarks(e.target.value)} />
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>

        </Accordion><br></br>


        <Table striped responsive hover size="sm">
          <thead className='p-3 mb-2 bg-success text-white'>
            <tr>
              <th style={{ width: '20%' }}>PRODUCT&nbsp;NAME</th>
              <th style={{ width: '9%' }}>UOM</th>
              <th style={{ width: '8%' }}>QTY(PCS)</th>
              <th style={{ width: '8%' }}>QTY(KG)</th>
              <th style={{ width: '8%' }}>RATE (₹)</th>
              <th style={{ width: '8%' }}>GROSS AMOUNT (₹)</th>
              <th style={{ width: '8%' }}>TRADE DISCOUNT %</th>
              <th style={{ width: '8%' }}>SCHEME DISCOUNT %</th>
              <th style={{ width: '8%' }}>NET AMOUNT (₹)</th>
              {/* <th>Per Unit Rate</th> */}
              <th style={{ width: '8%' }}>GST VALUE (₹)</th>
              <th style={{ width: '8%' }}>TOTAL (₹)</th>
              {/* <th>Action</th> */}
            </tr>
          </thead>
          <tbody style={{ overflowY: "scroll" }}>
            {workorderItem.map((item) =>
              <tr key={item.id} >
                <td> <Form.Group as={Col} md="12" >
                  <Form.Control disabled type="text" value={item.productName} size="sm" />
                </Form.Group>
                  <Form.Control disabled as="textarea" rows={2} onChange={(e) => dispatch(changeworkorderedittext(e.target.value, item))} value={item.text} className='form-control' />
                </td>
                <td><Form.Group as={Col} md="12">
                  <Form.Select disabled value={item.unitofmeasurement} onChange={(e) => { dispatch(changeworkordereditunitOfMeasurement(e.target.value, item, item.unitofmeasurement)); }} size="sm" >
                    <option>{item.unitofmeasurement == "box" ? "Box" : item.productType == "boxproduct" ? 'Box' : "Select"}</option>
                    <option value="pcs">PCS</option>
                    <option value="kg">KG</option>

                  </Form.Select>
                </Form.Group></td>
                <td><Form.Group as={Col} md="12">
                  <Form.Control disabled type="text" name="measurement" onChange={(e) => { dispatch(perworkorderedit(e.target.value, item, item.unitofmeasurement)); }} value={item.measurement} size="sm" />
                </Form.Group></td>
                <td><Form.Group as={Col} md="12">
                  <Form.Control type="text" name="qty" onChange={(e) => { dispatch(qtyworkorderedit(e.target.value, item, item.unitofmeasurement)) }} disabled value={item.unitofmeasurement == "box" ? '' : item.productType == "boxproduct" ? "" : item.qty} size="sm" />
                </Form.Group></td>
                <td><Form.Group as={Col} md="12">
                  <Form.Control disabled type="text" onChange={(e) => dispatch(changecostworkorderedit(e.target.value, item, item.unitofmeasurement))} value={item.dlp} size="sm" />
                </Form.Group></td>
                <td><Form.Group as={Col} md="12">
                  <Form.Control disabled type="text" value={Number(item.grossamount)} size="sm" readOnly requied />
                </Form.Group></td>
                <td><Form.Group as={Col} md="12">
                  <Form.Control disabled type="text" value={Number(item.discount || '')} size="sm" readOnly />
                </Form.Group></td>
                <td><Form.Group as={Col} md="12">
                  <Form.Control disabled type="text" onChange={(e) => { dispatch(changeworkordereditdiscount1(e.target.value, item, item.unitofmeasurement)) }} value={Number(item.discount1)} size="sm" requied />
                </Form.Group></td>
                <td><Form.Group as={Col} md="12">
                  <Form.Control disabled type="text" value={Number(item.total)} size="sm" readOnly requied />
                </Form.Group></td>
                <td><Form.Group as={Col} md="12">
                  <Form.Control disabled type="text" value={Number(item.gstvalue)} size="sm" readOnly requied />
                </Form.Group></td>
                <td><Form.Group as={Col} md="12">
                  <Form.Control disabled type="text" value={Number(item.amount)} size="sm" readOnly requied />
                </Form.Group></td>
                <td>
                  <Form.Group as={Col} md="12">
                    {/* <Form.Control type='button' className='btn btn-danger' value="X" onClick={() => dispatch(removefromcartfromworkorderedit(item.id))} size="sm" /> */}
                  </Form.Group>
                </td>
                {/* <button className="form-control btn-sm" id='c17' onClick={() => dispatch(removefromcart(item.id))}>x</button> */}
              </tr>
            )}
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              {/* <td></td> */}
              {/* <td> <span className="cname">Gross&nbsp;amount&nbsp;<b>₹</b></span></td> */}
              <td>
                <Form.Group as={Col} md="12">
                  {/* <Form.Control  type="text" disabled value={grossAmount} size="sm" style={{top:"-1px"}}/> */}
                </Form.Group>
              </td>
              <td></td>
            </tr>

          </tbody>
        </Table>
        <table className='table'>

          <tr>

            {workorderItem.length !== 0 && (
              <>
              <td>Total</td>
                <td className='qtyn nn'><b>{Reatilerg.totalQtyPcs}</b></td>
                <td className='kgg'><b>{Reatilerg.totalQtyKg}</b></td>
                <td className='gross'><b>{Reatilerg.grossTotal}</b></td>
                <td className='netn'><b>{Reatilerg.netValue}</b></td>
                <td className='gstn'><b>{Reatilerg.taxAmount}</b></td>
                
                <td className='total'><b>{amount}</b></td>
              </>
            )}
          </tr>

        </table>
        <br></br>
        <table>
          <tr>
            {workorderItem.length !== 0 && (
              <>
                <tr>
                  <th className='namee'>Summary</th>
                  <th className='namee'> Total Qty(KG)</th>
                  <th className='namee'>Total Qty(Pcs)</th>
                  <th className='namee'>Total price</th>
                </tr>
                <tr>
                  <th>Box Product</th>
                  <th></th>
                  <th>{Reatilerg.boxProductTotalQtyPcs}</th>
                  <th>{Reatilerg.boxProductTotalprice}</th>
                </tr>
                <tr>
                  <th>KG Product</th>
                  <th>{Reatilerg.kgProductTotalQtyKg}</th>
                  <th>{Reatilerg.kgProductTotalQtyPcs}</th>
                  <th>{Reatilerg.kgProductTotalprice}</th>
                </tr>
                <tr>
                  <th>Corporate Product</th>
                  <th></th>
                  <th>{Reatilerg.corporateProductTotalQtyPcs}</th>
                  <th>{Reatilerg.corporateProductTotalprice}</th>
                </tr>
                <tr>
                  <th>Cooker Product</th>
                  <th></th>
                  <th>{Reatilerg.cookerProductTotalQtyPcs}</th>
                  <th>{Reatilerg.cookerProductTotalprice}</th>
                </tr>
                <tr>
                  <th>Nosh Product</th>
                  <th></th>
                  <th>{Reatilerg.noshProductTotalQtyPcs}</th>
                  <th>{Reatilerg.noshProductTotalprice}</th>
                </tr>
                <tr>
                  <th>Total</th>
                  <th>{Reatilerg.totalQtyKg}</th>
                  <th>{Reatilerg.totalQtyPcs}</th>
                  <th>{amount}</th>
                </tr>
              </>
            )}
          </tr>



        </table>
        <br></br>
        <Button variant="primary" type="button" onClick={changeinplan}>Back</Button>
      </div>

    </MDBCard>
  )
}