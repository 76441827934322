import axios from "axios";
import { MDBCard } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
// import { BASE_URL } from "../../services/Api";
// import authHeader from "../../services/auth-header";
import Select from "react-select";
import Swal from "sweetalert2";
import { Link, useNavigate, useParams } from "react-router-dom";
import authHeader from "../../services/auth-header";
import { BASE_URL } from "../../services/Api";

export default function Mastercartoonedit(){
    const { id } = useParams();
    
const [data, setData] = useState([])
const[extracted,setextracted]=useState([])
    const [productAll, setProductAll] = useState([]);
    const [BrandAll, setBrandAll] = useState([]);
    const [brand, setbrand] = useState('');
    const [brand2, setBrand2] = useState([])
    const [product2, setproduct2] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const applyFontSizeTransition = (elementId) => {
      const element = document.getElementById(elementId);
      element.style.transition = 'font-size 2s';
      element.style.fontSize = '30px';
      element.style.fontWeight = '600';
      // font-weight: 600;
      setTimeout(() => {
         
        document.getElementById("typer1").classList.add('promote');
        element.style.fontSize = '24px';
      }, 1000);
    };
    const [product, setproduct] = useState('');
    const [productname1, setproductname1] = useState('');
    const [productname2, setproductname2] = useState('');
    const [qty,setqty]= useState('');
    const [emptymasterqty,setemptymasterqty] = useState('');
    const [minweight,setminweight] = useState('');
    const [maxweight,setmaxweight] = useState('');
    const [length,setlength] = useState('');
    const [width,setwidth] = useState('');
    const [height,setheight] = useState('');
    const [eancode,seteancode] = useState('');
    const [stdqty,setstdqty] = useState('');
    const [status, setstatus] = useState([])
const[pcs,setPCS]=useState([])
    const navigate = useNavigate();
    let Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

        /* Get Brand data */
        const getbranddata = () => {
            axios.get(BASE_URL + 'brand/getAll', {
                headers: authHeader()
            }).then(res => {
                setBrandAll(res.data.map(user => ({ value: user.id, label: user.brandName })))
            })
        }

        useEffect(() => {
            getbranddata();
        }, []);

        const onChangeBrand = (e) => {
            let name = e.value;
    setBrand2([e])
            setbrand({ "id": e.value });
            /* Product Get all */
            axios.get(BASE_URL + 'product/productsByBrand/' + name, {
                headers: authHeader()
            }).then(res => {
                // console.log('mello',res.data)
                setProductAll(res.data.map(use => ({ value: use.id, label: use.productName })));
            })
    
        }
    
        const onChangeProduct = (e) => {

const productmatch=extracted.filter((item)=>item.product==e.value)
if(productmatch){
    const brand1=productmatch.some((item)=>item.brand==brand.value)
    console.log(brand1)
    
    if(brand1){
        const brand12=productmatch.filter((item)=>item.brand==brand.value)
 if(brand12.some((item)=>item.id==id)){
    let name = e.value;
    setproduct({ "id": e.value });
setproduct2([e])
 }else{
    alert('Product already exist')
 }
        // alert('Product already exist')

    }else{
        let name = e.value;
        setproduct({ "id": e.value });
setproduct2([e]) 
    }
}else{
             let name = e.value;
            setproduct({ "id": e.value });
setproduct2([e])   
}
//             let name = e.value;
//             setproduct({ "id": e.value });
// setproduct2([e])
        };

useEffect(() => {
    axios.get(BASE_URL + `mastercartoon/getbyid/${id}`, {
        headers: authHeader()
      })
        .then(response => { 
            setData([response.data])
            setemptymasterqty(Number([response.data].map((item)=>item.emptymasterqty)))
            setqty(Number([response.data].map((item)=>item.qty)))
            setstdqty(Number([response.data].map((item)=>item.stdqty)))
            setPCS(Number([response.data].map((item)=>item.pcs)))
            setproductname1([response.data].map((item)=>item.productname1)[0])
            setproductname2([response.data].map((item)=>item.productname2)[0])
            setminweight(Number([response.data].map((item)=>item.minweight)))
            setmaxweight(Number([response.data].map((item)=>item.maxweight)))
            setlength(Number([response.data].map((item)=>item.length)))
            setwidth(Number([response.data].map((item)=>item.width)))
            setbrand({'id':Number([response.data].map((item)=>item.brand).map((item)=>item.id))})
            setproduct({'id':Number([response.data].map((item)=>item.product).map((item)=>item.id))})
            setheight(Number([response.data].map((item)=>item.height)))
            seteancode(Number([response.data].map((item)=>item.eancode)))
            setBrand2([response.data].map((item)=>item.brand).map(use => ({ value: use.id, label: use.brandName })))
            setproduct2([response.data].map((item)=>item.product).map(use => ({ value: use.id, label: use.productName })))
            setstatus([response.data].map((item)=>item.status)[0])
            console.log([response.data].map((item)=>item.status)[0])
            // minweight
            axios.get(BASE_URL + 'product/productsByBrand/' + [response.data].map((item)=>item.brand).map((item)=>item.id), {
                headers: authHeader()
            }).then(res => {
                // console.log('mello',res.data)
                setProductAll(res.data.map(use => ({ value: use.id, label: use.productName })));
            })
         })
        .catch(error => {
          console.error(error);
        });

        axios.get(BASE_URL + `mastercartoon/getll`, {
            headers: authHeader()
          }) .then(response => {


            const extractedData = response.data.map(item => ({
                id: item.id,
                product:item.product.id,
                value:item.brand.id
              }));
     console.log(extractedData)  
    
     setextracted(extractedData)  
    })
        .catch(error => {
          console.error(error);
        });





}, [ ])
console.log(product2)



        const submitdata = ( ) => {

if(status==false){

    setIsLoading(true)

            axios.put(BASE_URL + `mastercartoon/update/${id}`,{
                brand,product,qty,emptymasterqty,pcs,productname1,productname2,minweight,maxweight,length,width,height,eancode,stdqty,
            },{
                headers:authHeader()
            }).then(res=>{
                console.log(res.data=='')
                navigate('/MasterCarton/Table/1')
                if(res.status==201||res.status==200){
                 // alert("ee")
                 Toast.fire({
                   icon: 'success',
                   title: 'Successfully added'
                 })
               }
            }).catch(error=>{
              
                  if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      footer: `
                        <div>
                          <p id="issueText">Why do I have this issue?</p>
                          <ul>
                      
                          <li id="typer1">You might have an internet issue</li>
                          </ul>
                        </div>
                      `,
                      didOpen: () => {
                        applyFontSizeTransition('issueText');
                      }
                    });
                  }else{
                    Swal.fire({
                        title: 'This Product is already Create',
                        text: "You won't be able to revert this!",
                        icon: 'warning',
                      })
    
                  }
            })
            .finally(() => {
                setIsLoading(false); 
              });
        }else{
            Swal.fire({
                title: "Cartoon Barcode Created: You Can't Edit This One",
                showClass: {
                  popup: `
                    animate__animated
                    animate__fadeInUp
                    animate__faster
                  `
                },
                hideClass: {
                  popup: `
                    animate__animated
                    animate__fadeOutDown
                    animate__faster
                  `
                }
              });

// navigate('/Mastercartoontable/1')
        }
        }
        const handledelete=()=>{
            // alert(id)
         
if(status==false){
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.isConfirmed) {
        
                        axios.delete(BASE_URL + 'mastercartoon/delete/' + id, {
                            headers: authHeader()
                        }).then(res => {
                            Swal.fire(
                                'Deleted!',
                                'Your file has been deleted.',
                                'success'
                            )
                            navigate(`/Mastercartoontable/1`)
                        }).catch(error => {
                            console.error('Error adding  :', error);
                            if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
                            Swal.fire({
                              icon: "error",
                              title: "Oops...",
                              text: "Something went wrong!",
                              footer: `
                                <div>
                                  <p id="issueText">Why do I have this issue?</p>
                                  <ul>
                              
                                  <li id="typer1">You might have an internet issue</li>
                                  </ul>
                                </div>
                              `,
                              didOpen: () => {
                                applyFontSizeTransition('issueText');
                              }
                            });
                          }
                          })
                          .finally(() => {
                            setIsLoading(false); 
                          });
        
        
                    }
                })}else{
                    // alert('alread created ')
                    // navigate(`/Mastercartoontable/1`)
                    Swal.fire({
                        title: "Cartoon Barcode Created: You Can't Delete This One",
                        showClass: {
                          popup: `
                            animate__animated
                            animate__fadeInUp
                            animate__faster
                          `
                        },
                        hideClass: {
                          popup: `
                            animate__animated
                            animate__fadeOutDown
                            animate__faster
                          `
                        }
                      });

                }
            
        }



    return(
        <MDBCard className="company">
            <h1>Master Carton Edit</h1>
            <div className="row">
    <div className="col-5">
   {/* <Link to={`/Mastercartoontable/1`}> <button style={{width:'auto'}} className="btn btn-primary">Table</button></Link> */}
   <Link to={`/Mastercartoonview/${id}/1`}> <button style={{width:'auto'}} className="btn btn-primary">View</button></Link>

    <button style={{width:'auto'}} onClick={( )=>handledelete()} className="btn btn-primary">Delete</button>

    </div>
    <div className="col-1">
    </div>
   </div>
            <Form className="product1">
            <Row className="mb-1">
                <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Brand Name</Form.Label>
                        <Select options={BrandAll} value={[{ value: brand2.map((item)=>item.value), label:brand2.map((item)=>item.label) }]} onChange={(e) =>{ onChangeBrand(e)}} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Product Name</Form.Label>
                        <Select options={productAll} value={[{ value: product2.map((item)=>item.value), label:product2.map((item)=>item.label) }]} onChange={(e) => onChangeProduct(e)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">QTY</Form.Label>
                        <Form.Control type="text" value={qty} name="qty" placeholder="Enter QTY" autoComplete="off" onChange={(e) => setqty(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Empty Master Weight</Form.Label>
                        <Form.Control type="text" value={emptymasterqty} name="emptymasterqty" placeholder="Enter Empty Master QTY" autoComplete="off" onChange={(e) => setemptymasterqty(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">PCS</Form.Label>
                        <Form.Control type="text" value={pcs} name="PCS" placeholder="Enter PCS" autoComplete="off" onChange={(e) => setPCS(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Product Name1</Form.Label>
                        <Form.Control type="text" value={productname1} name="productname1" placeholder="Enter Product Name1" autoComplete="off" onChange={(e) => setproductname1(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Product Name2</Form.Label>
                        <Form.Control type="text" value={productname2} name="productname2" placeholder="Enter Product Name2" autoComplete="off" onChange={(e) => setproductname2(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Min Weight</Form.Label>
                        <Form.Control type="text" value={minweight} name="minweight" placeholder="Enter min Weight" autoComplete="off" onChange={(e) => setminweight(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Max Weight</Form.Label>
                        <Form.Control type="text" value={maxweight} name="maxweight" placeholder="Enter Max Weight" autoComplete="off" onChange={(e) => setmaxweight(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Length</Form.Label>
                        <Form.Control type="text" value={length} name="length" placeholder="Enter Length" autoComplete="off" onChange={(e) => setlength(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Width</Form.Label>
                        <Form.Control type="text"  value={width}  name="width" placeholder="Enter Width" autoComplete="off" onChange={(e) => setwidth(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Height</Form.Label>
                        <Form.Control type="text"  value={height} name="height" placeholder="Enter Height" autoComplete="off" onChange={(e) => setheight(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">EAN Code</Form.Label>
                        <Form.Control type="text" value={eancode} name="eancode" placeholder="Enter EAN Code" autoComplete="off" onChange={(e) => seteancode(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">STD QTY</Form.Label>
                        <Form.Control type="text" value={stdqty} name="stdqty" placeholder="Enter STD QTY" autoComplete="off" onChange={(e) => setstdqty(e.target.value)} />
                    </Form.Group>
                </Row>
                {/* <Button variant="primary" type="button" onClick={submitdata}>
                    Submit
                </Button> */}
                <Button variant="primary" style={{width:'auto'}} onClick={()=>submitdata()} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Submit'}
        </Button>
            </Form>
            </MDBCard>
    )
}