import axios from "axios";
import { MDBCard } from "mdbreact";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../services/Api";
import authHeader from "../../services/auth-header";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import Select from "react-select";
import { Link, useNavigate, useParams } from "react-router-dom";
import AuthService from "../../services/auth.service";
import Swal from "sweetalert2";

export default function View() {
    const { id } = useParams();
    const { pointindex } = useParams();
    const [remarks, setremarks] = useState([]);
    const [staff, setstaff] = useState('');
    const [formdate, setformdate] = useState('');

    const user = AuthService.getCurrentUser();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const applyFontSizeTransition = (elementId) => {
        const element = document.getElementById(elementId);
        element.style.transition = 'font-size 2s';
        element.style.fontSize = '30px';
        element.style.fontWeight = '600';
        // font-weight: 600;
        setTimeout(() => {

            document.getElementById("typer1").classList.add('promote');
            element.style.fontSize = '24px';
        }, 1000);
    };

    const getAssetRequestFormdata = () => {
        axios.get(BASE_URL + 'assetreqform/byid/' + id, {
            headers: authHeader()
        }).then(res => {
            setremarks(res.data.remarks);
            setstaff(res.data.staffname);
            setformdate(res.data.formdate);
        })
    }
    const defaultvalue = new Date(formdate);
    const default_Value = defaultvalue.toLocaleDateString('en-CA');
    useEffect(() => {
        getAssetRequestFormdata()
    }, []);

    const handledelete = (id) => {


        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                setIsLoading(true);
                axios.delete(BASE_URL + 'assetreqform/delete/' + id, {
                    headers: authHeader()
                }).then(res => {


                    navigate('/Asset Request Form/Table/1')

                    Swal.fire(
                        'Deleted!',
                        'Your file has been deleted.',
                        'success'
                    )



                    // window.location.reload();
                }).catch(error => {
                    console.error('Error adding  :', error);
                    if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            footer: `
                  <div>
                    <p id="issueText">Why do I have this issue?</p>
                    <ul>
                
                    <li id="typer1">You might have an internet issue</li>
                    </ul>
                  </div>
                `,
                            didOpen: () => {
                                applyFontSizeTransition('issueText');
                            }
                        });
                    }
                })
                    .finally(() => {
                        setIsLoading(false);
                    });

            }
        })
    }

    return (
        <MDBCard className="company">
            <div className='d-flex bd-highlight'>

                <div className="card-header card-title w-100">View Asset Request Form</div>
                {user.roles[0] === 'ROLE_ADMIN' &&

                    <Link className='p-2 w-200 card-header card-title bd-highlight' variant="primary" style={{ width: 'auto' }} onClick={() => handledelete(id)} disabled={isLoading}>

                        <Button className="btn-sm">  {isLoading ? 'Loading...' : 'Delete'}</Button>
                    </Link>
                }
                <Link className='p-2 w-200 card-header card-title bd-highlight' to={`/Asset Request Form/Update/${id}/${pointindex}`}><Button>Update</Button></Link>
                <Link className='p-2 w-200 card-header card-title bd-highlight' to={'/Asset Request Form/Table/1'}><Button>Back</Button></Link>
            </div>
            <Form className="product1">
                <Row className="mb-1">
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Staff Name</Form.Label>
                        <Form.Control type="text" value={staff} readOnly />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Date</Form.Label>
                        <Form.Control type="date" value={default_Value} />
                    </Form.Group>
                    <Form.Group as={Col} md="12" controlId="formBasicName">
                        <Form.Label className="label">Remarks</Form.Label>
                        <Form.Control as="textarea" rows={3} value={remarks} name='remarks' readOnly placeholder='Enter Remarks' autoComplete="off" />
                    </Form.Group>
                </Row><br></br>
                {/* <Row className="mb-1">
                    <Form.Group as={Col} md="12" controlId="formBasicName">
                        <Form.Label className="label">Assest</Form.Label>
                        <Select   />
                    </Form.Group>
                </Row>
                <br></br>
                <Table striped responsive hover size="sm">
                <thead className='p-3 mb-2 bg-success text-white'>
                        <tr>
                            <th>Assest Name</th>
                            <th>Qty</th>
                            <th>Rate</th>
                            <th>Amount</th>
                        </tr>
                        </thead>
                </Table> */}
            </Form>
        </MDBCard>
    )
}