// import { SET_DISTRIBUTOR_ID } from './actionTypes';

const initialState = {
    // items: [],
    data: null
};

const APrint1aprintcartonred = (state = initialState, action) => {
  switch (action.type) {
 
            case 'SET_DATA12':
              return {
                  ...state,
                  data: action.payload
              };
  default:
      return state;
  
  
  
  
    }




};

export default APrint1aprintcartonred;