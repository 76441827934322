import axios from "axios";
import { MDBCard } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../services/Api";
import authHeader from "../services/auth-header";
import Swal from "sweetalert2";


export default function View(){
const{pointindex}=useParams();
    const { id } = useParams();
    const [ data, setdata ] = useState('');
    const [ states, setstates ] = useState('');
    const [ subcontact, setcontact ] = useState('');
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const applyFontSizeTransition = (elementId) => {
      const element = document.getElementById(elementId);
      element.style.transition = 'font-size 2s';
      element.style.fontSize = '30px';
      element.style.fontWeight = '600';
      // font-weight: 600;
      setTimeout(() => {
         
        document.getElementById("typer1").classList.add('promote');
        element.style.fontSize = '24px';
      }, 1000);
    };
    const [addresses, setAddresses] = useState([]);  
    const handleChange = (index, event) => {
        // console.log(index,event)
        const newAddresses = [...addresses];
        newAddresses[index][event.target.name] = event.target.value;
        setAddresses(newAddresses);
      };
      const addAddress = () => {
      console.log(addAddress)
        setAddresses([...addresses, { supplier_delivery_address: '',state:"",pincode:"" }]);
      };
      const removeAddress = (index) => {
        const newAddresses = [...addresses];
        newAddresses.splice(index, 1);
        setAddresses(newAddresses);
      }; 
    let gatsupplierdata = ( ) =>{

        axios.get( BASE_URL + 'supplier/getById/' + id,{
            headers:authHeader()
        }).then(res=>{
            console.log("mello",res.data);
            setdata(res.data);
            setstates(res.data.states.name)
            setAddresses(res.data.supplierAddresses);

            // setcontact(res.data.map((item)=>item.supplierSubContacts))
            setcontact((res.data.supplierSubContacts))
        })
    };

    useEffect(() => {

        gatsupplierdata();

    },[])



    const getItem = (id) => {

        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.isConfirmed) {
            setIsLoading(true); 
            axios.delete(BASE_URL + 'supplier/delete/' + id, {
              headers: authHeader()
            }).then(res => {
             
            //   navi  SupplierTable
if(res.status==200){
    Swal.fire(
        'Deleted!',
        'Your file has been deleted.',
        'success'
      )
    //   window.location.reload();
      navigate(`/SupplierTable/${pointindex}`)
}
            }).catch(error => {
                console.error('Error adding  :', error);
                if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `
                    <div>
                      <p id="issueText">Why do I have this issue?</p>
                      <ul>
                  
                      <li id="typer1">You might have an internet issue</li>
                      </ul>
                    </div>
                  `,
                  didOpen: () => {
                    applyFontSizeTransition('issueText');
                  }
                });
              }
              })
              .finally(() => {
                setIsLoading(false); 
              });
          
          }
        })
      };




    return(
        <MDBCard>
        <div className='d-flex bd-highlight'>
            <div className="card-header card-title w-100">View Supplier</div>
            <Link  to={`/SupplierUpdate/${id}/${pointindex}`}><Button className='btn btn-sm btn-secondary'> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="svgbuttonheight  feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"/><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"/></svg></Button></Link>
            {/* <a class="delete btn btn-sm  red btn"  onClick={()=>getItem(id)}  ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class=" feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg></a> */}
            <Link className='p-2 w-200 card-header card-title bd-highlight' variant="primary" style={{width:'auto'}} onClick={()=>getItem(id)} disabled={isLoading}>
        
        <Button className="btn-sm">  {isLoading ? 'Loading...' : 'Delete'}</Button>
      </Link>
            <Link  to={`/Supplier/Contact/${id}`}><Button className='btn btn-sm btn-secondary'>Contact</Button></Link>

            <Link  to={`/SupplierTable/${pointindex}`}><Button className='btn btn-sm btn-secondary'>Back</Button></Link>
        </div>
        <Form className="product1">
            <Row className="mb-1">
                <Form.Group as={Col} md="12" controlId="formBasicName">
                    <Form.Label className="label">Company Name</Form.Label>
                    <Form.Control type="text" value={data.companyname} readOnly />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="formBasicName">
                    <Form.Label className="label">Supplier Name</Form.Label>
                    <Form.Control type="text" value={data.suppliername} />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="formBasicName">
                    <Form.Label className="label">Phone Number</Form.Label>
                    <Form.Control type="number" value={data.phonenumber} readOnly/>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="formBasicName">
                    <Form.Label className="label">GST Number</Form.Label>
                    <Form.Control type="text" value={data.gstno} readOnly />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Supplier</Form.Label>
             <span>&nbsp;{data.colourtype}</span>
          </Form.Group>
                <Form.Group as={Col} md="6" controlId="formBasicName">
                    <Form.Label className="label">Email</Form.Label>
                    <Form.Control type="text" value={data.email} />
                </Form.Group>
                <Form.Group as={Col} md="12" controlId="formBasicName">
                    <Form.Label className="label">Address</Form.Label>
                    <Form.Control as="textarea" value={data.address} readOnly/>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="formBasicName">
                    <Form.Label className="label">Country</Form.Label>
                    <Form.Control type="text" value={"India"} readOnly/>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="formBasicName">
                    <Form.Label className="label">State</Form.Label>
                    <Form.Control type="text" value={states} />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="formBasicName">
                <Form.Label className="label">State Code</Form.Label>
                <Form.Control type="text" value={data.statecode}  readOnly/>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="formBasicName">
                    <Form.Label className="label">City</Form.Label>
                    <Form.Control type="text" value={data.cities} />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="formBasicName">
                    <Form.Label className="label">Zip Code</Form.Label>
                    <Form.Control type="text"  value={data.zipcode}/>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="formBasicName">
                    <Form.Label className="label">Account No</Form.Label>
                    <Form.Control type="number" value={data.accountno}  />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="formBasicName">
                    <Form.Label className="label">Bank Name</Form.Label>
                    <Form.Control type="text" value={data.bankname} />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="formBasicName">
                    <Form.Label className="label">Branch Name</Form.Label>
                    <Form.Control type="text" value={data.branch} />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="formBasicName">
                    <Form.Label className="label">IFSC Code</Form.Label>
                    <Form.Control type="text" value={data.ifsc} />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="formBasicName">
                    <Form.Label className="label">Opening Balance</Form.Label>
                    <Form.Control type="text" value={data.openingbal}  />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="formBasicName">
                    <Form.Label className="label">Terms of payments</Form.Label>
                    <Form.Control type="text" value={data.termsofpayment}  />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="formBasicName">
                    <Form.Label className="label">Opening Balance Type</Form.Label>
                    <Form.Select value={data.openingbaltype} >
                        <option value="debit">Debit</option>
                        <option value="credit">Credit</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="formBasicName">
                    <Form.Label className="label">Opening Balance Date</Form.Label>
                    <Form.Control type="date" value={data.openingbaldate} />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="formBasicName">
                <Form.Label className="label">Password</Form.Label>
                <Form.Control type="text"  />
                </Form.Group>
            </Row>
            {/* <h3>Delivery Address</h3> */}
            <div className="row">
                  <div className="col">
                    {/* <button type='button' className="btn btn-sm btn-primary" onClick={addFormFields}>
                      +
                    </button> */}
                  </div>
                </div>
<div className="row">
              <div className="col-6 card">
                 {/* <label>Adresss</label> */}
                 {addresses.map((address, index) => (
                  <div key={index}>
                    <div className="flexleft"> <label>Address</label> 
                     
                 </div>
                    <textarea
                      type='text'
                      name='supplier_delivery_address'
                      className="form-control"
                      value={address.supplier_delivery_address}
                      onChange={(e) => handleChange(index, e)}
                    ></textarea>
                    <label className="form-label">Statename</label>
                    <input type="text" onChange={(e) => handleChange(index, e)} name='state' className="form-control" value={address.state} />
                    <label className="form-label">Pincode</label>
                    <input type="number" onChange={(e) => handleChange(index, e)} name='pincode' className="form-control" value={address.pincode} />

                    {/* <button type='button' className="btn" onClick={() => removeFormFields(index)}>
            Remove Address
          </button> */}
                  </div>
                ))}
              

              </div>
            </div>     
        </Form>
        
<div className="card">
    <div className="card-header white">
<h1>Sub Contact Detail</h1>
    </div>
    <div className="card-body">
<table className="table thrtable ">
    <tr className="white">
        <th>Sr.no</th>
        <th>contactname</th>
        <th>email</th>
        <th>Phone no</th>
    </tr>

    {
     subcontact.length!==0?subcontact.map((item)=>{
            return <>
            <tr>
                <td>{item.id}</td>
                <td>{item.contactname}</td>
                <td>{item.email}</td>
                <td>{item.phoneno}</td>
            </tr>
            
            </>
        }):""
    }

    
</table>


    </div>
</div>



    </MDBCard>
    )
}