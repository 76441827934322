import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import BrandService from "../../services/Brand";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from 'sweetalert2';
import { AiOutlineDelete } from "react-icons/ai";
export default function Create(){
    const {id} = useParams();

    const {pointindex} = useParams();
    
const[brandName,setbrandName]=useState('');
let Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})
useEffect(()=>{
    BrandService.getBrandEdit(id).then(res=>{
      setbrandName(res.data.brandName);
    })

//     const latitude = 37.7749;
// const longitude = -122.4194;

// const redirectToGoogleMaps = () => {
//   const url = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
//   window.open(url, '_blank');
// };

// redirectToGoogleMaps()
},[])
const [isLoading, setIsLoading] = useState(false);
const applyFontSizeTransition = (elementId) => {
  const element = document.getElementById(elementId);
  element.style.transition = 'font-size 2s';
  element.style.fontSize = '30px';
  element.style.fontWeight = '600';
  // font-weight: 600;
  setTimeout(() => {
     
    document.getElementById("typer1").classList.add('promote');
    element.style.fontSize = '24px';
  }, 1000);
};
const navigate = useNavigate();
const submitform = () =>{
    let brand = {brandName};

    setIsLoading(true); 
    BrandService.getBrandUpdate(id,brand).then(res=>{
      //  navigate('/brand')
       if(res.status==200){
        // alert("ee")
        Toast.fire({
          icon: 'success',
          title: 'Successfully Updated'
        })
      }
      
      navigate(`/brand/${pointindex}`)

    })  
    .catch(error => {
      console.error('Error adding  :', error);
      if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `
          <div>
            <p id="issueText">Why do I have this issue?</p>
            <ul>
        
            <li id="typer1">You might have an internet issue</li>
            </ul>
          </div>
        `,
        didOpen: () => {
          applyFontSizeTransition('issueText');
        }
      });
    }
    })

    .finally(() => {
      setIsLoading(false); 
    });
}
const getItem = (id) =>{


  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  }).then((result) => {
    if (result.isConfirmed) {
      setIsLoading(true); 
      BrandService.getDelete(id).then(res=>{


        navigate(`/brand/${pointindex}`)
       
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        )



        // window.location.reload();
      }).catch(error => {
        console.error('Error adding  :', error);
        if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `
            <div>
              <p id="issueText">Why do I have this issue?</p>
              <ul>
          
              <li id="typer1">You might have an internet issue</li>
              </ul>
            </div>
          `,
          didOpen: () => {
            applyFontSizeTransition('issueText');
          }
        });
      }
      })
      .finally(() => {
        setIsLoading(false); 
      });
    
    }
  })


};  

    return(
        <div data-aos="fade" class>
             <h1>EDIT BRAND</h1>
           <Link to={`/brand/${pointindex}`}><button className="btn btn-primary">Back</button></Link>
           {/* <Link onClick={()=> getItem(id)} className="" id='pb'>
           <button className="btn btn-primary  ">Delete</button> 
             </Link> */}

             <Link className='p-2 w-200 card-header card-title bd-highlight' variant="primary" style={{width:'auto'}} onClick={()=>getItem(id)} disabled={isLoading}>
        
        <Button className="btn-sm">  {isLoading ? 'Loading...' : 'Delete'}</Button>
      </Link>
      <Form className='product'>
      <Row className="mb-3">
      <Form.Group as={Col} md="12" controlId="formBasicName">
        <Form.Label className="cname2">Brand Name</Form.Label>
        <Form.Control type="text" autoComplete="off" value={brandName} name="brandName" onChange={(e)=>setbrandName(e.target.value)}  />
      </Form.Group>
      </Row>
      {/* <Button variant="primary"  onClick={submitform}>
        Submit
      </Button> */}
      <Button variant="primary" style={{width:'auto'}} onClick={submitform} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Submit'}
        </Button>
      </Form>
        </div>
    )
}