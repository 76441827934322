import { PUT_EDIT_DATA_TO_LOAD_WORKORDER } from "../constant";
import { PUT_NEW_EDIT_ADD_TO_CART_WORKORDER } from "../constant";
import { CHANGE_EDIT_MEASUREMENT_WORKORDER } from "../constant";
import { REMOVE_FROM_CART_FROM_EDIT_WORKORDER } from "../constant";
import { CHANGE_COST_EDIT_WORKORDER } from "../constant";
import { CHANGE_EDIT_DISCOUNT1_WORKORDER } from "../constant";
import { CHANGE_EDIT_TEXT_WORKORDER } from "../constant";
import { EMPTY_EDIT_DATA_WORKORDER } from "../constant";
import { EDIT_CHANGE_PER_WORKORDER } from "../constant";
import { CHANGE_EDIT_QTY_WORKORDER } from "../constant";
import { CHANGE_STAFFCANCLEQTY } from "../constant";
import { CHANGE_RETAILERCANCLEQTY } from "../constant";

export const workordereditdata = (data = [], action) => {

    switch (action.type) {
        case PUT_EDIT_DATA_TO_LOAD_WORKORDER:
            return [...action.data];


        case PUT_NEW_EDIT_ADD_TO_CART_WORKORDER:
            let newData = action.data2;
            let dataNew = action.data;

            function discountData() {
                if (dataNew.productType == "boxproduct") {
                    return ({ "ProductDiscount": newData.boxProductDiscount, "schemeDiscount": newData.schemeboxProductDiscount });
                }
                else if (dataNew.productType == "kgproduct") {
                    return ({ "ProductDiscount": newData.kgProductDiscount, "schemeDiscount": newData.schemekgProductDiscount });
                }
                else if (dataNew.productType == "corporateproduct") {
                    return ({ "ProductDiscount": newData.corporaetProductDiscount, "schemeDiscount": newData.schemecorporateProductDiscount });
                }
                else if (dataNew.productType == "cookerproduct") {
                    return ({ "ProductDiscount": newData.cookerProductDiscount, "schemeDiscount": newData.schemecookerProductDiscount });
                }
                else if (dataNew.productType == "noshproduct") {
                    return ({ "ProductDiscount": newData.noshProductDiscount, "schemeDiscount": newData.schemenoshProductDisocunt });
                }
            }
            let aa = discountData();
            const item = {
                id: action.data.id,
                brand: action.data.brand,
                capacity: action.data.capacity,
                category: action.data.category,
                cgst: action.data.cgst,
                cgstLedger: action.data.cgstLedger,
                diameter: action.data.diameter,
                eanCode: action.data.eanCode,
                hsnCode: action.data.hsnCode,
                igst: action.data.igst,
                igstLedger: action.data.igstLedger,
                dlp: action.data.dlp,
                productName: action.data.productName,
                productType: action.data.productType,
                standardQtyPerBox : action.data.standardQtyPerBox,
                sgst: action.data.sgst,
                sgstLedger: action.data.sgstLedger,
                shortName: action.data.shortName,
                shortName: action.data.shortName,
                uomPrimary: action.data.uomPrimary,
                uomSecondary: action.data.uomSecondary,
                discount: aa.ProductDiscount,
                discount1: aa.schemeDiscount,
                unitofmeasurement: action.data.unitofmeasurement == undefined ? action.data.productType !== "kgproduct" ? "box" : '' : action.data.unitofmeasurement
            }
            // console.log("mello5",item)
            return [item, ...data];

        case CHANGE_STAFFCANCLEQTY:
            let newCart6 = [...data]
            let itemIndex6 = newCart6.findIndex(obj => obj.id === action.item.id)
            let currItem6 = data[itemIndex6]
            currItem6.staffCancleQty = action.data
            data[itemIndex6] = currItem6
            return [...data];

            case CHANGE_RETAILERCANCLEQTY:
                let newCart7 = [...data]
                let itemIndex7 = newCart7.findIndex(obj => obj.id === action.item.id)
                let currItem7 = data[itemIndex7]
                currItem7.retailerCancleQty = action.data
                data[itemIndex7] = currItem7
                return [...data];

        case CHANGE_EDIT_MEASUREMENT_WORKORDER:

            let newCart3 = [...data]
            let itemIndex3 = newCart3.findIndex(obj => obj.id === action.item.id)
            let currItem3 = data[itemIndex3]
            currItem3.unitofmeasurement = action.data
            data[itemIndex3] = currItem3
            // console.log("mello", currItem3.unitofmeasurement)


            return [...data];

        case CHANGE_EDIT_QTY_WORKORDER:

            let newCart4 = [...data]
            let itemIndex4 = newCart4.findIndex(obj => obj.id === action.item.id)
            let currItem4 = data[itemIndex4]
            // currItem4.measurement = Number(action.data)

            if (action.data2 == "kg") {

                if (currItem4.discount > 0 || currItem4.discount1 > 0) {
                    if (action.data == "") {
                        currItem4.qty = '';
                        currItem4.measurement = '';
                    } else {
                        currItem4.qty = (action.data)
                        let aa = Number(currItem4.qty);
                        let ab = (Number(aa) / currItem4.uomSecondary)
                        currItem4.measurement = Math.round(ab);

                    }
                    let discount2 = Number(currItem4.qty) * Number(currItem4.dlp) * (Number(currItem4.discount) / 100)
                    currItem4.grossamount = (Number(currItem4.qty) * Number(currItem4.dlp)).toFixed(2);
                    let totaAmount = (Number(currItem4.qty) * Number(currItem4.dlp) - Number(discount2))
                    let schemediscount = ((Number(totaAmount) * Number(currItem4.discount1)) / 100)
                    let netAmount = (Number(totaAmount) - Number(schemediscount)).toFixed(2);
                    currItem4.total = ((Number(netAmount) * 100) / (Number(100) + Number(currItem4.igst))).toFixed(2);
                    // console.log("mello5",netAmount,currItem4.total)
                    currItem4.productId = currItem4.id
                    // currItem4.total = parseInt(currItem4.qty) * parseInt(currItem4.dlp)
                    currItem4.gstvalue = (Number(netAmount) - Number(currItem4.total)).toFixed(2);
                    currItem4.amount = (Number(currItem4.gstvalue) + Number(currItem4.total)).toFixed(2);
                    data[itemIndex4] = currItem4
                } else {

                    if (action.data == "") {
                        currItem4.qty = '';
                        currItem4.measurement = '';
                    } else {
                        currItem4.qty = (action.data)
                        let aa = Number(currItem4.qty);
                        let ab = (Number(aa) / currItem4.uomSecondary)
                        currItem4.measurement = Math.round(ab);
                    }
                    currItem4.discount = Number(0)
                    currItem4.productId = currItem4.id
                    currItem4.grossamount = (Number(currItem4.qty) * Number(currItem4.dlp)).toFixed(2);
                    let netAmount = (Number(currItem4.qty) * Number(currItem4.dlp)).toFixed(2);
                    currItem4.total = ((Number(netAmount) * 100) / (Number(100) + Number(currItem4.igst))).toFixed(2);
                    currItem4.gstvalue = (Number(netAmount) - Number(currItem4.total)).toFixed(2)
                    currItem4.amount = (Number(currItem4.gstvalue) + Number(currItem4.total)).toFixed(2);
                    data[itemIndex4] = currItem4


                }
            }

            return [...data]

        case EDIT_CHANGE_PER_WORKORDER:

            let newCart = [...data]
            let itemIndex = newCart.findIndex(obj => obj.id === action.item.id)
            let currItem = data[itemIndex]

            if (action.data2 == "pcs") {

                if (currItem.discount > 0 || currItem.discount1 > 0) {
                    if (action.data == "") {
                        currItem.measurement = Number(0)
                        currItem.qty = currItem.measurement
                    } else {
                        currItem.measurement = Number(action.data)
                        let aa = Number(currItem.measurement)
                        currItem.qty = (Number(aa) * Number(currItem.uomSecondary))
                    }

                    let discount2 = Number(currItem.qty) * Number(currItem.dlp) * (Number(currItem.discount) / 100)
                    currItem.grossamount = (Number(currItem.qty) * Number(currItem.dlp)).toFixed(2);
                    let totaAmount = Number(currItem.qty) * Number(currItem.dlp) - Number(discount2);
                    let schemediscount = ((Number(totaAmount) * Number(currItem.discount1)) / 100)
                    let netAmount = (Number(totaAmount) - Number(schemediscount)).toFixed(2);
                    currItem.total = ((Number(netAmount) * 100) / (Number(100) + Number(currItem.igst))).toFixed(2);
                    currItem.productId = currItem.id
                    // currItem.total = parseInt(currItem.qty) * parseInt(currItem.dlp)
                    currItem.gstvalue = (Number(netAmount) - Number(currItem.total)).toFixed(2);
                    currItem.amount = (Number(currItem.gstvalue) + Number(currItem.total)).toFixed(2);
                    data[itemIndex] = currItem

                } else {

                    if (action.data == "") {
                        currItem.measurement = Number(0)
                        currItem.qty = currItem.measurement
                    } else {
                        currItem.measurement = Number(action.data)
                        let aa = Number(currItem.measurement)
                        currItem.qty = (aa) * currItem.uomSecondary
                    }
                    currItem.discount = Number(0)
                    currItem.productId = currItem.id
                    currItem.grossamount = (Number(currItem.qty) * Number(currItem.dlp)).toFixed(2);
                    let netAmount = (Number(currItem.qty) * Number(currItem.dlp)).toFixed(2);
                    currItem.total = ((Number(netAmount) * 100) / (Number(100) + Number(currItem.igst))).toFixed(2);
                    currItem.gstvalue = (Number(netAmount) - Number(currItem.total)).toFixed(2);
                    currItem.amount = (Number(currItem.gstvalue) + Number(currItem.total)).toFixed(2);
                    data[itemIndex] = currItem


                }

            }
            if (currItem.unitofmeasurement == "box") {

                if (currItem.discount > 0 || currItem.discount1 > 0) {
                    if (action.data == "") {
                        currItem.measurement = Number(0)
                    } else {

                        currItem.measurement = Number(action.data)
                    }

                    let discount2 = Number(currItem.measurement) * Number(currItem.dlp) * (Number(currItem.discount) / 100)
                    currItem.grossamount = (Number(currItem.measurement) * Number(currItem.dlp)).toFixed(2);
                    let totaAmount = Number(currItem.measurement) * Number(currItem.dlp) - Number(discount2);
                    let schemediscount = ((Number(totaAmount) * Number(currItem.discount1)) / 100)
                    let netAmount = (Number(totaAmount) - Number(schemediscount)).toFixed(2);
                    currItem.total = ((Number(netAmount) * 100) / (Number(100) + Number(currItem.igst))).toFixed(2);
                    // console.log("mello5",currItem.grossamount)
                    currItem.productId = currItem.id
                    // currItem.total = parseInt(currItem.measurement) * parseInt(currItem.dlp)
                    currItem.gstvalue = (Number(netAmount) - Number(currItem.total)).toFixed(2);
                    currItem.amount = (Number(currItem.gstvalue) + Number(currItem.total)).toFixed(2);
                    data[itemIndex] = currItem

                } else {

                    if (action.data == "") {
                        currItem.measurement = Number(0)
                    } else {
                        currItem.measurement = Number(action.data)
                    }
                    currItem.discount = Number(0)
                    currItem.productId = currItem.id
                    currItem.grossamount = (Number(currItem.measurement) * Number(currItem.dlp)).toFixed(2);
                    let netAmount = (Number(currItem.measurement) * Number(currItem.dlp)).toFixed(2);
                    currItem.total = ((Number(netAmount) * 100) / (Number(100) + Number(currItem.igst))).toFixed(2);
                    currItem.gstvalue = (Number(netAmount) - Number(currItem.total)).toFixed(2);
                    currItem.amount = (Number(currItem.gstvalue) + Number(currItem.total)).toFixed(2);
                    data[itemIndex] = currItem


                }

            }

            return [...data];


        case REMOVE_FROM_CART_FROM_EDIT_WORKORDER:

            const remainingitem = data.filter((item) => item.id !== action.data);
            return [...remainingitem];


        case CHANGE_COST_EDIT_WORKORDER:

            let newCart1 = [...data]
            let itemIndex1 = newCart1.findIndex(obj => obj.id === action.item.id)
            let currItem1 = data[itemIndex1]

            if (action.data2 == "pcs" || action.data2 == "kg") {
                if (currItem1.discount > 0 || currItem1.discount1 > 0) {

                    if (action.data == "") {
                        currItem1.dlp = ""
                    } else {
                        currItem1.dlp = Number(action.data)
                    }
                    // currItem1.dlp = parseInt(action.data)
                    let discount3 = Number(currItem1.qty) * Number(currItem1.dlp) * (Number(currItem1.discount) / 100)
                    currItem1.grossamount = (Number(currItem1.qty) * Number(currItem1.dlp)).toFixed(2);
                    let totaAmount = Number(currItem1.qty) * Number(currItem1.dlp) - Number(discount3)
                    let schemediscount = ((Number(totaAmount) * Number(currItem1.discount1)) / 100)
                    let netAmount = (Number(totaAmount) - Number(schemediscount)).toFixed(2);
                    currItem1.total = ((Number(netAmount) * 100) / (Number(100) + Number(currItem1.igst))).toFixed(2);
                    // currItem1.total = parseInt(currItem1.qty) * parseInt(currItem1.dlp)
                    currItem1.gstvalue = (Number(netAmount) - Number(currItem1.total)).toFixed(2);
                    currItem1.amount = (Number(currItem1.gstvalue) + Number(currItem1.total)).toFixed(2);
                    data[itemIndex1] = currItem1
                    return [...data];
    
                } else {
    
                    if (action.data == "") {
                        currItem1.dlp = ""
                    } else {
                        currItem1.dlp = Number(action.data)
                    }
                    // currItem1.dlp = parseInt(action.data)
                    currItem1.discount = Number(0)
                    currItem1.grossamount = (Number(currItem1.qty) * Number(currItem1.dlp)).toFixed(2);
                    let netAmount = (Number(currItem1.qty) * Number(currItem1.dlp)).toFixed(2);
                    currItem1.total = ((Number(netAmount) * 100) / (Number(100) + Number(currItem1.igst))).toFixed(2);
                    currItem1.gstvalue = (Number(netAmount) - Number(currItem1.total)).toFixed(2);
                    currItem1.amount = (Number(currItem1.gstvalue) + Number(currItem1.total)).toFixed(2);
                    data[itemIndex1] = currItem1
                    return [...data];
    
                }
            }
            else if (currItem1.unitofmeasurement == "box") {

                if (currItem1.discount > 0 || currItem1.discount1 > 0) {

                    if (action.data == "") {
                        currItem1.dlp = ""
                    } else {
                        currItem1.dlp = Number(action.data)
                    }
                    // currItem1.dlp = parseInt(action.data)
                    let discount3 = Number(currItem1.measurement) * Number(currItem1.dlp) * (Number(currItem1.discount) / 100)
                    currItem1.grossamount = (Number(currItem1.measurement) * Number(currItem1.dlp)).toFixed(2);
                    let totaAmount = Number(currItem1.measurement) * Number(currItem1.dlp) - Number(discount3)
                    let schemediscount = ((Number(totaAmount) * Number(currItem1.discount1)) / 100)
                    let netAmount = (Number(totaAmount) - Number(schemediscount)).toFixed(2);
                    currItem1.total = ((Number(netAmount) * 100) / (Number(100) + Number(currItem1.igst))).toFixed(2);
                    // currItem1.total = parseInt(currItem1.measurement) * parseInt(currItem1.dlp)
                    currItem1.gstvalue = (Number(netAmount) - Number(currItem1.total)).toFixed(2);
                    currItem1.amount = (Number(currItem1.gstvalue) + Number(currItem1.total)).toFixed(2);
                    data[itemIndex1] = currItem1
                    return [...data];
    
                } else {
    
                    if (action.data == "") {
                        currItem1.dlp = ""
                    } else {
                        currItem1.dlp = Number(action.data)
                    }
                    // currItem1.dlp = parseInt(action.data)
                    currItem1.discount = Number(0)
                    currItem1.grossamount = (Number(currItem1.measurement) * Number(currItem1.dlp)).toFixed(2);
                    let netAmount = (Number(currItem1.measurement) * Number(currItem1.dlp)).toFixed(2);
                    currItem1.total = ((Number(netAmount) * 100) / (Number(100) + Number(currItem1.igst))).toFixed(2);
                    currItem1.gstvalue = (Number(netAmount) - Number(currItem1.total)).toFixed(2);
                    currItem1.amount = (Number(currItem1.gstvalue) + Number(currItem1.total)).toFixed(2);
                    data[itemIndex1] = currItem1
                    return [...data];
    
                }
            }

        case CHANGE_EDIT_DISCOUNT1_WORKORDER:

            let newCart2 = [...data]
            let itemIndex2 = newCart2.findIndex(obj => obj.id === action.item.id)
            let currItem2 = data[itemIndex2]

            if (action.data2 == "pcs" || action.data2 == "kg") {
                if (action.data == "") {
                    currItem2.discount1 = 0
                } else {
                    currItem2.discount1 = Number(action.data)
                }
    
                // currItem1.dlp = parseInt(action.data)
                let discount1 = Number(currItem2.qty) * Number(currItem2.dlp) * (Number(currItem2.discount) / 100)
                currItem2.grossamount = (Number(currItem2.qty) * Number(currItem2.dlp)).toFixed(2);
                let totaAmount = Number(currItem2.qty) * Number(currItem2.dlp) - Number(discount1)
                let schemediscount = ((Number(totaAmount) * Number(currItem2.discount1)) / 100)
                let netAmount = (Number(totaAmount) - Number(schemediscount)).toFixed(2);
                currItem2.total = ((Number(netAmount) * 100) / (Number(100) + Number(currItem2.igst))).toFixed(2);
                // currItem1.total = parseInt(currItem1.qty) * parseInt(currItem1.dlp)
                currItem2.gstvalue = (Number(netAmount) - Number(currItem2.total)).toFixed(2);
                currItem2.amount = (Number(currItem2.gstvalue) + Number(currItem2.total)).toFixed(2);
                data[itemIndex2] = currItem2

            }
            else if (currItem2.unitofmeasurement == "box") {

                if (action.data == "") {
                    currItem2.discount1 = 0
                } else {
                    currItem2.discount1 = Number(action.data)
                }
    
                // currItem1.dlp = parseInt(action.data)
                let discount1 = Number(currItem2.measurement) * Number(currItem2.dlp) * (Number(currItem2.discount) / 100)
                currItem2.grossamount = (Number(currItem2.measurement) * Number(currItem2.dlp)).toFixed(2);
                let totaAmount = Number(currItem2.measurement) * Number(currItem2.dlp) - Number(discount1)
                let schemediscount = ((Number(totaAmount) * Number(currItem2.discount1)) / 100)
                let netAmount = (Number(totaAmount) - Number(schemediscount)).toFixed(2);
                currItem2.total = ((Number(netAmount) * 100) / (Number(100) + Number(currItem2.igst))).toFixed(2);
                // currItem1.total = parseInt(currItem1.measurement) * parseInt(currItem1.dlp)
                currItem2.gstvalue = (Number(netAmount) - Number(currItem2.total)).toFixed(2);
                currItem2.amount = (Number(currItem2.gstvalue) + Number(currItem2.total)).toFixed(2);
                data[itemIndex2] = currItem2

            }

           

            return [...data];


        case CHANGE_EDIT_TEXT_WORKORDER:

            let newCart5 = [...data]
            let itemIndex5 = newCart5.findIndex(obj => obj.id === action.item.id)
            let currItem5 = data[itemIndex5]

            currItem5.text = action.data
            data[itemIndex5] = currItem5
            return [...data];


        case EMPTY_EDIT_DATA_WORKORDER:
            // console.warn("reducer called",action)
            return [];



        default:
            return data;
    }


}
