import axios from "axios";
import authHeader from "./auth-header";

const API_URL = "http://43.225.54.138:8080/scm/api/company/"; 


const getcompany = (id) => {
    return axios.get(API_URL +"getById/"+id , { headers: authHeader() });
  };

const getcompanyEdit = (id,company) => {
  return axios.put(API_URL + "update/"+id, company,{ headers: authHeader() });
};

const getpurchase = (id) => {
  return axios.get(API_URL + "ProductByWareCheck/"+id,{ headers: authHeader() });
};

const CompanyService = {
    getcompany,
    getcompanyEdit,
    getpurchase

};

export default CompanyService;