import axios from "axios";
import { MDBCard } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Accordion, Button, Col, Form, Row, Table } from "react-bootstrap";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../services/Api";
import authHeader from "../services/auth-header";
import Select from "react-select";
import Swal from "sweetalert2";
import AuthService from "../services/auth.service";

export default function View() {

    const { pointindex } = useParams();
    const { id } = useParams();
    const [branch, setbranch] = useState('');
    const [warehouse, setwarehouse] = useState('');
    const [customerdata, setcustomerdata] = useState('');
    const [data, setData] = useState('');
    const [date, setDate] = useState('');
    const [salesOrderItems, setsalesOrderItems] = useState([]);
    const [state, setstate] = useState('');
    const [deliveryAddress, setdelval] = useState([]);
    const [disdel, setdel] = useState([]);
    const user = AuthService.getCurrentUser();

    const navigate = useNavigate();
    const getItem = (id) => {

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {

                axios.delete(BASE_URL + 'so/' + id, {
                    headers: authHeader()
                }).then(res => {
                    // window.location.reload();
                    navigate(`/SalesOrderTable/${pointindex}`)
                })

                Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                )
            }
        })
    };
    const getsalesorderdata = () => {

        axios.get(BASE_URL + 'so/' + id, {
            headers: authHeader()
        }).then(res => {
            (res.data.retailerstatus === 'customer' ? setdel(res.data.retailer.deliveryAddress) : setdel(res.data.distributor.deliveryAddress))
            setSelectedOption(res.data.deliveryAddress)
            // setvoucher(res.data.voucherid);

            setdelval(res.data.deliveryAddress)
            console.log("mello", res.data);
            setbranch(res.data.branch);
            setwarehouse(res.data.warehouse);
            (res.data.retailerstatus === 'customer' ? setcustomerdata(res.data.retailer) : setcustomerdata(res.data.distributor))
            setDate(res.data.sodate);
            setData(res.data);
            setsalesOrderItems(res.data.salesOrderItems);
            (res.data.retailerstatus === 'customer' ? setstate(res.data.retailer) : setstate(res.data.distributor))

        })
    }

    const [selectedOption, setSelectedOption] = useState(null);

    const handleSelectChange = (selectedOption) => {
        setSelectedOption(selectedOption);

        setdelval(selectedOption)
    };

    let date_value = new Date(date);
    const defaultValue = date_value.toLocaleDateString('en-CA');

    useEffect(() => {
        getsalesorderdata()
    }, []);



    return (
        <MDBCard className="company">





            {data.status == 'converted' ? (
                <div className='d-flex bd-highlight'>
                    <h1>Sales Order View</h1>

                    <Link className='p-2 w-200 card-header btn-sm card-title bd-highlight' to={`/SalesOrderTable/${pointindex}`}><Button className="btn-sm">Back</Button></Link>

                    <Link to={'/sprint/' + id} className='p-2 w-200 card-header card-title bd-highlight' target="_blank">
                        <button className="btn btn-sm btn-primary">Print</button>
                    </Link>
                </div>
            ) : (
                <div className='d-flex bd-highlight'>
                    <h1>Sales Order View</h1>
                    {/* <button className='prox btn' onClick={()=>handleChang/e12()} ></button> */}
                    {/* <Link className='p-2 w-200 card-header  btn-sm card-title bd-highlight    ' onClick={()=>getItem(id)}><Button className="delete btn-sm"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"/><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"/><line x1="10" y1="11" x2="10" y2="17"/><line x1="14" y1="11" x2="14" y2="17"/></svg></Button></Link> */}
                    {user.roles[0] === 'ROLE_ADMIN' && <Link className='p-2 w-200 card-header  btn-sm card-title bd-highlight'  ><Button className="btn-sm" onClick={() => getItem(id)}>Delete</Button></Link>}
                    {/* <Link className='p-2 w-200 card-header  btn-sm card-title bd-highlight' to={`/poedit/${id}/${pointindex}`}><Button className="btn-sm">Edit</Button></Link> */}
                    {data.retailerstatus === 'customer' ? <Link className='p-2 w-200 card-header  btn-sm card-title bd-highlight' to={'/RetailerDeliverychallanCreate/' + id} ><Button className="btn-sm">DC</Button></Link> :
                        <Link className='p-2 w-200 card-header  btn-sm card-title bd-highlight' to={'/DeliveryChallanCreate/' + id} ><Button className="btn-sm">DC</Button></Link>}
                    <Link className='p-2 w-200 card-header btn-sm card-title bd-highlight' to={`/SalesOrderEdit/${id}/${pointindex}`}><Button className="btn-sm">Edit</Button></Link>


                    <Link className='p-2 w-200 card-header btn-sm card-title bd-highlight' to={`/SalesOrderTable/${pointindex}`}><Button className="btn-sm">Back</Button></Link>

                    <Link to={'/sprint/' + id} className='p-2 w-200 card-header card-title bd-highlight' target="_blank">
                        <button className="btn btn-sm btn-primary">Print</button>
                    </Link>
                </div>
            )}








            <Form className="product1">
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Sales Order Data</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Branch Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={branch && branch.branchname ? branch.branchname : ''}
                                        disabled
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">WareHouse Name</Form.Label>
                                    <Form.Control type="text" value={warehouse.name} disabled />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Date</Form.Label>
                                    <Form.Control type="date" value={defaultValue} />
                                </Form.Group>

                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Terms of Payment</Form.Label>
                                    <Form.Control type='text' value={data.paymentTerms} autoComplete="off" />
                                </Form.Group>

                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Customer Name</Form.Label>
                                    <Form.Control type='text' value={customerdata.tradeName} />

                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Buyer Order</Form.Label>
                                    <Form.Control type="text" value={data.buyerorder} />
                                </Form.Group>


                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Voucher Series</Form.Label>
                                    <Form.Control type="text" disabled value={data.voucherid} name="vocherno"   />
                                </Form.Group>


                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Voucher No</Form.Label>
                                    <Form.Control type="text" value={data.voucherno} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Dispatched Through</Form.Label>
                                    <Form.Control type="text" value={data.dispatchedthrough} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Destination</Form.Label>
                                    <Form.Control type="text" value={data.destination} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Terms Of Delivery</Form.Label>
                                    <Form.Control type="text" value={data.paymentTerms} />
                                </Form.Group>
                                {/* <Form.Group as={Col} md="8" controlId="formBasicName">
                                    <Form.Label className="label">Owner</Form.Label>
                                    <Form.Control type="text" autoComplete="off" />
                                </Form.Group> */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Quotation Status</Form.Label>
                                    <Form.Select value={data.quotationstatus} >
                                        <option value='true'>Open</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Tax Type</Form.Label>
                                    <Form.Select value={data.taxtype} >
                                        <option>--Select--</option>
                                        <option value='withtax'>With Tax</option>
                                        <option value='withouttax'>Without Tax</option>
                                    </Form.Select>
                                </Form.Group>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    {/* <Accordion.Item eventKey="1">
                        <Accordion.Header>Billing Address</Accordion.Header>
                        <Accordion.Body> */}
                    {/* <Row className="mb-1">
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Customer Address</Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Customer Details</Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Address : -</Form.Label>
                                    <span>&nbsp;{customerdata.deliveryAddress}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Name : -</Form.Label>
                                    <span>&nbsp;{customerdata.tradeName}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">City : -</Form.Label>
                                    <span>&nbsp;{customerdata.city}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Phone No : -</Form.Label>
                                    <span>&nbsp;{customerdata.mobNo1}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Country : -</Form.Label>
                                    <span>{"India"}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Email Id : -</Form.Label>
                                    <span> {customerdata.email1}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">State : -</Form.Label>
                                    <span>{supplierstate}</span>
                                </Form.Group>
                            </Row> */}


                    {/* <b><span className="">Delivery Address</span></b> <br></br> <br></br>
                                <Row className="mb-1"> */}
                    {/* <select className="form-select dd">
                                <option className="extrvagnze">select Address</option>
                               
                                {
                                    disdel.map((item)=>{
                                        return<>
                                          <option className="extrvagnze">Address:{item.delivery_address} ,State:-{item.state} &nbsp;,Pin-code:-{item.pincode}</option>
                                        </>
                                    })
                                }
                               </select> */}
                    {/* <Select
      options={options}
      onChange={handleSelectChange}
      filterOption={customFilter11}
      placeholder="Select Address"
      value={selectedOption}
    /> */}
                    {/* <select className="form-control" value={selectedOption} disabled onChange={(e)=>handleSelectChange(e.target.value
    )}>
    <option>Select Delivery Address</option>
    {
        disdel.map((item)=>{
            return<>
            <option value={item.id}>{item.delivery_address}</option>
            </>
        })
    }
</select>
                                
                            </Row>



                        </Accordion.Body>
                    </Accordion.Item> */}
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>Details</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="12" controlId="formBasicName">
                                    <Form.Label className="label">Remarks</Form.Label>
                                    <Form.Control as="textarea" value={data.remarks} rows={3} name='address' placeholder='Enter Remarks' />
                                </Form.Group>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <br></br>
                {/* <Row className="mb-1">
                <Form.Group as={Col} md="12" controlId="formBasicName">
                    <Form.Label className="label">Product</Form.Label>
                    <Select options={productdata} onChange={(e) => addData(e)} filterOption={customFilter}/>
                </Form.Group>
            </Row>
            <br></br> */}
                <Table striped responsive hover size="sm">
                    <thead className='p-3 mb-2 bg-success text-white'>
                        <tr>
                            <th>PRODUCT&nbsp;NAME</th>
                            <th >UOM</th>
                            <th >QTY(PCS)</th>
                            <th >QTY(KG)</th>
                            <th>SQ</th>
                            <th>SQData</th>
                            <th >Rate</th>
                            <th >Trade&nbsp;Discount</th>
                            <th>GROSS AMOUNT (₹)</th>
                            <th >SCHEME DISCOUNT %</th>
                            <th >NET AMOUNT (₹)</th>
                            <th >GST Value</th>
                            <th >Amount</th>
                        </tr>
                    </thead>
                    <tbody style={{ overflow: "scroll" }}>
                        {salesOrderItems.map(item =>
                            <tr key={item.id}>
                                <td>
                                    <Form.Group as={Col} md="12" >
                                        {/* <Form.Control type="text" value={(item.productName ? item.productName : item.product.productName)} size="sm" /> */}
                                        <textarea type="text" className="form-control textcontrol" value={(item.productName ? item.productName : item.product.productName)} size="sm" ></textarea>

                                    </Form.Group>
                                    <Form.Control as="textarea" value={item.text} rows={2} className='form-control' />
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" size="sm" value={item.unitofmeasurement || ""} />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" size="sm" value={item.someasurement || ""} />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" size="sm" value={item.unitofmeasurement == "box" ? "" : item.soqty} />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" size="sm" value={item.soquantity_placed} />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" size="sm" value={item.soquantity_placed_kg} />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" value={item.dlp} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" value={item.tradediscount} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" value={item.grossamount} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" value={item.schemeDiscount} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" value={Number(item.netAmount)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" value={Number(item.gstvalue)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" value={Number(item.total)} size="sm" />
                                    </Form.Group>
                                </td>
                                {/* <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type='button' className='btn btn-danger' value="X" size="sm" />
                                    </Form.Group>
                                </td> */}
                            </tr>
                        )}
                    </tbody>
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Packing&nbsp;&&nbsp;Forwarding</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={data.shippingcharge} style={{ height: 30 }} readOnly />
                                </Form.Group>
                            </td>

                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Net&nbsp;Amount</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={data.grossamount} style={{ height: 30 }} readOnly />
                                </Form.Group>
                            </td>

                        </tr>

                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>IGST</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={data.igst} style={{ height: 30 }} readOnly />
                                </Form.Group>
                            </td>

                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>SGST</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={data.igst == '' ? data.sgst : 0} style={{ height: 30 }} readOnly />
                                </Form.Group>
                            </td>

                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>CGST</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={data.igst == '' ? data.cgst : 0} style={{ height: 30 }} readOnly />
                                </Form.Group>
                            </td>

                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Round&nbsp;off&nbsp;Value</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={data.roundingofvalue} style={{ height: 30 }} readOnly />
                                </Form.Group>
                            </td>

                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Grand&nbsp;Total</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={data.grandtotal} style={{ height: 30 }} readOnly />
                                </Form.Group>
                            </td>

                        </tr>
                    </tbody>
                </Table>
                {/* <Button variant="primary" type="button"  onClick={submitdata}>
            Submit
        </Button> */}
            </Form>
        </MDBCard>
    )
}