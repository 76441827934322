import { SALES_GET_DATA_TO_LOAD } from "../constant";
import { SALES_ADD_TO_CART } from "../constant";
import { CHANGE_SALES_MEASUREMENT } from "../constant";
import { CHANGE_SALES_SQPB_MEASUREMENT } from "../constant";
import { CHANGE_SALES_QTY } from "../constant";
import { REMOVE_FROM_CART_FROM_SALES } from "../constant";
import { CHANGE_COST_SALES } from "../constant";
import { CHANGE_SALES_DISCOUNT } from "../constant";
import { CHANGE_SALES_TEXT } from "../constant";
import { EMPTY_SALES_DATA } from "../constant";
import { CHANGE_MEASUREMENT_SALES } from "../constant";



export const getsalesdatatoload = (data) => {

    //  console.warn("action called", data);
    return {

        type: SALES_GET_DATA_TO_LOAD,
        data: data
    }

}


export const salesaddtocart = (data, data2) => {

    //  console.warn("action called", data2);
    return {

        type: SALES_ADD_TO_CART,
        data: data,
        data2: data2
    }

}

export const changeunitofMeasurementsales = (data, item, data2) => {

    // console.warn("qty change called66", data2);
    return {

        type: CHANGE_MEASUREMENT_SALES,
        data: data,
        item: item,
        data2: data2
    }

}

export const removefromcartfromsales = (data) => {

    // console.warn("action REMOVE_TO_CART called", data);
    return {

        type: REMOVE_FROM_CART_FROM_SALES,
        data: data
    }

}

export const changesalescalmeasurement = (data, item, data2, data3) => {
    return {
        type: CHANGE_SALES_MEASUREMENT,
        data: data,
        item: item,
        data2: data2,
        data3: data3
    }
}
export const changesalessqpbmeasurement = (data, item, data2, data3) => {
    return {
        type: CHANGE_SALES_SQPB_MEASUREMENT,
        data: data,
        item: item,
        data2: data2,
        data3: data3
    }
}

export const changesalesqty = (data, item, data2, data3) => {

    // console.warn("qty change called", data);
    return {

        type: CHANGE_SALES_QTY,
        data: data,
        item: item,
        data2: data2,
        data3: data3
    }

}



export const changecostsales = (data, item, data2) => {

    // console.warn("cost change called", data);
    return {

        type: CHANGE_COST_SALES,
        data: data,
        item: item,
        data2: data2

    }

}


export const changesalesdiscount = (data, item, data2) => {

    // console.warn("discount change called", data);
    return {

        type: CHANGE_SALES_DISCOUNT,
        data: data,
        item: item,
        data2: data2

    }

}


export const changesalestext = (data, item) => {

    // console.warn("text change called", data);
    return {

        type: CHANGE_SALES_TEXT,
        data: data,
        item: item
    }

}


export const emptysalesdata = () => {

    return {

        type: EMPTY_SALES_DATA,
    }

}