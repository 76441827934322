import axios from "axios";
import { MDBCard } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Accordion, Button, Col, Form, Row, Table } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../services/Api";
import authHeader from "../services/auth-header";
import AuthService from "../services/auth.service";

export default function View() {
    const navigate=useNavigate();
    const { id } = useParams();
    const { pointindex } = useParams();
    const [data, setData] = useState('');
    const [Branchdata, setBranchdata] = useState('');
    const [warehouse, setwarehouse] = useState('');
    const [distributor, setdistributor] = useState('');
    const [date, setDate] = useState('');
    const [dcItems, setdcItems] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [deliveryAddress,setdelval]=useState([]);
    const[disdel,setdel]=useState([]);
    const user = AuthService.getCurrentUser();

    useEffect(() => {

        axios.get(BASE_URL + 'dc/' + id, {
            headers: authHeader()
        }).then(res => {
            (res.data.retailerstatus === 'customer'?setdel(res.data.retailer.deliveryAddress):setdel(res.data.distributor.deliveryAddress))
            // setdel(res.data.distributor.deliveryAddress)
            setSelectedOption(res.data.deliveryAddress)

            setdelval(res.data.deliveryAddress)
            console.log("mello", res.data);
            setBranchdata(res.data.branch.branchname)
            setwarehouse(res.data.warehouse);
            (res.data.retailerstatus === 'customer'?setdistributor(res.data.retailer):
            setdistributor(res.data.distributor))
            setData(res.data);
            setDate(res.data.dcdate)
            setdcItems(res.data.dcItems);
        })
    }, [])
    let date_value = new Date(date);
    const defaultValue = date_value.toLocaleDateString('en-CA');
    let date_value1 = new Date(data.buyerorderdate);
    const defaultValue1 = date_value.toLocaleDateString('en-CA');
    let date_value2 = new Date(data.ackdate);
    const defaultValue2 = date_value2.toLocaleDateString('en-CA');
    const getItem = (id) => {
        axios.delete( BASE_URL + 'dc/'+id,{
          headers:authHeader()
        }).then(res=>{
        //   window.location.reload()
        navigate(`/DeliveryChallanTable/${pointindex}`)
        })
      };
    

    return (

        <MDBCard className="company">
            {/* <h1>DC View</h1>
            <Link to={`/DeliveryChallanTable/${pointindex}`} className="btn btn-primary" >Back</Link>
            <a class="delete btn btn-sm  red btn"  onClick={()=>getItem(id)}  ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class=" feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg></a>
            <Link to={'/del/' + id} target="_blank">
  <button className="btn btn-sm btn-primary">Print</button>
</Link>
          <div className='p-2 w-100 bd-highlight'><Link to={'/Sales/Create/' + id}><button class="btn btn-sm btn-secondary">Sale Create</button></Link></div>
            */}

{data.status == 'converted'?(
          <div className='d-flex bd-highlight'>
          <h1>DC View</h1>
        {/* <button className='prox btn' onClick={()=>handleChang/e12()} ></button> */}
        {/* <Link className='p-2 w-200 card-header  btn-sm card-title bd-highlight    ' onClick={()=>getItem(id)}><Button className="delete btn-sm"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"/><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"/><line x1="10" y1="11" x2="10" y2="17"/><line x1="14" y1="11" x2="14" y2="17"/></svg></Button></Link> */}
                {/* <Link className='p-2 w-200 card-header  btn-sm card-title bd-highlight' to={`/poedit/${id}/${pointindex}`}><Button className="btn-sm">Edit</Button></Link> */}
        <Link className='p-2 w-200 card-header  btn-sm card-title bd-highlight'to={`/DeliveryChallanTable/${pointindex}`} ><Button className="btn-sm">Back</Button></Link>
        {/* <Link className='p-2 w-200 card-header btn-sm card-title bd-highlight' to={`/SalesOrderEdit/${id}/${pointindex}`}><Button className="btn-sm">Edit</Button></Link> */}

     
        <Link to={'/del/' + id} className='p-2 w-200 card-header card-title bd-highlight' target="_blank">
  <button className="btn btn-sm btn-primary">Print</button>
</Link>
      </div>
):(
    <div className='d-flex bd-highlight'>
    <h1>DC View</h1>
  {/* <button className='prox btn' onClick={()=>handleChang/e12()} ></button> */}
  {/* <Link className='p-2 w-200 card-header  btn-sm card-title bd-highlight    ' onClick={()=>getItem(id)}><Button className="delete btn-sm"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"/><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"/><line x1="10" y1="11" x2="10" y2="17"/><line x1="14" y1="11" x2="14" y2="17"/></svg></Button></Link> */}
  {user.roles[0] === 'ROLE_ADMIN' &&<Link className='p-2 w-200 card-header  btn-sm card-title bd-highlight'  ><Button className="btn-sm" onClick={()=>getItem(id)}>Delete</Button></Link>}
  {/* <Link className='p-2 w-200 card-header  btn-sm card-title bd-highlight' to={`/poedit/${id}/${pointindex}`}><Button className="btn-sm">Edit</Button></Link> */}
  <Link className='p-2 w-200 card-header  btn-sm card-title bd-highlight'to={`/DeliveryChallanTable/${pointindex}`} ><Button className="btn-sm">Back</Button></Link>
  {/* <Link className='p-2 w-200 card-header btn-sm card-title bd-highlight' to={`/SalesOrderEdit/${id}/${pointindex}`}><Button className="btn-sm">Edit</Button></Link> */}

  {data.retailerstatus === 'customer'?<Link className='p-2 w-200 card-header btn-sm card-title bd-highlight' to={'/RetailerSales/Create/' + id}><Button className="btn-sm"> Add Sale</Button></Link>:
  <Link className='p-2 w-200 card-header btn-sm card-title bd-highlight' to={'/Sales/Create/' + id}><Button className="btn-sm"> Add Sale</Button></Link>}

  <Link to={'/del/' + id} className='p-2 w-200 card-header card-title bd-highlight' target="_blank">
<button className="btn btn-sm btn-primary">Print</button>
</Link>
</div>
)}




           
           
            <Form className="product1">
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>DC Data</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Branch Name</Form.Label>
                                    <Form.Control type="text" value={Branchdata} readOnly />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Warehouse Name</Form.Label>
                                    <Form.Control type="text" value={warehouse.name} readOnly />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Customer Name</Form.Label>
                                    <Form.Control type="text" value={distributor.tradeName} readOnly />
                                </Form.Group>

                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Date</Form.Label>
                                    <Form.Control type="date" value={defaultValue} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Terms of Payment</Form.Label>
                                    <Form.Control type='text' value={data.paymentTerms} autoComplete="off" />
                                </Form.Group>
                                {/* <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Type</Form.Label>
                                    <Form.Select value={data.type == null ? "" : data.taxtype}>
                                        <option value="b2b">B2B</option>
                                        <option value="b2c">B2C</option>
                                    </Form.Select>
                                </Form.Group> */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Status</Form.Label>
                                    <Form.Select >
                                        <option value='true'>Open</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Voucher Series</Form.Label>
                                    <Form.Control type='text' value={data.voucherid} autoComplete="off" />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Tax Type</Form.Label>
                                    <Form.Select value={data.taxtype == null ? "" : data.taxtype}>
                                        <option>--Select--</option>
                                        <option value='withtax'>With Tax</option>
                                        <option value='withouttax'>Without Tax</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Buyer’s Order No</Form.Label>
                                    <Form.Control type="text" value={data.buyerorderno == null ? "" : data.buyerorderno} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Buyer’s Order Date</Form.Label>
                                    <Form.Control type="date" value={defaultValue1} />

                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Total No. of PKGs</Form.Label>
                                    <Form.Control type="text" value={data.totalnopkg == null ? "" : data.totalnopkg} />
                                </Form.Group>

                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Delivery Note No</Form.Label>
                                    <Form.Control type="text" value={data.deliverynotno == null ? "" : data.deliverynotno} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Despatch Through</Form.Label>
                                    <Form.Control type="text" value={data.dispatchedthrough == null ? "" : data.dispatchedthrough} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Destination</Form.Label>
                                    <Form.Control type="text" value={data.destination} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">TERMS OF DELIVERY</Form.Label>
                                    <Form.Control type="text" value={data.termsofdelivery} />
                                </Form.Group>

                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    {/* <Accordion.Item eventKey="1">
                        <Accordion.Header>DELIVERY ADDRESS</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Name</Form.Label>
                                    <Form.Control type="text" />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Address</Form.Label>
                                    <Form.Control type="text" />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">GST/UIN</Form.Label>
                                    <Form.Control type="text" />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">State Name</Form.Label>
                                    <Form.Control type="text" />
                                </Form.Group>



                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Phone No : -</Form.Label>
                                    <span>&nbsp;{customerSubContactsdata == "" ? customerAll.phonenumber : customerSubContactsdata.phoneno}</span>
                                </Form.Group>

                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Email Id : -</Form.Label>
                                    <span> {customerSubContactsdata == "" ? customerAll.email : customerSubContactsdata.email}</span>
                                </Form.Group>

                            </Row>
                        </Accordion.Body>
                    </Accordion.Item> */}
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>BILLING ADDRESS</Accordion.Header>
                        <Accordion.Body>
                            {/* <Row className="mb-1">
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Name</Form.Label>
                                    <Form.Control type="text" />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Address</Form.Label>
                                    <Form.Control type="text" />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">GST/UIN</Form.Label>
                                    <Form.Control type="text" />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">State Name</Form.Label>
                                    <Form.Control type="text" />
                                </Form.Group>

                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Phone No : -</Form.Label>
                                    <span>&nbsp;{customerSubContactsdata == "" ? customerAll.phonenumber : customerSubContactsdata.phoneno}</span>
                                </Form.Group>

                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Email Id : -</Form.Label>
                                    <span> {customerSubContactsdata == "" ? customerAll.email : customerSubContactsdata.email}</span>
                                </Form.Group>
                            </Row> */}
                            <b><span className="">Delivery Address</span></b> <br></br> <br></br>
                                <Row className="mb-1">
                               {/* <select className="form-select dd">
                                <option className="extrvagnze">select Address</option>
                               
                                {
                                    disdel.map((item)=>{
                                        return<>
                                          <option className="extrvagnze">Address:{item.delivery_address} ,State:-{item.state} &nbsp;,Pin-code:-{item.pincode}</option>
                                        </>
                                    })
                                }
                               </select> */}
                               {/* <Select
      options={options}
      onChange={handleSelectChange}
      filterOption={customFilter11}
      placeholder="Select Address"
      value={selectedOption}
    /> */}
<select className="form-control" value={selectedOption}  >
    <option>Select Delivery Address</option>
    {
        disdel.map((item)=>{
            return<>
            <option value={item.id}>{item.delivery_address}</option>
            </>
        })
    }
</select>
                                
                            </Row>


                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header>Details</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">IRN NO</Form.Label>
                                    <Form.Control type="text" value={data.irnno}/>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">ACK NO</Form.Label>
                                    <Form.Control type="text" value={data.ackno}/>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">ACK DATE</Form.Label>
                                    <Form.Control type="date" value={defaultValue2} />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">MSME NO</Form.Label>
                                    <Form.Control type="text" value={data.msmeno}/>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">UDYAM NO</Form.Label>
                                    <Form.Control type="text" value={data.udyamno}/>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">GST NO</Form.Label>
                                    <Form.Control type="text" value={data.gstno} />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">IGST OUTPUT</Form.Label>
                                    <Form.Control type="text" value={data.irnno}/>
                                </Form.Group>
                                {/* <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Rounding off</Form.Label>
                                    <Form.Control type="text" value={data.irnno}/>
                                </Form.Group> */}

                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="5">
                        <Accordion.Header>Account</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                {/* <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">HSN/SAC</Form.Label>
                                    <Form.Control type="text" />
                                </Form.Group> */}
                                {/* <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Taxable Value</Form.Label>
                                    <Form.Control type="text" value={data.irnno}/>
                                </Form.Group> */}
                                {/* <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">IGST</Form.Label>
                                    <Form.Control type="text" value={data.irnno}/>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Rate Amount</Form.Label>
                                    <Form.Control type="text" value={data.irnno}/>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Total Tax Amount</Form.Label>
                                    <Form.Control type="text" value={data.irnno}/>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Tax Amount (in Word)</Form.Label>
                                    <Form.Control type="text" value={data.irnno}/>
                                </Form.Group> */}
                                <h1>Company Bank Details</h1>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Bank Name</Form.Label>
                                    <Form.Control type="text" value={data.bankname}/>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Account Amount</Form.Label>
                                    <Form.Control type="text" value={data.accountno} />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Branch Name</Form.Label>
                                    <Form.Control type="text" value={data.branchname}/>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Ifsc Code</Form.Label>
                                    <Form.Control type="text" value={data.ifsccode}/>
                                </Form.Group>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <br></br>
                {/* <Row className="mb-1">
                <Form.Group as={Col} md="12" controlId="formBasicName">
                    <Form.Label className="label">Product</Form.Label>
                    <Select options={productdata} onChange={(e) => addData(e)} filterOption={customFilter}/>
                </Form.Group>
            </Row> */}
                <br></br>
                <Table striped responsive hover size="sm">
                    <thead className='p-3 mb-2 bg-success text-white'>
                        <tr>
                            <th>PRODUCT&nbsp;NAME</th>
                            <th>UOM</th>
                            <th >QTY(PCS)</th>
                            <th >QTY(KG)</th>
                            <th >Rate</th>
                            <th >Trade&nbsp;Discount</th>
                            <th>Gross&nbsp;Amount&nbsp;(₹)</th>
                            <th >Scheme&nbsp;Discount&nbsp;%</th>
                            <th >NET&nbsp;AMOUNT&nbsp;(₹)</th>
                            <th >GST&nbsp;Value</th>
                            <th >Amount</th>
                        </tr>
                    </thead>
                    <tbody style={{ overflow: "scroll" }}>
                        {dcItems.map(item =>
                            <tr>
                                <td>
                                    <Form.Group as={Col} md="12" >
                                    <textarea type="text"   className="form-control textcontrol" value={(item.productName ? item.productName : item.product.productName)} size="sm" ></textarea>
                                        
                                    </Form.Group>
                                    <Form.Control as="textarea" value={item.text} rows={2} className='form-control' />
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" value={item.unitofmeasurement} size="sm" disabled />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" size="sm" value={item.dcquantity_placed} />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" value={Number(item.dcquantity_placed_kg)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" size="sm" value={item.dlp} />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" value={item.tradediscount || ''} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" value={Number(item.grossamount)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" value={Number(item.schemeDiscount)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" value={Number(item.netAmount)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" value={Number(item.gstvalue)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" value={Number(item.grandtotal)} size="sm" />
                                    </Form.Group>
                                </td>

                            </tr>
                        )}
                    </tbody>
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Packing&nbsp;&&nbsp;Forwarding</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={data.shippingcharge} style={{ height: 30 }} readOnly />
                                </Form.Group>
                                </td>
                            
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Net&nbsp;Amount</td>
                            <td>
                            <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={data.shippingcharge} style={{ height: 30 }} readOnly />
                                </Form.Group>
                            </td>
                            
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>IGST</td>
                            <td>
                            <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={data.igst} style={{ height: 30 }} readOnly />
                                </Form.Group>
                            </td>
                           
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>SGST</td>
                            <td>
                            <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={data.sgst} style={{ height: 30 }} readOnly />
                                </Form.Group>
                            </td>
                           
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>CGST</td>
                            <td>
                            <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={data.cgst} style={{ height: 30 }} readOnly />
                                </Form.Group>
                            </td>
                            
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Round&nbsp;off&nbsp;Value</td>
                            <td>
                            <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={data.roundofvalue} style={{ height: 30 }} readOnly />
                                </Form.Group>
                            </td>
                            
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Grand&nbsp;Total</td>
                            <td>
                            <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={data.grandtotal} style={{ height: 30 }} readOnly />
                                </Form.Group>
                            </td>
                            
                        </tr>
                    </tbody>
                </Table>

            </Form>
        </MDBCard>
    )
}