import { numberToWords } from 'amount-to-words';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import authHeader from '../Component/services/auth-header';
import { useParams } from 'react-router-dom';

const DeliveryNote = () => {
  const { id } = useParams();

const[delv,setdel]=useState([])
  const[sname,setsname]=useState([])
  const [alting, setalting] = useState([])
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading1, setIsLoading1] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState([]);
const[data22,setdata22]=useState([])
const [data221, setData221] = useState([]) 
const[cdeatils,setcdeatils]=useState([])
const [jsonData,setjsondata1]=useState([])
 const[productd,setpro]=useState([])
 const [stateid, setstateid] = useState(0);

//  const [sname, setsname] = useState([]);

 const [totalSoqty, setTotalSoqty] = useState(0);
 const [totalGrossamount, setTotalGrossamount] = useState(0);
 const [totalDisAmt, setTotalDisAmt] = useState(0);
 const [totalDisAmt1, setTotalDisAmt1] = useState(0);
 const [Round, setRound] = useState([]); const [igst, setIgst] = useState([])
 const[sumigst,setsumgst]=useState([])
  const [sumctax, setSumctax] = useState([])
  const[Amount,setamount]=useState([])
const [dow, setdow] = useState([])
const[statee,setstatee]=useState([])
const[delivery,setdelivery]=useState([])
const[pincodin,setcoding]=useState([])
const[billing,setbilling]=useState([])  
 
const [sett,setTotalAltsoqty]=useState([])
const [netAmount,setTotalNetAmount]=useState([])

useEffect(() => {

    document.getElementById("qerteww").style.display = "none";
    
    document.getElementById("sidein").style.display = "none";




    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const updatedPageData = jsonData.slice(startIndex, endIndex);
    setCurrentPageData(updatedPageData);
  }, [currentPage]);

  let totalPages = Math.ceil(jsonData.length / 20);


  console.log(totalPages);
  // console.log(jsonData);
const datemaker=(checkdate)=>{
  const originalDateString =checkdate;
  const originalDate = new Date(originalDateString);
  
  // Options for formatting the date without time
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  
  // Format the date string
  const formattedDateString = originalDate.toLocaleDateString('en-US', options);
  return formattedDateString
  
}

  useEffect(() => {
 
    const elements = ["naving", "1sidebar", "qw", "swift", "logoo", "logoo1", "menu"];

    elements.forEach(elementId => {
      const element = document.getElementById(elementId);
      if (element) {
        element.classList.add('hidden');
      }
    });

    const newElement = document.getElementById("new");
    if (newElement) {
      newElement.classList.add('fullWidth');
    }
   
if(stateid==0){
    axios.get(`http://43.225.54.138:8080/scm/api/dc/${id}`, { headers: authHeader() })
    .then(response => {
      const itemere=response.data.distributor.deliveryAddress;
      const yep=itemere.filter((item)=>item.id==response.data.deliveryAddress)
      setdata22(response.data)
  // console.log(`http://43.225.54.138:8080/scm/api/product/page/${Number(getid)}/${Number(limit)}/${sort}/${fieldnme}`);
  //  let distJson = response.data.distributor.map(item =>{
  //   JSON.stringify(item)
  // });
  // console.log(response.data.distributor.deliveryAddress[0])
  // setdel(response.data.distributor.deliveryAddress[0])


  setstatee(yep.map((item)=>item.state))
  setcoding(yep.map((item)=>item.pincode))
  setdelivery(yep.map((item)=>item.delivery_address))
  setbilling(response.data.distributor.billingAddress)



    //  console.log(response.data.distributor.deliveryAddress)
  // setData221(response.data.distributor)
  
if (Array.isArray(response.data.distributor)) {
   const modifiedData = response.data.distributor.map(item => {
      const { deliveryAddress, ...rest } = item;
      return rest;
  });
  
   setData221(modifiedData);
} else {
   const { deliveryAddress, ...rest } = response.data.distributor;
  
   setData221(rest);
}
let  stateid=response.data.distributor.stateid
    
console.log(stateid)
  setstateid(stateid)

//   deliveryAddress
// : 
// [{id: 7, delivery_address: ""}]
      // setData221(response.data.map((item)=>item.distributor))
     
      // setData221(response.data.distributor.filter((o, i) => deliveryAddress !== i));



      // setData221(response.data.distributor))


    // console.log(first)
      setcdeatils(response.data.customerSubContacts)
      setIgst(response.data.igst)
      const extractedData = response.data.dcItems.map((item) => ({
        no: item.deliverychallanitemId,
        AllQtApprox: item.dcaltqty,
        Qty: item.dcquantity_placed,
        Dis: item.discount,
        DisAmt: item.discountamount,
        Dis1: item.discount1,
        DisAmt1: item.discountamount1,
        GrossAmt: item.grossamount,
        Per: item.product.productType,
        check: item.product.productType,
        Rate:item.mrp,
        Amount: item.netAmount,
        DescriptionofGoods:item.product.productName,
        EANCode:item.product.eanCode,
        HSNSAC:item.product.hsnCode,
        GStRate:item.product.igst,
        Rate:item.product.mrp,
        GStRatecg:item.product.cgst,
        GStRatesg:item.product.sgst,
        uomSecondary:item.product.uomSecondary
      }));
      
      
       response.data.dcItems.map((item) => {
        setTotalAltsoqty((prevTotal) => prevTotal + Number(item.dcaltqty));
        setTotalSoqty((prevTotal) => prevTotal + Number(item.dcquantity_placed));
        // setTotalGrossamount((prevTotal) => prevTotal + Number(item.grossamount));
        // setTotalDisAmt((prevTotal) => prevTotal + Number(item.discountamount));
        // setTotalDisAmt1((prevTotal) => prevTotal + Number(item.discountamount1));
        // setTotalNetAmount((prevTotal) => prevTotal + Number(item.netAmount));
      });
       setjsondata1(extractedData);
      console.log(extractedData);
     
const notebook = extractedData.map((item) => ({
  HSNSAC: item.HSNSAC,
  gst: item.GStRate,
   cgst : item.GStRatecg,
   sgst :item.GStRatesg,
  amount: item.Amount, 
}));

 
console.log(notebook );
const groupedData = notebook.reduce((acc, curr) => {
  const existingItem = acc.find(item => item.HSNSAC === curr.HSNSAC);
  if (existingItem) {
  existingItem.amount += (curr.amount);
  } else {
    acc.push(curr);
  }
  return acc;
}, []);
console.log(groupedData);
const updatedData = groupedData.map(item => {
  const { amount, gst, sgst, cgst, ...rest } = item;

  const igstAmount = (amount * gst) / 100;
  const sgstAmount = (amount * sgst) / 100;
  const cgstAmount = (amount * cgst) / 100;

  return {
    ...rest,
    amount,
    gst,
    igstAmount,
    sgst,
    sgstAmount,
    cgst,
    cgstAmount
  };
});

const updatedDataWithSums = updatedData.map(item => {
  const { sgstAmount, cgstAmount, ...rest } = item;

  const totalGST = sgstAmount + cgstAmount;

  return {
    ...rest,
    sgstAmount,
    cgstAmount,
    totalGST
  };
});
const sumValues = updatedDataWithSums.reduce((accumulator, item) => {
  accumulator.totalGST += item.totalGST || 0;
  accumulator.igstAmount += item.igstAmount || 0;
  accumulator.amount += item.amount || 0;
  return accumulator;
}, { totalGST: 0, igstAmount: 0, amount: 0 });

setsumgst(sumValues.totalGST);
setSumctax(sumValues.igstAmount);
setamount( sumValues.amount);

setdow(updatedDataWithSums)
      
      
      let sum1=response.data.dcItems.filter((item)=>item.product.productType=="kgproduct").map((item)=>item.product.uomSecondary*item.dcaltqty)
      
      const sum = sum1.reduce((acc, val) => acc + val, 0);
       
      let roundedSum = sum.toFixed(2);
      
       setIsLoading(roundedSum);
      //  rsetIsLoadingoundvalue
       setRound(response.data.dcItems.map((item)=>item.amount).reduce((acc, val) => acc + val, 0))
      let qty=response.data.dcItems.map((item)=>item.dcaltqty).reduce((acc, val) => acc + val, 0)
      let qty1=response.data.dcItems.map((item)=>item.dcaltqty).reduce((acc, val) => acc + val, 0)
      setIsLoading1(qty);
setalting(qty1)

 

    })
    .catch(error => {
      console.error(error);
    });}

    axios.get(`http://43.225.54.138:8080/scm/api/state/${stateid}`, { headers: authHeader() })
    .then(response => { 
       
      setsname(response.data.name)
  console.log(response.data.name)

  })
    .catch(error => {
      console.error(error);
    });
   


  }, [stateid ])
  let removedElements
  let itemsPerPage=18;
  const paragraphs = [];
  let i = 0;
  for (i; i < totalPages; i++) {
    let startIndex;
    let endIndex;
    let roman;
    let jsonDataSlice;
    // let remainingItems
    let lastPageDataSize

     
// console.log(totalPages);
 
      startIndex = i * itemsPerPage;
      endIndex = Math.min((i + 1) * itemsPerPage, jsonData.length);
      jsonDataSlice = jsonData.slice(startIndex, endIndex);
    
 
    if(totalPages===i+1){
      let lastPageIndex = totalPages - 1;
      let lastPageStartIndex = lastPageIndex * itemsPerPage;
      let lastPageEndIndex = Math.min((lastPageIndex + 1) * itemsPerPage, jsonData.length);
        lastPageDataSize = lastPageEndIndex - lastPageStartIndex;

  if (lastPageDataSize > 12) {
    let newidea = lastPageDataSize-12;
    console.log(newidea);
      jsonDataSlice = jsonData.slice(startIndex, endIndex-newidea);
        removedElements = jsonData.slice(endIndex - newidea, endIndex);
      console.log(removedElements);
  }


  if (totalPages==1&&i==0&&lastPageDataSize > 10) {
    let newidea = lastPageDataSize-10;
    console.log(newidea);
      jsonDataSlice = jsonData.slice(startIndex, endIndex-newidea);
        removedElements = jsonData.slice(endIndex - newidea, endIndex);
      console.log(removedElements);
  }


    } 


      // startIndex = i * itemsPerPage;
      // endIndex = Math.min((i + 1) * itemsPerPage, jsonData.length);
      // jsonDataSlice = jsonData.slice(startIndex, endIndex);




    





    paragraphs.push(<p className='jpmorgon' key={i}><div className='border1' >



      <div className='row   center'>
        <div className='col'>
          <div className='border2 fontprint'>   NEELAM APPLIANCES LIMITED</div>
        </div>
      </div>

     {i === 0? <div className='row   center'>
        <div className='col  '>
          <div className='border2 fontprint1'>Delivery Note</div>
        </div>
      </div>:<> <div className='row '>
        <div className='col-12 border'>
          <font className="border2 padding15 border  ertrt12">IRN NO.:{data22.irnno}</font>
        </div>
      </div><div className='row '>
        <div className='col border'>
          <font className="border2 padding15   ertrt12">ACK NO. :{data22.ackno}</font>
        </div>
      </div><div className='row '>
        <div className='col border'>
          <font className="border2 padding15 border  ertrt12">ACK Date :{data22.ackdate}</font>
        </div>
      </div><div className='row '>
        <div className='col border'>
          <font className="border2 padding15 ertrt12">MSME No: {data22.msmeno} / UDYAM No: UDYAM-MH-17-0011786 / GST No:27AABCN9540N1ZY.</font>
        </div>
      </div></>}

      <div className='row  '>
        <div className='col-6 '>
          <div className='border2 padding15 ertrt12'><font>Buyer’s Order No.    </font>:<font1>{data22.buyerorderno}</font1></div>
        </div>
        <div className='col-6 borderl border2'>
          <div ><font>Delivery Note No:    </font>:<font1>{data22.deliverynotno}</font1>  <font>  Date:    </font><font1>{datemaker(data22.dcdate)}
</font1>  </div>

        </div>
      </div>

      <div className='row  '>
        <div className='col-6 '>
          <div className='border2 padding15 ertrt12'><font>Buyer’s Order Date   </font>:<font1>{datemaker(data22.dcdate)}</font1></div>
        </div>
        <div className='col-6 borderl border2'>
          <div ><font>Despatch Through : {data22.dispatchedthrough} </font> </div>

        </div>
      </div>
      <div className='row  '>
        <div className='col-6 '>
          <div className='border2 padding15 ertrt12'><font>Total No. of PKGs :{data22.totalnopkg}</font></div>
        </div>
        <div className='col-6 borderl border2'>
          <div ><font>Destination : {data22.destination}:</font></div>

        </div>
      </div>
      <div className='row '>
        <div className='col'>
          <font className="border2 padding15 ertrt12">TERMS OF DELIVERY : {data22.termsofdelivery}</font>
        </div>
      </div>
      <div className='row  '>
        <div className='col-6 '>
          <div className='border2 padding15 ertrt12'><font>DELIVERY ADDRESS :</font></div>
        </div>
        <div className='col-6 borderl border2'>
          <div ><font>BILLING ADDRESS :</font></div>

        </div>
      </div>

      <div className='row  '>
        <div className='col-6 '>
          <div className='border2 padding15 ertrt12 probook'>
            <font>NAME:</font><font1>{data221.tradeName}</font1><br></br>
            {(() => {
              if (i === 0) {
                return (
                  <>
                <font>Address: </font>
                    <font1>{delivery}{pincodin}</font1>
                    <br />
                    <font>State Name :</font>
                    <font1>{statee}</font1>
                    <br />
                    <font>GSTIN/UIN : </font>
                    <font1>{data221.gstNumber}</font1>
                    <br />
                    <font>PAN/IT No : </font>
                    <font1>{data221.panNumber}</font1>
                    <br />
                    <font>Contact Person : </font>
                    <font1>{data221.perMobileNumber}, {data221.alterMobileNumber}, {cdeatils !== undefined && cdeatils !== null ? cdeatils.phoneno : ""}</font1>
                    <br />
                    <font>Email :</font>
                    <font1>{data221.perEmail}</font1>
                    <br />
                  </>
                );
              }
              return null;
            })()}
          </div>
        </div>
        <div className='col-6 borderl border2'>
        <div className='    ertrt12 probook'>
            <font>NAME:</font><font1>{data221.tradeName}</font1><br></br>
            {(() => {
              if (i === 0) {
                return (
                  <>
                    <font>Address:</font>
                    <font1>{data221.billingAddress}</font1>
                    <br />
                    <font>State Name :</font>
                    <font1>{sname}</font1>
                    <br />
                    <font>GSTIN/UIN : </font>
                    <font1>{data221.gstNumber}</font1>
                    <br />
                    <font>PAN/IT No : </font>
                    <font1>{data221.panNumber}</font1>
                    <br />
                    <font>Contact Person : </font>
                    <font1>{data221.perMobileNumber}, {data221.alterMobileNumber}, {cdeatils !== undefined && cdeatils !== null ? cdeatils.phoneno : ""}</font1>
                    <br />
                    <font>Email :</font>
                    <font1>{data221.perEmail}</font1>
                    <br />
                  </>
                );
              }
              return null;
            })()}
          </div>

        </div>
      </div>
      <table id='rowcount' className='   '>

        <tr className='thback'>
          <th className='thback' width="3%">Sr No.</th>
          <th className='thback' width="auto">Description of Goods <span className='white'>Goodsssssssssssssssssss</span></th>
          <th className='thback' width="3%">HSN/SAC</th>
          <th className='thback' width="3%">GST Rate</th>
          <th className='thback' width="3%">All Qt Approx</th>
          <th className='thback' width="3%">Qty</th>
          <th className='thback' width="3%">Rate</th>
          <th className='thback' width="3%">Per</th>
          <th className='thback' width="3%">Gross Amt</th>
          <th className='thback' width="3%">Dis%</th>
          <th className='thback' width="3%">Dis Amt</th>
          <th className='thback' width="3%">Dis%</th>
          <th className='thback' width="3%">Dis Amt</th>
          <th className='thback' width="3%">Amount</th>
        </tr>
     
   {
   
   }
        
    <tbody className='height'>
          {
            jsonDataSlice.map((item, index) => {
              return <tr>
                <td className='thback1'>{startIndex + index + 1}</td>
                <td className='thback1'>{item.DescriptionofGoods}<br></br> EAN Code : {item.EANCode}</td>
                <td className='thback1'>{item.HSNSAC}</td>
                <td className='thback1'>{item.GStRate}</td>
                 <td className='thback1'>{item.AllQtApprox}</td>  
                <td className='thback1'>{ item.Qty }</td>  

                 <td className='thback1'>{item.Rate}</td>
                {/* <td className='thback1'>{item.Per}</td> */}
                {item.check=="kgproduct" ? <td  className='thback1 sizeropt'>kg</td>:<td  className='thback1 sizeropt'>Pcs</td>}
                <td className='thback1'>{item.GrossAmt}</td>
                <td className='thback1'>{item.Dis}</td>
                <td className='thback1'>{item.DisAmt}</td>
                <td className='thback1'>{item.Dis1}</td>
                <td className='thback1'>{item.DisAmt1}</td>
                <td className='thback1'>{item.Amount}</td>
              </tr>
            })

          }

 





        </tbody> 
        
       
      </table>




{totalPages===i+1&&lastPageDataSize>12?<p className='jpmorgon' key={i}></p>:""}

{(()=>{
if(totalPages===i+1&&lastPageDataSize>12){
return (
 <>
    <div className='row   center'>
        <div className='col'>
          <div className='border2 fontprint'>   NEELAM APPLIANCES LIMITED</div>
        </div>
      </div>

      {i === 0? <div className='row   center'>
        <div className='col  '>
          <div className='border2 fontprint1'>Delivery Note</div>
        </div>
      </div>:<> <div className='row '>
        <div className='col-12 border'>
          <font className="border2 padding15 border  ertrt12">IRN NO.:{data22.irnno}</font>
        </div>
      </div><div className='row '>
        <div className='col border'>
          <font className="border2 padding15   ertrt12">ACK NO. :{data22.ackno}</font>
        </div>
      </div><div className='row '>
        <div className='col border'>
          <font className="border2 padding15 border  ertrt12">ACK Date :{data22.ackdate}</font>
        </div>
      </div><div className='row '>
        <div className='col border'>
          <font className="border2 padding15 ertrt12">MSME No: {data22.msmeno} / UDYAM No:{data22.udyamno} / GST No:{data22.gstno}.</font>
        </div>
      </div></>}
      <div className='row  '>
        <div className='col-6 '>
          <div className='border2 padding15 ertrt12'><font>Buyer’s Order No.    </font>:<font1>{data22.buyerorderno}</font1></div>
        </div>
        <div className='col-6 borderl border2'>
          <div ><font>  Delivery Note No :    </font>:<font1> {data22.deliverynotno}</font1>  <font>  Date:    </font><font1>{datemaker(data22.dcdate)}</font1>  </div>

        </div>
      </div>

      <div className='row  '>
        <div className='col-6 '>
          <div className='border2 padding15 ertrt12'><font>Buyer’s Order No.    </font>:<font1>{data22.buyerorderno}</font1></div>
        </div>
        <div className='col-6 borderl border2'>
          <div ><font>  Delivery Note No :    </font>:<font1> {data22.deliverynotno}</font1>  <font>  Date:    </font><font1> {datemaker(data22.dcdate)}</font1>  </div>

        </div>
      </div>

      <div className='row '>
        <div className='col'>
          <font className="border2 padding15 ertrt12">TERMS OF DELIVERY :</font>
        </div>
      </div>
      <div className='row  '>
        <div className='col-6 '>
          <div className='border2 ertrt12'><font>DELIVERY ADDRESS :</font></div>
        </div>
        <div className='col-6 borderl border2'>
          <div ><font>BILLING ADDRESS :</font></div>

        </div>
      </div>

      <div className='row  '>
        <div className='col-6 '>
          <div className='border2 ertrt12 probook'>
            <font>NAME:</font><font1>{data221.tradeName}</font1><br></br>
            {(() => {
              if (i === 0) {
                return (
                  <>
                <font>Address: </font>
                    <font1>{delivery}{pincodin}</font1>
                    <br />
                    <font>State Name :</font>
                    <font1>{statee}</font1>
                    <br />
                  <font>GSTIN/UIN : </font>
                  <font1>{data221.gstNumber}</font1>
                  <br />
                  <font>PAN/IT No : </font>
                  <font1>{data221.panNumber}</font1>
                  <br />
                  <font>Contact Person : </font>
                  <font1>{data221.perMobileNumber}, {data221.alterMobileNumber}, {cdeatils !== undefined && cdeatils !== null ? cdeatils.phoneno : ""}</font1>
                  <br />
                  <font>Email :</font>
                  <font1>{data221.perEmail}</font1>
                  <br />
                </>
                );
              }
              return null;
            })()}
          </div>
        </div>
        <div className='col-6 borderl border2'>
          <div className=' ertrt12 probook'>
            <font>NAME:</font><font1>{data221.tradeName}</font1><br></br>
            {(() => {
              if (i === 0) {
                return (
                  <>
                  <font>Address:</font>
                  <font1>{delv.billingAddress}</font1>
                  <br />
                  <font>State Name :</font>
                  <font1>{sname}</font1>
                  <br />
                  <font>GSTIN/UIN : </font>
                  <font1>{data221.gstNumber}</font1>
                  <br />
                  <font>PAN/IT No : </font>
                  <font1>{data221.panNumber}</font1>
                  <br />
                  <font>Contact Person : </font>
                  <font1>{data221.perMobileNumber}, {data221.alterMobileNumber}, {cdeatils !== undefined && cdeatils !== null ? cdeatils.phoneno : ""}</font1>
                  <br />
                  <font>Email :</font>
                  <font1>{data221.perEmail}</font1>
                  <br />
                </>
                );
              }
              return null;
            })()}
          </div>

        </div>
      </div>
      <table id='rowcount' className='  bordered' >

        <tr className='thback'>
          <th className='thback' width="3%">Sr No.</th>
          <th className='thback' width="auto">Description of Goods <span className='white'>Goodsssssssssssssssssss</span></th>
          <th className='thback' width="3%">HSN/SAC</th>
          <th className='thback' width="3%">GST Rate</th>
          <th className='thback' width="3%">All Qt Approx</th>
          <th className='thback' width="3%">Qty</th>
          <th className='thback' width="3%">Rate</th>
          <th className='thback' width="3%">Per</th>
          <th className='thback' width="3%">Gross Amt</th>
          <th className='thback' width="3%">Dis%</th>
          <th className='thback' width="3%">Dis Amt</th>
          <th className='thback' width="3%">Dis%</th>
          <th className='thback' width="3%">Dis Amt</th>
          <th className='thback' width="3%">Amount</th>
        </tr>
     
   {
   
   }
        
    <tbody className='height'>
          {
            removedElements.map((item, index) => {
              return <tr>
                <td className='thback1'>{startIndex + index + 1}</td>
                <td className='thback1'>{item.DescriptionofGoods}<br></br> EAN Code : {item.EANCode}</td>
                <td className='thback1'>{item.HSNSAC}</td>
                <td className='thback1'>{item.GStRate}</td>
                <td className='thback1'>{item.AllQtApprox}</td>  
                <td className='thback1'>{ item.Qty }</td>  
                 <td className='thback1'>{item.Rate}</td>
                {/* <td className='thback1'>{item.Per}</td> */}
                {item.check=="kgproduct" ? <td  className='thback1 sizeropt'>kg</td>:<td  className='thback1 sizeropt'>Pcs</td>}
        
                <td className='thback1'>{item.GrossAmt}</td>
                <td className='thback1'>{item.Dis}</td>
                <td className='thback1'>{item.DisAmt}</td>
                <td className='thback1'>{item.Dis1}</td>
                <td className='thback1'>{item.DisAmt1}</td>
                <td className='thback1'>{item.Amount}</td>
              </tr>
            })

          }

 





        </tbody> 
        
       
      </table>
 </>
)
}

if(totalPages===1&&i==0&&lastPageDataSize>10){
  return (
   <><p className='jpmorgon'></p>
      <div className='row   center'>
          <div className='col'>
            <div className='border2 fontprint'>   NEELAM APPLIANCES LIMITED</div>
          </div>
        </div>
  
        {i === 0? <div className='row   center'>
        <div className='col  '>
          <div className='border2 fontprint1'>Delivery Note</div>
        </div>
      </div>:<> <div className='row '>
        <div className='col-12 border'>
          <font className="border2 padding15 border  ertrt12">IRN NO.:{data22.irnno}</font>
        </div>
      </div><div className='row '>
        <div className='col border'>
          <font className="border2 padding15   ertrt12">ACK NO. :{data22.ackno}</font>
        </div>
      </div><div className='row '>
        <div className='col border'>
          <font className="border2 padding15 border  ertrt12">ACK Date :{data22.ackdate}</font>
        </div>
      </div><div className='row '>
        <div className='col border'>
          <font className="border2 padding15 ertrt12">MSME No: {data22.msmeno} / UDYAM No: UDYAM-MH-17-0011786 / GST No:27AABCN9540N1ZY.</font>
        </div>
      </div></>}
        <div className='row  '>
          <div className='col-6 '>
            <div className='border2 ertrt12'><font>Buyer’s Order No.    </font>:<font1>{data22.buyerorderno}</font1></div>
          </div>
          <div className='col-6 borderl border2'>
            <div ><font>  Delivery Note No :    </font>:<font1> {data22.deliverynotno}</font1>  <font>  Date:    </font><font1>{datemaker(data22.dcdate)}</font1>  </div>
  
          </div>
        </div>
  
        <div className='row  '>
          <div className='col-6 '>
            <div className='border2 ertrt12'><font>Buyer’s Order No.    </font>:<font1>{data22.buyerorderno}</font1></div>
          </div>
          <div className='col-6 borderl border2'>
            <div ><font>  Delivery Note No :    </font>:<font1> {data22.deliverynotno}</font1>  <font>  Date:    </font><font1> {datemaker(data22.dcdate)}</font1>  </div>
  
          </div>
        </div>
  
        <div className='row '>
          <div className='col'>
            <font className="border2 ertrt12">TERMS OF DELIVERY :</font>
          </div>
        </div>
        <div className='row  '>
          <div className='col-6 '>
            <div className='border2 ertrt12'><font>DELIVERY ADDRESS :</font></div>
          </div>
          <div className='col-6 borderl border2'>
            <div ><font>BILLING ADDRESS :</font></div>
  
          </div>
        </div>
  
        <div className='row  '>
          <div className='col-6 '>
            <div className='border2 ertrt12 probook'>
              <font>NAME:</font><font1>{data221.tradeName}</font1><br></br>
            
            </div>
          </div>
          <div className='col-6 borderl border2'>
            <div className=' ertrt12 probook'>
              <font>NAME:</font><font1>{data221.tradeName}</font1><br></br>
        
            </div>
  
          </div>
        </div>
        <table id='rowcount' className='  bordered'>
  
          <tr className='thback'>
            <th className='thback' width="3%">Sr No.</th>
            <th className='thback' width="auto">Description of Goods <span className='white'>Goodsssssssssssssssssss</span></th>
            <th className='thback' width="3%">HSN/SAC</th>
            <th className='thback' width="3%">GST Rate</th>
            <th className='thback' width="3%">All Qt Approx</th>
            <th className='thback' width="3%">Qty</th>
            <th className='thback' width="3%">Rate</th>
            <th className='thback' width="3%">Per</th>
            <th className='thback' width="3%">Gross Amt</th>
            <th className='thback' width="3%">Dis%</th>
            <th className='thback' width="3%">Dis Amt</th>
            <th className='thback' width="3%">Dis%</th>
            <th className='thback' width="3%">Dis Amt</th>
            <th className='thback' width="3%">Amount</th>
          </tr>
       
     {
     
     }
          
      <tbody className='height'>
            {
              removedElements.map((item, index) => {
                return <tr>
              <td className='thback1'>{startIndex + index + 1}</td>
                <td className='thback1'>{item.DescriptionofGoods}<br></br> EAN Code : {item.EANCode}</td>
                <td className='thback1'>{item.HSNSAC}</td>
                <td className='thback1'>{item.GStRate}</td>
                <td className='thback1'>{item.AllQtApprox}</td>  
                <td className='thback1'>{ item.Qty }</td>  
                 <td className='thback1'>{item.Rate}</td>
                {/* <td className='thback1'>{item.Per}</td> */}
                {item.check=="kgproduct" ? <td  className='thback1 sizeropt'>kg</td>:<td  className='thback1 sizeropt'>Pcs</td>}
                <td className='thback1'>{item.GrossAmt}</td>
                <td className='thback1'>{item.Dis}</td>
                <td className='thback1'>{item.DisAmt}</td>
                <td className='thback1'>{item.Dis1}</td>
                <td className='thback1'>{item.DisAmt1}</td>
                <td className='thback1'>{item.Amount}</td>
                </tr>
              })
  
            }
  
   
  
  
  
  
  
          </tbody> 
          
         
        </table>
   </>
  )
  }



 })()}


 {(()=>{
if(totalPages===i+1){
return (
  <>
   <table id='rowcount' className='  bordered'>
        <tr className='rede'>
          <th className='newhite' width="3%">Sr No.</th>
          <th className='newhite' width="auto">Description of Goods <span className='white'>Goodsssssssssssssssssss</span></th>
          <th className='newhite' width="3%">HSN/SAC</th>
          <th className='newhite' width="3%">GST Rate</th>
          <th className='newhite' width="3%">All Qt Approx</th>
          <th className='newhite' width="3%">Qty</th>
          <th className='newhite' width="3%">Rate</th>
          <th className='newhite' width="3%">Per</th>
          <th className='newhite' width="3%">Gross Amt</th>
          <th className='newhite' width="3%">Dis%</th>
          <th className='newhite' width="3%">Dis Amt</th>
          <th className='newhite' width="3%">Dis%</th>
          <th className='newhite' width="3%">Dis Amt</th>
          <th className='newhite' width="3%">Amount</th>
        </tr>

        <tbody className='height'>



        {/* <tr className='thback  bordert'>
            <td className='thback1'> </td>
            <td className='thback1' >  </td>
            <td className='thback1'> </td>
            <td className='thback1'> </td>
            <td className='thback1'> </td>
            <td className='thback1'></td>
            <td className='thback1'></td>
            <td className='thback1'></td>
            <td className='thback1'></td>
            <td className='thback1'></td>
            <td className='thback1'></td>
            <td className='thback1'></td>
            <td className='thback1'></td>
            <td className='thback1'> {data22.grossAmount}</td>
          </tr> */}



          <tr className='thback  bordert'>
            <td className='thback1'> </td>
            <td className='thback1' >IGST OUTPUT Rounding Off </td>
            <td className='thback1'> </td>
            <td className='thback1'> </td>
            <td className='thback1'> </td>
            <td className='thback1'></td>
            <td className='thback1'></td>
            <td className='thback1'></td>
            <td className='thback1'></td>
            <td className='thback1'></td>
            <td className='thback1'></td>
            <td className='thback1'></td>
            <td className='thback1'></td>
            <td className='thback1'>{data22.igst}<br></br>(-){data22.roundingofvalue}</td>
          </tr>
          <tr className='thback  bordert'>
            <td className='thback1'> </td>
            <td className='thback1'>Total</td>
            <td className='thback1'> </td>
            <td className='thback1'> </td>
            <td className='thback1'>{alting} </td>
            <td className='thback1'>{totalSoqty}</td>
            <td className='thback1'></td>
            <td className='thback1'></td>
            <td className='thback1'>{totalGrossamount}</td>
            <td className='thback1'></td>
            <td className='thback1'>{totalDisAmt}</td>
            <td className='thback1'></td>
            <td className='thback1'>{totalDisAmt1}</td>
            <td className='thback1'>{data22.grandtotal}</td>
          </tr>
        </tbody>
      </table>
      <div className='row     bordert'>
        <div className='col'>
          <div className='  fontprint'>  Amount Chargeable (In Words):<br></br>
          {numberToWords(data22.grandtotal)} </div>
        </div>
      </div>
      <div className='border1 row'>
    <div className='col-5 center '>
    <font>HSN/SAC </font>  
    </div>
    <div className='col-1 borderl'>
    <font>Tax Value </font>  
    </div>
    {igst!== 0 ? (
  <>
    <div className='col-1 borderl'>
      <font>IGST%</font>  
    </div>
    <div className='col-1 borderl'>
      <font>IGST Amount</font>  
    </div>
  </>
) : null}

   {igst=== 0 ?<><div className='col-1 borderl'>
    <font>CGST%</font>  
    </div>
    <div className='col-1 borderl'>
    <font>CGST Amount</font>  
    </div>
    <div className='col-1 borderl'>
    <font>SGST%</font>  
    </div>
    <div className='col-1 borderl'>
    <font>SGST Amount</font>  
    </div></>:""}
    <div className='col-1 borderl right2'>
    <font>Total<span className='smallingfont'>(Taxamount)</span></font>  
    </div>
</div>

{dow.map((item)=>{
  return    <>
         <div className='border1 row'>
    <div className='col-5 center '>
    <font>{item.HSNSAC}</font>  
    </div>
    <div className='col-1 borderl'>
    <font>{item.amount} </font>  
    </div>
    {item.gst!== 0 ? (
  <>
    <div className='col-1 borderl'>
      <font>{item.gst}%</font>  
    </div>
    <div className='col-1 borderl'>
  <font>{parseFloat(item.igstAmount).toFixed(2)}</font>  
</div>
<div className='col-1 borderl right2'>
  <font>{parseFloat((item.amount * item.gst / 100).toFixed(2))}</font>  
</div>

  </>
) : null}

   {igst === 0 ?<><div className='col-1 borderl'>
    <font>{item.cgst}%</font>  
    </div>
    <div className='col-1 borderl'>
    <font>{item.cgstAmount}</font>  
    </div>
    <div className='col-1 borderl'>
    <font>{item.sgst}%</font>  
    </div>
    <div className='col-1 borderl'>
  <font>{parseFloat(item.sgstAmount).toFixed(2)}</font>  
</div>
<div className='col-1 borderl right2'>
  <font>{parseFloat(item.totalGST).toFixed(2)}</font>  
</div>

    </>:""}
   
</div>
  
 
  
  </>
})}


{/* <div className='border1 row'>
    <div className='col-5 center '>
    <font>Total</font>  
    </div>
    <div className='col-1 borderl'>
    <font> {Amount} </font>  
    </div>
    {igst!== 0 ? (
  <>
    <div className='col-1 borderl'>
      <font></font>  
    </div>
    <div className='col-1 borderl'>
      <font>{sumigst}</font>  
    </div>
  </>
) : null}

   {igst == 0 ?<><div className='col-1 borderl'>
    <font> </font>  
    </div>
    <div className='col-1 borderl'>
    <font> {sumctax/2}  </font>  
    </div>
    <div className='col-1 borderl'>
    <font> </font>  
    </div>
    <div className='col-1 borderl'>
    <font>{sumctax/2}</font>  
    </div></>:""}
    {igst!== 0 ?<>  <div className='col-1 borderl right2'>
    <font>{sumigst}<span className='smallingfont'> </span></font>  
    </div></>:""}
    {igst== 0 ?<>  <div className='col-1 borderl right2'>
    <font>{sumctax}<span className='smallingfont'> </span></font>  
    </div></>:""}
</div> */}
<div className='border1 row'>
  <div className='col-5 center'>
    <font>Total</font>  
  </div>
  <div className='col-1 borderl'>
    <font>{parseFloat(Amount).toFixed(2)}</font>  
  </div>
  {igst !== 0 ? (
    <>
      <div className='col-1 borderl'>
        <font></font>  
      </div>
      <div className='col-1 borderl'>
        <font>{parseFloat(sumigst).toFixed(2)}</font>  
      </div>
    </>
  ) : (
    null
  )}
  {igst === 0 ? (
    <>
      <div className='col-1 borderl'>
        <font> </font>  
      </div>
      <div className='col-1 borderl'>
        <font>{(sumctax / 2).toFixed(2)}</font>  
      </div>
      <div className='col-1 borderl'>
        <font> </font>  
      </div>
      <div className='col-1 borderl'>
        <font>{(sumctax / 2).toFixed(2)}</font>  
      </div>
    </>
  ) : (
    ""
  )}
  {igst !== 0 ? (
    <>  
      <div className='col-1 borderl right2'>
        <font>{parseFloat(sumigst).toFixed(2)}<span className='smallingfont'> </span></font>  
      </div>
    </>
  ) : (
    ""
  )}
  {igst === 0 ? (
    <>  
      <div className='col-1 borderl right2'>
        <font>{parseFloat(sumctax).toFixed(2)}<span className='smallingfont'> </span></font>  
      </div>
    </>
  ) : (
    ""
  )}
</div>

      <div className='row     bordert'>
        <div className='col-2'>
          <div className='  fontprint'>  <img src='/images/salesQR.PNG' className='learnt' />   </div>
        </div>
        <div className='col  '>
          <div className='  fontprint borderl border2'>Company’s Bank Details </div>
          <div className='  fontprint  borderl'><font>Bank Name</font>:<font1>HDFC BANK LTD</font1></div>
          <div className='  fontprint  borderl'><font>A/C No.</font>:<font1>00382790000064</font1></div>
          <div className='  fontprint  borderl'><font>Branch Name</font>:<font1>EVERSHINECITY, VASAI EAST</font1></div>

          <div className='  fontprint borderl border2'><font>IFSC Code</font>:<font1>HDFC0000038</font1></div>
          <div className='  fontprint borderl '><font>Bank Instruction</font>:<font1> </font1></div>


        </div>
      </div>
      <div className='row bordert '>
        <div className='col-6 '>
          <div className='border2 ertrt12'><font>Declaration:</font></div>
        </div>
        <div className='col-6 borderl  '>
          {/* <div ><font>Total O/S :</font> <font>Total O/S :</font> <font>Total O/S :</font> </div> */}
          <div className='row border2'>
            <div className='col  '>
              <font>Total O/S :</font>
            </div>
            <div className='col borderl '>
              <font>Current O/S</font>
            </div>
            <div className='col  borderl'>
              <font>  >45 Days</font>
            </div>
            <div className='col  borderl'>
              <font>  >45 Days</font>
            </div>
          </div>
          <div className='row border2'>
            <div className='col  '>
              <font>2,165.00 Dr</font>
            </div>
            <div className='col borderl'>
              <font>2,165.00 Dr</font>
            </div>
            <div className='col borderl'>
              <font> </font>
            </div>
            <div className='col  borderl'>
              <font> </font>
            </div>
          </div>
          <div className='left2'>
            <font>NEELAM APPLIANCES LIMITED</font>
            <br></br>
            <br></br>

            <font> Authorised Signatory</font>

          </div>
        </div>
      </div>
      <div className='row   center'>
        <div className='col border1'>
          <div className='  fontprint'>  Regd & Corporate Office : Neelam House, Fafadia Indl. Estate, Golani Naka, Waliv Village, Vasai East. Dist: Palghar-401208.
            PH: 8080561321 / 22 / WhatsApp : 9321822426 EMAIL : mail@neelamappliances.com / PAN No: AABCN9540N / GST NO: 27AABCN9540N1ZY.</div>
        </div>
      </div>
      <div className='row   center'>
        <div className='col  '>
          <div className='  fontprint'> Mumbai Depot Address : Neelam Appliances Limited G-75, 1st Panjarapole Lane, Sarvodaya Nagar, C.P.Tank, Mumbai-400004. PH: 8080561323.</div>
        </div>
      </div>
      <div className='row   center'>
        <div className='col border1'>
          <div className='  fontprint'> Subject to Palghar Juridiction</div>
        </div>
      </div>
      <div className='row   center'>
        <div className='col  '>
          <div className=' fontprint'> This is an Computer generated Document, hence does not require a signature.</div>
        </div>
      </div></>
)
}
 })()}

    </div></p>);


  }


  return (

    <div className='  '>
      <div>{paragraphs}</div>



     

    </div>
  )
}

export default DeliveryNote