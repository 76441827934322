import { PURCHASE_GET_DATA_TO_LOAD } from "../constant";
import { PURCHASE_ADD_TO_CART } from "../constant";
import { PURCHASE_CHANGE_MEASUREMENT } from "../constant";
import { PURCHASE_CHANGE_QTYPCS } from "../constant";
import { PURCHASE_CHANGE_QTYKG } from "../constant";
import { CHANGE_COST_PURCHASE } from "../constant";
import { PURCHASE_CHANGE_CAL_MEASUREMENT } from "../constant";
import { CHANGE_PURCHASE_DISCOUNT } from "../constant";
import { REMOVE_FROM_CART_FROM_PURCHASE } from "../constant";
import { CHANGE_PURCHASE_TEXT } from "../constant";
import { EMPTY_PURCHASE_DATA } from "../constant";



export const getpurchasedatatoload = (data) => {

    //  console.warn("action called", data);
    return {

        type : PURCHASE_GET_DATA_TO_LOAD,
        data : data
    }

}


export const purchaseaddtocart = (data) => {

    //  console.warn("action called", data);
    return {

        type : PURCHASE_ADD_TO_CART,
        data : data
    }

}



export const removefromcartfrompurchase = (data) => {

    // console.warn("action REMOVE_TO_CART called", data);
    return {

        type : REMOVE_FROM_CART_FROM_PURCHASE,
        data : data
    }

}

export const purchasemeasurement = (data, item, data2) => {

    return {
        type: PURCHASE_CHANGE_MEASUREMENT,
        data: data,
        item: item,
        data2: data2
    }
}



export const purchaseqtypcs = (data, item, data2) => {


    return {

        type: PURCHASE_CHANGE_QTYPCS,
        data: data,
        item: item,
        data2: data2
    }

}

export const purchaseqtykgs = (data, item, data2) => {

    return {
        type: PURCHASE_CHANGE_QTYKG,
        data: data,
        item: item,
        data2: data2

    }
}


export const changecostpurchase = (data, item, data2) => {

    // console.warn("cost change called", data);
    return {

        type : CHANGE_COST_PURCHASE,
        data : data,
        item : item,
        data2 : data2

    }

}

export const purchasecalmeasurement = (data, item, data2 ) => {

    return{
        type: PURCHASE_CHANGE_CAL_MEASUREMENT,
        data: data,
        item: item,
        data2: data2
    }
}


export const changepurchasediscount = (data, item, data2) => {

    // console.warn("discount change called", data);
    return {

        type : CHANGE_PURCHASE_DISCOUNT,
        data : data,
        item : item,
        data2 : data2

    }

}


export const changepurchasetext = (data, item) => {

    // console.warn("text change called", data);
    return {

        type : CHANGE_PURCHASE_TEXT,
        data : data,
        item : item
    }

}


export const emptypurchasedata = () => {

    return {

        type : EMPTY_PURCHASE_DATA,
    }

}