import { MATERIAL_EDIT_GET_DATA_TO_LOAD } from "../constant";
import { MATERIAL_EDIT_ADD_TO_CART } from "../constant";
import { MATERIAL_CHANGE_MEASUREMENT } from "../constant";
import { MATERIAL_CHANGE_QTYPCS } from "../constant";
import { MATERIAL_CHANGE_QTYKG } from "../constant";
import { MATERIAL_CHANGE_CAL_MEASUREMENT } from "../constant";
import { CHANGE_COST_MATERIAL_EDIT } from "../constant";
import { REMOVE_FROM_CART_FROM_MATERIAL_EDIT } from "../constant";
import { CHANGE_MATERIAL_EDIT_DISCOUNT } from "../constant";
import { CHANGE_MATERIAL_EDIT_TEXT } from "../constant";
import { EMPTY_MATERIAL_EDIT_DATA } from "../constant";



export const getmaterialreceipteditdatatoload = (data) => {

    //  console.warn("action called", data);
    return {

        type : MATERIAL_EDIT_GET_DATA_TO_LOAD,
        data : data
    }

}


export const materialreceipteditaddtocart = (data) => {

    //  console.warn("action called", data);
    return {

        type : MATERIAL_EDIT_ADD_TO_CART,
        data : data
    }

}



export const removefromcartfrommaterialreceiptedit = (data) => {

    // console.warn("action REMOVE_TO_CART called", data);
    return {

        type : REMOVE_FROM_CART_FROM_MATERIAL_EDIT,
        data : data
    }

}

export const materialeditmeasurement = (data, item, data2) => {

    return {
        type: MATERIAL_CHANGE_MEASUREMENT,
        data: data,
        item: item,
        data2: data2
    }
}



export const materialeditqtypcs = (data, item, data2) => {


    return {

        type: MATERIAL_CHANGE_QTYPCS,
        data: data,
        item: item,
        data2: data2
    }

}

export const materialeditqtykgs = (data, item, data2) => {

    return {
        type: MATERIAL_CHANGE_QTYKG,
        data: data,
        item: item,
        data2: data2

    }
}


export const changecostmaterialreceiptedit = (data, item, data2) => {

    // console.warn("cost change called", data);
    return {

        type : CHANGE_COST_MATERIAL_EDIT,
        data : data,
        item : item,
        data2 : data2

    }

}

export const materialeditcalmeasurement = (data, item, data2 ) => {

    return{
        type:MATERIAL_CHANGE_CAL_MEASUREMENT,
        data: data,
        item: item,
        data2: data2
    }
}


export const changematerialreceipteditdiscount = (data, item, data2) => {

    // console.warn("discount change called", data);
    return {

        type : CHANGE_MATERIAL_EDIT_DISCOUNT,
        data : data,
        item : item,
        data2 : data2

    }

}


export const changematerialreceiptedittext = (data, item) => {

    // console.warn("text change called", data);
    return {

        type : CHANGE_MATERIAL_EDIT_TEXT,
        data : data,
        item : item
    }

}


export const emptymaterialreceipteditdata = () => {

    return {

        type : EMPTY_MATERIAL_EDIT_DATA,
    }

}