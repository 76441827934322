import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { BASE_URL } from '../../services/Api';
import authHeader from '../../services/auth-header';
import Select from "react-select";
import ProductService from '../../services/ProductService';
import Addcarinjobsheet from '../../../redux/Reducer/Addcarinjobsheet';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart, emptyCart, updateItem } from '../../../redux/Action/Addforinwardjobsheet';
import { Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import AuthService from '../../services/auth.service';
const Inwardview = () => {
    const [isLoading, setIsLoading] = useState(false);
    const applyFontSizeTransition = (elementId) => {
      const element = document.getElementById(elementId);
      element.style.transition = 'font-size 2s';
      element.style.fontSize = '30px';
      element.style.fontWeight = '600';
      // font-weight: 600;
      setTimeout(() => {
         
        document.getElementById("typer1").classList.add('promote');
        element.style.fontSize = '24px';
      }, 1000);
    };
    const { id } = useParams();
    const { pointindex } = useParams();
    const [disword, setdisword] = useState(encodeURIComponent(' '))
    const [dis, setdistributordata1] = useState([])
    const dispatch = useDispatch();
    const [jobsheetno, setJobsheetno] = useState([])
    const [jobsheetdate, setJobsheetdate] = useState([])
    const [jobtype, setJobtype] = useState([])
    const [remarks, setRemarks] = useState([])
    const [grandtotal, setGrandtotal] = useState([])
    const [warehouse, setWarehouse] = useState([])
    const [supplier, setSupplier] = useState([])
    const [jobsheetItems, setJobsheetItems] = useState([])
    const items = useSelector(state => state.Addcarinjobsheet.items);
    console.log(items)
    // console.log((items.map((item)=>Number(item.amt))))
    // const checkqty=useSelector(state=>state.Addcarinjobsheet)

    // alert(supplier)
    let sum
    if (items) {
        sum = items.reduce((acc, item) => acc + Number(item.amount), 0);
        console.log(sum)
    }

    useEffect(() => {

        axios.get(`${BASE_URL}product/page/0/200/asc/id/${disword}`, { headers: authHeader() })
            .then(response => {
                console.log(response)
                if (response.data.data == undefined) {
                    setdistributordata1(response.data.Index.map(use => ({ value: use.id, label: use.product_Name })));
                } if (response.data.Index == undefined) {
                    setdistributordata1(response.data.data.map(use => ({ value: use.id, label: use.product_Name })));

                }

                // console.log(response.data.data.map(use => ({ value: use.id, label: use.product_Name })))
            })




    }, [disword])
    const disid = (val) => {
        console.log(typeof val)
        // if(typeof val=='number'){
        // alert('its number')

        ProductService.getProductEdit(val).then(res => {
            console.log([res.data])
            const productdetails = [res.data]
            console.log(productdetails.map((item) => item.id))
            const extractedData = productdetails.map(item => ({
                id: item.id,
                productName: item.productName,
                dlp: item.dlp,
                type: item.productType,
                jobsheet_qty: 0,
                amount: 0,
                product: {
                    "id": val
                },
                type1: 'box',

                jobsheet_qty_kg: 0,
                uomSecondary: Number(item.uomSecondary)
            }));
            // alert(jobsheetItems.some((item)=>item.id==val))
            // console.log(extractedData[0])
            //   if(jobsheetItems.some((item)=>item.id==val)==false){
            dispatch(addToCart(extractedData[0]));
            //   }else{
            // alert('Already Exist')
            //   }
        })

        // else{
        //     alert('Already Exist')
        // }
        // setdistributor(val)
    }
    let onChangeSupplierData = (e) => {

        let name = e.value;


    };
    const [disword1, setdisword1] = useState(encodeURIComponent(' '))
    const [dis1, setdistributordata11] = useState([])

    useEffect(() => {

        axios.get(`http://43.225.54.138:8080/scm/api/supplier/page/0/10/asc/id/${disword1}`, { headers: authHeader() })
            .then(response => {
                console.log(response)
                if (response.data.data == undefined) {
                    setdistributordata11(response.data.Index.map(use => ({ value: use.id, label: use.suppliername })));
                } if (response.data.Index == undefined) {
                    setdistributordata11(response.data.data.map(use => ({ value: use.id, label: use.suppliername })));

                }

                //   console.log(response.data.data.map(use => ({ value: use.id, label: use.suppliername})))  
            })



    }, [disword])
    useEffect(() => {
        setJobsheetItems(items)
    }, [items])


    const handleclikc = () => {

        // items
        // items
        let extractedData = items.map(item => ({
            // id:item.id,
            amount: item.amount,
            dlp: item.dlp,
            jobsheet_qty: item.jobsheet_qty,
            productType: item.type1 !== 'kg' ? 'pcs' : 'kg',
            product: {
                "id": item.id
            },
            jobsheet_qty_kg: item.jobsheet_qty_kg,

        }));

        console.log([extractedData][0])


        const data = [{
            jobsheetno: jobsheetno,
            jobsheetdate: jobsheetdate,
            jobtype: jobtype,
            remarks: remarks,
            grandtotal: sum,
            warehouse: { id: Number(warehouse) },
            supplier: { id: Number(supplier) },
            // jobsheetItems:  [
            //     {
            //         "productType": "pcs",
            //         "jobsheet_qty": 20,
            //         "jobsheet_qty_kg": 10,
            //         "amount": 100,
            //         "rate": 20,
            //         "mrp": 10,
            //         "product": {
            //             "id": 6
            //         }
            //     },  {
            //         "productType": "pcs",
            //         "jobsheet_qty": 20,
            //         "jobsheet_qty_kg": 10,
            //         "amount": 100,
            //         "rate": 20,
            //         "mrp": 10,
            //         "product": {
            //             "id": 6
            //         }
            //     }
            // ]
            jobsheetItems: extractedData
        }];
        axios.put(BASE_URL + `jobworkinward/${id}`, data[0], {
            headers: authHeader()
        })
            .then(response => {
                console.log('Post request successful:', response.data);
                alert('sent')
            })
            .catch(error => {
                console.error('Error in post request:', error);
            });
    }
    const [disword2, setdisword2] = useState(encodeURIComponent(' '))
    const [dis2, setdistributordata2] = useState([])
    const [supplierDetails, setSupplierDetails] = useState([])
    const [warehousedetails, setWarehousedetails] = useState([])
    useEffect(() => {

        axios.get(`http://43.225.54.138:8080/scm/api/warehouse/page/0/10/asc/id/${disword2}`, { headers: authHeader() })
            .then(response => {
                console.log(response)
                if (response.data.data == undefined) {
                    setdistributordata2(response.data.Index.map(use => ({ value: use.id, label: use.name })));
                } if (response.data.Index == undefined) {
                    setdistributordata2(response.data.data.map(use => ({ value: use.id, label: use.name })));

                }

                // console.log(response.data.data.map(use => ({ value: use.id, label: use.suppliername})))  
            })



    }, [disword2])

    let data = [{ value: 'Cutting', label: 'Cutting' }, { value: 'Shaping', label: 'Shaping' }, { value: 'Molding ', label: 'Molding' }, { value: 'Finishing', label: 'Finishing' }]
    const navigate = useNavigate();


    useEffect(() => {
        dispatch(emptyCart());

        axios.get(BASE_URL + `jobworkinward/${id}`, {
            headers: authHeader()
        })
            .then(response => {

                console.log(response.data);
                setJobsheetno(response.data.jobsheetno)
                setJobsheetdate(response.data.jobsheetdate)
                setJobtype(response.data.jobtype)
                setRemarks(response.data.remarks)
                setSupplier(response.data.supplier.id)
                // setSupplierDetails(response.data.supplier.id warehouse)
                setWarehousedetails([response.data.warehouse].map(use => ({ value: use.id, label: use.name })))
                setSupplierDetails([response.data.supplier].map(use => ({ value: use.id, label: use.suppliername })))
                setWarehouse(response.data.warehouse.id)
                // alert('sent')
                console.log([response.data.supplier])
                const data = response.data.jobsheetItems
                const extractedData = data.map(item => ({
                    id: item.product.id,
                    productName: item.product.productName,
                    dlp: item.dlp,
                    type: item.product.productType,
                    type1: item.productType,
                    jobsheet_qty: item.jobsheet_qty,
                    amount: item.amount,
                    product: {
                        "id": item.product.id
                    },
                    type1: item.productType == 'kg' ? 'kg' : 'box',

                    jobsheet_qty_kg: item.jobsheet_qty_kg,
                    uomSecondary: Number(item.product.uomSecondary)
                }));

                console.log(extractedData)
                // setJobsheetItems(extractedData)
                // setJobsheetItems(extractedData);

                // Dispatch addToCart for each item
                extractedData.forEach(item => {
                    dispatch(addToCart(item));
                });

            })
            .catch(error => {
                console.error(error);
            });
    }, [])
    console.log(warehousedetails)
    //  let sup=supplier
    const getItem = (id) => {

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {

                axios.delete(BASE_URL + 'jobworkinward/' + id, {
                    headers: authHeader()
                }).then(res => {
                    Swal.fire(
                        'Deleted!',
                        'Your file has been deleted.',
                        'success'
                    )
                    navigate(`/Inwardtable/${pointindex}`)
                }).catch(error => {
                    console.error('Error adding  :', error);
                    if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      footer: `
                        <div>
                          <p id="issueText">Why do I have this issue?</p>
                          <ul>
                      
                          <li id="typer1">You might have an internet issue</li>
                          </ul>
                        </div>
                      `,
                      didOpen: () => {
                        applyFontSizeTransition('issueText');
                      }
                    });
                  }
                  })
                  .finally(() => {
                    setIsLoading(false); 
                  });


            }
        })
    };
    const user = AuthService.getCurrentUser();

    return (
        <div>
            <div className='card'>
                <div className='card-body'>

                    <div className='card-title card-header'>
                        Job-sheet in-Ward View
                    </div>
                    <div className='card-header card-title'>
                        <Link className='p-2 w-200 card-header card-title bd-highlight' to={`/Inwardtable/${pointindex}`}><Button className='btn-sm'>Back</Button></Link>
                        <Link className='p-2 w-200 card-header card-title bd-highlight' to={`/inwardedit/${id}/${pointindex}`}><Button className='btn-sm'>Edit</Button></Link>
                        {/* <button className='btn btn-primary btn-sm' onClick={() => getItem(id)}>Delete</button> */}
                        {user.roles[0] === 'ROLE_ADMIN' &&
                        <Link className='p-2 w-200 card-header card-title bd-highlight' variant="primary" style={{width:'auto'}} onClick={() => getItem(id)} disabled={isLoading}>
        
        <Button className="btn-sm">  {isLoading ? 'Loading...' : 'Delete'}</Button>
      </Link>
      }
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <label className='form-label'>Jobsheet Number</label>
                            <input type='text' disabled className='form-control' value={jobsheetno} onChange={(e) => setJobsheetno(e.target.value)} />
                        </div>
                        <div className='col'>
                            <label className='form-label'>  Date</label>
                            <input type='Date' disabled className='form-control' value={jobsheetdate} onChange={(e) => setJobsheetdate(e.target.value)} />
                        </div>

                    </div>
                    <div className='row'>
                        <div className='col'>
                            <label className='form-label'>Supplier</label>
                            <input type='text' style={{ border: 'none', background: 'white' }} disabled />
                            <Select
                                // options={dis1}
                                className='sett'
                                value={[{ value: supplierDetails.map((item) => item.value), label: supplierDetails.map((item) => item.label) }]}
                                onInputChange={(inputValue) => setdisword1(inputValue)}
                                onChange={(options) => { setSupplier(options.value); setSupplierDetails([options]) }}
                            />

                        </div>
                        <div className='col'>
                            <label className='form-label'>Warehouse</label>
                            <Select
                                // options={dis2}
                                className='sett'
                                disabled
                                value={[{ value: warehousedetails.map((item) => item.value), label: warehousedetails.map((item) => item.label) }]}
                                onInputChange={(inputValue) => setdisword2(inputValue)}
                                onChange={(options) => { setWarehouse(options.value); setWarehousedetails([options]) }}
                            />
                        </div>
                        <div className='col'>
                            <label className='form-label'>Job Type</label>
                            <Select   className='sett' disabled value={[{ value: jobtype, label: jobtype }]} onInputChange={(inputValue) => setdisword1(inputValue)} onChange={(options) => setJobtype(options.value)} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <label className='form-label'>Remarks</label>
                            <textarea className='form-control' disabled value={remarks} onChange={(e) => setRemarks(e.target.value)}>

                            </textarea>
                        </div>

                    </div><br></br>
                    {/* <input type='text' /> */}
                    {/* <Select options={dis} disabled onInputChange={(inputValue) => setdisword(inputValue)} onChange={(options) => disid(options.value)} /> */}
                    <br></br>
                    <table className='table thrtable '>
                        <thead>
                            <tr>
                                <th>Product Name</th>
                                <th>Product Type</th>
                                <th>Qty  </th>
                                <th>Qty kg</th>
                                <th>Rate  </th>
                                <th>Amount</th>
                                {/* <th width='50' height='12'>Action</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {jobsheetItems.map((item) => {
                                return <>
                                    <tr>
                                        {/* {item.type} */}
                                        <td>{item.productName}</td>
                                        <td width='150' height='12'><select className='form-select' disabled value={item.type1 !== 'kg' ? 'box' : 'kg'} onChange={(e) => {
                                            dispatch(updateItem(item.id, e.target.value, item.jobsheet_qty, item.dlp, item.jobsheet_qty_kg, item.amount));
                                        }}  >
                                            <option>Select</option>
                                            <option value='box'>Box</option>
                                            {item.type == 'kgproduct' ? <option value='kg'>KG</option> : ''}
                                        </select></td>
                                        <td width='150' height='12'><input type='number' min='0' value={Number(item.jobsheet_qty)} disabled  onChange={(e) => {
                                            dispatch(updateItem(item.id, item.type1, Number(e.target.value), item.dlp, item.jobsheet_qty_kg, item.amount));
                                        }} className='form-control' /></td>
                                        {/* <td>{item.qtykg}</td> */}
                                        <td width='150' height='12'><input type='number' min='0' disabled value={Number(item.jobsheet_qty_kg)}   onChange={(e) => {
                                            dispatch(updateItem(item.id, item.type1, item.jobsheet_qty, item.dlp, e.target.value, item.amount));
                                        }} className='form-control' /></td>
                                        <td width='150' height='12'><input type='number'  min='0' disabled value={Number(item.dlp)} onChange={(e) => {
                                            dispatch(updateItem(item.id, item.type1, item.jobsheet_qty, Number(e.target.value), item.jobsheet_qty_kg, item.amount));
                                        }} className='form-control' /></td>
                                        <td width='150' height='12'><input type='number' disabled min='0' value={Number(item.amount)} className='form-control' /></td>
                                        {/* <td>X</td> */}
                                    </tr>

                                </>

                            })}
                        </tbody>

                    </table>
                    <div className='row'>
                        <div className='col tomforgrandtotal'>
                            Total:{sum.toFixed(2)}
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col'>
                            <Link className='p-2 w-200 card-header card-title bd-highlight' to={`/Inwardtable/${pointindex}`}><Button className='btn-sm'>Back</Button></Link>
                        </div>
                    </div>
                </div>

            </div>



        </div>
    )
}

export default Inwardview