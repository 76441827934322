import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../services/Api'
import authHeader from '../services/auth-header'
import { MDBCard } from 'mdbreact'
import { Button, Col, Form, Row } from 'react-bootstrap'
import Select from 'react-select'
import { Link, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

export default function Create() {
    const [isLoading, setIsLoading] = useState(false);
    const applyFontSizeTransition = (elementId) => {
      const element = document.getElementById(elementId);
      element.style.transition = 'font-size 2s';
      element.style.fontSize = '30px';
      element.style.fontWeight = '600';
      // font-weight: 600;
      setTimeout(() => {
         
        document.getElementById("typer1").classList.add('promote');
        element.style.fontSize = '24px';
      }, 1000);
    };
    const [ branchname, setbranchname ] = useState('');
    const [ phoneno, setphoneno ] = useState('');
    const [ email, setemail ] = useState('');
    const [ gstno, setgstno ] = useState('');
    const [ address, setaddress ] = useState('');
    const [ zipcode, setzipcode ] = useState('');
    const [ bankname, setbankname ] = useState('');
    const [ accountno, setaccountno ] = useState('');
    const [ branch, setbranch ] = useState('');
    const [ ifsc, setifsc ] = useState('');
    const [ cities, setcities ] = useState('');
    const [ states, setstates ] = useState('');
    const [ statedata, setstatedata ] = useState('');
    const[gsastate,setgstate]=useState([])

    let navigate = useNavigate();

    let getStateData = ( ) => {

        axios.get( BASE_URL + 'state',{
            headers:authHeader()
        }).then(res=>{
            setstatedata((res.data).map(user=>({value:user.id,label:user.name})))
        });
    }
    useEffect(()=>{
        // axios.get(BASE_URL+'branch/',{
        //     headers:authHeader()
        // }).then(res=>{
        //     console.log("mello",res.data)
        // })
        getStateData();
    },[]);

    let onChangeState = ( e ) => {
        setstates({"id":e.value});
    }

    const submitdata = ( ) => {

        let country = {"id":101};
        setIsLoading(true); 

        axios.post( BASE_URL + 'branch/',{branchname,phoneno,email,gstno,address,zipcode,bankname,accountno,branch,ifsc,cities,states,country},{
            headers:authHeader()
        }).then(res=>{
            navigate('/Branch/Table/1')
        }) .catch(error => {
            console.error('Error adding  :', error);
            if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `
                <div>
                  <p id="issueText">Why do I have this issue?</p>
                  <ul>
              
                  <li id="typer1">You might have an internet issue</li>
                  </ul>
                </div>
              `,
              didOpen: () => {
                applyFontSizeTransition('issueText');
              }
            });
          }
          })
          .finally(() => {
            setIsLoading(false); 
          });
    }
    const getgstdetails=()=>{
        let cap
        axios.get(`http://43.225.54.138:8080/scm/gst/gstin/${gstno}`, { headers: authHeader() })
        .then(response => {
            console.log(response.data)
            // setGstdetails(response.data)
            axios.get(`http://43.225.54.138:8080/scm/api/state
            `, { headers: authHeader() })
            .then(response1 => {
                let statename=response1.data.filter((item)=>item.state_code==response.data.StateCode).map((item)=>item.name).toString()
                setgstate(statename)
                setaddress(response.data.AddrBnm+" "+response.data.AddrBno+" "+response.data.AddrFlno+" "+response.data.AddrLoc+" "+response.data.AddrSt+" "+response.data.AddrPncd+" "+statename)

            })
            .catch(error => {
              console.error(error);
            });
            
        })
        .catch(error => {
          console.error(error);
        });


    }


    return (
        <MDBCard className="company">
            <h1>Create Branch</h1>
            <Link to='/Branch/Table/1' className="btn btn-primary">Back</Link>
            <Form className="product1">
                <Row className="mb-1">
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Branch Name</Form.Label>
                        <Form.Control type="text" autoComplete="off" onChange={(e) => setbranchname(e.target.value)}  />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Phone No</Form.Label>
                        <Form.Control type="text" autoComplete="off" onChange={(e) => setphoneno(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">GST No</Form.Label>
                        <Form.Control type="text" autoComplete="off" onChange={(e) => setgstno(e.target.value)} />
                    </Form.Group>
                    <a className="btn btn-sm ertere" onClick={getgstdetails}>Get Gst Details</a>

                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Email</Form.Label>
                        <Form.Control type="text" autoComplete="off" onChange={(e) => setemail(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="12" controlId="formBasicName">
                        <Form.Label className="label">Address</Form.Label>
                        <Form.Control as="textarea" value={address} autoComplete="off" rows={3} name='address' placeholder='Address' onChange={(e) => setaddress(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Country</Form.Label>
                        <Form.Control type="text"  value={"India"} disabled/>
                    </Form.Group>                    
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">State</Form.Label>
                        <Select options={statedata} onChange={(e) => onChangeState(e)}/>
                    </Form.Group>                    
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">City</Form.Label>
                        <Form.Control type="text" autoComplete="off" onChange={(e) => setcities(e.target.value)}/>
                    </Form.Group>        
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Pin Code</Form.Label>
                        <Form.Control type="Number" autoComplete="off" onChange={(e) => setzipcode(e.target.value)}/>
                    </Form.Group>             
                     <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Account No</Form.Label>
                        <Form.Control type="text" autoComplete="off" onChange={(e) => setaccountno(e.target.value)}/>
                    </Form.Group>                   
                     <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Bank Name</Form.Label>
                        <Form.Control type="text" autoComplete="off" onChange={(e) => setbankname(e.target.value)}/>
                    </Form.Group>                    
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Branch Name</Form.Label>
                        <Form.Control type="text" autoComplete="off"  onChange={(e) => setbranch(e.target.value)} />
                    </Form.Group>                   
                     <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">IFSC Code</Form.Label>
                        <Form.Control type="text" autoComplete="off"  onChange={(e) => setifsc(e.target.value)}/>
                    </Form.Group>
                </Row>
                <Button variant="primary" style={{width:'auto'}} onClick={submitdata} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Submit'}
        </Button>
            </Form>

        </MDBCard>
    )
}
