import { SALES_RETURN_PUT_ADD_TO_CART } from "../constant";
import { SALES_RETURN_MEASUREMENT } from "../constant";
import { SALES_RETURN_CALMEASUREMENT } from "../constant";
import { SALES_RETURN_PCS } from "../constant";
import { SALES_RETURN_QTY } from "../constant";
import { SALES_RETURN_REMOVE_FROM_CART } from "../constant";
import { SALES_RETURN_COST } from "../constant";
import { SALES_RETURN_DISCOUNT } from "../constant";
import { SALES_RETURN_TEXT } from "../constant";
import { SALES_RETURN_EMPTY_DATA } from "../constant";


export const salesreturndata = (data = [], action) => {

    switch (action.type) {

        case SALES_RETURN_PUT_ADD_TO_CART:
            let dataNew = action.data;
            let newData = action.data2;

            function discountData1() {
                if (dataNew.productType == "boxproduct") {
                    return ({ "ProductDiscount": newData.boxProductDiscount, "schemeDiscount": newData.schemeboxProductDiscount });
                }
                else if (dataNew.productType == "kgproduct") {
                    return ({ "ProductDiscount": newData.kgProductDiscount, "schemeDiscount": newData.schemekgProductDiscount });
                }
                else if (dataNew.productType == "corporateproduct") {
                    return ({ "ProductDiscount": newData.corporaetProductDiscount, "schemeDiscount": newData.schemecorporateProductDiscount });
                }
                else if (dataNew.productType == "cookerproduct") {
                    return ({ "ProductDiscount": newData.cookerProductDiscount, "schemeDiscount": newData.schemecookerProductDiscount });
                }
                else if (dataNew.productType == "noshproduct") {
                    return ({ "ProductDiscount": newData.noshProductDiscount, "schemeDiscount": newData.schemenoshProductDisocunt });
                }
            }
            let disData = discountData1();

            let currentData = {
                // srid:action.data.id,
                brand: action.data.brand,
                capacity: action.data.capacity,
                category: action.data.category,
                cgst: action.data.cgst,
                cgstLedger: action.data.cgstLedger,
                diameter: action.data.diameter,
                eanCode: action.data.eanCode,
                hsnCode: action.data.hsnCode,
                igstLedger: action.data.igstLedger,
                dlp: action.data.dlp,
                standardQtyPerBox : action.data.standardQtyPerBox,
                sgst: action.data.sgst,
                sgstLedger: action.data.sgstLedger,
                shortName: action.data.shortName,
                uomPrimary: action.data.uomPrimary,
                uomSecondary: action.data.uomSecondary,
                tradeDiscount: disData.ProductDiscount,
                schemeDiscount: disData.schemeDiscount,
                product: { "id": action.data.id },
                igst: action.data.igst,
                // mrp: action.data.mrp,
                productName: action.data.productName,
                productType:action.data.productType,
                unitofmeasurement: action.data.unitofmeasurement == undefined ?action.data.productType !== "kgproduct" ? "box" :'' :action.data.unitofmeasurement,
                calunitofmeasurement: action.data.calunitofmeasurement == undefined ? action.data.productType !== "kgproduct" ? "box" : '' : action.data.calunitofmeasurement

            }

            return[currentData,...data];

            case SALES_RETURN_MEASUREMENT:
                let newCart = [...data]
                let itemIndex = newCart.findIndex(obj => obj.product.id === action.item.product.id)
                let currItem = data[itemIndex]

                currItem.unitofmeasurement = action.data
                data[itemIndex] = currItem
                return [...data];

                case SALES_RETURN_CALMEASUREMENT:
                    let newCart6 = [...data]
                    let itemIndex6 = newCart6.findIndex(obj => obj.product.id === action.item.product.id)
                    let currItem6 = data[itemIndex6]
        
                    currItem6.calunitofmeasurement = action.data
        
                    if (currItem6.calunitofmeasurement === "pcs") {
                        if (currItem6.tradeDiscount > 0 || currItem6.schemeDiscount > 0) {
        
                            let discount1 = Number(currItem6.srquantity) * Number(currItem6.dlp) * (Number(currItem6.tradeDiscount) / 100)
                            let totaAmount = (Number(currItem6.srquantity) * Number(currItem6.dlp)) - Number(discount1);
                            currItem6.grossamount = (Number(totaAmount)).toFixed(2);
                            let schemediscount = ((Number(totaAmount) * Number(currItem6.schemeDiscount)) / 100);
                            let NetAmount = (Number(totaAmount) - Number(schemediscount));
                            currItem6.netAmount = (Number(NetAmount)).toFixed(2);
                            currItem6.gstvalue = ((Number(currItem6.netAmount) / 100) * Number(currItem6.igst)).toFixed(2);
                            currItem6.total = (Number(currItem6.gstvalue) + Number(currItem6.netAmount)).toFixed(2);
                            data[itemIndex6] = currItem6
        
                        } else {
                            currItem6.grossamount = (Number(currItem6.srquantity) * Number(currItem6.dlp))
                            currItem6.netAmount = (Number(currItem6.grossamount)).toFixed(2);
                            currItem6.gstvalue = ((Number(currItem6.netAmount) / 100) * Number(currItem6.igst)).toFixed(2);
                            currItem6.total = ((Number(currItem6.gstvalue)) + (Number(currItem6.netAmount))).toFixed(2);
                            data[itemIndex6] = currItem6
                        }
                    }
                    if (currItem6.calunitofmeasurement === "kg") {
                        if (currItem6.tradeDiscount > 0 || currItem6.schemeDiscount > 0) {
                            let discount1 = Number(currItem6.srquantitykgs) * Number(currItem6.dlp) * (Number(currItem6.tradeDiscount) / 100)
                            let totaAmount = (Number(currItem6.srquantitykgs) * Number(currItem6.dlp)) - Number(discount1);
                            currItem6.grossamount = (Number(totaAmount)).toFixed(2);
                            let schemediscount = ((Number(totaAmount) * Number(currItem6.schemeDiscount)) / 100);
                            let NetAmount = (Number(totaAmount) - Number(schemediscount));
                            currItem6.netAmount = (Number(NetAmount)).toFixed(2);
                            currItem6.gstvalue = ((Number(currItem6.netAmount) / 100) * Number(currItem6.igst)).toFixed(2);
                            currItem6.total = (Number(currItem6.gstvalue) + Number(currItem6.netAmount)).toFixed(2);
                            data[itemIndex6] = currItem6
        
                        } else {
                            currItem6.grossamount = (Number(currItem6.srquantitykgs) * Number(currItem6.dlp)).toFixed(2);
                            currItem6.netAmount = (Number(currItem6.grossamount)).toFixed(2);
                            currItem6.gstvalue = ((Number(currItem6.netAmount) / 100) * Number(currItem6.igst)).toFixed(2);
                            currItem6.total = ((Number(currItem6.gstvalue)) + (Number(currItem6.netAmount))).toFixed(2);
                            data[itemIndex6] = currItem6
                        }
                    }
                    return [...data];


                case SALES_RETURN_PCS:
                    let newCart1 = [...data]
                    let itemIndex1 = newCart1.findIndex(obj => obj.product.id === action.item.product.id)
                    let currItem1 = data[itemIndex1]


                    if (currItem1.unitofmeasurement == "box") {
                        if (currItem1.tradeDiscount > 0 || currItem1.schemeDiscount > 0) {
                            if(action.data === ""){
                                currItem1.srquantity = ""
                            } else{
                                currItem1.srquantity = Number(action.data)
                            }
                             currItem1.srquantitykgs = 0;
                            let discount2 = Number(currItem1.srquantity) * Number(currItem1.dlp) * (Number(currItem1.tradeDiscount) / 100);
                            let totaAmount = Number(currItem1.srquantity) * Number(currItem1.dlp) - Number(discount2);
                            currItem1.grossamount = (Number(totaAmount)).toFixed(2);
                            let schemediscount = ((Number(totaAmount) * Number(currItem1.schemeDiscount)) / 100);
                            let NetAmount = (Number(totaAmount) - Number(schemediscount));
                            currItem1.netAmount = (Number(NetAmount)).toFixed(2);
                            currItem1.gstvalue = ((Number(currItem1.netAmount) / Number(100)) * (Number(currItem1.igst))).toFixed(2);
                            currItem1.total = (Number(currItem1.gstvalue) + Number(currItem1.netAmount)).toFixed(2);
                            data[itemIndex1] = currItem1
                        } else {
                            if (action.data === "") {
                                currItem1.srquantity = ""
                            } else{
                                currItem1.srquantity = Number(action.data)
                            }
                            currItem1.srquantitykgs = 0;
                            currItem1.tradeDiscount = Number(0);
                            currItem1.grossamount = (Number(currItem1.srquantity) * Number(currItem1.dlp)).toFixed(2);
                            let NetAmount = (Number(currItem1.srquantity) * Number(currItem1.dlp));
                            currItem1.netAmount = (Number(NetAmount)).toFixed(2);
                            currItem1.gstvalue = ((Number(currItem1.netAmount) / Number(100)) * (Number(currItem1.igst))).toFixed(2)
                            currItem1.total = (Number(currItem1.gstvalue) + Number(currItem1.netAmount)).toFixed(2);
                            data[itemIndex1] = currItem1
                        }
                    }
                    
                    if (currItem1.unitofmeasurement === "pcs") {

                        if (action.data === "") {
                            currItem1.srquantity = "";
                            currItem1.srquantitykgs = "";
                        } else {
                            currItem1.srquantity = Number(action.data)
                            let aa = Number(currItem1.srquantity)
                            currItem1.srquantitykgs = (Number(aa) * Number(currItem1.uomSecondary)).toFixed(3);
        
                        }
                    }

                    if (currItem1.unitofmeasurement === "kg") {

                        if (action.data === "") {
                            currItem1.srquantity = "";
        
                        } else {
                            currItem1.srquantity = Number(action.data)
        
                        }
                    }



                    if (currItem1.calunitofmeasurement === "pcs") {
                        if(currItem1.unitofmeasurement == "pcs"){
                            if (currItem1.tradeDiscount > 0 || currItem1.schemeDiscount > 0) {
                                if(action.data === ""){
                                    currItem1.srquantity = ""
                                } else{
                                    currItem1.srquantity = Number(action.data);
                                    let aa = Number(currItem1.srquantity)
                                    currItem1.srquantitykgs = (Number(aa) * Number(currItem1.uomSecondary)).toFixed(3);
                                }
                                let discount2 = Number(currItem1.srquantity) * Number(currItem1.dlp) * (Number(currItem1.tradeDiscount) / 100);
                                let totaAmount = Number(currItem1.srquantity) * Number(currItem1.dlp) - Number(discount2);
                                currItem1.grossamount = (Number(totaAmount)).toFixed(2);
                                let schemediscount = ((Number(totaAmount) * Number(currItem1.schemeDiscount)) / 100);
                                let NetAmount = (Number(totaAmount) - Number(schemediscount));
                                currItem1.netAmount = (Number(NetAmount)).toFixed(2);
                                currItem1.gstvalue = ((Number(currItem1.netAmount) / Number(100)) * (Number(currItem1.igst))).toFixed(2);
                                currItem1.total = (Number(currItem1.gstvalue) + Number(currItem1.netAmount)).toFixed(2);
                                data[itemIndex1] = currItem1
                            } else {
                                if (action.data === "") {
                                    currItem1.srquantity = ""
                                } else{
                                    currItem1.srquantity = Number(action.data)
                                    let aa = Number(currItem1.srquantity)
                                    currItem1.srquantitykgs = (Number(aa) * Number(currItem1.uomSecondary)).toFixed(3);
                                }
                                currItem1.tradeDiscount = Number(0);
                                currItem1.grossamount = (Number(currItem1.srquantity) * Number(currItem1.dlp)).toFixed(2);
                                let NetAmount = (Number(currItem1.srquantity) * Number(currItem1.dlp));
                                currItem1.netAmount = (Number(NetAmount)).toFixed(2);
                                currItem1.gstvalue = ((Number(currItem1.netAmount) / Number(100)) * (Number(currItem1.igst))).toFixed(2)
                                currItem1.total = (Number(currItem1.gstvalue) + Number(currItem1.netAmount)).toFixed(2);
                                data[itemIndex1] = currItem1
                            }
    
                        }else if (currItem.unitofmeasurement === 'kg') {
                            if (currItem1.tradeDiscount > 0 || currItem1.schemeDiscount > 0) {
                                if(action.data === ""){
                                    currItem1.srquantity = ""
                                } else{
                                    currItem1.srquantity = Number(action.data);
                                }
                                let discount2 = Number(currItem1.srquantity) * Number(currItem1.dlp) * (Number(currItem1.tradeDiscount) / 100);
                                let totaAmount = Number(currItem1.srquantity) * Number(currItem1.dlp) - Number(discount2);
                                currItem1.grossamount = (Number(totaAmount)).toFixed(2);
                                let schemediscount = ((Number(totaAmount) * Number(currItem1.schemeDiscount)) / 100);
                                let NetAmount = (Number(totaAmount) - Number(schemediscount));
                                currItem1.netAmount = (Number(NetAmount)).toFixed(2);
                                currItem1.gstvalue = ((Number(currItem1.netAmount) / Number(100)) * (Number(currItem1.igst))).toFixed(2);
                                currItem1.total = (Number(currItem1.gstvalue) + Number(currItem1.netAmount)).toFixed(2);
                                data[itemIndex1] = currItem1
                            } else {
                                if (action.data === "") {
                                    currItem1.srquantity = ""
                                } else{
                                    currItem1.srquantity = Number(action.data)
                                }
                                currItem1.tradeDiscount = Number(0);
                                currItem1.grossamount = (Number(currItem1.srquantity) * Number(currItem1.dlp)).toFixed(2);
                                let NetAmount = (Number(currItem1.srquantity) * Number(currItem1.dlp));
                                currItem1.netAmount = (Number(NetAmount)).toFixed(2);
                                currItem1.gstvalue = ((Number(currItem1.netAmount) / Number(100)) * (Number(currItem1.igst))).toFixed(2)
                                currItem1.total = (Number(currItem1.gstvalue) + Number(currItem1.netAmount)).toFixed(2);
                                data[itemIndex1] = currItem1
                            }
                        }
                    }
                    if (currItem1.calunitofmeasurement === "kg") {
                        if(currItem1.unitofmeasurement == "pcs"){
                            if (currItem1.tradeDiscount > 0 || currItem1.schemeDiscount > 0) {
                                if(action.data === ""){
                                    currItem1.srquantity = "";
                                    currItem1.srquantitykgs = '';
                                } else{
                                    currItem1.srquantity = Number(action.data);
                                    let aa = Number(currItem1.srquantity)
                                    currItem1.srquantitykgs = (Number(aa) * Number(currItem1.uomSecondary)).toFixed(3);
                                }
                                let discount2 = Number(currItem1.srquantitykgs) * Number(currItem1.dlp) * (Number(currItem1.tradeDiscount) / 100);
                                let totaAmount = Number(currItem1.srquantitykgs) * Number(currItem1.dlp) - Number(discount2);
                                currItem1.grossamount = (Number(totaAmount)).toFixed(2);
                                let schemediscount = ((Number(totaAmount) * Number(currItem1.schemeDiscount)) / 100);
                                let NetAmount = (Number(totaAmount) - Number(schemediscount));
                                currItem1.netAmount = (Number(NetAmount)).toFixed(2);
                                currItem1.gstvalue = ((Number(currItem1.netAmount) / Number(100)) * (Number(currItem1.igst))).toFixed(2);
                                currItem1.total = (Number(currItem1.gstvalue) + Number(currItem1.netAmount)).toFixed(2);
                                data[itemIndex1] = currItem1
                            } else {
                                if (action.data === "") {
                                    currItem1.srquantity = "";
                                    currItem1.srquantitykgs = '';
                                } else{
                                    currItem1.srquantity = Number(action.data)
                                    let aa = Number(currItem1.srquantity)
                                    currItem1.srquantitykgs = (Number(aa) * Number(currItem1.uomSecondary)).toFixed(3);
                                }
                                currItem1.tradeDiscount = Number(0);
                                currItem1.grossamount = (Number(currItem1.srquantitykgs) * Number(currItem1.dlp)).toFixed(2);
                                let NetAmount = (Number(currItem1.srquantitykgs) * Number(currItem1.dlp));
                                currItem1.netAmount = (Number(NetAmount)).toFixed(2);
                                currItem1.gstvalue = ((Number(currItem1.netAmount) / Number(100)) * (Number(currItem1.igst))).toFixed(2)
                                currItem1.total = (Number(currItem1.gstvalue) + Number(currItem1.netAmount)).toFixed(2);
                                data[itemIndex1] = currItem1
                            }
    
                        }else if (currItem1.unitofmeasurement === 'kg') {
                            if (currItem1.tradeDiscount > 0 || currItem1.schemeDiscount > 0) {
                                if(action.data === ""){
                                    currItem1.srquantity = "";
                                    
                                } else{
                                    currItem1.srquantity = Number(action.data);

                                }
                                let discount2 = Number(currItem1.srquantitykgs) * Number(currItem1.dlp) * (Number(currItem1.tradeDiscount) / 100);
                                let totaAmount = Number(currItem1.srquantitykgs) * Number(currItem1.dlp) - Number(discount2);
                                currItem1.grossamount = (Number(totaAmount)).toFixed(2);
                                let schemediscount = ((Number(totaAmount) * Number(currItem1.schemeDiscount)) / 100);
                                let NetAmount = (Number(totaAmount) - Number(schemediscount));
                                currItem1.netAmount = (Number(NetAmount)).toFixed(2);
                                currItem1.gstvalue = ((Number(currItem1.netAmount) / Number(100)) * (Number(currItem1.igst))).toFixed(2);
                                currItem1.total = (Number(currItem1.gstvalue) + Number(currItem1.netAmount)).toFixed(2);
                                data[itemIndex1] = currItem1
                            } else {
                                if (action.data === "") {
                                    currItem1.srquantity = "";
                                    
                                } else{
                                    currItem1.srquantity = Number(action.data)
                                 
                                }
                                currItem1.tradeDiscount = Number(0);
                                currItem1.grossamount = (Number(currItem1.srquantitykgs) * Number(currItem1.dlp)).toFixed(2);
                                let NetAmount = (Number(currItem1.srquantitykgs) * Number(currItem1.dlp));
                                currItem1.netAmount = (Number(NetAmount)).toFixed(2);
                                currItem1.gstvalue = ((Number(currItem1.netAmount) / Number(100)) * (Number(currItem1.igst))).toFixed(2)
                                currItem1.total = (Number(currItem1.gstvalue) + Number(currItem1.netAmount)).toFixed(2);
                                data[itemIndex1] = currItem1
                            }
                        }
                    }



                    if(currItem1.unitofmeasurement == "kg"){
                        if (currItem1.tradeDiscount > 0 || currItem1.schemeDiscount > 0) {
                            if(action.data === ""){
                                currItem1.srquantity = ""
                            } else{
                                currItem1.srquantity = Number(action.data);
                                
                            }
                            let discount2 = Number(currItem1.srquantitykgs) * Number(currItem1.dlp) * (Number(currItem1.tradeDiscount) / 100);
                            let totaAmount = Number(currItem1.srquantitykgs) * Number(currItem1.dlp) - Number(discount2);
                            currItem1.grossamount = (Number(totaAmount)).toFixed(2);
                            let schemediscount = ((Number(totaAmount) * Number(currItem1.schemeDiscount)) / 100);
                            let NetAmount = (Number(totaAmount) - Number(schemediscount));
                            currItem1.netAmount = (Number(NetAmount)).toFixed(2);
                            currItem1.gstvalue = ((Number(currItem1.netAmount) / Number(100)) * (Number(currItem1.igst))).toFixed(2);
                            currItem1.total = (Number(currItem1.gstvalue) + Number(currItem1.netAmount)).toFixed(2);
                            data[itemIndex1] = currItem1
                        } else {
                            if (action.data === "") {
                                currItem1.srquantity = ""
                            } else{
                                currItem1.srquantity = Number(action.data)
                                
                            }
                            currItem1.tradeDiscount = Number(0);
                            currItem1.grossamount = (Number(currItem1.srquantitykgs) * Number(currItem1.dlp)).toFixed(2);
                            let NetAmount = (Number(currItem1.srquantitykgs) * Number(currItem1.dlp));
                            currItem1.netAmount = (Number(NetAmount)).toFixed(2);
                            currItem1.gstvalue = ((Number(currItem1.netAmount) / Number(100)) * (Number(currItem1.igst))).toFixed(2)
                            currItem1.total = (Number(currItem1.gstvalue) + Number(currItem1.netAmount)).toFixed(2);
                            data[itemIndex1] = currItem1
                        }

                    }
                    return [...data];

                    case SALES_RETURN_QTY:
                        let newCart4 = [...data]
                        let itemIndex4 = newCart4.findIndex(obj => obj.product.id === action.item.product.id)
                        let currItem4 = data[itemIndex4]

                        if (currItem4.unitofmeasurement === "kg") {
                            if (action.data === "") {
                                currItem4.srquantity = "";
                                currItem4.srquantitykgs = "";
                            } else {
                                currItem4.srquantitykgs = Number(action.data)
                                let aa = Number(currItem4.srquantitykgs)
                                let ab = (Number(aa) / Number(currItem4.uomSecondary))
                                currItem4.srquantity = Math.round(ab);
                            }
                        }

                        if (currItem4.unitofmeasurement === "pcs") {
                            if (action.data === "") {
                                currItem4.srquantitykgs = "";
                            } else {
                                currItem4.srquantitykgs = Number(action.data)
                            }
                        }

                        if (currItem4.calunitofmeasurement === "kg") {

                            if (currItem4.unitofmeasurement === "kg") {
                                if (currItem4.tradeDiscount > 0 || currItem4.schemeDiscount > 0) {
                                    if(action.data === ""){
                                        currItem4.srquantitykgs = '';
                                    } else{
                                        currItem4.srquantitykgs = Number(action.data);
                                        let aa = Number(currItem4.srquantitykgs)
                                        let ab = (Number(aa) / Number(currItem4.uomSecondary))
                                        currItem4.srquantity = Math.round(ab);
                                    }
                                    
                                    let discount2 = Number(currItem4.srquantitykgs) * Number(currItem4.dlp) * (Number(currItem4.tradeDiscount) / 100);
                                    let totaAmount = Number(currItem4.srquantitykgs) * Number(currItem4.dlp) - Number(discount2);
                                    currItem4.grossamount = (Number(totaAmount)).toFixed(2);
                                    let schemediscount = ((Number(totaAmount) * Number(currItem4.schemeDiscount)) / 100);
                                    let NetAmount = (Number(totaAmount) - Number(schemediscount));
                                    currItem4.netAmount = (Number(NetAmount)).toFixed(2);
                                    currItem4.gstvalue = ((Number(currItem4.netAmount) / Number(100)) * (Number(currItem4.igst))).toFixed(2);
                                    currItem4.total = (Number(currItem4.gstvalue) + Number(currItem4.netAmount)).toFixed(2);
                                    data[itemIndex4] = currItem4
                                } else {
                                    if (action.data === "") {
                                        currItem4.srquantitykgs = '';
                                    } else{
                                        currItem4.srquantitykgs = Number(action.data);
                                        let aa = Number(currItem4.srquantitykgs)
                                        let ab = (Number(aa) / Number(currItem4.uomSecondary))
                                        currItem4.srquantity = Math.round(ab);
                                    }
                                    
                                    currItem4.tradeDiscount = Number(0);
                                    currItem4.grossamount = (Number(currItem4.srquantitykgs) * Number(currItem4.dlp)).toFixed(2);
                                    let NetAmount = (Number(currItem4.srquantitykgs) * Number(currItem4.dlp));
                                    currItem4.netAmount = (Number(NetAmount)).toFixed(2);
                                    currItem4.gstvalue = ((Number(currItem4.netAmount) / Number(100)) * (Number(currItem4.igst))).toFixed(2)
                                    currItem4.total = (Number(currItem4.gstvalue) + Number(currItem4.netAmount)).toFixed(2);
                                    data[itemIndex4] = currItem4
                                }
                            } else if(currItem4.unitofmeasurement === 'pcs'){
                                if (currItem4.tradeDiscount > 0 || currItem4.schemeDiscount > 0) {
                                    if(action.data === ""){
                                        currItem4.srquantitykgs = '';
                                    } else{
                                        currItem4.srquantitykgs = Number(action.data);
                                    }
                                    
                                    let discount2 = Number(currItem4.srquantitykgs) * Number(currItem4.dlp) * (Number(currItem4.tradeDiscount) / 100);
                                    let totaAmount = Number(currItem4.srquantitykgs) * Number(currItem4.dlp) - Number(discount2);
                                    currItem4.grossamount = (Number(totaAmount)).toFixed(2);
                                    let schemediscount = ((Number(totaAmount) * Number(currItem4.schemeDiscount)) / 100);
                                    let NetAmount = (Number(totaAmount) - Number(schemediscount));
                                    currItem4.netAmount = (Number(NetAmount)).toFixed(2);
                                    currItem4.gstvalue = ((Number(currItem4.netAmount) / Number(100)) * (Number(currItem4.igst))).toFixed(2);
                                    currItem4.total = (Number(currItem4.gstvalue) + Number(currItem4.netAmount)).toFixed(2);
                                    data[itemIndex4] = currItem4
                                } else {
                                    if (action.data === "") {
                                        currItem4.srquantitykgs = '';
                                    } else{
                                        currItem4.srquantitykgs = Number(action.data);
                                    }
                                    
                                    currItem4.tradeDiscount = Number(0);
                                    currItem4.grossamount = (Number(currItem4.srquantitykgs) * Number(currItem4.dlp)).toFixed(2);
                                    let NetAmount = (Number(currItem4.srquantitykgs) * Number(currItem4.dlp));
                                    currItem4.netAmount = (Number(NetAmount)).toFixed(2);
                                    currItem4.gstvalue = ((Number(currItem4.netAmount) / Number(100)) * (Number(currItem4.igst))).toFixed(2)
                                    currItem4.total = (Number(currItem4.gstvalue) + Number(currItem4.netAmount)).toFixed(2);
                                    data[itemIndex4] = currItem4
                                }
                            }

                        }
                        if(currItem4.calunitofmeasurement === "pcs"){

                            if (currItem4.unitofmeasurement === 'kg') {

                                if (currItem4.tradeDiscount > 0 || currItem4.schemeDiscount > 0) {
                                    if(action.data === ""){
                                        currItem4.srquantitykgs = '';
                                    } else{
                                        currItem4.srquantitykgs = Number(action.data);
                                        let aa = Number(currItem4.srquantitykgs)
                                        let ab = (Number(aa) / Number(currItem4.uomSecondary))
                                        currItem4.srquantity = Math.round(ab);
                                    }
                                    
                                    let discount2 = Number(currItem4.srquantity) * Number(currItem4.dlp) * (Number(currItem4.tradeDiscount) / 100);
                                    let totaAmount = Number(currItem4.srquantity) * Number(currItem4.dlp) - Number(discount2);
                                    currItem4.grossamount = (Number(totaAmount)).toFixed(2);
                                    let schemediscount = ((Number(totaAmount) * Number(currItem4.schemeDiscount)) / 100);
                                    let NetAmount = (Number(totaAmount) - Number(schemediscount));
                                    currItem4.netAmount = (Number(NetAmount)).toFixed(2);
                                    currItem4.gstvalue = ((Number(currItem4.netAmount) / Number(100)) * (Number(currItem4.igst))).toFixed(2);
                                    currItem4.total = (Number(currItem4.gstvalue) + Number(currItem4.netAmount)).toFixed(2);
                                    data[itemIndex4] = currItem4
                                } else {
                                    if (action.data === "") {
                                        currItem4.srquantitykgs = '';
                                    } else{
                                        currItem4.srquantitykgs = Number(action.data);
                                        let aa = Number(currItem4.srquantitykgs)
                                        let ab = (Number(aa) / Number(currItem4.uomSecondary))
                                        currItem4.srquantity = Math.round(ab);
                                    }
                                    
                                    currItem4.tradeDiscount = Number(0);
                                    currItem4.grossamount = (Number(currItem4.srquantity) * Number(currItem4.dlp)).toFixed(2);
                                    let NetAmount = (Number(currItem4.srquantity) * Number(currItem4.dlp));
                                    currItem4.netAmount = (Number(NetAmount)).toFixed(2);
                                    currItem4.gstvalue = ((Number(currItem4.netAmount) / Number(100)) * (Number(currItem4.igst))).toFixed(2)
                                    currItem4.total = (Number(currItem4.gstvalue) + Number(currItem4.netAmount)).toFixed(2);
                                    data[itemIndex4] = currItem4
                                }

                            } else if(currItem4.unitofmeasurement === 'pcs'){
                                
                                    if (currItem4.tradeDiscount > 0 || currItem4.schemeDiscount > 0) {
                                        if(action.data === ""){
                                            currItem4.srquantitykgs = ""
                                        } else{
                                            currItem4.srquantitykgs = Number(action.data);
        
                                        }
                                        let discount2 = Number(currItem4.srquantity) * Number(currItem4.dlp) * (Number(currItem4.tradeDiscount) / 400);
                                        let totaAmount = Number(currItem4.srquantity) * Number(currItem4.dlp) - Number(discount2);
                                        currItem4.grossamount = (Number(totaAmount)).toFixed(2);
                                        let schemediscount = ((Number(totaAmount) * Number(currItem4.schemeDiscount)) / 400);
                                        let NetAmount = (Number(totaAmount) - Number(schemediscount));
                                        currItem4.netAmount = (Number(NetAmount)).toFixed(2);
                                        currItem4.gstvalue = ((Number(currItem4.netAmount) / Number(400)) * (Number(currItem4.igst))).toFixed(2);
                                        currItem4.total = (Number(currItem4.gstvalue) + Number(currItem4.netAmount)).toFixed(2);
                                        data[itemIndex4] = currItem4
                                    } else {
                                        if (action.data === "") {
                                            currItem4.srquantitykgs = ""
                                        } else{
                                            currItem4.srquantitykgs = Number(action.data)
                                        }
                                        currItem4.tradeDiscount = Number(0);
                                        currItem4.grossamount = (Number(currItem4.srquantity) * Number(currItem4.dlp)).toFixed(2);
                                        let NetAmount = (Number(currItem4.srquantity) * Number(currItem4.dlp));
                                        currItem4.netAmount = (Number(NetAmount)).toFixed(2);
                                        currItem4.gstvalue = ((Number(currItem4.netAmount) / Number(400)) * (Number(currItem4.igst))).toFixed(2)
                                        currItem4.total = (Number(currItem4.gstvalue) + Number(currItem4.netAmount)).toFixed(2);
                                        data[itemIndex4] = currItem4
                                    }
            
                                
                            }
                        }




                    return[...data];


                    case SALES_RETURN_REMOVE_FROM_CART:

                    const remainingitem = data.filter((item) => item.product.id !== action.data);
                    return [...remainingitem];

                    case SALES_RETURN_COST:

                    let newCart2 = [...data]
                    let itemIndex2 = newCart2.findIndex(obj => obj.product.id === action.item.product.id)
                    let currItem2 = data[itemIndex2]
        
                    currItem2.dlp = action.data

                    if (currItem2.calunitofmeasurement === "box") {
                        if (currItem2.tradeDiscount > 0 || currItem2.schemeDiscount > 0) {
                            if(action.data === ""){
                                currItem2.dlp = ""
                            } else{
                                currItem2.dlp = Number(action.data)
                            }
                            currItem2.srquantitykgs = 0;
                      let discount2 = Number(currItem2.srquantity) * Number(currItem2.dlp) * (Number(currItem2.tradeDiscount) / 100);
                      let totaAmount = Number(currItem2.srquantity) * Number(currItem2.dlp) - Number(discount2); 
                      currItem2.grossamount = (Number(totaAmount)).toFixed(2);
                      let schemediscount = ((Number(totaAmount) * Number(currItem2.schemeDiscount)) / 100);
                      let NetAmount = (Number(totaAmount) - Number(schemediscount));
                      currItem2.netAmount = (Number(NetAmount)).toFixed(2);
                      currItem2.gstvalue = ((Number(currItem2.netAmount) / Number(100)) * (Number(currItem2.igst))).toFixed(2);
                      currItem2.total = (Number(currItem2.gstvalue) + Number(currItem2.netAmount)).toFixed(2);
                      data[itemIndex2] = currItem2
                        } else {
                            if (action.data === "") {
                                currItem2.dlp = ""
                            } else{
                                currItem2.dlp = Number(action.data)
                            }
                            currItem2.srquantitykgs = 0;
                            currItem2.tradeDiscount = Number(0);
                            currItem2.grossamount = (Number(currItem2.srquantity) * Number(currItem2.dlp)).toFixed(2);
                            let NetAmount = (Number(currItem2.srquantity) * Number(currItem2.dlp));
                            currItem2.netAmount = (Number(NetAmount)).toFixed(2);
                            currItem2.gstvalue = ((Number(currItem2.netAmount) / Number(100)) * (Number(currItem2.igst))).toFixed(2);
                            currItem2.total = (Number(currItem2.gstvalue) + Number(currItem2.netAmount)).toFixed(2);
                            data[itemIndex2] = currItem2
                        }
                    }
                    if(currItem2.calunitofmeasurement == "pcs"){
                        if (currItem2.tradeDiscount > 0 || currItem2.schemeDiscount > 0) {
                            if(action.data === ""){
                                currItem2.dlp = ""
                            } else{
                                currItem2.dlp = Number(action.data);

                            }
                            let discount2 = Number(currItem2.srquantity) * Number(currItem2.dlp) * (Number(currItem2.tradeDiscount) / 100);
                            let totaAmount = Number(currItem2.srquantity) * Number(currItem2.dlp) - Number(discount2);
                            currItem2.grossamount = (Number(totaAmount)).toFixed(2);
                            let schemediscount = ((Number(totaAmount) * Number(currItem2.schemeDiscount)) / 100);
                            let NetAmount = (Number(totaAmount) - Number(schemediscount));
                            currItem2.netAmount = (Number(NetAmount)).toFixed(2);
                            currItem2.gstvalue = ((Number(currItem2.netAmount) / Number(100)) * (Number(currItem2.igst))).toFixed(2);
                            currItem2.total = (Number(currItem2.gstvalue) + Number(currItem2.netAmount)).toFixed(2);
                            data[itemIndex2] = currItem2
                        } else {
                            if (action.data === "") {
                                currItem2.dlp = "";
                            } else{
                                currItem2.dlp = Number(action.data)
                            }
                            currItem2.tradeDiscount = Number(0);
                            currItem2.grossamount = (Number(currItem2.srquantity) * Number(currItem2.dlp)).toFixed(2);
                            let NetAmount = (Number(currItem2.srquantity) * Number(currItem2.dlp));
                            currItem2.netAmount = (Number(NetAmount)).toFixed(2);
                            currItem2.gstvalue = ((Number(currItem2.netAmount) / Number(100)) * (Number(currItem2.igst))).toFixed(2)
                            currItem2.total = (Number(currItem2.gstvalue) + Number(currItem2.netAmount)).toFixed(2);
                            data[itemIndex2] = currItem2
                        }

                    }

                    if (currItem2.calunitofmeasurement === "kg") {
                        if (currItem2.tradeDiscount > 0 || currItem2.schemeDiscount > 0) {
                            if(action.data === ""){
                                currItem2.dlp = "";
                                
                            } else{
                                currItem2.dlp = Number(action.data);

                            }
                            let discount2 = Number(currItem2.srquantitykgs) * Number(currItem2.dlp) * (Number(currItem2.tradeDiscount) / 100);
                            let totaAmount = Number(currItem2.srquantitykgs) * Number(currItem2.dlp) - Number(discount2);
                            currItem2.grossamount = (Number(totaAmount)).toFixed(2);
                            let schemediscount = ((Number(totaAmount) * Number(currItem2.schemeDiscount)) / 100);
                            let NetAmount = (Number(totaAmount) - Number(schemediscount));
                            currItem2.netAmount = (Number(NetAmount)).toFixed(2);
                            currItem2.gstvalue = ((Number(currItem2.netAmount) / Number(100)) * (Number(currItem2.igst))).toFixed(2);
                            currItem2.total = (Number(currItem2.gstvalue) + Number(currItem2.netAmount)).toFixed(2);
                            data[itemIndex2] = currItem2
                        } else {
                            if (action.data === "") {
                                currItem2.dlp = "";
                                
                            } else{
                                currItem2.dlp = Number(action.data);

                            }
                            currItem2.tradeDiscount = Number(0);
                            currItem2.grossamount = (Number(currItem2.srquantitykgs) * Number(currItem2.dlp)).toFixed(2);
                            let NetAmount = (Number(currItem2.srquantitykgs) * Number(currItem2.dlp));
                            currItem2.netAmount = (Number(NetAmount)).toFixed(2);
                            currItem2.gstvalue = ((Number(currItem2.netAmount) / Number(100)) * (Number(currItem2.igst))).toFixed(2)
                            currItem2.total = (Number(currItem2.gstvalue) + Number(currItem2.netAmount)).toFixed(2);
                            data[itemIndex2] = currItem2
                        }
                    }

                    return [...data];

                    case SALES_RETURN_DISCOUNT:

                    let newCart3 = [...data]
                    let itemIndex3 = newCart3.findIndex(obj => obj.product.id === action.item.product.id)
                    let currItem3 = data[itemIndex3]
        
        
                    if (currItem3.calunitofmeasurement == "box") {
                        
                            if(action.data === ""){
                                currItem3.schemeDiscount = ""
                            } else{
                                currItem3.schemeDiscount = Number(action.data)
                            }
                            currItem3.srquantitykgs = 0;
                      let discount2 = Number(currItem3.srquantity) * Number(currItem3.dlp) * (Number(currItem3.tradeDiscount) / 100);
                      let totaAmount = Number(currItem3.srquantity) * Number(currItem3.dlp) - Number(discount2); 
                      currItem3.grossamount = (Number(totaAmount)).toFixed(2);
                      let schemediscount = ((Number(totaAmount) * Number(currItem3.schemeDiscount)) / 100);
                      let NetAmount = (Number(totaAmount) - Number(schemediscount));
                      currItem3.netAmount = (Number(NetAmount)).toFixed(2);
                      currItem3.gstvalue = ((Number(currItem3.netAmount) / Number(100)) * (Number(currItem3.igst))).toFixed(2);
                      currItem3.total = (Number(currItem3.gstvalue) + Number(currItem3.netAmount)).toFixed(2);
                      data[itemIndex3] = currItem3
                        
                    } 
                    
                    if(currItem3.calunitofmeasurement == "pcs"){
                       
                            if(action.data === ""){
                                currItem3.schemeDiscount = ""
                            } else{
                                currItem3.schemeDiscount = Number(action.data);

                            }
                            let discount2 = Number(currItem3.srquantity) * Number(currItem3.dlp) * (Number(currItem3.tradeDiscount) / 100);
                            let totaAmount = Number(currItem3.srquantity) * Number(currItem3.dlp) - Number(discount2);
                            currItem3.grossamount = (Number(totaAmount)).toFixed(2);
                            let schemediscount = ((Number(totaAmount) * Number(currItem3.schemeDiscount)) / 100);
                            let NetAmount = (Number(totaAmount) - Number(schemediscount));
                            currItem3.netAmount = (Number(NetAmount)).toFixed(2);
                            currItem3.gstvalue = ((Number(currItem3.netAmount) / Number(100)) * (Number(currItem3.igst))).toFixed(2);
                            currItem3.total = (Number(currItem3.gstvalue) + Number(currItem3.netAmount)).toFixed(2);
                            data[itemIndex3] = currItem3
                        } 

                    

                    if (currItem3.calunitofmeasurement === "kg") {
                            if(action.data === ""){
                                currItem3.schemeDiscount = "";
                                
                            } else{
                                currItem3.schemeDiscount = Number(action.data);

                            }
                            let discount2 = Number(currItem3.srquantitykgs) * Number(currItem3.dlp) * (Number(currItem3.tradeDiscount) / 100);
                            let totaAmount = Number(currItem3.srquantitykgs) * Number(currItem3.dlp) - Number(discount2);
                            currItem3.grossamount = (Number(totaAmount)).toFixed(2);
                            let schemediscount = ((Number(totaAmount) * Number(currItem3.schemeDiscount)) / 100);
                            let NetAmount = (Number(totaAmount) - Number(schemediscount));
                            currItem3.netAmount = (Number(NetAmount)).toFixed(2);
                            currItem3.gstvalue = ((Number(currItem3.netAmount) / Number(100)) * (Number(currItem3.igst))).toFixed(2);
                            currItem3.total = (Number(currItem3.gstvalue) + Number(currItem3.netAmount)).toFixed(2);
                            data[itemIndex3] = currItem3
                        } 
                    
        
                    return [...data];

                    case SALES_RETURN_TEXT:

                    let newCart5 = [...data]
                    let itemIndex5 = newCart5.findIndex(obj => obj.product.id === action.item.product.id)
                    let currItem5 = data[itemIndex5]
        
                    currItem5.text = action.data
                    data[itemIndex5] = currItem5
                    return [...data];

                    case SALES_RETURN_EMPTY_DATA:
                        // console.warn("reducer called",action)
                        return [];

        default:
            return data;
    }
}