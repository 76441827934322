import axios from "axios";
import { MDBCard } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Accordion, Button, Col, Form, Row, Table } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { BASE_URL } from "../services/Api";
import authHeader from "../services/auth-header";
import ProductService from "../services/ProductService";
import { useDispatch, useSelector } from "react-redux";
import { changecalunitofMeasurementsalesedit, changecostsalesedit, changesaleseditdiscount, changesaleseditsqpbkgmeasurement, changesaleseditsqpbmeasurement, changesalesedittext, changeunitofMeasurementsalesedit, getsaleseditdatatoload, removefromcartfromsalesedit, saleseditaddtocart } from "../../redux/Action/saleupdate";
import Swal from "sweetalert2";

export default function Edit() {
    const [isLoading, setIsLoading] = useState(false);
    const [img2, setimg2] = useState("")
    const [pdf2, setpdf2] = useState("")
    const applyFontSizeTransition = (elementId) => {
        const element = document.getElementById(elementId);
        element.style.transition = 'font-size 2s';
        element.style.fontSize = '30px';
        element.style.fontWeight = '600';
        // font-weight: 600;
        setTimeout(() => {

            document.getElementById("typer1").classList.add('promote');
            element.style.fontSize = '24px';
        }, 1000);
    };
    const { id } = useParams();
    const { pointindex } = useParams();
    const [branchdata, setbranchdata] = useState('');
    const [branch, setbranch] = useState('');
    const [warehousehdata, setwarehousehdata] = useState('');
    const [warehouse, setwarehouse] = useState('');
    const [customerdata, setcustomerdata] = useState('');
    const [distributor, setdistributor] = useState('');
    const [buyerorderno, setbuyerorderno] = useState('');
    const [invoiceno, setinvoiceno] = useState('');
    const [invoicedate, setinvoicedate] = useState('');
    const [type, settype] = useState('');
    const [status, setstatus] = useState('');
    const [status1, setstatus1] = useState(false);
    const [status2, setstatus2] = useState(false);
    const [taxtype, settaxtype] = useState('');
    const [termsofdelivery, settermsofdelivery] = useState('');
    const [deliverynoteno, setdeliverynoteno] = useState('');
    const [destination, setdestination] = useState('');
    const [packaginglistno, setpackaginglistno] = useState('');
    const [gstno, setgstno] = useState('');
    const [panno, setpanno] = useState('');
    const [ewaybillno, setewaybillno] = useState('');
    const [grnno, setgrnno] = useState('');
    const [lrno, setlrno] = useState('');
    const [udyamno, setudyamno] = useState('');
    const [msmeno, setmsmeno] = useState('');
    const [productdata, setproductdata] = useState([]);
    const [boxProductDiscount, setboxProductDiscount] = useState('');
    const [schemeboxProductDiscount, setschemeboxProductDiscount] = useState('');
    const [kgProductDiscount, setkgProductDiscount] = useState('');
    const [schemekgProductDiscount, setschemekgProductDiscount] = useState('');
    const [corporaetProductDiscount, setcorporaetProductDiscount] = useState('');
    const [schemecorporateProductDiscount, setschemecorporateProductDiscount] = useState('');
    const [cookerProductDiscount, setcookerProductDiscount] = useState('');
    const [schemecookerProductDiscount, setschemecookerProductDiscount] = useState('');
    const [noshProductDiscount, setnoshProductDiscount] = useState('');
    const [schemenoshProductDisocunt, setschemenoshProductDisocunt] = useState('');
    const [shippingcharge, setShipping] = useState(0);
    const [round, setRound] = useState(0);
    const [bstateid, setbstateid] = useState('');
    const [dstateid, setdstateid] = useState('');
    const [paymentTerms, setpaymentTerms] = useState('');
    const [getaddresss, setaddress] = useState([])
    const [deliveryAddress, setdelvieryaddress] = useState([]);
    const [lrndate, setlrndate] = useState('');
    const [edd, setedd] = useState('');
    const [grndate, setgrndate] = useState('');
    const [dispatchedthrough, setdispatchedthrough] = useState('');
    const [totalnopkg, settotalnopkg] = useState('');
    const [retailerstatus, setretailerstatus] = useState('');
    const [retailer, setretailer] = useState('');
    const [img, setimg] = useState();
    const [pdf, setpdf] = useState();
    const [taxSch, settaxSch] = useState('');
    const [supTyp, setsupTyp] = useState('');
    const [no, setno] = useState('');
    const [dt1, setdt1] = useState('');
    const [seller, setseller] = useState({});
    const [buyer, setbuyer] = useState({});
    const [slNo, setslNo] = useState('');
    const [prdDesc, setprdDesc] = useState('');
    const [isServc, setisServc] = useState('');
    const [hsnCd, sethsnCd] = useState('');
    const [barcde, setbarcde] = useState('');
    const [qty, setqty] = useState('');
    const [freeQty, setfreeQty] = useState('');
    const [unit, setunit] = useState('');
    const [unitPrice, setunitPrice] = useState('');
    const [totAmt, settotAmt] = useState('');
    const [discount, setdiscount] = useState('');
    const [preTaxVal, setpreTaxVal] = useState('');
    const [assAmt, setassAmt] = useState('');
    const [gstRt, setgstRt] = useState('');
    const [igstAmt, setigstAmt] = useState('');
    const [cgstAmt, setcgstAmt] = useState('');
    const [sgstAmt, setsgstAmt] = useState('');
    const [cesRt, setcesRt] = useState('');
    const [cesAmt, setcesAmt] = useState('');
    const [cesNonAdvlAmt, setcesNonAdvlAmt] = useState('');
    const [stateCesRt, setstateCesRt] = useState('');
    const [stateCesAmt, setstateCesAmt] = useState('');
    const [stateCesNonAdvlAmt, setstateCesNonAdvlAmt] = useState('');
    const [othChrg, setothChrg] = useState('');
    const [totItemVal, settotItemVal] = useState('');
    const [ordLineRef, setordLineRef] = useState('');
    const [orgCntry, setorgCntry] = useState('');
    const [prdSlNo, setprdSlNo] = useState('');
    const [nm, setnm] = useState('');
    const [expDt1, setexpDt1] = useState('');
    const [wrDt1, setwrDt1] = useState('');
    const [anm, setanm] = useState({});
    const [valdtls, setvaldtls] = useState({});
    const [distance, setdistance] = useState(null);
    const [transMode, settransMode] = useState('');
    const [transId, settransId] = useState('');
    const [transName, settransName] = useState('');
    const [transDocDt1, settransDocDt1] = useState('');
    const [transDocNo, settransDocNo] = useState('');
    const [vehNo, setvehNo] = useState('');
    const [vehType, setvehType] = useState('');
    const [voucherid, setvoucherid] = useState('');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const salesItems1 = useSelector((state) => state.saleupdatedata);
    let da_ta = salesItems1.length && salesItems1.map((user) => ({
        "brand": user.brand,
        "calunitofmeasurement": user.calunitofmeasurement,
        "capacity": user.capacity,
        "category": user.category,
        "cgst": user.cgst,
        "cgstLedger": user.cgstLedger,
        "diameter": user.diameter,
        "dlp": user.dlp,
        "eanCode": user.eanCode,
        "grossamount": user.grossamount,
        "gstvalue": user.gstvalue,
        "hsnCode": user.hsnCode,
        "igst": user.igst,
        "igstLedger": user.igstLedger,
        "netAmount": user.netAmount,
        "product": { "id": user.product.id },
        "productName": user.productName,
        "productType": user.productType,
        "salesquantity": user.salesquantity,
        "salesquantitykgs": user.salesquantitykgs,
        "schemeDiscount": user.schemeDiscount,
        "sgst": user.sgst,
        "sgstLedger": user.sgstLedger,
        "shortName": user.shortName,
        "soid": user.soid,
        "standardQtyPerBox": user.standardQtyPerBox,
        "total": user.total,
        "tradeDiscount": user.tradeDiscount,
        "unitofmeasurement": user.unitofmeasurement,
        "uomPrimary": user.uomPrimary,
        "uomSecondary": user.uomSecondary,
        // "SlNo":user.soid,
        "prdDesc": user.PrdDesc,
        "isServc": "N",
        "qty": user.salesquantitykgs,
        "freeQty": user.salesquantity,
        "unit": user.unitofmeasurement,
        "totAmt": user.grossamount,
        "discount": ((Number(user.grossamount) / 100) * Number(user.schemeDiscount)).toFixed(2),
        "assAmt": user.netAmount,
        "gstRt": user.igst,
        "igstAmt": seller.stcd === buyer.stcd ? 0 : Number(user.gstvalue),
        "cgstAmt": seller.stcd === buyer.stcd ? ((user.gstvalue) / 2) : 0,
        "sgstAmt": seller.stcd === buyer.stcd ? ((user.gstvalue) / 2) : 0,
        "totItemVal": user.total


    }));

    let gross_Amount = salesItems1.length && salesItems1.map(item => Math.round(item.total)).reduce((prev, next) => Math.round(prev - (-next)));
    let gross_AmountassVal = salesItems1.length && salesItems1.map(item => (item.netAmount)).reduce((prev, next) => (prev - (-next)));

    let gst = salesItems1.length && salesItems1.map(item => (item.gstvalue)).reduce((prev, next) => (prev - (-next)));
    let cgstVal = salesItems1.length && salesItems1.map(item => (item.gstvalue)).reduce((prev, next) => (prev - (-next)));
    // console.log("mello",salesItems1);

    let aa = [];
    salesItems1.map(user => {
        let igst_value = user.igst;
        let total_value = user.total;
        let total_amou = ((Number(shippingcharge) / Number(gross_Amount)) * Number(total_value));
        let add_amou = Number(total_amou) - (-Number(total_value));
        let final_amou = ((Number(add_amou) / 100) * Number(igst_value)).toFixed(2);
        aa.push(final_amou)

        // console.log("mello",((Number(add_amou)/100)*Number(igst_value)).reduce((prev, next) => Math.round(prev - (-next))) );
    })
    let total_per = aa.length && aa.map(user => user).reduce((prev, next) => (prev - (-next)));

    let grossamount = Number(gross_Amount) + Number(shippingcharge);
    let grandTotal = Number(gross_Amount) + Number(shippingcharge) + Number(round) + Number(total_per);

    let igstTax = Number(total_per);
    let cgstTax = (Number(total_per) / 2);
    let sgstTax = (Number(total_per) / 2);

    function roundData(e) {
        if (e) {
            let gross_Amount = salesItems1.length && salesItems1.map(item => Math.round(item.total)).reduce((prev, next) => Math.round(prev - (-next)));
            let gst = salesItems1.length && salesItems1.map(item => (item.gstvalue)).reduce((prev, next) => (prev - (-next)));

            let aa = [];
            salesItems1.map(user => {
                let igst_value = user.igst;
                let total_value = user.total;
                let total_amou = ((Number(shippingcharge) / Number(gross_Amount)) * Number(total_value));
                let add_amou = Number(total_amou) - (-Number(total_value));
                let final_amou = ((Number(add_amou) / 100) * Number(igst_value)).toFixed(2);
                aa.push(final_amou)

                // console.log("mello",((Number(add_amou)/100)*Number(igst_value)).reduce((prev, next) => Math.round(prev - (-next))) );
            })
            let total_per = aa.length && aa.map(user => user).reduce((prev, next) => (prev - (-next)));


            let grossamount = Number(gross_Amount) + Number(shippingcharge);
            let grandTotal = Number(gross_Amount) + Number(shippingcharge) + Number(total_per);
            let grand_Total = Math.ceil(grandTotal);

            if (grand_Total > grandTotal) {
                let rou_nd = (Number(grand_Total) - Number(grandTotal)).toFixed(2);
                setRound(rou_nd);
            } else {
                setRound(0)
            }


        } else if (e == '') {
            setRound(0)
        }
    }

    const [datenow, setDatenow] = useState([])
    const [transportdate, settransDocDt11] = useState([])

    function formatDate(dateString) {
        const parts = dateString.split('/');
        const day = parts[0];
        const month = parts[1];
        const year = parts[2];

        const dtObj = new Date(year, month - 1, day);

        const tzOffset = dtObj.getTimezoneOffset() * 60000;

        const localDtObj = new Date(dtObj.getTime() - tzOffset);

        const formattedDate = localDtObj.toISOString().split('T')[0];

        return formattedDate;
    }



    const getSalesInvoiceData = () => {

        axios.get(BASE_URL + 'sales/' + id, {
            headers: authHeader()
        }).then(res => {

            // console.log("mello",res.data);
            (res.data.retailerstatus === 'customer' ? setaddress(res.data.retailer.deliveryAddress) : setaddress(res.data.distributor.deliveryAddress))
            setdelvieryaddress(Number(res.data.deliveryAddress))
            // console.log("mello",res.data);
            setbranchdata(res.data.branch);
            setbranch(res.data.branch.id);
            setwarehousehdata(res.data.warehouse);
            setwarehouse(res.data.warehouse.id);
            (res.data.retailerstatus === 'customer' ? setcustomerdata(res.data.retailer) : setcustomerdata(res.data.distributor));
            (res.data.retailerstatus === 'customer' ? setretailer(res.data.retailer.id) : setdistributor(res.data.distributor.id));
            setimg(res.data.imglocation);
            setpdf(res.data.pdflocation);
            setvoucherid(res.data.voucherseries);

            setpaymentTerms(res.data.paymentTerms);
            setdispatchedthrough(res.data.dispatchedthrough);
            settotalnopkg(res.data.totalnopkg);
            setbuyerorderno(res.data.buyerorderno);
            setinvoiceno(res.data.invoiceno);
            setinvoicedate(res.data.invoicedate);
            setlrndate(res.data.lrndate);
            setedd(res.data.edd);
            setgrndate(res.data.grndate);
            settype(res.data.type);
            setstatus(res.data.status);
            settaxtype(res.data.taxtype);
            settermsofdelivery(res.data.termsofdelivery);
            setdeliverynoteno(res.data.deliverynoteno);
            setdestination(res.data.destination);
            setpackaginglistno(res.data.packaginglistno);
            setgstno(res.data.gstno);
            setpanno(res.data.panno);
            setewaybillno(res.data.ewaybillno);
            setgrnno(res.data.grnno);
            setlrno(res.data.lrno);
            setudyamno(res.data.udyamno);
            setmsmeno(res.data.msmeno);
            setShipping(res.data.shippingcharge);
            setRound(res.data.roundingofvalue);
            setbstateid(res.data.branch.states.id);
            setretailerstatus(res.data.retailerstatus);
            settaxSch(res.data.taxSch);
            setsupTyp(res.data.supTyp);
            setno(res.data.no);
            setdt1(res.data.dt);
            setseller({ "gstin": res.data.gstin, "lglNm": res.data.lglNm, "addr1": res.data.addr1, "loc": res.data.loc, "pin": res.data.pin, "stcd": res.data.stcd, "ph": res.data.ph, "em": res.data.em })
            setbuyer({ "gstin": res.data.bgstin, "pos": res.data.pos, "lglNm": res.data.blglNm, "addr1": res.data.baddr1, "loc": res.data.bloc, "pin": res.data.bpin, "stcd": res.data.bstcd, "ph": res.data.bph, "em": res.data.bem })
            setslNo(res.data.slNo);
            setprdDesc(res.data.prdDesc);
            setisServc(res.data.isServc);
            sethsnCd(res.data.hsnCd);
            setbarcde(res.data.barcde);
            setqty(res.data.qty);
            setfreeQty(res.data.freeQty);
            setunit(res.data.unit);
            setunitPrice(res.data.unitPrice);
            settotAmt(res.data.totAmt);
            setdiscount(res.data.discount);
            setpreTaxVal(res.data.preTaxVal);
            setassAmt(res.data.assAmt);
            setgstRt(res.data.gstRt);
            setigstAmt(res.data.igstAmt);
            setcgstAmt(res.data.cgstAmt);
            setsgstAmt(res.data.sgstAmt);
            setcesRt(res.data.cesRt);
            setcesAmt(res.data.cesAmt);
            setcesNonAdvlAmt(res.data.cesNonAdvlAmt);
            setstateCesRt(res.data.stateCesRt);
            setstateCesAmt(res.data.stateCesAmt);
            setstateCesNonAdvlAmt(res.data.stateCesNonAdvlAmt);
            setothChrg(res.data.othChrg);
            settotItemVal(res.data.totItemVal);
            setordLineRef(res.data.ordLineRef);
            setorgCntry(res.data.orgCntry);
            setprdSlNo(res.data.prdSlNo);
            setnm(res.data.nm);
            setexpDt1(res.data.expDt);
            setwrDt1(res.data.wrDt);
            setanm({
                "nm": res.data.nm, "val": res.data.val, "assVal": res.data.assVal, "cgstVal": res.data.cgstVal, "sgstVal": res.data.sgstVal, 'igstVal': res.data.igstVal, "cesVal": res.data.cesVal,
                "stCesVal": res.data.stCesVal, "discount": res.data.discount, "othChrg": res.data.othChrg, "rndOffAmt": res.data.rndOffAmt, "totInvVal": res.data.totInvVal, "totInvValFc": res.data.totInvValFc
            });
            setvaldtls({
                "assVal": res.data.assVal, "cgstVal": res.data.cgstVal, "sgstVal": res.data.sgstVal, "igstVal": res.data.igstVal, "cesVal": res.data.cesVal, "stCesVal": res.data.stCesVal,
                "discount": res.data.vdiscount, "othChrg": res.data.vothChrg, "rndOffAmt": res.data.rndOffAmt, "totInvVal": res.data.totInvVal, "totInvValFc": res.data.totInvValFc
            })
            setdistance(res.data.distance);
            settransMode(res.data.transMode);
            settransId(res.data.transId);
            settransName(res.data.transName);
            settransDocDt1(res.data.transDocDt);
            settransDocNo(res.data.transDocNo);
            setvehNo(res.data.vehNo);
            setvehType(res.data.vehType);
            (res.data.retailerstatus === 'customer' ? setdstateid(res.data.retailer.stateid) : setdstateid(res.data.distributor.stateid));
            (res.data.retailerstatus === 'customer' ? setboxProductDiscount(res.data.retailer.boxProductDiscount) : setboxProductDiscount(res.data.distributor.boxProductDiscount));
            (res.data.retailerstatus === 'customer' ? setschemeboxProductDiscount(res.data.retailer.schemeboxProductDiscount) : setschemeboxProductDiscount(res.data.distributor.schemeboxProductDiscount));
            (res.data.retailerstatus === 'customer' ? setkgProductDiscount(res.data.retailer.kgProductDiscount) : setkgProductDiscount(res.data.distributor.kgProductDiscount));
            (res.data.retailerstatus === 'customer' ? setschemekgProductDiscount(res.data.retailer.schemekgProductDiscount) : setschemekgProductDiscount(res.data.distributor.schemekgProductDiscount));
            (res.data.retailerstatus === 'customer' ? setcorporaetProductDiscount(res.data.retailer.corporaetProductDiscount) : setcorporaetProductDiscount(res.data.distributor.corporaetProductDiscount));
            (res.data.retailerstatus === 'customer' ? setschemecorporateProductDiscount(res.data.retailer.schemecorporateProductDiscount) : setschemecorporateProductDiscount(res.data.distributor.schemecorporateProductDiscount));
            (res.data.retailerstatus === 'customer' ? setcookerProductDiscount(res.data.retailer.cookerProductDiscount) : setcookerProductDiscount(res.data.distributor.cookerProductDiscount));
            (res.data.retailerstatus === 'customer' ? setschemecookerProductDiscount(res.data.retailer.schemecookerProductDiscount) : setschemecookerProductDiscount(res.data.distributor.schemecookerProductDiscount));
            (res.data.retailerstatus === 'customer' ? setnoshProductDiscount(res.data.retailer.noshProductDiscount) : setnoshProductDiscount(res.data.distributor.noshProductDiscount));
            (res.data.retailerstatus === 'customer' ? setschemenoshProductDisocunt(res.data.retailer.schemenoshProductDisocunt) : setschemenoshProductDisocunt(res.data.distributor.schemenoshProductDisocunt));

            const date1 = res.data.transDocDt;
            const formattedDate1 = formatDate(date1);
            settransDocDt11(formattedDate1);

            const date2 = res.data.dt;
            const formattedDate2 = formatDate(date2);
            setDatenow(formattedDate2);
        })



    };
    const formatter = new Intl.DateTimeFormat('in-US', { day: '2-digit', month: '2-digit', year: 'numeric' });
    const datevalue1 = new Date(invoicedate);
    const defaultValue1 = datevalue1.toLocaleDateString('en-CA');
    const datevalue2 = new Date(lrndate);
    const defaultValue2 = datevalue2.toLocaleDateString('en-CA');
    const datevalue3 = new Date(edd);
    const defaultValue3 = datevalue3.toLocaleDateString('en-CA');
    const datevalue4 = new Date(grndate);
    const defaultValue4 = datevalue4.toLocaleDateString('en-CA');
    const datevalue_1 = new Date(dt1);
    let defaultValue_1 = datevalue_1.toLocaleDateString('en-CA');

    console.log(dt1)

    // let dtvalue = dt1 == "" ? '' : formatter.format(datevalue_1);


    // const defaultValue_1 = dt1 == ""?'':formatter.format(datevalue_01);
    const datevalue_2 = new Date(expDt1);
    const defaultValue_2 = datevalue_2.toLocaleDateString('en-CA');
    const datevalue_3 = new Date(wrDt1);
    const defaultValue_3 = datevalue_3.toLocaleDateString('en-CA');
    // const datevalue_4 = new Date(transDocDt1);
    // const defaultValue_4 = datevalue_4.toLocaleDateString('en-CA');
    // let transDocDt1value = transDocDt1 == "" ? '' : formatter.format(datevalue_4);
    /* get Product Data */

    let getProductData = () => {

        axios.get(BASE_URL + 'product/page/0/100/asc/id/%20', {
            headers: authHeader()
        }).then(res => {

            setproductdata((res.data.Index).map(use => ({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code + ' - ' + use.short_Name })));
        })

    };

    const customFilter = (e) => {
        let name = e;
        axios.get(BASE_URL + 'product/page/0/100/asc/id/' + name, {
            headers: authHeader()
        }).then(res => {
            setproductdata((res.data.data).map(use => ({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code + ' - ' + use.short_Name })))
        })
    }

    useEffect(() => {
        getSalesInvoiceData();
        getProductData();
        dispatch(getsaleseditdatatoload(id));
    }, []);
    const stype = [
        { "id": "B2B", "label": "Business to Business" },
        { "id": "SEZWP", "label": "SEZ with payment" },
        { "id": "SEZWOP", "label": "SEZ without payment" },
        { "id": "EXPWP", "label": "Export with Payment" },
        { "id": "EXPWOP", "label": "Export without payment" },
        { "id": "DEXP", "label": "Deemed Export" }
    ]
    const modes1 = [
        { "id": 1, "label": "Road" },
        { "id": 2, "label": "Rail" },
        { "id": 3, "label": "Air" },
        { "id": 4, "label": "Ship" },
        { "id": 5, "label": "In Transit" }
    ]
    const vechiletype = [
        { "id": "R", "label": "Regular" },
        { "id": "O", "label": "ODC" }
    ]

    let addData = (e) => {
        let name = e.value;
        let filteredItems = salesItems1.filter(item => item.product.id == name);
        if (filteredItems.length == "0") {
            dispatch(saleseditaddtocart(name, ({
                "schemeboxProductDiscount": schemeboxProductDiscount,
                "schemekgProductDiscount": schemekgProductDiscount,
                "schemecorporateProductDiscount": schemecorporateProductDiscount,
                "schemecookerProductDiscount": schemecookerProductDiscount,
                "schemenoshProductDisocunt": schemenoshProductDisocunt,
                "kgProductDiscount": kgProductDiscount,
                "boxProductDiscount": boxProductDiscount,
                "corporaetProductDiscount": corporaetProductDiscount,
                "cookerProductDiscount": cookerProductDiscount,
                "noshProductDiscount": noshProductDiscount
            })));
            setShipping('0');
        } else {
            Swal.fire({
                title: 'This Product is Already Added',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonColor: 'red'
            })
        }
    }

    /* get Shipping Charges */
    let settingship = (e) => {
        if (e == "") {

            setShipping("");
        } else {
            setShipping(e);
        }
    }

    /* get Round Value */
    const settinground = (e) => {

        if (e == "") {

            setRound("");
        } else {


            setRound(e);
        }

    };

    const SGSTcheck = () => {
        let aa = 0

        if (taxtype == "withtax") {

            if (bstateid === dstateid) {

                aa = sgstTax;

            } else {
                aa = 0;
            }

        } else {
            aa = 0;
        }

        return aa;

    }

    const cgstcheck = () => {

        let bb = 0

        if (taxtype == "withtax") {

            if (bstateid === dstateid) {

                bb = cgstTax;

            } else {

                bb = 0;

            }

        } else {

            bb = 0;

        }
        return bb;
    }

    const igstcheck = () => {
        let cc = 0;

        if (taxtype == "withtax") {
            if (bstateid === dstateid) {
                cc = 0;
            } else {
                cc = igstTax;
            }
        } else {
            cc = 0;
        }
        return cc;
    }
    const handleChange = (e) => setseller({ ...seller, [e.target.name]: e.target.value });
    const handleChange1 = (e) => setbuyer({ ...buyer, [e.target.name]: e.target.value });

    const handledate = (e) => {
        setDatenow(e)
        const parts = e.split('-');

        const formattedDate = `${parts[2]}/${parts[1]}/${parts[0]}`;

        setdt1(formattedDate);

        console.log(formattedDate)
    }


    const handledate1 = (e) => {
        settransDocDt11(e)
        const parts = e.split('-');

        const formattedDate = `${parts[2]}/${parts[1]}/${parts[0]}`;

        settransDocDt1(formattedDate);
    }
    console.log(buyer)

    const submitdata = () => {

        setIsLoading(true);
        let salesItems = da_ta;
        let igst = igstcheck();
        let cgst = cgstcheck();
        let sgst = SGSTcheck();
        let roundofvalue = round;
        let grandtotal = grandTotal;
        let valdtls = { "assVal": gross_AmountassVal, "cgstVal": seller.stcd === buyer.stcd ? (cgstVal / 2) : 0, "sgstVal": seller.stcd === buyer.stcd ? (cgstVal / 2) : 0, "igstVal": seller.stcd === buyer.stcd ? 0 : cgstVal, "othChrg": shippingcharge, "totInvVal": grossamount, "cesVal": 0, "stCesVal": 0 }

        let dt = dt1;
        let expDt = defaultValue_2;
        let wrDt = defaultValue_3;
        let transDocDt = transDocDt1;
        let attribDtls = [anm];
        let tdls = { taxSch, supTyp }
        const doc = { no, dt, }
        let item = [{
            slNo, prdDesc, isServc, hsnCd, barcde, qty, freeQty, unit, unitPrice, totAmt, discount, preTaxVal, assAmt, gstRt, igstAmt, cgstAmt, sgstAmt, cesRt, cesAmt, cesNonAdvlAmt, stateCesRt,
            stateCesAmt, stateCesNonAdvlAmt, othChrg, totItemVal, ordLineRef, orgCntry, prdSlNo, 'bchDtls': { nm, expDt, wrDt }, attribDtls
        }
        ];
        let ewb = { distance, transId, transName, transDocDt, transDocNo, vehNo, vehType, transMode }
        // let salesdata = {

        //     ackdate, warehouse,deliveryAddress:Number(deliveryAddress), distributor, buyerorderno, invoiceno, invoicedate, status, taxtype, termsofdelivery, deliverynoteno,
        //     destination,deliveryAddress, packaginglistno, gstno, panno, ewaybillno, grnno, ackno, lrno, udyamno, msmeno, salesItems1,
        //     igst,cgst,sgst,grandtotal,grossamount,shippingcharge,roundofvalue,branch,paymentTerms,lrndate,edd,grndate,totalnopkg,dispatchedthrough
        // }
        if (retailerstatus === 'customer') {
            const emptyBlob = new Blob([""], { type: "application/octet-stream" });

            const formdata = new FormData();
            formdata.append('warehouse', JSON.stringify(warehouse));
            formdata.append('deliveryAddress', deliveryAddress);
            formdata.append('retailer', JSON.stringify(retailer));
            formdata.append('buyerorderno', buyerorderno);
            formdata.append('invoiceno', invoiceno);
            formdata.append('invoicedate', invoicedate);
            formdata.append('status', status);
            formdata.append('taxtype', taxtype);
            formdata.append('termsofdelivery', termsofdelivery);
            formdata.append('deliverynoteno', deliverynoteno);
            formdata.append('destination', destination);
            // formdata.append('deliveryAddress',deliveryAddress);
            formdata.append('packaginglistno', packaginglistno);
            formdata.append('gstno', gstno);
            formdata.append('panno', panno);
            formdata.append('ewaybillno', ewaybillno);
            formdata.append('grnno', grnno);
            formdata.append('lrno', lrno);
            formdata.append('udyamno', udyamno);
            formdata.append('msmeno', msmeno);
            formdata.append('si', JSON.stringify(salesItems));
            formdata.append('igst', igst);
            formdata.append('cgst', cgst);
            formdata.append('sgst', sgst);
            formdata.append('grandtotal', grandtotal);
            formdata.append('grossamount', grossamount);
            formdata.append('shippingcharge', shippingcharge);
            formdata.append('roundofvalue', roundofvalue);
            formdata.append('branch', JSON.stringify(branch));
            formdata.append('paymentTerms', paymentTerms);
            formdata.append('lrndate', lrndate);
            formdata.append('edd', edd);
            formdata.append('grndate', grndate);
            formdata.append('totalnopkg', totalnopkg);
            formdata.append('dispatchedthrough', totalnopkg);
            img2 ? formdata.append('img', img2) : formdata.append('img', emptyBlob, 'empty-file.txt');
            pdf2 ? formdata.append('pdf', pdf2) : formdata.append('pdf', emptyBlob, 'empty-file.txt');

            // formdata.append('img', img);
            // formdata.append('pdf', pdf);
            formdata.append('tdls', JSON.stringify(tdls));
            formdata.append('doc', JSON.stringify(doc));
            formdata.append('seller', JSON.stringify(seller));
            formdata.append('buyer', JSON.stringify(buyer));
            formdata.append('item', JSON.stringify(item));
            formdata.append('valdtls', JSON.stringify(valdtls));
            formdata.append('ewb', JSON.stringify(ewb));
            axios.put(BASE_URL + 'sales/' + id, formdata, {
                headers: {
                    ...authHeader(),
                    'Content-Type': 'multipart/form-data',
                },
            }).then(res => {
                navigate('/Sales/Table/1')
            }).catch(error => {
                console.error('Error adding  :', error);
                if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `
                <div>
                  <p id="issueText">Why do I have this issue?</p>
                  <ul>
              
                  <li id="typer1">You might have an internet issue</li>
                  </ul>
                </div>
              `,
                        didOpen: () => {
                            applyFontSizeTransition('issueText');
                        }
                    });
                }
            })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            const emptyBlob = new Blob([""], { type: "application/octet-stream" });

            const formdata = new FormData();
            formdata.append('warehouse', JSON.stringify(warehouse));
            formdata.append('deliveryAddress', deliveryAddress);
            formdata.append('distributor', JSON.stringify(distributor));
            formdata.append('buyerorderno', buyerorderno);
            formdata.append('invoiceno', invoiceno);
            formdata.append('invoicedate', invoicedate);
            formdata.append('status', status);
            formdata.append('taxtype', taxtype);
            formdata.append('termsofdelivery', termsofdelivery);
            formdata.append('deliverynoteno', deliverynoteno);
            formdata.append('destination', destination);
            // formdata.append('deliveryAddress',deliveryAddress);
            formdata.append('packaginglistno', packaginglistno);
            formdata.append('gstno', gstno);
            formdata.append('panno', panno);
            formdata.append('ewaybillno', ewaybillno);
            formdata.append('grnno', grnno);
            formdata.append('lrno', lrno);
            formdata.append('udyamno', udyamno);
            formdata.append('msmeno', msmeno);
            formdata.append('si', JSON.stringify(salesItems));
            formdata.append('igst', igst);
            formdata.append('cgst', cgst);
            formdata.append('sgst', sgst);
            formdata.append('grandtotal', grandtotal);
            formdata.append('grossamount', grossamount);
            formdata.append('shippingcharge', shippingcharge);
            formdata.append('roundofvalue', roundofvalue);
            formdata.append('branch', JSON.stringify(branch));
            formdata.append('paymentTerms', paymentTerms);
            formdata.append('lrndate', lrndate);
            formdata.append('edd', edd);
            formdata.append('grndate', grndate);
            formdata.append('totalnopkg', totalnopkg);
            formdata.append('dispatchedthrough', totalnopkg);
            // formdata.append('img', img);
            img2 ? formdata.append('img', img2) : formdata.append('img', emptyBlob, 'empty-file.txt');
            pdf2 ? formdata.append('pdf', pdf2) : formdata.append('pdf', emptyBlob, 'empty-file.txt');
            // formdata.append('pdf', pdf);
            formdata.append('tdls', JSON.stringify(tdls));
            formdata.append('doc', JSON.stringify(doc));
            formdata.append('seller', JSON.stringify(seller));
            formdata.append('buyer', JSON.stringify(buyer));
            formdata.append('item', JSON.stringify(item));
            formdata.append('valdtls', JSON.stringify(valdtls));
            formdata.append('ewb', JSON.stringify(ewb));
            axios.put(BASE_URL + 'sales/' + id, formdata, {
                headers: {
                    ...authHeader(),
                    'Content-Type': 'multipart/form-data',
                },
            }).then(res => {
                navigate('/Sales/Table/1')
            }).catch(error => {
                console.error('Error adding  :', error);
                if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `
                <div>
                  <p id="issueText">Why do I have this issue?</p>
                  <ul>
              
                  <li id="typer1">You might have an internet issue</li>
                  </ul>
                </div>
              `,
                        didOpen: () => {
                            applyFontSizeTransition('issueText');
                        }
                    });
                }
            })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }
    return (
        <MDBCard className="company">
            <h1>Sales Invoice Update</h1>
            <Link className='p-2 w-200 card-header card-title bd-highlight' to={`/Sales/Table/${pointindex}`}><Button>Back</Button></Link>

            <Form className="product1">
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Sales Data</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Branch Name</Form.Label>
                                    <Form.Control type="text" value={branchdata.branchname} readOnly />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">WareHouse Name</Form.Label>
                                    <Form.Control type="text" value={warehousehdata.name} readOnly />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Customer Name</Form.Label>
                                    <Form.Control type="text" value={customerdata.tradeName} readOnly />

                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Terms of Payment</Form.Label>
                                    <Form.Control type='text' value={paymentTerms} name="paymentTerms" onChange={(e) => setpaymentTerms(e.target.value)} autoComplete="off" />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Buyer Order</Form.Label>
                                    <Form.Control type="text" autoComplete="off" value={buyerorderno} name="buyerorderno" onChange={(e) => setbuyerorderno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Invoice No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" value={invoiceno} onChange={(e) => setinvoiceno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Invoice Date</Form.Label>
                                    <Form.Control type="date" value={defaultValue1} onChange={(e) => setinvoicedate(e.target.value)} />
                                </Form.Group>
                                {/* <Form.Group as={Col} md="8" controlId="formBasicName">
                                    <Form.Label className="label">Owner</Form.Label>
                                    <Form.Control type="text" autoComplete="off" />
                                </Form.Group> */}
                                {/* <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Type</Form.Label>
                                    <Form.Select value={type} readOnly>
                                        <option value="b2b">B2B</option>
                                        <option value="b2c">B2C</option>
                                    </Form.Select>
                                </Form.Group> */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Voucher</Form.Label>
                                    <Form.Control type='text' value={voucherid} name="voucherid" readOnly autoComplete="off" />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Voucher Name</Form.Label>
                                    <Form.Control type='text' value={voucherid} name="voucherid" readOnly autoComplete="off" />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Status</Form.Label>
                                    <Form.Select value={status} readOnly>
                                        <option value='true'>Open</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Tax Type</Form.Label>
                                    <Form.Select value={taxtype}  >
                                        <option>--Select--</option>
                                        <option value='withtax'>With Tax</option>
                                        <option value='withouttax'>Without Tax</option>
                                    </Form.Select>
                                </Form.Group>
                                {status1 === false ? <div style={{ height: "120px", width: '20%', alignItems: "center", justifyContent: "center", textAlign: 'center', borderWidth: "2px", borderRadius: "1rem", borderStyle: "dashed", borderColor: "#cbd5e1", backgroundColor: "#f8fafc" }}>

                                    <button onClick={() => setstatus1(true)}>X</button>


                                </div> :
                                    <Form.Group as={Col} md="4" controlId="formBasicName">
                                        <Form.Label className="label">Image</Form.Label>
                                        <Form.Control type="file" onChange={(e) => { setimg2(e.target.files[0]); setimg() }} />
                                    </Form.Group>
                                }
                                {status2 === false ? <div style={{ height: "120px", width: '20%', alignItems: "center", justifyContent: "center", textAlign: 'center', borderWidth: "2px", borderRadius: "1rem", borderStyle: "dashed", borderColor: "#cbd5e1", backgroundColor: "#f8fafc" }}>

                                    <button onClick={() => setstatus2(true)}>X</button>
                                </div> :
                                    <Form.Group as={Col} md="4" controlId="formBasicName">
                                        <Form.Label className="label">PDF</Form.Label>
                                        <Form.Control type="file" accept=".pdf" onChange={(e) => { setpdf2(e.target.files[0]); setpdf("") }} />
                                    </Form.Group>
                                }
                                {pdf2 ? "" : <a href={`http://43.225.54.138:8080/scm/${img}`} download target="_blank">
                                    Download Document Image
                                </a>}
                                <br></br>
                                {img2 ? "" : <a href={`http://43.225.54.138:8080/scm/${pdf}`} download target="_blank">
                                    Download Document Pdf
                                </a>}
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Billing Address</Accordion.Header>
                        <Accordion.Body>
                            {/* <Row className="mb-1">
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Customer Address</Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Customer Details</Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Address : -</Form.Label>
                                    <span>&nbsp;{customerAll.address}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Name : -</Form.Label>
                                    <span>&nbsp;{customerSubContactsdata == "" ? customerAll.cname : customerSubContactsdata.contactname}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">City : -</Form.Label>
                                    <span>&nbsp;{customerAll.city}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Phone No : -</Form.Label>
                                    <span>&nbsp;{customerSubContactsdata == "" ? customerAll.phonenumber : customerSubContactsdata.phoneno}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Country : -</Form.Label>
                                    <span>{"India"}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Email Id : -</Form.Label>
                                    <span> {customerSubContactsdata == "" ? customerAll.email : customerSubContactsdata.email}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">State : -</Form.Label>
                                    <span>{supplierstate}</span>
                                </Form.Group>
                            </Row> */}
                            <b><span className="">Delivery Address</span></b> <br></br> <br></br>
                            <Row className="mb-1">
                                {/* <select className="form-select dd">
                                <option className="extrvagnze">select Address</option>
                               
                                {
                                    disdel.map((item)=>{
                                        return<>
                                          <option className="extrvagnze">Address:{item.delivery_address} ,State:-{item.state} &nbsp;,Pin-code:-{item.pincode}</option>
                                        </>
                                    })
                                }
                               </select> */}
                                <select className="form-select" value={deliveryAddress} onChange={(e) => setdelvieryaddress(e.target.value)}>
                                    <option>select data</option>
                                    {
                                        getaddresss.map((item) => {
                                            return <>
                                                <option value={item.id}>{item.delivery_address}</option>
                                            </>
                                        })
                                    }
                                </select>

                            </Row>

                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>Details</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Terms of Delivery</Form.Label>
                                    <Form.Control type="text" autoComplete="off" value={termsofdelivery} name="termsofdelivery" onChange={(e) => settermsofdelivery(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Delivery Note No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" value={deliverynoteno} name="deliverynoteno" onChange={(e) => setdeliverynoteno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Destination</Form.Label>
                                    <Form.Control type="text" autoComplete="off" value={destination} name="destination" onChange={(e) => setdestination(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Packaging List No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" value={packaginglistno} name="packaginglistno" onChange={(e) => setpackaginglistno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">GST No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" value={gstno} name="gstno" onChange={(e) => setgstno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Pan No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" value={panno} name="panno" onChange={(e) => setpanno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Eway Bill No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" value={ewaybillno} name="ewaybillno" onChange={(e) => setewaybillno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">GRN No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" value={grnno} name="grnno" onChange={(e) => setgrnno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Dispatched Through</Form.Label>
                                    <Form.Control type="text" name="dispatchedthrough" value={dispatchedthrough} autoComplete="off" onChange={(e) => setdispatchedthrough(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Total No PKG </Form.Label>
                                    <Form.Control type="text" name="totalnopkg" value={totalnopkg} autoComplete="off" onChange={(e) => settotalnopkg(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">lRN No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" value={lrno} name="lrno" onChange={(e) => setlrno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Udyam No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" value={udyamno} name="udyamno" onChange={(e) => setudyamno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">MSME No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" value={msmeno} name="msmeno" onChange={(e) => setmsmeno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">LRN Date</Form.Label>
                                    <Form.Control type="date" value={defaultValue2} onChange={(e) => setlrndate(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">EDD</Form.Label>
                                    <Form.Control type="date" value={defaultValue3} onChange={(e) => setedd(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">GRN Date</Form.Label>
                                    <Form.Control type="date" value={defaultValue4} onChange={(e) => setgrndate(e.target.value)} />
                                </Form.Group>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>GST Details</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <h1>Transport Details</h1>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Tax Type</Form.Label>
                                    <Form.Control type="text" autoComplete="off" placeholder="Enter Tax Type" name="taxSch" value={taxSch} onChange={(e) => settaxSch(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Type of supply</Form.Label>
                                    {/* <sele */}
                                    <select className="form-control" value={supTyp} onChange={(e) => setsupTyp(e.target.value)} >
                                        {stype.map(mode => (
                                            <option key={mode.id} value={mode.value}>{mode.label}</option>
                                        ))}
                                    </select>
                                    {/* // <Form.Control type="text" autoComplete="off" placeholder="Enter Type of supply" value={supTyp} name="supTyp" onChange={(e) => setsupTyp(e.target.value)} /> */}
                                </Form.Group>
                                <h1>Document Details</h1>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Document No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" placeholder="Enter Document No" name="no" value={no} onChange={(e) => setno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Document Date</Form.Label>
                                    <Form.Control type="date" autoComplete="off" value={datenow} name="dt" onChange={(e) => handledate(e.target.value)} />
                                </Form.Group>
                                <h1>Seller Details</h1>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">GSTIN of Supplier </Form.Label>
                                    <Form.Control type="text" autoComplete="off" name="gstin" value={seller.gstin} placeholder="Enter GSTIN of Supplier" onChange={(e) => handleChange(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Legal Name</Form.Label>
                                    <Form.Control type="text" autoComplete="off" name="lglNm" value={seller.lglNm} placeholder="Enter Legal Name" onChange={(e) => handleChange(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Address</Form.Label>
                                    <Form.Control type="text" autoComplete="off" name="addr1" value={seller.addr1} placeholder="Enter Address1" onChange={(e) => handleChange(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Location</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Location" value={seller.loc} autoComplete="off" name="loc" onChange={(e) => handleChange(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">PinCode</Form.Label>
                                    <Form.Control type="text" placeholder="Enter PinCode" name="pin" value={seller.pin} autoComplete="off" onChange={(e) => handleChange(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">State Code</Form.Label>
                                    <Form.Control type="text" placeholder="Enter State Code" value={seller.stcd} autoComplete="off" name="stcd" onChange={(e) => handleChange(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Phone Number</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Phone Number" value={seller.ph} name="ph" autoComplete="off" onChange={(e) => handleChange(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Email Id</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Email Id" name="em" value={seller.em} autoComplete="off" onChange={(e) => handleChange(e)} />
                                </Form.Group>
                                <h1>Buyer Details</h1>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">GSTIN of Buyer</Form.Label>
                                    <Form.Control type="text" placeholder="Enter GSTIN of Buyer" value={buyer.gstin} name="gstin" autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Legal Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Legal Name" name="lglNm" value={buyer.lglNm} autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Sate Code of Place of Supply</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Sate Code of Place of Supply" value={buyer.pos} name="pos" autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Address</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Address1" name="addr1" value={buyer.addr1} autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Location</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Location" name="loc" value={buyer.loc} autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">PinCode </Form.Label>
                                    <Form.Control type="text" placeholder="Enter PinCode" name="pin" value={buyer.pin} autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">State Code</Form.Label>
                                    <Form.Control type="text" placeholder="Enter State Code" name="stcd" value={buyer.stcd} autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Phone No</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Phone No" name="ph" value={buyer.ph} autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Email ID</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Email ID" name="em" value={buyer.em} autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group>
                                <h1>Eway Bill Details</h1>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Distance</Form.Label>
                                    <Form.Control type="text" value={distance} name="distance" onChange={(e) => setdistance(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Mode of Transport</Form.Label>
                                    <select className="form-control" value={transMode} onChange={(e) => settransMode(e.target.value)}>
         {modes1.map(mode => (
          <option key={mode.id}>{mode.label}</option>
        ))}
      </select>

                                    {/* <Form.Control type="text" value={transMode} name="transMode" onChange={(e) => settransMode(e.target.value)} /> */}
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Transporter Id</Form.Label>
                                    <Form.Control type="text" value={transId} name="transId" onChange={(e) => settransId(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Transporter Name</Form.Label>
                                    <Form.Control type="text" value={transName} name="transName" onChange={(e) => settransName(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Transport Document Date</Form.Label>
                                    <Form.Control type="date" name="transDocDt" value={transportdate} onChange={(e) => handledate1(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Transport Document No</Form.Label>
                                    <Form.Control type="text" value={transDocNo} name="transDocNo" onChange={(e) => settransDocNo(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Vehicle No</Form.Label>
                                    <Form.Control type="text" value={vehNo} name="vehNo" onChange={(e) => setvehNo(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Vehicle Type</Form.Label>
                                    <select className="form-control" value={vehType}  onChange={(e) => settransMode(e.target.value)}>
         {vechiletype.map(mode => (
          <option key={mode.id}>{mode.label}</option>
        ))}
      </select>
                                    {/* <Form.Control type="text" value={vehType} name="vehType" onChange={(e) => setvehType(e.target.value)} /> */}
                                </Form.Group>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <br></br>
                <Row className="mb-1">
                    <Form.Group as={Col} md="12" controlId="formBasicName">
                        <Form.Label className="label">Product</Form.Label>
                        <Select options={productdata} onChange={(e) => addData(e)} onInputChange={(e) => customFilter(e)} />
                    </Form.Group>
                </Row>
                <br></br>
                <Table striped responsive hover size="sm">
                    <thead className='p-3 mb-2 bg-success text-white'>
                        <tr>
                            <th>PRODUCT&nbsp;NAME</th>
                            <th >UOM</th>
                            <th >QTY(PCS)</th>
                            <th >QTY(KG)</th>
                            <th >UOM</th>
                            <th >Rate</th>
                            <th >Trade&nbsp;Discount</th>
                            <th>GROSS AMOUNT (₹)</th>
                            <th >SCHEME DISCOUNT %</th>
                            <th >NET AMOUNT (₹)</th>
                            <th >GST Value</th>
                            <th >Amount</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody style={{ overflow: "scroll" }}>
                        {salesItems1.map(item =>

                            <tr key={item.product.id}>
                                <td>
                                    <Form.Group as={Col} md="12" >
                                        {/* <Form.Control type="text" value={item.productName } size="sm" /> */}
                                        <textarea type="text" className="form-control textcontrol" value={item.productName} size="sm" ></textarea>

                                    </Form.Group>
                                    <Form.Control as="textarea" onChange={(e) => { dispatch(changesalesedittext(e.target.value, item)) }} value={item.text} rows={2} className='form-control' />
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Select disabled={item.unitofmeasurement == "box" ? true : item.productType !== "kgproduct" ? true : false} value={item.unitofmeasurement} onChange={(e) => { dispatch(changeunitofMeasurementsalesedit(e.target.value, item, item.unitofmeasurement)); }} size="sm">
                                            <option>{item.unitofmeasurement == "box" ? "PCS" : item.productType !== "kgproduct" ? 'PCS' : "Select"}</option>
                                            <option value="pcs">PCS</option>
                                            <option value="kg">KG</option>
                                        </Form.Select>
                                    </Form.Group>
                                </td>

                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" size="sm" onChange={(e) => { dispatch(changesaleseditsqpbmeasurement(e.target.value, item, taxtype, item.unitofmeasurement)); roundData(e.target.value) }} value={item.salesquantity} disabled={item.unitofmeasurement == "kg" ? true : false||item.unitofmeasurement == "" ? true : false} />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" onChange={(e) => { dispatch(changesaleseditsqpbkgmeasurement(e.target.value, item, taxtype, item.unitofmeasurement)); roundData(e.target.value) }} value={Number(item.salesquantitykgs)} disabled={item.unitofmeasurement == "box"||item.unitofmeasurement == "pcs" ? true : false || item.unitofmeasurement == "" ? true : false} size="sm" requied />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" size="sm" onChange={(e) => { dispatch(changecostsalesedit(e.target.value, item, taxtype)); roundData(e.target.value) }} value={Number(item.dlp)} />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Select disabled={item.calunitofmeasurement == "box" ? true : item.productType !== "kgproduct" ? true : false} value={item.calunitofmeasurement} onChange={(e) => { dispatch(changecalunitofMeasurementsalesedit(e.target.value, item, item.calunitofmeasurement)); roundData(e.target.value) }} size="sm">
                                            <option>{item.calunitofmeasurement == "box" ? "PCS" : item.productType !== "kgproduct" ? 'PCS' : "Select"}</option>
                                            <option value="pcs">PCS</option>
                                            <option value="kg">KG</option>
                                        </Form.Select>
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" min="0" size="sm" value={Number(item.tradeDiscount)} />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" value={Number(item.grossamount)} size="sm" readOnly requied />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" onChange={(e) => { dispatch(changesaleseditdiscount(e.target.value, item, taxtype)); roundData(e.target.value) }} value={Number(item.schemeDiscount)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" value={Number(item.netAmount)} size="sm" readOnly requied />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" value={Number(item.gstvalue)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" value={Number(item.total)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type='button' className='btn btn-danger' onClick={() => { dispatch(removefromcartfromsalesedit(item.product.id)); roundData('') }} value="X" size="sm" />
                                    </Form.Group>
                                </td>

                            </tr>
                        )}
                    </tbody>
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Packing&nbsp;&&nbsp;Forwarding</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={shippingcharge} onChange={(e) => settingship(e.target.value)} style={{ height: 30 }} readOnly />
                                </Form.Group>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Net&nbsp;Amount</td>
                            <td><Form.Group as={Col} md="12">
                                <Form.Control type="number" value={grossamount ? ((grossamount).toFixed(2)) : "0"} style={{ height: 30 }} />
                            </Form.Group></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>IGST</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={igstcheck()} style={{ height: 30 }} requied />
                                </Form.Group>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>SGST</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={SGSTcheck()} style={{ height: 30 }} requied />
                                </Form.Group>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>CGST</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={cgstcheck()} style={{ height: 30 }} requied />
                                </Form.Group>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Round&nbsp;off&nbsp;Value</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={round} onChange={(e) => settinground(e.target.value)} style={{ height: 30 }} />
                                </Form.Group>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Grand&nbsp;Total</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={grandTotal ? ((grandTotal).toFixed(2)) : "0"} style={{ height: 30 }} readOnly />
                                </Form.Group>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </Table>
                <Button variant="primary" type="button" onClick={submitdata}>
                    Submit
                </Button>
            </Form>
        </MDBCard>
    )
}