import { MDBCard, MDBCardBody } from 'mdb-react-ui-kit'
import { MDBDataTable } from 'mdbreact'
import React, { useEffect, useState } from 'react'
import { CSVLink } from 'react-csv'
import BrandService from '../../services/Brand'
import { FaUserEdit } from 'react-icons/fa'
import { AiOutlineDelete } from 'react-icons/ai'
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal';
import axios from 'axios'
import authHeader from '../../services/auth-header'
import Select from "react-select";
import { BASE_URL } from '../../services/Api'

// import Select from 'react-select/dist/declarations/src/Select'
// import Select from 'react-select/dist/declarations/src/Select'

const OpenCreate = () => {
  const [show, setShow] = useState(false);
  const [error, setError] = useState(encodeURIComponent(' '))
  const [disword, setdisword] = useState(encodeURIComponent(' '))
  const handleClose = () => setShow(false); // Modal
  const handleShow = () => setShow(true); // Modal
  const [posts, setPosts] = useState([]);
  const [datacsv, setcsv] = useState([])
  const [plus, setplus] = useState([])
  const [product2, setproduct2] = useState([])
  const [warehouseData, setWarehouseData] = useState([])
  const [quantity, setquantity] = useState([])
  const [user_date, setuser_date] = useState([])
  const [warehouse, setwarehouse] = useState([])
  const [product, setproduct] = useState([])
  const [loading, setLoading] = useState(false);



  const getItem = (id) => {


    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        BrandService.getDelete(id).then(res => {



          setplus(1 + plus)




          // window.location.reload();
        })
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        )
      }
    })


  };

  const handlesupersubmit = () => {
    setLoading(true);

    const postData = {
      stockdate: user_date,
      stockqty: quantity,
      product: {
        id: product
      },
      wid: warehouse,
    };
    // const headers = authHeader();

    axios.post(BASE_URL+'mininmumstock/', postData, { headers: authHeader() })
      .then(response => {
        console.log('POST request successful');
        console.log('Response:', response.data);
       window.location.reload()
      }) .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false); 
      });

  }
  useEffect(() => {

    axios.get(BASE_URL+`product/page/0/10/asc/id/${error.length?error:encodeURIComponent(' ')}`, { headers: authHeader() })
      .then(response => {
        //   const endTime = new Date().getTime();  
        //   const elapsedTime = endTime - startTime;  
        //   console.log(`Request took ${elapsedTime} milliseconds`);
        if (response.data.Index==undefined) {
          setproduct2(response.data.data.map(use => ({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code })));

        }  if (response.data.data==undefined) {
          setproduct2(response.data.Index.map(use => ({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code  })));

        }


        console.log(response)
      })
      .catch(error => {
        console.error(error);
      });

    axios.get(BASE_URL+`warehouse/page/0/10/asc/id/${disword.length?disword:encodeURIComponent(' ')}`, { headers: authHeader() })
      .then(response => {
        //   const endTime = new Date().getTime();  
        //   const elapsedTime = endTime - startTime;  
        //   console.log(`Request took ${elapsedTime} milliseconds`);
        if (response.data.Index==undefined) {
          setWarehouseData(response.data.data.map(use => ({ value: use.id, label: use.name })));

        }else{
          setWarehouseData(response.data.Index.map(use => ({ value: use.id, label: use.name })));
   
        }
        // if(1==1) {
        //   setWarehouseData(response.data.map(use => ({ value: use.id, label: use.name })));
        // }
        setWarehouseData(response.data.data.map(item =>({
          value:item.id, label: item.name
        })));
        
      })
      .catch(error => {
        console.error(error);
      });


    // BrandService.getBrand().then((response) => {
    //   const aaaa = response.data;
    //   var x = 1;


    //   let rowsData = []
    //   for (var index = 0; index < aaaa.length; index++) {
    //     let rowItem = {}
    //     rowItem["id"] = aaaa[index].id
    //     rowItem["index"] = x
    //     rowItem["name"] = aaaa[index].brandName
    //     rowItem["update"] = <div>
    //       <Link to={`/brandedit/${aaaa[index].id}`} className="pubutton" id='pb1'>
    //         <FaUserEdit />
    //       </Link>  <Link onClick={() => getItem(rowItem["id"])} className="pubutton" id='pb'>
    //         <AiOutlineDelete />
    //       </Link>
    //     </div>


    //     rowsData.push(rowItem);
    //     x += 1;
    //   }

    //   setPosts(rowsData);
    //   const csvData = response.data.map(({ update, ...rest }) => rest);
    //   setcsv(csvData);
    //   console.log(csvData)
    // })
  }, [error, disword]);


  const data = () => {
    return {
      columns: [

        {
          label: 'NO',
          field: 'index',
          width: 100,
        },

        {
          label: 'Brand Name',
          field: 'name',
          width: 150,
          attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'username',
          },

        },
        {
          label: 'Action',
          field: 'update',
          sort: 'disabled',
          width: 100,
        },


      ],
      rows: posts
    };
  };

  const setWarehouseId = (val) => {
    console.log(val)
    setwarehouse(val)
  }

  const productid = (val) => {
    console.log(val)
    setproduct(val)
  }








  return (
    <div>



      <div className='d-flex bd-highlight'>
        <div className='p-2 w-100 bd-highlight'> <Link  > <button onClick={handleShow} class="btn btn-sm btn-primary">Add MSL</button></Link></div>
        <div className='p-2 flex-shrink-1 bd-highlight'><CSVLink data={datacsv} class="btn btn-sm btn-primary">Export</CSVLink></div>  </div>




      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className='col'>
              <label className='form-label'>Warehouse</label>

              <Select options={warehouseData} onInputChange={(inputValue) => setdisword(inputValue)} onChange={(options) => setWarehouseId(options.value)} />

            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <label className='form-label'>Product</label>
              {/* <select className='form-select' onChange={(e)=>setError(e.target.value)} >
    <option>Select Product</option>
 </select> */}
              <Select options={product2} onInputChange={(inputValue) => setError(inputValue)} onChange={(options) => productid(options.value)} />

              {/* <input type='text' className='form-control'  onChange={(e)=>setError(e.target.value)}  /> */}
            </div>
          </div>

          <div className='row'>
            <div className='col'>
              <label className='form-label' >Date</label>
              <input type='Date' className='form-control' onChange={(e) => setuser_date(e.target.value)} />
            </div>
            <div className='col'>
              <label className='form-label'>Qty</label>
              <input type='number' className='form-control' onChange={(e) => setquantity(e.target.value)} />
            </div>
          </div>



        </Modal.Body>
        <Modal.Footer>
          <a className="btn btn-sm btn-secondary" variant="secondary" onClick={handleClose}>
            Close
          </a>
          <a className="btn btn-sm btn-secondary" variant="primary" onClick={() => { handleClose(); handlesupersubmit() }}>
         {loading?"loading":"Save Changes"}
          </a>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default OpenCreate