import React from 'react'
import { Mydatatable } from '../Product/Mydatatable'

const Newretailer = () => {

    let header = [
        { "label": "Id", "field": "id" },
        { "label": "Retailer Name", "field": "retailername" },
    
        { "label": "Distributor Name", "field":"distributorname" },
        // { "label": "Delivery Location", "field": "delivery_Location" },
        { "label": "ASE Staff Name", "field": "asestaffname" },
        { "label": "GST Number", "field": "gst_Number" },
     { "label": "State", "field": "state_name" },
     { "label": "City", "field": "city" },
     { "label": "Created Date", "field": "createddate" },
     { "label": "Created Time", "field": "createdtime" },
     { "label": "Created By", "field": "createbyname" },
     { "label": "Updated Date", "field": "updateddate" },
     { "label": "Updated Time", "field": "updatedtime" },
     { "label": "Updated By", "field": "updatedbyname" },
        { "label": "Action", "field": "action","subaction1":<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"/><circle cx="12" cy="12" r="3"/></svg> }
      ]; 



      const sumofkeys=[{"first":'id'} ]


  return (
    <Mydatatable
    head={header}
    title={"Retailer"}
    axiostabledata={'retailer'}
    Add={"Add Retailer"}
    Edit={"/retailerview"}
    View={"/retailerview"}
    Sumofkeys={sumofkeys}
    Create={'/retailercreate'}
    />
  )
}

export default Newretailer