import React from 'react'
import { Mydatatable } from '../Master/Product/Mydatatable'

const Newsales = () => {
    let header = [
        { "label": "Id", "field": "id" },
        { "label": "Invoice Date", "field": "invoicedate" },
        { "label": "Customer Name", "field": "distributorname" },
        { "label": "Taxable", "field": "netvalue" },
        { "label": "GST No", "field":"gstno" },
        { "label": "Grand total  ", "field": "grandtotal" },
     { "label": "Status", "field": "status" },
     { "label": "Created Date", "field": "createddate" },
     { "label": "Created Time", "field": "createdtime" },
     { "label": "Created By", "field": "createbyname" },
     { "label": "Updated Date", "field": "updateddate" },
     { "label": "Updated Time", "field": "updatedtime" },
     { "label": "Updated By", "field": "updatedbyname" },
        { "label": "Action", "field": "action","subaction1":<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"/><circle cx="12" cy="12" r="3"/></svg> }
      ]; 
    
    //   "id": 3,
    //   "status": "B2C",
    //   "gstno": "27AABCN9540N1ZY",
    //   "contactname": "omkar",
    //   "invoicedate": "2023-10-11T00:00:00.000+00:00",
    //   "netvalue": "10""
    
    
    
    
    
    const sumofkeys=[{"first":'netvalue'} ]


  return (
    
    <Mydatatable
    head={header}
    title={"Sales"}
    axiostabledata={'sales'}
    Add={"Add Sales"}
    Create={'/Sales/Create'}
    Edit={"/Sales/View"}
    View={"/Sales/View"}
    Sumofkeys={sumofkeys}
    />


  )
}

export default Newsales