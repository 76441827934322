import { SUPPLIER_EDIT_PUT_DATA_TO_LOAD } from "../constant";
import { PUT_NEW_SUPPLIER_EDIT_ADD_TO_CART } from "../constant";
import { CHANGE_SUPPLIER_EDIT_QTY } from "../constant";
import { REMOVE_FROM_CART_FROM_SUPPLIER_EDIT } from "../constant";
import { CHANGE_COST_SUPPLIER_EDIT } from "../constant";
import { CHANGE_SUPPLIER_EDIT_DISCOUNT } from "../constant";
import { CHANGE_SUPPLIER_EDIT_TEXT } from "../constant";
import { EMPTY_SUPPLIER_EDIT_DATA } from "../constant";


export const supplierdeliveryeditdata = (data = [], action) => {

    switch(action.type){
        case SUPPLIER_EDIT_PUT_DATA_TO_LOAD:
        return [...action.data];


        case PUT_NEW_SUPPLIER_EDIT_ADD_TO_CART:
            let currentData = {
                product:{"id":action.data.id},
                igst:action.data.igst,
                mrp:action.data.mrp,
                productName:action.data.productName
            }
            return [currentData, ...data];

        case CHANGE_SUPPLIER_EDIT_QTY:
           
            let newCart = [...data]
            let itemIndex = newCart.findIndex(obj => obj.product.id === action.item.product.id)
            let currItem = data[itemIndex]

            if(action.data2 == "withtax") {

                if(currItem.discount > 0){
                    if(action.data == ""){
                    currItem.sdn_quantity = Number(0)
                    } else {
                    currItem.sdn_quantity = Number(action.data)
                    }
        
                    let discount2 = Number(currItem.sdn_quantity) * Number(currItem.mrp) * (Number(currItem.discount)/100)
                    currItem.total = (Number(currItem.sdn_quantity) * Number(currItem.mrp) - Number(discount2)).toFixed(2);
                    currItem.productId = currItem.id
                    // currItem.total = parseInt(currItem.sdn_quantity) * parseInt(currItem.mrp)
                    currItem.gstvalue = (Number(currItem.total) * (Number(currItem.igst)/100)).toFixed(2);
                    currItem.amount = (Number(currItem.gstvalue) + Number(currItem.total)).toFixed(2);
                    data[itemIndex] = currItem
        
                    } else {
        
                        if(action.data == ""){
                            currItem.sdn_quantity = Number(0)
                            } else {
                            currItem.sdn_quantity = Number(action.data)
                            }
                            currItem.discount = Number(0)
                            currItem.productId = currItem.id
                            currItem.total = (Number(currItem.sdn_quantity) * Number(currItem.mrp)).toFixed(2);
                            currItem.gstvalue = (Number(currItem.total) * (Number(currItem.igst)/100)).toFixed(2);
                            currItem.amount = (Number(currItem.gstvalue) + Number(currItem.total)).toFixed(2);
                            data[itemIndex] = currItem
        
        
                    }

            } else {


                if(currItem.discount > 0){
                    if(action.data == ""){
                    currItem.sdn_quantity = Number(0)
                    } else {
                    currItem.sdn_quantity = Number(action.data)
                    }
        
                    let discount2 = Number(currItem.sdn_quantity) * Number(currItem.mrp) * (Number(currItem.discount)/100)
                    currItem.total = (Number(currItem.sdn_quantity) * Number(currItem.mrp) - Number(discount2)).toFixed(2);
                    currItem.productId = currItem.id
                    // currItem.total = parseInt(currItem.sdn_quantity) * parseInt(currItem.mrp)
                    currItem.gstvalue = Number(0)
                    currItem.amount = (Number(currItem.gstvalue) + Number(currItem.total)).toFixed(2);
                    data[itemIndex] = currItem
        
                    } else {
        
                        if(action.data == ""){
                            currItem.sdn_quantity = Number(0)
                            } else {
                            currItem.sdn_quantity = Number(action.data)
                            }
                            currItem.discount = Number(0)
                            currItem.productId = currItem.id
                            currItem.total = (Number(currItem.sdn_quantity) * Number(currItem.mrp)).toFixed(2);
                            currItem.gstvalue = Number(0)
                            currItem.amount = (Number(currItem.gstvalue) + Number(currItem.total)).toFixed(2);
                            data[itemIndex] = currItem
        
        
                    }


            }
           

            return [...data];

          

            case REMOVE_FROM_CART_FROM_SUPPLIER_EDIT:
        
            const remainingitem = data.filter((item)=> item.product.id!== action.data);
            return [...remainingitem];


            case CHANGE_COST_SUPPLIER_EDIT:
           
            let newCart1 = [...data]
            let itemIndex1 = newCart1.findIndex(obj => obj.product.id === action.item.product.id)
            let currItem1 = data[itemIndex1]


            if(action.data2 == "withtax") {

                if(currItem1.discount > 0){

                    if(action.data == ""){
                        currItem1.mrp = ""
                        } else {
                            currItem1.mrp = Number(action.data)
                        }
                    // currItem1.mrp = parseInt(action.data)
                    let discount3 = Number(currItem1.sdn_quantity) * Number(currItem1.mrp) * (Number(currItem1.discount)/100)
                    currItem1.total = (Number(currItem1.sdn_quantity) * Number(currItem1.mrp) - Number(discount3)).toFixed(2);
                    // currItem1.total = parseInt(currItem1.sdn_quantity) * parseInt(currItem1.mrp)
                    currItem1.gstvalue = (Number(currItem1.total) * (Number(currItem1.igst)/100)).toFixed(2);
                    currItem1.amount = (Number(currItem1.gstvalue) + Number(currItem1.total)).toFixed(2);
                    data[itemIndex1] = currItem1
                    return [...data];
    
                } else {
    
                    if(action.data == ""){
                        currItem1.mrp = ""
                        } else {
                            currItem1.mrp = Number(action.data)
                        }
                    // currItem1.mrp = parseInt(action.data)
                    currItem1.discount = Number(0)
                    currItem1.total = (Number(currItem1.sdn_quantity) * Number(currItem1.mrp)).toFixed(2);
                    currItem1.gstvalue = (Number(currItem1.total) * (Number(currItem1.igst)/100)).toFixed(2);
                    currItem1.amount = (Number(currItem1.gstvalue) + Number(currItem1.total)).toFixed(2);
                    data[itemIndex1] = currItem1
                    return [...data];
    
                }


            } else {


                if(currItem1.discount > 0){

                    if(action.data == ""){
                        currItem1.mrp = ""
                        } else {
                            currItem1.mrp = Number(action.data)
                        }
                    // currItem1.mrp = parseInt(action.data)
                    let discount3 = Number(currItem1.sdn_quantity) * Number(currItem1.mrp) * (Number(currItem1.discount)/100)
                    currItem1.total = (Number(currItem1.sdn_quantity) * Number(currItem1.mrp) - Number(discount3)).toFixed(2);
                    // currItem1.total = parseInt(currItem1.sdn_quantity) * parseInt(currItem1.mrp)
                    currItem1.gstvalue = Number(0)
                    currItem1.amount = (Number(currItem1.gstvalue) + Number(currItem1.total)).toFixed(2);
                    data[itemIndex1] = currItem1
                    return [...data];
    
                } else {
    
                    if(action.data == ""){
                        currItem1.mrp = ""
                        } else {
                            currItem1.mrp = Number(action.data)
                        }
                    // currItem1.mrp = parseInt(action.data)
                    currItem1.discount = Number(0)
                    currItem1.total = (Number(currItem1.sdn_quantity) * Number(currItem1.mrp)).toFixed(2);
                    currItem1.gstvalue = Number(0)
                    currItem1.amount = (Number(currItem1.gstvalue) + Number(currItem1.total)).toFixed(2);
                    data[itemIndex1] = currItem1
                    return [...data];
    
                }



            }


            case CHANGE_SUPPLIER_EDIT_DISCOUNT:
           
            let newCart2 = [...data]
            let itemIndex2 = newCart2.findIndex(obj => obj.product.id === action.item.product.id)
            let currItem2 = data[itemIndex2]


            if(action.data2 == "withtax") {

                if(action.data == ""){
                    currItem2.discount = 0
                    } else {
                        currItem2.discount = Number(action.data)
                    }
    
                // currItem1.mrp = parseInt(action.data)
                let discount1 = Number(currItem2.sdn_quantity) * Number(currItem2.mrp) * (Number(currItem2.discount)/100)
                currItem2.total = (Number(currItem2.sdn_quantity) * Number(currItem2.mrp) - Number(discount1)).toFixed(2);
                // currItem2.total = parseInt(currItem2.sdn_quantity) * parseInt(currItem2.mrp)
                currItem2.gstvalue = (Number(currItem2.total) * (Number(currItem2.igst)/100)).toFixed(2);
                currItem2.amount = (Number(currItem2.gstvalue) + Number(currItem2.total)).toFixed(2);
                data[itemIndex2] = currItem2

            } else {

                if(action.data == ""){
                    currItem2.discount = 0
                    } else {
                        currItem2.discount = Number(action.data)
                    }
    
                // currItem1.mrp = parseInt(action.data)
                let discount1 = Number(currItem2.sdn_quantity) * Number(currItem2.mrp) * (Number(currItem2.discount)/100)
                currItem2.total = (Number(currItem2.sdn_quantity) * Number(currItem2.mrp) - Number(discount1)).toFixed(2);
                // currItem2.total = parseInt(currItem2.sdn_quantity) * parseInt(currItem2.mrp)
                currItem2.gstvalue = Number(0)
                currItem2.amount = (Number(currItem2.gstvalue) + Number(currItem2.total)).toFixed(2);
                data[itemIndex2] = currItem2
                
            }
           
          
            return [...data];


            case CHANGE_SUPPLIER_EDIT_TEXT:
           
            let newCart5 = [...data]
            let itemIndex5 = newCart5.findIndex(obj => obj.product.id === action.item.product.id)
            let currItem5 = data[itemIndex5]
                     
            currItem5.text = action.data
            data[itemIndex5] = currItem5
            return [...data];


            case EMPTY_SUPPLIER_EDIT_DATA:
                // console.warn("reducer called",action)
                return [];
        

       
        default:
        return data;
    }
    

}
