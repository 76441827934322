import { MDBCard } from "mdbreact";
import React from "react";
import { useState } from "react";
import { Accordion, Button, Col, Form, Row, Table } from "react-bootstrap";
import Select from "react-select";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import { changecostmaterial, changematerialdiscount, changematerialqty, changematerialtext, getmaterialdatatoload, materialaddtocart, materialcalmeasurement, materialmeasurement, materialqtykgs, materialqtypcs, removefromcartfrommaterial } from '../../redux/Action/materialreceiptnote';
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import authHeader from "../services/auth-header";
import { BASE_URL } from "../services/Api";
import ProductService from "../services/ProductService";
export default function Create() {
    const { id } = useParams();

    const [product, setproduct] = useState([]);
    const [warehousedata, setwarehousedata] = useState('');
    const [supplierdata, setsupplierdata] = useState('');
    const [supplierSubContactsdata, setsupplierSubContactsdata] = useState('');
    const [supplierSubContacts_data, setsupplierSubContacts_data] = useState([]);
    const [supplier_SubContactsdata, setsupplier_SubContactsdata] = useState([]);
    const [mrndate, setmrndate] = useState(new Date());
    const [type, settype] = useState('');
    const [status, setstatus] = useState('');
    const [shippingaddress, setshippingaddress] = useState('');
    const [remarks, setremarks] = useState('');
    const [productdata, setproductdata] = useState([]);
    const [taxtype, settaxtype] = useState("");
    const [ receiptnoteno, setreceiptnoteno ] = useState('');
    const [ refernceno, setrefernceno ] = useState('');
    const [ receiptdate, setreceiptdate ] = useState(new Date());
    const [ otherreference, setotherreference ] = useState('');
    const [ branch,setBranch ] = useState('');
    const [ branchdata,setBranchdata ] = useState('');
    const [ bstateid, setbstateid ] = useState('');
    const [ sstateid, setsstateid ] = useState('');
    const [shippingcharge, setShipping] = useState(0);
    const [round, setRound] = useState(0);
    const [paymentTerms, setpaymentTerms] = useState('');
    const [voucherid, setvoucherid] = useState('');
    const [voucherseries, setvoucherseries] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const applyFontSizeTransition = (elementId) => {
      const element = document.getElementById(elementId);
      element.style.transition = 'font-size 2s';
      element.style.fontSize = '30px';
      element.style.fontWeight = '600';
      // font-weight: 600;
      setTimeout(() => {
         
        document.getElementById("typer1").classList.add('promote');
        element.style.fontSize = '24px';
      }, 1000);
    };
    
    const dispatch = useDispatch();
    const materialReceiptNoteItems = useSelector((state) => state.materialdata);
    let gross_Amount = materialReceiptNoteItems.length && materialReceiptNoteItems.map(item => Math.round(item.total)).reduce((prev, next) => Math.round(prev - (-next)));
    let gst = materialReceiptNoteItems.length && materialReceiptNoteItems.map(item => (item.gstvalue)).reduce((prev, next) => (prev - (-next)));
    // console.log("mello",gst)

    let aa = [];
    materialReceiptNoteItems.map(user=>{
        let igst_value = user.igst;
        let total_value = user.total;
        let total_amou = ((Number(shippingcharge)/Number(gross_Amount))* Number(total_value));
        let add_amou = Number(total_amou) - (-Number(total_value));
        let final_amou = ((Number(add_amou)/100)*Number(igst_value)).toFixed(2); 
        aa.push(final_amou)

        // console.log("mello",((Number(add_amou)/100)*Number(igst_value)).reduce((prev, next) => Math.round(prev - (-next))) );
    })
    let total_per = aa.length && aa.map(user=>user).reduce((prev, next) => (prev - (-next)));

    let grossAmount = Number(gross_Amount) + Number(shippingcharge);
    let grandTotal = Number(gross_Amount)  + Number(shippingcharge) + Number(round) +  Number(total_per);

    let igstTax = Number(total_per);
    let cgstTax = (Number(total_per)/2);
    let sgstTax = (Number(total_per)/2);

   const navigate = useNavigate();

   function roundData(e){
    if(e){
        let gross_Amount = materialReceiptNoteItems.length && materialReceiptNoteItems.map(item => Math.round(Number(item.total))).reduce((prev, next) => Math.round(prev - (-next)));
        let gst = materialReceiptNoteItems.length && materialReceiptNoteItems.map(item => (item.gstvalue)).reduce((prev, next) => (prev - (-next)));
        let aa = [];
        materialReceiptNoteItems.map(user=>{
            let igst_value = user.igst;
            let total_value = user.total;
            let total_amou = ((Number(shippingcharge)/Number(gross_Amount))* Number(total_value));
            let add_amou = Number(total_amou) - (-Number(total_value));
            let final_amou = ((Number(add_amou)/100)*Number(igst_value)).toFixed(2); 
            aa.push(final_amou)
    
            // console.log("mello",((Number(add_amou)/100)*Number(igst_value)).reduce((prev, next) => Math.round(prev - (-next))) );
        })
        let total_per = aa.length && aa.map(user=>user).reduce((prev, next) => (prev - (-next)));
        
        let grossAmount = Number(gross_Amount) + Number(shippingcharge);
        let grandTotal = Number(gross_Amount)  + Number(shippingcharge) + Number(total_per);
  
         let grand_Total = Math.ceil(grandTotal);
    
         if(grand_Total > grandTotal){
             let rou_nd = (Number(grand_Total) - Number(grandTotal)).toFixed(2);
             setRound(rou_nd);
         } else{
             setRound(0)
         }
           
          
    } else if(e==''){
        setRound(0)
    }
}
    const getsupplierdeliverydata = () => {

        axios.get(BASE_URL + 'sdn/' + id, {
            headers: authHeader()
        }).then(res => {
            console.log("mello", res.data)
            setwarehousedata(res.data.warehouse);
            setsupplierdata(res.data.supplier);
            setsupplierSubContactsdata(res.data.supplierSubContacts == null?null:{"id":res.data.supplierSubContacts.id});
            setsupplierSubContacts_data(res.data.supplierSubContacts == null?null:{value:res.data.supplierSubContacts.id,label:res.data.supplierSubContacts.contactname})
            setsupplier_SubContactsdata((res.data.supplier.supplierSubContacts).map(user=>({value:user.id,label:user.contactname})))
            settype(res.data.type);
            setstatus(res.data.status);
            setshippingaddress(res.data.shippingaddress);
            setremarks(res.data.remarks);
            settaxtype(res.data.taxtype);
            setBranch({"id":res.data.branch.id});
            setBranchdata(res.data.branch);
            setShipping(res.data.shippingcharge);
            setRound(res.data.roundofvalue);
            setpaymentTerms(res.data.paymentTerms);
            setbstateid(res.data.branch.states.id);
            setsstateid(res.data.supplier.states.id);
            setvoucherid(res.data.voucherid);
            setvoucherseries(res.data.voucherseries);

        });

    };

       /* get Product All */
       const getproductdata = () => {
        axios.get( BASE_URL + 'product/page/0/100/asc/id/%20',{
            headers:authHeader()
        }).then(res=>{

            setproductdata((res.data.Index).map(use => ({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code + ' - ' + use.short_Name })));
        })
    };

    const customFilter = (e) => {
        let name = e;
        axios.get( BASE_URL + 'product/page/0/100/asc/id/'+name,{
            headers:authHeader()
        }).then(res=>{
         setproductdata((res.data.data).map(use=>({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code + ' - ' + use.short_Name })))
        })
       };

       let date_value = new Date(mrndate);
       const defaultValue = date_value.toLocaleDateString('en-CA');
       let date_value1 = new Date(receiptdate);
       const defaultValue1 = date_value1.toLocaleDateString('en-CA');

    useEffect(() => {
        getsupplierdeliverydata();
        getproductdata();
        dispatch(getmaterialdatatoload(id));

    }, []);

const addData = ( e ) =>{
    const filteredItems = materialReceiptNoteItems.filter(item => item.product.id === e.value);
    if (filteredItems.length == "0") { 
        dispatch(materialaddtocart(e.value));
        setShipping("0");
    } else{
        Swal.fire({
            title: 'This Product is Already Added',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showConfirmButton:false,
            showCancelButton:true,
            cancelButtonColor:'red'
        })
    }

    }

     /* get Shipping Charges */
     let settingship = (e) => {
        if (e == "") {

            setShipping("");
          } else {
            setShipping(e);
          }

    };

     /* get Round Value */
const settinground = (e) => {

    if (e == "") {

      setRound("");
    } else {
      setRound(e);
    }
  };

  const SGSTcheck = () => {
    let aa = 0

    if (taxtype == "withtax") {

      if (bstateid === sstateid) {
         
        aa = sgstTax;

      } else {
        aa = 0;
      }

    } else {
      aa = 0;
    }

    return aa;

  }

  const cgstcheck = () => {

    let bb = 0

    if (taxtype == "withtax") {

      if (bstateid === sstateid) {

        bb = cgstTax;

      } else {

        bb = 0;

      }

    } else {

      bb = 0;

    }
    return bb;
  }

  const igstcheck = () => {
    let cc = 0;

    if (taxtype == "withtax") {
      if (bstateid === sstateid) {
        cc = 0;
      } else {
        cc = igstTax;
      }
    } else {
      cc = 0;
    }
    return cc;
  }

  const onChangesubcontact = ( e ) => {
    setsupplierSubContactsdata({"id":e.value});
    setsupplierSubContacts_data({value:e.value,label:e.label})

}

    const submitdata = ( ) => {
        setIsLoading(true); 
        let warehouse = { "id" : Number(warehousedata.id)};
        let supplier = { "id" : Number(supplierdata.id)};
        let supplierSubContacts = supplierSubContactsdata;
       let supplierdeliverynote_id = id;
       let igst = igstcheck();
       let cgst = cgstcheck();
       let sgst = SGSTcheck();
       let grandtotal = grandTotal;
       let roundofvalue = round;
       let grossamount = grossAmount;   
       let shippingAddress=shippingaddress;
       const materialreceiptnotesubmitdata = {
           warehouse,supplier,mrndate,receiptnoteno,refernceno,receiptdate,otherreference,status,shippingAddress,remarks,supplierSubContacts,taxtype,supplierdeliverynote_id,materialReceiptNoteItems,
           igst,cgst,sgst,grandtotal,roundofvalue,shippingcharge,grossamount,branch,paymentTerms,voucherid
       };

       axios.post( BASE_URL + 'mrn/sdn/' + id,materialreceiptnotesubmitdata,{
           headers:authHeader()
       }).then(res=>{
           navigate('/MaterialReceiptNoteTable/1')
        // console.log("mello",res.data)
       }).catch(error => {
        console.error('Error adding  :', error);
        if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `
            <div>
              <p id="issueText">Why do I have this issue?</p>
              <ul>
          
              <li id="typer1">You might have an internet issue</li>
              </ul>
            </div>
          `,
          didOpen: () => {
            applyFontSizeTransition('issueText');
          }
        });
      }
      }).finally(() => {
        setIsLoading(false); 
      });
   };

    return (
        <MDBCard className="company">
            <h1>Create Material Receipt Note</h1>
            <Link className='p-2 w-200 card-header card-title bd-highlight' to={`/SupplierDeliveryNoteView/${id}/1`}><Button>Back</Button></Link>

            <Form className="product1">
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Material Receipt Data</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                            <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Branch Name</Form.Label>
                                    <Form.Control type="text" value={branchdata.branchname} readOnly />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">WareHouse Name</Form.Label>
                                    <Form.Control type="text" value={warehousedata.name} disabled />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Supplier Company</Form.Label>
                                    <Form.Control type="text" value={supplierdata.companyname} readOnly />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Contact Name</Form.Label>
                                    <Select options={supplier_SubContactsdata} value={supplierSubContacts_data == null ?"":supplierSubContacts_data} onChange={(e) => onChangesubcontact(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Date</Form.Label>
                                    <Form.Control type="date" value={defaultValue} onChange={(e) => setmrndate(e.target.value)}/>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Terms of Payment</Form.Label>
                                    <Form.Control type='text' value={paymentTerms} name="paymentTerms" onChange={(e) => setpaymentTerms(e.target.value)} autoComplete="off" />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Receipt Note No</Form.Label>
                                    <Form.Control type="text" onChange={(e) => setreceiptnoteno(e.target.value)}/>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Reference No</Form.Label>
                                    <Form.Control type="text" onChange={(e) => setrefernceno(e.target.value)}/>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Receipt Date</Form.Label>
                                    <Form.Control type="date" value={defaultValue1} onChange={(e) => setreceiptdate(e.target.value)}/>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Other Reference</Form.Label>
                                    <Form.Control type="text" onChange={(e) => setotherreference(e.target.value)}/>
                                </Form.Group>
                                {/* <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Type</Form.Label>
                                    <Form.Select value={type} disabled>
                                        <option value="b2b">B2B</option>
                                        <option value="b2c">B2C</option>
                                    </Form.Select>
                                </Form.Group> */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Voucher</Form.Label>
                                    <Form.Control type="text" value={voucherseries} name="voucherseries" readOnly autoComplete="off" />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Status</Form.Label>
                                    <Form.Select value={status} disabled>
                                        <option value='true'>Open</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Tax Type</Form.Label>
                                    <Form.Select value={taxtype} disabled>
                                        <option>--Select--</option>
                                        <option value='withtax'>With Tax</option>
                                        <option value='withouttax'>Without Tax</option>
                                    </Form.Select>
                                </Form.Group>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    {/* <Accordion.Item eventKey="1">
                        <Accordion.Header>Billing Address</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Supplier Address</Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Supplier Details</Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Address : -</Form.Label>
                                    <span>&nbsp;{supplierdata.address}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Name : -</Form.Label>
                                    <span>&nbsp;{supplierSubContactsdata == null?"":supplierSubContactsdata.contactname}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">City : -</Form.Label>
                                    <span>&nbsp;{supplierdata.cities}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Phone No : -</Form.Label>
                                    <span>&nbsp;{supplierSubContactsdata == null?"":supplierSubContactsdata.phoneno}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Country : -</Form.Label>
                                    <span>{"India"}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Email Id : -</Form.Label>
                                    <span>{supplierSubContactsdata == null?"":supplierSubContactsdata.email}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">State : -</Form.Label>
                                    <span>{supplierstate}</span>
                                </Form.Group>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item> */}
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>Shipping Address</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="12" controlId="formBasicName">
                                    <Form.Label className="label">Address</Form.Label>
                                    <Form.Control as="textarea" value={shippingaddress} name='shippingaddress' onChange={(e) => setshippingaddress(e.target.value)} rows={3}  placeholder='Enter Shipping Address' />
                                </Form.Group>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>Details</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="12" controlId="formBasicName">
                                    <Form.Label className="label">Remarks</Form.Label>
                                    <Form.Control as="textarea" value={remarks} rows={3} onChange={(e) => setremarks(e.target.value)} name='remarks' placeholder='Enter Remarks' />
                                </Form.Group>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <br></br>
                <Row className="mb-1">
                    <Form.Group as={Col} md="12" controlId="formBasicName">
                        <Form.Label className="label">Product</Form.Label>
                        <Select options={productdata} onChange={(e) => addData(e)} onInputChange={(e) => customFilter(e) }  />
                        
                    </Form.Group>
                </Row>
                <br></br>
                <Table striped responsive hover size="sm">
                <thead className='p-3 mb-2 bg-success text-white'>
                        <tr>
                            <th style={{ width: '20%' }}>PRODUCT&nbsp;NAME</th>
                            <th style={{ width: '9%' }}>HSN&nbsp;Code</th>
                            <th style={{ width: '8%' }}>GST</th>
                            <th style={{ width: '8%' }}>UOM</th>
                            <th style={{ width: '8%' }}>QTY(PCS)</th>
                            <th style={{ width: '8%' }}>QTY(KG)</th>
                            <th style={{ width: '8%' }}>RATE (₹)</th>
                            <th style={{ width: '8%' }}>UOM</th>
                            <th style={{ width: '8%' }}>GROSS AMOUNT(₹)</th>
                            <th style={{ width: '8%' }}>DISCOUNT %</th>
                            <th style={{ width: '8%' }}>NET AMOUNT (₹)</th>
                            {/* <th>Per&nbsp;Unit&nbsp;Rate</th> */}
                            <th style={{ width: '8%' }}>GST VALUE&nbsp;(₹)</th>
                            <th style={{ width: '8%' }}>TOTAL&nbsp;(₹)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                            {/* <th>View</th> */}
                            <th>Action&nbsp;&nbsp;</th>
                        </tr>
                </thead>
                <tbody style={{ overflow: "scroll" }}>
                        {materialReceiptNoteItems.map(item =>
                        item.sdnitemid == null ?<tr key={item.product.id}>
                             <td>
                                    <Form.Group as={Col} md="12" >
                                        <textarea type="text" value={item.productName} className="form-control textcontrol" size="sm"></textarea>
                                    </Form.Group>
                                    <Form.Control as="textarea" onChange={(e) => { dispatch(changematerialtext(e.target.value, item)) }} value={item.text} rows={2} className='form-control' />
                                </td>
                                <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number"  value={item.hsncode} size="sm" readOnly/>
                                </Form.Group>
                                </td>
                                <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number"  value={item.igst} size="sm" readOnly/>
                                </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                    <Form.Select disabled={item.productType !== "kgproduct" ? true : false} onChange={(e) => { dispatch(materialmeasurement(e.target.value, item, item.unitofmeasurement)); }} size="sm">
                                        <option>{item.productType !== "kgproduct" ? 'PCS' : "Select"}</option>
                                        <option value="pcs">PCS</option>
                                        <option value="kg">KG</option>
                                    </Form.Select>
                                </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                    <Form.Control type="number" min="0" onChange={(e) => { dispatch(materialqtypcs(e.target.value, item, item.unitofmeasurement));roundData(e.target.value) }} disabled={item.unitofmeasurement == "kg" ? true : false} value={item.mrnquantity} size="sm" />
                                </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                    <Form.Control type="number" min="0" onChange={(e) => { dispatch(materialqtykgs(e.target.value, item, item.unitofmeasurement)); roundData(e.target.value)}} disabled={item.unitofmeasurement == "pcs" || item.unitofmeasurement == "box" ? true : false || item.unitofmeasurement == "" ? true : false} value={item.unitofmeasurement == "box" ? "" : item.mrnquantitykgs} size="sm" />
                                </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" size="sm" onChange={(e) => { dispatch(changecostmaterial(e.target.value, item, taxtype));roundData(e.target.value) }} value={item.dlp} />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Select disabled={item.productType !== "kgproduct" ? true : false} onChange={(e) => { dispatch(materialcalmeasurement(e.target.value, item, item.calunitofmeasurement)); roundData(e.target.value)}} size="sm">
                                            <option>{item.productType !== "kgproduct" ? 'PCS' : "Select"}</option>
                                            <option value="pcs">PCS</option>
                                            <option value="kg">KG</option>
                                        </Form.Select>
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" value={Number(item.grossamount)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                    <Form.Control type="number" min="0" onChange={(e) => { dispatch(changematerialdiscount(e.target.value, item, taxtype));roundData(e.target.value) }} value={item.tradeDiscount || ''} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" value={Number(item.total)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" value={Number(item.gstvalue)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" value={Number(item.amount)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type='button' className='btn btn-danger' onClick={() => {dispatch(removefromcartfrommaterial(item.product.id));roundData('')}} value="X" size="sm" />
                                    </Form.Group>
                                </td>

                        </tr>:
                            <tr key={item.sdnitemid}>
                                <td>
                                    <Form.Group as={Col} md="12" >
                                        {/* <Form.Control type="text" value={item.productName} size="sm" /> */}
                                        <textarea type="text" value={item.productName} className="form-control textcontrol" size="sm"></textarea>

                                    </Form.Group>
                                    <Form.Control as="textarea" onChange={(e) => { dispatch(changematerialtext(e.target.value, item)) }} value={item.text} rows={2} className='form-control' />
                                </td>
                                <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number"  value={item.hsncode} size="sm" readOnly/>
                                </Form.Group>
                                </td>
                                <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number"  value={item.igst} size="sm" readOnly/>
                                </Form.Group>
                                </td>
                                <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Select disabled={item.unitofmeasurement == "box" ? true:item.productType !== "kgproduct" ? true:false } value={item.unitofmeasurement} onChange={(e) => { dispatch(materialmeasurement(e.target.value, item, item.unitofmeasurement)); }} size="sm">
                                        <option>{item.unitofmeasurement == "box" ? "PCS":item.productType !== "kgproduct" ? 'PCS':"Select"}</option>
                                        <option value="pcs">PCS</option>
                                        <option value="kg">KG</option>
                                    </Form.Select>
                                </Form.Group>
                                </td>
                                <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" min="0" onChange={(e) => { dispatch(materialqtypcs(e.target.value, item, item.unitofmeasurement));roundData(e.target.value) }} disabled={item.unitofmeasurement == "kg"||item.unitofmeasurement == " " ? true : false} value={item.mrnquantity} size="sm" />
                                </Form.Group>
                                </td>
                                <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" min="0" onChange={(e) => { dispatch(materialqtykgs(e.target.value, item, item.unitofmeasurement));roundData(e.target.value) }}  disabled={item.unitofmeasurement == "pcs" || item.unitofmeasurement == "box" ? true : false || item.unitofmeasurement == "" ? true : false}  value={item.unitofmeasurement == "box" ? "" : item.mrnquantitykgs} size="sm" />
                                </Form.Group>
                                </td>
                                <td>
                                <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" size="sm" onChange={(e) => { dispatch(changecostmaterial(e.target.value, item, taxtype));roundData(e.target.value) }} value={item.dlp} />
                                    </Form.Group>
                                </td>
                                <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Select disabled={item.calunitofmeasurement == "box" ? true:item.productType !== "kgproduct" ? true:false } value={item.calunitofmeasurement} onChange={(e) => { dispatch(materialcalmeasurement(e.target.value, item, item.calunitofmeasurement)); roundData(e.target.value)}} size="sm">
                                        <option>{item.calunitofmeasurement == "box" ? "PCS":item.productType !== "kgproduct" ? 'PCS':"Select"}</option>
                                        <option value="pcs">PCS</option>
                                        <option value="kg">KG</option>
                                    </Form.Select>
                                </Form.Group>
                                </td>
                                <td>
                                <Form.Group as={Col} md="12">
                                        <Form.Control type="text" value={Number(item.grossamount)} size="sm" readOnly/>
                                    </Form.Group>
                                </td>
                                <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" min="0" onChange={(e) => { dispatch(changematerialdiscount(e.target.value, item, taxtype));roundData(e.target.value) }} value={item.tradeDiscount || ''} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                <Form.Group as={Col} md="12">
                                        <Form.Control type="text" value={Number(item.total)} size="sm" readOnly/>
                                    </Form.Group>
                                </td>
                                <td>
                                <Form.Group as={Col} md="12">
                                        <Form.Control type="text" value={Number(item.gstvalue)} size="sm" readOnly/>
                                    </Form.Group>
                                </td>
                                <td>
                                <Form.Group as={Col} md="12">
                                        <Form.Control type="text" value={Number(item.amount)} size="sm" readOnly/>
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        {/* <Form.Control type='button' className='btn btn-danger' onClick={() => dispatch(removefromcartfromsupplierdelivery(item.id))} value="X" size="sm" /> */}
                                    </Form.Group>
                                </td>
                            </tr>
                        )}
                    </tbody>
                <tbody>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td style={{ fontSize: 13 }}>Packing&nbsp;&&nbsp;Forwarding</td>
                        <td><Form.Group as={Col} md="12">
                                        <Form.Control type="number" value={shippingcharge} onChange={(e) => settingship(e.target.value)}   style={{height:30}} />
                                    </Form.Group></td>
                        <td></td>
                        
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td style={{ fontSize: 13 }}>Net&nbsp;Amount</td>
                        <td>
                            <Form.Group as={Col} md="12">
                                <Form.Control type="number" value={grossAmount?((grossAmount).toFixed(2)):"0"} style={{ height: 30 }} readOnly />
                            </Form.Group>
                            </td>
                            <td></td>
                       
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td style={{ fontSize: 13 }}>IGST</td>
                        <td><Form.Group as={Col} md="12">
                            <Form.Control type="number" value={igstcheck()} requied style={{ height: 30 }}/>
                            </Form.Group></td>
                            <td></td>
                        
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td style={{ fontSize: 13 }}>SGST</td>
                        <td><Form.Group as={Col} md="12">
                            <Form.Control type="number" value={SGSTcheck()} requied style={{ height: 30 }}/>
                            </Form.Group></td>
                            <td></td>
                       
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td style={{ fontSize: 13 }}>CGST</td>
                        <td><Form.Group as={Col} md="12">
                            <Form.Control type="number" value={cgstcheck()} requied style={{ height: 30 }}/>
                            </Form.Group></td>
                            <td></td>
                        
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td style={{ fontSize: 13 }}>Round&nbsp;off&nbsp;Value</td>
                        <td><Form.Group as={Col} md="12">
                                        <Form.Control type="number" value={round} onChange={(e) => settinground(e.target.value)}  style={{height:30}} />
                                    </Form.Group></td>
                                    <td></td>
                       
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td style={{ fontSize: 13 }}>Grand&nbsp;Total</td>
                        <td> <Form.Group as={Col} md="12">
                            <Form.Control type="number" value={grandTotal?((grandTotal).toFixed(2)):"0"} readOnly style={{ height: 30 }}/>
                            </Form.Group></td>
                            <td></td>
                        
                    </tr>
                </tbody>
            </Table>
            <Button variant="primary" style={{width:'auto'}} onClick={submitdata} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Submit'}
        </Button>
            </Form>
        </MDBCard>
    )
}