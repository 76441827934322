import axios from "axios";
import authHeader from "./auth-header";

const API_URL = "http://43.225.54.138:8080/scm/api/";



const getStaff = () => {
  return axios.get(API_URL + "staff/", { headers: authHeader() });
};
const getStaffRole = () => {
  return axios.get(API_URL + "role/", { headers: authHeader() });
};
const getStaffEdit = (id) => {
  return axios.get(API_URL + "staff/getById/"+id , { headers: authHeader() });
};
const getStaffcreate = ( staff ) => {
  return axios.post(API_URL + "auth/user/signup",staff, { headers: authHeader() });
};
const getStaffEditData = ( id,staff ) =>{
  return axios.put(API_URL + "staff/update/"+id ,staff, { headers: authHeader() });
}

const getDeleteStaff = ( id ) => {
  return axios.delete(API_URL + "staff/delete/"+id, { headers: authHeader() });
};
const getmapdata = (id) => {
  return axios.get(API_URL + "location/staff/" + id,{headers:authHeader()});
}
const getmapgetdata = (id) => {
  return axios.get(API_URL + "location/" + id,{headers:authHeader()});
}
const StaffService = {
  getStaff,
  getStaffRole,
  getStaffEdit,
  getStaffcreate,
  getStaffEditData,
  getDeleteStaff,
  getmapdata,
  getmapgetdata

};

export default StaffService;