import React from 'react'
import { Mydatatable } from '../Master/Product/Mydatatable';

export const Newbranch = () => {

    let header = [
        { "label": "Id", "field": "id" },
        { "label": "Branch Name", "field": "branchname" },
    
        // { "label": "Address", "field": "address" },
        { "label": "Email", "field": "email" },
        { "label": "GST No", "field": "gstno" },
        { "label": "State Name", "field": "state_name" },
        { "label": "Created Date", "field": "createddate" },
        { "label": "Created Time", "field": "createdtime" },
    //  { "label": "Remarks", "field": "remarks" },
    { "label": "Created By", "field": "createbyname" },
    { "label": "Updated Date", "field": "updateddate" },
    { "label": "Updated Time", "field": "updatedtime" },
    { "label": "Updated By", "field": "updatedbyname" },
        { "label": "Action", "field": "action","subaction1":<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"/><circle cx="12" cy="12" r="3"/></svg> }
      ]; 
      
      const sumofkeys=[{"first":'id'} ]


    //   /Branch/Edit/:id/:pointindex

    // <Route path="/Branch/Create" element={<CompanyBranchCreate />} />
    // <Route path="/Branch/Edit/:id/:pointindex" element={<CompanyBranchEdit />} />
    // <Route path="/Branch/View/:id/:pointindex" element={<CompanyBranchView />} /

    // "address": "Shop on.15, Ground Floor, Gera Imperium Green Neugi Nagar, Mala.",
    // "id": 5,
    // "branchname": "Panjim (PCSS)",
    // "email": "support@pcss.co.in",
    // "gstno": "30AADCP0808M1ZO",
    // "state_name": "Rajasthan"




  return (
    <Mydatatable
    head={header}
    title={" Branch    "}
    axiostabledata={'branch'}
    Add={"Add Branch"}
    // Edit={"/pedit"}
    Edit={"/Branch/View"}
    Create={'/Branch/Create'}
     Sumofkeys={sumofkeys}
    View={"/Branch/View"}
    />



  )
}
