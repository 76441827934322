import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { CSVLink } from 'react-csv';
import DataTable from 'react-data-table-component';
import { Link, useNavigate } from 'react-router-dom';
import { AiOutlineDelete } from 'react-icons/ai';
import { FaUserEdit } from 'react-icons/fa';
import { BsFillEyeFill } from 'react-icons/bs';
import { MDBCard, MDBCardBody } from 'mdb-react-ui-kit';
import { MDBDataTable } from 'mdbreact';
import WorkOrderService from '../../services/WorkOrderService';
import ReatilerService from '../../services/Reatiler';
import AuthService from '../../services/auth.service';
import Swal from 'sweetalert2';
export default function Table() {

  const [posts, setPosts] = useState([]);

  /* Delete Function */
  const getItem = (id) => {

    // WorkOrderService.getDeleteWorkOrder(id).then(res => {
    //   window.location.reload();
    // })
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        WorkOrderService.getDeleteWorkOrder(id).then(res => {
          window.location.reload();
        })
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        )
      }
    })






  };

  /* Reatiler data */
  const [reatilerAll, setreatilerAll] = useState([]);

  const reatilerdataAll = () => {
    ReatilerService.getReatilerTable().then(res => {
      setreatilerAll(res.data)
    });
  };

  /* Table Data */
  const tabledata = () => {
    const user = AuthService.getCurrentUser();
    WorkOrderService.getAllWorkOrder().then((response) => {
      const aaaa = response.data;
      // console.log("mello",aaaa)
      var x = 1;
      let rowsData = []
      for (var index = 0; index < aaaa.length; index++) {

        let rowItem = {}
        rowItem["id"] = aaaa[index].id
        rowItem["index"] = x
        rowItem["workOrderId"] = aaaa[index].workOrderId
        rowItem["workOrderDate"] = aaaa[index].workOrderDate
        // rowItem["retailerId"] = reatilerAll.map(user => user.id == aaaa[index].ret_id ? user.tradeName  : "")
        rowItem["retailerId"]=reatilerAll.filter((item)=>item.id==aaaa[index].ret_id).map((item)=>item.tradeName)
        rowItem['netamount'] = aaaa[index].netValue
        rowItem["total"] = aaaa[index].grossTotal
        rowItem["gstvalue"] = aaaa[index].taxAmount
        rowItem["remarks"] = aaaa[index].remarks
        rowItem["update"] = <div>
          <Link to={`/workorderedit/${aaaa[index].id}`} className="pubutton" id='pb1'>
            <FaUserEdit />
          </Link>  <Link to={`/workorderview/${aaaa[index].id}`} className="pubutton" >
            <BsFillEyeFill />
          </Link> {user.roles == 'ROLE_ADMIN'?<Link onClick={() => getItem(rowItem["id"])} className="pubutton" id='pb'>
            <AiOutlineDelete />
          </Link>:""}
        </div>
         rowsData.push(rowItem);
        x += 1;
      }
    
      setPosts(rowsData);

    })
  }
  useEffect(() => {

    reatilerdataAll();
    tabledata();

  }, [reatilerAll.length]);


  const data = () => {
    return {
      columns: [
        {
          label: 'Id',
          field: 'index',
          width: 100,
        },
        {
          label: '  Order Id',
          field: 'workOrderId',
          width: 100,
        },

        {
          label: '  Order Date',
          field: 'workOrderDate',
          width: 150,
          attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'username',
          },
        },
        {
          label: ' Retailer Name',
          field: 'retailerId',
          width: 270,
        },
        {
          label: ' Net Amount',
          field: 'netamount',
          width: 270,
        },
      
        {
          label: ' GST',
          field: 'gstvalue',
          width: 270,
        },
        {
          label: ' Total',
          field: 'total',
          width: 270,
        },
        {
          label: 'Remarks',
          field: 'remarks',
          sort: 'asc',
          width: 100,
        },

        {
          label: 'Action',
          field: 'update',
          sort: 'disabled',
          width: 100,
        },


      ],
      rows: posts
    };
  };

  return (
    <div  data-aos="fade">

      <MDBCard >
        <h1>WORK ORDER LIST</h1>
        <div className='d-flex bd-highlight'>
          <div className='p-2 w-100 bd-highlight'> <Link to='/workordercreate'> <button class="btn btn-sm btn-secondary">Add </button></Link></div>
          <div className='p-2 flex-shrink-1 bd-highlight'><CSVLink data={posts} class="btn btn-sm btn-primary">Export</CSVLink></div>
        </div>
        <MDBCardBody >
          <MDBDataTable

            hover
            entriesOptions={[5, 10, 15, 20, 25,50,100]}
            entries={10}
            data={data()}
            materialSearch={true}
          />
        </MDBCardBody>
      </MDBCard>


    </div>
  )
}
