import axios from "axios";
import { MDBCard } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { BASE_URL } from "../services/Api";
import authHeader from "../services/auth-header";
import { FaUserEdit } from "react-icons/fa";
import Modal from 'react-bootstrap/Modal';
import { MdDeleteOutline } from "react-icons/md";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

export default function AttendanceTable() {
    const [isLoading, setIsLoading] = useState(false);
    const applyFontSizeTransition = (elementId) => {
      const element = document.getElementById(elementId);
      element.style.transition = 'font-size 2s';
      element.style.fontSize = '30px';
      element.style.fontWeight = '600';
      // font-weight: 600;
      setTimeout(() => {
        //  
        document.getElementById("typer1").classList.add('promote');
        element.style.fontSize = '24px';
      }, 1000);
    };
    const [fromdate, setfromdate] = useState('');
    const [todate, settodate] = useState('');
    const [data, setdata] = useState([]);
    const [data2, setdata2] = useState();
    const [data1, setdata1] = useState(10);
    const [limit, setlimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [product_id, setfeilds] = useState("id");
    const [number, setnumber] = useState([]);
    const [superid, setsuperid] = useState(0);
    const [entries, setentries] = useState([]);
    const [disword, setdisword] = useState(encodeURIComponent(' '))
    const [show, setShow] = useState(false);
    const [dayin,setdayin] = useState('');
    const [dayintime,setdayintime] = useState('');
    const [dayout,setdayout] = useState('');
    const [dayouttime,setdayouttime] = useState('');
    const [status,setstatus] = useState('');
    const [staffname,setstaffname] = useState('');
    const [id,setid] = useState('');
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    let Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

    const handlechange = (entry) => {
        setdata1(entry);
        setlimit(entry);
    };

    const count = [{ value: 10 }, { value: 20 }, { value: 30 }, { value: 50 }, { value: 200 }];

    const getdata = () => {
        if(fromdate&&todate){
        setIsLoading(true)
        axios.get(BASE_URL + `attendance/index/${fromdate}/${todate}/${Number(currentPage) - 1}/${limit}/asc/${product_id}/${disword}`, {
            headers: authHeader()
        }).then(res => {
            setentries(res.data.Enteries);
            setnumber(res.data.Pages);
            setdata(res.data.Index);
        }).catch(error => {
            Swal.fire({
                title: 'There some is some issue',
                text: "You won't be able to revert this!",
                icon: 'warning',
            })
            if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `
                    <div>
                      <p id="issueText">Why do I have this issue?</p>
                      <ul>
                  
                      <li id="typer1">You might have an internet issue</li>
                      </ul>
                    </div>
                  `,
                  didOpen: () => {
                    applyFontSizeTransition('issueText');
                  }
                });
              }


        }).finally(() => {
            setIsLoading(false); 
          });
        }
    }

    useEffect(() => {
        getdata()
    }, [limit, currentPage, product_id, disword])
    const handlePageChange = (page) => {
        setCurrentPage(page);
        setsuperid(page - 1);
        // pagination(page - 1);
    };
    const renderPageNumbers = () => {
        const pages = [];
        const maxPagesToShow = 3;

        for (
            let i = Math.max(0, currentPage - 2);
            i <= Math.min(number - 1, currentPage + 1);
            i++
        ) {
            pages.push(
                <li
                    key={i}
                    className={i === Number(currentPage) ? "newway" : ""}
                    onClick={() => handlePageChange(i)}
                >
                    {i}
                </li>
            );
        }
        // pages+1
        return pages;
    };
    function getdayin(e){
        let a = new Date(e);
        // setdayin(e + " 00:00:00.000000")
        setdayin(a);
    }
    function getdayout(e){
        let a = new Date(e);
        // setdayout(e + " 00:00:00.000000")
        setdayout(a);
    }
    const getupdate = (e) => {
        if (e) {
            // let day_in = new Date(e.dayin).toLocaleDateString('en-CA');
            // let day_out = new Date(e.dayout).toLocaleDateString('en-CA');
            let day_in = new Date(e.dayin);
            let day_out = new Date(e.dayout);
            // setdayin(day_in + " 00:00:00.000000");
            setdayin(day_in);
            setdayintime(e.dayintime);
            // setdayout(day_out + " 00:00:00.000000");
            setdayout(day_out);
            setdayouttime(e.dayouttime);
            setstatus(e.status); 
            setstaffname(e.staff);
            setid(e.id);
        }
    }
    
function getdelete(e){
    // Swal.fire({
    //     title: 'Are you sure?',
    //     text: "You won't be able to revert this!",
    //     icon: 'warning',
    //     showCancelButton: true,
    //     confirmButtonColor: '#3085d6',
    //     cancelButtonColor: '#d33',
    //     confirmButtonText: 'Yes, delete it!'
    //   }).then((result) => {
    //     if (result.isConfirmed) {
      
    //       axios.delete(BASE_URL + '')(id).then(res => {
    //         window.location.reload();
    //       })
    //       Swal.fire(
    //         'Deleted!',
    //         'Your file has been deleted.',
    //         'success'
    //       )
    //     }
    //   })
}
const submitData = () =>{

    let sid = staffname.id;
    let staff = {"id":staffname.id}
    axios.put(BASE_URL + 'attendance/update/'+id,{sid,staff,dayin,dayintime,dayout,dayintime,status,id},{
        headers:authHeader()
    }).then(res => {
       window.location.reload();
       if (res.status == 200) {
        // alert("ee")
        Toast.fire({
          icon: 'success',
          title: 'Successfully Updated'
        })
      }
    }).catch(error => {
        Swal.fire({
            title: 'There is some issue while updating',
            text: "You won't be able to revert this!",
            icon: 'warning',
        })
        if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `
                <div>
                  <p id="issueText">Why do I have this issue?</p>
                  <ul>
              
                  <li id="typer1">You might have an internet issue</li>
                  </ul>
                </div>
              `,
              didOpen: () => {
                applyFontSizeTransition('issueText');
              }
            });
          }


    }).finally(() => {
        setIsLoading(false); 
      });
    // console.log('mello',sid,staff,dayin,dayintime,dayout,dayintime,status,id)
}

// const formatDate = (dateString) => {
//     if (!dateString) return "";  

//     const date = new Date(dateString);
//     if (isNaN(date.getTime())) return "";  

//     const options = { day: 'numeric', month: 'long', year: 'numeric' };
//     return date.toLocaleDateString(undefined, options);
// }
const formatDate = (dateString) => {
    if (!dateString) return "";

    const date = new Date(dateString);
    if (isNaN(date.getTime())) return ""; 

    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
console.log( `${day} ${month} ${year}`)
    return `${day} ${month} ${year}`;
}


    return (
        <MDBCard className="company">
            <div className='d-flex bd-highlight'>

            <div className="card-header card-title w-100">Attendance Table</div>
            <Link className='p-2 w-200 card-header  btn-sm card-title bd-highlight' to={`/AttendanceCreate`}><Button className="btn-sm">Add</Button></Link>
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Update</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="product1">
                        <Row className="mb-1">
                        <Form.Group as={Col} md="6" controlId="formBasicName">
                                <Form.Label className="label">Staff Name</Form.Label>
                                <Form.Control type='text'  value={staffname.staffName} readOnly autoComplete="off" />
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="formBasicName">
                                <Form.Label className="label">Day In</Form.Label>
                                <Form.Control type='date' name="dayin" value={dayin == ''?"":new Date(dayin).toLocaleDateString('en-CA')} onChange={(e) => getdayin(e.target.value)} autoComplete="off" />
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Day In Time</Form.Label>
                        <Form.Control type='time' name="dayintime" value={dayintime} onChange={(e) => setdayintime(e.target.value)} autoComplete="off" />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Day Out</Form.Label>
                        <Form.Control type='date' name="dayout" value={dayout == ""?"":new Date(dayout).toLocaleDateString('en-CA')} onChange={(e) => getdayout(e.target.value)} autoComplete="off" />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Day Out Time</Form.Label>
                        <Form.Control type='time' name="dayouttime" value={dayouttime} onChange={(e) => setdayouttime(e.target.value)} autoComplete="off" />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Status</Form.Label>
                        <Form.Select value={status} onChange={(e) => setstatus(e.target.value)}>
                            <option>Select</option>
                            <option value={'P'}>Present</option>
                            <option value={'HD'}>Half-Day</option>
                            <option value={'H'}>Holiday</option>
                            <option value={'HP'}>Holiday-Present</option>
                            <option value={'WO'}>Week-Off</option>
                            <option value={'WOP'}>Week-Off-Present</option>
                            <option value={'L'}>Leave</option>
                        </Form.Select>
                    </Form.Group>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={submitData}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
            <Form className="product1">
                <Row className="mb-1">
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">From Date</Form.Label>
                        <Form.Control type='date' name="fromdate" onChange={(e) => setfromdate(e.target.value)} autoComplete="off" />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">To Date</Form.Label>
                        <Form.Control type='date' name="todate" onChange={(e) => settodate(e.target.value)} autoComplete="off" />
                    </Form.Group>
                </Row>
                {/* <Button onClick={getdata}>Submit</Button> */}
                <Button variant="primary" style={{width:'auto'}} onClick={getdata} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Submit'}
        </Button>
            </Form>
            <br></br>
            <div className="forentriesandaddbutton">
                <div className="bottole">
                    <p>Show entries</p>
                    <select
                        className="newselect"
                        onChange={(e) => handlechange(e.target.value)}
                    >
                        {count.map((item) => {
                            return <option>{item.value}</option>;
                        })}
                    </select>
                </div>
            </div>
            <Table>
                <thead>
                    <tr>
                        <th>From Date</th>
                        <th>To Date</th>
                        <th>Begin Time</th>
                        <th>End Time</th>
                        <th>EMP Name</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <thead>
    {data.map(user => (
        <tr key={user.id}>
            <td>{formatDate(user.dayin)}</td>
            <td>{user.dayout ? formatDate(user.dayout) : ""}</td>
            <td>{user.dayintime}</td>
            <td>{user.dayouttime}</td>
            <td>{user.staff == null ? "" : user.staff.staffName}</td>
            <td>{user.status}</td>
            <td>
                <button onClick={() => { getupdate(user); handleShow() }} style={{ border: '0px', fontSize: '17px', color: 'green' }}>
                    <FaUserEdit />
                </button>
                <button onClick={() => { getdelete(user.id); }} style={{ border: '0px', color: 'red' }}>
                    <MdDeleteOutline />
                </button>
            </td>
        </tr>
    ))}
</thead>

            </Table>
            <div className="flex">
                <div className="showentri">
                    {data1 == 10 ? (
                        <p>
                            Showing {superid * data1 + 1} to {superid * data1 + 10} of{" "}
                            {entries} entries
                        </p>
                    ) : (
                        ""
                    )}
                    {data1 == 20 ? (
                        <p>
                            Showing {superid * data1 + 1} to {superid * data1 + 20} of{" "}
                            {entries} entries
                        </p>
                    ) : (
                        ""
                    )}
                    {data1 == 30 ? (
                        <p>
                            Showing {superid * data1 + 1} to {superid * data1 + 30} of{" "}
                            {entries} entries
                        </p>
                    ) : (
                        ""
                    )}
                    {data1 == 50 ? (
                        <p>
                            Showing {superid * data1 + 1} to {superid * data1 + 50} of{" "}
                            {entries} entries
                        </p>
                    ) : (
                        ""
                    )}
                </div>
                <div className="pagination">
                    <ul className="pagination">
                        <li
                            onClick={() =>
                                handlePageChange(currentPage > 1 ? currentPage - 1 : 1)
                            }
                        >
                            {"Previous"}
                        </li>
                        {renderPageNumbers()}
                        {number}
                        <li
                            onClick={() =>
                                handlePageChange(
                                    currentPage == number ? number : currentPage + 1
                                )
                            }
                        >
                            {"Next"}
                        </li>
                    </ul>
                </div>
            </div>
        </MDBCard>
    )
}