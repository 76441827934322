import axios from "axios";
import { MDBCard } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { BASE_URL } from "../../services/Api";
import authHeader from "../../services/auth-header";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

export default function Create(){
    const [brandAll,setbrandAll] = useState([]);
    const [brand,setbrand] = useState('');
    const [remarks,setremarks] = useState('');
    const [add, setAdd] = useState([]);
    const [status,setstatus] = useState(false);
    const [productAll, setProductAll] = useState([]);
    const [disword, setdisword] = useState(encodeURIComponent(' '));
    const [isLoading, setIsLoading] = useState(false);

    const applyFontSizeTransition = (elementId) => {
        const element = document.getElementById(elementId);
        element.style.transition = 'font-size 2s';
        element.style.fontSize = '30px';
        element.style.fontWeight = '600';
        // font-weight: 600;
        setTimeout(() => {
           
          document.getElementById("typer1").classList.add('promote');
          element.style.fontSize = '24px';
        }, 1000);
      };

    const getbranddata = () => {
        axios.get(BASE_URL + 'brand/getAll',{
            headers:authHeader()
        }).then(res=>{
           setbrandAll(res.data.map(user=>({value:user.id,label:user.brandName})))
        })
    };

    useEffect(() =>{
        getbranddata()
    },[]);

    const AddRow = () => {
        setdisword(encodeURIComponent(' '));
        if(status == false){
            Swal.fire({
                title: 'Please Select Brand',
                text: "You won't be able to revert this!",
                icon: 'warning',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
              })
        } else{
        const rowsInput = {
            id: add.length + 1,
            product: '',
            pcs: '',
            kg: '',
        }
        setAdd([...add, rowsInput])
    }
    }

    const deleteItem = (e) => {
        setbrand({"id":e.value});
        if(e){
            setstatus(true);
        }



    }
    useEffect(()=>{
        axios.get(BASE_URL + `product/page/0/100/asc/id/${disword}`, { headers: authHeader() })
        .then(res => {
      if(res.data.data==undefined){
        setProductAll((res.data.Index).map(use => ({ value: use.id, label: use.product_Name  })));
     }  if(res.data.Index==undefined){
      setProductAll((res.data.data).map(use => ({ value: use.id, label: use.product_Name })));
    
     }
        
    })
    },[disword]);
    const deleteItem1 = (index, evnt) => {
        setdisword(encodeURIComponent(' '))
        const { label, value } = evnt;
        const name = "product";
        const product_name = "productname";
        const rowsInput = [...add];
        rowsInput[index][name] = {"id":value};
        rowsInput[index][product_name] = label;
        setAdd(rowsInput);
    };

    const deleteTableRows = (index) => {
        const remainingitem = add.filter(obj => obj.id !== index)
        setAdd(remainingitem);
    }
    const qtypcschange = (index,e,row) => {
        const { name, value } = e.target;
        const rowsInput = [...add];
        rowsInput[index][name] = value;
        setAdd(rowsInput);
    }
    const qtykgchange = (index,e,row) => {
        const { name, value } = e.target;
        const rowsInput = [...add];
        rowsInput[index][name] = value;
        setAdd(rowsInput);
    }
    const navigate = useNavigate();
    const submitform = () => {
        setIsLoading(true); 

        let repackingItems = add.map(user=>(
            {"product":user.product,
            "pcs":user.pcs,
            "qty":user.kg,
        "productname":user.productname}
        ));
        // console.log('mello',{bomItems})
        axios.post(BASE_URL + 'repacking/',{brand,remarks,repackingItems},{headers:authHeader()}).then(res=>{
            // console.log('mello',res.data)
            navigate("/Repacking/Print/1",{ state: { "Printdata":res.data } })
        }) .catch(error => {
            console.error('Error adding  :', error);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `
                <div>
                  <p id="issueText">Why do I have this issue?</p>
                  <ul>
                  <li id="typer">You must be filling something incorrectly or
      </li>
                  <li id="typer1">You might have an internet issue</li>
                  </ul>
                </div>
              `,
              didOpen: () => {
                applyFontSizeTransition('issueText');
              }
            });
      
          })
          .finally(() => {
            setIsLoading(false); 
          });
    };
    
    return(
        <MDBCard className="company">
           <h1>Add Repacking</h1>
           <Form className="product1">
                <Row className="mb-1">
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Brand Name</Form.Label>
                        <Select options={brandAll} onChange={(e) =>deleteItem(e)}/>
                    </Form.Group>
                    {/* <Form.Group as={Col} md="12" controlId="formBasicName">
                        <Form.Label className="label">Remarks</Form.Label>
                        <Form.Control as="textarea" rows={3} name='remarks' onChange={(e) => setremarks(e.target.value)} placeholder='Enter Remarks' />
                    </Form.Group> */}
                </Row>
            </Form>
            <table>
                <thead>
                    <tr>
                        <th>No</th>
                        <th>Product&nbsp;Name</th>
                        <th>QTY(PCS)</th>
                        <th>QTY(KG)</th>
                        <th><button className="btn btn-outline-success" onClick={AddRow} >+</button></th>
                    </tr>
                </thead>
                <tbody>
                    {add.map((row, index) => (
                        <tr key={row.id}>
                            <td>{row.id}</td>
                            <td><Form.Group as={Col} md="12" controlId="formBasicName">
                                <Select options={productAll} onChange={(options) => deleteItem1(index, options)} onInputChange={(inputValue) => setdisword(inputValue)} />
                            </Form.Group></td>
                             <td>
                                <Form.Group as={Col} md="12" controlId="formBasicName">
                                <Form.Control type="number" min="0" name="pcs" size="sm" onChange={(e)=>qtypcschange(index,e,row)} requied />
                            </Form.Group>
                            </td>
                            <td>
                                <Form.Group as={Col} md="12" controlId="formBasicName">
                                <Form.Control type="number" min="0" name="kg" size="sm" onChange={(e)=>qtykgchange(index,e,row)} requied />
                            </Form.Group>
                            </td>
                            <td><button className="btn btn-outline-danger" onClick={() => (deleteTableRows(row.id))}>x</button></td> 
                        </tr>
                    ))}

                </tbody>
            </table>
            {/* <Button onClick={submitform}>Submit</Button> */}
            <Button variant="primary" onClick={submitform} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Submit'}
        </Button>
        </MDBCard>
    )
}