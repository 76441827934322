import axios from 'axios'
import { MDBCard } from 'mdbreact'
import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Select from 'react-select'
import { BASE_URL } from '../services/Api'
import authHeader from '../services/auth-header'
import Swal from 'sweetalert2'

export default function Edit() {
    const { id } = useParams();
const{pointindex}=useParams();
const [isLoading, setIsLoading] = useState(false);
const applyFontSizeTransition = (elementId) => {
  const element = document.getElementById(elementId);
  element.style.transition = 'font-size 2s';
  element.style.fontSize = '30px';
  element.style.fontWeight = '600';
  // font-weight: 600;
  setTimeout(() => {
     
    document.getElementById("typer1").classList.add('promote');
    element.style.fontSize = '24px';
  }, 1000);
};
    const [ branchname, setbranchname ] = useState('');
    const [ phoneno, setphoneno ] = useState('');
    const [ gstno, setgstno ] = useState('');
    const [ email, setemail ] = useState('');
    const [ address, setaddress ] = useState('');
    const [ cities, setcities ] = useState('');
    const [ accountno, setaccountno ] = useState('');
    const [ bankname, setbankname ] = useState('');
    const [ branch, setbranch ] = useState('');
    const [ ifsc, setifsc ] = useState('');
    const [ states, setStates ] = useState('');
    const [ statesdata, setStatesdata ] = useState([]);

    const navigate = useNavigate();

    const getBranchData = ( ) => {
        axios.get(BASE_URL + 'branch/'+id,{
            headers:authHeader()
        }).then(res=>{
            // console.log("mello",res.data);
            setbranchname(res.data.branchname);
            setphoneno(res.data.phoneno);
            setgstno(res.data.gstno);
            setemail(res.data.email);
            setaddress(res.data.address);
            setcities(res.data.cities);
            setaccountno(res.data.accountno);
            setbankname(res.data.bankname);
            setbranch(res.data.branch);
            setifsc(res.data.ifsc);
            setStates({"id":res.data.states.id});
            setStatesdata({value:res.data.states.id,label:res.data.states.name})
        })
    };

    useEffect(() => {
        getBranchData()
    },[]);
    const getgstdetails=()=>{
        let cap
        axios.get(`http://43.225.54.138:8080/scm/gst/gstin/${gstno}`, { headers: authHeader() })
        .then(response => {
            console.log(response.data)
            // setGstdetails(response.data)
            axios.get(`http://43.225.54.138:8080/scm/api/state
            `, { headers: authHeader() })
            .then(response1 => {
                let statename=response1.data.filter((item)=>item.state_code==response.data.StateCode).map((item)=>item.name).toString()
                // setgstate(statename)
                setaddress(response.data.AddrBnm+" "+response.data.AddrBno+" "+response.data.AddrFlno+" "+response.data.AddrLoc+" "+response.data.AddrSt+" "+response.data.AddrPncd+" "+statename)

            })
            .catch(error => {
              console.error(error);
            });
            
        })
        .catch(error => {
          console.error(error);
        });


    }
    let Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
    const submitdata = ( ) => {
        setIsLoading(true); 
        let country = {"id":101};
        axios.put( BASE_URL + 'branch/' + id,{
            branchname,phoneno,gstno,email,address,country,states,cities,accountno,bankname,branch,ifsc
        },{
            headers:authHeader()
        }).then(res=>{
            Toast.fire({
                icon: 'success',
                title: 'Successfully Updated'
              })
            navigate(`/Branch/Table/${pointindex}`)
        }).catch(error => {
            console.error('Error adding  :', error);
            if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `
                <div>
                  <p id="issueText">Why do I have this issue?</p>
                  <ul>
              
                  <li id="typer1">You might have an internet issue</li>
                  </ul>
                </div>
              `,
              didOpen: () => {
                applyFontSizeTransition('issueText');
              }
            });
          }
          })
            .finally(() => {
              setIsLoading(false); 
            });
    }
  return (
    <MDBCard className="company">
    <h1>Update Branch</h1>
    <Link  to={`/Branch/Table/${pointindex}`}><Button className='btn btn-sm btn-secondary'>Back</Button></Link>

    <Form className="product1">
        <Row className="mb-1">
            <Form.Group as={Col} md="6" controlId="formBasicName">
                <Form.Label className="label">Branch Name</Form.Label>
                <Form.Control type="text" value={branchname} name='branchname' onChange={(e) => setbranchname(e.target.value)} />
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="formBasicName">
                <Form.Label className="label">Phone No</Form.Label>
                <Form.Control type="text" value={phoneno} name='phoneno' onChange={(e) => setphoneno(e.target.value)} />
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="formBasicName">
                <Form.Label className="label">GST No</Form.Label>
                <Form.Control type="text"  value={gstno} name='gstno' onChange={(e)=>setgstno(e.target.value)} />
            </Form.Group>
            <a className="btn btn-sm ertere" onClick={getgstdetails}>Get Gst Details</a>

            <Form.Group as={Col} md="6" controlId="formBasicName">
                <Form.Label className="label">Email</Form.Label>
                <Form.Control type="text" value={email} name='email' onChange={(e) => setemail(e.target.value)}  />
            </Form.Group>
            <Form.Group as={Col} md="12" controlId="formBasicName">
                <Form.Label className="label">Address</Form.Label>
                <Form.Control as="textarea" value={address} onChange={(e) => setaddress(e.target.value)} rows={3} name='address' placeholder='Address'  />
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="formBasicName">
                <Form.Label className="label">Country</Form.Label>
                <Form.Control type="text"  value={"India"} disabled/>
            </Form.Group>                    
            <Form.Group as={Col} md="6" controlId="formBasicName">
                <Form.Label className="label">State</Form.Label>
                <Select value={statesdata} />
            </Form.Group>                    
            <Form.Group as={Col} md="6" controlId="formBasicName">
                <Form.Label className="label">City</Form.Label>
                <Form.Control type="text" value={cities} name='cities' onChange={(e) => setcities(e.target.value)} />
            </Form.Group>                    <Form.Group as={Col} md="6" controlId="formBasicName">
                <Form.Label className="label">Account No</Form.Label>
                <Form.Control type="text" value={accountno} name='accountno' onChange={(e) => setaccountno(e.target.value)} />
            </Form.Group>         
                       <Form.Group as={Col} md="6" controlId="formBasicName">
                <Form.Label className="label">Bank Name</Form.Label>
                <Form.Control type="text" value={bankname} name='bankname' onChange={(e)=>setbankname(e.target.value)}  />
            </Form.Group>              
                  <Form.Group as={Col} md="6" controlId="formBasicName">
                <Form.Label className="label">Branch Name</Form.Label>
                <Form.Control type="text" value={branch} name='branch' onChange={(e)=>setbranch(e.target.value)}  />
            </Form.Group>           
                     <Form.Group as={Col} md="6" controlId="formBasicName">
                <Form.Label className="label">IFSC Code</Form.Label>
                <Form.Control type="text" value={ifsc} name='ifsc' onChange={(e)=>setifsc(e.target.value)} />
            </Form.Group>
        </Row>
        <Button variant="primary" style={{width:'auto'}} onClick={submitdata} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Submit'}
        </Button>
    </Form>
</MDBCard>
  )
}
