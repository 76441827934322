import axios from "axios";
import { MDBCard } from "mdbreact";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../services/Api";
import authHeader from "../../services/auth-header";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import AuthService from "../../services/auth.service";

export default function Create() {
  const [remarks, setremarks] = useState('');
  const [formdate, setformdate] = useState('');
  const navigate = useNavigate();
  const [supplier, setSupplier] = useState('');

  let Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })
  // const getsupllierdata = () => {
  //     axios.get(BASE_URL + 'supplier/getAll', {
  //         headers: authHeader()
  //     }).then(res => {
  //         setstaffAll((res.data).map(user => ({ value: user.id, label: user.companyname })));
  //     })
  // }

  // useEffect(() => {
  //     getsupllierdata()
  // }, []);
  const [isLoading, setIsLoading] = useState(false);
  const applyFontSizeTransition = (elementId) => {
    const element = document.getElementById(elementId);
    element.style.transition = 'font-size 2s';
    element.style.fontSize = '30px';
    element.style.fontWeight = '600';
    // font-weight: 600;
    setTimeout(() => {

      document.getElementById("typer1").classList.add('promote');
      element.style.fontSize = '24px';
    }, 1000);
  };
  const user = AuthService.getCurrentUser();
  const submitdata = () => {
    setIsLoading(true);

    axios.post(BASE_URL + 'assetreqform/save', { remarks, formdate,staff:{id:supplier} }, {
      headers: authHeader()
    }).then(res => {
      navigate('/Asset Request Form/Table/1');
      if (res.status == 201 || res.status == 200) {
        // alert("ee")
        Toast.fire({
          icon: 'success',
          title: 'Successfully added'
        })
      }
    }).catch(error => {
      console.error('Error adding  :', error);
      if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `
                <div>
                  <p id="issueText">Why do I have this issue?</p>
                  <ul>
              
                  <li id="typer1">You might have an internet issue</li>
                  </ul>
                </div>
              `,
          didOpen: () => {
            applyFontSizeTransition('issueText');
          }
        });
      }
    })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const [disword, setdisword] = useState(encodeURIComponent(' '))
  const [dis, setdistributordata1] = useState([])

  useEffect(() => {

      axios.get(`http://43.225.54.138:8080/scm/api/staff/page/0/10/asc/id/${disword.length ? disword : encodeURIComponent(' ')}`, { headers: authHeader() })
          .then(response => {
              console.log(response)
              if (response.data.data == undefined) {
                  setdistributordata1(response.data.Index.map(use => ({ value: use.id, label: use.staff_Name })));
              } if (response.data.Index == undefined) {
                  setdistributordata1(response.data.data.map(use => ({ value: use.id, label: use.staff_Name })));

              }

              //   console.log(response.data.data.map(use => ({ value: use.id, label: use.suppliername})))  
          })

      /** Voucher GetAll */

      

  }, [disword])
  const onChangeSupplier = (e) => {
    // let name=
    setSupplier(  e.value );
   
};


  return (
    <MDBCard className="company">
      <h1>Add Asset Request Form</h1>
      <Form className="product1">
        <Row className="mb-1">
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="label">Staff Name</Form.Label>
            <Select options={dis} onInputChange={(inputValue) => setdisword(inputValue)} onChange={(options) => onChangeSupplier(options)} />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="label">Date</Form.Label>
            <Form.Control type="date" onChange={(e) => setformdate(e.target.value)} />
          </Form.Group>
          <Form.Group as={Col} md="12" controlId="formBasicName">
            <Form.Label className="label">Remarks</Form.Label>
            <Form.Control as="textarea" rows={3} value={remarks} name='remarks' onChange={(e) => setremarks(e.target.value)} placeholder='Enter Remarks' autoComplete="off" />
          </Form.Group>

        </Row><br></br>
        {/* <Row className="mb-1">
                    <Form.Group as={Col} md="12" controlId="formBasicName">
                        <Form.Label className="label">Assest</Form.Label>
                        <Select   />
                    </Form.Group>
                </Row> */}
        {/* <br></br>
                <Table striped responsive hover size="sm">
                <thead className='p-3 mb-2 bg-success text-white'>
                        <tr>
                            <th>Assest Name</th>
                            <th>Qty</th>
                            <th>Rate</th>
                            <th>Amount</th>
                        </tr>
                        </thead>
                </Table> */}
      </Form>
      {/* <Button variant="primary" onClick={submitdata} type="button" >
                    Submit
                </Button> */}
      <Button variant="primary" onClick={submitdata} disabled={isLoading}>
        {isLoading ? 'Loading...' : 'Submit'}
      </Button>
    </MDBCard>
  )
}