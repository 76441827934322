import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { BASE_URL } from '../../services/Api';
import authHeader from '../../services/auth-header';
import Swal from 'sweetalert2';

export default function Update() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [user_date, setuser_date] = useState('');
    const [inwardDistributorStockItems, setinwardDistributorStockItems] = useState([]);
    const [distributor, setdistributor] = useState('');
    const [dist_name, setdist_name] = useState('');
    const [distItems, setdistItems] = useState('');
    const [quantity, setquantity] = useState('');

    const applyFontSizeTransition = (elementId) => {
        const element = document.getElementById(elementId);
        element.style.transition = 'font-size 2s';
        element.style.fontSize = '30px';
        element.style.fontWeight = '600';
        // font-weight: 600;
        setTimeout(() => {

            document.getElementById("typer1").classList.add('promote');
            element.style.fontSize = '24px';
        }, 1000);
    };

    const handleClose = () => {
        setShow(false)
        navigate('/Distributor/Inward/Table/1')
    };

    const handleShow = () => setShow(true);

    const getdistoutwarddata = () => {
        axios.get(BASE_URL + 'indiststock/' + id, {
            headers: authHeader()
        }).then(res => {
            // console.log('mello',res.data)
            setuser_date(res.data.indate);
            setdistributor(res.data.distributorid);
            setinwardDistributorStockItems(res.data.inwardDistributorStockItems);
            setdistItems(res.data.inwardDistributorStockItems[0].product.productName);
            setquantity(res.data.inwardDistributorStockItems[0].inwardqty)

            let dist_id = res.data.distributorid;

            axios.get(BASE_URL + 'distributor/getById/' + dist_id, { headers: authHeader() }).then(res1 => {
                // console.log('mello',res1.data);
                setdist_name(res1.data.tradeName)
            })
        })
    }

    useEffect(() => {
        getdistoutwarddata();
        handleShow()
    }, []);

    const onChangeproduct = (e) => {
        let name = e;
        setquantity(e);
        setinwardDistributorStockItems(inwardDistributorStockItems.map(user => ({
            "rate": user.rate,
            'amount': (user.rate) * name,
            "product": { "id": user.product.id },
            'inwardqty': name

        })))
    }

    const handlesupersubmit = () => {
        let indate = user_date;
        let postData = {
            distributorid: distributor,
            indate,
            inwardDistributorStockItems
        };
        setIsLoading(true);

        axios.put(BASE_URL + 'indiststock/' + id, postData, { headers: authHeader() }).then(res => {
            console.log('mello', res.data)
        }).catch(error => {
            console.error('Error adding  :', error);
            if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `
              <div>
                <p id="issueText">Why do I have this issue?</p>
                <ul>
            
                <li id="typer1">You might have an internet issue</li>
                </ul>
              </div>
            `,
                    didOpen: () => {
                        applyFontSizeTransition('issueText');
                    }
                });
            }
        })
            .finally(() => {
                setIsLoading(false);
            });

    };

    return (
        <div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Update</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className='col'>
                            <label className='form-label'>Distrbutor Name</label>
                            <input type='text' value={dist_name} className='form-control' readOnly />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <label className='form-label'>Product</label>
                            <input type='text' value={distItems} className='form-control' readOnly />
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col'>
                            <label className='form-label' >Date</label>
                            <input type='Date' value={user_date} className='form-control' readOnly />
                        </div>
                        <div className='col'>
                            <label className='form-label'>Qty</label>
                            <input type='number' value={quantity} name='quantity' onChange={(e) => onChangeproduct(e.target.value)} className='form-control' />
                        </div>
                    </div>



                </Modal.Body>
                <Modal.Footer>
                    <a className="btn btn-sm btn-secondary" variant="secondary" onClick={handleClose}>
                        Close
                    </a>
                    <Button variant="primary" style={{ width: 'auto' }} onClick={() => { handleClose(); handlesupersubmit() }} disabled={isLoading}>
                        {isLoading ? 'Loading...' : 'Submit'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
