
import { GET_EDIT_DATA_TO_LOAD_WORKORDER } from "../constant";
import { EDIT_ADD_TO_CART_WORKORDER } from "../constant";
import { CHANGE_EDIT_MEASUREMENT_WORKORDER } from "../constant";
import { REMOVE_FROM_CART_FROM_EDIT_WORKORDER } from "../constant";
import { CHANGE_COST_EDIT_WORKORDER } from "../constant";
import { CHANGE_EDIT_DISCOUNT_WORKORDER } from "../constant";
import { CHANGE_EDIT_TEXT_WORKORDER } from "../constant";
import { EMPTY_EDIT_DATA_WORKORDER } from "../constant";
import { EDIT_CHANGE_PER_WORKORDER } from "../constant";
import { CHANGE_EDIT_DISCOUNT1_WORKORDER } from "../constant";
import { CHANGE_EDIT_QTY_WORKORDER } from "../constant";
import { CHANGE_STAFFCANCLEQTY } from "../constant";
import { CHANGE_RETAILERCANCLEQTY } from "../constant";

export const geteditdatatoloadworkorder = (data) => {

    //  console.warn("action called", data);
    return {

        type : GET_EDIT_DATA_TO_LOAD_WORKORDER,
        data : data
    }

}

export const changestaffcancleqty = (data,item) =>{
     return {

        type : CHANGE_STAFFCANCLEQTY,
        data : data,
        item :item
     }
};

export const changeretailercancleqty = (data,item) => {

    return {
        type :CHANGE_RETAILERCANCLEQTY,
        data : data,
        item : item
    }
}
export const workordereditaddtocart = (data,data2) => {

    //  console.warn("action called", data);
    return {

        type : EDIT_ADD_TO_CART_WORKORDER,
        data : data,
        data2 : data2
    }

}



export const removefromcartfromworkorderedit = (data) => {

    console.warn("action REMOVE_TO_CART called", data);
    return {

        type : REMOVE_FROM_CART_FROM_EDIT_WORKORDER,
        data : data
    }

}



export const changeworkordereditunitOfMeasurement = (data, item, data2) => {

    console.warn("qty change called66", data);
    return {

        type : CHANGE_EDIT_MEASUREMENT_WORKORDER,
        data : data,
        item : item,
        data2 : data2
    }

}

export const perworkorderedit = (data, item, data2) => {

    console.warn("qty change called5", data);
    return {

        type : EDIT_CHANGE_PER_WORKORDER,
        data : data,
        item : item,
        data2 : data2
    }

}
export const qtyworkorderedit = (data, item, data2) => {

    console.warn("qty change called5", data);
    return {

        type : CHANGE_EDIT_QTY_WORKORDER,
        data : data,
        item : item,
        data2 : data2
    }

}

export const changecostworkorderedit = (data, item, data2) => {

    // console.warn("cost change called", data);
    return {

        type : CHANGE_COST_EDIT_WORKORDER,
        data : data,
        item : item,
        data2 : data2

    }

}


export const changeworkordereditdiscount = (data, item, data2) => {

    // console.warn("discount change called", data);
    return {

        type : CHANGE_EDIT_DISCOUNT_WORKORDER,
        data : data,
        item : item,
        data2 : data2

    }

}

export const changeworkordereditdiscount1 = (data, item, data2) => {

    // console.warn("discount change called", data);
    return {

        type : CHANGE_EDIT_DISCOUNT1_WORKORDER,
        data : data,
        item : item,
        data2 : data2

    }

}

export const changeworkorderedittext = (data, item) => {

    // console.warn("text change called", data);
    return {

        type : CHANGE_EDIT_TEXT_WORKORDER,
        data : data,
        item : item
    }

}


export const emptyworkordereditdata = () => {

    return {

        type : EMPTY_EDIT_DATA_WORKORDER,
    }

}