import axios from "axios";
import { MDBCard } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { BASE_URL } from "../../services/Api";
import authHeader from "../../services/auth-header";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

export default function Create() {
    const [title, settitle] = useState('');
    const [group1, setgroup1] = useState('');
    const [group1data, setgroup1data] = useState([]);
    const [group2, setgroup2] = useState('');
    const [group2data, setgroup2data] = useState([]);
    const [group3, setgroup3] = useState('');
    const [group3data, setgroup3data] = useState([]);
    const [group4, setgroup4] = useState('');
    const [group4data, setgroup4data] = useState([]);
    const [group5, setgroup5] = useState('');
    const [group5data, setgroup5data] = useState([]);
    const [group6, setgroup6] = useState('');
    const [group6data, setgroup6data] = useState([]);
    const [group7, setgroup7] = useState('');
    const [group7data, setgroup7data] = useState([]);
    const [group8, setgroup8] = useState('');
    const [group8data, setgroup8data] = useState([]);
    const [group9, setgroup9] = useState('');
    const [group9data, setgroup9data] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [disword, setdisword] = useState(encodeURIComponent(' '));
    /** Navigation */
    const navigate = useNavigate();

    const applyFontSizeTransition = (elementId) => {
        const element = document.getElementById(elementId);
        element.style.transition = 'font-size 2s';
        element.style.fontSize = '30px';
        element.style.fontWeight = '600';
        // font-weight: 600;
        setTimeout(() => {

            document.getElementById("typer1").classList.add('promote');
            element.style.fontSize = '24px';
        }, 1000);
    };

    let Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });

    /** get Group1 & Group2 & Group3 & Group4 & Group5 & Group6 & Group7 & Group8  */
    useEffect(() => {
        /** Group1 */
        axios.get(BASE_URL + `group1/page/0/10/asc/id/${disword.length ? disword : encodeURIComponent(' ')}`, {
            headers: authHeader()
        }).then(res => {
            if (res.data.data == undefined) {
                setgroup1data(res.data.Index.map(use => ({ value: use.id, label: use.title })));
            } if (res.data.Index == undefined) {
                setgroup1data(res.data.data.map(use => ({ value: use.id, label: use.title })));

            }
        });

        /** Group2 */
        axios.get(BASE_URL + `group2/page/0/10/asc/id/${disword.length ? disword : encodeURIComponent(' ')}`, {
            headers: authHeader()
        }).then(res => {
            if (res.data.data == undefined) {
                setgroup2data(res.data.Index.map(use => ({ value: use.id, label: use.title })));
            } if (res.data.Index == undefined) {
                setgroup2data(res.data.data.map(use => ({ value: use.id, label: use.title })));

            }
        });

        /** Group3 */
        axios.get(BASE_URL + `group3/page/0/10/asc/id/${disword.length ? disword : encodeURIComponent(' ')}`, {
            headers: authHeader()
        }).then(res => {
            if (res.data.data == undefined) {
                setgroup3data(res.data.Index.map(use => ({ value: use.id, label: use.title })));
            } if (res.data.Index == undefined) {
                setgroup3data(res.data.data.map(use => ({ value: use.id, label: use.title })));

            }
        });

        /** Group4 */
        axios.get(BASE_URL + `group4/page/0/10/asc/id/${disword.length ? disword : encodeURIComponent(' ')}`, {
            headers: authHeader()
        }).then(res => {
            if (res.data.data == undefined) {
                setgroup4data(res.data.Index.map(use => ({ value: use.id, label: use.title })));
            } if (res.data.Index == undefined) {
                setgroup4data(res.data.data.map(use => ({ value: use.id, label: use.title })));

            }
        });

        /** Group5 */
        axios.get(BASE_URL + `group5/page/0/10/asc/id/${disword.length ? disword : encodeURIComponent(' ')}`, {
            headers: authHeader()
        }).then(res => {
            if (res.data.data == undefined) {
                setgroup5data(res.data.Index.map(use => ({ value: use.id, label: use.title })));
            } if (res.data.Index == undefined) {
                setgroup5data(res.data.data.map(use => ({ value: use.id, label: use.title })));

            }
        });

        /** Group6 */
        axios.get(BASE_URL + `group6/page/0/10/asc/id/${disword.length ? disword : encodeURIComponent(' ')}`, {
            headers: authHeader()
        }).then(res => {
            if (res.data.data == undefined) {
                setgroup6data(res.data.Index.map(use => ({ value: use.id, label: use.title })));
            } if (res.data.Index == undefined) {
                setgroup6data(res.data.data.map(use => ({ value: use.id, label: use.title })));

            }
        });
        /** Group7 */
        axios.get(BASE_URL + `group7/page/0/10/asc/id/${disword.length ? disword : encodeURIComponent(' ')}`, {
            headers: authHeader()
        }).then(res => {
            if (res.data.data == undefined) {
                setgroup7data(res.data.Index.map(use => ({ value: use.id, label: use.title })));
            } if (res.data.Index == undefined) {
                setgroup7data(res.data.data.map(use => ({ value: use.id, label: use.title })));

            }
        });
        /** Group8 */
        axios.get(BASE_URL + `group8/page/0/10/asc/id/${disword.length ? disword : encodeURIComponent(' ')}`, {
            headers: authHeader()
        }).then(res => {
            if (res.data.data == undefined) {
                setgroup8data(res.data.Index.map(use => ({ value: use.id, label: use.title })));
            } if (res.data.Index == undefined) {
                setgroup8data(res.data.data.map(use => ({ value: use.id, label: use.title })));

            }
        });
        /** Group9 */
        axios.get(BASE_URL + `group9/page/0/10/asc/id/${disword.length ? disword : encodeURIComponent(' ')}`, {
            headers: authHeader()
        }).then(res => {
            if (res.data.data == undefined) {
                setgroup9data(res.data.Index.map(use => ({ value: use.id, label: use.title })));
            } if (res.data.Index == undefined) {
                setgroup9data(res.data.data.map(use => ({ value: use.id, label: use.title })));

            }
        });
    }, [disword]);
    /** Group1 change */
    const group1change = (e) => {
        setgroup1({ "id": e.value })
    }

    /** Group2 change */
    const group2change = (e) => {
        setgroup2({ "id": e.value })
    }

    /** Group3 change */
    const group3change = (e) => {
        setgroup3({ "id": e.value })
    }
    /** Group4 change */
    const group4change = (e) => {
        setgroup4({ "id": e.value })
    }
    /** Group5 change */
    const group5change = (e) => {
        setgroup5({ "id": e.value })
    }
    /** Group6 change */
    const group6change = (e) => {
        setgroup6({ "id": e.value })
    }
    /** Group7 change */
    const group7change = (e) => {
        setgroup7({ "id": e.value })
    }
    /** Group8 change */
    const group8change = (e) => {
        setgroup8({ "id": e.value })
    }
    /** Group9 change */
    const group9change = (e) => {
        setgroup9({ "id": e.value })
    }
    const submitdata = () => {

        setIsLoading(true);

        axios.post(BASE_URL + 'group10/save', { group1, group2, group3, group4, group5, group6, group7, group8, group9, title }, {
            headers: authHeader()
        }).then(res => {
            navigate('/Group10Table/1');
            if (res.status == 201 || res.status == 200) {

                Toast.fire({
                    icon: 'success',
                    title: 'Successfully added'
                })
            }

        }).catch(error => {

            if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `
                  <div>
                    <p id="issueText">Why do I have this issue?</p>
                    <ul>
                
                    <li id="typer1">You might have an internet issue</li>
                    </ul>
                  </div>
                `,
                    didOpen: () => {
                        applyFontSizeTransition('issueText');
                    }
                });
            } else {
                Swal.fire({
                    title: 'This Product is already Created',
                    text: "You won't be able to Add!",
                    icon: 'warning',
                })

            }
        })
            .finally(() => {
                setIsLoading(false);
            });
    };
    return (
        <MDBCard className="company">
            <h1>Create Group10</h1>
            <Form className="product1">
                <Row className="mb-1">
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Group1</Form.Label>
                        <Select options={group1data} onChange={(e) => group1change(e)} onInputChange={(inputValue) => setdisword(inputValue)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Group2</Form.Label>
                        <Select options={group2data} onChange={(e) => group2change(e)} onInputChange={(inputValue) => setdisword(inputValue)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Group3</Form.Label>
                        <Select options={group3data} onChange={(e) => group3change(e)} onInputChange={(inputValue) => setdisword(inputValue)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Group4</Form.Label>
                        <Select options={group4data} onChange={(e) => group4change(e)} onInputChange={(inputValue) => setdisword(inputValue)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Group5</Form.Label>
                        <Select options={group5data} onChange={(e) => group5change(e)} onInputChange={(inputValue) => setdisword(inputValue)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Group6</Form.Label>
                        <Select options={group6data} onChange={(e) => group6change(e)} onInputChange={(inputValue) => setdisword(inputValue)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Group7</Form.Label>
                        <Select options={group7data} onChange={(e) => group7change(e)} onInputChange={(inputValue) => setdisword(inputValue)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Group8</Form.Label>
                        <Select options={group8data} onChange={(e) => group8change(e)} onInputChange={(inputValue) => setdisword(inputValue)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Group9</Form.Label>
                        <Select options={group9data} onChange={(e) => group9change(e)} onInputChange={(inputValue) => setdisword(inputValue)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Title</Form.Label>
                        <Form.Control type="text" name="title" onChange={(e) => settitle(e.target.value)} placeholder="Enter Title" />
                    </Form.Group>
                </Row>
                <Button variant="primary" style={{ width: 'auto' }} onClick={submitdata} disabled={isLoading}>
                    {isLoading ? 'Loading...' : 'Submit'}
                </Button>
            </Form>
        </MDBCard>
    )
}