import axios from "axios";
import { MDBCard } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Accordion, Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../services/Api";
import authHeader from "../services/auth-header";
import Swal from "sweetalert2";
import AuthService from "../services/auth.service";

export default function View(){
    const navigate = useNavigate();
    const [anm, setanm] = useState({});
    const [version, setversion] = useState('');
    const [taxSch, settaxSch] = useState('');
    const [supTyp, setsupTyp] = useState('');
    const [regRev, setregRev] = useState('');
    const [igstOnIntra, setigstOnIntra] = useState('');
    const [typ, settyp] = useState('');
    const [no, setno] = useState('');
    const [dt, setdt] = useState('');
    const [distance, setdistance] = useState('');
    const [transMode, settransMode] = useState('');
    const [transId, settransId] = useState('');
    const [transName, settransName] = useState('');
    const [transDocDt, settransDocDt] = useState('');
    const [transDocNo, settransDocNo] = useState('');
    const [vehNo, setvehNo] = useState('');
    const [vehType, setvehType] = useState('');
    const [sellerDtls, setseller] = useState({});
    const [buyerDtls, setbuyer] = useState({});
    const [valDtls, setvaldtls] = useState({});

    const { id } = useParams();
    const { pointindex } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const applyFontSizeTransition = (elementId) => {
      const element = document.getElementById(elementId);
      element.style.transition = 'font-size 2s';
      element.style.fontSize = '30px';
      element.style.fontWeight = '600';
      // font-weight: 600;
      setTimeout(() => {
         
        document.getElementById("typer1").classList.add('promote');
        element.style.fontSize = '24px';
      }, 1000);
    };
    const [ branchData,setBranchData ] = useState('');
    const [data,setdata] = useState('');
    const [ salesreturnItems, setsalesreturnItems ] = useState([]);
    const[warehouse,setwarehouse]=useState('')
    const[distributor,setdistributor] = useState('')
const [grossAmount, setGrossAmount] = useState([])
    const [grandtotal, setGrandtotal] = useState([])
    const [shipping, setshipping] = useState([])
    const getSalesreturndata = ( ) => {

        axios.get(BASE_URL + 'sr/' + id,{
            headers:authHeader()
        }).then(res=>{
            console.log("mello",res.data);
            setGrandtotal(res.data.grandtotal)
            setGrossAmount(res.data.grossAmount)
            setdata(res.data);
            setBranchData(res.data.branch)
            // setBranchData(res.data.branch);
            setsalesreturnItems(res.data.salesReturnItems);
            setwarehouse(res.data.warehouse);
            // setdistributor(res.data.distributor)
            (res.data.retailerstatus === 'customer'?setdistributor(res.data.retailer):setdistributor(res.data.distributor));

            setseller({ "gstin": res.data.gstin, "lglNm": res.data.lglNm, "trdNm": res.data.trdNm, "addr1": res.data.addr1, "addr2": res.data.addr2, "loc": res.data.loc, "pin": res.data.pin, "stcd": res.data.stcd, "ph": res.data.ph, "em": res.data.em })
            setbuyer({ "gstin": res.data.bgstin, "pos": res.data.pos, "lglNm": res.data.blglNm, "trdNm": res.data.btrdNm, "addr1": res.data.baddr1, "addr2": res.data.baddr2, "loc": res.data.bloc, "pin": res.data.bpin, "stcd": res.data.bstcd, "ph": res.data.bph, "em": res.data.bem })
           setversion(res.data.version)
           setanm({
            "nm": res.data.nm, "val": res.data.val, "assVal": res.data.assVal, "cgstVal": res.data.cgstVal, "sgstVal": res.data.sgstVal, 'igstVal': res.data.igstVal, "cesVal": res.data.cesVal,
            "stCesVal": res.data.stCesVal, "discount": res.data.discount, "othChrg": res.data.othChrg, "rndOffAmt": res.data.rndOffAmt, "totInvVal": res.data.totInvVal, "totInvValFc": res.data.totInvValFc
        });
        setshipping(res.data.shippingcharge)
        setvaldtls({
            "assVal": res.data.assVal, "cgstVal": res.data.cgstVal === 0 ? 0 : (res.data.cgstVal).toFixed(2), "sgstVal": res.data.sgstVal === 0 ? 0 : (res.data.sgstVal).toFixed(2), "igstVal": res.data.igstVal === 0 ? 0 : res.data.igstVal, "cesVal": res.data.cesVal, "stCesVal": res.data.stCesVal,
            "othChrg": res.data.vothChrg, "totInvVal": res.data.totInvVal,
        });
        settyp(res.data.typ);
        setno(res.data.no);
        const date = new Date(res.data.dt);
const day = String(date.getDate()).padStart(2, '0');
const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
const year = date.getFullYear();

const formattedDate = `${day}/${month}/${year}`;
        setdt(formattedDate);
        settaxSch(res.data.taxSch);
        setsupTyp(res.data.supTyp);
        setregRev(res.data.regRev);
        setigstOnIntra(res.data.igstOnIntra);
        })
    };
    let date_value = new Date(data.salesreturndate);
    const defaultValue = date_value.toLocaleDateString('en-CA');
    let date_value1 = new Date(data.invoicedate);
    const defaultValue1 = date_value1.toLocaleDateString('en-CA');
    useEffect(() => {
        getSalesreturndata()
    },[]);
    const getItem = (id) => {

        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.isConfirmed) {
            setIsLoading(true); 

            axios.delete( BASE_URL + 'sr/' + id, {
                headers:authHeader()
            }).then(res=>{ Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
              )
                // window.location.reload();
                navigate(`/salesreturn/${pointindex}`)

            }).catch(error => {
                console.error('Error adding  :', error);
                if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `
                    <div>
                      <p id="issueText">Why do I have this issue?</p>
                      <ul>
                  
                      <li id="typer1">You might have an internet issue</li>
                      </ul>
                    </div>
                  `,
                  didOpen: () => {
                    applyFontSizeTransition('issueText');
                  }
                });
              }
              })
              .finally(() => {
                setIsLoading(false); 
              });
            
            
           
          }
        })
      };
      const [Loading,   setLoading]=useState(false)
      const user = AuthService.getCurrentUser();
      const generateinvoice = () => {
        let attribDtls = [anm];
        let tranDtls = { taxSch, supTyp, regRev, igstOnIntra }
        const docDtls = { typ, no, dt, }
        let da_ta = salesreturnItems.map((item) => ({
            "slNo": item.slNo            ,
            "prdDesc": item.prdDesc,
            "isServc": item.isServc,
            "qty": item.qty,
            "freeQty": item.freeQty,
            "unit": item.unitofmeasurement,
            "totAmt": item.totAmt,
            "discount": item.discount,
            "assAmt": item.assAmt,
            "gstRt": item.gstRt,
            "igstAmt": Number(item.igstAmt),
            "cgstAmt": Number(item.cgstAmt),
            "sgstAmt": Number(item.sgstAmt),
            "totItemVal": item.total,
            "hsnCd": item.product.hsnCode,

        }))
        let itemList = da_ta;
        setLoading(true);
        // let ewbDtls = { distance, transId, transName, transDocDt, transDocNo, vehNo, vehType, transMode }
         axios.post("http://43.225.54.138:8080/scm/gst/post/encryptedeinvoice/" + id, { version, tranDtls, docDtls, sellerDtls, buyerDtls, itemList, valDtls }, {
            headers: authHeader()
        }).then(res => {
            Swal.fire({
                title: 'Success!',
                text: ' Credit Note Generated successfully',
                icon: 'success',
                confirmButtonText: 'OK'
              });
              window.location.reload();
            console.log('mello', res.data)
        }).finally(() => {
            setLoading(false)
          });
    }
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [show, setShow] = useState(false);
    const cancelinvoice=()=>{
        axios.post(`http://43.225.54.138:8080/scm/gst/get/cancelsalesreturn/${id}`, { 
       "irn":data.irnno,
       "cnlRsn":Number(cnlRsn),
       "cnlRem":cnlRem
        }, {
          headers: authHeader()
        }).then(res => {
          console.log('mello', res.data)
          
          Swal.fire({
            title: 'Success!',
            text: 'Canceled successfully',
            icon: 'success',
            confirmButtonText: 'OK'
          });
       
          window.location.reload();
       
       
        }) .finally(() => {
          setLoading(false)
        });
       
        
       }
       const [cnlRem, setcnlRem] = useState([])
const [cnlRsn, setcnlRsn] = useState([])
    return(

        <MDBCard className="company">
 <Modal  show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Cancel Credit Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        {/* "ewbNo":"241817689082",
"cancelRsnCode":2,
"cancelRmrk":"Cancelled the order" */}
<label className='form-control form-label'>IRN NO</label>

<input type="text" value={data.irnno} className="form-control"/>
 <label className='form-label'>Cancel Reason code</label>
<input type='number' onChange={(e)=>setcnlRsn(e.target.value)} className='form-control'/>
<label className='form-label'>Cancel Reason</label>
 <textarea className='form-control' onChange={(e)=>setcnlRem(e.target.value)}>

 </textarea>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className='btn-sm' onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" className='btn-sm' onClick={()=>{handleClose();cancelinvoice()}}>
          Cancel Credit Note
          </Button>
        </Modal.Footer>
      </Modal>

{data.status == 'converted'?(
            <div className='d-flex bd-highlight'>
            <div className="card-header card-title w-100">             <h1>Sales Return View</h1></div>
            {/* <Link className='p-2 w-200 card-header card-title bd-highlight' to={`/PurchaseEdit/${id}/${pointindex}`}><Button>Edit</Button></Link> */}
            <Link to={'/mysalesid/' + id} target="_blank">
      <button className="btn btn-sm btn-primary">Print</button>
    </Link>
            <Link className='p-2 w-200 card-header card-title bd-highlight' to={`/salesreturn/${pointindex}`}><Button>Back</Button></Link>
    
       
          </div>
):(
    <div className='d-flex bd-highlight'>
    <div className="card-header card-title w-100">             <h1>Sales Return View</h1></div>
    {/* <Link className='p-2 w-200 card-header card-title bd-highlight' to={`/PurchaseEdit/${id}/${pointindex}`}><Button>Edit</Button></Link> */}
    {user.roles[0] === 'ROLE_ADMIN' &&
    <Link className='p-2 w-200 card-header card-title bd-highlight' variant="primary" style={{width:'auto'}} onClick={()=>getItem(id)} disabled={isLoading}>
        
        <Button className="btn-sm">  {isLoading ? 'Loading...' : 'Delete'}</Button>
      </Link>      
      }
    <Link to={'/mysalesid/' + id} target="_blank">
<button className="btn btn-sm btn-primary">Print</button>
</Link>
{data.invoice_status=="InACT"?<Link className='p-2 w-200 card-header card-title bd-highlight' to={`/SalesReturnUpdate/${id}/${pointindex}`}><Button>Edit</Button></Link>
:""}    <Link className='p-2 w-200 card-header card-title bd-highlight' to={`/salesreturn/${pointindex}`}><Button>Back</Button></Link>
{ Loading==false?data.invoice_status=="InACT"?<button className="btn btn-sm btn-primary" onClick={()=>generateinvoice()}>E-invoice</button>:"":<button className="btn btn-sm btn-primary">Loading...</button>}
{data.invoice_status == "ACT"?<Link className='p-2 w-200 card-header card-title bd-highlight' variant="primary" style={{ width: 'auto' }} >   <Button  onClick={()=>handleShow()} style={{width:'184px'}}>Cancel Credit Note</Button></Link>
:""}  
  </div>
)}

            <Form className="product1">
            {
              data.invoice_status == "ACT"?<>
              <div className="row">
              <div className="col-8">
                  <b className="form-control">IRN-{data.irnno}</b>
              </div>
             
          </div>
          <br></br>
          <div className="row">
              <div className="col ">
              <b className="form-control">ACK NO-{data.ackno}</b>   
               </div>
              <div className="col ">
              <b className="form-control">ACK Date-{data.ackdate}</b>    </div>
              <div className="col">
              {/* <b>ACK NO-{Data.ackno}</b> */}
              <b className="form-control">Invoice-Status -{data.invoice_status}</b>
              </div>
          </div>
          
              </>:""
          }
           {
            data.invoice_status=='CNL'?  <b className="form-control form-label">CreditNote-Status-Canceled</b>:""
          }
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Sales Return Data</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Branch Name</Form.Label>
                                    <Form.Control type="text" value={branchData.branchname}  />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">WareHouse Name</Form.Label>
                                    <Form.Control type="text" value={warehouse.name} />
                                </Form.Group>

                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Voucher Series</Form.Label>
                                    <Form.Control type="text" value={data.voucherid} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Customer Name</Form.Label>
                                    <Form.Control type="text"  value={distributor.tradeName}/>

                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Date</Form.Label>
                                    <Form.Control type="date" value={defaultValue} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Terms of Payment</Form.Label>
                                    <Form.Control type='text' value={data.paymentTerms} autoComplete="off" />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Credit Note No</Form.Label>
                                    <Form.Control type="text" value={data.creditnoteno} autoComplete="off"  />
                                </Form.Group>
                                {/* <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Terms Of Payment</Form.Label>
                                    <Form.Control type="text" autoComplete="off"  />
                                </Form.Group> */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Invoice No</Form.Label>
                                    <Form.Control type="text"  value={data.invoiceno} autoComplete="off"  />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Invoice Date</Form.Label>
                                    <Form.Control type="date" value={defaultValue1} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Other Refernce</Form.Label>
                                    <Form.Control type="text" value={data.otherrefernce} autoComplete="off"  />
                                </Form.Group>
                                {/* <Form.Group as={Col} md="8" controlId="formBasicName">
                                    <Form.Label className="label">Owner</Form.Label>
                                    <Form.Control type="text" autoComplete="off" />
                                </Form.Group> */}



                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    {/* <Accordion.Item eventKey="1">
                        <Accordion.Header>Billing Address</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Customer Address</Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Customer Details</Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Address : -</Form.Label>
                                   <span>&nbsp;{customerAll.address}</span> 
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Name : -</Form.Label>
                                   <span>&nbsp;{customerSubContactsdata == "" ? customerAll.cname : customerSubContactsdata.contactname}</span> 
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">City : -</Form.Label>
                                   <span>&nbsp;{customerAll.city}</span> 
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Phone No : -</Form.Label>
                                   <span>&nbsp;{customerSubContactsdata == "" ? customerAll.phonenumber : customerSubContactsdata.phoneno}</span> 
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Country : -</Form.Label>
                                    <span>{"India"}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Email Id : -</Form.Label>
                                   <span> {customerSubContactsdata == "" ? customerAll.email : customerSubContactsdata.email}</span> 
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">State : -</Form.Label>
                                   <span>{supplierstate}</span> 
                                </Form.Group>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item> */}
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>Details</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Buyer Order No</Form.Label>
                                    <Form.Control type="text" value={data.buyerorderno} autoComplete="off"  />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Dispatched doc no</Form.Label>
                                    <Form.Control type="text" value={data.dispatcheddocno} autoComplete="off"  />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Dispatched Through</Form.Label>
                                    <Form.Control type="text" value={data.dispatchedthrough}autoComplete="off"  />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Destination</Form.Label>
                                    <Form.Control type="text" value={data.destination} autoComplete="off"  />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Terms of Delivery</Form.Label>
                                    <Form.Control type="text" value={data.termsofdelivery} autoComplete="off"  />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Declaration</Form.Label>
                                    <Form.Control type="text" value={data.declaration} autoComplete="off"  />
                                </Form.Group>

                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header>GST Details</Accordion.Header>
                        <Accordion.Body>
                        <Row className="mb-1">
                                <h1>Transport Details</h1>
                                {/* <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Tax Type</Form.Label>
                                    <Form.Control type="text" autoComplete="off" placeholder="Enter Tax Type" name="taxSch" onChange={(e) => settaxSch(e.target.value)} />
                                </Form.Group> */}
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Type of supply:{supTyp}</Form.Label>
 
                                    {/* <Form.Control type="text" autoComplete="off" placeholder="Enter Type of supply" onChange={(e) => setsupTyp(e.target.value)} /> */}
                                </Form.Group>
                                <h1>Document Details</h1>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Document No</Form.Label>
                                    <Form.Control type="text" value={no} disabled autoComplete="off" placeholder="Enter Document No"   />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Document Date</Form.Label>
                                    <Form.Control type="text" disabled value={dt} autoComplete="off"   />
                                </Form.Group>
                                <h1>Seller Details</h1>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">GSTIN of Seller </Form.Label>
                                    <Form.Control type="text" value={[sellerDtls].map((item) => item.gstin)} disabled autoComplete="off"  name="gstin" placeholder="Enter GSTIN of Supplier"   />
                                </Form.Group>
                                {/* value={[setsellindata].map((item) => item.gstin)} disabled */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Legal Name</Form.Label>
                                    <Form.Control type="text" autoComplete="off" value={[sellerDtls].map((item) => item.lglNm)} disabled name="lglNm" placeholder="Enter Legal Name"  />
                                </Form.Group>
                                {/* value={[setsellindata].map((item) => item.lglNm)} disabled  */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Address1</Form.Label>
                                    <Form.Control type="text"  autoComplete="off" value={[sellerDtls].map((item) => item.addr1)} disabled name="addr1" placeholder="Enter Address1"  />
                                </Form.Group>
                                {/* value={[setsellindata].map((item) => item.addr1)} disabled */}
                                {/* <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Address2</Form.Label>
                                    <Form.Control type="text" autoComplete="off" name="addr2" placeholder="Enter Address2"  />
                                </Form.Group> */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Location</Form.Label>
                                    <Form.Control type="text" disabled placeholder="Enter Location"  value={[sellerDtls].map((item) => item.loc)}   autoComplete="off" name="loc"  />
                                </Form.Group>
                                {/* value={[setsellindata].map((item) => item.loc)} disabled */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">PinCode</Form.Label>
                                    <Form.Control type="text" disabled placeholder="Enter PinCode" name="pin" value={[sellerDtls].map((item) => item.pin)}   autoComplete="off"  />
                                </Form.Group>
                                {/* value={[setsellindata].map((item) => item.pin)} disabled */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">State Code</Form.Label>
                                    <Form.Control type="text" disabled placeholder="Enter State Code"  value={[sellerDtls].map((item) => item.stcd)}   autoComplete="off" name="stcd"  />
                                </Form.Group>
                                {/* value={[setsellindata].map((item) => item.stcd)} disabled */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Phone Number</Form.Label>
                                    <Form.Control type="text" disabled placeholder="Enter Phone Number"  value={[sellerDtls].map((item) => item.ph)}   name="ph" autoComplete="off"  />
                                </Form.Group>
                                {/* value={[setsellindata].map((item) => item.ph)} disabled */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Email Id</Form.Label>
                                    <Form.Control type="text" disabled placeholder="Enter Email Id"    value={[sellerDtls].map((item) => item.em)}  name="em" autoComplete="off"  />
                                </Form.Group>
                                {/* disabled value={[setsellindata].map((item) => item.em)} */}
                                <h1>Buyer Details</h1>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">GSTIN of Buyer</Form.Label>
                                    <Form.Control type="text" disabled placeholder="Enter GSTIN of Buyer" value={buyerDtls.gstin || ""} name="gstin" autoComplete="off"  />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Legal Name</Form.Label>
                                    <Form.Control type="text" disabled placeholder="Enter Legal Name" value={buyerDtls.lglNm || ""} name="lglNm" autoComplete="off"  />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">State Code of Place of Supply</Form.Label>
                                    <Form.Control type="text" disabled placeholder="Enter State Code of Place of Supply" value={buyerDtls.pos || ""} name="pos" autoComplete="off"  />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Address1</Form.Label>
                                    <Form.Control type="text" disabled placeholder="Enter Address1" value={buyerDtls.addr1 || ""} name="addr1" autoComplete="off"  />
                                </Form.Group>
                                {/* <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Address2</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Address2" value={buyer.addr2 || ""} name="addr2" autoComplete="off"  />
                                </Form.Group> */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Location</Form.Label>
                                    <Form.Control type="text" disabled placeholder="Enter Location" value={buyerDtls.loc || ""} name="loc" autoComplete="off"  />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">PinCode </Form.Label>
                                    <Form.Control type="text" disabled placeholder="Enter PinCode" value={buyerDtls.pin || ""} name="pin" autoComplete="off"  />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">State Code</Form.Label>
                                    <Form.Control type="text" disabled placeholder="Enter State Code" value={buyerDtls.stcd || ""} name="stcd" autoComplete="off"  />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Phone No</Form.Label>
                                    <Form.Control type="text" disabled placeholder="Enter Phone No" value={buyerDtls.ph || ""} name="ph" autoComplete="off"  />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Email ID</Form.Label>
                                    <Form.Control type="text" disabled placeholder="Enter Email ID" value={buyerDtls.em || ""} name="em" autoComplete="off"  />
                                </Form.Group>
                               
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <br></br>

                 <Table striped responsive hover size="sm">
                    <thead className='p-3 mb-2 bg-success text-white'>
                        <tr>
                            <th>PRODUCT&nbsp;NAME</th>
                            <th >UOM</th>
                            <th>SQ(PCS)</th>
                            <th>SQ(KG)</th>
                            <th >Rate</th>
                            <th >Trade&nbsp;Discount</th>
                            <th>GROSS AMOUNT (₹)</th>
                            <th >SCHEME DISCOUNT %</th>
                            <th >NET AMOUNT (₹)</th>
                            <th >GST Value</th>
                            <th >Amount</th>
                          
                        </tr>
                    </thead>
                    <tbody style={{ overflow: "scroll" }}>
                        {salesreturnItems.map(item =>
                            <tr key={item.product.id}>
                                <td>
                                    <Form.Group as={Col} md="12" >
                                        {/* <Form.Control type="text" value={item.productName } size="sm" /> */}
                                        <textarea type="text"   className="form-control textcontrol" value={item.productName} size="sm" ></textarea>

                                    </Form.Group>
                                    <Form.Control as="textarea"  value={item.text} rows={2} className='form-control' />
                                </td>
                                <td><Form.Group as={Col} md="12">
                                <Form.Control type="text" min="0" size="sm" value={item.unitofmeasurement} readOnly />
                                </Form.Group>
                                </td>

                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" size="sm" value={item.srquantity} readOnly />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                    <Form.Control type="text" min="0" size="sm" value={item.srquantitykgs} readOnly />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" size="sm" readOnly value={Number(item.dlp)} />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" min="0" size="sm" value={Number(item.tradeDiscount)} readOnly/>
                                    </Form.Group>
                                </td>
                                <td><Form.Group as={Col} md="12">
                                    <Form.Control type="number" min="0" value={Number(item.grossamount)} size="sm" readOnly requied />
                                </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0"  value={Number(item.schemeDiscount)} size="sm" readOnly/>
                                    </Form.Group>
                                </td>
                                <td><Form.Group as={Col} md="12">
                                    <Form.Control type="number" min="0" value={Number(item.netAmount)} size="sm" readOnly requied />
                                </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" value={Number(item.gstvalue)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" value={Number(item.total)} size="sm" />
                                    </Form.Group>
                                </td>
                                

                            </tr>
                        )}
                    </tbody>
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Gross&nbsp;Amount</td>
                            <td> <Form.Group as={Col} md="12">
                                <Form.Control type="number" value={grossAmount} disabled style={{ height: 30 }} readOnly />
                            </Form.Group></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Shipping&nbsp;Charge</td>
                            <td><Form.Group as={Col} md="12">
                                <Form.Control type="number" value={shipping} disabled style={{ height: 30 }} />
                            </Form.Group></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>IGST</td>
                            <td>
                            <Form.Group as={Col} md="12">
                                <Form.Control type="number"  value={data.igst} disabled style={{ height: 30 }} requied />
                            </Form.Group>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>SGST</td>
                            <td>
                            <Form.Group as={Col} md="12">
                                <Form.Control type="number"  value={data.sgst} disabled style={{ height: 30 }} requied />
                            </Form.Group>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>CGST</td>
                            <td>
                            <Form.Group as={Col} md="12">
                                <Form.Control type="number"  value={data.cgst}  disabled  style={{ height: 30 }} requied />
                            </Form.Group>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Round&nbsp;off&nbsp;Value</td>
                            <td>
                            <Form.Group as={Col} md="12">
                                <Form.Control type="number"     style={{ height: 30 }} />
                            </Form.Group>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Grand&nbsp;Total</td>
                            <td>
                            <Form.Group as={Col} md="12">
                                <Form.Control type="number" value={grandtotal} disabled style={{ height: 30 }} readOnly />
                            </Form.Group>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </Table> 

            </Form>
        </MDBCard>
    )
}