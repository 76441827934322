import { SUPPLIER_GET_DATA_TO_LOAD } from "../constant";
import { SUPPLIER_ADD_TO_CART } from "../constant";
import { SUPPLIER_EDIT_CHANGE_MEASUREMENT } from "../constant";
import { SUPPLIER_EDIT_CHANGE_QTYPCS } from "../constant";
import { SUPPLIER_EDIT_CHANGE_QTYKG } from "../constant";
import { CHANGE_COST_SUPPLIER } from "../constant";
import { SUPPLIER_EDIT_CHANGE_CAL_MEASUREMENT } from "../constant";
import { REMOVE_FROM_CART_FROM_SUPPLIER } from "../constant";
import { CHANGE_SUPPLIER_DISCOUNT } from "../constant";
import { CHANGE_SUPPLIER_TEXT } from "../constant";
import { EMPTY_SUPPLIER_DATA } from "../constant";



export const getsupplierdeliverydatatoload = (data) => {

    //  console.warn("action called", data);
    return {

        type : SUPPLIER_GET_DATA_TO_LOAD,
        data : data
    }

}


export const supplierdeliveryaddtocart = (data) => {

    //  console.warn("action called", data);
    return {

        type : SUPPLIER_ADD_TO_CART,
        data : data
    }

}



export const removefromcartfromsupplierdelivery = (data) => {

    // console.warn("action REMOVE_TO_CART called", data);
    return {

        type : REMOVE_FROM_CART_FROM_SUPPLIER,
        data : data
    }

}

export const supplierdeliverymeasurement = (data, item, data2) => {

    return {
        type: SUPPLIER_EDIT_CHANGE_MEASUREMENT,
        data: data,
        item: item,
        data2: data2
    }
}

export const supplierdeliveryqtypcs = (data, item, data2) => {


    return {

        type: SUPPLIER_EDIT_CHANGE_QTYPCS,
        data: data,
        item: item,
        data2: data2
    }

}

export const supplierdeliveryqtykgs = (data, item, data2) => {

    return {
        type: SUPPLIER_EDIT_CHANGE_QTYKG,
        data: data,
        item: item,
        data2: data2

    }
}



export const changecostsupplierdelivery = (data, item, data2) => {

    // console.warn("cost change called", data);
    return {

        type : CHANGE_COST_SUPPLIER,
        data : data,
        item : item,
        data2 : data2

    }

}

export const supplierdeliverycalmeasurement = (data, item, data2 ) => {

    return{
        type:SUPPLIER_EDIT_CHANGE_CAL_MEASUREMENT,
        data: data,
        item: item,
        data2: data2
    }
}


export const changesupplierdeliverydiscount = (data, item, data2) => {

    // console.warn("discount change called", data);
    return {

        type : CHANGE_SUPPLIER_DISCOUNT,
        data : data,
        item : item,
        data2 : data2

    }

}


export const changesupplierdeliverytext = (data, item) => {

    // console.warn("text change called", data);
    return {

        type : CHANGE_SUPPLIER_TEXT,
        data : data,
        item : item
    }

}


export const emptysupplierdeliverydata = () => {

    return {

        type : EMPTY_SUPPLIER_DATA,
    }

}