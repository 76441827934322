import axios from "axios";
import authHeader from "./auth-header";

const API_URL = "http://43.225.54.138:8080/scm/api/brand/";


const getBrand = () => { 
    return axios.get(API_URL +"getAll", { headers: authHeader() });
    // return axios.get(API_URL +"getAll", { headers: authHeader() });
  };
  const getBrandEdit = (id) => {
    return axios.get(API_URL +"getById/"+id , { headers: authHeader() });
  };
const getBrandUpdate = (id,brand) => {
  return axios.put(API_URL + "update/"+id, brand,{ headers: authHeader() });
};

const getbrandCreate = (brand) => {
  return axios.post(API_URL + "create",brand,{ headers: authHeader() });
};
const getDelete = ( id ) => {
    return axios.delete(API_URL + "delete/"+id, { headers: authHeader() });
  };
const BrandService = {
    getBrand,
    getBrandEdit,
    getBrandUpdate,
    getbrandCreate,
    getDelete

};

export default BrandService;