import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../services/Api";
import axios from "axios";
import authHeader from "../../services/auth-header";
import { MDBCard } from "mdbreact";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { faL } from "@fortawesome/free-solid-svg-icons";

export default function Create() {
    const [isLoading, setIsLoading] = useState(false);
    const applyFontSizeTransition = (elementId) => {
      const element = document.getElementById(elementId);
      element.style.transition = 'font-size 2s';
      element.style.fontSize = '30px';
      element.style.fontWeight = '600';
      // font-weight: 600;
      setTimeout(() => {
        //  
        document.getElementById("typer1").classList.add('promote');
        element.style.fontSize = '24px';
      }, 1000);
    };
    
    const [productAll, setProductAll] = useState([]);
    const [BrandAll, setBrandAll] = useState([]);
    const [brand, setbrand] = useState('');
    const [disword, setdisword] = useState(encodeURIComponent(' '));
    const [product, setproduct] = useState('');
    const [productname, setproductname] = useState('');
    const [size, setsize] = useState('');
    const [qty, setqty] = useState('');
    const [mrp, setmrp] = useState('');
    const [capacity, setcapacity] = useState('');
    const [productname1, setproductname1] = useState('');
    const [productname2, setproductname2] = useState('');
    const [ourprice, setourprice] = useState('');
    const [brandname, setbrandname] = useState('');
    const [packedOn, setpackedOn] = useState('');
    const [printingqty, setprintingqty] = useState('');
    const [actualqty, setactualqty] = useState('');
    const [previewlabel, setpreviewlabel] = useState('');
    const [diameter, setdiameter] = useState('');

    const navigate = useNavigate();
    let Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    /* Get Brand data */
    const getbranddata = () => {
        axios.get(BASE_URL + 'brand/getAll', {
            headers: authHeader()
        }).then(res => {
            setBrandAll(res.data.map(user => ({ value: user.id, label: user.brandName })))
        })
    }

    useEffect(() => {
        getbranddata();
    }, []);

const [Bh, setBh] = useState(false)
const [re, setRe] = useState(false)
    const onChangeBrand = (e) => {
        let name = e.value;
        console.log(e.label=="BIGBASKET")
        const trimmedLabel = typeof e.label === 'string' ? e.label.trim() : '';

        if (trimmedLabel === 'BIGBASKET') {
        //   alert('OK WORKIN');  
setBh(true)
        } else{
            setBh(false)
        }
        if (trimmedLabel.toLowerCase() === 'reliance') {
        //   console.log('Label does not match.');
        // alert(trimmedLabel)
        setRe(true)
        }else{
            setRe(false)
        }
        setbrand({ "id": e.value });

        axios.get(BASE_URL + 'brand/getById/' + name, {
            headers: authHeader()
        }).then(res => {
            setbrandname(res.data.brandName)
        })
        /* Product Get all */
        axios.get(BASE_URL + 'product/productsByBrand/' + name, {
            headers: authHeader()
        }).then(res => {
            // console.log('mello',res.data)
            setProductAll(res.data.map(use => ({ value: use.id, label: use.productName })));
        })

    }

    const onChangeProduct = (e) => {
        let name = e.value;
        setproduct({ "id": e.value });

        axios.get(BASE_URL + 'product/getById/' + name, {
            headers: authHeader()
        }).then(res => {
            // console.log(res.data,"mello");
            setproductname(res.data.productName);
        })
    };


    const submitdata = () => {
        setIsLoading(true); 
        axios.post(BASE_URL + 'productbarcode/save', {
            brand, product, size, qty, mrp, capacity, productname1, productname2, ourprice, brandname, productname, diameter
        }, {
            headers: authHeader()
        }).then(res => {
            navigate('/ProductBarCode/Table/1')
            if (res.status == 201 || res.status == 200) {
                // alert("ee")
                Toast.fire({
                    icon: 'success',
                    title: 'Successfully added'
                })
            }
        }).catch(error => {
            Swal.fire({
                title: 'Product Barcode is already Created With This Brand And Product',
                text: "You won't be able to revert this!",
                icon: 'warning',
            })
            if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `
                    <div>
                      <p id="issueText">Why do I have this issue?</p>
                      <ul>
                  
                      <li id="typer1">You might have an internet issue</li>
                      </ul>
                    </div>
                  `,
                  didOpen: () => {
                    applyFontSizeTransition('issueText');
                  }
                });
              }


        }).finally(() => {
            setIsLoading(false); 
          });
    }

    return (
        <MDBCard className="company">
            <h1>Product BarCode Create</h1>
            <Form className="product1">
                <Row className="mb-1">
                    <Form.Group as={Col} md="4" controlId="formBasicName">
                        <Form.Label className="label">Brand Name</Form.Label>
                        <Select options={BrandAll} onChange={(e) => onChangeBrand(e)} />
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="formBasicName">
                        <Form.Label className="label">Product Name</Form.Label>
                        <Select options={productAll} onInputChange={(inputValue) => setdisword(inputValue)} onChange={(e) => onChangeProduct(e)} />
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="formBasicName">
                        <Form.Label className="label">Size</Form.Label>
                        <Form.Control type="text" autoComplete="off" onChange={(e) => setsize(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="formBasicName">
                        <Form.Label className="label">QTY</Form.Label>
                        <Form.Control type="text" autoComplete="off" onChange={(e) => setqty(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="formBasicName">
                        <Form.Label className="label">MRP</Form.Label>
                        <Form.Control type="text" autoComplete="off" onChange={(e) => setmrp(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="formBasicName">
                        <Form.Label className="label">Capacity</Form.Label>
                        <Form.Control type="text" autoComplete="off" onChange={(e) => setcapacity(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="formBasicName">
                        <Form.Label className="label">Diameter</Form.Label>
                        <Form.Control type="text" autoComplete="off" onChange={(e) => setdiameter(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="formBasicName">
                        <Form.Label className="label">Product Name1</Form.Label>
                        <Form.Control type="text" autoComplete="off" onChange={(e) => setproductname1(e.target.value)} />
                    </Form.Group>
                 {Bh?   <Form.Group as={Col} md="4" controlId="formBasicName">
                        <Form.Label className="label">Product Name2</Form.Label>
                        <Form.Control type="text" autoComplete="off" onChange={(e) => setproductname2(e.target.value)} />
                    </Form.Group>:""}
                   {re? <Form.Group as={Col} md="4" controlId="formBasicName">
                        <Form.Label className="label">OUR Price</Form.Label>
                        <Form.Control type="text" autoComplete="off" onChange={(e) => setourprice(e.target.value)} />
                    </Form.Group>:""}
                    {/* <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Packed On</Form.Label>
                                    <Form.Control type="date"  onChange={(e) => setpackedOn(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Printing QTY</Form.Label>
                                    <Form.Control type="text" autoComplete="off" onChange={(e) => setprintingqty(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Actual QTY</Form.Label>
                                    <Form.Control type="text" autoComplete="off" onChange={(e) => setactualqty(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Preview Label</Form.Label>
                                    <Form.Control type="text" autoComplete="off" onChange={(e) => setpreviewlabel(e.target.value)} />
                                </Form.Group> */}

                </Row>
                {/* <Button variant="primary" type="button" onClick={submitdata}>
                    Submit
                </Button> */}
                {/* <Button variant="primary" style={{width:'auto'}} onClick={submitdata} disabled={loading}>
          {loading ? 'Loading...' : 'Submit'}
        </Button> */}
        <Button variant="primary" style={{width:'auto'}} onClick={submitdata} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Submit'}
        </Button>  
            </Form>
        </MDBCard>
    )
}