import axios from 'axios';
import JsBarcode from 'jsbarcode';
import React, { useEffect, useState } from 'react';
import { BASE_URL } from '../../services/Api';
import authHeader from '../../services/auth-header';
import { useNavigate, useParams } from 'react-router-dom';


const Print = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {size}=useParams();

  const labelStyle = {
    width: '60mm',
    
    height: '45mm',
    border: '1px solid black',
    padding: '2mm',
    boxSizing: 'border-box',
    fontSize: '12pt',
    marginTop:'21px',
    overFlow:'hidden',
    // borderRadius: '12px',
    // rotate: '0deg'
   };
   const specialStyle1 = {
    marginTop:'37px',
    
  };
  const labelStyle1 = {
    width: '152mm',
    
    height: '84mm',
    border: '1px solid black',
    padding: '2mm',
    boxSizing: 'border-box',
    fontSize: '12pt',
    marginTop:'21px',
    overFlow:'hidden',
    // borderRadius: '12px',
    // rotate: '0deg'
   };
   const specialStyle = {
    marginTop:'37px',
    
  };

  const [cloudfront, setCloudfront] = useState(null);


  useEffect(() => {
    if (cloudfront) {
      JsBarcode("#barcode", `${data.eancode}`, {
        height: 25,
        displayValue: false,  
      });
    }
  }, [cloudfront]);
const [data, setData] = useState([])
  useEffect(() => {
    axios.get(BASE_URL +`productbarcode/product/${id}`, {
      headers: authHeader()
  }).then(res => {
      if(res.data !== null){
        processArray(size)
            console.log('mello',res.data)
           setData(res.data)
          
      }
  })




    document.getElementById("qerteww").style.display = "none";
    document.getElementById("sidein").style.display = "none";


    const elements = ["naving", "1sidebar", "qw", "swift", "logoo", "logoo1", "menu"];
    elements.forEach(elementId => {
      const element = document.getElementById(elementId);
      if (element) {
        element.classList.add('hidden');
      }
    });


    const newElement = document.getElementById("new");
    if (newElement) {
      newElement.classList.add('fullWidth');
    }


  }, []);


  const handlePrint = () => {
    window.print();
  };


  function processArray(inputNumber) {
    const array = Array.from({ length: inputNumber }, (_, index) => index + 1);
    const mappedArray = array.map(item => item * 1);
    setCloudfront(mappedArray);
    console.log(mappedArray.map(() => `Neelam`));
  }
  const handleprint12=()=>{
    window.location.href = '/PrintProductBarcode/1';
  }

  return (
    <dd style={{ display: 'flex' }}>
      <div className='row'>
        <div className='col-12'>
        <button className="btnmedia btn btn-primary"   onClick={handleprint12} style={{height:"auto",marginLeft:"20px", marginTop: '10mm',width:"150px" }}>
       Back
      </button>
      <button className="btnmedia btn btn-primary"   onClick={handlePrint} style={{height:"auto",marginLeft:"20px", marginTop: '10mm',width:"150px" }}>
        Print Label
      </button>
        </div>
      </div>
      <div className="print-content" >

      <div className="label-container p1">
  <div
    className="rotate"
    style={{
      ...labelStyle1,
      ...specialStyle1, // Apply specialStyle directly
    }}
  >
    <div className='row' style={{ marginTop: '-7px' }}>
      <div className='col' style={{ lineHeight: "15px" }}>
        <span style={{ fontSize: "12px", fontWeight: "700" }}>Manufactured, Packed and Marketed By</span><br />
        <span style={{ fontSize: "12px", fontWeight: "700" }}>NEELAM APPLIANCES LIMITED</span><br />
        <span style={{ fontSize: "11px", fontWeight: "700" }}>
          Address: Neelam House, Fafadia Ind Est. Waliv Village, Vasai(E)-401208, Dist Palghar, Maharashtra (India)
          For Complaints / Feedback / Queries Contact Customer Care Executive Time: 10am to 6pm,
          Consumer Care No: 8080561321/22 Email: mail@neelamappliances.com
          & Refer Manufactured, Packed and Marketed by Address.
        </span><br />
      </div>
    </div>
    <div style={{ padding: "3px" }}>
      <div className='row' style={{ borderTop: "1px solid black", padding: "2px", height: '58px' }}>
        <div className='col'>
          <div style={{ fontSize: "12px", fontWeight: "700" }}>Product Name:</div>
        </div>
        <div className='col-4' style={{ position: "relative", left: '2px', top: '-2px', border: '1px solid', borderTop: 'none', borderRight: 'none' }}>
          <div style={{ fontSize: "13px", fontWeight: "700" }}>Size: {data.size}</div>
        </div>
        <div className='col-12' style={{ position: "relative", left: '0px', top: '-1px', lineHeight: '8px', display: 'flex', flexDirection: "column" }}>
  {/* Check if data and data.brand are defined before accessing brandName */}
  {data?.brand?.brandName?.trim() !== "BIGBASKET" ? (
    <div style={{ fontSize: "13px", fontWeight: "700", paddingBottom: '4px' }}>
      {data.productname || 'Product Name Not Available'}
    </div>
  ) : (
    <>
      <span style={{ fontSize: "13px", fontWeight: "700", paddingBottom: '1px' }}>
        {data.productname1 || 'Product Name 1 Not Available'}
      </span>
      <span style={{ fontSize: "13px", fontWeight: "700", paddingBottom: '4px' }}>
        {data.productname2 || 'Product Name 2 Not Available'}
      </span>
    </>
  )}
</div>

      </div>
    </div>
    <div className='row' style={{ padding: '3px', position: "relative", top: "-8px" }}>
      <div className='col' style={{ position: "relative", top: '-4px', border: "1px solid black", borderRight: 'none', borderLeft: 'none' }}>
        <div style={{ fontSize: "13px", fontWeight: "700" }}>Capacity: {data.capacity}</div>
      </div>
      <div className='col' style={{ position: "relative", top: '-4px', border: "1px solid black", borderRight: 'none' }}>
        <div style={{ fontSize: "13px", fontWeight: "700" }}>Diameter: {data.diameter}</div>
      </div>
    </div>
    <div className='row' style={{ position: "relative", top: '-12px' }}>
      <div className='col-5'>
        <div style={{ fontSize: "13px", fontWeight: "700" }}>Max. Retail Price:<br />(Inclusive of All Taxes)</div>
      </div>
      <div className='col'>
      <div style={{ fontSize: "13px", fontWeight: "700" }}>
  ₹{(data?.brand?.brandName === "reliance" ? data?.ourprice : data?.mrp) || 'Price Not Available'}
</div>
      </div>
      <div className='col'>
        <div style={{ fontSize: "13px", fontWeight: "700" }}>Qty: {data.qty}</div>
      </div>
    </div>
    <div style={{ textAlign: "center", display: "flex", justifyContent: "center", overflow: "hidden", marginTop: '-8px' }}>
      {/* <img style={{ width: "191px", height: "31px", position: "relative", top: "-9px" }} src="https://barcode.tec-it.com/barcode.ashx?data=8904052700001&multiplebarcodes=true&translate-esc=on" alt="Barcode" /> */}
      <div style={{ display: 'flex', marginTop: '-5px', flexDirection: 'column', lineHeight: '1px' }}>
        <svg id="barcode" className='barcodeomkar' />
        <p style={{ fontSize: '13px', position: 'relative', top: '9px', marginTop: '20px!important', fontWeight: "700", marginTop: '9px', zIndex: 999999 }}>{data.eancode}</p>
      </div>
    </div>
    <div className='row' style={{ padding: '3px', position: "relative", top: "9px" }}>
      <div className='col' style={{ position: "relative", top: '1px', border: "1px solid black", borderLeft: 'none', borderRight: 'none', borderBottom: 'none' }}>
        <div style={{ fontSize: "13px", fontWeight: "700" }}>WASH BEFORE USE</div>
      </div>
      <div className='col' style={{ position: "relative", top: '1px', border: "1px solid black", borderBottom: 'none', borderRight: 'none' }}>
        <div style={{ fontSize: "13px", fontWeight: "700" }}>Mfg & Pkd on: {data.packedOn}</div>
      </div>
    </div>
  </div>
</div>



      
        {cloudfront?cloudfront.map((item, index) => (
          <>
          <div key={index} className="label-container " >
            <div className='rotate p2'  style={{
                ...labelStyle,
                ...(index > 3 ? specialStyle : {}),  
                ...(index >= 2 ? specialStyle : {}),
              }}>
              <div className='row' style={{marginTop:'-7px'}}>
                <div className='col' style={{ lineHeight: "3px" }}>
                  <span style={{  fontSize: "6px", fontWeight: "700" }}>Manufactured,Packed and Marketed By</span><br />
                  <span style={{  fontSize: "6px", fontWeight: "700" }}> NEELAM APPLIANCES LIMITED</span><br />
                  <span style={{  fontSize: "6px", fontWeight: "700" }}> Address : Neelam House, Fafadia Ind Est. Waliv Village,
Vasai(E)-401208,Dist Palghar. Maharashtra (India)
For Complaints / Feedback / Queries Contact Customer
Care Executive Time: 10am to 6pm , Consumer Care
No : 8080561321/22 Email: mail@neelamappliances.com
& Refer Manufactured, Packed and Marketed by Address.</span><br />

                </div>
               
              </div>
              <div style={{padding:"3px"}}>
              <div className='row' style={{borderTop:"1px solid black",padding:"2px",height:'37px'}}>
                <div className='col'>
            
                <div style={{  fontSize: "7px", fontWeight: "700" }}>Product Name :  </div>

                </div>
                <div className='col-4' style={{position:"relative",left:'2px',top:'-2px',border: '1px solid',borderTop:'none',borderRight:'none'}}>
                <div style={{  fontSize: "7px", fontWeight: "700" ,}}>Size :{data.size}</div>
                </div>
                <div className='col-12' style={{position:"relative",left:'0px',top:'-1px',lineHeight: '8px',display:'flex',flexDirection:"column"}}>
{data.brand.brandName.trim()!=="BIGBASKET"?<div style={{  fontSize: "7px", fontWeight: "700",paddingBottom:'4px' }}>{data.productname}</div>:<>
<span style={{  fontSize: "7px", fontWeight: "700",paddingBottom:'1px' }}>{data.productname1}</span>
<span style={{  fontSize: "7px", fontWeight: "700",paddingBottom:'4px' }}>{data.productname2}</span>

</>}      
          </div>
              </div>
            
              </div>
              <div className='row' style={{padding:'3px',position:"relative",top:"-8px"}}>
              <div className='col ' style={{position:"relative" ,top:'-4px',border:"1px solid black",borderRight:'none',borderLeft:'none'}}>
                <div style={{  fontSize: "6px", fontWeight: "700" ,}}>Capacity :{data.capacity}</div>
                </div>
                <div className='col ' style={{position:"relative" ,top:'-4px',border:"1px solid black",borderRight:'none'}}>
                <div style={{  fontSize: "6px", fontWeight: "700" ,}}>Diameter : {data.diameter}</div>
                </div>
              </div>
              <div className='row' style={{position:"relative" ,top:'-12px'}}>
                <div className='col-5'>
                  <div style={{  fontSize: "6px", fontWeight: "700" ,}}>Max. Retail Price :<br></br> (Inclusive of All Taxes)</div>
                </div>

                <div className='col'>
                  <div style={{ fontSize: "11px", fontWeight: "700"}}>₹{data.brand.brandName=="reliance"?data.ourprice:data.mrp}</div>
                </div>
                <div className='col'>
                  <div style={{ fontSize: "11px", fontWeight: "700"}}> Qty :{data.qty}</div>
                </div>
              </div>
<div style={{textAlign:"center",display:"flex", justifyContent:"center",overflow:"hidden",marginTop:'-8px'}}>
{/* <img style={{width:"191px",height:"31px",position:"relative",top:"-9px",}} src="https://barcode.tec-it.com/barcode.ashx?data=8904052700001&multiplebarcodes=true&translate-esc=on" alt="Barcode" /> */}
                 <div style={{ display: 'flex',marginTop:'-25px', flexDirection: 'column', lineHeight: '1px' }}>
                  <svg id="barcode" className='barcodeomkar'/>
                  <p style={{ fontSize: '7px',position:'relative',top:'3px',marginTop:'3px', fontWeight: "700", marginTop: '9px',zIndex:999999 }}>{data.eancode}</p>
                </div>
 </div>
<div className='row' style={{padding:'3px',position:"relative",top:"-11px"}}>
              <div className='col ' style={{position:"relative" ,top:'1px',border:"1px solid black",borderLeft:'none',borderRight:'none',borderBottom:'none'}}>
                <div style={{  fontSize: "7px", fontWeight: "700" ,}}>WASH BEFORE USE</div>
                </div>
                <div className='col ' style={{position:"relative" ,top:'1px',border:"1px solid black",borderBottom:'none',borderRight:'none'}}>
                <div style={{  fontSize: "7px", fontWeight: "700" ,}}>Mfg & Pkd on:{data.packedOn}</div>
                </div>
              </div>
             </div>
          </div>


          </>
        )):""}
         
      </div>
  
      <style>
        {`
        
        .p2{
        display:none
        }
        
        .print-content {
         
              display: grid;
              grid-template-columns: repeat(2, 40mm);
              gap: 21mm;  


            }


          @media print {
            @page {
              margin: 0;
            }
              .p2{
        display:block
        }
              .p1{
              display:none}
            body {
              margin: 0;
              padding: 0;
            }
            .rotate {
              width: 60mm;
              height: 45mm;
              margin: 0;
              padding: 0;
            }
            .btnmedia {
              display: none; /* Hide the print button during printing */
            }
            .print-content {
         
              display: grid;
              grid-template-columns: repeat(2, 40mm);
              gap: 7mm;  


            }
            .label-container {
              break-inside: avoid; /* Prevent breaking inside label-container */
            }
            .page-break {
              page-break-before: always;
              display: block;
              height: 0;
              margin: 0;
            }
          }
        `}
      </style>
    </dd>
  );
};


export default Print;



