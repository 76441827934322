import axios from "axios";
import authHeader from "./auth-header";

const API_URL = "http://43.225.54.138:8080/scm/api/workorder/";


const getAllWorkOrder = () => {
    return axios.get(API_URL +"getAll", { headers: authHeader() });
  };

  const getWorkOrderCreate = (workorder) => {
    return axios.post(API_URL +"create",workorder, { headers: authHeader() });
  };
const getWorkOrderEdit = (wid,workorder) => {
  return axios.put(API_URL + "update/"+wid, workorder,{ headers: authHeader() });
};

const getWorkOrder = (id) => {
  return axios.get(API_URL + "getById/"+id,{ headers: authHeader() });
};

const getDeleteWorkOrder = ( wid ) => {
    return axios.delete(API_URL + "delete/"+wid, { headers: authHeader() });
  };

  const getWorkOrderProduct = (id) => {
    return axios.get(API_URL + "warehouse/"+id,{ headers: authHeader() });
  };

  const getPrimaryOrder = (id,from_date,to_date) => {
    return axios.get(API_URL + id+'/'+from_date+'/'+to_date,{ headers: authHeader() });
  };
const WorkOrderService = {
    getAllWorkOrder,
    getWorkOrderCreate,
    getWorkOrderEdit,
    getWorkOrder,
    getDeleteWorkOrder,
    getWorkOrderProduct,
    getPrimaryOrder

};

export default WorkOrderService;