import axios from "axios";
import { MDBCard } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Accordion, Button, Col, Form, Row, Table } from "react-bootstrap";
import Select from "react-select";
import { BASE_URL } from "../services/Api";
import authHeader from "../services/auth-header";
import ProductService from "../services/ProductService";
import { emptysalesreturndata, salesreturnaddtocart, salesreturncalmeasurement, salesreturncost, salesreturndiscount, salesreturnmeasurement, salesreturnqty, salesreturnremovefromcart, salesreturnsqp, salesreturntext } from "../../redux/Action/salesreturn";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import CompanyService from "../services/CompanyService";

export default function Create() {
    const [isLoading, setIsLoading] = useState(false);
    const applyFontSizeTransition = (elementId) => {
      const element = document.getElementById(elementId);
      element.style.transition = 'font-size 2s';
      element.style.fontSize = '30px';
      element.style.fontWeight = '600';
      // font-weight: 600;
      setTimeout(() => {
         
        document.getElementById("typer1").classList.add('promote');
        element.style.fontSize = '24px';
      }, 1000);
    }; const modes1=[
        { "id": 1, "label": "Road" },
        { "id": 2, "label": "Rail" },
        { "id": 3, "label": "Air" },
        { "id": 4, "label": "Ship" },
        { "id": 5, "label": "In Transit" }
      ]
      const vechiletype=[
        { "id": "R", "label": "Regular" },
        { "id": "O", "label": "ODC" }
      ]
    const [supTyp, setsupTyp] = useState('');
    const [dt1, setdt1] = useState('');
    const [seller, setseller] = useState({});
    const [buyer, setbuyer] = useState({});
    const [distance, setdistance] = useState('');
    const [transMode, settransMode] = useState('');
    const [setsellindata, setSetsellindata] = useState([])
    const handleChange = (e) => setseller({ ...seller, [e.target.name]: e.target.value });
    const handleChange1 = (e) => setbuyer({ ...buyer, [e.target.name]: e.target.value });
    const [branchdata, setbranchdata] = useState([]);
    const [branch, setbranch] = useState('');
    const [customerData, setCustomerData] = useState([]);
    const [retailer, setretailer] = useState('');
    const [customer,setcustomer] =useState('');
    const [distributor, setdistributor] = useState('');
    const [warehousehdata, setwarehousehdata] = useState([]);
    const [warehousehvalue, setwarehousehvalue] = useState([]);
    const [warehouse, setwarehouse] = useState('');
    const [productdata, setproductdata] = useState([]);
    const [salesreturndate, setsalesreturndate] = useState('');
    const [creditnoteno, setcreditnoteno] = useState('');
    const [paymentTerms, setpaymentTerms] = useState('');
    const [invoiceno, setinvoiceno] = useState('');
    const [invoicedate, setinvoicedate] = useState("");
    const [otherrefernce, setotherrefernce] = useState('');
    const [buyerorderno, setbuyerorderno] = useState('');
    const [dispatcheddocno, setdispatcheddocno] = useState('');
    const [dispatchedthrough, setdispatchedthrough] = useState('');
    const [destination, setdestination] = useState('');
    const [termsofdelivery, settermsofdelivery] = useState('');
    const [declaration, setdeclaration] = useState('');
    const [boxProductDiscount, setboxProductDiscount] = useState('');
    const [schemeboxProductDiscount, setschemeboxProductDiscount] = useState('');
    const [kgProductDiscount, setkgProductDiscount] = useState('');
    const [schemekgProductDiscount, setschemekgProductDiscount] = useState('');
    const [corporaetProductDiscount, setcorporaetProductDiscount] = useState('');
    const [schemecorporateProductDiscount, setschemecorporateProductDiscount] = useState('');
    const [cookerProductDiscount, setcookerProductDiscount] = useState('');
    const [schemecookerProductDiscount, setschemecookerProductDiscount] = useState('');
    const [noshProductDiscount, setnoshProductDiscount] = useState('');
    const [schemenoshProductDisocunt, setschemenoshProductDisocunt] = useState('');
    const [dstateid, setdstateid] = useState('');
    const [bstateid, setbstateid] = useState('');
    const [shippingcharge, setShipping] = useState(0);
    const [round, setRound] = useState(0);
    const [shippingfortax, setshippingfortax] = useState('');
    const [transName, settransName] = useState('');
    const [transDocDt1, settransDocDt1] = useState('');
    const [transDocNo, settransDocNo] = useState('');
    const [vehNo, setvehNo] = useState('');
    const [vehType, setvehType] = useState('');
    const [transId, settransId] = useState('');

    const [voucherid, setvoucher] = useState([])
    const Onchangevoucher = (e) => {
        setvoucher(e.label)
    }
    const[data11,setdata11]=useState([])
    const [disword11, setdisword11] = useState(encodeURIComponent(' '))
const [distributordata111, setdistributordata111] = useState([])
useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}transporter/page/0/200/asc/id/${disword11.length ? disword11 : encodeURIComponent(' ')}`,
        { headers: authHeader() }
      );

      let options = [];
      if (response.data.Index) {
        setdata11(response.data.Index)
        options = response.data.Index.map(item => ({
          value: item.id,
          label: item.transporter_Gstin,
        }));
      } else if (response.data.data) {
        setdata11(response.data.data)
        options = response.data.data.map(item => ({
          value: item.id,
          label: item.transporter_Gstin,
        }));
      } else {
        console.warn('Unexpected response structure:', response.data);
      }

      // Add a placeholder option at the beginning
      const optionsWithPlaceholder = [
        // { value: '', label: 'Select an option' }, // Placeholder option
        ...options,
      ];

      setdistributordata111(optionsWithPlaceholder);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, [disword11]);
const handletrans=(e)=>{
    settransId(e.label)
    console.log(e)
    console.log((data11.filter((item)=>item.id==e.value).map((item)=>item.transporter_Name))
    )
    settransName((data11.filter((item)=>item.id==e.value).map((item)=>item.transporter_Name))[0])
    } 
    const dispatch = useDispatch();
    let navigate = useNavigate();
   
    const salesReturnItems = useSelector((state) => state.salesreturndata);
    let gross_Amount = salesReturnItems.length && salesReturnItems.map(item => Math.round(item.total)).reduce((prev, next) => Math.round(prev - (-next)));
    let gst = salesReturnItems.length && salesReturnItems.map(item => (item.gstvalue)).reduce((prev, next) => (prev - (-next)));
    // let gst = salesItems1.length && salesItems1.map(item => (item.gstvalue)).reduce((prev, next) => (prev - (-next)));
    let cgstVal = salesReturnItems.length && salesReturnItems.map(item => (item.gstvalue)).reduce((prev, next) => (prev - (-next)));
    // console.log("mello",salesReturnItems)

     let gross_AmountassVal = salesReturnItems.length && salesReturnItems.map(item => (item.netAmount)).reduce((prev, next) => (prev - (-next)));
     let da_ta = salesReturnItems.length && salesReturnItems.map((user) => ({
        "brand": user.brand,
        "calunitofmeasurement": user.calunitofmeasurement,
        "capacity": user.capacity,
        "category": user.category,
        "cgst": user.cgst,
        "cgstLedger": user.cgstLedger,
        "diameter": user.diameter,
        "dlp": user.dlp,
        "eanCode": user.eanCode,
        "grossamount": user.grossamount,
        "gstvalue": user.gstvalue,
        "hsnCode": user.hsnCode,
        "igst": user.igst,
        "igstLedger": user.igstLedger,
        "netAmount": user.netAmount,
        "product": { "id": user.product.id },
        "productName": user.productName,
        "productType": user.productType,
        "salesquantity": user.salesquantity,
        "salesquantitykgs": user.salesquantitykgs,
        "schemeDiscount": user.schemeDiscount,
        "sgst": user.sgst,
        "sgstLedger": user.sgstLedger,
        "shortName": user.shortName,
        "soid": user.soid,
        "standardQtyPerBox": user.standardQtyPerBox,
        "total": user.total,
        "tradeDiscount": user.tradeDiscount,
        "unitofmeasurement": user.unitofmeasurement,
        "uomPrimary": user.uomPrimary,
        "uomSecondary": user.uomSecondary,
        // "SlNo":user.soid,
        "prdDesc": user.PrdDesc,
        "isServc": "N",
        "qty": user.salesquantitykgs,
        "freeQty": user.salesquantity,
        "unit": user.unitofmeasurement,
        "totAmt": user.grossamount,
        "discount": ((Number(user.grossamount) / 100) * Number(user.schemeDiscount)).toFixed(2),
        "assAmt": user.netAmount,
        "gstRt": user.igst,
        "igstAmt": seller.stcd === buyer.stcd ? 0 : Number(user.gstvalue),
        "cgstAmt": seller.stcd === buyer.stcd ? ((user.gstvalue) / 2) : 0,
        "sgstAmt": seller.stcd === buyer.stcd ? ((user.gstvalue) / 2) : 0,
        "totItemVal": user.total


    }));
    
    let aa = [];
    salesReturnItems.map(user => {
        let igst_value = user.igst;
        let total_value = user.total;
        let total_amou = ((Number(shippingcharge) / Number(gross_Amount)) * Number(total_value));
        let add_amou = Number(total_amou) - (-Number(total_value));
        let final_amou = ((Number(add_amou) / 100) * Number(igst_value)).toFixed(2);
        aa.push(final_amou)

        // console.log("mello",((Number(add_amou)/100)*Number(igst_value)).reduce((prev, next) => Math.round(prev - (-next))) );
    })
    let total_per = aa.length && aa.map(user => user).reduce((prev, next) => (prev - (-next)));
    



    let grossAmount = Number(gross_Amount) + Number(shippingcharge);
    let grandTotal = Number(gross_Amount) + Number(shippingcharge) + Number(round) + Number(total_per);

    let igstTax = total_per == "" ? Number(gst) : Number(total_per);

    let cgstTax = total_per == "" ? (Number(gst) / 2) : (Number(total_per)) / 2;
    let sgstTax = total_per == "" ? (Number(gst) / 2) : (Number(total_per)) / 2;

    console.log(salesReturnItems)

    function roundData(e){
        if(e){
            let gross_Amount = salesReturnItems.length && salesReturnItems.map(item => Math.round(item.total)).reduce((prev, next) => Math.round(prev - (-next)));
            let gst = salesReturnItems.length && salesReturnItems.map(item => (item.gstvalue)).reduce((prev, next) => (prev - (-next)));
            // console.log("mello",salesReturnItems)
            let aa = [];
            salesReturnItems.map(user => {
                let igst_value = user.igst;
                let total_value = user.total;
                let total_amou = ((Number(shippingcharge) / Number(gross_Amount)) * Number(total_value));
                let add_amou = Number(total_amou) - (-Number(total_value));
                let final_amou = ((Number(add_amou) / 100) * Number(igst_value)).toFixed(2);
                aa.push(final_amou)
        
                // console.log("mello",((Number(add_amou)/100)*Number(igst_value)).reduce((prev, next) => Math.round(prev - (-next))) );
            })
            let total_per = aa.length && aa.map(user => user).reduce((prev, next) => (prev - (-next)));
            
        
        
        
            let grossAmount = Number(gross_Amount) + Number(shippingcharge);
            let grandTotal = Number(gross_Amount) + Number(shippingcharge)  + Number(total_per);
             let grand_Total = Math.ceil(grandTotal);
        
             if(grand_Total > grandTotal){
                 let rou_nd = (Number(grand_Total) - Number(grandTotal)).toFixed(2);
                 setRound(rou_nd);
             } else{
                 setRound(0)
             }
               
              
        } else if(e==''){
            setRound(0)
        }
    }
    /* Get Branch Data */

    const getbranchdata = () => {

        axios.get(BASE_URL + 'branch/getAll', {
            headers: authHeader()
        }).then(res => {
            // console.log("mello",res.data)
            setbranchdata((res.data).map(user => ({ value: user.id, label: user.branchname })));
        })
    };


    /* get Product Data */

    let getProductData = () => {

        axios.get( BASE_URL + 'product/page/0/100/asc/id/%20',{
            headers:authHeader()
        }).then(res=>{

            setproductdata((res.data.Index).map(use => ({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code + ' - ' + use.short_Name })));
        })

    };

    const customFilter = (e) => {
        let name = e;
        axios.get( BASE_URL + 'product/page/0/100/asc/id/'+name,{
            headers:authHeader()
        }).then(res=>{
         setproductdata((res.data.data).map(use=>({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code + ' - ' + use.short_Name })))
        })
    }

    /* Current Date */
    const datevalue = new Date();
    const defaultValue = datevalue.toLocaleDateString('en-CA');
    const datevalue1 = new Date();
    const defaultValue1 = datevalue1.toLocaleDateString('en-CA');
    useEffect(() => {
        getbranchdata();
        getProductData();
        getcompanydata();
        setsalesreturndate(defaultValue);
        setinvoicedate(defaultValue1);
        dispatch(emptysalesreturndata());
    }, []);


    /* onChange Distributor */

    const onChangeCustomer = (e) => {
        // console.log("mello",e);
        let name = e.value;
        setdistributor({ "id": e.value });

        axios.get(BASE_URL + 'distributor/getById/' + name, {
            headers: authHeader()
        }).then(res => {
            setbuyer({ "gstin": res.data.gstNumber, "loc": res.data.city, "pos": res.data.stateid, "stcd": res.data.stateid, "addr1": res.data.billingAddress, "lglNm": res.data.tradeName, "pin": res.data.pinCode, "ph": res.data.perMobileNumber, "em": res.data.perEmail })
            setdstateid(res.data.state.id);
            setboxProductDiscount(res.data.boxProductDiscount);
            setschemeboxProductDiscount(res.data.schemeboxProductDiscount);
            setkgProductDiscount(res.data.kgProductDiscount);
            setschemekgProductDiscount(res.data.schemekgProductDiscount);
            setcorporaetProductDiscount(res.data.corporaetProductDiscount);
            setschemecorporateProductDiscount(res.data.schemecorporateProductDiscount);
            setcookerProductDiscount(res.data.cookerProductDiscount);
            setschemecookerProductDiscount(res.data.schemecookerProductDiscount);
            setnoshProductDiscount(res.data.noshProductDiscount);
            setschemenoshProductDisocunt(res.data.schemenoshProductDisocunt);
        })
    };

    const onChangeCustomer1 = (e) => {
        // console.log("mello",e);
        let name = e.value;
        setretailer({ "id": e.value });

        axios.get(BASE_URL + 'retailer/getById/' + name, {
            headers: authHeader()
        }).then(res => {
setbuyer({ "gstin": res.data.gstNumber, "loc": res.data.city, "pos": res.data.stateid, "stcd": res.data.stateid, "addr1": res.data.billingAddress, "lglNm": res.data.tradeName, "pin": res.data.pinCode, "ph": res.data.perMobileNumber, "em": res.data.perEmail })
            // setdel(res.data.deliveryAddress);
            setdstateid(res.data.stateid);
            setboxProductDiscount(res.data.boxProductDiscount);
            setschemeboxProductDiscount(res.data.schemeboxProductDiscount);
            setkgProductDiscount(res.data.kgProductDiscount);
            setschemekgProductDiscount(res.data.schemekgProductDiscount);
            setcorporaetProductDiscount(res.data.corporaetProductDiscount);
            setschemecorporateProductDiscount(res.data.schemecorporateProductDiscount);
            setcookerProductDiscount(res.data.cookerProductDiscount);
            setschemecookerProductDiscount(res.data.schemecookerProductDiscount);
            setnoshProductDiscount(res.data.noshProductDiscount);
            setschemenoshProductDisocunt(res.data.schemenoshProductDisocunt);
        })
    };

    let onChangeBranch = (e) => {
        let name = e.value;
        setbranch({ "id": e.value });
        setwarehousehvalue("");

        axios.get(BASE_URL + 'branch/' + name, {
            headers: authHeader()
        }).then(res => {
            // console.log("mello",res.data)
            setbstateid(res.data.states.id)
        })

        axios.get(BASE_URL + 'warehouse/getAll', {
            headers: authHeader()
        }).then(res => {
            let branch_data = [];
            (res.data).map(user => {
                // let branchid = user.branch.id;
                if (user.branch !== null) {
                    if (user.branch.id === e.value) {
                        branch_data.push({ value: user.id, label: user.name })
                    }
                }

            })

            setwarehousehdata(branch_data)

        })
    };
    const onChangewarehouse = (e) => {
        setwarehouse({ "id": e.value })
        setwarehousehvalue({ value: e.value, label: e.label })
    }
    let addData = (e) => {
        const filteredItems = salesReturnItems.filter(item => item.product.id === e.value);
        if (branch == "" || distributor == "" && retailer == '') {
            Swal.fire({
                title: 'Please Branch or Distributor First Select',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonColor: 'red'
            })
        } else {
            if (filteredItems.length == "0") {
                dispatch(salesreturnaddtocart(e.value, ({
                    "schemeboxProductDiscount": schemeboxProductDiscount,
                    "schemekgProductDiscount": schemekgProductDiscount,
                    "schemecorporateProductDiscount": schemecorporateProductDiscount,
                    "schemecookerProductDiscount": schemecookerProductDiscount,
                    "schemenoshProductDisocunt": schemenoshProductDisocunt,
                    "kgProductDiscount": kgProductDiscount,
                    "boxProductDiscount": boxProductDiscount,
                    "corporaetProductDiscount": corporaetProductDiscount,
                    "cookerProductDiscount": cookerProductDiscount,
                    "noshProductDiscount": noshProductDiscount
                })));
                setShipping('0');
            } else {
                Swal.fire({
                    title: 'This Product is Already Added',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showConfirmButton: false,
                    showCancelButton: true,
                    cancelButtonColor: 'red'
                })
            }
        }
    };

    /* get Shipping Charges */
    let settingship = (e) => {
        if (e == "") {

            setShipping("");
        } else {

            setShipping(e);
        }
    }

    /* get Round Value */
    const settinground = (e) => {

        if (e == "") {

            setRound("");
        } else {


            setRound(e);
        }

    };

    const SGSTcheck = () => {
        let aa = 0



        if (bstateid === dstateid) {

            aa = sgstTax;

        } else {
            aa = 0;
        }



        return aa;

    }

    const cgstcheck = () => {

        let bb = 0



        if (bstateid === dstateid) {

            bb = cgstTax;

        } else {

            bb = 0;

        }


        return bb;
    }

    const igstcheck = () => {
        let cc = 0;


        if (bstateid === dstateid) {
            cc = 0;
        } else {
            cc = igstTax;
        }

        return cc;
    }

console.log(setsellindata)
console.log(buyer)
    const submitdata = () => {
        setIsLoading(true); 
        let tranDtls = {  supTyp }
        let docDtls={"dt":dt1,"no":no}
        // "docDtls": {
        //     "typ": "CRN",
        //     "no": "Doc/7123",
        //     "dt": "03/07/2024"
        // },
        let igst = igstcheck();
        let cgst = cgstcheck();
        let sgst = SGSTcheck();
        let grandtotal = grandTotal;
        let roundingoff = round;
        let company = {"id":1}
        let valdtls = { "assVal": gross_AmountassVal, "cgstVal": Number(seller.stcd) === Number(buyer.stcd) ? (cgstVal / 2) : 0, "sgstVal": seller.stcd === buyer.stcd ? (cgstVal / 2) : 0, "igstVal": seller.stcd === buyer.stcd ? 0 : cgstVal, "othChrg": shippingcharge, "totInvVal": grossAmount, "cesVal": 0, "stCesVal": 0 }
      const salesretunrn=salesReturnItems.map((user) => ({
...user,
"igstAmt": seller.stcd === buyer.stcd ? 0 : Number(user.gstvalue),
"cgstAmt": seller.stcd === buyer.stcd ? ((user.gstvalue) / 2) : 0,
"sgstAmt": seller.stcd === buyer.stcd ? ((user.gstvalue) / 2) : 0,
"totItemVal": user.total,     
"isServc": "N",
      }));
        let salesreturndata = {
            branch, warehouse, distributor,voucherid, salesreturndate, creditnoteno, paymentTerms, invoiceno, invoicedate, otherrefernce, buyerorderno, dispatcheddocno, dispatchedthrough,
            destination, termsofdelivery, declaration, salesReturnItems, igst, cgst, sgst,company, grossAmount, roundingoff, grandtotal
        }
        if(customer === 'distributor'){
        axios.post(BASE_URL + 'sr/', {
            branch, warehouse,tranDtls, distributor,voucherid, salesreturndate, creditnoteno, paymentTerms, invoiceno, invoicedate, otherrefernce, buyerorderno, dispatcheddocno, dispatchedthrough,
            destination, termsofdelivery,docDtls:docDtls,valDtls:valdtls, declaration,sellerDtls:setsellindata,buyerDtls:buyer, salesReturnItems:da_ta, igst, cgst, sgst,company, grossAmount, roundingoff, grandtotal
        }, {
            headers: authHeader()
        }).then(res => {
            navigate('/salesreturn/1');
        }).catch(error => {
            console.error('Error adding  :', error);
            if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `
                <div>
                  <p id="issueText">Why do I have this issue?</p>
                  <ul>
              
                  <li id="typer1">You might have an internet issue</li>
                  </ul>
                </div>
              `,
              didOpen: () => {
                applyFontSizeTransition('issueText');
              }
            });
          }
          })
          .finally(() => {
            setIsLoading(false); 
          });
    } else if(customer === 'retailer'){
        axios.post(BASE_URL + 'sr/', {
            branch, warehouse,tranDtls, retailer,voucherid, salesreturndate, creditnoteno, paymentTerms, invoiceno, invoicedate, otherrefernce, buyerorderno, dispatcheddocno, dispatchedthrough,
            destination, termsofdelivery,docDtls:docDtls,valDtls:valdtls,sellerDtls:setsellindata,buyerDtls:buyer, declaration, salesReturnItems:da_ta, igst, cgst, sgst,company, grossAmount, roundingoff, grandtotal
        }, {
            headers: authHeader()
        }).then(res => {
            navigate('/salesreturn/1');
        }).catch(error => {
            console.error('Error adding  :', error);
            if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `
                <div>
                  <p id="issueText">Why do I have this issue?</p>
                  <ul>
              
                  <li id="typer1">You might have an internet issue</li>
                  </ul>
                </div>
              `,
              didOpen: () => {
                applyFontSizeTransition('issueText');
              }
            });
          }
          })
          .finally(() => {
            setIsLoading(false); 
          });
    }

    }

    

    let getcompanydata = () => {
        const id = 1;
        CompanyService.getcompany(id).then((res) => {
            const transformedData = {
                "gstin": res.data.gst,
                "lglNm": res.data.name,
                "addr1": res.data.address,
                "loc": res.data.cities,
                "pin": parseInt(res.data.zipcode),
                "stcd": res.data.states.state_code,
                "ph": res.data.phone,
                "em": res.data.email
            };
            setSetsellindata(transformedData)
            console.log("mello", [transformedData].map((item) => item.gstin));
        });
    };

    const [disword, setdisword] = useState(encodeURIComponent(' '))
    const [dis,setdistributordata1]=useState([])
    const [distributordata12, setdistributordata12] = useState([])
    const [disword12, setdisword12] = useState(encodeURIComponent(' '))
    const handlesuptype=(e)=>{
        setsupTyp(e.id)
     }
     const [no, setno] = useState('');

    useEffect(() => {
        if(customer === "distributor"){
            axios.get(`http://43.225.54.138:8080/scm/api/distributor/page/0/10/asc/id/${disword.length?disword:encodeURIComponent(' ')}`, { headers: authHeader() })
            .then(response => {
          console.log(response)
          if(response.data.data==undefined){
            setdistributordata1(response.data.Index.map(use => ({ value: use.id, label: use.trade_Name})));
         }  if(response.data.Index==undefined){
          setdistributordata1(response.data.data.map(use => ({ value: use.id, label: use.trade_Name})));
        
         }
            
         })
        } else if(customer === 'retailer'){
            axios.get(`http://43.225.54.138:8080/scm/api/retailer/page/0/100/asc/id/${disword.length?disword:encodeURIComponent(' ')}`, { headers: authHeader() })
            .then(response => {
        //   console.log(response,"mello")
          if(response.data.data==undefined){
            setdistributordata1(response.data.Index.map(use => ({ value: use.id, label: use.retailername})));
          }  if(response.data.Index==undefined){
          setdistributordata1(response.data.data.map(use => ({ value: use.id, label: use.retailername})));
          
          }
            
           })
        }
    
        axios.get(BASE_URL + `voucher/page/0/100/desc/id/${disword12.length ? disword12 : encodeURIComponent(' ')}`, {
            headers: authHeader()
        }).then(response => {
            if (response.data.data == undefined) {
                setdistributordata12(response.data.Index.map(use => ({ value: use.voucherseries, label: use.voucherseries })));
            } if (response.data.Index == undefined) {
                setdistributordata12(response.data.data.map(use => ({ value: use.voucherseries, label: use.voucherseries })));
    
            }
         })
    
      
      }, [ customer,disword,disword12])

      const stype = [
        { "id": "B2B", "label": "Business to Business" },
        { "id": "SEZWP", "label": "SEZ with payment" },
        { "id": "SEZWOP", "label": "SEZ without payment" },
        { "id": "EXPWP", "label": "Export with Payment" },
        { "id": "EXPWOP", "label": "Export without payment" },
        { "id": "DEXP", "label": "Deemed Export" }
    ]
  
    return (
        <MDBCard className="company">
            <h1>Sales Return Create</h1>
            <Link className='p-2 w-200 card-header card-title bd-highlight' to={`/salesreturn/1`}><Button>Back</Button></Link>

            <Form className="product1">
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Sales Return Data</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                            <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Choose Customer</Form.Label>
                                    <Form.Select onChange={(e) => setcustomer(e.target.value)}>
                                        <option>Select</option>
                                        <option value={'retailer'}>Retailer</option>
                                        <option value={'distributor'}>Distributor</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Branch Name</Form.Label>
                                    <Select options={branchdata} onChange={(e) => onChangeBranch(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">WareHouse Name</Form.Label>
                                    <Select value={warehousehvalue} options={warehousehdata} onChange={(e) => onChangewarehouse(e)} />
                                </Form.Group>
                                {customer === "distributor" &&
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Customer Name</Form.Label>
                                    {/* <Select options={customerData} onChange={(e) => onChangeCustomer(e)} /> */}
                                    <Select options={dis}  onInputChange={(inputValue) => setdisword(inputValue)} onChange={(options) => onChangeCustomer(options)}/>

                                </Form.Group>}
                                {customer === 'retailer' && 
                                 <Form.Group as={Col} md="4" controlId="formBasicName">
                                 <Form.Label className="label">Customer Name</Form.Label>
                                 {/* <Select options={customerData} onChange={(e) => onChangeCustomer(e)} /> */}
                                 <Select options={dis}  onInputChange={(inputValue) => setdisword(inputValue)} onChange={(options) => onChangeCustomer1(options)}/>

                             </Form.Group>}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Date</Form.Label>
                                    <Form.Control type="date" value={salesreturndate} onChange={(e) => setsalesreturndate(e.target.value)} />
                                </Form.Group>

                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Credit Note No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" onChange={(e) => setcreditnoteno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                <Form.Label className="label">Vouchers Series</Form.Label>
                                {/* <Select options={customerData} onChange={(e) => onChangeCustomer(e)} /> */}
                                    <Select options={distributordata12} onInputChange={(inputValue) => setdisword12(inputValue)} onChange={(options) => Onchangevoucher(options)} />

                                </Form.Group>


                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Terms Of Payment</Form.Label>
                                    <Form.Control type="text" autoComplete="off" onChange={(e) => setpaymentTerms(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Invoice No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" onChange={(e) => setinvoiceno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Invoice Date</Form.Label>
                                    <Form.Control type="date" onChange={(e) => setinvoicedate(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Other Reference</Form.Label>
                                    <Form.Control type="text" autoComplete="off" onChange={(e) => setotherrefernce(e.target.value)} />
                                </Form.Group>
                      



                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                   
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>Details</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Buyer Order No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" onChange={(e) => setbuyerorderno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Dispatched doc no</Form.Label>
                                    <Form.Control type="text" autoComplete="off" onChange={(e) => setdispatcheddocno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Dispatched Through</Form.Label>
                                    <Form.Control type="text" autoComplete="off" onChange={(e) => setdispatchedthrough(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Destination</Form.Label>
                                    <Form.Control type="text" autoComplete="off" onChange={(e) => setdestination(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Terms of Delivery</Form.Label>
                                    <Form.Control type="text" autoComplete="off" onChange={(e) => settermsofdelivery(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Declaration</Form.Label>
                                    <Form.Control type="text" autoComplete="off" onChange={(e) => setdeclaration(e.target.value)} />
                                </Form.Group>

                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>GST Details</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <h1>Transport Details</h1>
                                {/* <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Tax Type</Form.Label>
                                    <Form.Control type="text" autoComplete="off" placeholder="Enter Tax Type" name="taxSch" onChange={(e) => settaxSch(e.target.value)} />
                                </Form.Group> */}
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Type of supply</Form.Label>
                                    <Select options={stype} className='sett' onChange={handlesuptype}/>

                                    {/* <Form.Control type="text" autoComplete="off" placeholder="Enter Type of supply" onChange={(e) => setsupTyp(e.target.value)} /> */}
                                </Form.Group>
                                <h1>Document Details</h1>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Document No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" placeholder="Enter Document No" onChange={(e) => setno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Document Date</Form.Label>
                                    <Form.Control type="date" autoComplete="off" onChange={(e) => setdt1(e.target.value)} />
                                </Form.Group>
                                <h1>Seller Details</h1>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">GSTIN of Seller </Form.Label>
                                    <Form.Control type="text" value={[setsellindata].map((item) => item.gstin)} disabled autoComplete="off"  name="gstin" placeholder="Enter GSTIN of Supplier" onChange={(e) => {handleChange(e); }} />
                                </Form.Group>
                                {/* value={[setsellindata].map((item) => item.gstin)} disabled */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Legal Name</Form.Label>
                                    <Form.Control type="text" autoComplete="off" value={[setsellindata].map((item) => item.lglNm)} disabled name="lglNm" placeholder="Enter Legal Name" onChange={(e) => handleChange(e)} />
                                </Form.Group>
                                {/* value={[setsellindata].map((item) => item.lglNm)} disabled  */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Address1</Form.Label>
                                    <Form.Control type="text"  autoComplete="off" value={[setsellindata].map((item) => item.addr1)} disabled name="addr1" placeholder="Enter Address1" onChange={(e) => handleChange(e)} />
                                </Form.Group>
                                {/* value={[setsellindata].map((item) => item.addr1)} disabled */}
                                {/* <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Address2</Form.Label>
                                    <Form.Control type="text" autoComplete="off" name="addr2" placeholder="Enter Address2" onChange={(e) => handleChange(e)} />
                                </Form.Group> */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Location</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Location"  value={[setsellindata].map((item) => item.loc)} disabled autoComplete="off" name="loc" onChange={(e) => handleChange(e)} />
                                </Form.Group>
                                {/* value={[setsellindata].map((item) => item.loc)} disabled */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">PinCode</Form.Label>
                                    <Form.Control type="text"  placeholder="Enter PinCode" name="pin" value={[setsellindata].map((item) => item.pin)} disabled autoComplete="off" onChange={(e) => handleChange(e)} />
                                </Form.Group>
                                {/* value={[setsellindata].map((item) => item.pin)} disabled */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">State Code</Form.Label>
                                    <Form.Control type="text"  placeholder="Enter State Code"  value={[setsellindata].map((item) => item.stcd)} disabled autoComplete="off" name="stcd" onChange={(e) => handleChange(e)} />
                                </Form.Group>
                                {/* value={[setsellindata].map((item) => item.stcd)} disabled */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Phone Number</Form.Label>
                                    <Form.Control type="text"  placeholder="Enter Phone Number"  value={[setsellindata].map((item) => item.ph)} disabled name="ph" autoComplete="off" onChange={(e) => handleChange(e)} />
                                </Form.Group>
                                {/* value={[setsellindata].map((item) => item.ph)} disabled */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Email Id</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Email Id"  disabled value={[setsellindata].map((item) => item.em)}  name="em" autoComplete="off" onChange={(e) => handleChange(e)} />
                                </Form.Group>
                                {/* disabled value={[setsellindata].map((item) => item.em)} */}
                                <h1>Buyer Details</h1>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">GSTIN of Buyer</Form.Label>
                                    <Form.Control type="text" placeholder="Enter GSTIN of Buyer" value={buyer.gstin || ""} name="gstin" autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Legal Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Legal Name" value={buyer.lglNm || ""} name="lglNm" autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">State Code of Place of Supply</Form.Label>
                                    <Form.Control type="text" placeholder="Enter State Code of Place of Supply" value={buyer.pos || ""} name="pos" autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Address1</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Address1" value={buyer.addr1 || ""} name="addr1" autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group>
                                {/* <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Address2</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Address2" value={buyer.addr2 || ""} name="addr2" autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group> */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Location</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Location" value={buyer.loc || ""} name="loc" autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">PinCode </Form.Label>
                                    <Form.Control type="text" placeholder="Enter PinCode" value={buyer.pin || ""} name="pin" autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">State Code</Form.Label>
                                    <Form.Control type="text" placeholder="Enter State Code" value={buyer.stcd || ""} name="stcd" autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Phone No</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Phone No" value={buyer.ph || ""} name="ph" autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Email ID</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Email ID" value={buyer.em || ""} name="em" autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group>
                              
                                
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <br></br>
                <Row className="mb-1">
                    <Form.Group as={Col} md="12" controlId="formBasicName">
                        <Form.Label className="label">Product</Form.Label>
                        <Select options={productdata} onChange={(e) => addData(e)} onInputChange={(e) => customFilter(e) } />
                    </Form.Group>
                </Row>
                <br></br>
                <Table striped responsive hover size="sm">
                    <thead className='p-3 mb-2 bg-success text-white'>
                        <tr>
                            <th>PRODUCT&nbsp;NAME</th>
                            <th >UOM</th>
                            <th>QTY(PCS)</th>
                            <th>QTY(KG)</th>
                            <th >Rate</th>
                            <th >UOM</th>
                            <th >Trade&nbsp;Discount</th>
                            <th>GROSS AMOUNT (₹)</th>
                            <th >SCHEME DISCOUNT %</th>
                            <th >NET AMOUNT (₹)</th>
                            <th >GST Value</th>
                            <th >Amount</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody style={{ overflow: "scroll" }}>
                        {salesReturnItems.map(item =>
                            <tr key={item.product.id}>
                                <td>
                                    <Form.Group as={Col} md="12" >
                                        {/* <Form.Control type="text" value={item.productName} size="sm" /> */}
                                        <textarea type="text"   className="form-control textcontrol" value={item.productName} size="sm" ></textarea>

                                    </Form.Group>
                                    <Form.Control as="textarea" onChange={(e) => { dispatch(salesreturntext(e.target.value, item)) }} value={item.text} rows={2} className='form-control' />
                                </td>
                                <td><Form.Group as={Col} md="12">
                                    <Form.Select disabled={item.productType !== "kgproduct" ? true : false} onChange={(e) => { dispatch(salesreturnmeasurement(e.target.value, item, item.unitofmeasurement)); }} size="sm">
                                        <option>{item.productType !== "kgproduct" ? 'PCS' : "Select"}</option>
                                        <option value="pcs">PCS</option>
                                        <option value="kg">KG</option>
                                    </Form.Select>
                                </Form.Group>
                                </td>

                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" size="sm" onChange={(e) => { dispatch(salesreturnsqp(e.target.value, item, item.unitofmeasurement));roundData(e.target.value) }}  value={item.srquantity} disabled={item.unitofmeasurement == ""||item.unitofmeasurement == "kg"  ? true : false} />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" onChange={(e) => { dispatch(salesreturnqty(e.target.value, item, item.unitofmeasurement));roundData(e.target.value) }} value={Number(item.srquantitykgs)} disabled={item.unitofmeasurement == "box"||item.unitofmeasurement == "pcs"  ? true : false } size="sm" requied />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" size="sm" onChange={(e) => { dispatch(salesreturncost(e.target.value, item,));roundData(e.target.value) }} value={Number(item.dlp)} />
                                    </Form.Group>
                                </td>
                                <td><Form.Group as={Col} md="12">
                                    <Form.Select disabled={item.productType !== "kgproduct" ? true : false} onChange={(e) => { dispatch(salesreturncalmeasurement(e.target.value, item, item.calunitofmeasurement)); }} size="sm">
                                        <option>{item.productType !== "kgproduct" ? 'PCS' : "Select"}</option>
                                        <option value="pcs">PCS</option>
                                        <option value="kg">KG</option>
                                    </Form.Select>
                                </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" min="0" size="sm" value={Number(item.tradeDiscount)} />
                                    </Form.Group>
                                </td>
                                <td><Form.Group as={Col} md="12">
                                    <Form.Control type="number" min="0" value={Number(item.grossamount)} size="sm" readOnly requied />
                                </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" onChange={(e) => { dispatch(salesreturndiscount(e.target.value, item,));roundData(e.target.value) }} value={Number(item.schemeDiscount)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td><Form.Group as={Col} md="12">
                                    <Form.Control type="number" min="0" value={Number(item.netAmount)} size="sm" readOnly requied />
                                </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" value={Number(item.gstvalue)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" value={Number(item.total)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type='button' className='btn btn-danger' onClick={() => {dispatch(salesreturnremovefromcart(item.product.id));roundData('')}} value="X" size="sm" />
                                    </Form.Group>
                                </td>

                            </tr>
                        )}
                    </tbody>
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Packing&nbsp;&&nbsp;Forwarding</td>
                            <td><Form.Group as={Col} md="12">
                                <Form.Control type="number" value={shippingcharge} onChange={(e) => settingship(e.target.value)} style={{ height: 30 }} />
                            </Form.Group></td>
                            <td></td>
                        </tr>
                        <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td style={{ fontSize: 13 }}>Net&nbsp;Amount</td>
                                <td> <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={grossAmount ? ((grossAmount).toFixed(2)) : "0"} style={{ height: 30 }} readOnly />
                                </Form.Group></td>
                                <td></td>
                            </tr>
                        <tr>


                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>IGST</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={igstcheck()} style={{ height: 30 }} requied />
                                </Form.Group>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>SGST</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={SGSTcheck()} style={{ height: 30 }} requied />
                                </Form.Group>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>CGST</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={cgstcheck()} style={{ height: 30 }} requied />
                                </Form.Group>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Round&nbsp;off&nbsp;Value</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={round} onChange={(e) => settinground(e.target.value)} style={{ height: 30 }} />
                                </Form.Group>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Grand&nbsp;Total</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={grandTotal ? ((grandTotal).toFixed(2)) : "0"} style={{ height: 30 }} readOnly />
                                </Form.Group>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </Table>
                {/* <Button variant="primary" type="button" onClick={submitdata}>
                    Submit
                </Button> */}
                <Button variant="primary" style={{width:'auto'}} onClick={submitdata} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Submit'}
        </Button>
            </Form>
        </MDBCard>
    )
}