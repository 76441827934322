import { CHANGE_MEASUREMENT_SALESORDER, SALESORDER_PUT_DATA_TO_LOAD } from "../constant";
import { WORKORDERBYSALESORDER_PUT_DATA_TO_LOAD } from "../constant";
import { PUT_NEW_SALESORDER_ADD_TO_CART } from "../constant";
import { CHANGE_SALESORDER_MEASUREMENT } from "../constant";
import { CHANGE_SALESORDER_SQPB_MEASUREMENT } from "../constant";
import { CHANGE_SALESORDER_QTY } from "../constant";
import { REMOVE_FROM_CART_FROM_SALESORDER } from "../constant";
import { CHANGE_COST_SALESORDER } from "../constant";
import { CHANGE_SALESORDER_DISCOUNT } from "../constant";
import { CHANGE_SALESORDER_TEXT } from "../constant";
import { EMPTY_SALESORDER_DATA } from "../constant";


export const salesorderdata = (data = [], action) => {

    switch (action.type) {
        case SALESORDER_PUT_DATA_TO_LOAD:

            //  console.log("mello",action.data)
            let getcurrentdata = [];
            (action.data).map(user => {
                let data = {

                    // amount:user.amount,
                    tradediscount: user.discount,
                    schemeDiscount: user.discount1,
                    dlp: user.dlp,
                    grossamount: user.grossamount,
                    gstvalue: user.gstvalue,
                    igst: user.igst,
                    someasurement: user.measurement,
                    netAmount: user.netAmount,
                    product: { "id": user.productId },
                    productName: user.productName,
                    soqty: user.qty,
                    soquantity_placed: user.quantity_placed,
                    soquantity_placed_kg: user.quantity_placed_kg,
                    total: user.total,
                    unitofmeasurement: user.unitofmeasurement,
                    sgst: user.sgst,
                    cgst: user.cgst,
                    mrp: user.mrp,
                    productType: user.productType,
                    standardQtyPerBox: user.standardQtyPerBox,
                    uomPrimary: user.uomPrimary,
                    uomSecondary: user.uomSecondary,
                    poid:user.poid

                }
                getcurrentdata.push(data);
            });


            return [...getcurrentdata];

            case WORKORDERBYSALESORDER_PUT_DATA_TO_LOAD:
                console.log('mello',action.data)

            let getcurrentdata1 = [];
            (action.data).map(user => {
                let data = {

                    // amount:user.amount,
                    tradediscount: user.discount,
                    schemeDiscount: user.discount1,
                    dlp: user.dlp,
                    grossamount: user.grossamount,
                    gstvalue: user.gstvalue,
                    igst: user.igst,
                    someasurement: user.measurement,
                    netAmount: user.total,
                    product: { "id": user.productId },
                    productName: user.productName,
                    soqty: user.qty,
                    // soquantity_placed: user.quantity_placed,
                    // soquantity_placed_kg: user.quantity_placed_kg,
                    total: user.amount,
                    unitofmeasurement: user.unitofmeasurement,
                    sgst: user.sgst,
                    cgst: user.cgst,
                    mrp: user.mrp,
                    productType: user.productType,
                    standardQtyPerBox: user.standardQtyPerBox,
                    uomPrimary: user.uom_primary,
                    uomSecondary: user.uomSecondary,
                    wid:user.wid

                }
                getcurrentdata1.push(data);
            });


            return [...getcurrentdata1];


        case PUT_NEW_SALESORDER_ADD_TO_CART:
            let newData3 = action.data2;
            let dataNew = action.data;

            function discountData1() {
                if (dataNew.productType == "boxproduct") {
                    return ({ "ProductDiscount": newData3.boxProductDiscount, "schemeDiscount": newData3.schemeboxProductDiscount });
                }
                else if (dataNew.productType == "kgproduct") {
                    return ({ "ProductDiscount": newData3.kgProductDiscount, "schemeDiscount": newData3.schemekgProductDiscount });
                }
                else if (dataNew.productType == "corporateproduct") {
                    return ({ "ProductDiscount": newData3.corporaetProductDiscount, "schemeDiscount": newData3.schemecorporateProductDiscount });
                }
                else if (dataNew.productType == "cookerproduct") {
                    return ({ "ProductDiscount": newData3.cookerProductDiscount, "schemeDiscount": newData3.schemecookerProductDiscount });
                }
                else if (dataNew.productType == "noshproduct") {
                    return ({ "ProductDiscount": newData3.noshProductDiscount, "schemeDiscount": newData3.schemenoshProductDisocunt });
                }
            }

            let aa = discountData1();

            let currentData = {
                brand: action.data.brand,
                capacity: action.data.capacity,
                category: action.data.category,
                cgst: action.data.cgst,
                cgstLedger: action.data.cgstLedger,
                diameter: action.data.diameter,
                eanCode: action.data.eanCode,
                hsnCode: action.data.hsnCode,
                igstLedger: action.data.igstLedger,
                dlp: action.data.dlp,
                standardQtyPerBox : action.data.standardQtyPerBox,
                sgst: action.data.sgst,
                sgstLedger: action.data.sgstLedger,
                shortName: action.data.shortName,
                uomPrimary: action.data.uomPrimary,
                uomSecondary: action.data.uomSecondary,
                tradediscount: aa.ProductDiscount,
                schemeDiscount: aa.schemeDiscount,
                product: { "id": action.data.id },
                igst: action.data.igst,
                mrp: action.data.mrp,
                productName: action.data.productName,
                productType:action.data.productType,
                unitofmeasurement: action.data.unitofmeasurement == undefined ?action.data.productType !== "kgproduct" ? "box" :'' :action.data.unitofmeasurement

            }
            
            return [currentData, ...data];
            
            case CHANGE_MEASUREMENT_SALESORDER:

            let newCart6 = [...data]
            let itemIndex6 = newCart6.findIndex(obj => obj.product.id === action.item.product.id)
            let currItem6 = data[itemIndex6]
            currItem6.unitofmeasurement = action.data
            data[itemIndex6] = currItem6

            return [...data];


        case CHANGE_SALESORDER_QTY:

            let newCart = [...data]
            let itemIndex = newCart.findIndex(obj => obj.product.id === action.item.product.id)
            let currItem = data[itemIndex]
            // console.log("mello",currItem)
            if (action.data3 == "kg") {

                if (action.data2 == "withtax") {

                    if (currItem.tradediscount > 0 || currItem.schemeDiscount > 0) {
                        if (action.data == "") {
                            currItem.soqty = Number(0);
                            currItem.someasurement = Number(0);
                        } else {
                            currItem.soqty = Number(action.data)
                            let aa = Number(currItem.soqty);
                            let ab = (Number(aa) / currItem.uomSecondary)
                            currItem.someasurement = Math.round(ab);


                            let a = (Number(currItem.someasurement) / Number(currItem.standardQtyPerBox));
                            if ((Number(Math.trunc(a)) * Number(currItem.standardQtyPerBox)) == Number(currItem.measurement)) {
                                currItem.soquantity_placed = Number(Number(currItem.someasurement));
                            } else {
                                currItem.soquantity_placed = (Number(Math.trunc(a) + 1) * Number(currItem.standardQtyPerBox))
                            }
                            currItem.soquantity_placed_kg = (Number(currItem.soquantity_placed) * Number(currItem.uomSecondary)).toFixed(3);

                        }

                        let discount2 = Number(currItem.soquantity_placed_kg) * Number(currItem.dlp) * (Number(currItem.tradediscount) / 100)
                        let totaAmount = (Number(currItem.soquantity_placed_kg) * Number(currItem.dlp) - Number(discount2))
                        currItem.grossamount = (Number(totaAmount)).toFixed(2);
                        let schemediscount = ((Number(totaAmount) * Number(currItem.schemeDiscount)) / 100);
                        let NetAmount = (Number(totaAmount) - Number(schemediscount));
                        currItem.netAmount = (Number(NetAmount)).toFixed(2)
                        currItem.gstvalue = ((Number(currItem.netAmount) / Number(100)) * (Number(currItem.igst))).toFixed(2);
                        currItem.total = (Number(currItem.gstvalue) + Number(currItem.netAmount)).toFixed(2)
                        data[itemIndex] = currItem

                    } else {

                        if (action.data == "") {
                            currItem.soqty = Number(0);
                            currItem.someasurement = Number(0);
                        } else {
                            currItem.soqty = Number(action.data)
                            let aa = Number(currItem.soqty);
                            let ab = (Number(aa) / currItem.uomSecondary)
                            currItem.someasurement = Math.round(ab);

                            let a = (Number(currItem.someasurement) / Number(currItem.standardQtyPerBox));
                            if ((Number(Math.trunc(a)) * Number(currItem.standardQtyPerBox)) == Number(currItem.someasurement)) {
                                currItem.soquantity_placed = Number(Number(currItem.someasurement))
                            } else {
                                currItem.soquantity_placed = (Number(Math.trunc(a) + 1) * Number(currItem.standardQtyPerBox))
                            }
                            currItem.soquantity_placed_kg = (Number(currItem.soquantity_placed) * Number(currItem.uomSecondary)).toFixed(3);

                        }
                        currItem.tradediscount = Number(0)
                        currItem.grossamount = (Number(currItem.soquantity_placed_kg) * Number(currItem.dlp)).toFixed(2);
                        let NetAmount = (Number(currItem.soquantity_placed_kg) * Number(currItem.dlp))
                        currItem.netAmount = (Number(NetAmount)).toFixed(2);
                        currItem.gstvalue = ((Number(currItem.netAmount) / Number(100)) * (Number(currItem.igst))).toFixed(2);
                        currItem.total = (Number(currItem.gstvalue) + Number(currItem.netAmount)).toFixed(2)
                        data[itemIndex] = currItem

                    }

                }
                // else {


                //     if (currItem.discount > 0) {
                //         if (action.data == "") {
                //             currItem.soqty = Number(0)
                //         } else {
                //             currItem.soqty = Number(action.data)
                //         }

                //         let discount2 = Number(currItem.soqty) * Number(currItem.mrp) * (Number(currItem.discount) / 100)
                //         currItem.total = (Number(currItem.soqty) * Number(currItem.mrp) - Number(discount2)).toFixed(2);
                //         currItem.productId = currItem.id
                //         // currItem.total = parseInt(currItem.soqty) * parseInt(currItem.mrp)
                //         currItem.gstvalue = Number(0)
                //         currItem.amount = (Number(currItem.gstvalue) + Number(currItem.total)).toFixed(2);
                //         data[itemIndex] = currItem

                //     } else {

                //         if (action.data == "") {
                //             currItem.soqty = Number(0)
                //         } else {
                //             currItem.soqty = Number(action.data)
                //         }
                //         currItem.discount = Number(0)
                //         currItem.productId = currItem.id
                //         currItem.total = (Number(currItem.soqty) * Number(currItem.mrp)).toFixed(2);
                //         currItem.gstvalue = Number(0)
                //         currItem.amount = (Number(currItem.gstvalue) + Number(currItem.total)).toFixed(2);
                //         data[itemIndex] = currItem


                //     }


                // }
            }

            return [...data];

        case CHANGE_SALESORDER_MEASUREMENT:
            let newCart3 = [...data]
            let itemIndex3 = newCart3.findIndex(obj => obj.product.id === action.item.product.id)
            let currItem3 = data[itemIndex3]
            // console.log("mello",currItem3)
            if (action.data3 == "pcs") {
                if (action.data2 == "withtax") {
                    if (currItem3.tradediscount > 0 || currItem3.schemeDiscount > 0) {
                        if (action.data == "") {
                            currItem3.someasurement = Number(0)
                            currItem3.soqty = Number(0)
                        } else {
                            currItem3.someasurement = Number(action.data)
                            let aa = Number(currItem3.someasurement)
                            currItem3.soqty = (Number(aa) * Number(currItem3.uomSecondary)).toFixed(3);

                            let a = (Number(action.data) / Number(currItem3.standardQtyPerBox));
                            if ((Number(Math.trunc(a)) * Number(currItem3.standardQtyPerBox)) == Number(action.data)) {
                                currItem3.soquantity_placed = Number(Number(currItem3.someasurement))
                            } else {
                                currItem3.soquantity_placed = (Number(Math.trunc(a) + 1) * Number(currItem3.standardQtyPerBox))
                            }
                            currItem3.soquantity_placed_kg = (Number(currItem3.soquantity_placed) * Number(currItem3.uomSecondary)).toFixed(3);
                        }

                        let discount2 = Number(currItem3.soquantity_placed_kg) * Number(currItem3.dlp) * (Number(currItem3.tradediscount) / 100)
                        let totaAmount = Number(currItem3.soquantity_placed_kg) * Number(currItem3.dlp) - Number(discount2);
                        currItem3.grossamount = (Number(totaAmount)).toFixed(2);
                        let schemediscount = ((Number(totaAmount) * Number(currItem3.schemeDiscount)) / 100)
                        let NetAmount = (Number(totaAmount) - Number(schemediscount))
                        currItem3.netAmount = (Number(NetAmount)).toFixed(2);
                        currItem3.gstvalue = ((Number(currItem3.netAmount) / Number(100)) * (Number(currItem3.igst))).toFixed(2);
                        currItem3.total = (Number(currItem3.gstvalue) + Number(currItem3.netAmount)).toFixed(2);
                        data[itemIndex3] = currItem3
                    } else {

                        if (action.data == "") {
                            currItem3.someasurement = Number(0)
                            currItem3.soqty = Number(0);
                        } else {
                            currItem3.someasurement = Number(action.data)
                            let aa = Number(currItem3.someasurement)
                            currItem3.soqty = (Number(aa) * (Number(currItem3.uomSecondary))).toFixed(3);;
                            let a = (Number(action.data) / Number(currItem3.standardQtyPerBox));
                            if ((Number(Math.trunc(a)) * Number(currItem3.standardQtyPerBox)) == Number(action.data)) {
                                currItem3.soquantity_placed = Number(Number(currItem3.someasurement))
                            } else {
                                currItem3.soquantity_placed = (Number(Math.trunc(a) + 1) * Number(currItem3.standardQtyPerBox))
                            }
                            currItem3.soquantity_placed_kg = (Number(currItem3.soquantity_placed) * Number(currItem3.uomSecondary)).toFixed(3);
                        }

                        currItem3.tradediscount = Number(0)
                        currItem3.grossamount = (Number(currItem3.soquantity_placed_kg) * Number(currItem3.dlp)).toFixed(2);
                        let NetAmount = (Number(currItem3.soquantity_placed_kg) * Number(currItem3.dlp))
                        currItem3.netAmount = (Number(NetAmount)).toFixed(2);
                        currItem3.gstvalue = ((Number(currItem3.netAmount) / Number(100)) * (Number(currItem3.igst))).toFixed(2)
                        currItem3.total = (Number(currItem3.gstvalue) + Number(currItem3.netAmount)).toFixed(2)
                        data[itemIndex3] = currItem3
                    }
                }
            }

            if(action.data3 == "box"){
                if (action.data2 == "withtax") {
                    if (currItem3.tradediscount > 0 || currItem3.schemeDiscount > 0) {
                        if (action.data == "") {
                            currItem3.someasurement = Number(0)
                        } else {
                            
                            currItem3.someasurement = Number(action.data)
    
                            let a = (Number(action.data)/Number(currItem3.standardQtyPerBox));
                            if( (Number(Math.trunc(a))* Number(currItem3.standardQtyPerBox)) == Number(action.data)){
                             currItem3.soquantity_placed= Number(Number(action.data))
                            } else{
                                currItem3.soquantity_placed = (Number(Math.trunc(a)+1)* Number(currItem3.standardQtyPerBox))
                            }
                            currItem3.soquantity_placed_kg = '';
                        }
                           
                        let discount2 = Number(currItem3.soquantity_placed) * Number(currItem3.dlp) * (Number(currItem3.tradediscount) / 100)
                        let totaAmount = Number(currItem3.soquantity_placed) * Number(currItem3.dlp) - Number(discount2);
                        currItem3.grossamount = (Number(totaAmount)).toFixed(2);
                        let schemediscount = ((Number(totaAmount) * Number(currItem3.schemeDiscount)) / 100)
                        let NetAmount = (Number(totaAmount) - Number(schemediscount))
                        currItem3.netAmount = (Number(NetAmount)).toFixed(2);
                        // console.log("mello5",currItem.grossamount)
                        // currItem.NetAmount = parseInt(currItem.measurement) * parseInt(currItem.dlp)
                        currItem3.gstvalue = ((Number(currItem3.netAmount)/Number(100))*(Number(currItem3.igst))).toFixed(2);
                        currItem3.total = (Number(currItem3.gstvalue) + Number(currItem3.netAmount)).toFixed(2);
                        data[itemIndex3] = currItem3
    
                    } else {
    
                        if (action.data == "") {
                            currItem3.someasurement = Number(0)
                        } else {
                            currItem3.someasurement = Number(action.data)
    
                            let a = (Number(action.data)/Number(currItem3.standardQtyPerBox));
                            if( (Number(Math.trunc(a))* Number(currItem3.standardQtyPerBox)) == Number(action.data)){
                             currItem3.soquantity_placed= Number(Number(action.data))
                            } else{
                                currItem3.soquantity_placed = (Number(Math.trunc(a)+1)* Number(currItem3.standardQtyPerBox))
                            }
                            currItem3.soquantity_placed_kg = '';
    
                        }
                        currItem3.tradediscount = Number(0)
                        currItem3.grossamount = (Number(currItem3.soquantity_placed) * Number(currItem3.dlp)).toFixed(2);
                        let NetAmount = (Number(currItem3.soquantity_placed) * Number(currItem3.dlp))
                        currItem3.netAmount = (Number(NetAmount)).toFixed(2);
                        currItem3.gstvalue = ((Number(currItem3.netAmount)/Number(100))*(Number(currItem3.igst))).toFixed(2)
                        currItem3.total = (Number(currItem3.gstvalue) + Number(currItem3.netAmount)).toFixed(2)
                        data[itemIndex3] = currItem3
    
    
                    }
                }
            }

            return[...data];

        case REMOVE_FROM_CART_FROM_SALESORDER:

            const remainingitem = data.filter((item) => item.product.id !== action.data);
            return [...remainingitem];

        case CHANGE_SALESORDER_SQPB_MEASUREMENT:
            let newCart4 = [...data]
            let itemIndex4 = newCart4.findIndex(obj => obj.product.id === action.item.product.id)
            let currItem4 = data[itemIndex4]
            currItem4.sqpb = action.data
           
            if(currItem4.unitofmeasurement == "box"){
                if(currItem4.soquantity_placed){
   
                  currItem4.soquantity_placed = action.data
               if( currItem4.uomSecondary == "" || currItem4.uomSecondary == "0"){
   
                   if (currItem4.tradediscount > 0 || currItem4.schemeDiscount > 0) {
   
                       let discount2 = Number(currItem4.sqpb) * Number(currItem4.dlp) * (Number(currItem4.tradediscount) / 100);
                       currItem4.soquantity_placed_kg = '';
                       let totaAmount = Number(currItem4.sqpb) * Number(currItem4.dlp) - Number(discount2);
                       currItem4.grossamount = (Number(totaAmount)).toFixed(2);
                       let schemediscount = ((Number(totaAmount) * Number(currItem4.schemeDiscount)) / 100);
                       let NetAmount = (Number(totaAmount) - Number(schemediscount));
                       currItem4.netAmount= (Number(NetAmount)).toFixed(2);
                       currItem4.gstvalue = ((Number(currItem4.netAmount)/Number(100))*(Number(currItem4.igst))).toFixed(2)
                       currItem4.total = (Number(currItem4.gstvalue) + Number(currItem4.netAmount)).toFixed(2)
                       data[itemIndex4] = currItem4
                       
                   } else {
                       currItem4.tradediscount = Number(0);
                       currItem4.soquantity_placed_kg = '';
                       currItem4.grossamount = (Number(currItem4.sqpb) * Number(currItem4.dlp)).toFixed(2);
                       let NetAmount = (Number(currItem4.sqpb) * Number(currItem4.dlp));
                       currItem4.netAmount = (Number(NetAmount)).toFixed(2);
                       currItem4.gstvalue = ((Number(currItem4.netAmount)/Number(100))*(Number(currItem4.igst))).toFixed(2)
                       currItem4.total = (Number(currItem4.gstvalue) + Number(currItem4.netAmount)).toFixed(2);
                       data[itemIndex4] = currItem4
                       // console.log("mello",totaAmount,NetAmount)
                   }
               }
               else{
                   if (currItem4.tradediscount > 0 || currItem4.schemeDiscount > 0) {
                       let qtydata = (Number(currItem4.sqpb) * Number(currItem4.uomSecondary))
                       currItem4.soquantity_placed_kg ='';
                       let discount2 = Number(qtydata) * Number(currItem4.dlp) * (Number(currItem4.tradediscount) / 100);
                       let totaAmount = Number(qtydata) * Number(currItem4.dlp) - Number(discount2);
                       currItem4.grossamount = (Number(totaAmount)).toFixed(2);
                       let schemediscount = ((Number(totaAmount) * Number(currItem4.schemeDiscount)) / 100);
                       let NetAmount = (Number(totaAmount) - Number(schemediscount));
                       currItem4.netAmount= (Number(NetAmount)).toFixed(2);
                       currItem4.gstvalue = ((Number(currItem4.netAmount)/Number(100))*(Number(currItem4.igst))).toFixed(2)
                       currItem4.total = (Number(currItem4.gstvalue) + Number(currItem4.netAmount)).toFixed(2)
                       data[itemIndex4] = currItem4
                       
                   }  else {
                       let qtydata = (Number(currItem4.sqpb) * Number(currItem4.uomSecondary))
                       currItem4.soquantity_placed_kg ='';
                       currItem4.tradediscount = Number(0);
                       currItem4.grossamount = (Number(qtydata) * Number(currItem4.dlp)).toFixed(2);
                       let NetAmount = (Number(qtydata) * Number(currItem4.dlp));
                       currItem4.netAmount = (Number(NetAmount)).toFixed(2)
                       currItem4.gstvalue = ((Number(currItem4.netAmount)/Number(100))*(Number(currItem4.igst))).toFixed(2) 
                       currItem4.total = (Number(currItem4.gstvalue) + Number(currItem4.netAmount)).toFixed(2)
                       data[itemIndex4] = currItem4
                       // console.log("mello",totaAmount,NetAmount)
                   }
               }
           }
       } /* Box Product End */
       else {
           if(currItem4.soquantity_placed){
   
               currItem4.soquantity_placed = action.data
            if( currItem4.uomSecondary == "" || currItem4.uomSecondary == "0"){
   
                if (currItem4.tradediscount > 0 || currItem4.schemeDiscount > 0) {
   
                    let discount2 = Number(currItem4.sqpb) * Number(currItem4.dlp) * (Number(currItem4.tradediscount) / 100);
                    currItem4.soquantity_placed_kg = currItem4.sqpb;
                    let totaAmount = Number(currItem4.sqpb) * Number(currItem4.dlp) - Number(discount2);
                    currItem4.grossamount = (Number(totaAmount)).toFixed(2);
                    let schemediscount = ((Number(totaAmount) * Number(currItem4.schemeDiscount)) / 100);
                    let NetAmount = (Number(totaAmount) - Number(schemediscount));
                    currItem4.netAmount= (Number(NetAmount)).toFixed(2);
                    currItem4.gstvalue = ((Number(currItem4.netAmount)/Number(100))*(Number(currItem4.igst))).toFixed(2)
                    currItem4.total = (Number(currItem4.gstvalue) + Number(currItem4.netAmount)).toFixed(2);
                    data[itemIndex4] = currItem4
                    
                } else {
                    currItem4.tradediscount = Number(0);
                    currItem4.soquantity_placed_kg = currItem4.sqpb;
                    currItem4.grossamount = (Number(currItem4.sqpb) * Number(currItem4.dlp)).toFixed(2);
                    let NetAmount = (Number(currItem4.sqpb) * Number(currItem4.dlp));
                    currItem4.netAmount = (Number(NetAmount)).toFixed(2);
                    currItem4.gstvalue = ((Number(currItem4.netAmount)/Number(100))*(Number(currItem4.igst))).toFixed(2)
                    currItem4.total = (Number(currItem4.gstvalue) + Number(currItem4.netAmount)).toFixed(2)
                    data[itemIndex4] = currItem4
                    // console.log("mello",totaAmount,NetAmount)
                }
            }
            else{
                if (currItem4.tradediscount > 0 || currItem4.schemeDiscount > 0) {
                    let qtydata = (Number(currItem4.sqpb) * Number(currItem4.uomSecondary))
                    currItem4.soquantity_placed_kg =Number(qtydata).toFixed(3);
                    let discount2 = Number(qtydata) * Number(currItem4.dlp) * (Number(currItem4.tradediscount) / 100);
                    let totaAmount = Number(qtydata) * Number(currItem4.dlp) - Number(discount2);
                    currItem4.grossamount = (Number(totaAmount)).toFixed(2);
                    let schemediscount = ((Number(totaAmount) * Number(currItem4.schemeDiscount)) / 100);
                    let NetAmount = (Number(totaAmount) - Number(schemediscount));
                    currItem4.netAmount= (Number(NetAmount)).toFixed(2);
                    currItem4.gstvalue = ((Number(currItem4.netAmount)/Number(100))*(Number(currItem4.igst))).toFixed(2)
                    currItem4.total = (Number(currItem4.gstvalue) + Number(currItem4.netAmount)).toFixed(2);
                    data[itemIndex4] = currItem4
                    
                }  else {
                    let qtydata = (Number(currItem4.sqpb) * Number(currItem4.uomSecondary))
                    currItem4.soquantity_placed_kg =Number(qtydata).toFixed(3);
                    currItem4.tradediscount = Number(0);
                    currItem4.grossamount = (Number(qtydata) * Number(currItem4.dlp)).toFixed(2);
                    let NetAmount = (Number(qtydata) * Number(currItem4.dlp));
                    currItem4.netAmount = (Number(NetAmount)).toFixed(2);
                    currItem4.gstvalue = ((Number(currItem4.netAmount)/Number(100))*(Number(currItem4.igst))).toFixed(2) 
                    currItem4.total = (Number(currItem4.gstvalue) + Number(currItem4.netAmount)).toFixed(2)
                    data[itemIndex4] = currItem4
                    // console.log("mello",totaAmount,NetAmount)
                }
            }
        }
       }
     return[...data];

        case CHANGE_COST_SALESORDER:

            let newCart1 = [...data]
            let itemIndex1 = newCart1.findIndex(obj => obj.product.id === action.item.product.id)
            let currItem1 = data[itemIndex1]


            if(currItem1.sqpb == null){
                if (currItem1.unitofmeasurement == "box") {
                if (currItem1.tradediscount > 0 || currItem1.schemeDiscount > 0) {
    
                    if (action.data == "") {
                        currItem1.dlp = ""
                    } else {
                        currItem1.dlp = Number(action.data)
                    }
                    // currItem1.dlp = parseInt(action.data)
                    
                    let a = (Number(currItem1.someasurement)/Number(currItem1.standardQtyPerBox));
                            if( (Number(Math.trunc(a))* Number(currItem1.standardQtyPerBox)) == Number(currItem1.someasurement)){
                             currItem1.soquantity_placed= Number(Number(currItem1.someasurement))
                            } else{
                                currItem1.soquantity_placed = (Number(Math.trunc(a)+1)* Number(currItem1.standardQtyPerBox))
                            }
                            currItem1.soquantity_placed_kg = '';
    
                    let discount3 = Number(currItem1.soquantity_placed) * Number(currItem1.dlp) * (Number(currItem1.tradediscount) / 100)
                    let totaAmount = Number(currItem1.soquantity_placed) * Number(currItem1.dlp) - Number(discount3)
                    currItem1.grossamount = (Number(totaAmount)).toFixed(2);
                    let schemediscount = ((Number(totaAmount) * Number(currItem1.schemeDiscount)) / 100)
                    let NetAmount = (Number(totaAmount) - Number(schemediscount))
                    currItem1.netAmount = (Number(NetAmount)).toFixed(2);
                    // currItem1.NetAmount = parseInt(currItem1.quantity_placed_kg) * parseInt(currItem1.dlp)
                    currItem1.gstvalue = ((Number(currItem1.netAmount)/Number(100))*(Number(currItem1.igst))).toFixed(2);
                    currItem1.total = (Number(currItem1.gstvalue) + Number(currItem1.netAmount)).toFixed(2);
                    data[itemIndex1] = currItem1
                    return [...data];
    
                } else {
    
                    if (action.data == "") {
                        currItem1.dlp = ""
                    } else {
                        currItem1.dlp = Number(action.data)
                    }
                    // currItem1.dlp = parseInt(action.data)
    
                    let a = (Number(currItem1.someasurement)/Number(currItem1.standardQtyPerBox));
                    if( (Number(Math.trunc(a))* Number(currItem1.standardQtyPerBox)) == Number(currItem1.someasurement)){
                     currItem1.soquantity_placed= Number(Number(currItem1.someasurement))
                    } else{
                        currItem1.soquantity_placed = (Number(Math.trunc(a)+1)* Number(currItem1.standardQtyPerBox))
                    }
                    currItem1.soquantity_placed_kg = '';
    
    
                    currItem1.tradediscount = Number(0)
                    currItem1.grossamount = (Number(currItem1.soquantity_placed) * Number(currItem1.dlp)).toFixed(2);
                    let NetAmount = (Number(currItem1.soquantity_placed) * Number(currItem1.dlp))
                    currItem1.netAmount = (Number(NetAmount)).toFixed(2)
                    currItem1.gstvalue = ((Number(currItem1.netAmount)/Number(100))*(Number(currItem1.igst))).toFixed(2);
                    currItem1.total = (Number(currItem1.gstvalue) + Number(currItem1.netAmount)).toFixed(2);
                    data[itemIndex1] = currItem1
                    return [...data];
    
                }
            } /*end Box Product */
            else{
                if (currItem1.tradediscount > 0 || currItem1.schemeDiscount > 0) {
    
                    if (action.data == "") {
                        currItem1.dlp = ""
                    } else {
                        currItem1.dlp = Number(action.data)
                    }
                    // currItem1.dlp = parseInt(action.data)
    
                    let a = (Number(currItem1.someasurement)/Number(currItem1.standardQtyPerBox));
                        if( (Number(Math.trunc(a))* Number(currItem1.standardQtyPerBox)) == Number(currItem1.someasurement)){
                         currItem1.soquantity_placed= Number(Number(currItem1.someasurement))
                        } else{
                            currItem1.soquantity_placed = (Number(Math.trunc(a)+1)* Number(currItem1.standardQtyPerBox))
                        }
                        currItem1.soquantity_placed_kg = (Number(currItem1.soquantity_placed) * Number(currItem1.uomSecondary)).toFixed(3);
    
                    let discount3 = Number(currItem1.soquantity_placed_kg) * Number(currItem1.dlp) * (Number(currItem1.tradediscount) / 100)
                    let totaAmount = Number(currItem1.soquantity_placed_kg) * Number(currItem1.dlp) - Number(discount3)
                    currItem1.grossamount = (Number(totaAmount)).toFixed(2);
                    let schemediscount = ((Number(totaAmount) * Number(currItem1.schemeDiscount)) / 100)
                    let NetAmount = (Number(totaAmount) - Number(schemediscount))
                    currItem1.netAmount = (Number(NetAmount)).toFixed(2);
                    // currItem1.NetAmount = parseInt(currItem1.quantity_placed_kg) * parseInt(currItem1.dlp);
                    currItem1.gstvalue = ((Number(currItem1.netAmount)/Number(100))*(Number(currItem1.igst))).toFixed(2);
                    currItem1.total = (Number(currItem1.gstvalue) + Number(currItem1.netAmount)).toFixed(2);
                    data[itemIndex1] = currItem1
                    return [...data];
    
                } else {
    
                    if (action.data == "") {
                        currItem1.dlp = ""
                    } else {
                        currItem1.dlp = Number(action.data)
                    }
                    // currItem1.dlp = parseInt(action.data)
    
                    let a = (Number(currItem1.someasurement)/Number(currItem1.standardQtyPerBox));
                        if( (Number(Math.trunc(a))* Number(currItem1.standardQtyPerBox)) == Number(currItem1.someasurement)){
                         currItem1.soquantity_placed= Number(Number(currItem1.someasurement))
                        } else{
                            currItem1.soquantity_placed = (Number(Math.trunc(a)+1)* Number(currItem1.standardQtyPerBox))
                        }
                        currItem1.soquantity_placed_kg = (Number(currItem1.soquantity_placed) * Number(currItem1.uomSecondary)).toFixed(3)
    
                    currItem1.tradediscount = Number(0)
                    currItem1.grossamount = (Number(currItem1.soquantity_placed_kg) * Number(currItem1.dlp)).toFixed(2);
                    let NetAmount = (Number(currItem1.soquantity_placed_kg) * Number(currItem1.dlp))
                    currItem1.netAmount = (Number(NetAmount)).toFixed(2);
                    currItem1.gstvalue = ((Number(currItem1.netAmount)/Number(100))*(Number(currItem1.igst))).toFixed(2);
                    currItem1.total = (Number(currItem1.gstvalue) + Number(currItem1.netAmount)).toFixed(2);
                    data[itemIndex1] = currItem1
                    return [...data];
    
                }
            } /* end KG Product */
        } else {
    
            if (currItem1.unitofmeasurement == "box") {
                if (currItem1.tradediscount > 0 || currItem1.schemeDiscount > 0) {
    
                    if (action.data == "") {
                        currItem1.dlp = ""
                    } else {
                        currItem1.dlp = Number(action.data)
                    }
                    // currItem1.dlp = parseInt(action.data)
                    let discount3 = Number(currItem1.sqpb) * Number(currItem1.dlp) * (Number(currItem1.tradediscount) / 100)
                    currItem1.soquantity_placed_kg = '';
                    let totaAmount = Number(currItem1.sqpb) * Number(currItem1.dlp) - Number(discount3)
                    currItem1.grossamount = (Number(totaAmount)).toFixed(2);
                    let schemediscount = ((Number(totaAmount) * Number(currItem1.schemeDiscount)) / 100)
                    let NetAmount = (Number(totaAmount) - Number(schemediscount))
                    currItem1.netAmount = (Number(NetAmount)).toFixed(2);
                    // currItem1.NetAmount = parseInt(currItem1.measurement) * parseInt(currItem1.dlp)
                    currItem1.gstvalue = ((Number(currItem1.netAmount)/Number(100))*(Number(currItem1.igst))).toFixed(2);
                    currItem1.total = (Number(currItem1.gstvalue) + Number(currItem1.netAmount)).toFixed(2);
                    data[itemIndex1] = currItem1
                    return [...data];
    
                } else {
    
                    if (action.data == "") {
                        currItem1.dlp = ""
                    } else {
                        currItem1.dlp = Number(action.data)
                    }
                    // currItem1.dlp = parseInt(action.data)
                    currItem1.tradediscount = Number(0);
                    currItem1.grossamount = (Number(currItem1.sqpb) * Number(currItem1.dlp)).toFixed(2);
                    currItem1.soquantity_placed_kg = '';
                    let NetAmount = (Number(currItem1.sqpb) * Number(currItem1.dlp));
                    currItem1.netAmount = (Number(NetAmount)).toFixed(2);
                    currItem1.gstvalue = ((Number(currItem1.netAmount)/Number(100))*(Number(currItem1.igst))).toFixed(2);
                    currItem1.total = (Number(currItem1.gstvalue) + Number(currItem1.netAmount)).toFixed(2);
                    data[itemIndex1] = currItem1
                    return [...data];
    
                }
            } else{
                if (currItem1.tradediscount > 0 || currItem1.schemeDiscount > 0) {
    
                    if (action.data == "") {
                        currItem1.dlp = ""
                    } else {
                        currItem1.dlp = Number(action.data)
                    }
                    // currItem1.dlp = parseInt(action.data)
                    let qtydata = (Number(currItem1.sqpb) * Number(currItem1.uomSecondary))
                    currItem1.soquantity_placed_kg =Number(qtydata).toFixed(3);
                    let discount3 = Number(qtydata) * Number(currItem1.dlp) * (Number(currItem1.tradediscount) / 100)
                    let totaAmount = Number(qtydata) * Number(currItem1.dlp) - Number(discount3)
                    currItem1.grossamount = (Number(totaAmount)).toFixed(2);
                    let schemediscount = ((Number(totaAmount) * Number(currItem1.schemeDiscount)) / 100)
                    let NetAmount = (Number(totaAmount) - Number(schemediscount))
                    currItem1.netAmount = (Number(NetAmount)).toFixed(2);
                    // currItem1.NetAmount = parseInt(currItem1.qty) * parseInt(currItem1.dlp)
                    currItem1.gstvalue = ((Number(currItem1.netAmount)/Number(100))*(Number(currItem1.igst))).toFixed(2);
                    currItem1.total = (Number(currItem1.gstvalue) + Number(currItem1.netAmount)).toFixed(2);
                    data[itemIndex1] = currItem1
                    return [...data];
    
                } else {
    
                    if (action.data == "") {
                        currItem1.dlp = ""
                    } else {
                        currItem1.dlp = Number(action.data)
                    }
                    // currItem1.dlp = parseInt(action.data)
                    let qtydata = (Number(currItem1.sqpb) * Number(currItem1.uomSecondary))
                    currItem1.soquantity_placed_kg =Number(qtydata).toFixed(3);
                    currItem1.tradediscount = Number(0);
                    currItem1.grossamount = (Number(qtydata) * Number(currItem1.dlp)).toFixed(2);
                    let NetAmount = (Number(qtydata) * Number(currItem1.dlp))
                    currItem1.netAmount = (Number(NetAmount)).toFixed(2);
                    currItem1.gstvalue = ((Number(currItem1.netAmount)/Number(100))*(Number(currItem1.igst))).toFixed(2);
                    currItem1.total = (Number(currItem1.gstvalue) + Number(currItem1.netAmount)).toFixed(2);
                    data[itemIndex1] = currItem1
                    return [...data];
    
                }
            }
    
        }
        return [...data];

        case CHANGE_SALESORDER_DISCOUNT:

            let newCart2 = [...data]
            let itemIndex2 = newCart2.findIndex(obj => obj.product.id === action.item.product.id)
            let currItem2 = data[itemIndex2]


            if(currItem2.sqpb == null){
            if (currItem2.unitofmeasurement == "box") {
            if (action.data == "") {
                currItem2.schemeDiscount = 0
            } else {
                currItem2.schemeDiscount = Number(action.data)
            }

            // currItem1.dlp = parseInt(action.data)

            let a = (Number(currItem2.someasurement)/Number(currItem2.standardQtyPerBox));
                        if( (Number(Math.trunc(a))* Number(currItem2.standardQtyPerBox)) == Number(currItem2.someasurement)){
                         currItem2.soquantity_placed= Number(Number(currItem2.someasurement))
                        } else{
                            currItem2.soquantity_placed = (Number(Math.trunc(a)+1)* Number(currItem2.standardQtyPerBox))
                        }
                        currItem2.soquantity_placed_kg = '';

            let discount1 = Number(currItem2.soquantity_placed) * Number(currItem2.dlp) * (Number(currItem2.tradediscount) / 100)
            let totaAmount = Number(currItem2.soquantity_placed) * Number(currItem2.dlp) - Number(discount1)
            currItem2.grossamount = (Number(totaAmount)).toFixed(2);
                let schemediscount = ((Number(totaAmount) * Number(currItem2.schemeDiscount)) / 100)
                let NetAmount = (Number(totaAmount) - Number(schemediscount))
                currItem2.netAmount = (Number(NetAmount)).toFixed(2);
                // currItem1.NetAmount = parseInt(currItem1.quantity_placed_kg) * parseInt(currItem1.dlp)
                currItem2.gstvalue = ((Number(currItem2.netAmount)/Number(100))*(Number(currItem2.igst))).toFixed(2);
            currItem2.total = (Number(currItem2.gstvalue) + Number(currItem2.netAmount)).toFixed(2);
            data[itemIndex2] = currItem2

        } /* BOX Product End */
        else{
            if (action.data == "") {
                currItem2.schemeDiscount = 0
            } else {
                currItem2.schemeDiscount = Number(action.data)
            }

            // currItem1.dlp = parseInt(action.data)

            let a = (Number(currItem2.someasurement)/Number(currItem2.standardQtyPerBox));
                    if( (Number(Math.trunc(a))* Number(currItem2.standardQtyPerBox)) == Number(currItem2.someasurement)){
                     currItem2.soquantity_placed= Number(Number(currItem2.someasurement))
                    } else{
                        currItem2.soquantity_placed = (Number(Math.trunc(a)+1)* Number(currItem2.standardQtyPerBox))
                    }
                    currItem2.soquantity_placed_kg = (Number(currItem2.soquantity_placed) * Number(currItem2.uomSecondary)).toFixed(3)

            let discount1 = Number(currItem2.soquantity_placed_kg) * Number(currItem2.dlp) * (Number(currItem2.tradediscount) / 100)
            let totaAmount = Number(currItem2.soquantity_placed_kg) * Number(currItem2.dlp) - Number(discount1)
            currItem2.grossamount = (Number(totaAmount)).toFixed(2);
                let schemediscount = ((Number(totaAmount) * Number(currItem2.schemeDiscount)) / 100)
                let NetAmount = (Number(totaAmount) - Number(schemediscount))
                currItem2.netAmount = (Number(NetAmount)).toFixed(2);
                // currItem1.NetAmount = parseInt(currItem1.quantity_placed_kg) * parseInt(currItem1.dlp)
                currItem2.gstvalue = ((Number(currItem2.netAmount)/Number(100))*(Number(currItem2.igst))).toFixed(2);
            currItem2.total = (Number(currItem2.gstvalue) + Number(currItem2.netAmount)).toFixed(2);
            data[itemIndex2] = currItem2
        }
    } else {

        if (currItem2.unitofmeasurement == "box") {
            if (action.data == "") {
                currItem2.schemeDiscount = 0
            } else {
                currItem2.schemeDiscount = Number(action.data)
            }

            // currItem1.dlp = parseInt(action.data)
            
            let discount1 = Number(currItem2.sqpb) * Number(currItem2.dlp) * (Number(currItem2.tradediscount) / 100);
            currItem2.soquantity_placed_kg = '';
            let totaAmount = Number(currItem2.sqpb) * Number(currItem2.dlp) - Number(discount1)
            currItem2.grossamount = (Number(totaAmount)).toFixed(2);
                let schemediscount = ((Number(totaAmount) * Number(currItem2.schemeDiscount)) / 100)
                let NetAmount = (Number(totaAmount) - Number(schemediscount))
                currItem2.netAmount = (Number(NetAmount)).toFixed(2);
                // currItem1.NetAmount = parseInt(currItem1.measurement) * parseInt(currItem1.dlp)
                currItem2.gstvalue = ((Number(currItem2.netAmount)/Number(100))*(Number(currItem2.igst))).toFixed(2);
            currItem2.total = (Number(currItem2.gstvalue) + Number(currItem2.netAmount)).toFixed(2);
            data[itemIndex2] = currItem2

        } else{
            if (action.data == "") {
                currItem2.schemeDiscount = 0
            } else {
                currItem2.schemeDiscount = Number(action.data)
            }

            // currItem1.dlp = parseInt(action.data)
            // console.log("mello",currItem2.uomSecondary)
            let qtydata = (Number(currItem2.sqpb) * Number(currItem2.uomSecondary))
            currItem2.soquantity_placed_kg =Number(qtydata).toFixed(3);
            let discount1 = Number(qtydata) * Number(currItem2.dlp) * (Number(currItem2.tradediscount) / 100)
            let totaAmount = Number(qtydata) * Number(currItem2.dlp) - Number(discount1)
            currItem2.grossamount = (Number(totaAmount)).toFixed(2) ;
                let schemediscount = ((Number(totaAmount) * Number(currItem2.schemeDiscount)) / 100)
                let NetAmount = (Number(totaAmount) - Number(schemediscount))
                currItem2.netAmount = (Number(NetAmount)).toFixed(2);
                // currItem1.NetAmount = parseInt(currItem1.qtydata) * parseInt(currItem1.dlp)
                currItem2.gstvalue = ((Number(currItem2.netAmount)/Number(100))*(Number(currItem2.igst))).toFixed(2);
            currItem2.total = (Number(currItem2.gstvalue) + Number(currItem2.netAmount)).toFixed(2);
            data[itemIndex2] = currItem2
        }

    }
            return [...data];


        case CHANGE_SALESORDER_TEXT:

            let newCart5 = [...data]
            let itemIndex5 = newCart5.findIndex(obj => obj.product.id === action.item.product.id)
            let currItem5 = data[itemIndex5]

            currItem5.text = action.data
            data[itemIndex5] = currItem5
            return [...data];


        case EMPTY_SALESORDER_DATA:
            // console.warn("reducer called",action)
            return [];



        default:
            return data;
    }


}
