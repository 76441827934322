import { SALESORDER_GET_DATA_TO_LOAD } from "../constant";
import { WORKORDERBYSALESORDER_GET_DATA_TO_LOAD } from "../constant";
import { SALESORDER_ADD_TO_CART } from "../constant";
import { CHANGE_SALESORDER_MEASUREMENT } from "../constant";
import { CHANGE_SALESORDER_SQPB_MEASUREMENT } from "../constant";
import { CHANGE_SALESORDER_QTY } from "../constant";
import { REMOVE_FROM_CART_FROM_SALESORDER } from "../constant";
import { CHANGE_COST_SALESORDER } from "../constant";
import { CHANGE_SALESORDER_DISCOUNT } from "../constant";
import { CHANGE_SALESORDER_TEXT } from "../constant";
import { EMPTY_SALESORDER_DATA } from "../constant";
import { CHANGE_MEASUREMENT_SALESORDER } from "../constant";



export const getsalesorderdatatoload = (data) => {

    //  console.warn("action called", data);
    return {

        type: SALESORDER_GET_DATA_TO_LOAD,
        data: data
    }

}

export const getworkorderbysalesorderdatatoload = (data) => {

    //  console.warn("action called", data);
    return {

        type: WORKORDERBYSALESORDER_GET_DATA_TO_LOAD,
        data: data
    }

}

export const salesorderaddtocart = (data, data2) => {

    //  console.warn("action called", data2);
    return {

        type: SALESORDER_ADD_TO_CART,
        data: data,
        data2: data2
    }

}

export const changeunitofMeasurementsalesorder = (data, item, data2) => {

    // console.warn("qty change called66", data2);
    return {

        type: CHANGE_MEASUREMENT_SALESORDER,
        data: data,
        item: item,
        data2: data2
    }

}

export const removefromcartfromsalesorder = (data) => {

    // console.warn("action REMOVE_TO_CART called", data);
    return {

        type: REMOVE_FROM_CART_FROM_SALESORDER,
        data: data
    }

}

export const changesalesordermeasurement = (data, item, data2, data3) => {
    return {
        type: CHANGE_SALESORDER_MEASUREMENT,
        data: data,
        item: item,
        data2: data2,
        data3: data3
    }
}
export const changesalesordersqpbmeasurement = (data, item, data2, data3) => {
    return {
        type: CHANGE_SALESORDER_SQPB_MEASUREMENT,
        data: data,
        item: item,
        data2: data2,
        data3: data3
    }
}

export const changesalesorderqty = (data, item, data2, data3) => {

    // console.warn("qty change called", data);
    return {

        type: CHANGE_SALESORDER_QTY,
        data: data,
        item: item,
        data2: data2,
        data3: data3
    }

}



export const changecostsalesorder = (data, item, data2) => {

    // console.warn("cost change called", data);
    return {

        type: CHANGE_COST_SALESORDER,
        data: data,
        item: item,
        data2: data2

    }

}


export const changesalesorderdiscount = (data, item, data2) => {

    // console.warn("discount change called", data);
    return {

        type: CHANGE_SALESORDER_DISCOUNT,
        data: data,
        item: item,
        data2: data2

    }

}


export const changesalesordertext = (data, item) => {

    // console.warn("text change called", data);
    return {

        type: CHANGE_SALESORDER_TEXT,
        data: data,
        item: item
    }

}


export const emptysalesorderdata = () => {

    return {

        type: EMPTY_SALESORDER_DATA,
    }

}