import axios from 'axios';
import React, { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import authHeader from '../../services/auth-header';
import { BASE_URL } from '../../services/Api';
import { useNavigate, useParams } from 'react-router-dom';


const SetPrint = () => {
  const { id } = useParams();
  const {size}=useParams();
  const navigate = useNavigate();
  const labelStyle = {
    width: '60mm',
    // width:'260px',
    // height:'auto',
    height: '45mm',
    border: '1px solid black',
    padding: '2mm',
    boxSizing: 'border-box',
    fontSize: '12pt',
    marginTop:'21px',
    // rotate:'0deg'
   };
   const specialStyle = {
    marginTop:'37px'
  };
  const labelStyle1 = {
    width: '109mm',
    // width:'260px',
    // height:'auto',
    height: '69mm',
    border: '1px solid black',
    padding: '2mm',
    boxSizing: 'border-box',
    fontSize: '12pt',
    marginTop:'21px',
    // rotate:'0deg'
   };
   const specialStyle1 = {
    marginTop:'37px'
  };
  const [cloudfront, setCloudfront] = useState([]);
  const [data, setData] = useState([])


  useEffect(() => {

    axios.get(BASE_URL +`setbarcode/${id}`, {
      headers: authHeader()
  }).then(res => {
      if(res.data !== null){
            console.log('mello',res.data)
           setData(res.data)
           processArray(size)
      }
  })






    document.getElementById("qerteww").style.display = "none";
    document.getElementById("sidein").style.display = "none";


    const elements = ["naving", "1sidebar", "qw", "swift", "logoo", "logoo1", "menu"];
    elements.forEach(elementId => {
      const element = document.getElementById(elementId);
      if (element) {
        element.classList.add('hidden');
      }
    });


    const newElement = document.getElementById("new");
    if (newElement) {
      newElement.classList.add('fullWidth');
    }


  }, []);


  const handlePrint = () => {
    window.print();
  };


  function processArray(inputNumber) {
    const array = Array.from({ length: inputNumber }, (_, index) => index + 1);
    const mappedArray = array.map(item => item * 1);
    setCloudfront(mappedArray);
    console.log(mappedArray.map(() => `Neelam`));
  }
  const handleprint12=()=>{
    window.location.href = '/PrintSetbarcode/1';
  }

  return (
    <dd style={{ display: 'flex' }}>
      <div className='row'>
        <div className='col'>
          {/* <input type='number' min="1" onChange={(e) => processArray(e.target.value)} className='form-control btnmedia' /> */}
          <button className="btnmedia btn btn-primary"   onClick={handleprint12} style={{height:"auto",marginLeft:"20px", marginTop: '10mm',width:"150px" }}>
       Back
      </button>
      <button className="btnmedia btn btn-primary"   onClick={handlePrint} style={{height:"auto",marginLeft:"20px", marginTop: '10mm',width:"150px" }}>
        Print Label
      </button>
        </div>
      </div>

      <div className="print-content p1" >
      <div className="label-container">
  <div
    className="rotate label-style"
    style={{
      ...labelStyle1,
      ...specialStyle1, // Apply specialStyle directly if it's a constant style
    }}
  >
    <div className='row'>
      <div className='col-8' style={{ lineHeight: "16px" }}>
        <span className='heading1'>Kindly Scan QR Code For</span><br />
        <span className='heading1'>Company Detail & Consumer</span><br />
        <span className='heading1'>Care Detail.</span><br />
      </div>
      <div className='col'>
        {/* <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/QR_code_for_mobile_English_Wikipedia.svg/220px-QR_code_for_mobile_English_Wikipedia.svg.png"
          style={{ width: "55px", marginTop: "-4px" }} alt="QR Code" /> */}
        <QRCode
        style={{width:'20mm',marginTop:'0px',height:'auto'}}
          // className="qr-code2"
          size={256}
          value="Manufactured , Packed and Marketed By:
NEELAM APPLIANCES LIMITED 
Address:Neelam House, Fafadia Ind East. Waliv Village, Vasai (East)-401208 , Dist Palghar. Maharashtra (India).
PH: 8080561321  mail: mail@neelamappliances.com"
          viewBox="0 0 256 256"
        />
      </div>
    </div>
    <div className='row'>
      <div className='col  '  style={{ lineHeight: "16px" }}>
        <span className='product-name1'style={{    fontSize: '13px',fontWeight: '700'}}>Product Name</span>:<br />
        <span className='product-details1'style={{    fontSize: '13px',fontWeight: '700'}}>{data.productname1}</span><br />
        <span className='product-details1'style={{    fontSize: '13px',fontWeight: '700'}}>{data.productname2}</span>
      </div>
    </div>
    <div className='row jksrow'>
      <div className='col jkscol' >
        <dd className='dd' style={{ lineHeight: "16px" }}>
          <span className='label1'style={{    fontSize: '13px',fontWeight: '700'}}>EAN Code:</span> <br />
          <span className='value11'style={{    fontSize: '9x',fontWeight: '700'}}>{data.eancode}</span><br />
        </dd>
      { data.eancode?<QRCode
          className='qr-code'
          size={256}
          value={data.eancode}
          viewBox={`0 0 256 256`}
        />:""}
      </div>
      <div className='col'>
        <dd className='dd'style={{ lineHeight: "16px" }}>
          <span className='label1' style={{    fontSize: '13px',fontWeight: '700'}}>Quantity Approx:</span> <br />
          <span className='value1' style={{    fontSize: '9px',fontWeight: '700'}}>{data.quantity}</span><br />
        </dd>
      {data.quantity?<QRCode
          className='qr-code'
          size={256}
          value={data.quantity}
          viewBox={`0 0 256 256`}
        />:""}
      </div>
    </div>
    <b style={{ fontSize: "12px", fontWeight: "700", position: "relative", top: "13px" }}>
      *THIS LABEL IS USED FOR INTERNAL PURPOSE ONLY.*
    </b>
  </div>
</div>

        
         
      </div>


      <div className="print-content " >

   


        {cloudfront.map((item, index) => (
          <>
          <div key={index} className="label-container p2" >
            <div className={`${index+1 >= 2?'rotate jknone label-style ':"rotate label-style "}`}  style={{
                ...labelStyle,
                ...(index > 3 ? specialStyle : {}),  
                ...(index >= 2 ? specialStyle : {}),
              }}>
              <div className='row'>
                <div className='col-6' style={{ lineHeight: "8px" }}>
                  <span className='heading' >Kindly Scan QR Code For</span><br />
                  <span className='heading' >Company Detail & Consumer</span><br />
                  <span className='heading'  >Care Detail.</span><br />
                </div>
                <div className='col'>
                  {/* <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/QR_code_for_mobile_English_Wikipedia.svg/220px-QR_code_for_mobile_English_Wikipedia.svg.png"
                    style={{ width: "55px", marginTop: "-4px" }} alt="QR Code" /> */}
              <QRCode
              // style={{width:'20mm',height:'auto',marginTop:"0px"}}
    className="qr-code2"
    size={256}
    value="Manufactured , Packed and Marketed By:
NEELAM APPLIANCES LIMITED 
Address:Neelam House, Fafadia Ind East. Waliv Village, Vasai (East)-401208 , Dist Palghar. Maharashtra (India).
PH: 8080561321  mail: mail@neelamappliances.com"
    viewBox="0 0 256 256"
/>
                </div>
              </div>
              <div className='row'>
                <div className='col linehighter'  >
                <span className='product-name'>Product Name</span>:<br />
      <span className='product-details'>{data.productname1}</span><br />
      <span className='product-details'>{data.productname2}</span>

                </div>
              </div>
              {/* <br></br> */}
              <div className='row jksrow'>
    <div className='col jkscol'>
        <dd className='dd'>
            <span className='label'>EAN Code:</span> <br />
            <span className='value'>{data.eancode}</span><br />
        </dd>
        <QRCode
            className='qr-code'
            size={256}
            value={data.eancode}
            viewBox={`0 0 256 256`}
        />
    </div>
    <div className='col'>
        <dd className='dd'>
            <span className='label'>Quantity Approx:</span> <br />
            <span className='value'>{data.quantity}</span><br />
        </dd>
        <QRCode
            className='qr-code'
            size={256}
            value={data.quantity}
            viewBox={`0 0 256 256`}
        />
    </div>
</div>
              <b style={{ fontSize: "7px", fontWeight: "700", position: "relative", top: "-5px" }}>*THIS LABEL IS USED FOR INTERNAL PURPOSE ONLY.*</b>
            </div>
          </div>



          </>
        ))}
         
      </div>
  
      <style>
        {`.jksrow {
    margin-top: 9px;
}
    .p2{
    display:none!important
    }
    .heading1{
      border-bottom: 1px solid black;
  font-size: 12px;
  font-weight: 700;
    
    }
.p1{
    display:block!important

}
    .label-style {
    width: 60mm; /* or '260px' if you prefer */
    height: 45mm;
    border: 1px solid black;
    padding: 2mm;
    box-sizing: border-box;
    font-size: 12pt;
    margin-top: 21px;
    /* rotate: 0deg; */ /* Uncomment if rotation is needed */
}
.jknone{
display:none}
.jkscol {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.dd {
    line-height: 6px;
}

.dd span {
    font-weight: 700;
}

.dd .label {
    font-size: 7px;
}

.dd .value {
    font-size: 8px;
}

/* QR Code Styles */
.qr-code {
    height: auto;
    width: 10mm;
}

.qr-code2 {
    height: auto;
    width: 14mm;
    margin-top: -4px;
}
         .print-content {
         
              display: grid;
              grid-template-columns: repeat(2, 40mm);
              gap: 27mm;  


            }
           .heading {
  border-bottom: 1px solid black;
  font-size: 7px;
  font-weight: 700;
}
          @media print {
            @page {
              margin: 0;
            }
            body {
              margin: 0;
              padding: 0;
            }
              .p1{
    display:none!important

}

.p2{
    display:block!important
    }


              .jknone{
display:block}
            .rotate {
              width: 60mm;
              height: 45mm;
              margin: 0;
              padding: 0;
            }
            .btnmedia {
              display: none; /* Hide the print button during printing */
            }
            .print-content {
             display: grid;
              grid-template-columns: repeat(2, 40mm);
              gap: 7mm;  
            }
            .label-container {
              break-inside: avoid; /* Prevent breaking inside label-container */
            }
            .page-break {
              page-break-before: always;
              display: block;
              height: 0;
              margin: 0;
            }
          }
        `}
      </style>
    </dd>
  );
};


export default SetPrint;



