import axios from "axios";
import authHeader from "./auth-header";

const API_URL = "http://43.225.54.138:8080/scm/api/";

const getPublicContent = () => {
  return axios.get(API_URL + "all");
};

const getUserBoard = () => {
  return axios.get(API_URL + "user", { headers: authHeader() });
};

const getModeratorBoard = () => {
  return axios.get(API_URL + "mod", { headers: authHeader() });
};

const getAdminBoard = () => {
  return axios.get(API_URL + "all", { headers: authHeader() });
};
const getcountry = () => {
  return axios.get(API_URL + "company/getAllcountry", { headers: authHeader() });
};
const getstate = (cid) => {
  return axios.get(API_URL + "company/getByCountryId/"+cid, { headers: authHeader() });
};
const getstateEdit = () => {
  return axios.get(API_URL + "company/getAllstate", { headers: authHeader() });
};
const getstatecode = (cid) => {
  return axios.get(API_URL + "company/getStatecodeById/"+cid, { headers: authHeader() });
};
const getcountrybyid = (id) => {
  return axios.get(API_URL + "company/getCountryById/"+id, { headers: authHeader() });
};
const getstateybyid = (cid) => {
  return axios.get(API_URL + "company/getStateById/"+cid, { headers: authHeader() });
};
const getpurchasereturn = ( purchaseReturn ) => {
  return axios.post(API_URL + "purchasereturn/add",purchaseReturn, { headers: authHeader() });
};

const getpurchasereturn1 = ( id ) => {
  return axios.get(API_URL + "purchasereturn/findPurchaseReturnByPurchaseId/"+id, { headers: authHeader() });
};

const getpurchasereturnget = ( ) =>{
  return axios.get(API_URL + "purchasereturn/getAll", { headers: authHeader() });
}

const getpurchasereturnedit = ( id ) =>{
  return axios.get(API_URL + "purchasereturn/getById/"+id, { headers: authHeader() });
}

const getreturndelete = (id) =>{
  return axios.delete(API_URL + "purchasereturn/delete/"+id, { headers: authHeader() });
}

const getWaredetailreport = (warehouse,start_date,end_date) =>{
  return axios.get(API_URL + "warehousereport/report/" + warehouse+'/'+start_date+'/'+end_date, { headers: authHeader() });
}

const getprimaryorder = ( primaryorder ) => {
  return axios.post(API_URL + "primaryworkorder/create",primaryorder, { headers: authHeader() });
};

const getdisdelete = (id) =>{
  return axios.delete(API_URL + "distributor/openingstock/delete/"+id, { headers: authHeader() });
}



const getprimaryordertable = ( ) => {
  return axios.get(API_URL + "primaryworkorder/getAll", { headers: authHeader() });
};
const getprimaryorderedit = ( id ) => {
  return axios.get(API_URL + "primaryworkorder/getById/"+id, { headers: authHeader() });
};
const getprimaryorderdelete = (id) => {
  return axios.delete(API_URL + "primaryworkorder/delete/"+id, { headers: authHeader() });
};

const getZoneData = ( ) => {
  return axios.get(API_URL + "zone/getAll", { headers: authHeader() });
};
const getZoneDataEdit = ( id ) => {
  return axios.get(API_URL + "zone/getStaffByZoneId/"+id, { headers: authHeader() });
};

const getZoneDataEdit1 = ( id ) => {
  console.log(id)
  return axios.get(API_URL + "zone/getById/"+id, { headers: authHeader() });
};

const getRSMData = ( id ) => {
  return axios.get(API_URL + "staff/rsm/zone/"+id, { headers: authHeader() });
};
const getNSMData = ( ) => {
  return axios.get(API_URL + "staff/nsm", { headers: authHeader() });
};

const getASMData = ( id ) => {
  return axios.get(API_URL + "staff/asm/rsm/"+id, { headers: authHeader() });
};

const getASMedit = ( id ) => {
  return axios.get(API_URL + "staff/asm/asm/"+id, { headers: authHeader() });
};
const getASEData = ( id ) => {
  return axios.get(API_URL + "staff/ase/asm/"+id, { headers: authHeader() });
};
const getASEData1 = ( id ) => {
  return axios.get(API_URL + "staff/ase/rsm/"+id, { headers: authHeader() });
};
const getAEedit = ( id ) => {
  return axios.get(API_URL + "staff/ase/ase/"+id, { headers: authHeader() });
};
// const getWaredetailreport = (warehouse,start_date,end_date) =>{
//   return axios.get(API_URL + "warehousereport/report/" + warehouse+'/'+start_date+'/'+end_date, {headers:authHeader()} );
// }
const getprimaryestimatedays = (id,pid,days,primaryItemId) => {
  return axios.put(API_URL +"workorder/addEstimatedDays/" + id + '/' + pid + '/' + days + '/' + primaryItemId,{ headers: authHeader() });
}
// 110/1630/300/1
const UserService = {
  getPublicContent,
  getUserBoard,
  getModeratorBoard,
  getAdminBoard,
  getcountry,
  getstate,
  getstatecode,
  getstateEdit,
  getcountrybyid,
  getstateybyid,
  getpurchasereturn,
  getpurchasereturn1,
  getpurchasereturnget,
  getreturndelete,
  getpurchasereturnedit,
  getWaredetailreport,
  getprimaryorder,
  getprimaryorderedit,
  getprimaryordertable,
  getprimaryorderdelete,
  getZoneData,
  getZoneDataEdit,
  getRSMData,
  getNSMData,
  getASMData,
  getASMedit,
  getASEData,
  getASEData1,
  getdisdelete,
  getAEedit,
  getprimaryestimatedays,
  getZoneDataEdit1
};

export default UserService;