import axios from "axios";
import { MDBCard } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Accordion, Button, Col, Form, Row, Table } from "react-bootstrap";
import { BASE_URL } from "../services/Api";
import authHeader from "../services/auth-header";
import Select from "react-select";
import { Link, useNavigate, useParams } from "react-router-dom";
import ProductService from "../services/ProductService";
import { changecostsales, changesalescalmeasurement, changesalesdiscount, changesalesqty, changesalessqpbmeasurement, changesalestext, changeunitofMeasurementsales, getsalesdatatoload, removefromcartfromsales, salesaddtocart } from "../../redux/Action/Sales";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";

export default function Create() {
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const applyFontSizeTransition = (elementId) => {
        const element = document.getElementById(elementId);
        element.style.transition = 'font-size 2s';
        element.style.fontSize = '30px';
        element.style.fontWeight = '600';
        // font-weight: 600;
        setTimeout(() => {

            document.getElementById("typer1").classList.add('promote');
            element.style.fontSize = '24px';
        }, 1000);
    };
    const [warehousedata, setwarehousedata] = useState('');
    const [warehouse, setwarehouse] = useState('');
    const [branchdata, setbranchdata] = useState('');
    const [branch, setbranch] = useState('');
    const [distributordata, setdistributordata] = useState('');
    const [distributor, setdistributor] = useState('');
    const [ackdate, setackdate] = useState(new Date());
    const [retailer, setretailer] = useState('');
    const [retailerdata, setretailerdata] = useState('');
    const [buyerorderno, setbuyerorderno] = useState('');
    const [invoiceno, setinvoiceno] = useState('');
    const [invoicedate, setinvoicedate] = useState('');
    const [type, settype] = useState('b2b');
    const [status, setstatus] = useState('');
    const [termsofdelivery, settermsofdelivery] = useState('');
    const [deliverynoteno, setdeliverynoteno] = useState('');
    const [destination, setdestination] = useState('');
    const [packaginglistno, setpackaginglistno] = useState('');
    const [gstno, setgstno] = useState('');
    const [panno, setpanno] = useState('');
    const [ewaybillno, setewaybillno] = useState('');
    const [grnno, setgrnno] = useState('');
    const [lrno, setlrno] = useState('');
    const [udyamno, setudyamno] = useState('');
    const [msmeno, setmsmeno] = useState('');
    const [productdata, setproductdata] = useState([]);
    const [boxProductDiscount, setboxProductDiscount] = useState('');
    const [schemeboxProductDiscount, setschemeboxProductDiscount] = useState('');
    const [kgProductDiscount, setkgProductDiscount] = useState('');
    const [schemekgProductDiscount, setschemekgProductDiscount] = useState('');
    const [corporaetProductDiscount, setcorporaetProductDiscount] = useState('');
    const [schemecorporateProductDiscount, setschemecorporateProductDiscount] = useState('');
    const [cookerProductDiscount, setcookerProductDiscount] = useState('');
    const [schemecookerProductDiscount, setschemecookerProductDiscount] = useState('');
    const [noshProductDiscount, setnoshProductDiscount] = useState('');
    const [schemenoshProductDisocunt, setschemenoshProductDisocunt] = useState('');
    const [taxtype, settaxtype] = useState('');
    const [shippingcharge, setShipping] = useState(0);
    const [round, setRound] = useState(0);
    const [bstateid, setbstateid] = useState('');
    const [dstateid, setdstateid] = useState('');
    const [paymentTerms, setpaymentTerms] = useState('');
    const [getaddresss, setaddress] = useState([])
    const [deliveryAddress, setdelvieryaddress] = useState([]);
    const [lrndate, setlrndate] = useState('');
    const [edd, setedd] = useState('');
    const [grndate, setgrndate] = useState('');
    const [dispatchedthrough, setdispatchedthrough] = useState('');
    const [totalnopkg, settotalnopkg] = useState('');
    const [img, setimg] = useState();
    const [pdf, setpdf] = useState();
    const [taxSch, settaxSch] = useState('');
    const [supTyp, setsupTyp] = useState('');
    const [no, setno] = useState('');
    const [dt1, setdt1] = useState('');
    const [seller, setseller] = useState({});
    const [buyer, setbuyer] = useState({});
    const [slNo, setslNo] = useState('');
    const [prdDesc, setprdDesc] = useState('');
    const [isServc, setisServc] = useState('');
    const [hsnCd, sethsnCd] = useState('');
    const [barcde, setbarcde] = useState('');
    const [qty, setqty] = useState('');
    const [freeQty, setfreeQty] = useState('');
    const [unit, setunit] = useState('');
    const [unitPrice, setunitPrice] = useState('');
    const [totAmt, settotAmt] = useState('');
    const [discount, setdiscount] = useState('');
    const [preTaxVal, setpreTaxVal] = useState('');
    const [assAmt, setassAmt] = useState('');
    const [gstRt, setgstRt] = useState('');
    const [igstAmt, setigstAmt] = useState('');
    const [cgstAmt, setcgstAmt] = useState('');
    const [sgstAmt, setsgstAmt] = useState('');
    const [cesRt, setcesRt] = useState('');
    const [cesAmt, setcesAmt] = useState('');
    const [cesNonAdvlAmt, setcesNonAdvlAmt] = useState('');
    const [stateCesRt, setstateCesRt] = useState('');
    const [stateCesAmt, setstateCesAmt] = useState('');
    const [stateCesNonAdvlAmt, setstateCesNonAdvlAmt] = useState('');
    const [othChrg, setothChrg] = useState('');
    const [totItemVal, settotItemVal] = useState('');
    const [ordLineRef, setordLineRef] = useState('');
    const [orgCntry, setorgCntry] = useState('');
    const [prdSlNo, setprdSlNo] = useState('');
    const [nm, setnm] = useState('');
    const [expDt1, setexpDt1] = useState('');
    const [wrDt1, setwrDt1] = useState('');
    const [anm, setanm] = useState({});
    const [valdtls, setvaldtls] = useState({});
    const [distance, setdistance] = useState('');
    const [transMode, settransMode] = useState('');
    const [transId, settransId] = useState('');
    const [transName, settransName] = useState('');
    const [transDocDt1, settransDocDt1] = useState('');
    const [transDocNo, settransDocNo] = useState('');
    const [vehNo, setvehNo] = useState('');
    const [vehType, setvehType] = useState('');
    const [voucherid, setvoucherid] = useState('');
    const [voucherseries, setvoucherseries] = useState('');
    const modes1=[
        { "id": 1, "label": "Road" },
        { "id": 2, "label": "Rail" },
        { "id": 3, "label": "Air" },
        { "id": 4, "label": "Ship" },
        { "id": 5, "label": "In Transit" }
      ]
      const vechiletype=[
        { "id": "R", "label": "Regular" },
        { "id": "O", "label": "ODC" }
      ]
    let dispatch = useDispatch();
    const navigate = useNavigate();
    let salesItems1 = useSelector((state) => state.salesdata);
    let da_ta = salesItems1.length && salesItems1.map((user) => ({
        "brand": user.brand,
        "calunitofmeasurement": user.calunitofmeasurement,
        "capacity": user.capacity,
        "category": user.category,
        "cgst": user.cgst,
        "cgstLedger": user.cgstLedger,
        "diameter": user.diameter,
        "dlp": user.dlp,
        "eanCode": user.eanCode,
        "grossamount": user.grossamount,
        "gstvalue": user.gstvalue,
        "hsnCode": user.hsnCode,
        "igst": user.igst,
        "igstLedger": user.igstLedger,
        "netAmount": user.netAmount,
        "product": { "id": user.product.id },
        "productName": user.productName,
        "productType": user.productType,
        "salesquantity": user.salesquantity,
        "salesquantitykgs": user.salesquantitykgs,
        "schemeDiscount": user.schemeDiscount,
        "sgst": user.sgst,
        "sgstLedger": user.sgstLedger,
        "shortName": user.shortName,
        "soid": user.soid,
        "standardQtyPerBox": user.standardQtyPerBox,
        "total": user.total,
        "tradeDiscount": user.tradeDiscount,
        "unitofmeasurement": user.unitofmeasurement,
        "uomPrimary": user.uomPrimary,
        "uomSecondary": user.uomSecondary,
        // "SlNo":user.soid,
        "prdDesc": user.PrdDesc,
        "isServc": "N",
        "qty": user.salesquantitykgs,
        "freeQty": user.salesquantity,
        "unit": user.unitofmeasurement,
        "totAmt": user.grossamount,
        "discount": ((Number(user.grossamount)/100) * Number(user.schemeDiscount)).toFixed(2),
        "assAmt": user.netAmount,
        "gstRt": user.igst,
        "igstAmt":seller.stcd === buyer.stcd?0:Number(user.gstvalue),
        "cgstAmt":seller.stcd === buyer.stcd? ((user.gstvalue)/2):0,
        "sgstAmt": seller.stcd === buyer.stcd?((user.gstvalue)/2):0,
        "totItemVal": user.total

    }));
    let gross_Amount = salesItems1.length && salesItems1.map(item => Math.round(item.total)).reduce((prev, next) => Math.round(prev - (-next)));
    let gross_AmountassVal = salesItems1.length && salesItems1.map(item => (item.netAmount)).reduce((prev, next) => (prev - (-next)));
    let gst = salesItems1.length && salesItems1.map(item => (item.gstvalue)).reduce((prev, next) => (prev - (-next)));
    let cgstVal = salesItems1.length && salesItems1.map(item => (item.gstvalue)).reduce((prev, next) => (prev - (-next)));
    // console.log('mello',salesItems1)
    let aa = [];
    salesItems1.map(user => {
        let igst_value = user.igst;
        let total_value = user.total;
        let total_amou = ((Number(shippingcharge) / Number(gross_Amount)) * Number(total_value));
        let add_amou = Number(total_amou) - (-Number(total_value));
        let final_amou = ((Number(add_amou) / 100) * Number(igst_value)).toFixed(2);
        aa.push(final_amou)

        // console.log("mello",((Number(add_amou)/100)*Number(igst_value)).reduce((prev, next) => Math.round(prev - (-next))) );
    })
    let total_per = aa.length && aa.map(user => user).reduce((prev, next) => (prev - (-next)));

    let grossAmount = Number(gross_Amount) + Number(shippingcharge);
    let grandTotal = Number(gross_Amount) + Number(shippingcharge) + Number(round) + Number(total_per);

    let igstTax = Number(total_per);
    let cgstTax = (Number(total_per) / 2);
    let sgstTax = (Number(total_per) / 2);


    function roundData(e) {
        if (e) {
            let gross_Amount = salesItems1.length && salesItems1.map(item => Math.round(item.total)).reduce((prev, next) => Math.round(prev - (-next)));
            let gst = salesItems1.length && salesItems1.map(item => (item.gstvalue)).reduce((prev, next) => (prev - (-next)));

            let aa = [];
            salesItems1.map(user => {
                let igst_value = user.igst;
                let total_value = user.total;
                let total_amou = ((Number(shippingcharge) / Number(gross_Amount)) * Number(total_value));
                let add_amou = Number(total_amou) - (-Number(total_value));
                let final_amou = ((Number(add_amou) / 100) * Number(igst_value)).toFixed(2);
                aa.push(final_amou)

                // console.log("mello",((Number(add_amou)/100)*Number(igst_value)).reduce((prev, next) => Math.round(prev - (-next))) );
            })
            let total_per = aa.length && aa.map(user => user).reduce((prev, next) => (prev - (-next)));


            let grossamount = Number(gross_Amount) + Number(shippingcharge);
            let grandTotal = Number(gross_Amount) + Number(shippingcharge) + Number(total_per);
            let grand_Total = Math.ceil(grandTotal);

            if (grand_Total > grandTotal) {
                let rou_nd = (Number(grand_Total) - Number(grandTotal)).toFixed(2);
                setRound(rou_nd);
            } else {
                setRound(0)
            }


        } else if (e == '') {
            setRound(0)
        }
    }

    const getSalesOrder = () => {

        axios.get(BASE_URL + 'dc/' + id, {
            headers: authHeader()
        }).then(res => {
            setdelvieryaddress(res.data.deliveryAddress)
            console.log("mello", res.data);
            setaddress(res.data.distributor == null ? res.data.retailer.deliveryAddress : res.data.distributor.deliveryAddress);
            setbranchdata(res.data.branch.branchname);
            setbranch(res.data.branch.id);
            setbstateid(res.data.branch.states.id);
            setwarehousedata(res.data.warehouse);
            setwarehouse(res.data.warehouse.id);
            setbuyerorderno(res.data.buyerorderno);
            setdistributordata(res.data.distributor == null ? null : res.data.distributor);
            setdistributor(res.data.distributor == null ? null : res.data.distributor.id);
            setretailerdata(res.data.retailer == null ? null : res.data.retailer);
            setretailer(res.data.retailer == null ? null : res.data.retailer.id);
            setpaymentTerms(res.data.paymentTerms);
            setdstateid(res.data.distributor == null ? res.data.retailer.stateid : res.data.distributor.stateid)
            setdestination(res.data.destination);
            setgstno(res.data.gstno);
            setdispatchedthrough(res.data.dispatchedthrough);
            settotalnopkg(res.data.totalnopkg);
            setlrno(res.data.irnno);
            setudyamno(res.data.udyamno);
            setmsmeno(res.data.msmeno);
            settermsofdelivery(res.data.termsofdelivery);
            setdeliverynoteno(res.data.deliverynotno);
            settaxtype(res.data.taxtype);
            setboxProductDiscount(res.data.distributor == null ? res.data.retailer.boxProductDiscount : res.data.distributor.boxProductDiscount);
            setschemeboxProductDiscount(res.data.distributor == null ? res.data.retailer.schemeboxProductDiscount : res.data.distributor.schemeboxProductDiscount);
            setkgProductDiscount(res.data.distributor == null ? res.data.retailer.kgProductDiscount : res.data.distributor.kgProductDiscount);
            setschemekgProductDiscount(res.data.distributor == null ? res.data.retailer.schemekgProductDiscount : res.data.distributor.schemekgProductDiscount);
            setcorporaetProductDiscount(res.data.distributor == null ? res.data.retailer.corporaetProductDiscount : res.data.distributor.corporaetProductDiscount);
            setschemecorporateProductDiscount(res.data.distributor == null ? res.data.retailer.schemecorporateProductDiscount : res.data.distributor.schemecorporateProductDiscount);
            setcookerProductDiscount(res.data.distributor == null ? res.data.retailer.cookerProductDiscount : res.data.distributor.cookerProductDiscount);
            setschemecookerProductDiscount(res.data.distributor == null ? res.data.retailer.schemecookerProductDiscount : res.data.distributor.schemecookerProductDiscount);
            setnoshProductDiscount(res.data.distributor == null ? res.data.retailer.noshProductDiscount : res.data.distributor.noshProductDiscount);
            setschemenoshProductDisocunt(res.data.distributor == null ? res.data.retailer.schemenoshProductDisocunt : res.data.distributor.schemenoshProductDisocunt);
            setvoucherid(res.data.voucherid);
            setvoucherseries(res.data.voucherseries);
        })
    }

    /* get Product Data */

    let getProductData = () => {

        axios.get(BASE_URL + 'product/page/0/20/asc/id/%20', {
            headers: authHeader()
        }).then(res => {

            setproductdata((res.data.Index).map(use => ({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code + ' - ' + use.short_Name })));
        })

    };

    const customFilter = (e) => {
        let name = e;
        axios.get(BASE_URL + 'product/page/0/20/asc/id/' + name, {
            headers: authHeader()
        }).then(res => {
            setproductdata((res.data.data).map(use => ({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code + ' - ' + use.short_Name })))
        })
    }

    let date_value = new Date(ackdate);
    const defaultValue = date_value.toLocaleDateString('en-CA');
    useEffect(() => {
        getSalesOrder();
        getProductData();
        setackdate(defaultValue);
        dispatch(getsalesdatatoload(id));
    }, []);

    let addData = (e) => {
        let name = e.value;
        let filteredItems = salesItems1.filter(item => item.product.id == name);
        if (filteredItems.length == "0") {
            dispatch(salesaddtocart(name, ({
                "schemeboxProductDiscount": schemeboxProductDiscount,
                "schemekgProductDiscount": schemekgProductDiscount,
                "schemecorporateProductDiscount": schemecorporateProductDiscount,
                "schemecookerProductDiscount": schemecookerProductDiscount,
                "schemenoshProductDisocunt": schemenoshProductDisocunt,
                "kgProductDiscount": kgProductDiscount,
                "boxProductDiscount": boxProductDiscount,
                "corporaetProductDiscount": corporaetProductDiscount,
                "cookerProductDiscount": cookerProductDiscount,
                "noshProductDiscount": noshProductDiscount
            })));
            setShipping("0");
        } else {
            Swal.fire({
                title: 'This Product is Already Added',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonColor: 'red'
            })
        }
    }

    /* get Shipping Charges */
    let settingship = (e) => {
        if (e == "") {

            setShipping("");
        } else {
            setShipping(e);
        }
    }

    /* get Round Value */
    const settinground = (e) => {

        if (e == "") {

            setRound("");
        } else {


            setRound(e);
        }

    };

    const SGSTcheck = () => {
        let aa = 0

        if (taxtype == "withtax") {

            if (bstateid === dstateid) {

                aa = sgstTax;

            } else {
                aa = 0;
            }

        } else {
            aa = 0;
        }

        return aa;

    }

    const cgstcheck = () => {

        let bb = 0

        if (taxtype == "withtax") {

            if (bstateid === dstateid) {

                bb = cgstTax;

            } else {

                bb = 0;

            }

        } else {

            bb = 0;

        }
        return bb;
    }

    const igstcheck = () => {
        let cc = 0;

        if (taxtype == "withtax") {
            if (bstateid === dstateid) {
                cc = 0;
            } else {
                cc = igstTax;
            }
        } else {
            cc = 0;
        }
        return cc;
    }

    const datevalue_1 = new Date(dt1);
    const formatter = new Intl.DateTimeFormat('in-US', { day: '2-digit', month: '2-digit', year: 'numeric' });
    const defaultValue_1 = dt1 == "" ? '' : formatter.format(datevalue_1);
    const datevalue_2 = new Date(expDt1);
    const defaultValue_2 = expDt1 == "" ? '' : formatter.format(datevalue_2);
    const datevalue_3 = new Date(wrDt1);
    const defaultValue_3 = wrDt1 == "" ? '' : formatter.format(datevalue_3);
    const datevalue_4 = new Date(transDocDt1);
    const defaultValue_4 = transDocDt1 == "" ? '' : formatter.format(datevalue_4);


    const handleChange = (e) => setseller({ ...seller, [e.target.name]: e.target.value });
    const handleChange1 = (e) => setbuyer({ ...buyer, [e.target.name]: e.target.value });
    const handleChange2 = (e) => setanm({ ...anm, [e.target.name]: e.target.value });
    const handleChange3 = (e) => setvaldtls({ ...valdtls, [e.target.name]: e.target.value });
    const stype = [
        { "id": "B2B", "label": "Business to Business" },
        { "id": "SEZWP", "label": "SEZ with payment" },
        { "id": "SEZWOP", "label": "SEZ without payment" },
        { "id": "EXPWP", "label": "Export with Payment" },
        { "id": "EXPWOP", "label": "Export without payment" },
        { "id": "DEXP", "label": "Deemed Export" }
    ]

    const submitdata = () => {
        setIsLoading(true);
        let salesItems = da_ta;
        let igst = igstcheck();
        let cgst = cgstcheck();
        let sgst = SGSTcheck();
        let grandtotal = grandTotal;
        let roundofvalue = round;
        let dcId = id;
        let dt = defaultValue_1;
        let expDt = defaultValue_2;
        let wrDt = defaultValue_3;
        let transDocDt = defaultValue_4;
        let attribDtls = [anm];
        let tdls = { taxSch, supTyp }
        const doc = { no, dt, }
        let valdtls = { "assVal": gross_AmountassVal, "cgstVal":seller.stcd === buyer.stcd?(cgstVal/2):0, "sgstVal": seller.stcd === buyer.stcd?(cgstVal/2):0, "igstVal": seller.stcd === buyer.stcd?0:cgstVal, "othChrg": shippingcharge, "totInvVal": grossAmount, "cesVal": 0, "stCesVal": 0 }

        let ewb = { distance, transId, transName, transDocDt, transDocNo, vehNo, vehType, transMode }
        if (distributordata !== null) {
            const formdata = new FormData();
            formdata.append('warehouse', warehouse);
            formdata.append('dcId', dcId);
            formdata.append('distributor', distributor);
            formdata.append('buyerorderno', buyerorderno);
            formdata.append('invoiceno', invoiceno);
            formdata.append('invoicedate', invoicedate);
            formdata.append('status', status);
            formdata.append('taxtype', taxtype);
            formdata.append('termsofdelivery', termsofdelivery);
            formdata.append('deliverynoteno', deliverynoteno);
            formdata.append('destination', destination);
            formdata.append('packaginglistno', packaginglistno);
            formdata.append('gstno', gstno);
            formdata.append('panno', panno);
            // formdata.append('ewaybillno', ewaybillno);
            formdata.append('grnno', grnno);
            formdata.append('lrno', lrno);
            formdata.append('udyamno', udyamno);
            formdata.append('msmeno', msmeno);
            formdata.append('si', JSON.stringify(salesItems));
            formdata.append('lrndate', lrndate);
            formdata.append('edd', edd);
            formdata.append('grndate', grndate);
            formdata.append('igst', igst);
            formdata.append('cgst', cgst);
            formdata.append('sgst', sgst);
            formdata.append('grandtotal', grandtotal);
            formdata.append('deliveryAddress', deliveryAddress);
            formdata.append('grossAmount', grossAmount);
            formdata.append('shippingcharge', shippingcharge);
            formdata.append('roundofvalue', roundofvalue);
            formdata.append('branch', branch);
            formdata.append('paymentTerms', paymentTerms);
            formdata.append('dispatchedthrough', dispatchedthrough);
            formdata.append('totalnopkg', totalnopkg);
            formdata.append('img', img);
            formdata.append('pdf', pdf);
            formdata.append('tdls', JSON.stringify(tdls));
            formdata.append('doc', JSON.stringify(doc));
            formdata.append('seller', JSON.stringify(seller));
            formdata.append('buyer', JSON.stringify(buyer));
            formdata.append('valdtls', JSON.stringify(valdtls));
            formdata.append('ewb', JSON.stringify(ewb));
            formdata.append('voucherid', voucherid);
            axios.post(BASE_URL + 'sales/dc/' + id, formdata, {
                headers: authHeader()
            }).then(res => {
                navigate('/Sales/Table/1')
            }).catch(error => {
                console.error('Error adding  :', error);
                if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `
                    <div>
                      <p id="issueText">Why do I have this issue?</p>
                      <ul>
                  
                      <li id="typer1">You might have an internet issue</li>
                      </ul>
                    </div>
                  `,
                        didOpen: () => {
                            applyFontSizeTransition('issueText');
                        }
                    });
                }
            })
                .finally(() => {
                    setIsLoading(false);
                });
        } else if (retailerdata !== null) {
            const formdata = new FormData();
            formdata.append('warehouse', warehouse);
            formdata.append('dcId', dcId);
            formdata.append('ackdate', ackdate);
            formdata.append('retailer', retailer);
            formdata.append('buyerorderno', buyerorderno);
            formdata.append('invoiceno', invoiceno);
            formdata.append('invoicedate', invoicedate);
            formdata.append('status', status);
            formdata.append('taxtype', taxtype);
            formdata.append('termsofdelivery', termsofdelivery);
            formdata.append('deliverynoteno', deliverynoteno);
            formdata.append('destination', destination);
            formdata.append('packaginglistno', packaginglistno);
            formdata.append('gstno', gstno);
            formdata.append('panno', panno);
            // formdata.append('ewaybillno', ewaybillno);
            formdata.append('grnno', grnno);
            formdata.append('lrno', lrno);
            formdata.append('udyamno', udyamno);
            formdata.append('msmeno', msmeno);
            formdata.append('si', JSON.stringify(salesItems));
            formdata.append('lrndate', lrndate);
            formdata.append('edd', edd);
            formdata.append('grndate', grndate);
            formdata.append('igst', igst);
            formdata.append('cgst', cgst);
            formdata.append('sgst', sgst);
            formdata.append('grandtotal', grandtotal);
            formdata.append('deliveryAddress', deliveryAddress);
            formdata.append('grossAmount', grossAmount);
            formdata.append('shippingcharge', shippingcharge);
            formdata.append('roundofvalue', roundofvalue);
            formdata.append('branch', branch);
            formdata.append('paymentTerms', paymentTerms);
            formdata.append('dispatchedthrough', dispatchedthrough);
            formdata.append('totalnopkg', totalnopkg);
            formdata.append('img', img);
            formdata.append('pdf', pdf);
            formdata.append('tdls', JSON.stringify(tdls));
            formdata.append('doc', JSON.stringify(doc));
            formdata.append('seller', JSON.stringify(seller));
            formdata.append('buyer', JSON.stringify(buyer));
            formdata.append('valdtls', JSON.stringify(valdtls));
            formdata.append('ewb', JSON.stringify(ewb));
            formdata.append('voucherid', voucherid);
            axios.post(BASE_URL + 'sales/dc/' + id, formdata, {
                headers: authHeader()
            }).then(res => {
                navigate('/Sales/Table/1')
            }).catch(error => {
                console.error('Error adding  :', error);
                if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `
                    <div>
                      <p id="issueText">Why do I have this issue?</p>
                      <ul>
                  
                      <li id="typer1">You might have an internet issue</li>
                      </ul>
                    </div>
                  `,
                        didOpen: () => {
                            applyFontSizeTransition('issueText');
                        }
                    });
                }
            })
                .finally(() => {
                    setIsLoading(false);
                });
        }


    }
    const handlesuptype=(e)=>{
        setsupTyp(e.id)
     }
    return (
        <MDBCard className="company">
            <h1>Sales Create</h1>
            <Link to='/Sales/Table/1' className="btn btn-primary">Back</Link>
            <Form className="product1">
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Sales Data</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Branch Name</Form.Label>
                                    <Form.Control type="text" value={branchdata} readOnly />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Warehouse Name</Form.Label>
                                    <Form.Control type="text" value={warehousedata.name} readOnly />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Date</Form.Label>
                                    <Form.Control type="date" value={ackdate} onChange={(e) => setackdate(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Terms of Payment</Form.Label>
                                    <Form.Control type='text' value={paymentTerms} name="paymentTerms" onChange={(e) => setpaymentTerms(e.target.value)} autoComplete="off" />
                                </Form.Group>
                                {distributordata !== null &&
                                    <Form.Group as={Col} md="4" controlId="formBasicName">
                                        <Form.Label className="label">Customer Name</Form.Label>
                                        <Form.Control type="text" value={distributordata.tradeName} readOnly />
                                    </Form.Group>}
                                {retailerdata !== null &&
                                    <Form.Group as={Col} md="4" controlId="formBasicName">
                                        <Form.Label className="label">Customer Name</Form.Label>
                                        <Form.Control type="text" value={retailerdata.tradeName} readOnly />
                                    </Form.Group>
                                }
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Buyer Order</Form.Label>
                                    <Form.Control type="text" autoComplete="off" name="buyerorderno" value={buyerorderno} onChange={(e) => setbuyerorderno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Invoice No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" onChange={(e) => setinvoiceno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Invoice Date</Form.Label>
                                    <Form.Control type="date" onChange={(e) => setinvoicedate(e.target.value)} />
                                </Form.Group>
                                {/* <Form.Group as={Col} md="8" controlId="formBasicName">
                                    <Form.Label className="label">Owner</Form.Label>
                                    <Form.Control type="text" />
                                </Form.Group> */}
                                {/* <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Type</Form.Label>
                                    <Form.Select value={type} onChange={(e) => settype(e.target.value)}>
                                        <option value="b2b">B2B</option>
                                        <option value="b2c">B2C</option>
                                    </Form.Select>
                                </Form.Group> */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Voucher</Form.Label>
                                    <Form.Control type="text" value={voucherseries} name="voucherseries" readOnly autoComplete="off" />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Quotation Status</Form.Label>
                                    <Form.Select value={status}>
                                        <option value='true'>Open</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Tax Type</Form.Label>
                                    <Form.Select value={taxtype} >
                                        <option>--Select--</option>
                                        <option value='withtax'>With Tax</option>
                                        <option value='withouttax'>Without Tax</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Image</Form.Label>
                                    <Form.Control type="file" onChange={(e) => setimg(e.target.files[0])} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">PDF</Form.Label>
                                    <Form.Control type="file" accept=".pdf" onChange={(e) => setpdf(e.target.files[0])} />
                                </Form.Group>

                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Billing Address</Accordion.Header>
                        <Accordion.Body>
                            {/* <Row className="mb-1">
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Customer Address</Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Customer Details</Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Address : -</Form.Label>
                                    <span>&nbsp;{customerAll.address}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Name : -</Form.Label>
                                    <span>&nbsp;{customerSubContactsdata == "" ? customerAll.cname : customerSubContactsdata.contactname}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">City : -</Form.Label>
                                    <span>&nbsp;{customerAll.city}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Phone No : -</Form.Label>
                                    <span>&nbsp;{customerSubContactsdata == "" ? customerAll.phonenumber : customerSubContactsdata.phoneno}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Country : -</Form.Label>
                                    <span>{"India"}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Email Id : -</Form.Label>
                                    <span> {customerSubContactsdata == "" ? customerAll.email : customerSubContactsdata.email}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">State : -</Form.Label>
                                    <span>{supplierstate}</span>
                                </Form.Group>
                            </Row> */}
                            <b><span className="">Delivery Address</span></b> <br></br> <br></br>
                            <Row className="mb-1">
                                {/* <select className="form-select dd">
                                <option className="extrvagnze">select Address</option>
                               
                                {
                                    disdel.map((item)=>{
                                        return<>
                                          <option className="extrvagnze">Address:{item.delivery_address} ,State:-{item.state} &nbsp;,Pin-code:-{item.pincode}</option>
                                        </>
                                    })
                                }
                               </select> */}
                                <select className="form-select" value={deliveryAddress} onChange={(e) => setdelvieryaddress(e.target.value)}>
                                    <option>select data</option>
                                    {
                                        getaddresss.map((item) => {
                                            return <>
                                                <option value={item.id}>{item.delivery_address}</option>
                                            </>
                                        })
                                    }
                                </select>

                            </Row>



                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>Details</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Terms of Delivery</Form.Label>
                                    <Form.Control type="text" autoComplete="off" name="termsofdelivery" value={termsofdelivery} onChange={(e) => settermsofdelivery(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Delivery Note No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" value={deliverynoteno} name="deliverynoteno" onChange={(e) => setdeliverynoteno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Destination</Form.Label>
                                    <Form.Control type="text" autoComplete="off" value={destination} name="destination" onChange={(e) => setdestination(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Packaging List No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" onChange={(e) => setpackaginglistno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">GST No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" value={gstno} name="gstno" onChange={(e) => setgstno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Pan No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" onChange={(e) => setpanno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Eway Bill No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" onChange={(e) => setewaybillno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">GRN No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" onChange={(e) => setgrnno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Dispatched Through</Form.Label>
                                    <Form.Control type="text" autoComplete="off" value={dispatchedthrough} name="dispatchedthrough" onChange={(e) => setdispatchedthrough(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Total No PKG </Form.Label>
                                    <Form.Control type="text" autoComplete="off" value={totalnopkg} name="totalnopkg" onChange={(e) => settotalnopkg(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">LRN No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" value={lrno} name="lrno" onChange={(e) => setlrno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Udyam No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" value={udyamno} name="udyamno" onChange={(e) => setudyamno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">MSME No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" value={msmeno} name="msmeno" onChange={(e) => setmsmeno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">LRN Date</Form.Label>
                                    <Form.Control type="date" onChange={(e) => setlrndate(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">EDD</Form.Label>
                                    <Form.Control type="date" onChange={(e) => setedd(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">GRN Date</Form.Label>
                                    <Form.Control type="date" onChange={(e) => setgrndate(e.target.value)} />
                                </Form.Group>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>GST Details</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <h1>Transport Details</h1>
                                {/* <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Tax Type</Form.Label>
                                    <Form.Control type="text" autoComplete="off" placeholder="Enter Tax Type" name="taxSch" onChange={(e) => settaxSch(e.target.value)} />
                                </Form.Group> */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Type of supply</Form.Label>
                                    <Select options={stype} className='sett' onChange={handlesuptype}/>
                                </Form.Group>
                                <h1>Document Details</h1>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Document No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" placeholder="Enter Document No" onChange={(e) => setno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Document Date</Form.Label>
                                    <Form.Control type="date" autoComplete="off" onChange={(e) => setdt1(e.target.value)} />
                                </Form.Group>
                                <h1>Seller Details</h1>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">GSTIN of Supplier </Form.Label>
                                    <Form.Control type="text" autoComplete="off" name="gstin" placeholder="Enter GSTIN of Supplier" onChange={(e) => handleChange(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Legal Name</Form.Label>
                                    <Form.Control type="text" autoComplete="off" name="lglNm" placeholder="Enter Legal Name" onChange={(e) => handleChange(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Address1</Form.Label>
                                    <Form.Control type="text" autoComplete="off" name="addr1" placeholder="Enter Address1" onChange={(e) => handleChange(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Location</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Location" autoComplete="off" name="loc" onChange={(e) => handleChange(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">PinCode</Form.Label>
                                    <Form.Control type="text" placeholder="Enter PinCode" name="pin" autoComplete="off" onChange={(e) => handleChange(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">State Code</Form.Label>
                                    <Form.Control type="text" placeholder="Enter State Code" autoComplete="off" name="stcd" onChange={(e) => handleChange(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Phone Number</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Phone Number" name="ph" autoComplete="off" onChange={(e) => handleChange(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Email Id</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Email Id" name="em" autoComplete="off" onChange={(e) => handleChange(e)} />
                                </Form.Group>
                                <h1>Buyer Details</h1>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">GSTIN of Buyer</Form.Label>
                                    <Form.Control type="text" placeholder="Enter GSTIN of Buyer" name="gstin" autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Legal Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Legal Name" name="lglNm" autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">State Code of Place of Supply</Form.Label>
                                    <Form.Control type="text" placeholder="Enter State Code of Place of Supply" name="pos" autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Address1</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Address1" name="addr1" autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Location</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Location" name="loc" autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">PinCode </Form.Label>
                                    <Form.Control type="text" placeholder="Enter PinCode" name="pin" autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">State Code</Form.Label>
                                    <Form.Control type="text" placeholder="Enter State Code" name="stcd" autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Phone No</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Phone No" name="ph" autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Email ID</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Email ID" name="em" autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group>
                                <h1>Eway Bill Details</h1>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Distance</Form.Label>
                                    <Form.Control type="text" onChange={(e) => setdistance(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Mode of Transport</Form.Label>
                                    <Select options={modes1} className='sett'onChange={(e) => settransMode(e.id)}  />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Transporter Id</Form.Label>
                                    <Form.Control type="text" onChange={(e) => settransId(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Transporter Name</Form.Label>
                                    <Form.Control type="text" onChange={(e) => settransName(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Transport Document Date</Form.Label>
                                    <Form.Control type="date" onChange={(e) => settransDocDt1(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Transport Document No</Form.Label>
                                    <Form.Control type="text" onChange={(e) => settransDocNo(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Vehicle No</Form.Label>
                                    <Form.Control type="text" onChange={(e) => setvehNo(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Vehicle Type</Form.Label>
                                    <Select options={vechiletype}   className='sett'onChange={(e) => setvehType(e.id)}  />
                                </Form.Group>

                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <br></br>
                <Row className="mb-1">
                    <Form.Group as={Col} md="12" controlId="formBasicName">
                        <Form.Label className="label">Product</Form.Label>
                        <Select options={productdata} onChange={(e) => addData(e)} onInputChange={(e) => customFilter(e)} />
                    </Form.Group>
                </Row>
                <br></br>
                <Table striped responsive hover size="sm">
                    <thead className='p-3 mb-2 bg-success text-white'>
                        <tr>
                            <th>PRODUCT&nbsp;NAME</th>
                            <th>UOM</th>
                            <th >QTY(PCS)</th>
                            <th >QTY(KG)</th>
                            <th >Rate</th>
                            <th >UOM</th>
                            <th >Trade&nbsp;Discount</th>
                            <th>Gross&nbsp;Amount&nbsp;(₹)</th>
                            <th >Scheme&nbsp;Discount&nbsp;%</th>
                            <th >NET&nbsp;AMOUNT&nbsp;(₹)</th>
                            <th >GST&nbsp;Value</th>
                            <th >Amount</th>
                            <th >Action</th>
                        </tr>
                    </thead>
                    <tbody style={{ overflow: "scroll" }}>
                        {salesItems1.map(item =>
                            <tr key={item.product.id}>
                                <td>
                                    <Form.Group as={Col} md="12" >
                                        {/* <Form.Control type="text" value={(item.productName ? item.productName : item.product.productName)} size="sm" /> */}
                                        <textarea type="text" className="form-control textcontrol" value={(item.productName ? item.productName : item.product.productName)} size="sm" ></textarea>

                                    </Form.Group>
                                    <Form.Control as="textarea" onChange={(e) => { dispatch(changesalestext(e.target.value, item)) }} value={item.text} rows={2} className='form-control' />
                                </td>
                                <td><Form.Group as={Col} md="12">
                                    <Form.Select disabled={item.unitofmeasurement == "box" ? true : item.productType !== "kgproduct" ? true : false} value={item.unitofmeasurement} onChange={(e) => { dispatch(changeunitofMeasurementsales(e.target.value, item, item.unitofmeasurement)); }} size="sm">
                                        <option>{item.unitofmeasurement == "box" ? "PCS" : item.productType !== "kgproduct" ? 'PCS' : "Select"}</option>
                                        <option value="pcs">PCS</option>
                                        <option value="kg">KG</option>
                                    </Form.Select>
                                </Form.Group>
                                </td>

                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" size="sm" onChange={(e) => { dispatch(changesalessqpbmeasurement(e.target.value, item, taxtype, item.unitofmeasurement)); roundData(e.target.value) }} value={item.salesquantity} disabled={item.unitofmeasurement == "kg" ? true : false||item.unitofmeasurement == "" ? true : false} />
                                    </Form.Group>
                                </td>
                                <td>
                                    {/* {'ediokml'} */}
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" onChange={(e) => { dispatch(changesalesqty(e.target.value, item, taxtype, item.unitofmeasurement)); roundData(e.target.value) }} value={Number(item.salesquantitykgs)} disabled={item.unitofmeasurement == "box"||item.unitofmeasurement == "pcs" ? true : false || item.unitofmeasurement == "" ? true : false} size="sm" />
                                    </Form.Group>
                                </td>

                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" size="sm" onChange={(e) => { dispatch(changecostsales(e.target.value, item, taxtype)); roundData(e.target.value) }} value={Number(item.dlp)} />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Select disabled={item.calunitofmeasurement == "box" ? true : item.productType !== "kgproduct" ? true : false} value={item.calunitofmeasurement} onChange={(e) => { dispatch(changesalescalmeasurement(e.target.value, item, item.calunitofmeasurement)); roundData(e.target.value) }} size="sm">
                                            <option>{item.calunitofmeasurement == "box" ? "PCS" : item.productType !== "kgproduct" ? 'PCS' : "Select"}</option>
                                            <option value="pcs">PCS</option>
                                            <option value="kg">KG</option>
                                        </Form.Select>
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" min="0" size="sm" value={Number(item.tradeDiscount)} />
                                    </Form.Group>
                                </td>
                                <td><Form.Group as={Col} md="12">
                                    <Form.Control type="number" min="0" value={Number(item.grossamount)} size="sm" readOnly requied />
                                </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" onChange={(e) => { dispatch(changesalesdiscount(e.target.value, item, taxtype)); roundData(e.target.value) }} value={Number(item.schemeDiscount)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td><Form.Group as={Col} md="12">
                                    <Form.Control type="number" min="0" value={Number(item.netAmount)} size="sm" readOnly requied />
                                </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" value={Number(item.gstvalue)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" value={Number(item.total)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type='button' className='btn btn-danger' onClick={() => { dispatch(removefromcartfromsales(item.product.id)); roundData('') }} value="X" size="sm" />
                                    </Form.Group>
                                </td>
                            </tr>

                        )}
                    </tbody>
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Packing&nbsp;&&nbsp;Forwarding</td>
                            <td><Form.Group as={Col} md="12">
                                <Form.Control type="number" value={shippingcharge} onChange={(e) => settingship(e.target.value)} style={{ height: 30 }} />
                            </Form.Group></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Net&nbsp;Amount</td>
                            <td> <Form.Group as={Col} md="12">
                                <Form.Control type="number" value={grossAmount ? ((grossAmount).toFixed(2)) : "0"} style={{ height: 30 }} readOnly />
                            </Form.Group></td>
                            <td></td>
                        </tr>

                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>IGST</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={igstcheck()} style={{ height: 30 }} requied />
                                </Form.Group>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>SGST</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={SGSTcheck()} style={{ height: 30 }} requied />
                                </Form.Group>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>CGST</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={cgstcheck()} style={{ height: 30 }} requied />
                                </Form.Group>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Round&nbsp;off&nbsp;Value</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={round} onChange={(e) => settinground(e.target.value)} style={{ height: 30 }} />
                                </Form.Group>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Grand&nbsp;Total</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={grandTotal ? ((grandTotal).toFixed(2)) : "0"} style={{ height: 30 }} readOnly />
                                </Form.Group>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </Table>
                <Button variant="primary" type="button" onClick={submitdata}>
                    Submit
                </Button>
            </Form>
        </MDBCard>
    )
}