import React, { useEffect, useState } from "react";
import ReatilerService from "../services/Reatiler";
import { RetailerMonthReportComponents } from "./RetailerMonthReportComponents";
function RetailerMonthReport() {
  const [retailer, setRetailer] = useState("");

  // useEffect(() => {
  //   ReatilerService.getReatilerTable().then((response) => {
  //     console.log(response.data)
  //     setRetailer(
  //       response.data.map((user) => ({ value: user.id, label: user.tradeName }))
  //     );
  //   });
  // }, []);

  let header = [
    { label: "Month", field: "month" },
    { label: "Order Qty kgs", field: "order_qty_kg" },
    { label: "Order Qty Kgs / Pcs", field: "order_qty_kg_pcs" }, 
    { label: "Rate", field: "rate" },
    { label: "Order Value", field: "order_value" },
    { label: "Order Qty Pcs / Set", field: "order_qty_pcs_set" },
    { label: "Rate", field: "rate_set" },
    { label: "Order Value", field: "order_value_set" },
     
    
    
  ];
  const sumofkeys = [{ first: "primaryorderqty" }];

  return (
    <RetailerMonthReportComponents
      head={header}
      title={"Retailer MonthReport"}
      axiostabledata={"ret/report/get"}
      Add={" "}
      Create={" "}
      Edit={""}
      View={""}
      retailerdata={retailer}
      Sumofkeys={sumofkeys}
    />
  );
}

export default RetailerMonthReport;
