import axios from "axios";
import { MDBCard } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../services/Api";
import authHeader from "../../services/auth-header";
import Swal from "sweetalert2";

export default function Update(){
    const {id} = useParams();
    const[title,settitle] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const applyFontSizeTransition = (elementId) => {
      const element = document.getElementById(elementId);
      element.style.transition = 'font-size 2s';
      element.style.fontSize = '30px';
      element.style.fontWeight = '600';
      // font-weight: 600;
      setTimeout(() => {
         
        document.getElementById("typer1").classList.add('promote');
        element.style.fontSize = '24px';
      }, 1000);
    };
    const navigate = useNavigate();
    let Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

    const getdata = () => {
        axios.get(BASE_URL + 'group1/getById/'+id,{
            headers:authHeader()
        }).then(res=>{
            settitle(res.data.title)
        })
    }
    useEffect(()=>{
        getdata()
    },[]);
    const submitdata = () => {
        setIsLoading(true); 
        axios.put(BASE_URL + 'group1/update/'+id,{title},{
            headers:authHeader()
        }).then(res=>{
            navigate('/Group1Table/1');
            if(res.status==201||res.status==200){
              // alert("ee")
              Toast.fire({
                icon: 'success',
                title: 'Successfully Updated'
              })
            }
        }).catch(error=>{
              
            if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `
                  <div>
                    <p id="issueText">Why do I have this issue?</p>
                    <ul>
                
                    <li id="typer1">You might have an internet issue</li>
                    </ul>
                  </div>
                `,
                didOpen: () => {
                  applyFontSizeTransition('issueText');
                }
              });
            }else{
              Swal.fire({
                  title: 'This Product is already Created',
                  text: "You won't be able to Add!",
                  icon: 'warning',
                })
          
            }
          })
          .finally(() => {
          setIsLoading(false); 
          });
    }
    return(
        <MDBCard className="company">
        <h1>Update Group1</h1>
        <Form className="product1">
            <Row className="mb-1">
                <Form.Group as={Col} md="6" controlId="formBasicName">
                    <Form.Label className="label">Title</Form.Label>
                    <Form.Control type="text" name="title" value={title} onChange={(e) => settitle(e.target.value)} placeholder="Enter Title" />
                </Form.Group>
                </Row>
                <Button variant="primary" style={{width:'auto'}} onClick={submitdata} disabled={isLoading}>
      {isLoading ? 'Loading...' : 'Submit'}
    </Button>
        </Form>
    </MDBCard>
    )
}