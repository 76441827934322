
/* Purchase Create */

import { ADD_TO_CART_WORKORDER } from "../constant";
import { REMOVE_TO_CART_WORKORDER } from "../constant";
import { CHANGE_MEASUREMENT_WORKORDER } from "../constant";
import { CHANGE_PER_WORKORDER } from "../constant";
import { CHANGE_COST_WORKORDER } from "../constant";
import { CHANGE_DISCOUNT_WORKORDER } from "../constant";
import { CHANGE_DISCOUNT1_WORKORDER } from "../constant";
import { CHANGE_TEXT_WORKORDER } from "../constant";
import { EMPTY_DATA_WORKORDER } from "../constant";
import { CHANGE_QTY_WORKORDER } from "../constant";




export const addtocartworkorder = (data,data2) => {

    //  console.warn("action called", data2);
    return {

        type : ADD_TO_CART_WORKORDER,
        data : data,
        data2 : data2
    }

}



export const removefromcartworkorder = (data) => {

    // console.warn("action REMOVE_TO_CART_WORKORDER called", data);
    return {

        type : REMOVE_TO_CART_WORKORDER,
        data : data
    }

}



export const changeunitofMeasurementworkorder = (data, item, data2) => {

    // console.warn("qty change called66", data);
    return {

        type : CHANGE_MEASUREMENT_WORKORDER,
        data : data,
        item : item,
        data2 : data2
    }

}

export const perworkorder = (data, item, data2) => {

    // console.warn("qty change called5", data2);
    return {

        type : CHANGE_PER_WORKORDER,
        data : data,
        item : item,
        data2 : data2
    }

}

export const changeqtyworkorder = (data, item, data2) => {

    // console.warn("qty change called66", data2);
    return {

        type : CHANGE_QTY_WORKORDER,
        data : data,
        item : item,
        data2 : data2
    }

}

export const changecostworkorder = (data, item, data2) => {

    // console.warn("cost change called", data);
    return {

        type : CHANGE_COST_WORKORDER,
        data : data,
        item : item,
        data2 : data2

    }

}


export const changediscountworkorder = (data, item, data2) => {

    // console.warn("discount change called", data);
    return {

        type : CHANGE_DISCOUNT_WORKORDER,
        data : data,
        item : item,
        data2 : data2

    }

}

export const changediscount1workorder = (data, item, data2) => {

    // console.warn("discount1 change called", data);
    return {

        type : CHANGE_DISCOUNT1_WORKORDER,
        data : data,
        item : item,
        data2 : data2

    }

}
export const changetextworkorder = (data, item) => {

    // console.warn("text change called", data);
    return {

        type : CHANGE_TEXT_WORKORDER,
        data : data,
        item : item
    }

}


export const emptydataworkorder = () => {

    return {

        type : EMPTY_DATA_WORKORDER,
    }

}