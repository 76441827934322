import axios from "axios";
import authHeader from "./auth-header";

const API_URL = "http://43.225.54.138:8080/scm/api/warehouse/";



const getwarehouse = () => {
  return axios.get(API_URL + "getAll", { headers: authHeader() });
};
const getwarehouseCreate = (warehouse) => {
  return axios.post(API_URL + "add", warehouse,{ headers: authHeader() });
};
const getWareEdit = ( id ) => {
  return axios.get(API_URL +"getById/"+id , { headers: authHeader() });
};

const getDelete = ( id ) => {
  return axios.delete(API_URL + "delete/"+id, { headers: authHeader() });
};
const WareHouseService = {
    getwarehouse,
    getWareEdit,
    getwarehouseCreate,
    getDelete

};

export default WareHouseService;