import React from 'react'
import { Mydatatable } from '../Product/Mydatatable'

const Transporttable = () => {
  let header = [
    { "label": "Id", "field": "id" },
    { "label": "Transporter Gst Number", "field": "transporter_Gstin" },
    { "label": "Transporter Name", "field": "transporter_Name" },

    { "label": "Created Date", "field": "createddate" },
    { "label": "Created Time", "field": "createdtime" },
    { "label": "Created By", "field": "createbyname" },
    
    { "label": "Action", "field": "action", "subaction1": <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" /><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /></svg> }
  ];

  const sumofkeys = [{ "first": 'id' }]


  // {id: 239, brandName: 'Neelam'}


  return (

    <Mydatatable
      head={header}
      title={"Transporter"}
      axiostabledata={'transporter'}
      Add={"Add"}
      // Edit={"/pedit"}
       Create={'/Transportcreate'}
      Sumofkeys={sumofkeys}
     />





  )
}

export default Transporttable