import { GET_DATA_PRIMARYORDER_UPDATE } from "../constant";
import { GET_PRODUCT_ADD_PRIMARYORDER_UPDATE } from "../constant";
import { REMOVE_TO_CART_PRIMARYORDER_UPDATE } from "../constant";
import { CHANGE_UOM_PRIMARYORDER_UPDATE } from "../constant";
import { CHANGE_QTYPCS_PRIMARYORDER_UPDATE } from "../constant";
import { CHANGE_QTYKG_PRIMARYORDER_UPDATE } from "../constant";
import { CHANGE_SQPBPCS_PRIMARYORDER_UPDATE } from "../constant";
import { CHANGE_COST_PRIMARYORDER_UPDATE } from "../constant";
import { CHANGE_DISCOUNT1_PRIMARYORDER_UPDATE } from "../constant";
import { CHANGE_TEXT_PRIMARYORDER_UPDATE } from "../constant";

export const getprimarydataupdate = (data) => {

    return {
        type: GET_DATA_PRIMARYORDER_UPDATE,
        data: data
    }
};

export const addprimaryorderproductupdate = (data, data2) => {

    return {
        type: GET_PRODUCT_ADD_PRIMARYORDER_UPDATE,
        data: data,
        data2: data2
    }
};

export const removefromcartprimaryorderupdate = (data) => {

    // console.warn("action REMOVE_TO_CART_WORKORDER called", data);
    return {

        type: REMOVE_TO_CART_PRIMARYORDER_UPDATE,
        data: data
    }

};

export const changeuomprimaryorderupdate = (data, item, data2) => {

    return {
        type: CHANGE_UOM_PRIMARYORDER_UPDATE,
        data: data,
        item: item,
        data2: data2
    }
};

export const changeqtypcsprimaryorderupdate = (data, item, data2) => {

    return {
        type: CHANGE_QTYPCS_PRIMARYORDER_UPDATE,
        data: data,
        item: item,
        data2: data2
    }
};

export const changeqtykgprimaryorderupdate = (data, item, data2) => {

    return {
        type: CHANGE_QTYKG_PRIMARYORDER_UPDATE,
        data: data,
        item: item,
        data2: data2
    }
};

export const changesqpbpcsprimaryorderupdate = (data, item) => {

    return {
        type: CHANGE_SQPBPCS_PRIMARYORDER_UPDATE,
        data: data,
        item: item,
    }
};

export const changecostprimaryorderupdate = (data, item, data2) => {

    return {
        type: CHANGE_COST_PRIMARYORDER_UPDATE,
        data: data,
        item: item,
        data2: data2
    }
};

export const changediscountprimaryorderupdate = (data, item) => {

    return {

        type: CHANGE_DISCOUNT1_PRIMARYORDER_UPDATE,
        data: data,
        item: item
    }
};

export const changetextprimaryorderupdate = (data, item) => {

    // console.warn("text change called", data);
    return {

        type: CHANGE_TEXT_PRIMARYORDER_UPDATE,
        data: data,
        item: item
    }

}