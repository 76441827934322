import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../services/Api";
import authHeader from "../services/auth-header";
import { MDBCard } from "mdbreact";
import { Button, Col, Form, Row } from "react-bootstrap";

export default function Contact(){
  
    const { id } = useParams();

    const[suppliername,setsuppliername] = useState('');
    const[ contactname,setcontactname ] = useState('');
    const[ email,setemail ] = useState('');
    const [ phoneno,setphoneno ] = useState('');
    const [ supplier, setsupplier ] = useState('');
    const navigate = useNavigate();

    const getsupplierdata = ( ) => {
        axios.get( BASE_URL + 'supplier/getById/' + id , {
            headers:authHeader()
        }).then(res=>{
            
       setsuppliername(res.data.suppliername);
       setsupplier({"id":res.data.id})
        })
    };

    useEffect(() => {
        getsupplierdata()
    },[])
    const SubmitData = ( ) => {
        
        axios.post( BASE_URL + 'suppliersubcontacts/' ,{supplier,contactname,email,phoneno},{
            headers:authHeader()
        }).then(res=>{
            // navigate('/Supplier/Table')
            navigate('/SupplierTable/1')    
          })
    }
    return(
        <MDBCard className="company">
        <h1>Add Contact</h1>
        <Form className="product1">
        <Row className="mb-1">
        <Form.Group as={Col} md="6" controlId="formBasicName">
        <Form.Label className="label">Supplier Name &nbsp;:</Form.Label>
        <span> &nbsp;{suppliername}</span>
              </Form.Group>
        <Form.Group as={Col} md="6" controlId="formBasicName">
              
              </Form.Group>
        <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label className="label">Contact Name:</Form.Label>
              <Form.Control type="text"  onChange={(e) => setcontactname(e.target.value)} placeholder='Enter Contact Name'/>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="formBasicName">
              
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label className="label">Email:</Form.Label>
              <Form.Control type="text" onChange={(e) => setemail(e.target.value)}  placeholder='Enter Email'/>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="formBasicName">
              
              </Form.Group>
            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label className="label">Phone Number:</Form.Label>
              <Form.Control type="number" onChange={(e) => setphoneno(e.target.value)}  placeholder='Enter Phone Number'/>
            </Form.Group>
            
        </Row>
        <Button variant="primary" onClick={SubmitData}>Submit</Button>
        </Form>
      </MDBCard>
    )
}