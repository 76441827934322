import axios from 'axios';
import React, { useEffect, useState } from 'react'
import authHeader from '../Component/services/auth-header';
import { numberToWords } from 'amount-to-words';
import { useParams } from 'react-router-dom';

const Salesreturn = () => {
  const datemaker=(checkdate)=>{
    const originalDateString =checkdate;
    const originalDate = new Date(originalDateString);
    
    // Options for formatting the date without time
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    
    // Format the date string
    const formattedDateString = originalDate.toLocaleDateString('en-US', options);
    return formattedDateString
    
  }
 const [stateid,setstateid]=useState([])
  const [isLoading, setIsLoading] = useState(true);
  const [state_code, setstate_code] = useState([]);
  const [sumofqty,setsumqty]=useState([])
  const [sumofkg,setsumkg]=useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState([]);
  const[sname,setsname]=useState([])
const [cdeatils, setcdeatils] = useState([])
const [data22, setData22] = useState([])
const[cus,setcus]=useState([])
const [company, setCompany] = useState([])
const[jsonData,setjsonData]=useState([])
 const [grandtotal, setgrd] = useState([])
 const [igst, setIgst] = useState([])
 const [sgst, setsgst] = useState([])
 const [cgst, setcgst] = useState([])
const [Round, setRound] = useState([])
const[rounding,setrvalue]=useState([])
const [state_name,setcodestate]=useState([])
// const[sname,setsname]=useState([])

  // console.log(jsonData.length);
  const[sumigst,setsumgst]=useState([])
  const [sumctax, setSumctax] = useState([])
  const[Amount,setamount]=useState([])
const [dow,setdow]=useState([])
const { id } = useParams();
  useEffect(() => {
    document.getElementById("qerteww").style.display = "none";
    
    document.getElementById("sidein").style.display = "none";



    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const updatedPageData = jsonData.slice(startIndex, endIndex);
    setCurrentPageData(updatedPageData);
  }, [currentPage]);

  let totalPages = Math.ceil(jsonData.length / 12);


  console.log(cdeatils.contactname);
  // console.log(jsonData);


  useEffect(() => {
    
    axios.get(`http://43.225.54.138:8080/scm/api/sr/${id}`, { headers: authHeader() })
    .then(response => {
       
  // console.log(`http://43.225.54.138:8080/scm/api/product/page/${Number(getid)}/${Number(limit)}/${sort}/${fieldnme}`);
      // console.log(response.data.customer.states);
      // if(response.data.customer.states.name){
      //   setstatname(response.data.customer.states.name)
      //   setstate_code(response.data.customer.states.state_code)
      // }

// if(response.data.customerSubContacts)
//    {   setcdeatils(response.data.customerSubContacts)}
if(response.data.distributor.stateid.length!==null){
axios.get(`http://43.225.54.138:8080/scm/api/state/${response.data.distributor.stateid}`, { headers: authHeader() })
.then(response => { 

  setsname(response.data.name)
  setstate_code(response.data.state_code)
console.log(response.data.name)

})
.catch(error => {
  console.error(error);
});}

setstateid(response.data.distributor.stateid)
 console.log(response.data.distributor.stateid)
      setData22(response.data)
      setcus(response.data.distributor)
      setCompany(response.data.company)
      setIgst(response.data.igst)
      setsgst(response.data.sgst)
      setcgst(response.data.cgst)
      setrvalue(response.data.roundingoff)
      setgrd(response.data.grandtotal)
const extractedData = response.data.salesReturnItems.map((item) => ({
  no: item.id,
  AllQtApprox: item.sraltquantity,
  Qty: item.srquantity,
  Dis: item.schemeDiscount,
  Per: item.product.productType,
  Amount: item.netAmount,
  ptotal:item.total,
  DescriptionofGoods:item.product.productName,
  EANCode:item.product.eanCode,
  HSNSAC:item.product.hsnCode,
  GStRate:item.product.igst,
  GStRatesg:item.product.sgst,
  GStRatecg:item.product.cgst,
  Rate:item.product.dlp,
  uomSecondary:item.product.uomSecondary,
typecheck:item.product.productType
}));
console.log(extractedData);
 
setjsonData(extractedData)
 
 
const notebook = extractedData.map((item) => ({
  HSNSAC: item.HSNSAC,
  gst: item.GStRate,
   cgst : item.GStRatecg,
   sgst :item.GStRatesg,
  amount: item.Amount, 
}));

 
console.log(notebook );
const groupedData = notebook.reduce((acc, curr) => {
  const existingItem = acc.find(item => item.HSNSAC === curr.HSNSAC);
  if (existingItem) {
  existingItem.amount += (curr.amount);
  } else {
    acc.push(curr);
  }
  return acc;
}, []);
console.log(groupedData);
const updatedData = groupedData.map(item => {
  const { amount, gst, sgst, cgst, ...rest } = item;

  const igstAmount = (amount * gst) / 100;
  const sgstAmount = (amount * sgst) / 100;
  const cgstAmount = (amount * cgst) / 100;

  return {
    ...rest,
    amount,
    gst,
    igstAmount,
    sgst,
    sgstAmount,
    cgst,
    cgstAmount
  };
});

const updatedDataWithSums = updatedData.map(item => {
  const { sgstAmount, cgstAmount, ...rest } = item;

  const totalGST = sgstAmount + cgstAmount;

  return {
    ...rest,
    sgstAmount,
    cgstAmount,
    totalGST
  };
});
const sumValues = updatedDataWithSums.reduce((accumulator, item) => {
  accumulator.totalGST += item.totalGST || 0;
  accumulator.igstAmount += item.igstAmount || 0;
  accumulator.amount += item.amount || 0;
  return accumulator;
}, { totalGST: 0, igstAmount: 0, amount: 0 });

setsumgst(sumValues.totalGST);
setSumctax(sumValues.igstAmount);
setamount( sumValues.amount);

setdow(updatedDataWithSums)
console.log(updatedData);
let sum1=response.data.salesReturnItems.filter((item)=>item.product.productType=="kgproduct").map((item)=>item.product.uomSecondary*item.srquantity)

const sum = sum1.reduce((acc, val) => acc + val, 0);
 
let roundedSum = sum.toFixed(2);

 setIsLoading(roundedSum);
//  roundvalue
 setRound(response.data.salesReturnItems.map((item)=>item.amount).reduce((acc, val) => acc + val, 0))
let qty=response.data.salesReturnItems.map((item)=>item.srquantity).reduce((acc, val) => acc + val, 0)
let qty2=response.data.salesReturnItems.map((item)=>item. sraltquantity).reduce((acc, val) => acc + val, 0)
setsumqty(qty)
setsumkg(qty2);
    })
    .catch(error => {
      console.error(error);
    });








    
    const elements = ["naving", "1sidebar", "qw", "swift", "logoo", "logoo1", "menu"];

    elements.forEach(elementId => {
      const element = document.getElementById(elementId);
      if (element) {
        element.classList.add('hidden');
      }
    });





    const newElement = document.getElementById("new");
    if (newElement) {
      newElement.classList.add('fullWidth');
    }
  }, [])
  let removedElements
  let itemsPerPage=12;
  const paragraphs = [];
  let i = 0;
  for (i; i < totalPages; i++) {
    let startIndex;
    let endIndex;
    let roman;
    let jsonDataSlice;
    // let remainingItems
    let lastPageDataSize


     
// console.log(totalPages);


      startIndex = i * itemsPerPage;
      endIndex = Math.min((i + 1) * itemsPerPage, jsonData.length);
      jsonDataSlice = jsonData.slice(startIndex, endIndex);
    
      
 
    if(totalPages===i+1){
      let lastPageIndex = totalPages - 1;
      let lastPageStartIndex = lastPageIndex * itemsPerPage;
      let lastPageEndIndex = Math.min((lastPageIndex + 1) * itemsPerPage, jsonData.length);
        lastPageDataSize = lastPageEndIndex - lastPageStartIndex;

  if (lastPageDataSize >4) {
    let newidea = lastPageDataSize-4;
    console.log(newidea);
      jsonDataSlice = jsonData.slice(startIndex, endIndex-newidea);
        removedElements = jsonData.slice(endIndex - newidea, endIndex);
      console.log(removedElements);
  }


  
  if (totalPages === 1 && i === 0 && lastPageDataSize > 4) {
    let newidea = lastPageDataSize - 4;  
  
    if (jsonData.length >= 5 && jsonData.length <= 12) {
      newidea = lastPageDataSize - (jsonData.length - 4);
    }
  
    console.log(newidea);
  
    jsonDataSlice = jsonData.slice(startIndex, endIndex - newidea);
    removedElements = jsonData.slice(endIndex - newidea, endIndex);
    console.log(removedElements);
  }
  

    } 


      // startIndex = i * itemsPerPage;
      // endIndex = Math.min((i + 1) * itemsPerPage, jsonData.length);
      // jsonDataSlice = jsonData.slice(startIndex, endIndex);




    

console.log(dow);



    paragraphs.push(<p className='jpmorgon' key={i}>
       {i===0? <p className='fontprint center '>Salesreturn</p>:""}
        <div className='border1'>
<div className='row border1'>
  <div className='col-7 border1'>
    <h1 className='para size9'><p><b> {company.cname}</b></p>{company.address}<br></br> Contact : {company.phone}<br></br>
E-Mail : {company.email}<br></br>
www.neelamappliances.com
GSTIN/UIN: {company.gst}<br></br>
State Name : Maharashtra, Code : 27
</h1>
  </div>
  <div className='col rty33  ' style={{border:"none"}}>
  <div className='row    borderhard'>
      <div className='col  borderight'>
      <b className='size9'>Credit Note No</b> 
      <font className="size9">{data22.creditnoteno}</font>

      </div>
      <div className='col '>
      <b className='size9'>Dated:</b> 
      <font className="size9">{data22.dated}</font>
      </div>
      
    </div>
    <div className='row    borderhard'>
      <div className='col  borderight'>
      

      </div>
      <div className='col '>
      <b className='size9'>Mode/Terms of Pyament:</b> 
      <font className="size9">{data22.paymentTerms}</font>
      </div>
      
    </div>
    <div className='row    borderhard'>
      <div className='col  borderight'>
      <b className='size9'>Original Invoice Dated:</b> 
      <font className="size9">{datemaker(data22.invoicedate)} </font>

      </div>
      <div className='col '>
      <b className='size9'>Other Reference:</b> 
      <font className="size9">{data22.otherrefernce}</font>
      </div>
      
    </div>
    <div className='row    borderhard'>
      <div className='col  borderight'>
      <b className='size9'>Buyer's Order No.</b> 
      <font className="size9">{data22.buyerorderno}</font>

      </div>
      <div className='col '>
      <b className='size9'>Dated:</b> 
      <font className="size9">{data22.dated}</font>
      </div>
      
    </div>
    <div className='row    borderhard'>
      <div className='col  borderight'>
      <b className='size9'>Dispatch DOc NO.</b> 
      <font className="size9">{data22.dispatcheddocno}</font>

      </div>
      <div className='col '>
      <b className='size9'> </b> 
      <font className="size9"> </font>
      </div>
      
    </div>
    <div className='row    borderhard'>
      <div className='col  borderight'>
      <b className='size9'>Dispatch through</b> 
      <font className="size9">{data22.dispatchedthrough}</font>

      </div>
      <div className='col '>
      <b className='size9'>Destination:</b> 
      <font className="size9">{data22.destination}</font>
      </div>
      
    </div>
    <div className='row    '>
      <div className='col  borderight'>
      <b className='size9'>Terms Of delivery:</b> 
      <font className="size9">{data22.termsofdelivery}</font>

      </div>
      
      
    </div>
   
   
  </div>
  <div className='col-7 border1'>
    
<h1 className='para size9'><p><b><b>Consignee (Ship to)</b><br></br> {company.cname}</b></p>{company.address}<br></br> Contact : {company.phone}<br></br>
E-Mail : {company.email}<br></br>
www.neelamappliances.com
GSTIN/UIN: {company.gst}<br></br>
State Name : Maharashtra, Code : 27
</h1>
  </div>
  <div className='col'>
    {/* <h1>hry</h1> */}
  </div>
  <div className='col-7 border1'>
    <h1 className='para'> 
    <b className='size9'>Buyer (Bill to)</b><br></br>
    <font className="size9">
{cus.tradeName}, {cus.cities}<br></br>
{cus.billingAddress} <br></br>
<font  className="size9">GSTIN/UIN :{cus.gstNumber}</font><br></br>
<font  className="size9">PAN/IT No  :{cus.panNumber}</font><br></br>
<font  className="size9">State Name :{sname}, Code :{state_code}</font><br></br>
{/* <font className="size9">Contact person :{cdeatils.contactname} </font> */}
<font  className="size9">Mob No {cus.mobNo1}</font><br></br>
<font  className="size9">E-Mail :{cus.perEmail}</font>
</font>
</h1>
  </div>
  <div className='col'>
 
  </div>
</div>
     
      <table id='rowcount' className='  bordered'>

        <tr className='thback'>
          <th className='thback' width="3%">Sr No.</th>
          <th className='thback' width="auto">Description of Goods <span className='white'>Goodsssssssssssssssssss</span></th>
          <th className='thback' width="3%">HSN/SAC</th>
          <th className='thback' width="3%">GST Rate</th>
          <th className='thback' width="3%">  Due on</th>
          <th className='thback' width="3%"> Alt.Quantity</th>
          <th className='thback' width="3%">Quantity</th>
          <th className='thback' width="3%">Rate</th>
          <th className='thback' width="3%">Per</th>
          <th className='thback' width="3%">Dis.%</th>
          <th className='thback' width="3%">Amount</th>
        </tr>
     
   {
   
   }
        
    <tbody className='height'>
          {
            jsonDataSlice.map((item, index) => {
              return <tr>
                <td  className='thback1'>{startIndex + index + 1}</td>
                <td  className='thback1 rtrtetr  sizeropt' style={{textAlign:'left'}}><frf>{item.DescriptionofGoods}</frf><br></br><ntn>
                Batch : {item.EANCode}<br></br> CustArt Code : {item.EANCode}<br></br> EAN Code : {item.EANCode}</ntn></td>
                <td  className='thback1 sizeropt'>{item.HSNSAC}</td>
                <td  className='thback1 sizeropt'>{item.GStRate}</td>
                <td  className='thback1 sizeropt'>11-9-2044</td>
             <td className='thback1'>{item.AllQtApprox } </td>                
             <td className='thback1'>{ item.Qty } </td>  
                <td  className='thback1 sizeropt'>{item.Rate}</td>
                {item.typecheck=="kgproduct" ?<td  className='thback1 sizeropt'>kg</td>:<td  className='thback1 sizeropt'>Pcs</td>}
          
                <td  className='thback1 sizeropt'>{item.Dis}</td>
                
                <td  className='thback1 sizeropt'>{item.ptotal}</td>
              </tr>
            })

          }

 





        </tbody> 
        
       
      </table>




{totalPages===i+1&&lastPageDataSize>4?<p className='jpmorgon' key={i}></p>:""}

{(()=>{
if(totalPages===i+1&&lastPageDataSize>4){
return (
<div className='border1'>
  <div className='row border1'>
  <div className='col-9 border1'>
  <h1 className='para size9'><p><b> {company.cname}</b></p>{company.address}<br></br> Contact : {company.phone}<br></br>
E-Mail : {company.email}<br></br>
www.neelamappliances.com
GSTIN/UIN: {company.gst}<br></br>
State Name : Maharashtra, Code : 27
</h1>
  </div>
  <div className='col rty33  ' style={{border:"none"}}>
  <div className='row    borderhard'>
      <div className='col  borderight'>
      <b className='size9'>Credit Note No</b> 
      <font className="size9">{data22.creditnoteno}</font>

      </div>
      <div className='col '>
      <b className='size9'>Dated:</b> 
      <font className="size9">{data22.dated}</font>
      </div>
      
    </div>
    <div className='row    borderhard'>
      <div className='col  borderight'>
      

      </div>
      <div className='col '>
      <b className='size9'>Mode/Terms of Pyament:</b> 
      <font className="size9">{data22.paymentTerms}</font>
      </div>
      
    </div>
    <div className='row    borderhard'>
      <div className='col  borderight'>
      <b className='size9'>Original Invoice Dated:</b> 
      <font className="size9">{datemaker(data22.invoicedate)} </font>

      </div>
      <div className='col '>
      <b className='size9'>Other Reference:</b> 
      <font className="size9">{data22.otherrefernce}</font>
      </div>
      
    </div>
    <div className='row    borderhard'>
      <div className='col  borderight'>
      <b className='size9'>Buyer's Order No.</b> 
      <font className="size9">{data22.buyerorderno}</font>

      </div>
      <div className='col '>
      <b className='size9'>Dated:</b> 
      <font className="size9">{data22.dated}</font>
      </div>
      
    </div>
    <div className='row    borderhard'>
      <div className='col  borderight'>
      <b className='size9'>Dispatch DOc NO.</b> 
      <font className="size9">{data22.dispatcheddocno}</font>

      </div>
      <div className='col '>
      <b className='size9'> </b> 
      <font className="size9"> </font>
      </div>
      
    </div>
    <div className='row    borderhard'>
      <div className='col  borderight'>
      <b className='size9'>Dispatch through</b> 
      <font className="size9">{data22.dispatchedthrough}</font>

      </div>
      <div className='col '>
      <b className='size9'>Destination:</b> 
      <font className="size9">{data22.destination}</font>
      </div>
      
    </div>
    <div className='row    '>
      <div className='col  borderight'>
      <b className='size9'>Terms Of delivery:</b> 
      <font className="size9">{data22.termsofdelivery}</font>

      </div>
      
      
    </div>
   
   
  </div>
  <div className='col-9 border1'>
  <h1 className='para size9'><p><b><b>Consignee (Ship to)</b><br></br> {company.cname}</b></p>{company.address}<br></br> Contact : {company.phone}<br></br>
E-Mail : {company.email}<br></br>
www.neelamappliances.com
GSTIN/UIN: {company.gst}<br></br>
State Name : Maharashtra, Code : 27
</h1>
  </div>
  <div className='col'>
    {/* <h1>hry</h1> */}
  </div>
  <div className='col-9 border1'>
  <h1 className='para'> 
    <b className='size9'>Buyer (Bill to)</b><br></br>
    <font className="size9">
{cus.cname}, {cus.cities}<br></br>
{cus.address} <br></br>
<font  className="size9">GSTIN/UIN :{cus.gstno}</font><br></br>
<font  className="size9">PAN/IT No  :{cus.pan}</font><br></br>
<font  className="size9">State Name :{sname}, Code :{state_code}</font><br></br>
<font className="size9">Contact person :{cdeatils.contactname} </font>
<font  className="size9">Mob No {cdeatils.phoneno}</font><br></br>
<font  className="size9">E-Mail :{cus.email}</font>
</font>
</h1>
  </div>
  <div className='col'>
 
  </div>
  <table id='rowcount' className='  bordered'>
  
  <tr className='thback'>
    <th className='thback ' width="1px">Sr No.</th>
    <th className='thback' width="1px">Description of Goods <span className='white'>Goodsssssssssssssssssss</span></th>
    <th className='thback' width="1px">HSN/SAC</th>
    <th className='thback' width="1px">GST Rate</th>
    <th className='thback' width="1px">  Due on</th>
    <th className='thback' width="1px"> Alt.Quantity</th>
    <th className='thback' width="3%">Quantity</th>
          <th className='thback' width="3%">Rate</th>
          <th className='thback' width="3%">Per</th>
          <th className='thback' width="3%">Dis.%</th>
    
    <th className='thback' width="1px">Amount</th>
  </tr>
 
{

}
    
<tbody className='height'>
      {
        removedElements.map((item, index) => {
          return <tr>
           <td  className='thback1'>{startIndex + index + 1}</td>
                <td  className='thback1 rtrtetr  sizeropt' style={{textAlign:'left'}}><frf>{item.DescriptionofGoods}</frf><br></br><ntn>
                Batch : {item.EANCode}<br></br> CustArt Code : {item.EANCode}<br></br> EAN Code : {item.EANCode}</ntn></td>
                <td  className='thback1 sizeropt'>{item.HSNSAC}</td>
                <td  className='thback1 sizeropt'>{item.GStRate}</td>
                <td  className='thback1 sizeropt'>11-9-2044</td>
                <td className='thback1'>{item.AllQtApprox } </td>                
             <td className='thback1'>{ item.Qty } </td>  
                <td  className='thback1 sizeropt'>{item.Rate}</td>
                {item.typecheck=="kgproduct" ?<td  className='thback1 sizeropt'>kg</td>:<td  className='thback1 sizeropt'>Pcs</td>}

                <td  className='thback1 sizeropt'>{item.Dis}</td>
                
                <td  className='thback1 sizeropt'>{item.ptotal}</td>
          </tr>
        })

      }







    </tbody> 
    
   
  </table>
</div></div>)
}

if(totalPages===1&&i==0&&lastPageDataSize>4){
  return (
   <>
   <p className='jpmorgon'></p>
   
  <div className='row border1'>
  <div className='col-9 border1'>
  <h1 className='para size9'><p><b> {company.cname}</b></p>{company.address}<br></br> Contact : {company.phone}<br></br>
E-Mail : {company.email}<br></br>
www.neelamappliances.com
GSTIN/UIN: {company.gst}<br></br>
State Name : Maharashtra, Code : 27
</h1>
  </div>
  <div className='col rty33  ' style={{border:"none"}}>
  <div className='row    borderhard'>
      <div className='col  borderight'>
      <b className='size9'>Credit Note No</b> 
      <font className="size9">{data22.creditnoteno}</font>

      </div>
      <div className='col '>
      <b className='size9'>Dated:</b> 
      <font className="size9">{data22.dated}</font>
      </div>
      
    </div>
    <div className='row    borderhard'>
      <div className='col  borderight'>
      

      </div>
      <div className='col '>
      <b className='size9'>Mode/Terms of Pyament:</b> 
      <font className="size9">{data22.paymentTerms}</font>
      </div>
      
    </div>
    <div className='row    borderhard'>
      <div className='col  borderight'>
      <b className='size9'>Original Invoice Dated:</b> 
      <font className="size9">{datemaker(data22.invoicedate)} </font>

      </div>
      <div className='col '>
      <b className='size9'>Other Reference:</b> 
      <font className="size9">{data22.otherrefernce}</font>
      </div>
      
    </div>
    <div className='row    borderhard'>
      <div className='col  borderight'>
      <b className='size9'>Buyer's Order No.</b> 
      <font className="size9">{data22.buyerorderno}</font>

      </div>
      <div className='col '>
      <b className='size9'>Dated:</b> 
      <font className="size9">{data22.dated}</font>
      </div>
      
    </div>
    <div className='row    borderhard'>
      <div className='col  borderight'>
      <b className='size9'>Dispatch DOc NO.</b> 
      <font className="size9">{data22.dispatcheddocno}</font>

      </div>
      <div className='col '>
      <b className='size9'> </b> 
      <font className="size9"> </font>
      </div>
      
    </div>
    <div className='row    borderhard'>
      <div className='col  borderight'>
      <b className='size9'>Dispatch through</b> 
      <font className="size9">{data22.dispatchedthrough}</font>

      </div>
      <div className='col '>
      <b className='size9'>Destination:</b> 
      <font className="size9">{data22.destination}</font>
      </div>
      
    </div>
    <div className='row    '>
      <div className='col  borderight'>
      <b className='size9'>Terms Of delivery:</b> 
      <font className="size9">{data22.termsofdelivery}</font>

      </div>
      
      
    </div>
   
   
  </div>
  <div className='col-9 border1'>
  <h1 className='para size9'><p><b><b>Consignee (Ship to)</b><br></br> {company.cname}</b></p>{company.address}<br></br> Contact : {company.phone}<br></br>
E-Mail : {company.email}<br></br>
www.neelamappliances.com
GSTIN/UIN: {company.gst}<br></br>
State Name : Maharashtra, Code : 27
</h1>
  </div>
  <div className='col'>
    {/* <h1>hry</h1> */}
  </div>
  <div className='col-9 border1'>
  <h1 className='para'> 
    <b className='size9'>Buyer (Bill to)</b><br></br>
    <font className="size9">
{cus.cname}, {cus.cities}<br></br>
{cus.address} <br></br>
<font  className="size9">GSTIN/UIN :{cus.gstno}</font><br></br>
<font  className="size9">PAN/IT No  :{cus.pan}</font><br></br>
<font  className="size9">State Name :{sname}, Code :{state_code}</font><br></br>
<font className="size9">Contact person :{cdeatils.contactname} </font>
<font  className="size9">Mob No {cdeatils.phoneno}</font><br></br>
<font  className="size9">E-Mail :{cus.email}</font>
</font>
</h1>
  </div>
  <div className='col'>
 
  </div>
</div>
        <table id='rowcount' className='  bordered'>
  
        <tr className='thback'>
          <th className='thback' width="3%">Sr No.</th>
          <th className='thback sizeropt' width="auto">Description of Goods <span className='white'>Goodssssssssssssssssssssssssssssssssssssssssssssss</span></th>
          <th className='thback sizeropt' width="3%">HSN/SAC</th>
          <th className='thback sizeropt' width="3%">GST Rate</th>
          <th className='thback sizeropt' width="3%">  Due on</th>
          <th className='thback sizeropt' width="3%"> Alt.Quantity</th>
          <th className='thback' width="3%">Quantity</th>
          <th className='thback' width="3%">Rate</th>
          <th className='thback' width="3%">Per</th>
          <th className='thback' width="3%">Dis.%</th>
          
          <th className='thback' width="3%">Amount</th>
        </tr>
       
     {
     
     }
          
      <tbody className='height'>
            {
              removedElements.map((item, index) => {
                return <tr>
              <td  className='thback1'>{startIndex + index + 1}</td>
                <td  className='thback1 rtrtetr  sizeropt' style={{textAlign:'left'}}><frf>{item.DescriptionofGoods}</frf><br></br><ntn>
                Batch : {item.EANCode}<br></br> CustArt Code : {item.EANCode}<br></br> EAN Code : {item.EANCode}</ntn></td>
                <td  className='thback1 sizeropt'>{item.HSNSAC}</td>
                <td  className='thback1 sizeropt'>{item.GStRate}</td>
                <td  className='thback1 sizeropt'>11-9-2044</td>
                <td className='thback1'>{item.AllQtApprox } </td>                
             <td className='thback1'>{ item.Qty } </td>  
                <td  className='thback1 sizeropt'>{item.Rate}</td>
               {item.typecheck=="kgproduct" ? <td  className='thback1 sizeropt'>kg</td>:<td  className='thback1 sizeropt'>Pcs</td>}
                <td  className='thback1 sizeropt'>{item.Dis}</td>
                
                <td  className='thback1 sizeropt'>{item.ptotal}</td>
                  </tr>
              })
  
            }
  
   
  
  
  
  
  
          </tbody> 
          
         
        </table>
   
 
   </>
  )
  }



 })()}


 {(()=>{
if(totalPages===i+1){
return (
  <>


  
  <table id='rowcount' className='   '>
   {/* <tr className='rede size9'> */}
   <tr className='thback'>
          <th className='thback white' width="3%">Sr No.</th>
          <th className='thback white' width="auto">Description of Goods <span className='white'>Goodsssssssssssssssssss</span></th>
          <th className='thback white' width="3%">HSN/SAC</th>
          <th className='thback white' width="3%">GST Rate</th>
          <th className='thback white' width="3%">  Due on</th>
          <th className='thback white' width="3%"> Alt.Quantity</th>
          <th className='thback white' width="3%">Quantity</th>
          <th className='thback white' width="3%">Rate</th>
          <th className='thback white' width="3%">Per</th>
          <th className='thback white' width="3%">Dis.%</th>
          <th className='thback white' width="3%">Amount</th>
        </tr>

        <tbody className='height'>

    { igst!==0?   <tr>    <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt right'>IGST INPUT</td>
                  <td  className='thback1 sizeropt'> </td> <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt'>  </td>
                  <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt'>{igst}</td>
                  </tr>:""}


       { igst==0? <tr>    <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt right'>SGST INPUT</td>
                  <td  className='thback1 sizeropt'> </td> <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt'>  </td>
                  <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt'> {sgst}</td>
                  </tr>:""}
                 {  igst==0?<tr>    <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt right'>CGST INPUT</td>
                  <td  className='thback1 sizeropt'> </td> <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt'>  </td>
                  <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt'>{cgst}</td>
                  </tr>:""}
                  <tr>    <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt right'>Rounding Off</td>
                  <td  className='thback1 sizeropt'> </td> <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt'>  </td>
                  <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt'>{rounding}</td>
                  </tr>
                  <tr>    <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt ogleft'>Bill Details:New Ref{data22.otherrefernce} 90Days 3,18,89.00 Cr<br></br></td>
                  <td  className='thback1 sizeropt'> </td> <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt'>  </td>
                  <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt'> </td>
                  </tr>
                  
          <tr className='thback  bordert'>
            <td  className='thback1 sizeropt'> </td>
            <td  className='thback1 sizeropt'>Total</td>
            
            <td  className='thback1 sizeropt'>  </td>
            <td  className='thback1 sizeropt'>  </td>
            <td  className='thback1 sizeropt'>   </td>
            <td  className='thback1 sizeropt'> {sumofkg} </td>
            <td  className='thback1 sizeropt'>{sumofqty} </td>
            <td  className='thback1 sizeropt'></td>
            <td  className='thback1 sizeropt'> </td>
            <td  className='thback1 sizeropt'> </td>
            <td  className='thback1 sizeropt'>{grandtotal} </td>
          </tr>
        </tbody>
      </table>
      <div className='row     bordert'>
        <div className='col'>
          <div className='sizeropt fontprint'>  Amount Chargeable (In Words): <br></br>
          {numberToWords((grandtotal).toFixed(2))} </div>
        </div> 
       
        <div className='col-1 right'>
          <div className='size9  fontprint'>E.& O.E</div>
        </div> 
        <div className='border1 row'>
    <div className='col-5 center '>
    <font>HSN/SAC </font>  
    </div>
    <div className='col-1 borderl'>
    <font>Tax Value </font>  
    </div>
    {igst!== 0 ? (
  <>
    <div className='col-1 borderl'>
      <font>IGST%</font>  
    </div>
    <div className='col-1 borderl'>
      <font>IGST Amount</font>  
    </div>
  </>
) : null}

   {igst=== 0 ?<><div className='col-1 borderl'>
    <font>CGST%</font>  
    </div>
    <div className='col-1 borderl'>
    <font>CGST Amount</font>  
    </div>
    <div className='col-1 borderl'>
    <font>SGST%</font>  
    </div>
    <div className='col-1 borderl'>
    <font>SGST Amount</font>  
    </div></>:""}
    <div className='col-1 borderl right2'>
    <font>Total<span className='smallingfont'>(Taxamount)</span></font>  
    </div>
</div>

{dow.map((item)=>{
  return    <>
         <div className='border1 row'>
    <div className='col-5 center '>
    <font>{item.HSNSAC}</font>  
    </div>
    <div className='col-1 borderl'>
    <font>{item.amount} </font>  
    </div>
    {item.gst!== 0 ? (
  <>
    <div className='col-1 borderl'>
      <font>{item.gst}%</font>  
    </div>
    <div className='col-1 borderl'>
      <font>{item.igstAmount}</font>  
    </div>
    <div className='col-1 borderl right2'>
    <font>{item.amount*item.gst/100}</font>  
    </div>
  </>
) : null}

   {igst === 0 ?<><div className='col-1 borderl'>
    <font>{item.cgst}%</font>  
    </div>
    <div className='col-1 borderl'>
    <font>{item.cgstAmount}</font>  
    </div>
    <div className='col-1 borderl'>
    <font>{item.sgst}%</font>  
    </div>
    <div className='col-1 borderl'>
    <font>{item.sgstAmount}</font>  
    </div>
    <div className='col-1 borderl right2'>
    <font>{item.totalGST}</font>  
    </div>
    </>:""}
   
</div>
  
 
  
  </>
})}


<div className='border1 row'>
    <div className='col-5 center '>
    <font>Total</font>  
    </div>
    <div className='col-1 borderl'>
    <font> {Amount} </font>  
    </div>
    {igst!== 0 ? (
  <>
    <div className='col-1 borderl'>
      <font></font>  
    </div>
    <div className='col-1 borderl'>
      <font>{sumigst}</font>  
    </div>
  </>
) : null}

   {igst == 0 ?<><div className='col-1 borderl'>
    <font> </font>  
    </div>
    <div className='col-1 borderl'>
    <font> {sumctax/2}  </font>  
    </div>
    <div className='col-1 borderl'>
    <font> </font>  
    </div>
    <div className='col-1 borderl'>
    <font>{sumctax/2}</font>  
    </div></>:""}
    {igst!== 0 ?<>  <div className='col-1 borderl right2'>
    <font>{sumigst}<span className='smallingfont'> </span></font>  
    </div></>:""}
    {igst== 0 ?<>  <div className='col-1 borderl right2'>
    <font>{sumctax}<span className='smallingfont'> </span></font>  
    </div></>:""}
</div>
{/* <div className='border1 row'>
    <div className='col-5 right '>
    <font>Total</font>  
    </div>
    <div className='col-1 borderl'>
    <font>1,38,178 </font>  
    </div>
    
    <div className='col-1 borderl right2'>
    <font>16,581.36</font>  
    </div>  <div className='col-1 borderl right2'>
    <font>16,581.36</font>  
    </div>
   
    <div className='col-1 borderl right2'>
    <font>16,581.36</font>  
    </div>
</div> */}
<div className='col-6'>
          <div className='sizeropt fontprint'>  Amount Chargeable (In Words): <br></br>
            {numberToWords((grandtotal).toFixed(2))}</div>
        </div>          
        <div className='col-6'>
          <div className='sizeropt fontprint'>   <br></br>
          </div>
        </div>     <div className='col-6'>
          <div className='sizeropt fontprint'>   <br></br>
          </div>
        </div>          
        <div className='col-6'>
        <div className='sizeropt fontprint'>    
        Company's:Bank Details
        Bank Name:HDFC BANK LTD
        A/c No.:0045984507698
        Branch & IFS COde :EVERSHINECITY,VASAI,EAST & HDFC0000038
          </div>
        </div> 
      
       <div className='orgindisplay'>
         <h1 className='gstin'> 
Declaration:<br></br>
 We Declare that this invoice shows the actual price of the goods described and that all particukars are treu and correct
         </h1>
         <h1 className='ertrt'>
<p className='fontsize12 size911'>
for NEELAM APPLIANCES LIMITED
</p>
<p className='right fontsize12 size911'>Authorised Signatory</p>

         </h1>
       </div>
       
      </div>
      
 </>
)
}
 })()}

    </div></p>);


  }


  return (

    <div className='  '>
      <div>{paragraphs}</div>


     

    </div>
  )
}

export default Salesreturn