import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import authHeader from "../services/auth-header";
import AuthService from "../services/auth.service";
import New2 from "./New2";


export default function New() {
  const [brandName, setbrandName] = useState([]);
  const [error, setError] = useState(encodeURIComponent(' '));
  const [number, setnumber] = useState([])
  const [total, setotal] = useState([])
  const [size, setsize] = useState([])
  const [getid, setgetid] = useState(0)
  const[limit,setlimit]=useState(10)
const[sort,setsort]=useState("asc")
const[fieldnme,setfeilds]=useState("id")

const navigate = useNavigate();
const [responseState, setResponseState] = useState(false);
const { pointindex } = useParams();
  let data
  useEffect(() => {
    const user = AuthService.getCurrentUser();

    const headers = {
      Authorization: 'Bearer your_token_here',
    };

const startTime = new Date().getTime(); 
 axios.get(`http://43.225.54.138:8080/scm/api/product/page/${Number(getid)}/${Number(limit)}/${sort}/${fieldnme}/${error}`, { headers: authHeader() })
  .then(response => {
    const endTime = new Date().getTime();  
    const elapsedTime = endTime - startTime;  
    console.log(`Request took ${elapsedTime} milliseconds`);

    console.log(response.data.Enteries); //


    if (response.status >= 200 && response.status < 300) {
       if (response.data ) {
        setResponseState(true);
        console.log(true)
      } else {
        setResponseState(false);
        console.log(false)

      }
    }



if(response.data.products==undefined){
  setsize(0);
    setnumber(0);
    setbrandName(response.data.Searchproducts);






}
else{
  setsize(response.data.Enteries);
  setnumber(response.data.Pages);
  setbrandName(response.data.products);
}
})
  .catch(error => {
    console.error(error);
  });

 
}, [getid,limit,fieldnme,sort,error]);




   let header = [
    { "label": "Id", "field": "id" },
    { "label": "Product Name", "field": "productName" },
    { "label": "Standard Qty Per Box", "field": "standardQtyPerBox" },
    { "label": "Group", "field": "productType" },
    { "label": "Ean Code", "field": "eanCode" },
    { "label": "MRP(₹)", "field": "mrp" },
    { "label": "Action", "field": "action","subaction1":<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"/><circle cx="12" cy="12" r="3"/></svg> }
  ];

  //    let brandName = [
  //    ];


const sotreddata=(value)=>{
  setsort(value.sort);
  setfeilds(value.field);
console.log(value.field);
}


const searching=(search)=>{
  console.log(search.length);
   if(search.length==0){
    console.log(search)
    setError(encodeURIComponent(' '))
  }else{console.log(search)
    setError(search)

 
}
}
const newentry=(entry)=>{
  console.log(entry);
  setlimit(entry)
}

const handlinks=(link)=>{
console.log(link.map((item)=>item.view.view));
// if(link.map((item)=>item.view)){
//   console.log("view");
// }else{
//   console.log("edit");
// }
const id1 = link.map((item) => item.view.view);
console.log(id1);

const id = Number(id1[0]);
console.log(id);
 
  navigate(`/pview/1`);
 


}





  const pagination = (msg) => {
    console.log(msg);

    setgetid(msg)
  }
 

const counting=[{"value":10},{"value":20},{"value":30},{"value":50}]
  return (
    <>
    <h1 id="status"></h1>
      {/* <h1>{getid}</h1> */}
      <New2 name={"Products"}
        addbutton="Add product"
        Export="product"
        tdata={brandName}
        head={header}
        sotreddata={sotreddata}
        handlinks={handlinks}
        count={counting}
        // searching={searching}
        newentry={newentry}
        pagination={pagination}
        numberofpages={number}
        totalsize={size}
        // Export="product"
        View='pview'
        Addpage="/pcreate"
        Edit='pedit'
        class="productcardfix"
        totalelemnts={total} />
    </>
  )
}