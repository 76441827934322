import axios from 'axios';
import React, { useEffect, useState } from 'react'
import authHeader from '../../services/auth-header';
import Select from "react-select";
import { Link, useNavigate, useParams } from 'react-router-dom';
import UserService from '../../services/user.service';
import Swal from 'sweetalert2';
import { Button } from 'react-bootstrap';
import AuthService from '../../services/auth.service';

const Openingedit = () => {
    const [show, setShow] = useState(false);
    const [error, setError] = useState(encodeURIComponent(' '))
    const [disword, setdisword] = useState(encodeURIComponent(' '))
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const[posts,setPosts]=useState([]);
    const[datacsv,setcsv]=useState([])
    const[plus,setplus]=useState([])
    const [product2, setproduct2] = useState([ ])
    const [distributor1,setdistributordata]=useState([])
const [edis, setedisd] = useState([])
const [edist, setedist] = useState([])
const [user_date, setuser_date] = useState([])
const [distributor, setdistributor] = useState([])
const [product, setproduct] = useState([])
const [pdi, setProductid] = useState([])
const [pdl, setProductlabel] = useState([])
const [quantity, setquantity] = useState([])

const {id}=useParams();
const {pointindex}=useParams();
const navigate = useNavigate();
console.log(id)
const [datamany, setDatamany] = useState([])
    useEffect(()=>{ 
        if(datamany.length==0){
        axios.get(`http://43.225.54.138:8080/scm/api/distributor/openingstock/get/${id}`, { headers: authHeader() })
        .then(response => {
        //   const endTime = new Date().getTime();  
        //   const elapsedTime = endTime - startTime;  
          console.log(response.data);
          setquantity(response.data.quantity)
        setDatamany(response.data)
        setedisd(response.data.distributor.tradeName)
        setedist(response.data.distributor.id)
        setuser_date(response.data.user_date)
        setProductid(response.data.product.productName)
        setProductlabel(response.data.product.id)
        // setedist({value:response.data.distributor.id,label:response.data.distributor.tradeName}) 
    })
        .catch(error => {
          console.error(error);
        });}



        axios.get(`http://43.225.54.138:8080/scm/api/product/export`, { headers: authHeader() })
        .then(response => {
        //   const endTime = new Date().getTime();  
        //   const elapsedTime = endTime - startTime;  
        //   console.log(`Request took ${elapsedTime} milliseconds`);
        setproduct(response.data)
        console.log(response)  
    })
        .catch(error => {
          console.error(error);
        });

        axios.get(`http://43.225.54.138:8080/scm/api/distributor/export`, { headers: authHeader() })
        .then(response => {
          setdistributor(response.data)
        console.log(response)  
    })
        .catch(error => {
          console.error(error);
        });


   
    },[error,disword]);
    const disid=(val)=>{
      console.log(val)
      setdistributor(val)
  }
  
  const productid=(val)=>{
    console.log(val)
    setproduct(val)
  }

  const getItem = (  ) =>{

    // UserService.getprimaryorderdelete(id).then(res=>{
    //   window.location.reload();
    // })


    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
      
    UserService.getdisdelete(Number(id)).then(res=>{
      // window.location.reload();
      navigate(`/OpenCreate/${pointindex}`);
     Swal.fire(
        'Deleted!',
        'Your file has been deleted.',
        'success'
      )  })
       
      }
    })
  };

  const handlesupersubmit=()=>{
    console.log("submit")
    const postData = {
      user_date: user_date,
      quantity: quantity,
      product: {
        id: Number(pdl)
      },
      distributor: {
        id: Number(edist)
      }
    };





    // const headers = authHeader();

    axios.put(`http://43.225.54.138:8080/scm/api/distributor/openingstock/put/${id}`, postData, { headers: authHeader() })
      .then(response => {
        console.log('POST request successful');
        console.log('Response:', response.data);
        navigate(`/OpenCreate/${pointindex}`);

      })
      .catch(error => {
        console.error('Error occurred during POST request:', error);
      });
    }
  const dis=distributor.map((item)=>{
    return <>
    <option value={item.id}>{item.trade_Name}</option>
    </>
  })
  const product1=product.map((item)=>{
    return<>
     <option value={item.id}>{item.product_Name}</option>
    </>
  }) 

  const user = AuthService.getCurrentUser();
  return (
    <div className='card'>
        <div className='card-header'>
            


<div className='d-flex bd-highlight'>
        <div className="card-header card-title w-100"> Opening dis edit </div>
        {/* <button className='prox btn' onClick={()=>handleChang/e12()} ></button> */}
        {/* <Link className='p-2 w-200 card-header  btn-sm card-title bd-highlight    ' onClick={()=>getItem(id)}><Button className="delete btn-sm"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"/><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"/><line x1="10" y1="11" x2="10" y2="17"/><line x1="14" y1="11" x2="14" y2="17"/></svg></Button></Link> */}
        {user.roles[0] === 'ROLE_ADMIN' &&<Link className='p-2 w-200 card-header  btn-sm card-title bd-highlight'  ><Button className="btn-sm" onClick={()=>getItem(id)}>Delete</Button></Link>}
        {/* <Link className='p-2 w-200 card-header  btn-sm card-title bd-highlight' to={`/retaileredit/${id}/0`}><Button className="btn-sm">Edit</Button></Link> */}

        
        <Link className='p-2 w-200 card-header btn-sm card-title bd-highlight' to={`/OpenCreate/${pointindex}`}><Button className="btn-sm">Back</Button></Link>
      </div>




        </div>
        <div className='card-body'>
    <div className='row'>
        <div className='col'>
            <label className='form-label'>Distributor</label>
            <input type='text' value={edis}  disabled className="form-control"/>
        </div>
        <div className='col'>
            <label className='form-label'>Product</label>
            <input type='text' value={pdi} disabled className="form-control"/>

        </div>
    </div>

    <div className='row'>
        <div className='col'>
            <label className='form-label'>Qty</label>
            <input 
                type='number' 
                value={quantity} 
                className='form-control' 
                onChange={(e)=>setquantity(e.target.value)}
            />
        </div>
        <div className='col'>
            <label className='form-label'>Date</label>
            <input 
            disabled
                type='date' 
                className='form-control' 
                value={user_date} 
                onChange={(e)=>setuser_date(e.target.value)}
            />
        </div>
    </div>
</div>
<br></br>
<button className='btn btn-primary' onClick={()=>handlesupersubmit()}>Submit</button>
<br></br>

    </div>

  )
}

export default Openingedit