import axios from "axios";
import React, { useEffect, useState } from "react";
import AuthService from "../services/auth.service";
import authHeader from "../services/auth-header";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import { CSVLink } from "react-csv";
import Select from "react-select"; // import  from '../Retailer/Edit';
import { Button, Table } from "react-bootstrap";
import { BASE_URL } from "../services/Api";

export const PrimaryOrderComponent = ({
  head,
  axiostabledata,
  title,
  Sumofkeys,
}) => {
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState(encodeURIComponent(" "));
  const [number, setnumber] = useState([]);
  const [total, setotal] = useState([]);
  const [size, setsize] = useState([]);
  const [getid, setgetid] = useState(0);
  const [limit, setlimit] = useState(10);
  const [sort1, setsort1] = useState(true);
  const [sort, setsort] = useState("asc");
  const [product_id, setfeilds] = useState("product_id");
  const [data, setdata] = useState([]);
  const [superid, setsuperid] = useState(0);
  const [exportpage, setexport] = useState([]);
  const [data1, setdata1] = useState(10);
  const [entries, setentries] = useState([]);
  const { pointindex } = useParams();
  const [warehousehvalue, setwarehousehvalue] = useState([]);
  const [warehousehvaluedb, setwarehousehvaluedb] = useState([]);
  const [distributorvalue, setdistributorvalue] = useState([]);
  const [distributorData, setdistributorData] = useState([]);
  const [distributor,setdistributor] = useState('');
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [boleean, setboleean] = useState(false);

  const pagination = (msg) => {
    //  console.log(msg);

    setgetid(msg);
  };
  const handlechange = (entry) => {
    setdata1(entry);
    setlimit(entry);
  };

  const count = [{ value: 10 }, { value: 20 }, { value: 30 }, { value: 50 }];

  const searchhandle = (search) => {
    if (search.length == 0) {
      setError(encodeURIComponent(" "));
    } else {
      setError(search);
    }
  };

  const onChangedistributordata = (e) => {
    // console.log(e.value);
    setdistributor(Number(e.value));
    setdistributorvalue({ value: e.value, label: e.label });
  };

  const [loading, setLoading] = useState(false);

  const submitdata = () => {
    setLoading(true);

      axios.get(BASE_URL + 'primaryworkorder/page/primary/0/20/desc/productid/'+startDate + '/' + endDate + '/'+ distributor,{
          headers:authHeader()
      }).then(res=>{
          console.log('mello',res.data.Index);
          setdata((res.data.Index))
      }) .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false); 
      });

  }





  useEffect(() => {
    axios.get(BASE_URL + 'distributor/page/0/100/asc/id/%20',{
        headers:authHeader()
    }).then(res=>{
        setdistributorData((res.data.Index).map(user=>({ value:user.id,label:user.trade_Name})))
    })
    setCurrentPage(Number(pointindex));

    setgetid(Number(pointindex));

  }, []);

  const customFilter = (e) => {
    let name = e;
    axios.get( BASE_URL + `distributor/page/0/100/asc/id/${name?name:"%20"}`,{
        headers:authHeader()
    }).then(res=>{
      if (res && res.data && res.data.data) {
        setdistributorData(res.data.data.map(user => ({
            value: user.id,
            label: user.trade_Name
        })));
    } else {
         console.error("Data is undefined or has unexpected structure");
    }
    
        // setdistributorData((res.data.data).map(user=>({ value:user.id,label:user.trade_Name})))
    })
   }

  const handlePageChange = (page) => {
    setCurrentPage(page);
    // console.log(page-1);
    setsuperid(page - 1);
    pagination(page - 1);
  };
  const renderPageNumbers = () => {
    const pages = [];
    const maxPagesToShow = 3;

    for (
      let i = Math.max(0, currentPage - 2);
      i <= Math.min(number - 1, currentPage + 1);
      i++
    ) {
      pages.push(
        <li
          key={i}
          className={i === Number(currentPage) ? "newway" : ""}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </li>
      );
    }
    // pages+1
    return pages;
  };

  const bruce = data.map((item, rowIndex) => (
    <tr className="the">
      {head.map(
        (item2) => (
          
          <td
            className="datatabletd"
          >
            {item2.field == "action" ? item2.subaction1 : item[item2.field]}
          </td>
        )
  
      )}
    </tr>
  ));
  
  return (

    <>
      <MDBCard data-aos="fade">
        <div className="card-header flex">
          <h1>{title}</h1>
 
        </div>

        <MDBCardBody className="amit-scrolling">
          <form>
            <div className="row">
              <div className="col">
                
                  <div className="row">
                    <div className="col-6">
                      <label className="form-label">Select Distributor</label>
                      <Select
                        value={distributorvalue}
                        options={distributorData}
                        onChange={(e) => onChangedistributordata(e)}
                        onInputChange={(e) => customFilter(e) }
                      />
                    </div>
                  </div>
                
              </div>
              <div className="row">
                <div className="col">
                  <label className="form-label">Start Date:</label>
                  <input
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label className="form-label">End Date:</label>
                  <input
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <div className="row" style={{ margin: "10px", padding: "10px" }}>
              {/* <a
                className="btn btn-sm btn-primary"
                onClick={() => submitdata()}
              >
                Submit
              </a> */}

              <Button variant="primary" style={{width:'auto'}}    onClick={() => submitdata()}disabled={loading}>
          {loading ? 'Loading...' : 'Submit'}
        </Button>
            </div>
          </form>
          <br></br>
          <div className="forentriesandaddbutton">
            <div className="bottole">
              <p>Show entries</p>
              <select
                className="newselect"
                onChange={(e) => handlechange(e.target.value)}
              >
                {count.map((item) => {
                  return <option>{item.value}</option>;
                })}
              </select>
            </div>
            <div className=" ">
              <input
                type="text"
                className="form-control"
                onChange={(e) => searchhandle(e.target.value)}
              />
            </div>
          </div>
          {data.length == 0 ? (
            <div className="pilt">
              <div class="loader"></div>
            </div>
          ) : (
            ""
          )}
          <Table className="table thrtable " striped responsive size="sm">
            <thead>
              <tr>
                <th>Product Name</th>
                <th>Primary Order Id</th>
                <th >Unit of Measurement</th>
                <th >QTY(PCS)</th>
                <th >QTY(KG)</th>
                <th >Rate</th>
                <th >Gross Amount</th>
                <th >Trade Discount</th>
                <th >Scheme Discount</th>
                <th >Net Amount</th>
                <th >GST Value</th>
                <th >Total</th>
                {/* <th colspan="4">Rate</th> */}
              </tr>
        
            </thead>
            <tbody>{bruce}</tbody>
          </Table>

          <div className="flex">
            <div className="showentri">
              {data1 == 10 ? (
                <p>
                  Showing {superid * data1 + 1} to {superid * data1 + 10} of{" "}
                  {entries} entries
                </p>
              ) : (
                ""
              )}
              {data1 == 20 ? (
                <p>
                  Showing {superid * data1 + 1} to {superid * data1 + 20} of{" "}
                  {entries} entries
                </p>
              ) : (
                ""
              )}
              {data1 == 30 ? (
                <p>
                  Showing {superid * data1 + 1} to {superid * data1 + 30} of{" "}
                  {entries} entries
                </p>
              ) : (
                ""
              )}
              {data1 == 50 ? (
                <p>
                  Showing {superid * data1 + 1} to {superid * data1 + 50} of{" "}
                  {entries} entries
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="pagination">
              <ul className="pagination">
                <li
                  onClick={() =>
                    handlePageChange(currentPage > 1 ? currentPage - 1 : 1)
                  }
                >
                  {"Previous"}
                </li>
                {renderPageNumbers()}
                {number}
                <li
                  onClick={() =>
                    handlePageChange(
                      currentPage == number ? number : currentPage + 1
                    )
                  }
                >
                  {"Next"}
                </li>
              </ul>
            </div>
          </div>
        </MDBCardBody>
      </MDBCard>
    </>
  );
};
