import axios from "axios";
import { MDBCard } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../services/Api";
import authHeader from "../../services/auth-header";
import AuthService from "../../services/auth.service";
import Swal from "sweetalert2";

export default function View(){
    const { id } = useParams();
    const { pointindex } = useParams();
   const [product,setproduct] = useState('');
   const [rate,setrate] = useState('');
   const [description,setdescription] = useState('');
   const [itemdata,setitemdata] = useState([]);

   const navigate = useNavigate();
   const [isLoading, setIsLoading] = useState(false);
   const applyFontSizeTransition = (elementId) => {
     const element = document.getElementById(elementId);
     element.style.transition = 'font-size 2s';
     element.style.fontSize = '30px';
     element.style.fontWeight = '600';
     // font-weight: 600;
     setTimeout(() => {
        
       document.getElementById("typer1").classList.add('promote');
       element.style.fontSize = '24px';
     }, 1000);
   };

   const getbomdata = () => {
    axios.get(BASE_URL + 'bom/'+id,{
        headers:authHeader()
    }).then(res=>{
        setproduct(res.data.product);
        setrate(res.data.rate);
        setdescription(res.data.description);
        setitemdata(res.data.bomItems)
    })
   };

   useEffect(()=>{
    getbomdata();
   },[]);

   const getItem = (id) => {

    // ReatilerService.getDelete(id).then(res => {
    //   window.location.reload();
    // })

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true); 

        axios.delete(BASE_URL + 'bom/delete/'+id,{
            headers:authHeader()
        }).then(res => {
        //   window.location.reload();
        Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          )
        navigate(`/BOM/Table/${pointindex}`)
        }).catch(error => {
            console.error('Error adding  :', error);
            if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `
                <div>
                  <p id="issueText">Why do I have this issue?</p>
                  <ul>
              
                  <li id="typer1">You might have an internet issue</li>
                  </ul>
                </div>
              `,
              didOpen: () => {
                applyFontSizeTransition('issueText');
              }
            });
          }
          })
          .finally(() => {
            setIsLoading(false); 
          });
     
      }
    })
  };

   const user = AuthService.getCurrentUser();
    return(
        <MDBCard className="company">
                 <div className='d-flex bd-highlight'>
        <div className="card-header card-title w-100">View Bill Of Material</div>
        {user.roles[0] === 'ROLE_ADMIN' &&
        
        <Link className='p-2 w-200 card-header card-title bd-highlight' variant="primary" style={{width:'auto'}} onClick={()=>getItem(id)} disabled={isLoading}>
        
        <Button className="btn-sm">  {isLoading ? 'Loading...' : 'Delete'}</Button>
      </Link>  
        }
        {/* <Link className='p-2 w-200 card-header  btn-sm card-title bd-highlight' to={`/retaileredit/${id}/1`}><Button className="btn-sm">Edit</Button></Link> */}

        
        <Link className='p-2 w-200 card-header btn-sm card-title bd-highlight' to={`/BOM/Table/${pointindex}`}><Button className="btn-sm">Back</Button></Link>
      </div>
        
        <Link to='/BOM/Table/1' className="btn btn-primary">Back</Link>
        <Form className="product1">
                <Row className="mb-1">
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Product Name</Form.Label>
                        <Form.Control type="text" value={product.productName} readOnly/>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Rate</Form.Label>
                        <Form.Control type="text" value={rate} readOnly />
                    </Form.Group>
                    <Form.Group as={Col} md="12" controlId="formBasicName">
                        <Form.Label className="label">Description</Form.Label>
                        <Form.Control as="textarea" rows={3} value={description} readOnly />
                    </Form.Group>
                </Row>
            </Form>
            <table>
                <thead>
                    <tr>
                        <th>No</th>
                        <th>Product&nbsp;Name</th>
                        <th>UOM</th>
                        <th>Qty(pcs)</th>
                        <th>Qty(kg)</th>
                        <th>UOM</th>
                        <th>Rate</th>
                        <th>Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {itemdata.map((item,index)=>
                    <tr key={index}>
                        <td>{index +1}</td>
                        <td>{item.product.productName}</td>
                        <td>{item.uom}</td>
                        <td>{item.qty}</td>
                        <td>{item.kg}</td>
                        <td>{item.uom2}</td>
                        <td>{item.rate}</td>
                        <td>{item.amount}</td>
                    </tr>
                    )}
                </tbody>
            </table>
        </MDBCard>
    )
}