import axios from "axios";
import { MDBCard } from "mdbreact";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../services/Api";
import authHeader from "../../services/auth-header";
import { Button, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { useNavigate } from "react-router-dom";

export default function PrintProductbarcode() {
    const [productAll, setProductAll] = useState([]);
    const [BrandAll, setBrandAll] = useState([]);
    const [brand, setbrand] = useState('');
    const [product, setproduct] = useState('');
    const [size,setsize] = useState('');
    const [data, setData] = useState('');
    const [status, setstatus] = useState(false);
    const navigate = useNavigate();
 
    /* Get Brand data */
    const getbranddata = () => {
        axios.get(BASE_URL + 'brand/getAll', {
            headers: authHeader()
        }).then(res => {
            setBrandAll(res.data.map(user => ({ value: user.id, label: user.brandName })))
        })
    }

    useEffect(() => {
        getbranddata();
    }, []);

    const onChangeBrand = (e) => {
        let name = e.value;

        setbrand(e.value);
        /* Product Get all */
        axios.get(BASE_URL + 'productbarcode/product1/' + name, {
            headers: authHeader()
        }).then(res => {
            // console.log('mello',res.data)
            setProductAll(res.data.map(use => ({ value: use.id, label: use.product_name })));
        })

    }

    const onChangeProduct = (e) => {
        let name = e.value;
        setproduct(e.value);

    };

    const submitdata = () => {
        // setstatus(true)
        axios.get(BASE_URL + 'productbarcode/barcodebypb/' + product + '/' + brand, {
            headers: authHeader()
        }).then(res => {
            if(res.data !== null){
                navigate(`/ProductbarPrint/${res.data.id}/${size}`)
                //  console.log('mello',res.data)
                setstatus(true)
                setData(res.data)
                
            }
        })
    }

    const[printingdata,setprintingdata] = useState([]);
    const getsizedata = (e)=>{
        let name = e;
        let aa =[];
        for (var i = 0; i < name; i++){
            aa.push(i)
        }
        setprintingdata(aa)
    }
    
    const Print1 = () => {
        
        //console.log('print');  
     
        if(status === true){

            let printContents = document.getElementById('Print').innerHTML;
            let originalContents = document.body.innerHTML;
            document.body.innerHTML = printContents;
            window.print();
            document.body.innerHTML = originalContents;
        }

      
    }
    useEffect(()=>{
          Print1()
    },[status]);
    
    return (
        <MDBCard className="company">
            <h1>Product BarCode Print</h1>
            <Form className="product1">
                <Row className="mb-1">
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Brand Name</Form.Label>
                        <Select options={BrandAll} onChange={(e) => onChangeBrand(e)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Product Name</Form.Label>
                        <Select options={productAll} onChange={(e) => onChangeProduct(e)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Number of Print Pages</Form.Label>
                        <input type="number" className="form-control" onChange={(e)=>setsize(e.target.value)}/>
                     </Form.Group>
                    {/* <Form.Group as={Col} md="4" controlId="formBasicName">
                        <Form.Label className="label">Printing QTY</Form.Label>
                        <Form.Control type="text" autoComplete="off" onChange={(e) => getsizedata(e.target.value)} />
                    </Form.Group> */}
                </Row>
                <Button variant="primary" type="button" onClick={submitdata}>
                    Submit
                </Button>
                {/* {status === true && 
                <div id="Print" >
                {printingdata.map(user=><div className="column" style={{padding:'8px',border:'0.5px solid gray',borderRadius:'20px',marginTop:'8px',width:'48%'}}>
                <div class='rotate' >
              <div style={{borderBottom:'3px solid black'}}>
            <div  style={{fontSize:'17px',fontWeight:'bold'}}>Manufactured,Packed and Marketed By</div>
            <div  style={{fontSize:'16px',fontWeight:'bold'}}>NEELAM APPLIANCES LIMITED</div>
            <div className="spa" style={{fontSize:'13px',fontWeight:'700'}}>Address: Neelam House,Fafadia Ind East.Waliv Village,</div>
            <div className="spa" style={{fontSize:'13px',fontWeight:'700'}}>Vasai(E)-401208,Dist Palghar.Maharashtra (India)</div>
            <div className="spa" style={{fontSize:'13px',fontWeight:'700'}}>For Complaints / Feedback / Queries Contact Customer</div>
            <div className="spa" style={{fontSize:'13px',fontWeight:'700'}}>Care Executive Time:10am to 6pm,Consumer Care</div>
            <div className="spa" style={{fontSize:'13px',fontWeight:'700'}}>No:8080561321/22 Email:mail@neelamappliances.com</div>
            <div className="spa" style={{fontSize:'13px',fontWeight:'700'}}>& Refer Manufactured, Packed and Marketed by Address.</div>
            </div>
            <div>
                <div style={{display:'flex'}}>
                    <div style={{fontSize:'17px',fontWeight:'bold'}}>Product Name:
                    <div>{data.productname}</div>
                    </div>
                    <div class="col-md-4 ms-auto" style={{fontSize:'16px',fontWeight:'bold',border:'3px solid black',height:'30px',padding:'4px',borderTop:'hidden'}}>Size : {data.size}</div>
                </div>
                <div style={{fontSize:'16px',fontWeight:'bold',border:'3px solid black',display:'flex',justifyContent:'space-between',padding:'4px',marginTop:'-4px'}}>
                    <div>Capacity : {data.capacity}</div>
                    <div>Diameter : {data.diameter}</div>
                </div>
                <div style={{fontSize:'16px',fontWeight:'bold',display:'flex',justifyContent:'space-between',padding:'5px'}}>
                    <div style={{fontSize:'15px',fontWeight:'bold'}}>Max. Retail Price : <span style={{fontSize:'20px',fontWeight:'bold'}}>RS.{data.mrp}</span>
                    <div style={{fontSize:'13px',fontWeight:'700'}}>(Inclusive of All Taxes)</div>
                    </div>
                    <div style={{fontSize:'15px',fontWeight:'bold'}}>QTY : {data.qty} Unit</div>
                </div>
                <div style={{ width: '100%',height:'30px',marginTop:'-7px',overflow:'hidden',justifyContent:'center',alignItems:'center',textAlign:'center' }}>
                <img src={"http://43.225.54.138:8080/scm/BarcodeImages/ProductBarcode/"+data.barcodename }  style={{width:'300px',height:'50px'}} />
                </div>
                <div style={{fontSize:'18px',fontWeight:'bold',textAlign:'center'}}>{data.eancode}</div>
                <div style={{fontSize:'16px',fontWeight:'700',display:'flex',justifyContent:'space-between',padding:'5px',borderTop:'3px solid black',marginTop:'-3px' }}>
                    <div style={{marginTop:'-3px'}}>WASH&nbsp;BEFORE&nbsp;USE</div>
                    <div style={{marginTop:'-3px'}}>Packed&nbsp;On&nbsp;:&nbsp;{data.packedOn}</div>
                </div>
                </div>
           
                    </div></div>)}</div>
                     } */}
            </Form>
        </MDBCard>
    )
}