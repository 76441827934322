import React, { useEffect, useState } from "react";
// import WareHouseService from "../services/WareHouseService";
import { DistrubutorMinstockcomponent } from "./DistrubutorMinstockcomponent";
// import { MininmumStockReportComponent } from "./MininmumStockReportComponent";
function DistrubutorMinsotckcreate() {
  const [warehousename, setWarehousename] = useState("");

//   useEffect(() => {
//     // WareHouseService.getwarehouse().then((response) => {
//     //   setWarehousename(
//     //     response.data.map((user) => ({ value: user.id, label: user.name }))
//     //   );
//     // });
//   }, []);

  

  let header = [
    // { label: "Date", field: "date" },
    { label: "Product Name", field: "product_Name" },
    {label:"outwardqty",field:"outwardqty"},
    {label:"outwarddistributorstockqty",field:"outwarddistributorstockqty"},
    {label:"minimumstockqty",field:"minimumstockqty"},
    {label:"inwardqty",field:"inwardqty"},
    {label:"distopeningstock",field:"distopeningstock"},
    {label:"dcquantity",field:"dcquantity"},

    // {
    //   label: "Action",
    //   field: "action",
    //   subaction1: (
    //     <svg
    //       xmlns="http://www.w3.org/2000/svg"
    //       width="24"
    //       height="24"
    //       viewBox="0 0 24 24"
    //       fill="none"
    //       stroke="currentColor"
    //       stroke-width="2"
    //       stroke-linecap="round"
    //       stroke-linejoin="round"
    //       class="feather feather-eye"
    //     >
    //       <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
    //       <circle cx="12" cy="12" r="3" />
    //     </svg>
    //   ),
    // },
  ];
  const sumofkeys = [{ first: "closingstock" }];
  return (
    <DistrubutorMinstockcomponent
      head={header}
      title={"Distributor Minimum  Stock"}
      axiostabledata={"dms"}
      Add={""}
      Create={""}
      // Edit={""}
      // View={""}
    //   warehousehdata={warehousename}
      Sumofkeys={sumofkeys}
    />
  );
}

export default DistrubutorMinsotckcreate;
