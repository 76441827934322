import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
 import { useNavigate } from "react-router-dom";
import { MDBCard } from "mdbreact";
import Swal from 'sweetalert2'
import authHeader from "../../services/auth-header";
import AuthService from "../../services/auth.service";
import axios from "axios";
import New2 from "../New2";
export const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})
export default function Worktable(){
    const [brandName, setbrandName] = useState([]);
    const [error, setError] = useState(encodeURIComponent(' '));
const [Responsestatus, setResponsestatus] = useState([])
    const [number, setnumber] = useState([])
    const [total, setotal] = useState([])
    const [size, setsize] = useState([])
    const [getid, setgetid] = useState(0)
    const[limit,setlimit]=useState(10)
  const[sort,setsort]=useState("asc")
  const[fieldnme,setfeilds]=useState("id")
  const [responseState, setResponseState] = useState(false);

const navigate = useNavigate();
useEffect(() => {
 


const startTime = new Date().getTime();  

axios.get(`http://43.225.54.138:8080/scm/api/workorder/page/${Number(getid)}/${Number(limit)}/${sort}/${fieldnme}/${error}`, { headers: authHeader() })
  .then(response => {
    const endTime = new Date().getTime();  
    const elapsedTime = endTime - startTime;  
    console.log(`Request took ${elapsedTime} milliseconds`);

setotal(response.data.Enteries);
console.log(response);
setsize(response.data.Enteries);
setnumber(response.data.Pages);
setbrandName(response.data.Index);
setResponsestatus(response.status)
    console.log(response.status);
    if (response.status >= 200 && response.status < 300) {
      if (response.data ) {
       setResponseState(true);
       console.log(true)
     } else {
       setResponseState(false);
       console.log(false)

     }
   }
        })
  .catch(error => {
    console.error(error);
  });

 
  }, [getid,limit,fieldnme,sort]);


  const sotreddata=(value)=>{
    setsort(value.sort);
    setfeilds(value.field);
  console.log(value.field);
  }
  
  
  
  const newentry=(entry)=>{
    console.log(entry);
    setlimit(entry)
  }
  
  const handlinks=(link)=>{
  console.log(link);
  
  }
  
  let header = [
    { "label": "Id", "field": "id" },
    { "label": "Order Id    ", "field": "workOrderId" },

    { "label": "Retailer Name", "field": "tradeName    " },
    { "label": "Net Amount", "field": "netValue    " },
    { "label": "GST", "field": "taxAmount" },
    { "label": "Total", "field": "grossTotal    " },
 { "label": "Remarks", "field": "remarks" },
    { "label": "Action", "field": "action","subaction1":<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"/><circle cx="12" cy="12" r="3"/></svg> }
  ]; 
  
  
  
    const pagination = (msg) => {
      console.log(msg);
      setgetid(msg)
    }
  const counting=[{"value":10},{"value":20},{"value":30},{"value":50}]
 
    return(
        <>
       <New2 name="Secondary   LIST"
        addbutton="Add Secondary  "
        tdata={brandName}
        head={header}
        sotreddata={sotreddata}
        handlinks={handlinks}
        count={counting}
        newentry={newentry}
        Export="workorder"
        pagination={pagination}
        numberofpages={number}
        totalsize={size}
        View='workorderview'
        Edit='workorderedit'
        Addpage="/workordercreate"
        totalelemnts={total}
        Status={Responsestatus}
        
        />
        </>


    )
}
