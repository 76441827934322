import axios from 'axios';
import { MDBCard } from 'mdbreact';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import Select from 'react-select';
import { BASE_URL } from '../services/Api';
import authHeader from '../services/auth-header';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

export default function Create() {
  const [year, setyear] = useState('');
  const [month, setmonth] = useState('');
  const [valedate, setvaledate] = useState([]);
  const [attendancedata, setattendancedata] = useState([]);
  const [attendancedata1, setattendancedata1] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const [isVisible1, setIsVisible1] = useState('');
  const [headers, setheaders] = useState([]);
  const [headers1, setheaders1] = useState([]);
  const [data1, setdata1] = useState(10);
  const [limit, setlimit] = useState(10);
  const [superid, setsuperid] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [getid, setgetid] = useState(0);
  const [number, setnumber] = useState([]);
  const [entries, setentries] = useState([]);
  const [date, setdate] = useState('01');
  const [product_id, setfeilds] = useState("id");
  const [status, setstatus] = useState(false);
  const [disword, setdisword] = useState(encodeURIComponent(' '));

  const pagination = (msg) => {
    //  console.log(msg);

    setgetid(msg);
  };

  const handlechange = (entry) => {
    setdata1(entry);
    setlimit(entry);
  };

  const count = [{ value: 10 }, { value: 20 }, { value: 30 }, { value: 50 }];


  var years = Array.from(Array(new Date().getFullYear() - 1923), (_, i) => (i + 2023).toString())

  const selectdata = years.map(user => ({ value: user, label: user }));
  const onChangeyears = (e) => {
    setyear(e.value)
    setvaledate({ value: e.value, label: e.label })
  }
  function getatten() {
    let newdate = new Date();
    let years = newdate.getFullYear();
    let months = newdate.getMonth() + 1;
    setyear(years);
    setvaledate({ value: years, label: years })
    setmonth(months)

    axios.get(BASE_URL + `attendance/attendancereport/${years}-${months}-${date}/${Number(currentPage) - 1}/${limit}/asc/${product_id}/${disword}`, {
      headers: authHeader()
    }).then(res => {
      setentries(res.data.Enteries);
      setnumber(res.data.Pages);
      setattendancedata(res.data.Index)

    })
    axios.get(BASE_URL + 'attendance/dates/' + years + '-' + months + '-01', {
      headers: authHeader()
    }).then(res => {
      setheaders(res.data);
      let aa = [];
      res.data.map(user => {

        let date_1 = new Date(user.date);
        let dates_1 = date_1.getDate();
        let day_1 = new Date(user.date);
        let days_1 = day_1.getDay();
        aa.push({ "date": dates_1, "holiday": user.holiday, "days": days_1 })

      });
      setheaders1(aa)
    });

    axios.get(BASE_URL + `attendance/attendancereport1/${years}-${months}-${date}`, {
      headers: authHeader()
    }).then(res => {
      setattendancedata1(res.data)
    })
  }

  const attendancechange = () => {

    setstatus(true);
    axios.get(BASE_URL + 'attendance/dates/' + year + '-' + month + '-01', {
      headers: authHeader()
    }).then(res => {
      setheaders(res.data);
      let aa = [];
      res.data.map(user => {

        let date_1 = new Date(user.date);
        let dates_1 = date_1.getDate();
        let day_1 = new Date(user.date);
        let days_1 = day_1.getDay();
        aa.push({ "date": dates_1, "holiday": user.holiday, "days": days_1 })

      });
      setheaders1(aa);
    })


    axios.get(BASE_URL + `attendance/attendancereport/${year}-${month}-${date}/${Number(currentPage) - 1}/${limit}/asc/${product_id}/%20`, {
      headers: authHeader()
    }).then(res => {
      setentries(res.data.Enteries);
      setnumber(res.data.Pages);
      setattendancedata(res.data.Index)

    });

    axios.get(BASE_URL + `attendance/attendancereport1/${year}-${month}-${date}`, {
      headers: authHeader()
    }).then(res => {
      setattendancedata1(res.data)
    })
  }

  useEffect(() => {
    if (status == false) {

      getatten()
    } else {
      attendancechange()
    }

  }, [limit, currentPage, product_id, status, disword]);




  const tableRows = attendancedata.map((item, index) => (
    <>
      <tr onClick={() => { setIsVisible2((prev) => !prev); setIsVisible1(item.id) }} key={item.id} style={{ background: '#164194', color: 'white' }}>
        <td>{item.staffName}</td>
        {headers.map((dateItem, dateIndex) => {

          const foundDate = item.attendanceData.find(nameItem => nameItem.date === dateItem.date);
          return <td key={dateIndex}>{foundDate && foundDate.status}</td>; // If found, display 'X'
        })}
      </tr>
      {isVisible1 === item.id && isVisible2 ?
        <tr class="rowsopen">
          <td>In Time</td>
          {headers.map((dateItem, dateIndex) => {

            const foundDate = item.attendanceData.find(nameItem => nameItem.date === dateItem.date);
            return <td key={dateIndex}>{foundDate ? foundDate.dayintime : '0'}</td>;
          })}
        </tr> : isVisible &&
        <tr class="rowsopen">
          <td>In Time</td>
          {headers.map((dateItem, dateIndex) => {

            const foundDate = item.attendanceData.find(nameItem => nameItem.date === dateItem.date);
            return <td key={dateIndex}>{foundDate ? foundDate.dayintime : '0'}</td>;
          })}
        </tr>
      }
      {isVisible1 === item.id && isVisible2 ?
        <tr class="rowsopen">
          <td>Out Time</td>
          {headers.map((dateItem, dateIndex) => {

            const foundDate = item.attendanceData.find(nameItem => nameItem.date === dateItem.date);
            return <td key={dateIndex}>{foundDate ? foundDate.dayouttime : '0'}</td>;
          })}
        </tr> : isVisible &&
        <tr class="rowsopen">
          <td>Out Time</td>
          {headers.map((dateItem, dateIndex) => {

            const foundDate = item.attendanceData.find(nameItem => nameItem.date === dateItem.date);
            return <td key={dateIndex}>{foundDate ? foundDate.dayouttime : '0'}</td>;
          })}
        </tr>
      }
      {isVisible1 === item.id && isVisible2 ?
        <tr class="rowsopen">
          <td>Duration</td>
          {headers.map((dateItem, dateIndex) => {

            const foundDate = item.attendanceData.find(nameItem => nameItem.date === dateItem.date);
            return <td key={dateIndex}>{foundDate ? foundDate.duration : '0'}</td>;
          })}
        </tr> : isVisible &&
        <tr class="rowsopen">
          <td>Duration</td>
          {headers.map((dateItem, dateIndex) => {

            const foundDate = item.attendanceData.find(nameItem => nameItem.date === dateItem.date);
            return <td key={dateIndex}>{foundDate ? foundDate.duration : '0'}</td>;
          })}
        </tr>

      }
      {isVisible1 === item.id && isVisible2 ?
        <tr class="rowsopen">
          <td>Summary</td>
          <td colspan="3" >Total&nbsp;Present&nbsp;:&nbsp;{item.totalPresent}&nbsp;Days</td>
          <td colspan="3" >Total&nbsp;Absent&nbsp;:&nbsp;{item.totalAbsent}&nbsp;Days</td>
          <td colspan="3" >Total&nbsp;Half&nbsp;Days&nbsp;:&nbsp;{item.totalHalfDays}&nbsp;Days</td>
          <td colspan="3" >Total&nbsp;Holiday&nbsp;:&nbsp;{item.totalHoliday}</td>
          <td colspan="3" >Total&nbsp;Holiday&nbsp;Present&nbsp;:&nbsp;{item.totalHolidayPresent}</td>
          <td colspan="3" >Total&nbsp;Leave&nbsp;:&nbsp;{item.totalLeave}</td>
          <td colspan="3" >Total&nbsp;Week&nbsp;Off&nbsp;:&nbsp;{item.totalWorkOff}</td>
          <td colspan="3" >Total&nbsp;Week&nbsp;Off&nbsp;Present&nbsp;:&nbsp;{item.totalWorkOffPresent}</td>
          <td colspan="3" >Total&nbsp;Working&nbsp;Hours&nbsp;:&nbsp;{item.totalWorkingHours}</td>
        </tr> : isVisible &&
        <tr class="rowsopen">
          <td>Summary</td>
          <td colspan="3" >Total&nbsp;Present&nbsp;:&nbsp;{item.totalPresent}&nbsp;Days</td>
          <td colspan="3" >Total&nbsp;Absent&nbsp;:&nbsp;{item.totalAbsent}&nbsp;Days</td>
          <td colspan="3" >Total&nbsp;Half&nbsp;Days&nbsp;:&nbsp;{item.totalHalfDays}&nbsp;Days</td>
          <td colspan="3" >Total&nbsp;Holiday&nbsp;:&nbsp;{item.totalHoliday}</td>
          <td colspan="3" >Total&nbsp;Holiday&nbsp;Present&nbsp;:&nbsp;{item.totalHolidayPresent}</td>
          <td colspan="3" >Total&nbsp;Leave&nbsp;:&nbsp;{item.totalLeave}</td>
          <td colspan="3" >Total&nbsp;Week&nbsp;Off&nbsp;:&nbsp;{item.totalWorkOff}</td>
          <td colspan="3" >Total&nbsp;Week&nbsp;Off&nbsp;Present&nbsp;:&nbsp;{item.totalWorkOffPresent}</td>
          <td colspan="3" >Total&nbsp;Working&nbsp;Hours&nbsp;:&nbsp;{item.totalWorkingHours}</td>
        </tr>

      }
    </>
  ));

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setsuperid(page - 1);
    pagination(page - 1);
  };
  const renderPageNumbers = () => {
    const pages = [];
    const maxPagesToShow = 3;

    for (
      let i = Math.max(0, currentPage - 2);
      i <= Math.min(number - 1, currentPage + 1);
      i++
    ) {
      pages.push(
        <li
          key={i}
          className={i === Number(currentPage) ? "newway" : ""}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </li>
      );
    }
    // pages+1
    return pages;
  };

  const handleExport = () => {
    const matrix = [];

    // Populate matrix with staff names as rows
    attendancedata.forEach(({ dayintime, dayouttime, duration, staffName, totalPresent, totalWorkOffPresent, totalAbsent, totalHalfDays, totalHoliday, totalHolidayPresent, totalLeave, totalWorkOff, attendanceData, totalWorkingHours }) => {
      const row = [staffName];
      const row4 = ["Summary", "Total Present : " + totalPresent, "Total Absent : " + totalAbsent, "Total HalfDays : " + totalHalfDays, "Total Holiday : " + totalHoliday, "Total Holiday Present : " + totalHolidayPresent,
        "Total Leave : " + totalLeave, "Total Week Off : " + totalWorkOff, "Total Week Off Present : " + totalWorkOffPresent, "Total Working Hours : " + totalWorkingHours]; // Start each row with staff name
      const row1 = ["Day In Time ", dayintime]; // Start each row with staff name
      const row2 = ["Day Out Time", dayouttime];
      const row3 = ["Duration", duration]; // Start each row with staff name
      headers.forEach(({ date }) => {
        const matchedDate = attendanceData.find(item => item.date === date);
        row.push(matchedDate ? matchedDate.status : ''); // Add status if date matches, otherwise add empty string
        row1.push(matchedDate ? matchedDate.dayintime : '0');
        row2.push(matchedDate ? matchedDate.dayouttime : '0');
        row3.push(matchedDate ? matchedDate.duration : '0'); // Add status if date matches, otherwise add empty string
      });
      matrix.push(row, row1, row2, row3, row4);
    });
    const headers_0 = headers1.map(item => (item.date));
    const headers_1 = headers1.map(item => (item.holiday === 'no' ? "" : item.holiday));
    const headers_2 = headers1.map(item => (item.days == '0' && "S" || item.days == '1' && "M" || item.days == '2' && "T" || item.days == '3' && "W" || item.days == '4' && "T" || item.days == '5' && "F" || item.days == '6' && "S"));

    // Add header row with dates
    const headers_01 = ['Date', ...headers_0];
    const headers_02 = ['Staff Name', ...headers_1];
    const headers_03 = ['Days in a Week', ...headers_2];

    // Create worksheet
    const worksheetData = [headers_01, headers_03, headers_02, ...matrix];
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

    // Create workbook and add worksheet
    const columnWidths = [{ wpx: 200 }, ...headers_01.map(() => ({ wpx: 200 }))];
    worksheet['!cols'] = columnWidths;
    const columnWidths1 = [{ wpx: 200 }, ...headers_02.map(() => ({ wpx: 200 }))];
    worksheet['!cols'] = columnWidths1;
    const columnWidths2 = [{ wpx: 200 }, ...headers_03.map(() => ({ wpx: 200 }))];
    worksheet['!cols'] = columnWidths2;
    // Set row heights (20 pixels for header, 18 pixels for data rows)
    const rowHeights = [{ hpx: 20 }, ...matrix.map(() => ({ hpx: 18 }))];
    worksheet['!rows'] = rowHeights;

    // Create workbook and add worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Write workbook and trigger download
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const dataBlob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(dataBlob, 'ExportedData.xlsx');
  };
  return (
    <MDBCard>
      <div>
        <div className="card-header card-title w-100">Attendance</div>
        <Form className='product1'>
          <Row className="mb-1">
            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label className="cname2">Month</Form.Label>
              <Form.Select value={month} onChange={(e) => setmonth(e.target.value)}>
                <option>--Select--</option>
                <option value={"1"}>January</option>
                <option value={'2'}>February</option>
                <option value={'3'}>March</option>
                <option value={'4'}>April</option>
                <option value={'5'}>May</option>
                <option value={'6'}>June</option>
                <option value={'7'}>July</option>
                <option value={'8'}>August</option>
                <option value={'9'}>September</option>
                <option value={'10'}>October</option>
                <option value={'11'}>November</option>
                <option value={'12'}>December</option>
              </Form.Select>

            </Form.Group>
            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label className="cname2">Years</Form.Label>
              <Select options={selectdata} value={valedate} onChange={(e) => onChangeyears(e)} />
            </Form.Group>

            <Button onClick={attendancechange}>Submit</Button>
            {isVisible === false ? <Button onClick={() => { setIsVisible(true) }}>All Show</Button> : <Button onClick={() => { setIsVisible(false) }}>Close</Button>}
          </Row>
        </Form>
        <br></br>
        <div className="forentriesandaddbutton">
          <div className="bottole">
            <p>Show entries</p>
            <select
              className="newselect"
              onChange={(e) => handlechange(e.target.value)}
            >
              {count.map((item) => {
                return <option>{item.value}</option>;
              })}
            </select>
          </div>
          <div>
            <Row className="mb-1">
              <Form.Group as={Col} md="4" controlId="formBasicName">
                <button onClick={handleExport} class="whitefont pk " style={{ marginTop: '8px' }}>Export</button>
              </Form.Group>
              <Form.Group as={Col} md="8" controlId="formBasicName">
                <Form.Control type="search" placeholder="Search Here" autoComplete="off" onChange={(e) => setdisword(e.target.value)} />

              </Form.Group>
            </Row>
          </div>
        </div>
        <Table triped responsive size="sm" sty>
          <thead style={{ background: 'white', border: '0.5px solid black' }}>

            <tr >
              <th></th>
              {headers1.map(user =>
                <th>{user.date}</th>
              )}
            </tr>
            <tr >
              <th>Employee</th>
              {headers1.map(user =>
                <th>{user.holiday === 'no' ? "" : user.holiday}</th>
              )}
            </tr>
            <tr >
              <th></th>
              {headers1.map(user =>
                <th>{user.days == '0' && "S" || user.days == '1' && "M" || user.days == '2' && "T" || user.days == '3' && "W" || user.days == '4' && "T" || user.days == '5' && "F" || user.days == '6' && "S"}</th>
              )}
            </tr>
          </thead>
          <tbody>
            {tableRows}
          </tbody>

        </Table>
        <div className="flex">
          <div className="showentri">
            {data1 == 10 ? (
              <p>
                Showing {superid * data1 + 1} to {superid * data1 + 10} of{" "}
                {entries} entries
              </p>
            ) : (
              ""
            )}
            {data1 == 20 ? (
              <p>
                Showing {superid * data1 + 1} to {superid * data1 + 20} of{" "}
                {entries} entries
              </p>
            ) : (
              ""
            )}
            {data1 == 30 ? (
              <p>
                Showing {superid * data1 + 1} to {superid * data1 + 30} of{" "}
                {entries} entries
              </p>
            ) : (
              ""
            )}
            {data1 == 50 ? (
              <p>
                Showing {superid * data1 + 1} to {superid * data1 + 50} of{" "}
                {entries} entries
              </p>
            ) : (
              ""
            )}
          </div>
          <div className="pagination">
            <ul className="pagination">
              <li
                onClick={() =>
                  handlePageChange(currentPage > 1 ? currentPage - 1 : 1)
                }
              >
                {"Previous"}
              </li>
              {renderPageNumbers()}
              {number}
              <li
                onClick={() =>
                  handlePageChange(
                    currentPage == number ? number : currentPage + 1
                  )
                }
              >
                {"Next"}
              </li>
            </ul>
          </div>
        </div>

      </div>
    </MDBCard>
  )
}