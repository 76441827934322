import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../../services/Api'
import authHeader from '../../services/auth-header'
import Select from "react-select";
import ProductService from '../../services/ProductService';
import Addcarinjobsheet from '../../../redux/Reducer/Addcarinjobsheet';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart, emptyCart, removeFromCart, updateItem } from '../../../redux/Action/Addforinwardjobsheet';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Button } from 'react-bootstrap';
// import state from 'sweetalert/typings/modules/state';

const JobsheetInwardcreate = () => {
    const [isLoading, setIsLoading] = useState(false);
    const applyFontSizeTransition = (elementId) => {
      const element = document.getElementById(elementId);
      element.style.transition = 'font-size 2s';
      element.style.fontSize = '30px';
      element.style.fontWeight = '600';
      // font-weight: 600;
      setTimeout(() => {
         
        document.getElementById("typer1").classList.add('promote');
        element.style.fontSize = '24px';
      }, 1000);
    };
    const [disword, setdisword] = useState(encodeURIComponent(' '))
    const [dis, setdistributordata1] = useState([])
    const dispatch = useDispatch();
    const [jobsheetno, setJobsheetno] = useState([])
    const [jobsheetdate, setJobsheetdate] = useState([])
    const [jobtype, setJobtype] = useState([])
    const [remarks, setRemarks] = useState('')
    const [grandtotal, setGrandtotal] = useState([])
    const [warehouse, setWarehouse] = useState([])
    const [supplier, setSupplier] = useState([])
    const [jobsheetItems, setJobsheetItems] = useState([])
    const items = useSelector(state => state.Addcarinjobsheet.items);
// console.log((items.map((item)=>Number(item.amt))))
// const checkqty=useSelector(state=>state.Addcarinjobsheet)
console.log(items)
let sum
if(items){
   sum = items.reduce((acc, item) => acc + Number(item.amount), 0);
console.log(sum)
}
 useEffect(() => {
    dispatch(emptyCart());

 }, [ ])
 
    useEffect(() => {

        axios.get(`${BASE_URL}product/page/0/200/asc/id/${disword.length?disword:encodeURIComponent(' ')}`, { headers: authHeader() })
            .then(response => {
                console.log(response)
                if (response.data.data == undefined) {
                    setdistributordata1(response.data.Index.map(use => ({ value: use.id, label: use.product_Name })));
                } if (response.data.Index == undefined) {
                    setdistributordata1(response.data.data.map(use => ({ value: use.id, label: use.product_Name })));

                }

                // console.log(response.data.data.map(use => ({ value: use.id, label: use.product_Name })))
            })




    }, [ disword])
    const disid=(val)=>{
        console.log(typeof val)
        // if(typeof val=='number'){
// alert('its number')
ProductService.getProductEdit(val).then(res=>{
    console.log([res.data])
  const productdetails=[res.data]
  console.log(productdetails.map((item)=>item.id))
  const extractedData = productdetails.map(item => ({
    id:item.id,
    productName: item.productName,
    dlp: item.dlp,
    type:item.productType,
    jobsheet_qty:0,
    amount:0,
    product: {
        "id": val
    },
    type1:'box',
    
    jobsheet_qty_kg:0,
    uomSecondary:Number(item.uomSecondary)
}));

// console.log(extractedData[0])
dispatch(addToCart(extractedData[0]));

   })
   // setdistributor(val)
    }
    let onChangeSupplierData = (e) => {

        let name = e.value;

     
    };
    const [disword1, setdisword1] = useState(encodeURIComponent(' '))
    const [dis1,setdistributordata11]=useState([])
    const handleDelete = (id) => {
        dispatch(removeFromCart(id));
    };  
    useEffect(() => {
    
      axios.get(`http://43.225.54.138:8080/scm/api/supplier/page/0/10/asc/id/${disword1.length?disword1:encodeURIComponent(' ')}`, { headers: authHeader() })
      .then(response => {
    console.log(response)
    if(response.data.data==undefined){
        setdistributordata11(response.data.Index.map(use => ({ value: use.id, label: use.suppliername})));
   }  if(response.data.Index==undefined){
    setdistributordata11(response.data.data.map(use => ({ value: use.id, label: use.suppliername})));
  
   }
      
//   console.log(response.data.data.map(use => ({ value: use.id, label: use.suppliername})))  
  })
  
  
  
  }, [ disword])
                         
  const [disword2, setdisword2] = useState(encodeURIComponent(' '))
  const [dis2,setdistributordata2]=useState([])
  
  useEffect(() => {
  
    axios.get(`http://43.225.54.138:8080/scm/api/warehouse/page/0/10/asc/id/${disword2.length?disword2:encodeURIComponent(' ')}`, { headers: authHeader() })
    .then(response => {
  console.log(response)
  if(response.data.data==undefined){
    setdistributordata2(response.data.Index.map(use => ({ value: use.id, label: use.name})));
 }  if(response.data.Index==undefined){
    setdistributordata2(response.data.data.map(use => ({ value: use.id, label: use.name})));

 }
    
// console.log(response.data.data.map(use => ({ value: use.id, label: use.suppliername})))  
})



}, [ disword2])
const navigate = useNavigate();

let data=[{value:'Cutting',label:'Cutting'},{value:'Shaping',label:'Shaping'},{value:'Moulding ',label:'Moulding'},{value:'Finishing',label:'Finishing'}]

const handleclikc=()=>{
    setIsLoading(true); 

    // items
    // items
    let extractedData = items.map(item => ({
        // id:item.id,
        amount:item.amount,
        dlp: item.dlp,
      jobsheet_qty:item.jobsheet_qty,
      productType:item.type1!=='kg'?'pcs':'kg',
         product: {
            "id": item.id
        },
       jobsheet_qty_kg:item.jobsheet_qty_kg,
        // uomSecondary:Number(item.uomSecondary)
    }));

    console.log([extractedData][0])

//    let d2ata=[
//     {
//         "productType": "pcs",
//         "jobsheet_qty": 20,
//         "jobsheet_qty_kg": 10,
//         "amount": 100,
//         "rate": 20,
//         "mrp": 10,
//         "product": {
//             "id": 6
//         }
//     }
// ]
// console.log(d2ata)
    // console.log([{jobsheetno,jobsheetdate,jobtype,remarks,grandtotal:sum,warehouse:{'id':warehouse},supplier:{'id':supplier},jobsheetItems:items}])
   
    const data = [{
        jobsheetno: jobsheetno,
        jobsheetdate:jobsheetdate,
        jobtype:jobtype,
        remarks:remarks,
        grandtotal: sum, 
        warehouse: { id: Number(warehouse) },
        supplier: { id: Number(supplier) },
        // jobsheetItems:  [
        //     {
        //         "productType": "pcs",
        //         "jobsheet_qty": 20,
        //         "jobsheet_qty_kg": 10,
        //         "amount": 100,
        //         "rate": 20,
        //         "mrp": 10,
        //         "product": {
        //             "id": 6
        //         }
        //     },  {
        //         "productType": "pcs",
        //         "jobsheet_qty": 20,
        //         "jobsheet_qty_kg": 10,
        //         "amount": 100,
        //         "rate": 20,
        //         "mrp": 10,
        //         "product": {
        //             "id": 6
        //         }
        //     }
        // ]
        jobsheetItems:extractedData
      }];
    axios.post(BASE_URL+"jobworkinward/", data[0], {
        headers: authHeader()
      })
        .then(response => {
          console.log('Post request successful:', response.data);
          dispatch(emptyCart());
          navigate(`/Inwardtable/0`)

        //   Inwardtable
        //   alert('sent')
        }).catch(error => {
            console.error('Error adding  :', error);
            if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `
                <div>
                  <p id="issueText">Why do I have this issue?</p>
                  <ul>
              
                  <li id="typer1">You might have an internet issue</li>
                  </ul>
                </div>
              `,
              didOpen: () => {
                applyFontSizeTransition('issueText');
              }
            });
          }
          })
          .finally(() => {
            setIsLoading(false); 
          });
}

// "jobsheetno": "101",
// "jobsheetdate": "2024-05-10",
// "jobtype": "cutting",
// "remarks": "cut",
// "grandtotal": 100,
// "warehouse": {
//     "id": 1
// },
// "supplier": {
//     "id": 10
// },
// "jobsheetItems"

    return (
        <div>
            <div className='card'>
                <div className='card-body'>
                    <div className='card-title card-header'>
                        Jobsheet Inward Create
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <label className='form-label'>Jobsheet Number</label>
                            <input type='text' className='form-control' onChange={(e)=>setJobsheetno(e.target.value)}/>
                        </div>
                        <div className='col'>
                            <label className='form-label'>  Date</label>
                            <input type='Date' className='form-control' onChange={(e)=>setJobsheetdate(e.target.value)}/>
                        </div>
                        
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <label className='form-label'>Supplier</label>
                            <input type='text' style={{border:'none',background:'white'}} disabled/>
                            <Select options={dis1} className='sett'  onInputChange={(inputValue) => setdisword1(inputValue)} onChange={(options) => setSupplier(options.value)}/>
                        </div>
                        <div className='col'>
                            <label className='form-label'>Warehouse</label>
                            <Select options={dis2} className='sett'  onInputChange={(inputValue) => setdisword2(inputValue)} onChange={(options) => setWarehouse(options.value)}/>
                        </div>
                        <div className='col'>
                            <label className='form-label'>Job Type</label>
                            <Select options={data} className='sett'   onChange={(options) => setJobtype(options.value)}/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <label className='form-label'>Remarks</label>
                            <textarea className='form-control' onChange={(e)=>setRemarks(e.target.value)}>

                            </textarea>
                        </div>

                    </div><br></br>
                    {/* <input type='text' /> */}
                    <Select options={dis} onInputChange={(inputValue) => setdisword(inputValue)} onChange={(options) => disid(options.value)}/>
                    <br></br>
                    <table className='table thrtable '>
                        <thead>
                            <tr>
                                <th>Product Name</th>
                                <th>Product Type</th>
                                <th>Qty  </th>
                                <th>Qty kg</th>
                                <th>Rate  </th>
                                <th>Amount</th>
                                <th  width='50' height='12'>Action</th>
                            </tr>
                        </thead>
<tbody>
    {items.map((item)=>{
return<>
<tr>
{/* {item.type} */}
<td>{item.productName}</td>
<td width='150' height='12'><select  className='form-select'  onChange={(e) => {
    dispatch(updateItem(item.id, e.target.value, item.jobsheet_qty, item.dlp,item.jobsheet_qty_kg,item.amount));
  }}>
    <option>Select</option>
    <option value='box'>Box</option>
   {item.type=='kgproduct'?<option value='kg'>KG</option>:''}
    </select></td>
<td width='150' height='12'><input type='number' min='0' value={Number(item.jobsheet_qty)} disabled={item.type1=='kg'?true:false} onChange={(e) => {
    dispatch(updateItem(item.id, item.type1, Number(e.target.value), item.dlp,item.jobsheet_qty_kg,item.amount));
  }} className='form-control'/></td>
  {/* <td>{item.qtykg}</td> */}
  <td width='150' height='12'><input type='number'  min='0' value={Number(item.jobsheet_qty_kg)} disabled={item.type1=='box'?true:false} onChange={(e) => {
    dispatch(updateItem(item.id, item.type1,item.jobsheet_qty, item.dlp, e.target.value,item.amount));
  }}className='form-control'/></td>
<td width='150' height='12'><input type='number'  min='0' value={Number(item.dlp)} onChange={(e) => {
    dispatch(updateItem(item.id, item.type1,item.jobsheet_qty,Number(e.target.value), item.jobsheet_qty_kg,item.amount));
  }}className='form-control'/></td>
<td width='150' height='12'><input type='number'  min='0' value={Number(item.amount)} className='form-control'/></td>
<td>    <button onClick={() => handleDelete(item.id)}>Delete</button></td>
</tr>

</>

    })}
</tbody>

                    </table>
                    <div className='row'>
                        <div className='col tomforgrandtotal'>
                            Total:{sum.toFixed(2)}
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col'>
                        <Button variant="primary" style={{width:'auto'}} onClick={()=>handleclikc()} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Submit'}
        </Button>
                            {/* <button className='btn btn-primary' onClick={()=>handleclikc()}>Submit</button> */}
                        </div>
                    </div>
                </div>

            </div>



        </div>
    )
}

export default JobsheetInwardcreate