import axios from "axios";
import { MDBCard } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { BASE_URL } from "../../services/Api";
import authHeader from "../../services/auth-header";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

export default function Create() {
    const [staffAll,setstaffAll] = useState([]);
    const [staff,setstaff] = useState('');
    const [disword,setdisword] = useState(encodeURIComponent(' '));
    const [gname,setgname] = useState('');


    const navigate = useNavigate();

    let Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
    const [isLoading, setIsLoading] = useState(false);
    const applyFontSizeTransition = (elementId) => {
      const element = document.getElementById(elementId);
      element.style.transition = 'font-size 2s';
      element.style.fontSize = '30px';
      element.style.fontWeight = '600';
      // font-weight: 600;
      setTimeout(() => {
         
        document.getElementById("typer1").classList.add('promote');
        element.style.fontSize = '24px';
      }, 1000);
    };

    useEffect(()=>{
    axios.get(BASE_URL + `staff/page/0/10/desc/id/${disword.length == 0?"%20":disword}`,{
        headers:authHeader()
    }).then(response=>{
    //    console.log('mello',res.data);
       if (response.data.data == undefined) {
        setstaffAll(response.data.Index.map(use => ({ value: use.id, label: use.staff_Name })));
    } if (response.data.Index == undefined) {
        setstaffAll(response.data.data.map(use => ({ value: use.id, label: use.staff_Name })));

    }
    })
    },[disword])

    const onChangeStaff = (e) => {
        setstaff({"id":e.value})
    };

    const submitdata = () => {
        setIsLoading(true);
        axios.post(BASE_URL + 'galaprefix/save',{staff,gname},{
            headers:authHeader()
        }).then(res=>{
            navigate('/Gala/Table/1');
            if(res.status==201||res.status==200){
              // alert("ee")
              Toast.fire({
                icon: 'success',
                title: 'Successfully added'
              })
            }
        }).catch(error => {
            console.error('Error adding  :', error);
            if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `
                <div>
                  <p id="issueText">Why do I have this issue?</p>
                  <ul>
              
                  <li id="typer1">You might have an internet issue</li>
                  </ul>
                </div>
              `,
              didOpen: () => {
                applyFontSizeTransition('issueText');
              }
            });
          }
          })
          .finally(() => {
            setIsLoading(false); 
          });
          
    }
    return (
        <MDBCard className="company">
            <h1>Gala Create</h1>
            <Form className="product1">
                <Row className="mb-1">
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Staff Name</Form.Label>
                        <Select options={staffAll} onChange={(e) => onChangeStaff(e)} onInputChange={(inputValue) => setdisword(inputValue)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Gala Name</Form.Label>
                        <Form.Control type="text" name="gname" placeholder="Enter Gala Name" onChange={(e) => setgname(e.target.value)} />
                    </Form.Group>

                </Row>
                <Button variant="primary" style={{width:'auto'}} onClick={submitdata} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Submit'}
        </Button>
            </Form>
        </MDBCard>
    )
}