import axios from "axios";
import { MDBCard } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import Select from "react-select";
import { BASE_URL } from "../services/Api";
import authHeader from "../services/auth-header";

export default function PendingOrderVsGodownStockComponents() {

    const [warehouseAll, setwarehouseAll] = useState([]);
    const [fromdate, setfromdate] = useState(new Date());
    const [todate, settodate] = useState('');
    const [warehouseid, setwarehouseid] = useState([]);
    const [data, setdata] = useState([]);
    const [warehouse_name, setwarehouse_name] = useState([]);

    const datevalue_1 = new Date(fromdate);
    const defaultValue_1 = datevalue_1.toLocaleDateString('en-CA');
    const getwarehousedata = () => {
        axios.get(BASE_URL + 'warehouse/getAll', { headers: authHeader() }).then(res => {
            setwarehouseAll((res.data).map(user => ({ value: user.id, label: user.name })));
        })
    };

    useEffect(() => {
        getwarehousedata();
    }, [])

    const onChamngewarehouse = (e) => {

        setwarehouse_name(e)

        let aa = [];
        e.map(user => {

            aa.push(user.value)
        })
        setwarehouseid(aa)
    }
    const [loading, setLoading] = useState(false);

    const submit = () => {
        // let warehousevalue = {"wareid":warehouseid}
        setLoading(true);

        axios.post(BASE_URL + 'reports/pend/' + fromdate + '/' + todate, { "wareid": warehouseid }, { headers: authHeader() }).then(res => {
            console.log('mello', res.data)
            setdata(res.data);
        }) .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            setLoading(false); 
          });
    }
    return (
        <MDBCard className="company">
            <h1>Panding Order Vs Godown Stock Reports</h1>
            <Form className="product1">
                <Row className="mb-1">
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Warehouse Name</Form.Label>
                        <Select options={warehouseAll} isMulti onChange={(e) => onChamngewarehouse(e)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">From Date</Form.Label>
                        <Form.Control type="date" value={defaultValue_1} onChange={(e) => setfromdate(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">To Date</Form.Label>
                        <Form.Control type="date" onChange={(e) => settodate(e.target.value)} />
                    </Form.Group>
                </Row>
                {/* <Button onClick={submit}>Submit</Button> */}
                <Button variant="primary" style={{width:'auto'}}    onClick={() => submit()}disabled={loading}>
          {loading ? 'Loading...' : 'Submit'}
        </Button>
            </Form>
            <Table>
                <thead>
                    <tr>
                        <th>ITEM NAME</th>
                        <th>EAN CODE</th>
                        <th>PENDING ORDER (Primary Order)</th>
                        {data.length !== 0 ?
                            (warehouse_name.map(user => (
                                <th>{user.label}</th>
                            ))) : ""}
                        <th>TOTAL GODOWN QTY</th>
                        <th>TOTAL QTY</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td></td>
                        <td></td>
                        <td style={{display:'flex',justifyContent:'space-between'}}>
                            <th>Quantity</th>
                            <th>(Alt. Qty)</th>
                            <th>Rate</th>
                            <th>Value</th>
                        </td>
                        {data.length !== 0 ?
                            (warehouse_name.map(user => (
                                <td>
                                    <th>Quantity</th>
                                    <th>(Alt. Qty)</th>
                                </td>
                            ))) : ""}
                        <td>
                            <th>Quantity</th>
                            <th>(Alt. Qty)</th>
                        </td>
                        <td>
                            <th>Quantity</th>
                            <th>(Alt. Qty)</th>
                        </td>
                    </tr>
                </tbody>
                <tbody>
                    {data.map((item, index) => (
                        <tr key={index}>
                            <td>{item.product_Name}</td>
                            <td>{item.ean_code}</td>
                            <td style={{display:'flex',justifyContent:'space-between'}}>
                                <th>{item.primaryorderpcsqty}</th>
                                <th>{item.primaryorderkgqty}</th>
                                <th>{item.rate}</th>
                                <th>{item.primaryorderpcsqty * item.rate}</th>
                            </td>
                            {warehouse_name.map(user => (
                                <td>
                                    <th>{item.closingStock.map(use => user.label === use.warehousename && use.closingkgqty)}</th>
                                    <th>{item.closingStock.map(use => user.label === use.warehousename && use.closinpcsqty)}</th>
                                </td>
                            ))}
                            <td style={{display:'flex',justifyContent:'space-between'}}>
                                <th>{item.totalkgclosingstock}</th>
                                <th>{item.totalpcsclosingstock}</th>
                                </td>
                                <td>
                                <th>{item.totalpcsstock}</th>
                                <th>{item.totalkgstock}</th>
                                </td>
                            {/* <td>{item.closingStock[0].warehousename}</td> */}
                            {/* <td>{item.primaryorderkgqty}</td> */}
                        </tr>
                    ))}
                </tbody>
            </Table>
        </MDBCard>
    )
}