import axios from "axios";
import { MDBCard } from "mdbreact";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../services/Api";
import authHeader from "../../services/auth-header";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Select from "react-select";

export default function Create() {
  // State for existing fields
  const [assetsname, setassetsname] = useState('');
  const [mrp, setmrp] = useState('');
  const [srno, setsrno] = useState('');
  const [remarks, setremarks] = useState('');
  const [expiryDate, setexpiryDate] = useState('');

  // State for new fields
  const [invoiceNo, setInvoiceNo] = useState('');
  const [invoiceDate, setInvoiceDate] = useState('');
  const [insuranceProviderName, setInsuranceProviderName] = useState('');
  const [insurancePolicyNo, setInsurancePolicyNo] = useState('');
  const [ipnStartDate, setIpnStartDate] = useState('');
  const [ipnEndDate, setIpnEndDate] = useState('');
  const [insuranceFileName, setInsuranceFileName] = useState('');
  const [insuranceFileLocation, setInsuranceFileLocation] = useState('');
  const [supplier, setSupplier] = useState('');
  const [inscFile, setInscFile] = useState(null);

  const navigate = useNavigate();

  let Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  const [isLoading, setIsLoading] = useState(false);

  const applyFontSizeTransition = (elementId) => {
    const element = document.getElementById(elementId);
    element.style.transition = 'font-size 2s';
    element.style.fontSize = '30px';
    element.style.fontWeight = '600';
    setTimeout(() => {
      document.getElementById("typer1").classList.add('promote');
      element.style.fontSize = '24px';
    }, 1000);
  };

  // const submitdata = () => {
  //   setIsLoading(true);
  //   axios.post(BASE_URL + 'asset/save', {
  //     assetsname,
  //     mrp,
  //     srno,
  //     remarks,
  //     expiryDate,
  //     invoiceNo,
  //     invoiceDate,
  //     insuranceProviderName,
  //     insurancePolicyNo,
  //     ipnStartDate,
  //     ipnEndDate,
  //     insuranceFileName,
  //     insuranceFileLocation,
  //     supplier,
  //     inscFile
  //   }, {
  //     headers: authHeader()
  //   }).then(res => {
  //     navigate('/Assest/Table/1');
  //     if (res.status === 201 || res.status === 200) {
  //       Toast.fire({
  //         icon: 'success',
  //         title: 'Successfully added'
  //       });
  //     }
  //   }).catch(error => {
  //     console.error('Error adding :', error);
  //     if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
  //       Swal.fire({
  //         icon: "error",
  //         title: "Oops...",
  //         text: "Something went wrong!",
  //         footer: `
  //           <div>
  //             <p id="issueText">Why do I have this issue?</p>
  //             <ul>
  //               <li id="typer1">You might have an internet issue</li>
  //             </ul>
  //           </div>
  //         `,
  //         didOpen: () => {
  //           applyFontSizeTransition('issueText');
  //         }
  //       });
  //     }
  //   }).finally(() => {
  //     setIsLoading(false);
  //   });
  // };
  const submitdata = () => {
    setIsLoading(true);
  
    // Create a new FormData instance
    const formData = new FormData();
  
    // Append each form field to the FormData object
    formData.append('assetsname', assetsname);
    formData.append('mrp', mrp);
    formData.append('srno', srno);
    formData.append('remarks', remarks);
    formData.append('expiryDate', expiryDate);
    formData.append('invoiceno', invoiceNo);
    formData.append('invoicedate', invoiceDate);
    formData.append('insuranceprovidername', insuranceProviderName);
    formData.append('insurancepolicyno', insurancePolicyNo);
    formData.append('ipnstartdate', ipnStartDate);
    formData.append('ipnenddate', ipnEndDate);
     // inscfile
    formData.append('supplier', supplier);
    
    // Append the file separately if it exists
    if (inscFile) {
      formData.append('inscfile', inscFile);
    }
  
    // Send the FormData object in the Axios request
    axios.post(BASE_URL + 'asset/save', formData, {
      headers: {
        ...authHeader(),
        'Content-Type': 'multipart/form-data' // Set the correct content type for FormData
      }
    }).then(res => {
      navigate('/Assest/Table/1');
      if (res.status === 201 || res.status === 200) {
        Toast.fire({
          icon: 'success',
          title: 'Successfully added'
        });
      }
    }).catch(error => {
      console.error('Error adding :', error);
      if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `
            <div>
              <p id="issueText">Why do I have this issue?</p>
              <ul>
                <li id="typer1">You might have an internet issue</li>
              </ul>
            </div>
          `,
          didOpen: () => {
            applyFontSizeTransition('issueText');
          }
        });
      }
    }).finally(() => {
      setIsLoading(false);
    });
  };
  


  const [disword, setdisword] = useState(encodeURIComponent(' '))
  const [dis, setdistributordata1] = useState([])

  useEffect(() => {

      axios.get(`http://43.225.54.138:8080/scm/api/supplier/page/0/10/asc/id/${disword.length ? disword : encodeURIComponent(' ')}`, { headers: authHeader() })
          .then(response => {
              console.log(response)
              if (response.data.data == undefined) {
                  setdistributordata1(response.data.Index.map(use => ({ value: use.id, label: use.companyname })));
              } if (response.data.Index == undefined) {
                  setdistributordata1(response.data.data.map(use => ({ value: use.id, label: use.companyname })));

              }

              //   console.log(response.data.data.map(use => ({ value: use.id, label: use.suppliername})))  
          })

      /** Voucher GetAll */

      

  }, [disword])

  const onChangeSupplier = (e) => {
    // let name=
    setSupplier(  e.value );
   
};
  return (
    <MDBCard className="company">
      <h1>Add Asset</h1>
      <Form className="product1">
        <Row className="mb-1">
          {/* Existing Fields */}
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="label">Asset Name</Form.Label>
            <Form.Control type="text" name="assetsname" onChange={(e) => setassetsname(e.target.value)} autoComplete="off" placeholder="Enter Asset Name" />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicMRP">
            <Form.Label className="label">MRP</Form.Label>
            <Form.Control type="text" name="mrp" onChange={(e) => setmrp(e.target.value)} autoComplete="off" placeholder="Enter MRP" />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicExpiryDate">
            <Form.Label className="label">Warranty Till Date</Form.Label>
            <Form.Control type="date" name="expiryDate" onChange={(e) => setexpiryDate(e.target.value)} autoComplete="off" />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicSerialNumber">
            <Form.Label className="label">Serial Number</Form.Label>
            <Form.Control type="text" name="srno" onChange={(e) => setsrno(e.target.value)} autoComplete="off" placeholder="Enter Serial Number" />
          </Form.Group>
          <Form.Group as={Col} md="12" controlId="formBasicRemarks">
            <Form.Label className="label">Remarks</Form.Label>
            <Form.Control as="textarea" rows={3} name='remarks' onChange={(e) => setremarks(e.target.value)} placeholder='Enter Remarks' autoComplete="off" />
          </Form.Group>

          {/* New Fields */}
          <Form.Group as={Col} md="6" controlId="formInvoiceNo">
            <Form.Label className="label">Invoice Number</Form.Label>
            <Form.Control type="text" name="invoiceNo" onChange={(e) => setInvoiceNo(e.target.value)} autoComplete="off" placeholder="Enter Invoice Number" />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formInvoiceDate">
            <Form.Label className="label">Invoice Date</Form.Label>
            <Form.Control type="date" name="invoiceDate" onChange={(e) => setInvoiceDate(e.target.value)} autoComplete="off" />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formInsuranceProviderName">
            <Form.Label className="label">Insurance Provider Name</Form.Label>
            <Form.Control type="text" name="insuranceProviderName" onChange={(e) => setInsuranceProviderName(e.target.value)} autoComplete="off" placeholder="Enter Insurance Provider Name" />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formInsurancePolicyNo">
            <Form.Label className="label">Insurance Policy Number</Form.Label>
            <Form.Control type="text" name="insurancePolicyNo" onChange={(e) => setInsurancePolicyNo(e.target.value)} autoComplete="off" placeholder="Enter Insurance Policy Number" />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formIpnStartDate">
            <Form.Label className="label">IPN Start Date</Form.Label>
            <Form.Control type="date" name="ipnStartDate" onChange={(e) => setIpnStartDate(e.target.value)} autoComplete="off" />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formIpnEndDate">
            <Form.Label className="label">IPN End Date</Form.Label>
            <Form.Control type="date" name="ipnEndDate" onChange={(e) => setIpnEndDate(e.target.value)} autoComplete="off" />
          </Form.Group>
           
          <Form.Group as={Col} md="6" controlId="formSupplier">
            <Form.Label className="label">Supplier</Form.Label>
            <Select options={dis} onInputChange={(inputValue) => setdisword(inputValue)} onChange={(options) => onChangeSupplier(options)} />
            {/* <Form.Control type="text" name="supplier" onChange={(e) => setSupplier(e.target.value)} autoComplete="off" placeholder="Enter Supplier" /> */}
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formInscFile">
            <Form.Label className="label">Insurance File</Form.Label>
            <Form.Control type="file" name="inscFile" onChange={(e) => setInscFile(e.target.files[0])} autoComplete="off" />
          </Form.Group>
        </Row>
        <Button variant="primary" onClick={submitdata} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Submit'}
        </Button>
      </Form>
    </MDBCard>
  );
}
