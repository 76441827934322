import React from "react";
import { Mydatatable } from "../../Master/Product/Mydatatable";

export default function Table(){

    let header = [
        { "label": "Id", "field": "id" },
        { "label": "Product Name", "field": "productname" },
        { "label": "Size", "field": "size" },
        { "label": "Capacity", "field": "capacity" },
        { "label": "MRP", "field": "mrp" },
        { "label": "QTY", "field": "qty" },
        { "label": "EAN Code", "field": "eancode" },
        // { "label": "Packed On", "field": "packed_On" },
        // // 
        // { "label": "MRP(₹)", "field": "mrp" },
        { "label": "Created Date", "field": "createddate" },
        { "label": "Created Time", "field": "createdtime" },
        { "label": "Created By", "field": "createbyname" },
        // { "label": "Updated By", "field": "updatedbyname" },
        { "label": "Action", "field": "action","subaction1":<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"/><circle cx="12" cy="12" r="3"/></svg> }
      ];


      const sumofkeys=[{"first":'mrp'} ]

    return(
    
        <Mydatatable
        head={header}
        title={"Product BarCode"}
        axiostabledata={'productbarcode'}
        Add={"Add"}
        Create={'/ProductBarCode/Create'}
        Edit={"/ProductBarCode/Print"}
        View={"/ProductBarCode/Print"}
        Sumofkeys={sumofkeys}
        />
    )
}