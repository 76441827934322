import axios from 'axios';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import authHeader from '../../services/auth-header';
import WareHouseService from '../../services/WareHouseService';
import Select from 'react-select';
import { BASE_URL } from '../../services/Api';
import Swal from 'sweetalert2';

export default function WareEdit() {
  const { id } = useParams();
  const { pointindex } = useParams();
  const navigate = useNavigate();
  const [posts, setPosts] = useState([]);
  const [branch, setBranch] = useState('');
  const [branchvalue, setBranchvalue] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const applyFontSizeTransition = (elementId) => {
    const element = document.getElementById(elementId);
    element.style.transition = 'font-size 2s';
    element.style.fontSize = '30px';
    element.style.fontWeight = '600';
    // font-weight: 600;
    setTimeout(() => {
       
      document.getElementById("typer1").classList.add('promote');
      element.style.fontSize = '24px';
    }, 1000);
  };
  useEffect(() => {
    WareHouseService.getWareEdit(id).then((response) => {

      setPosts({
        name: response.data.name,
        location: response.data.location,
        type: response.data.type
      });
      setBranch({ "id": response.data.branch.id });
      setBranchvalue( response.data.branch.branchname);
    })
  }, []);
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setPosts(values => ({ ...values, [name]: value }))
  };

  const submitform = () => {

    const warehouse = {
     posts,
      branch
    }

    const typecheck = posts.type;
    
    if(typecheck == 1){
      setIsLoading(true); 

      WareHouseService.getwarehouse().then((response) => {
        const typevalue = [];
        response.data.forEach(user =>
          typevalue.push(user.type)
        );
        const data = typevalue.includes(typecheck);
        if (data == true) {
          alert("Primary Warehouse Already Exist In Database");
        } else {
          axios.put(BASE_URL + `warehouse/update/` + id, posts, {
            headers: authHeader()
          }).then((response) => {
            navigate(`/warehouse${pointindex}`);
          }) .catch(error => {
            console.error('Error adding  :', error);
            if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `
                <div>
                  <p id="issueText">Why do I have this issue?</p>
                  <ul>
              
                  <li id="typer1">You might have an internet issue</li>
                  </ul>
                </div>
              `,
              didOpen: () => {
                applyFontSizeTransition('issueText');
              }
            });
          }
          })
          .finally(() => {
            setIsLoading(false); 
          });
        }
      })
    } else{      setIsLoading(true); 

    axios.put(BASE_URL + `warehouse/update/` + id, posts, {
      headers: authHeader()
    }).then((response) => {
      navigate(`/warehouse/${pointindex}`);
    }) .catch(error => {
      console.error('Error adding  :', error);
      if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `
          <div>
            <p id="issueText">Why do I have this issue?</p>
            <ul>
        
            <li id="typer1">You might have an internet issue</li>
            </ul>
          </div>
        `,
        didOpen: () => {
          applyFontSizeTransition('issueText');
        }
      });
    }
    })
    .finally(() => {
      setIsLoading(false); 
    });
  }
  }
  return (
    <div>

      <h1>Edit WAREHOUSE</h1>
      <Link className='btn btn-primary' to={`/warehouse/${pointindex}`}>Back</Link>
      <Form className='product'>
        <Row className="mb-1">
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">WareHouse Name</Form.Label>
            <Form.Control type="text" name='name' value={posts.name} onChange={handleChange} placeholder="Enter WareHouse Name" />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Location</Form.Label>
            <Form.Control type="text" name='location' value={posts.location} onChange={handleChange} placeholder="Enter Your City" />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Branch</Form.Label>
            <Form.Control type="text" value={branchvalue} readOnly/>
            </Form.Group>
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">WareHouse Type</Form.Label>
            <Form.Select name='type' value={posts.type} onChange={handleChange}>
              <option >--Select--</option>
              <option value="1">Primary</option>
              <option value="2">Secondary</option>

            </Form.Select>
          </Form.Group>
        </Row>
       
        <Button variant="primary" style={{width:'auto'}} onClick={submitform} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Submit'}
        </Button>
      </Form>

    </div>
  )
}
