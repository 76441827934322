import axios from "axios";
import { MDBCard } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../services/Api";
import authHeader from "../services/auth-header";
import Swal from "sweetalert2";
import AuthService from "../services/auth.service";

export default function View() {
    const { id } = useParams();
    const { pointindex } = useParams();

    const [data, setdata] = useState('');
    const [supplier, setsupplier] = useState('');

    const getcontractmanagement = () => {
        axios.get(BASE_URL + 'cm/' + id, {
            headers: authHeader()
        }).then(res => {
            console.log('mello', res.data)
            setdata(res.data);
            setsupplier(res.data.supplier);
        })
    }
    const navigate = useNavigate();
    useEffect(() => {
        getcontractmanagement();
    }, []);

    const getItem = (id) => {

        // DistrbutorService.getDelete(id).then(res => {
        //   window.location.reload();
        // })
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {

                axios.delete(BASE_URL + 'cm/' + id, {
                    headers: authHeader()
                }).then(res => {
                    //   window.location.reload();
                    navigate('/ContractManagement/Table/1')
                })
                Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                )
            }
        })

    };
    const user1 = AuthService.getCurrentUser();
    return (
        <MDBCard className="company">
            <div className='d-flex bd-highlight'>
                <div className="card-header card-title w-100">View Contract Management</div>
                <Link to={`/ContractManagement/Update/${id}/${pointindex}`}><Button className='btn btn-sm btn-secondary'>Update</Button></Link>
                {/* <a class="delete btn btn-sm  red btn"  onClick={()=>getItem(id)}  ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class=" feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg></a> */}
                {user1.roles[0] === 'ROLE_ADMIN' && <Link   ><Button onClick={() => getItem(id)} className='btn btn-sm btn-secondary'>Delete</Button></Link>}

                <Link to={`/ContractManagement/Table/${pointindex}`}><Button className='btn btn-sm btn-secondary'>Back</Button></Link>

            </div>
            <Form className="product1">
                <Row className="mb-1">
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Supplier Name</Form.Label>
                        <Form.Control type="text" value={supplier.companyname} readOnly />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">From Date</Form.Label>
                        <Form.Control type="text" value={data.fromdate} readOnly />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">To Date</Form.Label>
                        <Form.Control type="text" value={data.todate} readOnly />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Taxable Amount</Form.Label>
                        <Form.Control type="text" placeholder="Enter Taxable Amount" value={data.taxableamount} autoComplete="off" />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Tax Amount</Form.Label>
                        <Form.Control type="text" placeholder="Enter Tax Amount" value={data.taxamount} autoComplete="off" />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Total Amount</Form.Label>
                        <Form.Control type="text" placeholder="Enter Total Amount" value={data.totalamount} autoComplete="off" />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Document : &nbsp;</Form.Label>
                        <a style={{ color: 'white' }} href={`http://43.225.54.138:8080/scm/${data.doclocation}`} download target="_blank" ><span style={{ color: 'white', border: '0.5px solid #ededed', padding: '6px', background: '#007bff', borderRadius: '6px', cursor: 'pointer' }}>Download PDF</span></a>
                        {/* <a href = {data.pdf} target = "_blank">Download Pdf</a> */}
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Contract Type</Form.Label>
                        <Form.Control type="text"  name="contracttype" value={data.contracttype}  autoComplete="off" />
                    </Form.Group>
                    <Form.Group as={Col} md="12" controlId="formBasicName">
                        <Form.Label className="label">Remarks</Form.Label>
                        <Form.Control as="textarea" rows={3} name='address' value={data.remarks} placeholder='Enter Remarks' autoComplete="off" />
                    </Form.Group>
                </Row>
            </Form>
        </MDBCard>
    )
}