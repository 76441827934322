import axios from "axios";
import { MDBCard } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Accordion, Button, Col, Form, Row, Table } from "react-bootstrap";
import { BASE_URL } from "../services/Api";
import authHeader from "../services/auth-header";
import Select from "react-select";
import { Link, useNavigate, useParams } from "react-router-dom";
import ProductService from "../services/ProductService";
import { changecostsales, changesalescalmeasurement, changesalesdiscount, changesalesqty, changesalessqpbmeasurement, changesalestext, changeunitofMeasurementsales, getsalesdatatoload, removefromcartfromsales, salesaddtocart } from "../../redux/Action/Sales";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";

export default function RetailerCreatesales() {
    const { id } = useParams();

    const [warehousedata, setwarehousedata] = useState('');
    const [warehouse, setwarehouse] = useState('');
    const [branchdata, setbranchdata] = useState('');
    const [branch, setbranch] = useState('');
    const [retailerdata, setretailerdata] = useState('');
    const [retailer, setretailer] = useState('');
    const [ackdate, setackdate] = useState(new Date());
    const [buyerorderno, setbuyerorderno] = useState('');
    const [invoiceno, setinvoiceno] = useState('');
    const [invoicedate, setinvoicedate] = useState('');
    const [type, settype] = useState('b2b');
    const [status, setstatus] = useState('');
    const [termsofdelivery, settermsofdelivery] = useState('');
    const [deliverynoteno, setdeliverynoteno] = useState('');
    const [destination, setdestination] = useState('');
    const [packaginglistno, setpackaginglistno] = useState('');
    const [gstno, setgstno] = useState('');
    const [panno, setpanno] = useState('');
    const [ewaybillno, setewaybillno] = useState('');
    const [grnno, setgrnno] = useState('');
    const [ackno, setackno] = useState('');
    const [lrno, setlrno] = useState('');
    const [udyamno, setudyamno] = useState('');
    const [msmeno, setmsmeno] = useState('');
    const [productdata, setproductdata] = useState([]);
    const [boxProductDiscount, setboxProductDiscount] = useState('');
    const [schemeboxProductDiscount, setschemeboxProductDiscount] = useState('');
    const [kgProductDiscount, setkgProductDiscount] = useState('');
    const [schemekgProductDiscount, setschemekgProductDiscount] = useState('');
    const [corporaetProductDiscount, setcorporaetProductDiscount] = useState('');
    const [schemecorporateProductDiscount, setschemecorporateProductDiscount] = useState('');
    const [cookerProductDiscount, setcookerProductDiscount] = useState('');
    const [schemecookerProductDiscount, setschemecookerProductDiscount] = useState('');
    const [noshProductDiscount, setnoshProductDiscount] = useState('');
    const [schemenoshProductDisocunt, setschemenoshProductDisocunt] = useState('');
    const [taxtype, settaxtype] = useState('');
    const [shippingcharge, setShipping] = useState(0);
    const [round, setRound] = useState(0);
    const [bstateid, setbstateid] = useState('');
    const [dstateid, setdstateid] = useState('');
    const [paymentTerms, setpaymentTerms] = useState('');
    const [getaddresss,setaddress]=useState([])
    const [deliveryAddress,setdelvieryaddress]=useState([]);
    const [lrndate,setlrndate] = useState('');
    const [edd,setedd] = useState('');
    const [grndate,setgrndate] = useState('');
    const[dispatchedthrough,setdispatchedthrough]=useState('');
    const[totalnopkg,settotalnopkg]=useState('');

    let dispatch = useDispatch();
    const navigate = useNavigate();
    let salesItems = useSelector((state) => state.salesdata);
    let gross_Amount = salesItems.length && salesItems.map(item => Math.round(item.total)).reduce((prev, next) => Math.round(prev - (-next)));
    let gst = salesItems.length && salesItems.map(item => (item.gstvalue)).reduce((prev, next) => (prev - (-next)));
// console.log('mello',salesItems)
    let aa = [];
    salesItems.map(user => {
        let igst_value = user.igst;
        let total_value = user.total;
        let total_amou = ((Number(shippingcharge) / Number(gross_Amount)) * Number(total_value));
        let add_amou = Number(total_amou) - (-Number(total_value));
        let final_amou = ((Number(add_amou) / 100) * Number(igst_value)).toFixed(2);
        aa.push(final_amou)

        // console.log("mello",((Number(add_amou)/100)*Number(igst_value)).reduce((prev, next) => Math.round(prev - (-next))) );
    })
    let total_per = aa.length && aa.map(user => user).reduce((prev, next) => (prev - (-next)));

    let grossAmount = Number(gross_Amount) + Number(shippingcharge);
    let grandTotal = Number(gross_Amount) + Number(shippingcharge) + Number(round) + Number(total_per);

    let igstTax = Number(total_per);
    let cgstTax = (Number(total_per) / 2);
    let sgstTax = (Number(total_per) / 2);


    function roundData(e){
        if(e){
            let gross_Amount = salesItems.length && salesItems.map(item => Math.round(item.total)).reduce((prev, next) => Math.round(prev - (-next)));
            let gst = salesItems.length && salesItems.map(item => (item.gstvalue)).reduce((prev, next) => (prev - (-next)));
        
            let aa = [];
            salesItems.map(user=>{
                let igst_value = user.igst;
                let total_value = user.total;
                let total_amou = ((Number(shippingcharge)/Number(gross_Amount))* Number(total_value));
                let add_amou = Number(total_amou) - (-Number(total_value));
                let final_amou = ((Number(add_amou)/100)*Number(igst_value)).toFixed(2); 
                aa.push(final_amou)
        
                // console.log("mello",((Number(add_amou)/100)*Number(igst_value)).reduce((prev, next) => Math.round(prev - (-next))) );
            })
            let total_per = aa.length && aa.map(user=>user).reduce((prev, next) => (prev - (-next)));
            
        
            let grossamount = Number(gross_Amount) + Number(shippingcharge);
            let grandTotal = Number(gross_Amount)  + Number(shippingcharge)  + Number(total_per);
             let grand_Total = Math.ceil(grandTotal);
        
             if(grand_Total > grandTotal){
                 let rou_nd = (Number(grand_Total) - Number(grandTotal)).toFixed(2);
                 setRound(rou_nd);
             } else{
                 setRound(0)
             }
               
              
        } else if(e==''){
            setRound(0)
        }
    }

    const getSalesOrder = () => {

        axios.get(BASE_URL + 'dc/' + id, {
            headers: authHeader()
        }).then(res => { setdelvieryaddress(res.data.deliveryAddress)
            // console.log("mello",res.data);
            setaddress(res.data.retailer.deliveryAddress);
            setbranchdata(res.data.branch.branchname);
            setbranch({ "id": res.data.branch.id });
            setbstateid(res.data.branch.states.id);
            setvoucher(res.data.voucherid)
            setwarehousedata(res.data.warehouse);
            setwarehouse({ "id": res.data.warehouse.id });
            setretailerdata(res.data.retailer);
            setretailer({ "id": res.data.retailer.id });
            setpaymentTerms(res.data.paymentTerms);
            setdstateid(res.data.retailer.stateid)
            setdestination(res.data.destination);
            settermsofdelivery(res.data.termsofdelivery);
            settaxtype(res.data.taxtype);
            setboxProductDiscount(res.data.retailer.boxProductDiscount);
            setschemeboxProductDiscount(res.data.retailer.schemeboxProductDiscount);
            setkgProductDiscount(res.data.retailer.kgProductDiscount);
            setschemekgProductDiscount(res.data.retailer.schemekgProductDiscount);
            setcorporaetProductDiscount(res.data.retailer.corporaetProductDiscount);
            setschemecorporateProductDiscount(res.data.retailer.schemecorporateProductDiscount);
            setcookerProductDiscount(res.data.retailer.cookerProductDiscount);
            setschemecookerProductDiscount(res.data.retailer.schemecookerProductDiscount);
            setnoshProductDiscount(res.data.retailer.noshProductDiscount);
            setschemenoshProductDisocunt(res.data.retailer.schemenoshProductDisocunt);
        })
    }

    /* get Product Data */

    let getProductData = () => {

        axios.get( BASE_URL + 'product/page/0/100/asc/id/%20',{
            headers:authHeader()
        }).then(res=>{

            setproductdata((res.data.Index).map(use => ({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code + ' - ' + use.short_Name })));
        })

    };

    const customFilter = (e) => {
        let name = e;
        axios.get( BASE_URL + 'product/page/0/100/asc/id/'+name,{
            headers:authHeader()
        }).then(res=>{
         setproductdata((res.data.data).map(use=>({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code + ' - ' + use.short_Name })))
        })
    }

    let date_value = new Date(ackdate);
    const defaultValue = date_value.toLocaleDateString('en-CA');
    useEffect(() => {
        getSalesOrder();
        getProductData();
        setackdate(defaultValue);
        dispatch(getsalesdatatoload(id));
    }, []);

    let addData = (e) => {
        let name = e.value;
        let filteredItems = salesItems.filter(item=> item.product.id == name );
        if(filteredItems.length == "0"){
        dispatch(salesaddtocart(name, ({
            "schemeboxProductDiscount": schemeboxProductDiscount,
            "schemekgProductDiscount": schemekgProductDiscount,
            "schemecorporateProductDiscount": schemecorporateProductDiscount,
            "schemecookerProductDiscount": schemecookerProductDiscount,
            "schemenoshProductDisocunt": schemenoshProductDisocunt,
            "kgProductDiscount": kgProductDiscount,
            "boxProductDiscount": boxProductDiscount,
            "corporaetProductDiscount": corporaetProductDiscount,
            "cookerProductDiscount": cookerProductDiscount,
            "noshProductDiscount": noshProductDiscount
        })));
        setShipping("0");
    } else {
        Swal.fire({
            title: 'This Product is Already Added',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonColor: 'red'
        })
    }
    }

    /* get Shipping Charges */
    let settingship = (e) => {
        if (e == "") {

            setShipping("");
        } else {
            setShipping(e);
        }
    }

    /* get Round Value */
    const settinground = (e) => {

        if (e == "") {

            setRound("");
        } else {


            setRound(e);
        }

    };

    const SGSTcheck = () => {
        let aa = 0

        if (taxtype == "withtax") {

            if (bstateid === dstateid) {

                aa = sgstTax;

            } else {
                aa = 0;
            }

        } else {
            aa = 0;
        }

        return aa;

    }

    const cgstcheck = () => {

        let bb = 0

        if (taxtype == "withtax") {

            if (bstateid === dstateid) {

                bb = cgstTax;

            } else {

                bb = 0;

            }

        } else {

            bb = 0;

        }
        return bb;
    }

    const igstcheck = () => {
        let cc = 0;

        if (taxtype == "withtax") {
            if (bstateid === dstateid) {
                cc = 0;
            } else {
                cc = igstTax;
            }
        } else {
            cc = 0;
        }
        return cc;
    }
    const [voucherid, setvoucher] = useState([])

    const submitdata = () => {
        let igst = igstcheck();
        let cgst = cgstcheck();
        let sgst = SGSTcheck();
        let grandtotal = grandTotal;
        let roundofvalue = round;
        let dcId = id;
        let salesdata = {
            warehouse, ackdate, warehouse,voucherid, retailer, buyerorderno, invoiceno, invoicedate, status, taxtype, termsofdelivery, deliverynoteno,
            destination, packaginglistno, gstno, panno, ewaybillno, grnno, ackno, lrno, udyamno, msmeno, salesItems, dcId,lrndate,edd,grndate,dispatchedthrough,totalnopkg,
            igst, cgst, sgst, grandtotal,deliveryAddress:Number(deliveryAddress), grossAmount, shippingcharge, roundofvalue, branch, paymentTerms
        }
        axios.post(BASE_URL + 'sales/dc/' + id, salesdata, {
            headers: authHeader()
        }).then(res => {
            navigate('/Sales/Table/1')
        })

    }
    return (
        <MDBCard className="company">
            <h1>Sales Create</h1>
            <Link to='/Sales/Table/1' className="btn btn-primary">Back</Link>
            <Form className="product1">
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Sales Data</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Branch Name</Form.Label>
                                    <Form.Control type="text" value={branchdata} readOnly />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Warehouse Name</Form.Label>
                                    <Form.Control type="text" value={warehousedata.name} readOnly />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Date</Form.Label>
                                    <Form.Control type="date" value={ackdate} onChange={(e) => setackdate(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                <Form.Label className="label">Vouchers Series</Form.Label>
                                <Form.Control type='text' value={voucherid} name="voucherid" onChange={(e) => setpaymentTerms(e.target.value)} autoComplete="off" />

                                {/* <Select options={customerData} onChange={(e) => onChangeCustomer(e)} /> */}
                                    {/* <Select options={distributordata12} onInputChange={(inputValue) => setdisword12(inputValue)} onChange={(options) => Onchangevoucher(options)} /> */}

                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Terms of Payment</Form.Label>
                                    <Form.Control type='text' value={paymentTerms} name="paymentTerms" onChange={(e) => setpaymentTerms(e.target.value)} autoComplete="off" />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Customer Name</Form.Label>
                                    <Form.Control type="text" value={retailerdata.tradeName} readOnly />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Buyer Order</Form.Label>
                                    <Form.Control type="text" autoComplete="off" onChange={(e) => setbuyerorderno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Invoice No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" onChange={(e) => setinvoiceno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Invoice Date</Form.Label>
                                    <Form.Control type="date" onChange={(e) => setinvoicedate(e.target.value)} />
                                </Form.Group>
                                {/* <Form.Group as={Col} md="8" controlId="formBasicName">
                                    <Form.Label className="label">Owner</Form.Label>
                                    <Form.Control type="text" />
                                </Form.Group> */}
                                {/* <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Type</Form.Label>
                                    <Form.Select value={type} onChange={(e) => settype(e.target.value)}>
                                        <option value="b2b">B2B</option>
                                        <option value="b2c">B2C</option>
                                    </Form.Select>
                                </Form.Group> */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Quotation Status</Form.Label>
                                    <Form.Select value={status}>
                                        <option value='true'>Open</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Tax Type</Form.Label>
                                    <Form.Select value={taxtype} >
                                        <option>--Select--</option>
                                        <option value='withtax'>With Tax</option>
                                        <option value='withouttax'>Without Tax</option>
                                    </Form.Select>
                                </Form.Group>


                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Billing Address</Accordion.Header>
                        <Accordion.Body>
                            {/* <Row className="mb-1">
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Customer Address</Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Customer Details</Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Address : -</Form.Label>
                                    <span>&nbsp;{customerAll.address}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Name : -</Form.Label>
                                    <span>&nbsp;{customerSubContactsdata == "" ? customerAll.cname : customerSubContactsdata.contactname}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">City : -</Form.Label>
                                    <span>&nbsp;{customerAll.city}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Phone No : -</Form.Label>
                                    <span>&nbsp;{customerSubContactsdata == "" ? customerAll.phonenumber : customerSubContactsdata.phoneno}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Country : -</Form.Label>
                                    <span>{"India"}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Email Id : -</Form.Label>
                                    <span> {customerSubContactsdata == "" ? customerAll.email : customerSubContactsdata.email}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">State : -</Form.Label>
                                    <span>{supplierstate}</span>
                                </Form.Group>
                            </Row> */}
                            <b><span className="">Delivery Address</span></b> <br></br> <br></br>
                                <Row className="mb-1">
                               {/* <select className="form-select dd">
                                <option className="extrvagnze">select Address</option>
                               
                                {
                                    disdel.map((item)=>{
                                        return<>
                                          <option className="extrvagnze">Address:{item.delivery_address} ,State:-{item.state} &nbsp;,Pin-code:-{item.pincode}</option>
                                        </>
                                    })
                                }
                               </select> */}
      <select  className="form-select" value={deliveryAddress} onChange={(e)=>setdelvieryaddress(e.target.value)}>
        <option>select data</option>
        {
            getaddresss.map((item)=>{
                return<>
                <option value={item.id}>{item.delivery_address}</option>
                </>
            })
        }
      </select>
                                
                            </Row>



                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>Details</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Terms of Delivery</Form.Label>
                                    <Form.Control type="text" autoComplete="off" onChange={(e) => settermsofdelivery(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Delivery Note No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" onChange={(e) => setdeliverynoteno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Destination</Form.Label>
                                    <Form.Control type="text" autoComplete="off" onChange={(e) => setdestination(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Packaging List No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" onChange={(e) => setpackaginglistno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">GST No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" onChange={(e) => setgstno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Pan No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" onChange={(e) => setpanno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Eway Bill No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" onChange={(e) => setewaybillno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">GRN No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" onChange={(e) => setgrnno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Dispatched Through</Form.Label>
                                    <Form.Control type="text" autoComplete="off" onChange={(e) => setdispatchedthrough(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Total No PKG </Form.Label>
                                    <Form.Control type="text" autoComplete="off" onChange={(e) => settotalnopkg(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Account No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" onChange={(e) => setackno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">LRN No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" onChange={(e) => setlrno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Udyam No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" onChange={(e) => setudyamno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">MSME No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" onChange={(e) => setmsmeno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">LRN Date</Form.Label>
                                    <Form.Control type="date"  onChange={(e) => setlrndate(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">EDD</Form.Label>
                                    <Form.Control type="date"  onChange={(e) => setedd(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">GRN Date</Form.Label>
                                    <Form.Control type="date"  onChange={(e) => setgrndate(e.target.value)} />
                                </Form.Group>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>

                </Accordion>
                <br></br>
                <Row className="mb-1">
                    <Form.Group as={Col} md="12" controlId="formBasicName">
                        <Form.Label className="label">Product</Form.Label>
                        <Select options={productdata} onChange={(e) => addData(e)} onInputChange={(e) => customFilter(e) } />
                    </Form.Group>
                </Row>
                <br></br>
                <Table striped responsive hover size="sm">
                    <thead className='p-3 mb-2 bg-success text-white'>
                        <tr>
                            <th>PRODUCT&nbsp;NAME</th>
                            <th>UOM</th>
                            <th >QTY(PCS)</th>
                            <th >QTY(KG)</th>
                            <th >Rate</th>
                            <th >UOM</th>
                            <th >Trade&nbsp;Discount</th>
                            <th>Gross&nbsp;Amount&nbsp;(₹)</th>
                            <th >Scheme&nbsp;Discount&nbsp;%</th>
                            <th >NET&nbsp;AMOUNT&nbsp;(₹)</th>
                            <th >GST&nbsp;Value</th>
                            <th >Amount</th>
                            <th >Action</th>
                        </tr>
                    </thead>
                    <tbody style={{ overflow: "scroll" }}>
                        {salesItems.map(item =>
                                <tr key={item.product.id}>
                                    <td>
                                        <Form.Group as={Col} md="12" >
                                            {/* <Form.Control type="text" value={(item.productName ? item.productName : item.product.productName)} size="sm" /> */}
                                            <textarea type="text"   className="form-control textcontrol" value={(item.productName ? item.productName : item.product.productName)} size="sm" ></textarea>

                                        </Form.Group>
                                        <Form.Control as="textarea" onChange={(e) => { dispatch(changesalestext(e.target.value, item)) }} value={item.text} rows={2} className='form-control' />
                                    </td>
                                    <td><Form.Group as={Col} md="12">
                                        <Form.Select disabled={item.unitofmeasurement == "box" ? true:item.productType !== "kgproduct" ? true:false } value={item.unitofmeasurement} onChange={(e) => { dispatch(changeunitofMeasurementsales(e.target.value, item, item.unitofmeasurement)); }} size="sm">
                                            <option>{item.unitofmeasurement == "box" ? "PCS":item.productType !== "kgproduct" ? 'PCS':"Select"}</option>
                                            <option value="pcs">PCS</option>
                                            <option value="kg">KG</option>
                                        </Form.Select>
                                    </Form.Group>
                                    </td>

                                    <td>
                                        <Form.Group as={Col} md="12">
                                            <Form.Control type="number" min="0" size="sm" onChange={(e) => { dispatch(changesalessqpbmeasurement(e.target.value, item, taxtype, item.unitofmeasurement));roundData(e.target.value) }}  value={item.salesquantity} disabled={item.unitofmeasurement == "" ? true : false} />
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group as={Col} md="12">
                                            <Form.Control type="text" min="0" onChange={(e) => { dispatch(changesalesqty(e.target.value, item, taxtype, item.unitofmeasurement));roundData(e.target.value) }} value={Number(item.salesquantitykgs)} disabled={item.unitofmeasurement == "box" ? true : false || item.unitofmeasurement == "" ? true : false} size="sm"  />
                                        </Form.Group>
                                    </td>
        
                                    <td>
                                        <Form.Group as={Col} md="12">
                                            <Form.Control type="number" min="0" size="sm" onChange={(e) => { dispatch(changecostsales(e.target.value, item, taxtype));roundData(e.target.value) }} value={Number(item.dlp)} />
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group as={Col} md="12">
                                            <Form.Select disabled={item.calunitofmeasurement == "box" ? true : item.productType !== "kgproduct" ? true : false} value={item.calunitofmeasurement} onChange={(e) => { dispatch(changesalescalmeasurement(e.target.value, item, item.calunitofmeasurement)); roundData(e.target.value)}} size="sm">
                                                <option>{item.calunitofmeasurement == "box" ? "PCS" : item.productType !== "kgproduct" ? 'PCS' : "Select"}</option>
                                                <option value="pcs">PCS</option>
                                                <option value="kg">KG</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group as={Col} md="12">
                                            <Form.Control type="text" min="0" size="sm" value={Number(item.tradeDiscount)} />
                                        </Form.Group>
                                    </td>
                                    <td><Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" value={Number(item.grossamount)} size="sm" readOnly requied />
                                    </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group as={Col} md="12">
                                            <Form.Control type="number" min="0" onChange={(e) => { dispatch(changesalesdiscount(e.target.value, item, taxtype));roundData(e.target.value) }} value={Number(item.schemeDiscount)} size="sm" />
                                        </Form.Group>
                                    </td>
                                    <td><Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" value={Number(item.netAmount)} size="sm" readOnly requied />
                                    </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group as={Col} md="12">
                                            <Form.Control type="number" min="0" value={Number(item.gstvalue)} size="sm" />
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group as={Col} md="12">
                                            <Form.Control type="number" min="0" value={Number(item.total)} size="sm" />
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Form.Group as={Col} md="12">
                                            <Form.Control type='button' className='btn btn-danger' onClick={() => {dispatch(removefromcartfromsales(item.product.id));roundData('')}} value="X" size="sm" />
                                        </Form.Group>
                                    </td>
                                </tr>
                                
                        )}
                    </tbody>
                    <tbody>
                    <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Packing&nbsp;&&nbsp;Forwarding</td>
                            <td><Form.Group as={Col} md="12">
                                <Form.Control type="number" value={shippingcharge} onChange={(e) => settingship(e.target.value)} style={{ height: 30 }} />
                            </Form.Group></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Net&nbsp;Amount</td>
                            <td> <Form.Group as={Col} md="12">
                                <Form.Control type="number" value={grossAmount?((grossAmount).toFixed(2)):"0"} style={{ height: 30 }} readOnly />
                            </Form.Group></td>
                            <td></td>
                        </tr>

                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>IGST</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={igstcheck()} style={{ height: 30 }} requied />
                                </Form.Group>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>SGST</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={SGSTcheck()} style={{ height: 30 }} requied />
                                </Form.Group>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>CGST</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={cgstcheck()} style={{ height: 30 }} requied />
                                </Form.Group>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Round&nbsp;off&nbsp;Value</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={round} onChange={(e) => settinground(e.target.value)} style={{ height: 30 }} />
                                </Form.Group>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Grand&nbsp;Total</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={grandTotal?((grandTotal).toFixed(2)):"0"} style={{ height: 30 }} readOnly />
                                </Form.Group>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </Table>
                <Button variant="primary" type="button" onClick={submitdata}>
                    Submit
                </Button>
            </Form>
        </MDBCard>
    )
}