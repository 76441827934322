import React from 'react'
import { Mydatatable } from '../Product/Mydatatable'
import { getroles } from '../../Login/Login';

const Meettable = () => {
    let header = [
        { "label": "Id", "field": "id" },
        { "label": "Meeting Date", "field": "meetingdate" },
        { "label": "Meeting Title", "field": "meetingtitle" },
        // { "label": "Staff Name", "field": "staff_name" },
        
        // 
        { "label": "Created Date", "field": "createddate" },
        { "label": "Created Time", "field": "createdtime" },
        { "label": "Created By", "field": "createbyname" },
        { "label": "Updated Date", "field": "updateddate" },
        { "label": "Updated Time", "field": "updatedtime" },
        { "label": "Updated By", "field": "updatedbyname" },
    //  { "label": "Remarks", "field": "remarks" },
        { "label": "Action", "field": "action","subaction1":<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"/><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"/></svg> }
      ]; 
      
      const sumofkeys=[{"first":'id'} ]
      let header1 = [
        { "label": "Id", "field": "id" },
        { "label": "Meeting Date", "field": "meetingdate" },
        { "label": "Meeting Title", "field": "meetingtitle" },
        // { "label": "Staff Name", "field": "staff_name" },
        
        // 
        { "label": "Created Date", "field": "createddate" },
        { "label": "Created Time", "field": "createdtime" },
        { "label": "Created By", "field": "createbyname" },
        { "label": "Updated Date", "field": "updateddate" },
        { "label": "Updated Time", "field": "updatedtime" },
        { "label": "Updated By", "field": "updatedbyname" },
    //  { "label": "Remarks", "field": "remarks" },
       ]; 
      
       
// {id: 239, brandName: 'Neelam'}
 
let ram = getroles();
console.log(ram)
  return (
   
    <Mydatatable
    head={ram=='ROLE_ADMIN'? header:header1}
    title={"Minutes of Meeting"}
    axiostabledata={'meeting'}
    Add={"Add"}
    // Edit={"/pedit"}
    Edit={"/Meetingview"}
    Create={'/Meetingcreate'}
     Sumofkeys={sumofkeys}
    View={"/Meetingview"}
    />





  )
}

export default Meettable