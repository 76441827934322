import React from "react";
import { Mydatatable } from "../../Master/Product/Mydatatable";

export default function Table(){
    let header = [
        { "label": "Id", "field": "id" },
        { "label": "Staff Name", "field": "staffname" },
    
        // { "label": "Status", "field":"status" },
        // { "label": "Serial No  ", "field": "grandtotal" },
        // { "label": "State", "field": "state_name" },
        // { "label": "To Date", "field": "net_Amount" },
     { "label": "Remarks", "field": "remarks" },
     { "label": "Date", "field": "date" },
     { "label": "Created Time", "field": "createdtime" },
     { "label": "Created By", "field": "createbyname" },
     { "label": "Updated Date", "field": "updateddate" },
     { "label": "Updated Time", "field": "updatedtime" },
     { "label": "Updated By", "field": "updatedbyname" },
        { "label": "Action", "field": "action","subaction1":<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"/><circle cx="12" cy="12" r="3"/></svg> }
      ]; 

      

      const sumofkeys=[{"first":'grandtotal'},{'first':'net_Amount'} ]
    return(
        <Mydatatable
        head={header}
        title={"Asset Request"}
        axiostabledata={'assetreqform'}
        Add={"Add"}
        Create={'/Asset Request Form/Create'}
        Edit={"/Asset Request Form/View"}
        View={"/Asset Request Form/View"}
        Sumofkeys={sumofkeys}
        />
    )
}