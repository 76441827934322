import React, { useEffect, useState } from "react";
import WareHouseService from "../services/WareHouseService";
import { PrimaryOrderComponent } from "./PrimaryOrderComponent";

function Primaryorderreports() {

  let header = [
    { label: "Product Name", field: "productname" },
    { label: "Primary Order Id", field: "workorderid" },
    { label: "Unit of Measurement", field: "unittomeasurement" },
    { label: "QTY(PCS)", field: "measurement" },
    { label: "QTY(KG)", field: "qty" },
    { label: "Rate", field: "dlp" },

    { label: "Gross Amount", field: "grossamount" },
    { label: "Trade Discount", field: "discount" },
    { label: "Scheme Discount", field: "discount1" },
    { label: "Net Amount", field: "netamount" },


    { label: "GST Value", field: "gstvalue" },
    { label: "Total", field: "total" },
    // { label: "Rate", field: "outwardrate" },
    // { label: "Value", field: "outwardamount" },


    // { label: "Quantity", field: "closingkgqty" },
    // { label: "(Alt.Units)", field: "closingpcsqty" },
    // { label: "Rate", field: "closingstockrate" },
    // { label: "Value", field: "closingamount" },
  ];
  const sumofkeys = [{ first: "closingstock" }];
  return (
    <PrimaryOrderComponent
      head={header}
      title={"Primary Order Reports"}
      axiostabledata={""}
      Add={""}
      Create={""}
      Edit={""}
      View={""}
      Sumofkeys={sumofkeys}
    />
  );
}

export default Primaryorderreports;
