import axios from "axios";
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { BASE_URL } from "../services/Api";
import { useEffect } from "react";
import authHeader from "../services/auth-header";
import { addtocart, changecostpurchase, changepurchasediscount, changepurchaseqty, changepurchasetext, emptypurchasedata, getpurchasedatatoload, purchaseaddtocart, purchasecalmeasurement, purchasechangetext, purchasecostchange, purchasediscountchange, purchaseemptydata, purchasemeasurement, purchaseqtychange, purchaseqtykgs, purchaseqtypcs, removecart, removefromcartfrompurchase } from "../../redux/Action/Purchase";
import { MDBCard } from "mdbreact";
import { Accordion, Button, Col, Form, Row, Table } from "react-bootstrap";
import Select from "react-select";
import ProductService from "../services/ProductService";
import Swal from "sweetalert2";


export default function Create1() {


    const [product, setproduct] = useState([]);
    const [supplierdata, setsupplierdata] = useState([]);
    const [supplierSubContactsdata, setsupplierSubContactsdata] = useState([]);
    const [purchasedate, setpurchasedate] = useState('');
    const [type, settype] = useState('b2b');
    const [status, setstatus] = useState('true');
    const [shippingaddress, setshippingaddress] = useState('');
    const [remarks, setremarks] = useState('');
    const [productdata, setproductdata] = useState([]);
    const [taxtype, settaxtype] = useState("withtax");
    const [invoiceno, setinvoiceno] = useState('');
    const [ewaybillno, setewaybillno] = useState('');
    const [otherreference, setotherreference] = useState('');
    const [supplierinvoiceno, setsupplierinvoiceno] = useState('');
    const [supplierinvoicedate, setsupplierinvoicedate] = useState('');
    const [warehouse, setwarehouse] = useState('');
    const [supplier, setsupplier] = useState('');
    const [supplierDetails, setsupplierDetails] = useState('');
    const [supplierSubContacts1, setsupplierSubContacts1] = useState('');
    const [supplierSubContactsDetails, setsupplierSubContactsDetails] = useState('');
    const [supplierstate_Zone, setsupplierstate_Zone] = useState('');
    const [branchdata, setbranchdata] = useState([]);
    const [branch, setbranch] = useState('');
    const [shippingcharge, setShipping] = useState(0);
    const [shippingfortax, setshippingfortax] = useState('');
    const [round, setRound] = useState(0);
    const [bstateid, setbstateid] = useState('');
    const [sstateid, setsstateid] = useState('');
    const [warehousehdata, setwarehousehdata] = useState([]);
    const [warehousehvalue, setwarehousehvalue] = useState([]);
    const [paymentTerms, setpaymentTerms] = useState('');
    const [voucherData, setvoucherData] = useState([]);
    const [voucherid, setvoucherid] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const applyFontSizeTransition = (elementId) => {
        const element = document.getElementById(elementId);
        element.style.transition = 'font-size 2s';
        element.style.fontSize = '30px';
        element.style.fontWeight = '600';
        // font-weight: 600;
        setTimeout(() => {

            document.getElementById("typer1").classList.add('promote');
            element.style.fontSize = '24px';
        }, 1000);
    };

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const purchaseItems = useSelector((state) => state.purchasedata);
    let net_Amount = purchaseItems.length && purchaseItems.map(item => Number(item.total)).reduce((prev, next) => (prev - (-next)).toFixed(2))
    let gst = purchaseItems.length && purchaseItems.map(item => (item.gstvalue)).reduce((prev, next) => (prev - (-next)));
console.log(purchaseItems)
    let aa = [];
    purchaseItems.map(user => {
        let igst_value = user.igst;
        let total_value = user.total;
        let total_amou = ((Number(shippingcharge) / Number(net_Amount)) * Number(total_value));
        let add_amou = Number(total_amou) - (-Number(total_value));
        let final_amou = ((Number(add_amou) / 100) * Number(igst_value)).toFixed(2);
        aa.push(final_amou)

        // console.log("mello",((Number(add_amou)/100)*Number(igst_value)).reduce((prev, next) => Math.round(prev - (-next))) );
    })
    let total_per = aa.length && aa.map(user => user).reduce((prev, next) => (prev - (-next)));


    let netAmount = Number(net_Amount) + Number(shippingcharge);
    let grandTotal = Number(net_Amount) + Number(shippingcharge) + Number(round) + Number(total_per);

    let igstTax = total_per == "" ? Number(gst) : Number(total_per);


    let cgstTax = total_per == "" ? (Number(gst) / 2) : (Number(total_per)) / 2;
    let sgstTax = total_per == "" ? (Number(gst) / 2) : (Number(total_per)) / 2;

    function roundData(e) {
        if (e) {
            let net_Amount = purchaseItems.length && purchaseItems.map(item => Number(item.total)).reduce((prev, next) => (prev - (-next)).toFixed(2))
            let gst = purchaseItems.length && purchaseItems.map(item => (item.gstvalue)).reduce((prev, next) => (prev - (-next)));

            let aa = [];
            purchaseItems.map(user => {
                let igst_value = user.igst;
                let total_value = user.total;
                let total_amou = ((Number(shippingcharge) / Number(net_Amount)) * Number(total_value));
                let add_amou = Number(total_amou) - (-Number(total_value));
                let final_amou = ((Number(add_amou) / 100) * Number(igst_value)).toFixed(2);
                aa.push(final_amou)

                // console.log("mello",((Number(add_amou)/100)*Number(igst_value)).reduce((prev, next) => Math.round(prev - (-next))) );
            })
            let total_per = aa.length && aa.map(user => user).reduce((prev, next) => (prev - (-next)));


            let netAmount = Number(net_Amount) + Number(shippingcharge);
            let grandTotal = Number(net_Amount) + Number(shippingcharge) + Number(total_per);

            let grand_Total = Math.ceil(grandTotal);

            if (grand_Total > grandTotal) {
                let rou_nd = (Number(grand_Total) - Number(grandTotal)).toFixed(2);
                setRound(rou_nd);
            } else {
                setRound(0)
            }


        } else if (e == '') {
            setRound(0)
        }
    }

    /* Get Branch Data */

    const getbranchdata = () => {

        axios.get(BASE_URL + 'branch/getAll', {
            headers: authHeader()
        }).then(res => {
            // console.log("mello",res.data)
            setbranchdata((res.data).map(user => ({ value: user.id, label: user.branchname })));
        })
    };

    /* Get Supplier All Data */

    const getsupplierdata = () => {

        axios.get(BASE_URL + 'supplier/getAll', {
            headers: authHeader()
        }).then(res => {
            setsupplierdata((res.data).map(user => ({ value: user.id, label: user.companyname })))
        })
    };

    /* get Product All */
    const getproductdata = () => {
        axios.get(BASE_URL + 'product/page/0/100/asc/id/%20', {
            headers: authHeader()
        }).then(res => {

            setproductdata((res.data.Index).map(use => ({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code + ' - ' + use.short_Name })));
        })
    };

    const customFilter = (e) => {
        let name = e;
        axios.get(BASE_URL + 'product/page/0/100/asc/id/' + name, {
            headers: authHeader()
        }).then(res => {
            setproductdata((res.data.data).map(use => ({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code + ' - ' + use.short_Name })))
        })
    };


    useEffect(() => {
        getbranchdata();
        getsupplierdata();
        getproductdata();
        dispatch(emptypurchasedata());
    }, []);


    /* Supplier Data Change */

    let onChangeSupplierData = (e) => {

        let name = e.value;

        setsupplier({ "id": e.value });

        axios.get(BASE_URL + 'supplier/getById/' + name, {
            headers: authHeader()
        }).then(res => {
            setsupplierSubContactsdata((res.data.supplierSubContacts).map(user => ({ value: user.id, label: user.contactname })));
            setsupplierDetails(res.data);
            // setsupplierstate_Zone(res.data.state_Zone.state_name);
            setsstateid(res.data.states.id);

        })

    };

    /* Supplier SubContact Change */
    let suppliersubcontactchange = (e) => {

        setsupplierSubContacts1({ "id": e.value });
        (supplierDetails.supplierSubContacts).map(user => {
            if (user.id == e.value) {
                setsupplierSubContactsDetails(user)
            }
        })

    }
    let onChangeBranch = (e) => {
        let name = e.value;
        setbranch({ "id": e.value });
        setwarehousehvalue("");

        axios.get(BASE_URL + 'branch/' + name, {
            headers: authHeader()
        }).then(res => {
            // console.log("mello",res.data)
            setbstateid(res.data.states.id)
        })

        axios.get(BASE_URL + 'warehouse/getAll', {
            headers: authHeader()
        }).then(res => {
            let branch_data = [];
            (res.data).map(user => {
                // let branchid = user.branch.id;
                if (user.branch !== null) {
                    if (user.branch.id === e.value) {
                        branch_data.push({ value: user.id, label: user.name })
                    }
                }

            })

            setwarehousehdata(branch_data)

        })
    };
    const onChangewarehouse = (e) => {
        setwarehouse({ "id": e.value })
        setwarehousehvalue({ value: e.value, label: e.label })
    };

    let date_value = new Date(purchasedate);
    const defaultValue = date_value.toLocaleDateString('en-CA');

    /* get Shipping Charges */
    let settingship = (e) => {
        if (e == "") {

            setShipping("");
        } else {

            setShipping(e);
        }

    };

    /* get Round Value */
    const settinground = (e) => {

        if (e == "") {

            setRound("");
        } else {


            setRound(e);
        }

    };

    const SGSTcheck = () => {
        let aa = 0

        if (taxtype == "withtax") {

            if (bstateid === sstateid) {

                aa = sgstTax;

            } else {
                aa = 0;
            }

        } else {
            aa = 0;
        }

        return aa;

    }

    const cgstcheck = () => {

        let bb = 0

        if (taxtype == "withtax") {

            if (bstateid === sstateid) {

                bb = cgstTax;

            } else {

                bb = 0;

            }

        } else {

            bb = 0;

        }
        return bb;
    }

    const igstcheck = () => {
        let cc = 0;

        if (taxtype == "withtax") {
            if (bstateid === sstateid) {
                cc = 0;
            } else {
                cc = igstTax;
            }
        } else {
            cc = 0;
        }
        return cc;
    }
    let addData = (e) => {
        const filteredItems = purchaseItems.filter(item => item.product.id === e.value);
        if (branch == "" || supplier == "") {
            Swal.fire({
                title: 'Please Branch or Supplier First Select',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonColor: 'red'
            })
        } else {
            if (filteredItems.length == "0") {
                dispatch(purchaseaddtocart(e.value));
                setShipping("0");
            } else {
                Swal.fire({
                    title: 'This Product is Already Added',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showConfirmButton: false,
                    showCancelButton: true,
                    cancelButtonColor: 'red'
                })
            }
        }
    };

    const submitdata = () => {
        setIsLoading(true);
        let igst = igstcheck();
        let cgst = cgstcheck();
        let sgst = SGSTcheck();
        let grandtotal = grandTotal;
        let roundofvalue = round;
        let supplierSubContacts
        if (supplierSubContacts1 !== '') {
            supplierSubContacts = supplierSubContacts1
        }

        const purchasesubmitdata = {
            warehouse, supplier, purchasedate, invoiceno, ewaybillno, otherreference, supplierinvoicedate, supplierinvoiceno, status, shippingaddress, remarks, supplierSubContacts, taxtype, purchaseItems,
            igst, cgst, sgst, grandtotal, netAmount, shippingcharge, roundofvalue, branch, paymentTerms,voucherid
        };

        axios.post(BASE_URL + 'purchase/', purchasesubmitdata, {
            headers: authHeader()
        }).then(res => {
            navigate('/Newpurchase/1')
            // console.log("mello",res.data)
        }).catch(error => {
            console.error('Error adding  :', error);
            if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `
            <div>
              <p id="issueText">Why do I have this issue?</p>
              <ul>
          
              <li id="typer1">You might have an internet issue</li>
              </ul>
            </div>
          `,
                    didOpen: () => {
                        applyFontSizeTransition('issueText');
                    }
                });
            }
        }).finally(() => {
            setIsLoading(false);
        });
        dispatch(emptypurchasedata());
    };


    const [disword, setdisword] = useState(encodeURIComponent(' '))
    const [dis, setdistributordata1] = useState([])

    useEffect(() => {

        axios.get(`http://43.225.54.138:8080/scm/api/supplier/page/0/10/asc/id/${disword.length ? disword : encodeURIComponent(' ')}`, { headers: authHeader() })
            .then(response => {
                console.log(response)
                if (response.data.data == undefined) {
                    setdistributordata1(response.data.Index.map(use => ({ value: use.id, label: use.companyname })));
                } if (response.data.Index == undefined) {
                    setdistributordata1(response.data.data.map(use => ({ value: use.id, label: use.companyname })));

                }

            })


        /** Voucher GetAll */

        axios.get(BASE_URL + `voucher/page/0/10/desc/id/${disword.length ? disword : encodeURIComponent(' ')}`, { headers: authHeader() }).then(res => {
            if (res.data.data == undefined) {
                setvoucherData(res.data.Index.map(use => ({ value: use.id, label: use.voucherseries })));
            } if (res.data.Index == undefined) {
                setvoucherData(res.data.data.map(use => ({ value: use.id, label: use.voucherseries })));

            }
        })

    }, [disword])

    const onChangeVoucher = (e) =>{
        setvoucherid(e.label);
    }

    return (
        <MDBCard className="company">
            <div className='d-flex bd-highlight'>
                <div className="card-header card-title w-100">   <h1>Create Purchase</h1></div>
                <Link className='p-2 w-200 card-header card-title bd-highlight' to={'/Newpurchase/1'}><Button>Back</Button></Link>
            </div>
            <Form className="product1">
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Purchase Data</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Branch Name</Form.Label>
                                    <Select options={branchdata} onChange={(e) => onChangeBranch(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">WareHouse Name</Form.Label>
                                    <Select value={warehousehvalue} options={warehousehdata} onChange={(e) => onChangewarehouse(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Supplier Company</Form.Label>
                                    {/* <Select options={supplierdata} onChange={(e) => onChangeSupplierData(e)}/> */}
                                    <Select options={dis} onInputChange={(inputValue) => setdisword(inputValue)} onChange={(options) => onChangeSupplierData(options)} />

                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Contact Name</Form.Label>
                                    <Select options={supplierSubContactsdata} onChange={(e) => suppliersubcontactchange(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Date</Form.Label>
                                    <Form.Control type="date" value={defaultValue} onChange={(e) => setpurchasedate(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Terms of Payment</Form.Label>
                                    <Form.Control type='text' name="paymentTerms" onChange={(e) => setpaymentTerms(e.target.value)} autoComplete="off" />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Invoice No</Form.Label>
                                    <Form.Control type="text" onChange={(e) => setinvoiceno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Ewaybill No</Form.Label>
                                    <Form.Control type="text" onChange={(e) => setewaybillno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Other Reference</Form.Label>
                                    <Form.Control type="text" onChange={(e) => setotherreference(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Supplier Invoice No</Form.Label>
                                    <Form.Control type="text" onChange={(e) => setsupplierinvoiceno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Supplier Invoice Date</Form.Label>
                                    <Form.Control type="date" onChange={(e) => setsupplierinvoicedate(e.target.value)} />
                                </Form.Group>
                                {/* <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Type</Form.Label>
                                    <Form.Select value={type} onChange={(e) => settaxtype(e.target.value)}>
                                        <option value="b2b">B2B</option>
                                        <option value="b2c">B2C</option>
                                    </Form.Select>
                                </Form.Group> */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Voucher</Form.Label>
                                    <Select options={voucherData} onChange={(e) => onChangeVoucher(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Voucher Name</Form.Label>
                                    <Form.Control type='text' value={voucherid} name="voucherid"  autoComplete="off" readOnly/>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Status</Form.Label>
                                    <Form.Select value={status} onChange={(e) => setstatus(e.target.value)}>
                                        <option value='true'>Open</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Tax Type</Form.Label>
                                    <Form.Select value={taxtype} onChange={(e) => settaxtype(e.target.value)} disabled>
                                        <option>--Select--</option>
                                        <option value='withtax'>With Tax</option>
                                        <option value='withouttax'>Without Tax</option>
                                    </Form.Select>
                                </Form.Group>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    {/* <Accordion.Item eventKey="1">
                        <Accordion.Header>Billing Address</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Supplier Address</Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Supplier Details</Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Address : -</Form.Label>
                                    <span>&nbsp;{supplierDetails.address}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Name : -</Form.Label>
                                    <span>&nbsp;{supplierSubContactsDetails == ""?supplierDetails.suppliername:supplierSubContactsDetails.contactname}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">City : -</Form.Label>
                                    <span>&nbsp;{supplierDetails.cities}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Phone No : -</Form.Label>
                                    <span>&nbsp;{supplierSubContactsDetails == ""?supplierDetails.phonenumber:supplierSubContactsDetails.phoneno}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Country : -</Form.Label>
                                    <span>{"India"}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Email Id : -</Form.Label>
                                    <span>{supplierSubContactsDetails == ""?supplierDetails.email:supplierSubContactsDetails.email}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">State : -</Form.Label>
                                    <span>&nbsp;{supplierstate_Zone}</span>
                                </Form.Group>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item> */}
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>Shipping Address</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="12" controlId="formBasicName">
                                    <Form.Label className="label">Address</Form.Label>
                                    <Form.Control as="textarea" onChange={(e) => setshippingaddress(e.target.value)} rows={3} name='shippingaddress' placeholder='Enter Shipping Address' />
                                </Form.Group>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>Details</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="12" controlId="formBasicName">
                                    <Form.Label className="label">Remarks</Form.Label>
                                    <Form.Control as="textarea" onChange={(e) => setremarks(e.target.value)} rows={3} name='remarks' placeholder='Enter Remarks' />
                                </Form.Group>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <br></br>
                <Row className="mb-1">
                    <Form.Group as={Col} md="12" controlId="formBasicName">
                        <Form.Label className="label">Product</Form.Label>
                        <Select options={productdata} onChange={(e) => addData(e)} onInputChange={(e) => customFilter(e)} />

                    </Form.Group>
                </Row>
                <br></br>
                <Table striped responsive hover size="sm">
                    <thead className='p-3 mb-2 bg-success text-white'>
                        <tr>
                            <th style={{ width: '20%' }}>PRODUCT&nbsp;NAME</th>
                            <th style={{ width: '9%' }}>HSN&nbsp;Code</th>
                            <th style={{ width: '8%' }}>GST</th>
                            <th style={{ width: '8%' }}>UOM</th>
                            <th style={{ width: '8%' }}>QTY(PCS)</th>
                            <th style={{ width: '8%' }}>QTY(KG)</th>
                            <th style={{ width: '8%' }}>RATE (₹)</th>
                            <th style={{ width: '8%' }}>UOM</th>
                            <th style={{ width: '8%' }}>GROSS AMOUNT(₹)</th>
                            <th style={{ width: '8%' }}>DISCOUNT %</th>
                            <th style={{ width: '8%' }}>NET AMOUNT (₹)</th>
                            {/* <th>Per&nbsp;Unit&nbsp;Rate</th> */}
                            <th style={{ width: '8%' }}>GST VALUE&nbsp;(₹)</th>
                            <th style={{ width: '8%' }}>TOTAL&nbsp;(₹)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                            {/* <th>View</th> */}
                            <th>Action&nbsp;&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody style={{ overflow: "scroll" }}>
                        {purchaseItems.map(item =>
                            <tr key={item.product.id}>
                                <td>
                                    <Form.Group as={Col} md="12" >
                                        {/* <Form.Control type="text" value={item.productName} size="sm" /> */}
                                        <textarea type="text" value={item.productName} className="form-control textcontrol" size="sm"></textarea>

                                    </Form.Group>
                                    <Form.Control as="textarea" onChange={(e) => { dispatch(changepurchasetext(e.target.value, item)) }} value={item.text} rows={2} className='form-control' />
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" value={item.hsncode} size="sm" readOnly />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" value={item.igst} size="sm" readOnly />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Select disabled={item.productType !== "kgproduct" ? true : false} onChange={(e) => { dispatch(purchasemeasurement(e.target.value, item, item.unitofmeasurement)); }} size="sm">
                                            <option>{item.productType !== "kgproduct" ? 'PCS' : "Select"}</option>
                                            <option value="pcs">PCS</option>
                                            <option value="kg">KG</option>
                                        </Form.Select>
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col}  md="12">
                                        {/* {item.unitofmeasurement} */}
                                        <Form.Control type="number"   min="0" onChange={(e) => { dispatch(purchaseqtypcs(e.target.value, item, item.unitofmeasurement)); roundData(e.target.value) }} disabled={item.unitofmeasurement == "kg" ? true : false} value={item.puquantity} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number"   min="0" onChange={(e) => { dispatch(purchaseqtykgs(e.target.value, item, item.unitofmeasurement)); roundData(e.target.value) }} disabled={item.unitofmeasurement == "pcs" || item.unitofmeasurement == "boxproduct" ? true : false || item.unitofmeasurement == "" ? true : false} value={item.unitofmeasurement == "box" ? "" : item.puquantitykgs} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" size="sm" onChange={(e) => { dispatch(changecostpurchase(e.target.value, item, taxtype)); roundData(e.target.value) }} value={item.dlp} />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Select disabled={item.productType !== "kgproduct" ? true : false} onChange={(e) => { dispatch(purchasecalmeasurement(e.target.value, item, item.calunitofmeasurement)); roundData(e.target.value) }} size="sm">
                                            <option>{item.productType !== "kgproduct" ? 'PCS' : "Select"}</option>
                                            <option value="pcs">PCS</option>
                                            <option value="kg">KG</option>
                                        </Form.Select>
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" value={Number(item.grossamount)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" onChange={(e) => { dispatch(changepurchasediscount(e.target.value, item, taxtype)); roundData(e.target.value) }} value={item.tradeDiscount || ''} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" value={Number(item.total)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" value={Number(item.gstvalue)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" value={Number(item.amount)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type='button' className='btn btn-danger' onClick={() => { dispatch(removefromcartfrompurchase(item.product.id)); roundData('') }} value="X" size="sm" />
                                    </Form.Group>
                                </td>
                            </tr>
                        )}
                    </tbody>
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Packing&nbsp;&&nbsp;Forwarding</td>
                            <td>                                <Form.Group as={Col} md="12">
                                <Form.Control type="number" value={shippingcharge} onChange={(e) => settingship(e.target.value)} style={{ height: 30 }} />
                            </Form.Group>
                            </td>
                            <td></td>

                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Net&nbsp;Amount</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={netAmount ? ((netAmount).toFixed(2)) : "0"} style={{ height: 30 }} />
                                </Form.Group>
                            </td>
                            <td></td>

                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>IGST</td>
                            <td><Form.Group as={Col} md="12">
                                <Form.Control type="number" value={igstcheck()} requied style={{ height: 30 }} />
                            </Form.Group></td>
                            <td></td>

                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>SGST</td>
                            <td> <Form.Group as={Col} md="12">
                                <Form.Control type="number" value={SGSTcheck()} requied style={{ height: 30 }} />
                            </Form.Group></td>
                            <td></td>

                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>CGST</td>
                            <td><Form.Group as={Col} md="12">
                                <Form.Control type="number" value={cgstcheck()} requied style={{ height: 30 }} />
                            </Form.Group></td>
                            <td></td>

                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Round&nbsp;off&nbsp;Value</td>
                            <td><Form.Group as={Col} md="12">
                                <Form.Control type="number" value={round} onChange={(e) => settinground(e.target.value)} style={{ height: 30 }} />
                            </Form.Group></td>
                            <td></td>

                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Grand&nbsp;Total</td>
                            <td><Form.Group as={Col} md="12">
                                <Form.Control type="number" value={grandTotal ? ((grandTotal).toFixed(2)) : "0"} readOnly style={{ height: 30 }} />
                            </Form.Group></td>
                            <td></td>

                        </tr>
                    </tbody>
                </Table>
                <Button variant="primary" style={{ width: 'auto' }} onClick={submitdata} disabled={isLoading}>
                    {isLoading ? 'Loading...' : 'Submit'}
                </Button>
            </Form>
        </MDBCard>
    )
}