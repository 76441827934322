import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { BASE_URL } from '../../services/Api';
import authHeader from '../../services/auth-header';
import Select from "react-select";
import ProductService from '../../services/ProductService';
import Addcarinjobsheet from '../../../redux/Reducer/Addcarinjobsheet';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart, emptyCart, removeFromCart, updateItem } from '../../../redux/Action/Addforinwardjobsheet';
import { Accordion, Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

import Swal from 'sweetalert2';
import AuthService from '../../services/auth.service';
const Outwardedit = () => {
  const datasupplier=[
    { "id": 1, "label": "Supply" },
    { "id": 2, "label": "Import" },
    { "id": 3, "label": "Export" },
    { "id": 4, "label": "Job Work" },
    { "id": 5, "label": "For Own Use" },
    { "id": 6, "label": "Job Work Returns" },
    { "id": 7, "label": "Sales Return" },
    { "id": 8, "label": "Others" },
    { "id": 9, "label": "SKD/CKD" },
    { "id": 10, "label": "Line Sales" },
    { "id": 11, "label": "Recipient Not Known" },
    { "id": 12, "label": "Exhibition or Fairs" }
  ]
  
  const doctype=[
    { "id": "INV", "label": "Tax Invoice" },
    { "id": "BIL", "label": "Bill of Supply" },
    { "id": "BOE", "label": "Bill of Entry" },
    { "id": "CHL", "label": "Delivery Challan" },
    { "id": "CNT", "label": "Credit Note" },
    { "id": "OTH", "label": "Others" }
  ]
  const handleDocTypeChange=(selectedOption)=>{
    setDocType(selectedOption)
    console.log(selectedOption)
    }
    const handlesupplytype=(selectedOption)=>{
      setSubSupplyType(selectedOption)
    }
    const handlemodes=(selectedOption)=>{
      setTransMode(selectedOption)
    }
    const handletype=(selectedOption)=>{
    setTransactionType(selectedOption)
    }
    const modes1=[
      { "id": 1, "label": "Road" },
      { "id": 2, "label": "Rail" },
      { "id": 3, "label": "Air" },
      { "id": 4, "label": "Ship" },
      { "id": 5, "label": "In Transit" }
    ]

const handlevtype=(selectedOption)=>{
  setVehicleType(selectedOption)
}


    {/* <Select options={modes1} className='sett' onChange={handlemodes}  /> */}
    const transtype=[
      { "id": 1, "label": "Regular" },
      { "id": 2, "label": "Bill To - Ship To" },
      { "id": 3, "label": "Bill From - Dispatch From" },
      { "id": 4, "label": "Combination of 2 and 3" }
    ]
  const [isLoading, setIsLoading] = useState(false);
  const applyFontSizeTransition = (elementId) => {
    const element = document.getElementById(elementId);
    element.style.transition = 'font-size 2s';
    element.style.fontSize = '30px';
    element.style.fontWeight = '600';
    // font-weight: 600;
    setTimeout(() => {
       
      document.getElementById("typer1").classList.add('promote');
      element.style.fontSize = '24px';
    }, 1000);
  };
  const { id } = useParams();
  const { pointindex } = useParams();
  const [disword, setdisword] = useState(encodeURIComponent(' '))
  const [dis, setdistributordata1] = useState([])
  const dispatch = useDispatch();
  const [jobsheetno, setJobsheetno] = useState([])
  const [jobsheetdate, setJobsheetdate] = useState([])
  const [jobtype, setJobtype] = useState([])
  const [remarks, setRemarks] = useState([])
  const [grandtotal, setGrandtotal] = useState([])
  const [warehouse, setWarehouse] = useState([])
  const [supplier, setSupplier] = useState([])
  const [jobsheetItems, setJobsheetItems] = useState([]);


  const [subSupplyType, setSubSupplyType] = useState('');
const [docType, setDocType] = useState('');
const [docNo, setDocNo] = useState('');
const [docDate, setDocDate] = useState('');
const [fromGstin, setFromGstin] = useState('');
const [fromTrdName, setFromTrdName] = useState(null);
const [fromAddr1, setFromAddr1] = useState('');
const [fromAddr2, setFromAddr2] = useState('');
const [fromPlace, setFromPlace] = useState('');
const [fromPincode, setFromPincode] = useState(0);
const [actFromStateCode, setActFromStateCode] = useState(0);
const [fromStateCode, setFromStateCode] = useState(0);
const [toGstin, setToGstin] = useState('');
const [toTrdName, setToTrdName] = useState('');
const [toAddr1, setToAddr1] = useState('');
const [toAddr2, setToAddr2] = useState('');
const [toPlace, setToPlace] = useState('');
const [toPincode, setToPincode] =  useState([]);
const [actToStateCode, setActToStateCode] = useState([]);
const [toStateCode, setToStateCode] = useState([]);
const [transactionType, setTransactionType] = useState([]);
const [transporterId, setTransporterId] = useState('');
const [transporterName, setTransporterName] = useState('');
const [transDocNo, setTransDocNo] = useState('');
const [transMode, setTransMode] = useState('');
const [transDistance, setTransDistance] = useState('');
const [transDocDate, setTransDocDate] = useState('');
const [vehicleNo, setVehicleNo] = useState('');
const [vehicleType, setVehicleType] = useState('');
const [ewayBillNo, setEwayBillNo] =  useState([]);
const [ewayBillDate, setEwayBillDate] = useState('');
const [validUpto, setValidUpto] = useState('');
const [alert, setAlert] = useState('');
const [ewayStatus, setEwayStatus] = useState('');
  const items = useSelector(state => state.Addcarinjobsheet.items);
console.log(items)
  // console.log((items.map((item)=>Number(item.amt))))
  // const checkqty=useSelector(state=>state.Addcarinjobsheet)

// alert(supplier)
  let sum
  if (items) {
    sum = items.reduce((acc, item) => acc + Number(item.amount), 0);
    console.log(sum)
  }

  useEffect(() => {

    axios.get(`${BASE_URL}product/page/0/200/asc/id/${disword.length?disword:encodeURIComponent(' ')}`, { headers: authHeader() })
      .then(response => {
        console.log(response)
        if (response.data.data == undefined) {
          setdistributordata1(response.data.Index.map(use => ({ value: use.id, label: use.product_Name })));
        } if (response.data.Index == undefined) {
          setdistributordata1(response.data.data.map(use => ({ value: use.id, label: use.product_Name })));

        }

        // console.log(response.data.data.map(use => ({ value: use.id, label: use.product_Name })))
      })




  }, [disword])
  const disid = (val) => {
    console.log(typeof val)
    // if(typeof val=='number'){
    // alert('its number')
    ProductService.getProductEdit(val).then(res => {
      console.log([res.data])
      const productdetails = [res.data]
      console.log(productdetails.map((item) => item.id))
      const extractedData = productdetails.map(item => ({
        id: item.id,
        productName: item.productName,
        dlp: item.dlp,
        type: item.productType,
        jobsheet_qty: 0,
        amount: 0,
        product: {
          "id": val
        },
        type1: 'box',

        jobsheet_qty_kg: 0,
        uomSecondary: Number(item.uomSecondary)
      }));

      // console.log(extractedData[0])
      dispatch(addToCart(extractedData[0]));

    })
    // setdistributor(val)
  }
  let onChangeSupplierData = (e) => {

    let name = e.value;


  };
const vechiletype=[
  { "id": "R", "label": "Regular" },
  { "id": "O", "label": "Over Dimensional Cargo" }
]

  const [disword1, setdisword1] = useState(encodeURIComponent(' '))
  const [dis1, setdistributordata11] = useState([])

  useEffect(() => {

    axios.get(`http://43.225.54.138:8080/scm/api/supplier/page/0/10/asc/id/${disword1.length?disword1:encodeURIComponent(' ')}`, { headers: authHeader() })
      .then(response => {
        console.log(response)
        if (response.data.data == undefined) {
          setdistributordata11(response.data.Index.map(use => ({ value: use.id, label: use.suppliername })));
        } if (response.data.Index == undefined) {
          setdistributordata11(response.data.data.map(use => ({ value: use.id, label: use.suppliername })));

        }

        //   console.log(response.data.data.map(use => ({ value: use.id, label: use.suppliername})))  
      })



  }, [disword1])
  useEffect(() => {
    setJobsheetItems(items)
  }, [items])

  // const navigate = useNavigate();

  const handleclikc = () => {
    setIsLoading(true); 

    // items
    // items
    let extractedData = items.map(item => ({
      // id:item.id,
      amount: item.amount,
      dlp: item.dlp,
      jobsheet_qty: item.jobsheet_qty,
      productType: item.type1 !=='kg' ? 'pcs' : 'kg',
      product: {
        "id": item.id
      },
      jobsheet_qty_kg: item.jobsheet_qty_kg,

    })); 
    const data = [{
      jobsheetno: jobsheetno,
      jobsheetdate: jobsheetdate,
      jobtype: jobtype,
      remarks: remarks,
      grandtotal: sum,
      warehouse: { id: Number(warehouse) },
      supplier: { id: Number(supplier) },
      jobworkoutwarditems: extractedData,   
      subSupplyType: subSupplyType.id,
      docType: docType.id,
      docNo: docNo,
      docDate: docDate,
      fromGstin: fromGstin,
      fromTrdName: fromTrdName,
      fromAddr1: fromAddr1,
      fromAddr2: fromAddr2,
      fromPlace: fromPlace,
      fromPincode: fromPincode,
      actFromStateCode: actFromStateCode,
      fromStateCode: fromStateCode,
      toGstin: toGstin,
      toTrdName: toTrdName,
      toAddr1: toAddr1,
      toAddr2: toAddr2,
      toPlace: toPlace,
      toPincode: toPincode,
      actToStateCode: actToStateCode,
      toStateCode: toStateCode,
      transactionType: transactionType.id,
      transporterId: transporterId,
      transporterName: transporterName,
      transDocNo: transDocNo,
      transMode: transMode.id,
      transDistance: transDistance,
      transDocDate: transDocDate,
      vehicleNo: vehicleNo,
      vehicleType: vehicleType.id,
      // ewayBillNo: ewayBillNo,
      ewayBillDate: ewayBillDate,
      validUpto: validUpto,
      alert: alert,
      // eway_status: eway_status
    }];
    axios.put(BASE_URL + `jobworkoutward/${id}`, data[0], {
      headers: authHeader()
    })
      .then(response => {
        console.log('Post request successful:', response.data);
        navigate(`/Outwardtable/1`)
      }).catch(error => {
        console.error('Error adding  :', error);
        if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `
            <div>
              <p id="issueText">Why do I have this issue?</p>
              <ul>
          
              <li id="typer1">You might have an internet issue</li>
              </ul>
            </div>
          `,
          didOpen: () => {
            applyFontSizeTransition('issueText');
          }
        });
      }
      })
      .finally(() => {
        setIsLoading(false); 
      });
  }
  const [disword2, setdisword2] = useState(encodeURIComponent(' '))
  const [dis2, setdistributordata2] = useState([])
  const [supplierDetails, setSupplierDetails] = useState([])
  const [warehousedetails, setWarehousedetails] = useState([])
  useEffect(() => {

    axios.get(`http://43.225.54.138:8080/scm/api/warehouse/page/0/10/asc/id/${disword2.length?disword2:encodeURIComponent(' ')}`, { headers: authHeader() })
      .then(response => {
        console.log(response)
        if (response.data.data == undefined) {
          setdistributordata2(response.data.Index.map(use => ({ value: use.id, label: use.name })));
        } if (response.data.Index == undefined) {
          setdistributordata2(response.data.data.map(use => ({ value: use.id, label: use.name })));

        }

        // console.log(response.data.data.map(use => ({ value: use.id, label: use.suppliername})))  
      })



  }, [disword2])

  let data = [{ value: 'Cutting', label: 'Cutting' }, { value: 'Shaping', label: 'Shaping' }, { value: 'Moulding ', label: 'Moulding' }, { value: 'Finishing', label: 'Finishing' }]

  const handleDelete = (id) => {
    dispatch(removeFromCart(id));
};
const [data11, setdata11] = useState([])
  useEffect(() => {
    dispatch(emptyCart());

    axios.get(BASE_URL + `jobworkoutward/${id}`, {
      headers: authHeader()
    })
      .then(response => {
        setdata11(response.data);
        setJobsheetno(response.data.jobsheetno)
        setJobsheetdate(response.data.jobsheetdate)
        setJobtype(response.data.jobtype)
        setRemarks(response.data.remarks)
        setSupplier(response.data.supplier.id)
        // setSupplierDetails(response.data.supplier.id warehouse)
        setWarehousedetails([response.data.warehouse].map(use => ({ value: use.id, label: use.name })))
        setSupplierDetails([response.data.supplier].map(use => ({ value: use.id, label: use.suppliername })))
        setWarehouse(response.data.warehouse.id)
        // alert('sent')
        setDocNo(response.data.docNo)
setDocDate(response.data.docDate)
setFromTrdName(response.data.fromTrdName)
setFromGstin(response.data.fromGstin)
setFromAddr1(response.data.fromAddr1)
setFromAddr2(response.data.fromAddr2)
setFromPlace(response.data.fromPlace)
setFromPincode(response.data.fromPincode)
setActFromStateCode(response.data.fromStateCode)
setFromStateCode(response.data.fromStateCode)
setToGstin(response.data.toGstin)
setToTrdName(response.data.toTrdName)
setToAddr1(response.data.toAddr1)
setToAddr2(response.data.toAddr2)
setTransDocNo(response.data.transDocNo)
setTransporterName(response.data.transporterName)
setToPlace(response.data.toPlace)
setToPincode(response.data.toPincode)
setActToStateCode(response.data.actToStateCode)
setToStateCode(response.data.toStateCode)
setTransporterId(response.data.transporterId)
setTransporterName(response.data.transporterName)
setTransDistance(response.data.transDistance)
setTransDocDate(response.data.transDocDate)
setVehicleNo(response.data.vehicleNo)

        // docDate
        console.log([response.data.supplier])
        const data = response.data.jobworkoutwarditems
        const extractedData = data.map(item => ({
          id: item.product.id,
          productName: item.product.productName,
          dlp: item.dlp,
          type: item.product.productType,
          type1: item.productType,
          jobsheet_qty: item.jobsheet_qty,
          amount: item.amount,
          product: {
            "id": item.product.id
          },
          type1: item.productType=='kg'?'kg':'box',

          jobsheet_qty_kg: item.jobsheet_qty_kg,
          uomSecondary: Number(item.product.uomSecondary)
        }));

        // console.log(extractedData)
        // setJobsheetItems(extractedData)
        // setJobsheetItems(extractedData);

        // Dispatch addToCart for each item
        extractedData.forEach(item => {
          dispatch(addToCart(item));
        });
setSubSupplyType(datasupplier.filter((item)=>item.id==response.data.subSupplyType)[0])
setDocType(doctype.filter((item)=>item.id==response.data.docType)[0])
setTransMode(modes1.filter((item)=>item.id==response.data.transMode)[0])
setVehicleType(vechiletype.filter((item)=>item.id==response.data.vehicleType)[0])
setTransactionType(transtype.filter((item)=>item.id==response.data.transactionType)[0])
console.log(datasupplier.filter((item)=>item.id==response.data.subSupplyType)[0])
      })
      .catch(error => {
        console.error(error);
      });
  }, [])
console.log(warehousedetails)
  //  let sup=supplier
  const navigate = useNavigate();

  const getItem = (id) => {

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true); 

        axios.delete( BASE_URL + 'jobworkoutward/' + id, {
            headers:authHeader()
        }).then(res=>{
            Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
              )
            navigate(`/Outwardtable/${pointindex}`)
        }).catch(error => {
          console.error('Error adding  :', error);
          if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `
              <div>
                <p id="issueText">Why do I have this issue?</p>
                <ul>
            
                <li id="typer1">You might have an internet issue</li>
                </ul>
              </div>
            `,
            didOpen: () => {
              applyFontSizeTransition('issueText');
            }
          });
        }
        })
        .finally(() => {
          setIsLoading(false); 
        });
        
   
      }
    })
  };


  const user = AuthService.getCurrentUser();

  function formatDate(dateString) {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
  }

  const [Loading, setLoading] = useState(false)
const handleewaybill=()=>{
// console.log(data11)
setLoading(true)

const requestData = {
  supplyType: data11.supplyType,
  subSupplyType: data11.subSupplyType,
  docType: data11.docType,
  docNo: data11.docNo,
  docDate: data11.docDate,
  fromGstin: data11.fromGstin,
  fromTrdName: data11.fromTrdName,
  fromAddr1: data11.fromAddr1,
  fromAddr2: data11.fromAddr2,
  fromPlace: data11.fromPlace,
  fromPincode: data11.fromPincode,
  actFromStateCode: data11.actFromStateCode,
  fromStateCode: data11.fromStateCode,
  toGstin: data11.toGstin,
  toTrdName: data11.toTrdName,
  toAddr1: data11.toAddr1,
  toAddr2: data11.toAddr2,
  toPlace: data11.toPlace,
  toPincode: data11.toPincode,
  actToStateCode: data11.actToStateCode,
  toStateCode: data11.toStateCode,
  transactionType: data11.transactionType,
  transporterId: data11.transporterId,
  transporterName: data11.transporterName,
  transDocNo: data11.transDocNo,
  transMode: data11.transMode,
  transDistance: data11.transDistance,
  transDocDate: data11.transDocDate,
  vehicleNo: data11.vehicleNo,
  vehicleType: data11.vehicleType,
  itemList: data11.jobworkoutwarditems.map(item => ({
      productName: item.product.productName,
      hsnCode: item.product.hsnCode,
      quantity: item.jobsheet_qty,
      qtyUnit: item.productType=="kg"?"kgs":"pcs",
      product: {
          id: item.product.id
      },
      jo_id: item.jo_id
  }))
};

axios.post("http://43.225.54.138:8080/scm/gst/post/ewaypartb/" + id, { supplyType: data11.supplyType,
  subSupplyType: data11.subSupplyType,
  docType: data11.docType,
  docNo: data11.docNo,
  docDate: formatDate(data11.docDate),
  fromGstin: data11.fromGstin,
  fromTrdName: data11.fromTrdName,
  fromAddr1: data11.fromAddr1,
  fromAddr2: data11.fromAddr2,
  fromPlace: data11.fromPlace,
  fromPincode: data11.fromPincode,
  actFromStateCode: data11.actFromStateCode,
  fromStateCode: data11.fromStateCode,
  toGstin: data11.toGstin,
  toTrdName: data11.toTrdName,
  toAddr1: data11.toAddr1,
  toAddr2: data11.toAddr2,
  toPlace: data11.toPlace,
  toPincode: data11.toPincode,
  actToStateCode: data11.actToStateCode,
  toStateCode: data11.toStateCode,
  transactionType: data11.transactionType,
  transporterId: data11.transporterId,
  transporterName: data11.transporterName,
  transDocNo: data11.transDocNo,
  transMode: data11.transMode,
  transDistance: data11.transDistance,
  transDocDate: formatDate(data11.transDocDate),
  vehicleNo: data11.vehicleNo,
  vehicleType: data11.vehicleType,
  itemList: data11.jobworkoutwarditems.map(item => ({
      productName: item.product.productName,
      hsnCode: item.product.hsnCode,
      quantity: item.jobsheet_qty,
      qtyUnit: item.productType=="kg"?"kgs":"pcs",
      product: {
          id: item.product.id
      },
      jo_id: id
  })) }, {
  headers: authHeader()
}).then(res => {
  console.log('mello', res.data)
  Swal.fire({
    title: 'Success!',
    text: 'E-way bill generated successfully',
    icon: 'success',
    confirmButtonText: 'OK'
  });
  window.location.reload();

}) .finally(() => {
  setLoading(false)
});
console.log(requestData)

}




 const [show, setShow] = useState(false);
 const [cancelRmrk, setcancelRmrk] = useState([])
 const [cancelRsnCode, setcancelRsnCode] = useState([])

 const handleClose = () => setShow(false);
 const handleShow = () => setShow(true);
 const handleewaybilldelete=()=>{
  axios.post(`http://43.225.54.138:8080/scm/gst/post/canceleway/${id}`, { 
"ewbNo":data11.ewayBillNo,
"cancelRsnCode":Number(cancelRsnCode),
"cancelRmrk":cancelRmrk
  }, {
    headers: authHeader()
  }).then(res => {
    console.log('mello', res.data)
    
    Swal.fire({
      title: 'Success!',
      text: 'E-way bill Deleted successfully',
      icon: 'success',
      confirmButtonText: 'OK'
    });

    window.location.reload();


  }) .finally(() => {
    setLoading(false)
  });
 }
  return (
    <div>
      <div className='card'>
        <div className='card-body'>
          <div className='card-title card-header'>
            Jobsheet Outward View
          </div>
          <div  className='card-header card-title'>

          <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Cancel E-way Bill</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        {/* "ewbNo":"241817689082",
"cancelRsnCode":2,
"cancelRmrk":"Cancelled the order" */}
<label className='form-control form-label'>{data11.ewayBillNo}</label>
<label className='form-label'>Cancel Reason code</label>
<input type='number' onChange={(e)=>setcancelRsnCode(e.target.value)} className='form-control'/>
<label className='form-label'>Cancel Reason</label>
 <textarea className='form-control' onChange={(e)=>setcancelRmrk(e.target.value)}>

 </textarea>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className='btn-sm' onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" className='btn-sm' onClick={()=>{handleClose();handleewaybilldelete()}}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>


       { data11.eway_status!=='Generated'?  <div className='card-header card-title'>
{Loading==false?data11.eway_status!=='Cancel' ?<Link className='p-2 w-200 card-header card-title bd-highlight'onClick={()=>handleewaybill()} ><Button className='btn-sm'>E-way bill</Button></Link>:null:<Link className='p-2 w-200 card-header card-title bd-highlight'  ><Button className='btn-sm'>Loading...</Button></Link>}                 
      
       <Link className='p-2 w-200 card-header card-title bd-highlight' to={`/Outwardtable/${pointindex}`}><Button className='btn-sm'>Back</Button></Link>
                        <Link className='p-2 w-200 card-header card-title bd-highlight' to={`/Outwardedit/${id}/${pointindex}`}><Button className='btn-sm'>Edit</Button></Link>
                        {user.roles[0] === 'ROLE_ADMIN' &&<Link className='p-2 w-200 card-header card-title bd-highlight' variant="primary" style={{width:'auto'}} onClick={() => getItem(id)} disabled={isLoading}>
        
        <Button className="btn-sm">{isLoading ? 'Loading...' : 'Delete'}</Button>
      </Link>   }                 </div>:
  
      <Link className='p-2 w-300 card-header card-title bd-highlight'  onClick={handleShow} ><Button className='btn-sm'style={{width:'179px'}}> Cancel E-way bill</Button></Link>}



          {/* <Link className='p-2 w-200 card-header card-title bd-highlight' to={`/Outwardtable/${pointindex}`}><Button className='btn-sm'>Back</Button></Link> */}
 {/*  */}
          {/* <Link className='p-2 w-200 card-header card-title bd-highlight' variant="primary" style={{width:'auto'}} onClick={() => getItem(id)} disabled={isLoading}> */}
        
        {/* <Button className="btn-sm">  {isLoading ? 'Loading...' : 'Delete'}</Button> */}
      {/* </Link>          */}
            </div>
            <br></br>
{ data11.eway_status=='Generated'?<label  className='form-label form-control'>E-way Bill No:-<span className="loght">{data11.ewayBillNo}</span> &nbsp; &nbsp; &nbsp; &nbsp; E-way Bill Date:-<span className="loght">{data11.ewayBillDate}</span> &nbsp;<br></br> E-way bill status: <span className="loght">{data11.eway_status}</span> &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; E-way bill Valid Upto: <span className="loght">{data11.validUpto}</span></label>:null}      



<label className='form-label'>E-way bill status: <span className="loght">{data11.eway_status=='Cancel'?data11.eway_status+'ed':data11.eway_status}</span></label>
  <div className='row'>
            <div className='col'>
              <label className='form-label' >Jobsheet Number</label>
              <input type='text' className='form-control' value={jobsheetno} onChange={(e) => setJobsheetno(e.target.value)} />
            </div>
            <div className='col'>
              <label className='form-label'>  Date</label>
              <input type='Date' className='form-control' value={jobsheetdate} onChange={(e) => setJobsheetdate(e.target.value)} />
            </div>

          </div>
          <div className='row'>
            <div className='col'>
              <label className='form-label'>Supplier</label>
              <input type='text' style={{ border: 'none', background: 'white' }} disabled />
              <Select 
  options={dis1} 
  className='sett'
  value={[{ value: supplierDetails.map((item)=>item.value), label:supplierDetails.map((item)=>item.label) }]} 
  onInputChange={(inputValue) => setdisword1(inputValue)} 
  onChange={(options) =>{ setSupplier(options.value);setSupplierDetails([options])}} 
/>

            </div>
            <div className='col'>
              <label className='form-label'>Warehouse</label>
              <Select
                options={dis2}
                className='sett'
                value={[{ value: warehousedetails.map((item)=>item.value), label:warehousedetails.map((item)=>item.label) }]} 
                onInputChange={(inputValue) => setdisword2(inputValue)}
                onChange={(options) => {setWarehouse(options.value); setWarehousedetails([options])}}
              />
            </div>
            <div className='col'>
              <label className='form-label'>Job Type</label>
              <Select options={data} className='sett' value={[{ value: jobtype, label: jobtype }]} onInputChange={(inputValue) => setdisword1(inputValue)} onChange={(options) => setJobtype(options.value)} />
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <label className='form-label'>Remarks</label>
              <textarea className='form-control' value={remarks} onChange={(e) => setRemarks(e.target.value)}>

              </textarea>
            </div>

          </div>
          <br></br>
                    <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
                <Accordion.Header>Delivery Note</Accordion.Header>
                <br></br>
                {/* <br></br> */}
                <Accordion.Body>
                <div className='row'>
                        <div className='col'>
                            <label className='form-label'>Sub Supply Type</label>
                            <Select options={datasupplier}
                            value={subSupplyType}
                             className='sett' onChange={handlesupplytype} />

                        </div>
                   
                        <div className='col'>
                            <label className='form-label'>Document Type</label>
                            <Select options={doctype} className='sett' 
                            value={docType}
                            onChange={handleDocTypeChange} />
                            
                        </div>
                        <div className='col'>
                            <label className='form-label'>Document No</label>
                            {/* <Select options={doctype} className='sett'   /> */}
                            <input type='text' className='form-control' value={docNo} onChange={(e)=>setDocNo(e.target.value)}/>
                        </div>
                        <div className='col'>
                            <label className='form-label'>Document Date</label>
                            {/* <Select options={doctype} className='sett'   /> */}
                            <input type='date' className='form-control' value={docDate}  onChange={(e)=>setDocDate(e.target.value)}/>
                        </div>

{/* fromGstin neelam gst number */}
                        
                    </div>
                  <h1>Consignor Details(From)</h1>
<div className='row'>
  <div className='col'>

  <label className='form-label'>Trade Name</label>
                            {/* <Select options={doctype} className='sett'   /> */}
                            <input type='TEXT' className='form-control' value={fromTrdName}   onChange={(e)=>setFromTrdName(e.target.value)}/>

  </div>

  <div className='col'>

<label className='form-label'>GSTIN/UIN</label>
                          {/* <Select options={doctype} className='sett'   /> */}
                          <input type='TEXT' className='form-control'value={fromGstin}   onChange={(e)=>setFromGstin(e.target.value)}/>

</div>

</div>
<div className='row'>
<div className='col'>

<label className='form-label'>Address 1</label>
                          {/* <Select options={doctype} className='sett'   /> */}
                          <input type='TEXT' className='form-control' value={fromAddr1} onChange={(e)=>setFromAddr1(e.target.value)}/>

</div>
<div className='col'>

<label className='form-label'>Address 2</label>
                          {/* <Select options={doctype} className='sett'   /> */}
                          <input type='TEXT' className='form-control' value={fromAddr2} onChange={(e)=>setFromAddr2(e.target.value)}/>

</div>

</div>
<div className='row'>
  <div className='col'>
    <div className='form-group'>
      <label htmlFor='city' className='form-label'>City</label>
      <input type='text'   className='form-control' value={fromPlace} onChange={(e)=>setFromPlace(e.target.value)}/>
    </div>
  </div>
  <div className='col'>
    <div className='form-group'>
      <label  className='form-label'>Pin-Code</label>
      <input type='text'   className='form-control' value={fromPincode} onChange={(e)=>setFromPincode(e.target.value)}/>
    </div>
  </div>
  
  <div className='col'>
    <div className='form-group'>
      <label  className='form-label'>From-State-Code</label>
      <input type='text'  className='form-control'  value={fromStateCode} onChange={(e)=>setFromStateCode(e.target.value)}/>
    </div>
   
  </div>






</div>
<br></br>
<h1>Consignor Details(To)</h1>

<div className='row'>
  <div className='col'>
    <label className='form-label'>To GST</label>
    <input className='form-control' type="text"  value={toGstin}  onChange={(e)=>setToGstin(e.target.value)}/>
  </div>
  <div className='col'>
    <label className='form-label'>To TradeName</label>
    <input className='form-control' type='text' value={toTrdName} onChange={(e)=>setToTrdName(e.target.value)}/>
  </div>
</div>
<div className='row'>
  <div className='col'>
    <label className='form-label'>To Address 1</label>
    <input className='form-control' type='text' value={toAddr1}  onChange={(e)=>setToAddr1(e.target.value)}/>
  </div>
  <div className='col'>
    <label className='form-label'>To Address 2</label>
    <input className='form-control' type='text'  value={toAddr2} onChange={(e)=>setToAddr2(e.target.value)}/>
  </div>


</div>
<br></br>
<div className='row'>
  <div className='col'>
    <label className='form-label'>To Place</label>
    <input type='text' className='form-control'  value={toPlace} onChange={(e)=>setToPlace(e.target.value)}/>
  </div>
  <div className='col'>
    <label className='form-label'>To Pincode</label>
    <input type='text' className='form-control' value={toPincode} onChange={(e)=>setToPincode(e.target.value)}/>
  </div>
  <div className='col'>
    <label className='form-label'>Act To State</label>
    <input type='text' className='form-control' value={actToStateCode} onChange={(e)=>setActToStateCode(e.target.value)}/>
  </div>
  <div className='col'>
    <label className='form-label'>To StateCode</label>
    <input type='text' className='form-control'  value={toStateCode}  onChange={(e)=>setToStateCode(e.target.value)}/>
  </div>
  <div className='col'>
    <label className='form-label'>transactionType</label>
    <Select options={transtype} className='sett' value={transactionType} onChange={handletype}  />

    {/* <input type="text" className='form-control' onChange={(e)=>setTransactionType(e.target.value)}/> */}
  </div>
</div>
<br></br>
<h1>Transporter Details</h1>
<div className='row'>
  <div className='col-6'>
    <label className='form-label'>Transporter Id</label>
    <input className='form-control' type='text' value={transporterId} onChange={(e)=>setTransporterId(e.target.value)}/>

  </div>
  <div className='col-6'>
    <label className='form-label'>Transporter Name</label>
    <input className='form-control' type='text' value={transporterName}  onChange={(e)=>setTransporterName(e.target.value)}/>

  </div>
  <br></br>
  <h1>Part B Details</h1>
  <div className='col'>
    <label className='form-label'>Transporter Doc No  </label>
    <input className='form-control' type='text' value={transDocNo}  onChange={(e)=>setTransDocNo(e.target.value)}/>

  </div>

  <div className='col'>
    <label className='form-label'>Transporter Mode</label>
    {/* <input className='form-control' type='text' onChange={(e)=>setTransMode(e.target.value)}/> */}
    <Select options={modes1}  value={transMode} className='sett' onChange={handlemodes}  />
  </div>
</div>
<div className='row'>
  <div className='col'>
  <label className='form-label'>Transporter Distance</label>
  <input className='form-control' type='text' value={transDistance} onChange={(e)=>setTransDistance(e.target.value)}/>
  </div>
  <div className='col'>
  <label className='form-label'>Transporter Doc Date</label>
  <input className='form-control' type='date' value={transDocDate}  onChange={(e)=>setTransDocDate(e.target.value)}/>
  </div>
  <div className='col'>
    <label className='form-label'>Vehicle No</label>
    <input className='form-control' type='text' value={vehicleNo} onChange={(e)=>setVehicleNo(e.target.value)}/>

  </div>

  <div className='col'>
    <div className='form-label'>Vehicle Type</div>
    {/* <input className='form-control' type='text'  value={vehicleType?vehicleType:data1.vehicleType} onChange={(e)=>setVehicleType(e.target.value)}/> */}
    <Select options={vechiletype}  value={vehicleType} className='sett' onChange={handlevtype}  />

    
  </div>
</div>

 
                 </Accordion.Body>
            </Accordion.Item>
        </Accordion>

        <br></br>
          {/* <input type='text' /> */}
          <Select options={dis} onInputChange={(inputValue) => setdisword(inputValue)} onChange={(options) => disid(options.value)} />
          <br></br>
          <table className='table thrtable '>
            <thead>
              <tr>
                <th>Product Name</th>
                <th>Product Type</th>
                <th>Qty  </th>
                <th>Qty kg</th>
                <th>Rate  </th>
                <th>Amount</th>
                <th width='50' height='12'>Action</th>
              </tr>
            </thead>
            <tbody>
              {jobsheetItems.map((item) => {
                return <>
                  <tr>
                    {/* {item.type} */}
                    <td>{item.productName}</td>
                    <td width='150' height='12'><select className='form-select' value={item.type1 !== 'kg'? 'box' : 'kg'} onChange={(e) => {
                      dispatch(updateItem(item.id, e.target.value, item.jobsheet_qty, item.dlp, item.jobsheet_qty_kg, item.amount));
                    }}  >
                      <option>Select</option>
                      <option value='box'>Box</option>
                      {item.type == 'kgproduct' ? <option value='kg'>KG</option> : ''}
                    </select></td>
                    <td width='150' height='12'><input type='number' min='0' value={Number(item.jobsheet_qty)} disabled={item.type1 == 'kg' ? true : false} onChange={(e) => {
                      dispatch(updateItem(item.id, item.type1, Number(e.target.value), item.dlp, item.jobsheet_qty_kg, item.amount));
                    }} className='form-control' /></td>
                    {/* <td>{item.qtykg}</td> */}
                    <td width='150' height='12'><input type='number' min='0' value={Number(item.jobsheet_qty_kg)} disabled={item.type1 == 'box' ? true : false} onChange={(e) => {
                      dispatch(updateItem(item.id, item.type1, item.jobsheet_qty, item.dlp, e.target.value, item.amount));
                    }} className='form-control' /></td>
                    <td width='150' height='12'><input type='number' min='0' value={Number(item.dlp)} onChange={(e) => {
                      dispatch(updateItem(item.id, item.type1, item.jobsheet_qty, Number(e.target.value), item.jobsheet_qty_kg, item.amount));
                    }} className='form-control' /></td>
                    <td width='150' height='12'><input type='number' min='0' value={Number(item.amount)} className='form-control' /></td>
                    <td><td>    <div onClick={() => handleDelete(item.id)}>X</div></td></td>
                  </tr>

                </>

              })}
            </tbody>

          </table>
          <div className='row'>
            <div className='col tomforgrandtotal'>
              Total:{sum.toFixed(2)}
            </div>
          </div>

          <div className='row'>
            <div className='col'>
              {/* <button className='btn btn-primary' onClick={() => handleclikc()}>Submit</button> */}

              {/* <Button variant="primary" style={{width:'auto'}} onClick={()=>handleclikc()} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Submit'}
        </Button> */}
            </div>
          </div>
        </div>

      </div>



    </div>
  )
}

export default Outwardedit