/* PO Create */

import { PO_ADD_TO_CART } from "../constant";
import { PO_REMOVE_TO_CART } from "../constant";
import { PO_CHANGE_QTYPCS } from "../constant";
import { PO_CHANGE_QTYKG } from "../constant";
import { PO_CHANGE_COST } from "../constant";
import { PO_CHANGE_CAL_MEASUREMENT } from "../constant";
import { PO_CHANGE_DISCOUNT1 } from "../constant";
import { PO_CHANGE_TEXT } from "../constant";
import { PO_EMPTY_DATA } from "../constant";
import { PO_CHANGE_MEASUREMENT } from "../constant";


export const poaddtocart = (data) => {

    return {

        type: PO_ADD_TO_CART,
        data: data
    }

}

export const poremovefromcart = (data) => {


    return {

        type: PO_REMOVE_TO_CART,
        data: data
    }

}

export const pochangemeasurement = (data, item, data2) => {

    return {
        type: PO_CHANGE_MEASUREMENT,
        data: data,
        item: item,
        data2: data2
    }
}

export const pochangeqtypcs = (data, item, data2) => {


    return {

        type: PO_CHANGE_QTYPCS,
        data: data,
        item: item,
        data2: data2
    }

}

export const pochangeqtykgs = (data, item, data2) => {

    return {
        type: PO_CHANGE_QTYKG,
        data: data,
        item: item,
        data2: data2

    }
}

export const pochangecost = (data, item, data2) => {


    return {

        type: PO_CHANGE_COST,
        data: data,
        item: item,
        data2: data2

    }

}

export const pochangecalmeasurement = (data, item, data2 ) => {

    return{
        type:PO_CHANGE_CAL_MEASUREMENT,
        data: data,
        item: item,
        data2: data2
    }
}

export const pochangediscount1 = (data, item, data2) => {


    return {

        type: PO_CHANGE_DISCOUNT1,
        data: data,
        item: item,
        data2: data2

    }

}



export const pochangetext = (data, item) => {


    return {

        type: PO_CHANGE_TEXT,
        data: data,
        item: item
    }

}


export const poemptydata = () => {

    return {

        type: PO_EMPTY_DATA,
    }

}