import axios from 'axios';
import React, { useEffect, useState } from 'react'
import authHeader from '../Component/services/auth-header';

const INvoice = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading1, setIsLoading1] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState([]);
  const [data22, setdata22] = useState([])
  const [dis, setsdis] = useState([])
  const [csub, setsub] = useState([])
  const [com, setcom] = useState([])
  const [branch, setbranch] = useState([])
  const [statename, setnane] = useState([])
  const [statecode, setcode] = useState([])
  const [supsate, setsup] = useState([])
  const [supsate1, setsup1] = useState([])
  const [scontact, setscontact] = useState([])
  const [jsonData, setjsonData] = useState([])
  const [igsttax, setigst] = useState([])
  const [cgsttax, setcgst] = useState([])
  const [sgsttax, setsgst] = useState([])
  const [round, setround] = useState([])
  const [gpt, setgpt] = useState([])
  const [rty, setry] = useState([])
  const [roundtotal, setroundtotal] = useState([])
const[po,setpo]=useState([])
  useEffect(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const updatedPageData = jsonData.slice(startIndex, endIndex);
    setCurrentPageData(updatedPageData);
  }, [currentPage]);

  let totalPages = Math.ceil(jsonData.length / 12);


  console.log(totalPages);
  // console.log(jsonData);


  useEffect(() => {
    axios.get(`http://43.225.54.138:8080/scm/api/pr/2`, { headers: authHeader() })
    .then(response => {
      setdata22(response.data)
      // console.log(`http://43.225.54.138:8080/scm/api/product/page/${Number(getid)}/${Number(limit)}/${sort}/${fieldnme}`);
      setpo(response.data);
      setsdis(response.data.supplier)
      setsub(response.data.supplierSubContacts)
      setcom(response.data.company)
      setbranch(response.data.branch)
      setnane(response.data.branch.states.name)
      console.log(response.data);
      setsup(response.data.supplier.states.name)
      setsup1(response.data.supplier.states.state_code)
      setcode(response.data.branch.states.state_code)
      setscontact(response.data.supplierSubContacts)
      const extractedData = response.data.purchaseOrderItems.map((item) => ({
        id: item.id,
        AllQtApprox: item.id,
        Qty: item.poquantity,
        amount: item.amount,
        Per: item.product.productType,
        Amount: item.amount,
        DescriptionofGoods: item.product.productName,
        EANCode: item.product.eanCode,
        HSNSAC: item.product.hsnCode,
        GStRate: item.product.igst,
        Rate: item.rate,
        uomSecondary: item.product.uomSecondary,
        productType: item.product.productType,
        porate: item.product.mrp,
        discount: item.discount
      }));
      // const extractedData12 = response.data.map((item) => ({
      //   id: item.id,
      // }))
      console.log(extractedData);
      setgpt(response.data.grandtotal)
      setigst(response.data.igst)
      setsgst(response.data.sgst)
      setcgst(response.data.cgst)
      setround(response.data.roundofvalue)
      setjsonData(extractedData)
      setIsLoading1(response.data.warehouse)
      let sum1 = response.data.purchaseOrderItems.filter((item) => item.product.productType == "kgproduct").map((item) => item.product.uomSecondary * item.poquantity)

      const sum = sum1.reduce((acc, val) => acc + val, 0);

      let roundedSum = sum.toFixed(2);

      setIsLoading(roundedSum);
      //  console.log();
      setroundtotal(response.data.purchaseOrderItems.map((item) => item.amount).reduce((acc, val) => acc + val, 0))

      const qty = response.data.purchaseOrderItems.map((item) => item.poquantity);

      const qty1 = qty.reduce((acc, val) => acc + val, 0);

      setry(qty1.toFixed(2))

    })
    .catch(error => {
      console.error(error);
    });

    const elements = ["naving", "1sidebar", "qw", "swift", "logoo", "logoo1", "menu"];

    elements.forEach(elementId => {
      const element = document.getElementById(elementId);
      if (element) {
        element.classList.add('hidden');
      }
    });

    const newElement = document.getElementById("new");
    if (newElement) {
      newElement.classList.add('fullWidth');
    }
  }, [])
  let removedElements
  let itemsPerPage=12;
  const paragraphs = [];
  let i = 0;
  for (i; i < totalPages; i++) {
    let startIndex;
    let endIndex;
    let roman;
    let jsonDataSlice;
    // let remainingItems
    let lastPageDataSize

     
// console.log(totalPages);
 
      startIndex = i * itemsPerPage;
      endIndex = Math.min((i + 1) * itemsPerPage, jsonData.length);
      jsonDataSlice = jsonData.slice(startIndex, endIndex);
    
 
    if(totalPages===i+1){
      let lastPageIndex = totalPages - 1;
      let lastPageStartIndex = lastPageIndex * itemsPerPage;
      let lastPageEndIndex = Math.min((lastPageIndex + 1) * itemsPerPage, jsonData.length);
        lastPageDataSize = lastPageEndIndex - lastPageStartIndex;

  if (lastPageDataSize >4) {
    let newidea = lastPageDataSize-4;
    console.log(newidea);
      jsonDataSlice = jsonData.slice(startIndex, endIndex-newidea);
        removedElements = jsonData.slice(endIndex - newidea, endIndex);
      console.log(removedElements);
  }


  if (totalPages==1&&i==0&&lastPageDataSize >4) {
    let newidea = lastPageDataSize-4;
    console.log(newidea);
      jsonDataSlice = jsonData.slice(startIndex, endIndex-newidea);
        removedElements = jsonData.slice(endIndex - newidea, endIndex);
      console.log(removedElements);
  }


    } 


      // startIndex = i * itemsPerPage;
      // endIndex = Math.min((i + 1) * itemsPerPage, jsonData.length);
      // jsonDataSlice = jsonData.slice(startIndex, endIndex);




    





    paragraphs.push(<p className='jpmorgon' key={i}>
       {i===0? <p className='fontprint center '>Invoice</p>:""}
        <div className='border1'>
<div className='row border1'>
  <div className='col-9 border1'>
  <h1 className='para size9'><p><b>{com.cname}</b></p><br></br> {com.address}<br></br>
  Contact : {com.phone}<br></br>
E-Mail : {com.email}
<br></br>www.neelamappliances.com<br></br>
GSTIN/UIN: {com.gst}
 <br></br>State Name : Maharashtra, Code : 27

</h1>
  </div>
  <div className='col rty33'>
  <div className='row    borderhard'>
              <div className='col  borderight'>
                <b className='size9'>Voucher No</b><br></br>
                <font className="size9">{po.voucherno}</font>

              </div>
              <div className='col '>
                <b className='size9'>Dated:</b><br></br>
                <font className="size9">{po.podate}</font>
              </div>

            </div>
    
    
    <div className='row     borderbottom'>
      <div className='col  borderight'>
      <b className='size9'>Supplier Invoice No & Date.</b>
      <font className="size9"></font>

      </div>
      <div className='col '>
      <b className='size9'>Other Reference:</b>
      <font className="size9"></font>
      </div>
      
    </div>
   
  </div>
  <div className='col-9 border1'>
  <h1 className='para size9 fizer'> <b>Consignee (Ship to)</b><br></br>
              {branch.branchname} <br></br>
              {branch.address}<br></br>
              GSTIN/UIN :{branch.gstno}<br></br>
              State Name:{statename}, Code : {statecode}

            </h1>
  </div>
  <div className='col'>
    {/* <h1>hry</h1> */}
  </div>
  <div className='col-9 border1'>
  <h1 className='para'>
              <b className='size9'>Supplier (Bill from)</b><br></br>
              <font className="size9">
                {dis.companyname},{dis.cities}
                {dis.address}{dis.zipcode} <br></br>

                <font className="size9">GSTIN/UIN :   {dis.gstno}</font><br></br>
                <font className="size9">PAN/IT No  :AAFFB8197A</font><br></br>
                <font className="size9">State Name :{supsate} , Code :{supsate1}</font><br></br>
                <font className="size9">Contact person :{scontact.contactname}</font><br></br>
                <font className="size9">Contact :  {dis.phonenumber} ,{scontact.phoneno}</font><br></br>
                <font className="size9">E-Mail : {dis.email}</font>
              </font>
            </h1>
  </div>
  <div className='col'>
 
  </div>
</div>
     
      <table id='rowcount' className='  bordered'>

        <tr className='thback'>
          <th className='thback' width="3%">Sr No.</th>
          <th className='thback' width="auto">Description of Goods <span className='white'>Goodsssssssssssssssssss</span></th>
          <th className='thback' width="3%">HSN/SAC</th>
          <th className='thback' width="3%">GST Rate</th>
          <th className='thback' width="3%">  Due on</th>
          <th className='thback' width="3%"> Alt.Quantity</th>
          <th className='thback' width="3%">Quantity</th>
          <th className='thback' width="3%">Rate</th>
          <th className='thback' width="3%">Per</th>
          <th className='thback' width="3%">Dis.%</th>
          <th className='thback' width="3%">Amount</th>
        </tr>
     
   {
   
   }
        
    <tbody className='height'>
          {
            jsonDataSlice.map((item, index) => {
              return <tr>
                <td  className='thback1'>{startIndex + index + 1}</td>
                <td  className='thback1 sizeropt'>{item.DescriptionofGoods}<br></br> Godown : {isLoading1.name}</td>
                <td  className='thback1 sizeropt'>{item.HSNSAC}</td>
                <td  className='thback1 sizeropt'>{item.GStRate}%</td>
                {i ? <td className='thback1'>{i}</td> : <td className='thback1'>{item.AllQtApprox}</td>}
                <td  className='thback1 sizeropt'>{item.Qty}</td>
                <td  className='thback1 sizeropt'>{item.Rate}</td>
                <td  className='thback1 sizeropt'>{item.Per}</td>
                <td  className='thback1 sizeropt'>{item.GrossAmt}</td>
                <td  className='thback1 sizeropt'>{item.Dis}</td>
                <td  className='thback1 sizeropt'>{item.Dis}</td>
              </tr>
            })

          }

 





        </tbody> 
        
       
      </table>




{totalPages===i+1&&lastPageDataSize>4?<p className='jpmorgon' key={i}></p>:""}

{(()=>{
if(totalPages===i+1&&lastPageDataSize>4){
return (
<div className='border1'><div className='row border1'>
  <div className='col-9 border1'>
  <h1 className='para size9'><p><b>{com.cname}</b></p><br></br> {com.address}<br></br>
  Contact : {com.phone}<br></br>
E-Mail : {com.email}
<br></br>www.neelamappliances.com<br></br>
GSTIN/UIN: {com.gst}
 <br></br>State Name : Maharashtra, Code : 27

</h1>
  </div>
  <div className='col rty33'>
  <div className='row    borderhard'>
              <div className='col  borderight'>
                <b className='size9'>Voucher No</b><br></br>
                <font className="size9">{po.voucherno}</font>

              </div>
              <div className='col '>
                <b className='size9'>Dated:</b><br></br>
                <font className="size9">{po.podate}</font>
              </div>

            </div>
    
    
    <div className='row     borderbottom'>
      <div className='col  borderight'>
      <b className='size9'>Supplier Invoice No & Date.</b>
      <font className="size9"></font>

      </div>
      <div className='col '>
      <b className='size9'>Other Reference:</b>
      <font className="size9"></font>
      </div>
      
    </div>
   
  </div>
  <div className='col-9 border1'>
  <h1 className='para size9 fizer'> <b>Consignee (Ship to)</b><br></br>
              {branch.branchname} <br></br>
              {branch.address}<br></br>
              GSTIN/UIN :{branch.gstno}<br></br>
              State Name:{statename}, Code : {statecode}

            </h1>
  </div>
  <div className='col'>
    {/* <h1>hry</h1> */}
  </div>
  <div className='col-9 border1'>
  <h1 className='para'>
              <b className='size9'>Supplier (Bill from)</b><br></br>
              <font className="size9">
                {dis.companyname},{dis.cities}
                {dis.address}{dis.zipcode} <br></br>

                <font className="size9">GSTIN/UIN :   {dis.gstno}</font><br></br>
                <font className="size9">PAN/IT No  :AAFFB8197A</font><br></br>
                <font className="size9">State Name :{supsate} , Code :{supsate1}</font><br></br>
                <font className="size9">Contact person :{scontact.contactname}</font><br></br>
                <font className="size9">Contact :  {dis.phonenumber} ,{scontact.phoneno}</font><br></br>
                <font className="size9">E-Mail : {dis.email}</font>
              </font>
            </h1>
  </div>
  <div className='col'>
 
  </div>
  <table id='rowcount' className='  bordered'>
  
  <tr className='thback'>
    <th className='thback ' width="1px">Sr No.</th>
    <th className='thback' width="1px">Description of Goods <span className='white'>Goodsssssssssssssssssss</span></th>
    <th className='thback' width="1px">HSN/SAC</th>
    <th className='thback' width="1px">GST Rate</th>
    <th className='thback' width="1px">  Due on</th>
    <th className='thback' width="1px"> Alt.Quantity</th>
    <th className='thback' width="3%">Quantity</th>
          <th className='thback' width="3%">Rate</th>
          <th className='thback' width="3%">Per</th>
          <th className='thback' width="3%">Dis.%</th>
    
    <th className='thback' width="1px">Amount</th>
  </tr>
 
{

}
    
<tbody className='height'>
      {
        removedElements.map((item, index) => {
          return <tr>
            <td  className='thback1 sizeropt'>{jsonData.length+index}</td>
            <td  className='thback1 sizeropt'>{item.DescriptionofGoods}<br></br> Godown : {isLoading1.name}</td>
            <td  className='thback1 sizeropt'>{item.HSNSAC}</td>
            <td  className='thback1 sizeropt'>{item.GStRate}%</td>
            {i ? <td className='thback1'>{i}</td> : <td className='thback1'>{item.AllQtApprox}</td>}
            <td  className='thback1 sizeropt'>{item.Qty}</td>
            <td  className='thback1 sizeropt'>{item.Rate}</td>
            <td  className='thback1 sizeropt'>{item.Per}</td>
            <td  className='thback1 sizeropt'>{item.GrossAmt}</td>
            <td  className='thback1 sizeropt'>{item.Dis}</td>
            <td  className='thback1 sizeropt'>{item.Dis}</td> 
          </tr>
        })

      }







    </tbody> 
    
   
  </table>
</div></div>)
}

if(totalPages===1&&i==0&&lastPageDataSize>4){
  return (
   <>
   <p className='jpmorgon'></p>
   
  <div className='row border1'>
  <div className='col-9 border1'>
  <h1 className='para size9'><p><b>{com.cname}</b></p><br></br> {com.address}<br></br>
  Contact : {com.phone}<br></br>
E-Mail : {com.email}
<br></br>www.neelamappliances.com<br></br>
GSTIN/UIN: {com.gst}
 <br></br>State Name : Maharashtra, Code : 27

</h1>
  </div>
  <div className='col rty33'>
  <div className='row    borderhard'>
              <div className='col  borderight'>
                <b className='size9'>Voucher No</b><br></br>
                <font className="size9">{po.voucherno}</font>

              </div>
              <div className='col '>
                <b className='size9'>Dated:</b><br></br>
                <font className="size9">{po.podate}</font>
              </div>

            </div>
    
    
    <div className='row     borderbottom'>
      <div className='col  borderight'>
      <b className='size9'>Supplier Invoice No & Date.</b>
      <font className="size9"></font>

      </div>
      <div className='col '>
      <b className='size9'>Other Reference:</b>
      <font className="size9"></font>
      </div>
      
    </div>
   
  </div>
  <div className='col-9 border1'>
  <h1 className='para size9 fizer'> <b>Consignee (Ship to)</b><br></br>
              {branch.branchname} <br></br>
              {branch.address}<br></br>
              GSTIN/UIN :{branch.gstno}<br></br>
              State Name:{statename}, Code : {statecode}

            </h1>
  </div>
  <div className='col'>
    {/* <h1>hry</h1> */}
  </div>
  <div className='col-9 border1'>
  <h1 className='para'>
              <b className='size9'>Supplier (Bill from)</b><br></br>
              <font className="size9">
                {dis.companyname},{dis.cities}
                {dis.address}{dis.zipcode} <br></br>

                <font className="size9">GSTIN/UIN :   {dis.gstno}</font><br></br>
                <font className="size9">PAN/IT No  :AAFFB8197A</font><br></br>
                <font className="size9">State Name :{supsate} , Code :{supsate1}</font><br></br>
                <font className="size9">Contact person :{scontact.contactname}</font><br></br>
                <font className="size9">Contact :  {dis.phonenumber} ,{scontact.phoneno}</font><br></br>
                <font className="size9">E-Mail : {dis.email}</font>
              </font>
            </h1>
  </div>
  <div className='col'>
 
  </div>
</div>
        <table id='rowcount' className='  bordered'>
  
        <tr className='thback'>
          <th className='thback' width="3%">Sr No.</th>
          <th className='thback sizeropt' width="auto">Description of Goods <span className='white'>Goodssssssssssssssssssssssssssssssssssssssssssssss</span></th>
          <th className='thback sizeropt' width="3%">HSN/SAC</th>
          <th className='thback sizeropt' width="3%">GST Rate</th>
          <th className='thback sizeropt' width="3%">  Due on</th>
          <th className='thback sizeropt' width="3%"> Alt.Quantity</th>
          <th className='thback' width="3%">Quantity</th>
          <th className='thback' width="3%">Rate</th>
          <th className='thback' width="3%">Per</th>
          <th className='thback' width="3%">Dis.%</th>
          
          <th className='thback' width="3%">Amount</th>
        </tr>
       
     {
     
     }
          
      <tbody className='height'>
            {
              removedElements.map((item, index) => {
                return <tr>
                  <td  className='thback1 sizeropt'>{jsonData.length+index}</td>
                  <td  className='thback1 sizeropt'>{item.DescriptionofGoods}<br></br> Godown : {isLoading1.name}</td>
                  <td  className='thback1 sizeropt'>{item.HSNSAC}</td>
                  <td  className='thback1 sizeropt'>{item.GStRate}%</td>
                  <td  className='thback1 sizeropt'>11-nov-2060</td>
                  {i ? <td className='thback1'>{i}</td> : <td className='thback1'>{item.AllQtApprox}</td>}
                  <td  className='thback1 sizeropt'>{item.Qty}</td>
                  <td  className='thback1 sizeropt'>{item.Rate}</td>
                  <td  className='thback1 sizeropt'>{item.Per}</td>
                  <td  className='thback1 sizeropt'>{item.GrossAmt}</td>
                  <td  className='thback1 sizeropt'>{item.Dis}</td>
                  <td  className='thback1 sizeropt'>{item.Dis}</td>
                  </tr>
              })
  
            }
  
   
  
  
  
  
  
          </tbody> 
          
         
        </table>
   
 
   </>
  )
  }



 })()}


 {(()=>{
if(totalPages===i+1){
return (
  <>


  
  <table id='rowcount' className='   '>
   {/* <tr className='rede size9'> */}
   <tr className='thback'>
          <th className='thback white' width="3%">Sr No.</th>
          <th className='thback white' width="auto">Description of Goods <span className='white'>Goodsssssssssssssssssss</span></th>
          <th className='thback white' width="3%">HSN/SAC</th>
          <th className='thback white' width="3%">GST Rate</th>
          <th className='thback white' width="3%">  Due on</th>
          <th className='thback white' width="3%"> Alt.Quantity</th>
          <th className='thback white' width="3%">Quantity</th>
          <th className='thback white' width="3%">Rate</th>
          <th className='thback white' width="3%">Per</th>
          <th className='thback white' width="3%">Dis.%</th>
          <th className='thback bgwhite' width="3%">2345674</th>
        </tr>

        <tbody className='height'>




        <tr>    <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt right'>SGST INPUT</td>
                  <td  className='thback1 sizeropt'> </td> <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt'>  </td>
                  <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt'> 17000.00</td>
                  </tr>
                  <tr>    <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt right'>CGST INPUT</td>
                  <td  className='thback1 sizeropt'> </td> <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt'>  </td>
                  <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt'> 17000.00</td>
                  </tr>
                  <tr>    <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt right'>Rounding Off</td>
                  <td  className='thback1 sizeropt'> </td> <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt'>  </td>
                  <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt'> 1.10</td>
                  </tr>
                  <tr>    <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt ogleft'>Bill Details:New Ref 333-34 90Days 3,18,89.00 Cr<br></br></td>
                  <td  className='thback1 sizeropt'> </td> <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt'>  </td>
                  <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt'> </td>
                  <td  className='thback1 sizeropt'> </td>
                  </tr>
          <tr className='thback  bordert'>
            <td  className='thback1 sizeropt'> </td>
            <td  className='thback1 sizeropt'>Total</td>
            
            <td  className='thback1 sizeropt'>  </td>
            <td  className='thback1 sizeropt'>  </td>
            <td  className='thback1 sizeropt'>   </td>
            <td  className='thback1 sizeropt'> 439.520</td>
            <td  className='thback1 sizeropt'>439.520 </td>
            <td  className='thback1 sizeropt'></td>
            <td  className='thback1 sizeropt'> </td>
            <td  className='thback1 sizeropt'> </td>
            <td  className='thback1 sizeropt'>ī 98,452.00 </td>
          </tr>
        </tbody>
      </table>
      <div className='row     bordert'>
        <div className='col'>
          <div className='sizeropt fontprint'>  Amount Chargeable (In Words): <br></br>
            Indian Rupees Three Lakh Twenty Seven Thousand Seventy Five</div>
        </div> 
       
        <div className='col-1 right'>
          <div className='size9  fontprint'>E.& O.E</div>
        </div> 
        <div className='border1 row'>
    <div className='col-5 center '>
    <font>HSN/SAC </font>  
    </div>
    <div className='col-1 borderl'>
    <font>Tax Value </font>  
    </div>
    <div className='col-1 borderl'>
    <font>SGST%</font>  
    </div>
    <div className='col-1 borderl'>
    <font>UGST Amount</font>  
    </div>
    <div className='col-1 borderl'>
    <font>CGST%</font>  
    </div>
    <div className='col-1 borderl'>
    <font>CGST Amount</font>  
    </div>
    <div className='col-1 borderl right2'>
    <font>Total<span className='smallingfont'>(Taxamount)</span></font>  
    </div>
</div>

<div className='border1 row'>
    <div className='col-5 center '>
    <font>73239390</font>  
    </div>
    <div className='col-1 borderl'>
    <font>1,38,178 </font>  
    </div>
    <div className='col-1 borderl'>
    <font>12%</font>  
    </div>
    <div className='col-1 borderl'>
    <font>16,581.36</font>  
    </div>
    <div className='col-1 borderl'>
    <font>12%</font>  
    </div>
    <div className='col-1 borderl'>
    <font>16,581.36</font>  
    </div>
    <div className='col-1 borderl right2'>
    <font>16,581.36</font>  
    </div>
</div>
<div className='border1 row'>
    <div className='col-5 right '>
    <font>Total</font>  
    </div>
    <div className='col-1 borderl'>
    <font>1,38,178 </font>  
    </div>
    <div className='col-1 borderl'>
    <font>12%</font>  
    </div>
    <div className='col-1 borderl'>
    <font>16,581.36</font>  
    </div>
    <div className='col-1 borderl'>
    <font> </font>  
    </div>
    <div className='col-1 borderl'>
    <font>16,581.36</font>  
    </div>
    <div className='col-1 borderl right2'>
    <font>16,581.36</font>  
    </div>
</div>
<div className='col'>
          <div className='sizeropt fontprint'>  Amount Chargeable (In Words): <br></br>
            Indian Rupees Three Lakh Twenty Seven Thousand Seventy Five</div>
        </div>          
      
     
       <div className='orgindisplay'>
         <h1 className='gstin'> 
Company GSTIN/UIN:27AAFFB8197A1ZG
 
         </h1>
         <h1 className='ertrt'>
<p className='fontsize12 size911'>
for NEELAM APPLIANCES LIMITED
</p>
<p className='right fontsize12 size911'>Authorised Signatory</p>

         </h1>
       </div>
       
      </div>
      
 </>
)
}
 })()}

    </div><h1 className='center  fontsize12 size911'>This Document is Computer Generated</h1></p>);


  }


  return (

    <div className='  '>
      <div>{paragraphs}</div>



     

    </div>
  )
}

export default INvoice