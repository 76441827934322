import React, { useEffect, useState } from 'react';
import { GoogleMap, LoadScript, Marker, Polyline } from '@react-google-maps/api';
import Componentmap from './Componentmap';
import axios from 'axios';

const MapView = () => {
  const [routeCoordinates, setRouteCoordinates] = useState([]);
  const [error, setError] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [loading, setLoading] = useState(true);
const [data, setData] = useState([{ checkinLocationLatitude: 19.0759, checkinLocationLongitude: 72.8777 }])
  const apiKey = 'AIzaSyAi8GeH0OVLzpqznM3pFy-0Lt6ecDofWro'; 
  const mapStyles = {
    height: '85vh',
    width: '100%',
    top: '1px'
  };

  const mumbaiRailwayStation = { lat: 19.0759, lng: 72.8777 };
  const hyderabadRailwayStation = { lat: 17.3888, lng: 78.4708 };
  const delhi = { lat: 28.7041, lng: 77.1025 }; 

  const fetchDirections = async (data) => {

    try {
         const origin = `${data[0].checkinLocationLatitude},${data[0].checkinLocationLongitude}`;
        const destination = `${data[data.length - 1].checkinLocationLatitude},${data[data.length - 1].checkinLocationLongitude}`;

         const waypoints = data.slice(1, -1).map(location => `${location.checkinLocationLatitude},${location.checkinLocationLongitude}`).join('|');

 
        const url = `https://maps.googleapis.com/maps/api/directions/json?origin=${origin}&destination=${destination}&waypoints=${waypoints}&key=${apiKey}`;

        const response = await axios.get(url);

        if (response.status !== 200) {
            throw new Error('Failed to fetch directions data');
        }

        const responseData = response.data;
        const points = responseData.routes[0].overview_polyline.points;
        // const decodedPoints = google.maps.geometry.encoding.decodePath(points);

        setLoading(false);
    } catch (error) {
        setLoading(false);
        setError(error.message);
    }
};
  
  useEffect(() => {
    const timeout = setTimeout(() => {
      fetchDirections(data);
          const coordinates = data.map(point => ({
        lat: Number(point.checkinLocationLatitude),
        lng: Number(point.checkinLocationLongitude),
      }));
  console.log(coordinates)
      setRouteCoordinates(coordinates);
    }, 1000);
  
    return () => clearTimeout(timeout);
  }, [data]);
  const hadndledata=(data)=>{
    // console.log(data)
    setData(data)
  }
console.log(routeCoordinates)
  return (
    <div>
      <LoadScript googleMapsApiKey={apiKey}>
        <GoogleMap
          mapContainerStyle={mapStyles}
          zoom={4}
          center={mumbaiRailwayStation}
        >
          <Polyline
            path={routeCoordinates}
            options={{
              strokeColor: "#FF0000",
              strokeOpacity: 1,
              strokeWeight: 2
            }}
          />
          {/* <Marker position={mumbaiRailwayStation} />
          <Marker position={hyderabadRailwayStation} />
          <Marker position={delhi} /> */}
         {data!==undefined?(data.map((item)=>{
          return<>
          <Marker     title="batman" position={{ lat: Number(item.checkinLocationLatitude), lng: Number(item.checkinLocationLongitude) }} />
           
          </>
         })):null}
        </GoogleMap>
      </LoadScript>
      <Componentmap onDateChange1={hadndledata}/>
    </div>
  );
};

export default MapView;
