import axios from "axios";
import { MDBCard } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../services/Api";
import authHeader from "../services/auth-header";
import { Button, Col, Form, Row } from "react-bootstrap";
import AuthService from "../services/auth.service";
import Swal from "sweetalert2";

export default function View(){
    const {id} = useParams();
    const [voucherseries,setvoucherseries] = useState('');
    const user = AuthService.getCurrentUser();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const applyFontSizeTransition = (elementId) => {
      const element = document.getElementById(elementId);
      element.style.transition = 'font-size 2s';
      element.style.fontSize = '30px';
      element.style.fontWeight = '600';
      // font-weight: 600;
      setTimeout(() => {
         
        document.getElementById("typer1").classList.add('promote');
        element.style.fontSize = '24px';
      }, 1000);
    };

    useEffect(()=>{
        axios.get(BASE_URL + 'voucher/getbyid/'+id,{
            headers:authHeader()
        }).then(res=>{
            setvoucherseries(res.data.voucherseries);
        })
    },[]);

    const handledelete=(id)=>{
  
     
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.isConfirmed) {
            setIsLoading(true); 
            axios.delete(BASE_URL + 'voucher/delete/'+id,{
              headers:authHeader()
            }).then(res=>{
      
      
              navigate(`/Voucher/Table/1`)
             
              Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
              )
      
      
      
              // window.location.reload();
            }).catch(error => {
              console.error('Error adding  :', error);
              if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `
                  <div>
                    <p id="issueText">Why do I have this issue?</p>
                    <ul>
                
                    <li id="typer1">You might have an internet issue</li>
                    </ul>
                  </div>
                `,
                didOpen: () => {
                  applyFontSizeTransition('issueText');
                }
              });
            }
            })
            .finally(() => {
              setIsLoading(false); 
            });
          
          }
        })
              }

    return(
        <MDBCard className="company">
            {/* <h1>Voucher View</h1>/ */}
            <div className='d-flex bd-highlight'>
            <div className="card-header card-title w-100">Voucher View</div>
            {user.roles[0] === 'ROLE_ADMIN' &&      
        
        <Link className='p-2 w-200 card-header card-title bd-highlight' variant="primary" style={{width:'auto'}} onClick={()=>handledelete(id)} disabled={isLoading}>
        
        <Button className="btn-sm">  {isLoading ? 'Loading...' : 'Delete'}</Button>
      </Link>
        }
            <Link className='p-2 w-200 card-header card-title bd-highlight' to={`/Voucher/Table/1`}><Button>Back</Button></Link>
            <Link className='p-2 w-200 card-header card-title bd-highlight' to={`/Voucher/Update/`+id+'/1'}><Button>Update</Button></Link>
             </div>
            <Form className="product1">
            <Row className="mb-1">
                <Form.Group as={Col} md="6" controlId="formBasicName">
                    <Form.Label className="label">Voucher Series</Form.Label>
                    <Form.Control type="text" name="voucherseries" value={voucherseries} autoComplete="off" placeholder="Enter" />
                </Form.Group>
                </Row>
                <Button disabled>Submit</Button>
            </Form>
            
        </MDBCard>
    )
}