import axios from "axios";
import { MDBCard } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../services/Api";
import authHeader from "../../services/auth-header";
import Swal from "sweetalert2";

export default function View(){
    const {id} = useParams();
    const { pointindex } = useParams();
    const navigate = useNavigate();

    const[date,setdate] = useState('');
    const[occasion,setoccasion] = useState('');
    
    const getdata = () =>{
        axios.get(BASE_URL + "holiday/byid/"+id,{
            headers:authHeader()
        }).then(res=>{
            setdate(res.data.date);
            setoccasion(res.data.occasion);

        })
    };

    useEffect(()=>{
        getdata();
    },[]);
    
    const handledelete=(id)=>{
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.isConfirmed) {
        
           axios.delete(BASE_URL + 'holiday/delete/'+id,{
            headers:authHeader()
           }).then(res=>{
         if(1==1){
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
            navigate(`/HolidayTable/${pointindex}`)
         }
            })
           
          }
        })
        }
    return(
        <MDBCard className="company">
            <div className='d-flex bd-highlight'>
            <div className="card-header card-title w-100">Holiday View</div>
            <Link className='p-2 w-200 card-header card-title bd-highlight'  onClick={()=>handledelete(id)} ><Button className="btn-sm">Delete</Button></Link>
        
        <Link className='p-2 w-200 card-header card-title bd-highlight' to={`/HolidayUpdate/${id}/${pointindex}`}><Button className="btn-sm">Update</Button></Link>

        <Link className='p-2 w-200 card-header card-title bd-highlight' to={`/HolidayTable/${pointindex}`}><Button  className="btn-sm">Back</Button></Link>
            </div>
            <Form className="product1">
                <Row className="mb-1">
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Holiday Date</Form.Label>
                        <Form.Control type='date' name="date" value={date} onChange={(e) => setdate(e.target.value)} autoComplete="off" />
                    </Form.Group>
                    <Form.Group as={Col} md="12" controlId="formBasicName">
                        <Form.Label className="label">Occasion</Form.Label>
                        <Form.Control as="textarea" rows={3} value={occasion}name='occasion' onChange={(e) => setoccasion(e.target.value)} placeholder='Enter Occasion' />
                    </Form.Group>
                </Row>
                {/* <Button onClick={submitdata}>Submit</Button> */}
            </Form>
        </MDBCard>
    )
}