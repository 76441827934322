import axios from 'axios';
import React, { useEffect, useState } from 'react'
import authHeader from '../services/auth-header';
import { BASE_URL } from '../services/Api';
import { MDBDataTable } from 'mdbreact';
import { Link } from 'react-router-dom';
import { FaUserEdit } from 'react-icons/fa';
import { AiOutlineDelete } from 'react-icons/ai';
import { BsFillEyeFill } from 'react-icons/bs';
import Swal from 'sweetalert2';
import { Mydatatable } from '../Master/Product/Mydatatable';
import WareHouseService from '../services/WareHouseService';

const Minstock = () => {
const[warename,setwarename]=useState([])


useEffect(() => {
  WareHouseService.getwarehouse().then((response) => {
    // setfulldisdata(res.data)
    setwarename((response.data).map(user => ({ value: user.id, label: user.name })));
})
}, [ ])


let header = [
  { "label": "id", "field": "product_id" },
  { "label": "product_name", "field": "product_name" },

  { "label": "date", "field": "date" },
  { "label": "outwardqty", "field": "outwardqty" },
  { "label": "totaldciqty", "field": "totaldciqty" },

  // { "label": "totalmrniqty", "field": "totalmrniqty" },
  // { "label": "totalosqty", "field": "totalosqty" },
  // { "label": "totalpriqty", "field": "totalpriqty" },
  // { "label": "totalsriqty", "field": "totalsriqty" },



  { "label": "  inwardqty  ", "field":"inwardqty" },
  { "label": "outwardqty", "field": "outwardqty" },
  { "label": "closingstock", "field": "closingstock" },
 { "label": "msqty", "field": "msqty" },
  { "label": "Action", "field": "action","subaction1":<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"/><circle cx="12" cy="12" r="3"/></svg> }
]; 



 
const sumofkeys=[{"first":'closingstock'} ]
return(<>

<Mydatatable 
    head={header}
    title={"Mininmum Stock"}
    axiostabledata={'mininmumstock'}
    Add={" "}
    Create={' '}
    Edit={""}
    View={""}

    warehousehdata={warename}
    Sumofkeys={sumofkeys}
    />


</>);


}
export default Minstock