 import { GET_DATA_PRIMARYORDER } from "../constant";
 import { GET_PRODUTDATA_PRIMARYORDER } from "../constant";
 import { REMOVE_TO_CART_PRIMARYORDER } from "../constant";
 import { CHANGE_MEASUREMENT_PRIMARYORDER } from "../constant";
 import { CHANGE_PER_PRIMARYORDER } from "../constant";
 import { CHANGE_QTY_PRIMARYORDER } from "../constant";
 import { CHANGE_COST_PRIMARYORDER } from "../constant";
 import { CHANGE_DISCOUNT1_PRIMARYORDER } from "../constant";
 import { CHANGE_TEXT_PRIMARYORDER } from "../constant";
 import { EMPTY_DATA_PRIMARYORDER } from "../constant";
 import { CHANGE_SQPB_PRIMARYORDER } from "../constant";

 export const getDataPrimaryOrder = (data,data2) =>{
// console.log("mello",data)
return {

    type : GET_DATA_PRIMARYORDER,
    data : data,
    data2 : data2
}

 }

 export const addtocartprimaryorder = (data,data2) => {

    // console.warn("action called", data);
   return {

       type : GET_PRODUTDATA_PRIMARYORDER,
       data : data,
       data2 : data2
   }

}

export const removefromcartprimaryorder = (data) => {

    console.warn("action REMOVE_TO_CART_WORKORDER called", data);
    return {

        type : REMOVE_TO_CART_PRIMARYORDER,
        data : data
    }

}

export const changeunitofMeasurementprimaryorder = (data, item, data2) => {

    console.warn("qty change called66", data);
    return {

        type : CHANGE_MEASUREMENT_PRIMARYORDER,
        data : data,
        item : item,
        data2 : data2
    }

}

export const perprimaryorder = (data, item, data2) => {

    console.warn("qty change called5", data2);
    return {

        type : CHANGE_PER_PRIMARYORDER,
        data : data,
        item : item,
        data2 : data2
    }

}

export const changeqtyprimaryorder = (data, item, data2) => {

    console.warn("qty change called66", data2);
    return {

        type : CHANGE_QTY_PRIMARYORDER,
        data : data,
        item : item,
        data2 : data2
    }

}

export const changecostprimaryorder = (data, item, data2) => {

    console.warn("cost change called", data);
    return {

        type : CHANGE_COST_PRIMARYORDER,
        data : data,
        item : item,
        data2 : data2

    }

}

export const changediscount1primaryorder = (data, item, data2) => {

    console.warn("discount1 change called", data);
    return {

        type : CHANGE_DISCOUNT1_PRIMARYORDER,
        data : data,
        item : item,
        data2 : data2

    }

}

export const changetextprimaryorder = (data, item) => {

    console.warn("text change called", data);
    return {

        type : CHANGE_TEXT_PRIMARYORDER,
        data : data,
        item : item
    }

}

export const changestandardQtyPerBoxprimaryorder = (data, item) => {

    // console.warn("text change called", data);
    return {

        type : CHANGE_SQPB_PRIMARYORDER,
        data : data,
        item : item
    }

}

export const emptydataprimaryorder = () => {

    return {

        type : EMPTY_DATA_PRIMARYORDER,
    }

}