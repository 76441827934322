import React, { useEffect, useState } from "react";
import WareHouseService from "../services/WareHouseService";
import { GodownStockReportComponents } from "./GodownStockReportComponents";
function GodownStockReport() {
  const [warehousename, setWarehousename] = useState("");

  useEffect(() => {
    WareHouseService.getwarehouse().then((response) => {
      setWarehousename(
        response.data.map((user) => ({ value: user.id, label: user.name }))
      );
    });
  }, []);
  console.log(warehousename);

  let header = [
    { label: "Product Name", field: "product_Name" },
    { label: "Quantity", field: "previousclosingkgqty" },
    { label: "(Alt.Units)", field: "previousclosingpcsqty" },
    { label: "Rate", field: "previousclosingrate" },
    { label: "Value", field: "previousclosingamount" },

    { label: "Quantity", field: "inwardkgsqty" },
    { label: "(Alt.Units)", field: "inwardpcsqty" },
    { label: "Rate", field: "inwardrate" },
    { label: "Value", field: "inwardamount" },


    { label: "Quantity", field: "outwardkgqty" },
    { label: "(Alt.Units)", field: "outwardpcsqty" },
    { label: "Rate", field: "outwardrate" },
    { label: "Value", field: "outwardamount" },


    { label: "Quantity", field: "closingkgqty" },
    { label: "(Alt.Units)", field: "closingpcsqty" },
    { label: "Rate", field: "closingstockrate" },
    { label: "Value", field: "closingamount" },
  ];
  const sumofkeys = [{ first: "closingstock" }];
  return (
    <GodownStockReportComponents
      head={header}
      title={"Godown Stock Report"}
      axiostabledata={"reports/godown"}
      Add={""}
      Create={""}
      Edit={""}
      View={""}
      warehousehdata={warehousename}
      Sumofkeys={sumofkeys}
    />
  );
}

export default GodownStockReport;
