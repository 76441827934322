import React from 'react'
import { Mydatatable } from '../Master/Product/Mydatatable'

const Newsalesorder = () => 

{    let header = [
    { "label": "Id", "field": "id" },
    { "label": "Customer Name", "field": "trade_Name" },

    { "label": "SO Date", "field":"sodate" },
    // { "label": "SDN", "field": "sdndate" },
    {'label': 'Taxable', 'field': 'grossamount'},
    { "label": "Tax  ", "field": "igst" },
    { "label": "Grand Total", "field": "grandtotal" },
 { "label": "Remarks", "field": "remarks" },
 { "label": "Created Date", "field": "createddate" },
 { "label": "Created Time", "field": "createdtime" },
 { "label": "Created By", "field": "createbyname" },
 { "label": "Updated Date", "field": "updateddate" },
 { "label": "Updated Time", "field": "updatedtime" },
 { "label": "Updated By", "field": "updatedbyname" },
    { "label": "Action", "field": "action","subaction1":<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"/><circle cx="12" cy="12" r="3"/></svg> }
  ]; 

const sumofkeys=[{"first":'grandtotal'},{"first":'igst'}  ]
  return (
    <Mydatatable
    head={header}
    title={"Sales Order"}
    axiostabledata={'so'}
    Add={"Add SO"}
    Create={'/SalesOrderCreate'}
    Edit={"/SalesOrderView"}
    View={"/SalesOrderView"}
    Sumofkeys={sumofkeys}
    />
  )
}

export default Newsalesorder