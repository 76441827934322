import React, { useState } from 'react';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import axios from 'axios';
import authHeader from '../../services/auth-header';

// Replace this with your actual Google Maps API key
const apiKey = 'AIzaSyAi8GeH0OVLzpqznM3pFy-0Lt6ecDofWro';

const mapStyles = {
  height: '85vh',
  width: '100%',
};

const initialCenter = { lat: 19.0759, lng: 72.8777 };

const Staffmapdis = () => {
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [pincode, setPincode] = useState('');
  const [markers, setMarkers] = useState([
    { id: 1, name: 'Mumbai Railway Station', position: { lat: 19.182748, lng: 73.1977957 } },
    { id: 2, name: 'Mumbai Lokmanya Tilak Terminus', position: { lat: 19.0791, lng: 72.9304 } },
    { id: 3, name: 'Mumbai Dadar Station', position: { lat: 19.0176, lng: 72.8448 } },
    { id: 4, name: 'Mumbai Kurla Station', position: { lat: 19.0975, lng: 72.8790 } },
  ]);
  const [loading, setLoading] = useState(false);

  const handleSearchChange = (event) => {
    setPincode(event.target.value);
  };

  const handleSearchClick = () => {
    setLoading(true); // Start loading
    axios.get(`http://43.225.54.138:8080/scm/api/distributor/fetchlocation/${pincode}`, { headers: authHeader() })
      .then(response => {
        const markersData = response.data.map(item => ({
          id: item.id,
          name: item.name,
          position: {
            lat: item.lattitude,
            lng: item.longitude,
          },
          pincode: item.pincode,
          city: item.city,
          billingAddress: item.billingAddress,
        }));
        setMarkers(markersData);
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false); // End loading
      });
  };
   
  return (

    <div style={{ position: 'relative' }}>
      <div style={{ position: 'absolute', top: '10px', left: '50%', transform: 'translateX(-50%)', zIndex: 1, width: '80%' }}>
        <input
          type="text"
          value={pincode}
          onChange={handleSearchChange}
          placeholder="Enter pincode..."
          style={{
            width: '80%',
            padding: '10px',
            borderRadius: '5px',
            border: '1px solid #ccc',
            boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
            fontSize: '16px',
            marginRight: '10px',
          }}
        />
        <button
          onClick={handleSearchClick}
          disabled={loading}
          style={{
            padding: '10px',
            borderRadius: '5px',
            border: '1px solid #ccc',
            backgroundColor: loading ? '#6c757d' : '#007bff',
            color: '#fff',
            fontSize: '16px',
            cursor: loading ? 'not-allowed' : 'pointer',
          }}
        >
          {loading ? 'Loading...' : 'Search'}
        </button>
      </div>
      <LoadScript googleMapsApiKey={apiKey}>
        <GoogleMap
          mapContainerStyle={mapStyles}
          center={initialCenter}
          zoom={6}
        >
          {markers.map(marker => (
            <Marker
              key={marker.id}
              position={marker.position}
              onClick={() => setSelectedMarker(marker)}
            />
          ))}
          {selectedMarker && (
            <InfoWindow
              position={selectedMarker.position}
              onCloseClick={() => setSelectedMarker(null)}
            >
              <div style={{background:"#163E94"}}>
                <h3 style={{fontWeight:600,color:"white"}}>{selectedMarker.name}</h3>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      </LoadScript>
    </div>
  
);
};

export default Staffmapdis;
