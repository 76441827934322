import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Row, Form, Col, Button } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import ReatilerService from "../../services/Reatiler";
import UserService from "../../services/user.service";
import DistrbutorService from "../../services/Distrbutor";
import TemporaryReatilerService from "../../services/TemporaryRetailer";
import { MDBCard } from "mdbreact";

export default function Create() {
    const [ase, setase] = useState([]);
    const [asm, setasm] = useState([]);
    const [rsm, setrsm] = useState([]);

    /* Retailer Id */
    const { id } = useParams();
const[aadharcard,setaadharcard]=useState('')   
 const [nsm, setNsm] = useState([{}]);

    const [tradeName, settradeName] = useState('');
    const [billingAddress, setbillingAddress] = useState('');
    const [deliveryAddress, setdeliveryAddress] = useState([]);
    const [country, setcountry] = useState({ value: "101", label: "India" });
    const [statecode, setstatecode] = useState('');
    const [city, setcity] = useState('');
    const [panNumber, setpanNumber] = useState('');
    const [gstNumber, setgstNumber] = useState('');
    const [gstType, setgstType] = useState('');
    const [pinCode, setpinCode] = useState('');
    const [perMobileNumber, setperMobileNumber] = useState('');
    const [alterMobileNumber, setalterMobileNumber] = useState('');
    const [perEmail, setperEmail] = useState('');
    const [alterEmail, setalterEmail] = useState('');
    const [creditLimit, setcreditLimit] = useState('');
    const [creditDays, setcreditDays] = useState('');
    const [transporterName, settransporterName] = useState('');
    const [deliveryLocation, setdeliveryLocation] = useState('');
    const [countryoptions, setCountryoptions] = useState({ value: "101", label: "India" });
    const [boxProductDiscount, setboxProductDiscount] = useState('');
    const [schemeboxProductDiscount, setschemeboxProductDiscount] = useState('0');
    const [kgProductDiscount, setkgProductDiscount] = useState('');
    const [schemekgProductDiscount, setschemekgProductDiscount] = useState('0');
    const [corporaetProductDiscount, setcorporaetProductDiscount] = useState('');
    const [schemecorporateProductDiscount, setschemecorporateProductDiscount] = useState('0');
    const [cookerProductDiscount, setcookerProductDiscount] = useState('');
    const [schemecookerProductDiscount, setschemecookerProductDiscount] = useState('0');
    const [noshProductDiscount, setnoshProductDiscount] = useState('');
    const [schemenoshProductDisocunt, setschemenoshProductDisocunt] = useState('0');
    const [retailerName1, setretailerName1] = useState('');
    const [retailerName2, setretailerName2] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('');
    const [roles1, setRoles1] = useState('');
    const [dob1, setdob1] = useState('');
    const [dob2, setdob2] = useState('');
    const [doa1, setdoa1] = useState('');
    const [doa2, setdoa2] = useState('');
    const [mobNo1, setmobNo1] = useState('');
    const [mobNo2, setmobNo2] = useState('');
    const [email1, setemail1] = useState('');
    const [email2, setemail2] = useState('');
    const [aseid, setaseid] = useState('');
    const [asmid, setasmid] = useState('');
    const [rsmid, setrsmid] = useState('');
    // const [nsm, setNsm] = useState([]);
    const [nsmid, setnsmid] = useState('');
    const [zonesid, setzonesid] = useState('');
    const [stateid, setstateid] = useState('');
    const [distrubatorid, setdistrubatorid] = useState('');
    const [distrubatorName, setdistrubatorName] = useState('');
    const [authorized, setunauthorized] = useState('');
    const [error, setError] = useState(false);
    const [zoneValue, setZoneValue] = useState([]);
    const [zoneData, setZoneData] = useState([]);
    const [zoneStateData, setZoneStateData] = useState([]);
    const [statevalue, setstatevalue] = useState([]);
    const [stateData, setstateData] = useState([]);
    const [RsmValue, setRsmValue] = useState([]);
    const [RsmData, setRsmData] = useState([]);
    const [AsmValue, setAsmValue] = useState([]);
    const [AsmData, setAsmData] = useState([]);
    const [AseValue, setAseValue] = useState([]);
    const [AseData, setAseData] = useState([]);
    const [distributordata, setDistributordata] = useState([]);
    const [distributorvaluedata, setDistributorvaluedata] = useState([]);
    const [distributorvalue, setDistributorvalue] = useState([]);
    const[referredBy,setreferredBy]=useState('');
    const[longitude,setLongitude]=useState('');
    const[latitude,setLatitude]=useState('')
    /* Navigator */
    const navigate = useNavigate();
    let handleChange = (i, e) => {
        let newFormValues = [...deliveryAddress];
        newFormValues[i][e.target.name] = e.target.value;
        setdeliveryAddress(newFormValues);
      }
    
      let addFormFields = () => {
        setdeliveryAddress([...deliveryAddress, { delivery_address: '', state: '', pincode: '' }])
      }
    
      let removeFormFields = (i) => {
        let newFormValues = [...deliveryAddress];
        newFormValues.splice(i, 1);
        setdeliveryAddress(newFormValues)
      }
    useEffect(() => {
        /* Reatiler Data */
        TemporaryReatilerService.gettemporaryRetailerGet(id).then(res => {

            settradeName(res.data.tradeName);
            setbillingAddress(res.data.billingAddress);
            const modifiedDeliveryAddress = res.data.deliveryAddress.map(item => {
                const { id, ...rest } = item;
                return rest; 
            });
            
            setdeliveryAddress(modifiedDeliveryAddress);
            setcity(res.data.city);
            setcountry(res.data.country);
            // setstatecode(res.data.statecode);
            setpanNumber(res.data.panNumber);
            setgstNumber(res.data.gstNumber);
            setgstType(res.data.gstType);
            setpinCode(res.data.pinCode);
            setperMobileNumber(res.data.perMobileNumber);
            setalterMobileNumber(res.data.alterMobileNumber);
            setperEmail(res.data.perEmail);
            setalterEmail(res.data.alterEmail);
            setcreditLimit(res.data.creditLimit);
            setcreditDays(res.data.creditDays);
            getallstaff(res.data.stateid)
            settransporterName(res.data.transporterName);
            setdeliveryLocation(res.data.deliveryLocation);
            setretailerName1(res.data.retailerName1);
            setretailerName2(res.data.retailerName2); let zone = res.data.zonesid;
            let state = res.data.stateid;
            let dis_Id = res.data.distributorId;
            UserService.getZoneData().then(res1 => {
                (res1.data).map(user => {
                    if (user.id == zone) {
                        console.log( zone)
                        setZoneValue({ value: user.id, label: user.zoneName });
                        /* State Data */
                        (user.state_zone).map(item => {
                            if (item.id == state) {
                                setstatevalue({ value: item.id, label: item.state_name });
                            }
                        });
                        setstateData((user.state_zone).map(user => ({ value: user.id, label: user.state_name })));   /* State Data */
                    }
                });
                setZoneData((res1.data).map(user => ({ value: user.id, label: user.zoneName })));
                setZoneStateData(res1.data);
            });

            /* RSM Data */
            UserService.getRSMData(zone).then(res => {
                setRsmData((res.data).map(user => ({ value: user.id, label: user.staffName }))) });
console.log(res.data.roles )
            setRoles1(res.data.roles[0].id)
            setRole(res.data.roles[0].name)
            setdob1(res.data.dob1);
            setLatitude(res.data.latitude);
            setLongitude(res.data.longitude);
            setdob2(res.data.dob2);
            setdoa1(res.data.doa1);
            setdoa2(res.data.doa2);
            setmobNo1(res.data.mobNo1);
            setmobNo2(res.data.mobNo2);
            setemail1(res.data.email1);
            setemail2(res.data.email2);
            setzonesid(res.data.zonesid);
            setstateid(res.data.stateid);
            setunauthorized(res.data.authorized);
            setreferredBy(res.data.referredBy);
            setaadharcard(res.data.aadharcard);
            setdistrubatorid(res.data.distributorId);
            /* Zone */
           

            /* Distrbutor Data */
            let dist = [];
            DistrbutorService.getReatilerTable().then(res => {
                setDistributorvaluedata(res.data);
                (res.data).map(user => {
                    if (user.stateid == state) {
                        dist.push(user);
                    }
                    if(user.id == dis_Id){
                        setDistributorvalue({value:user.id,label:user.trade_name })
                    }
                });
                setDistributordata(dist.map(user => ({ value: user.id, label: user.trade_name })));
            });
        });

        /* NSM Data */
        UserService.getNSMData().then(res => {
            setNsm((res.data).map(user => ({ value: user.id, label: user.staffName })))

        });


    }, []);

    /* Zone Data Change */
    const onChangeZone = (e) => {
        setzonesid(e.value);
        setstatevalue('');
        setRsmValue('');
        setAsmValue('');
        setAseValue('');
        setDistributorvalue('');
        /* Zone */
        zoneStateData.map(user => {
            if (user.id == e.value) {
                setZoneValue({ value: user.id, label: user.zoneName });
                setstateData((user.state_zone).map(user => ({ value: user.id, label: user.state_name }))); /* Zone Cahneg State Data */
            }
        });

        /* RSM Data */
        UserService.getRSMData(e.value).then(res => {
            setRsmData((res.data).map(user => ({ value: user.id, label: user.staffName })))
        });
    };

    /* State Data Change */

    const onChangeState = (e) => {
        let name = e.value;
        setstateid(e.value);

        /* state */
        stateData.map(user => {
            if (user.value === name) {
                setstatevalue({ value: user.value, label: user.label })
                
            }
        });
        setrsmid([])
        /* Distributor Data */
        setrsmid([])
        setnsmid([])
        setaseid([])
        setasmid([])
         /* Distributor Data */
         let dist = [];
         distributorvaluedata.map(user => {
             if (user.stateid == name) {
                 dist.push(user);
             }
         })
 
         setDistributordata(dist.map(user => ({ value: user.id, label: user.trade_name })));
         getallstaff(name)
    };
    /* NSM Change */
    // const onNsm = (e) => {
    //     setnsmid(e.value);
    // }
    // /* RSM Data Change */

    // const onChangeRsm = (e) => {
    //     let name = e.value;
    //     setrsmid(e.value);
    //     setRsmValue({ value: e.value, label: e.label });

    //     /* ASM Data */
    //     UserService.getASMData(name).then(res => {
    //         setAsmData((res.data).map(user => ({ value: user.id, label: user.staffName })))
    //     });

    //     /* ASE Data */
    //     UserService.getASEData1(name).then(res => {
    //         setAseData((res.data).map(user => ({ value: user.id, label: user.staffName })))
    //     });
    // };

    // /* ASM Data Change */
    // const onChangeAsm = (e) => {
    //     let name = e.value;
    //     setasmid(e.value);
    //     setAsmValue({ value: e.value, label: e.label });

    //     /* ASE Data */
    //     UserService.getASEData(name).then(res => {
    //         setAseData((res.data).map(user => ({ value: user.id, label: user.staffName })))
    //     });
    // };

    // /* ASE Data Change */
    // const onChangeAse = (e) => {
    //     let name = e.value;
    //     setaseid(e.value);
    //     setAseValue({ value: e.value, label: e.label });
    // }

     /* Distributor Data Change */
     const onNsm = (e) => {
        setnsmid(e.map((item)=>({ value: item.value, label: item.label })));
    };
    const onRsm = (e) => {
        // setase('');
        // setasm('');
        // let name = e.value;
        console.log(e)
        setrsmid(e.map((item)=>({ value: item.value, label: item.label })));
        
        // console.log(({ value: user.id, label: user.state_name }))
        // setasmvalue('');
        // setasevalue('');
        // setRsmvalue({ value: (e.value), label: (e.label) })
        /* ASM DATA */
        // UserService.getASMData(name).then(res => {
        //     setasm([{ value: "NAN", label: "NA" }, ...(res.data).map(user => ({ value: user.id, label: user.staffName }))])
        // });
    
        /* ASE Data */
        // UserService.getASEData1(name).then(res => {
        //     setase([{ value: "NAN", label: "NA" }, ...(res.data).map(user => ({ value: user.id, label: user.staffName }))])
        // });
    
    };
        
    const getallstaff=(id)=>{
        DistrbutorService.getstaffforret(id).then(res => {
          // setDistributordata(res.data);
          setasm([res.data.filter((item)=>item.rolename=="ROLE_ASM").map(user => ({ value: user.id, label: user.staff_name}))])
          setrsm([res.data.filter((item)=>item.rolename=="ROLE_RSM").map(user => ({ value: user.id, label: user.staff_name}))])
          setNsm([res.data.filter((item)=>item.rolename=="ROLE_NSM").map(user => ({ value: user.id, label: user.staff_name}))])
    
          // setNsm
          setase([res.data.filter((item)=>item.rolename=="ROLE_ASE").map(user => ({ value: user.id, label: user.staff_name}))])
          console.log([res.data.filter((item)=>item.rolename=="ROLE_ASE").map(user => ({ value: user.id, label: user.staff_name}))])
      });
    }
    /* ASM Change */
    const onAsm = (e) => {
        // setase('');
        setasmid(e.map((item)=>({ value: item.value, label: item.label })));
     
    
    };
    
    /* ASE Chnage */
    const onAse = (e) => {
        setaseid(e.map((item)=>({ value: item.value, label: item.label })));
        console.log(e.map((item)=>item.value))
        
    };
     const onChangeDistributor = (e) =>{
        setdistrubatorid(e.value);
        setDistributorvalue({ value: e.value, label: e.label });
     }
    /* Date Formate Change */
    const datevalue_1 = new Date(dob1);
    const defaultValue_1 = datevalue_1.toLocaleDateString('en-CA');
    const datevalue_2 = new Date(dob2);
    const defaultValue_2 = datevalue_2.toLocaleDateString('en-CA');
    const datevalue1 = new Date(doa1);
    const defaultValue1 = datevalue1.toLocaleDateString('en-CA');
    const datevalue2 = new Date(doa2);
    const defaultValue2 = datevalue2.toLocaleDateString('en-CA');


    const SubmitForm = () => {
        // const deliveryAddress=[{ delivery_address: '',state:"",pincode:"" }]
        const retailerToStaffs = [{
            'asmid': asmid.length > 0 ? asmid.map((item)=>item.value) : [],
            'rsmid': rsmid.length > 0 ? rsmid.map((item)=>item.value) : [],
            'aseid':  aseid.length > 0 ? aseid.map((item)=>item.value): [],
            'nsmid':   nsmid.length > 0 ? nsmid.map((item)=>item.value): []
        }];
        let roleId = roles1 ;
        const distributor = { "id": distrubatorid }
        let activestatus = "";
        let DiscountStructure = '';
        let tempretid = Number(id);
        const Retailer = {
            tradeName,
            transporterName,
            billingAddress,
            deliveryAddress:deliveryAddress,
            gstNumber,
            panNumber,
            activestatus,
            country,
            city,
            pinCode,
            gstType,
            perMobileNumber,
            alterMobileNumber,
            retailerToStaffs,
            perEmail,
            alterEmail,
            creditLimit,
            creditDays,
            longitude,
            latitude,
            deliveryLocation,
            boxProductDiscount,
            schemeboxProductDiscount,
            kgProductDiscount,
            schemekgProductDiscount,
            corporaetProductDiscount,
            schemecorporateProductDiscount,
            cookerProductDiscount,
            schemecookerProductDiscount,
            noshProductDiscount,
            schemenoshProductDisocunt,
            retailerName1,
            retailerName2,
            dob1,
            dob2,
            doa1,
            doa2,
            mobNo1,
            mobNo2,
            email1,
            email2,
            // aseid,
            // asmid,
            // rsmid,
            // nsmid,
            zonesid,
            stateid,
            password,
            roleId,
            DiscountStructure,
            distributor,
            authorized,
            tempretid,
            referredBy,
            aadharcard
        }
        
        if (referredBy.length !==0 && tradeName.length !== 0 && billingAddress.length !== 0 && deliveryAddress.length !== 0 && gstNumber.length !== 0 && zonesid.length !== 0 && authorized.length !== 0 && stateid.length !== 0 && city.length !== 0 && perMobileNumber.length !== 0 && gstType.length !== 0 && creditDays.length !== 0 && perEmail.length !== 0 &&  creditLimit.length !== 0 && transporterName !== 0 && retailerName1 !== 0 && (mobNo1.toString().length)==10 && defaultValue_1 !== 0 && distrubatorName !== 0 && password !== 0) {
            setError(false)
            TemporaryReatilerService.gettemporarycompanyEdit(Retailer).then(res => {
                navigate('/Newretailer/1');
            })
        }
        else {
            setError(true)
        }
        // TemporaryReatilerService.gettemporarycompanyEdit(Retailer).then(res => {
        //             navigate('/Newretailer/1');
        //         })

    }





    return (
        <MDBCard data-aos="fade">
         <div className='d-flex bd-highlight'>
        <div className="card-header card-title w-100">CREATE RETAILER</div>
        <Link className='p-2 w-200 card-header card-title bd-highlight' to={'/temporaryretailer/0'}><Button>Back</Button></Link>
      </div>
        <div className="card">
            
            <div className='card card-body'>
                <Row className="mb-1">
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Referred By*</Form.Label>
                        <Form.Control type="text" autoComplete="off" onChange={(e) => setreferredBy(e.target.value)} style={{ borderColor: error && referredBy.length == 0 ? "red" : "" }} name="referredBy" value={referredBy} />
                        {error && referredBy.length === 0 ? <p style={{ color: error && referredBy.length === 0 ? "red" : " " }}>These field is Required</p> : ""}

                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Trade Name*</Form.Label>
                        <Form.Control type="text" autoComplete="off" onChange={(e) => settradeName(e.target.value)} style={{ borderColor: error && tradeName.length == 0 ? "red" : "" }} name="tradeName" value={tradeName} />
                        {error && tradeName.length === 0 ? <p style={{ color: error && tradeName.length === 0 ? "red" : " " }}>These field is Required</p> : ""}

                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Billing Address*</Form.Label>
                        <Form.Control type="text" autoComplete="off" value={billingAddress} name="billingAddress" style={{ borderColor: error && billingAddress.length == 0 ? "red" : "" }} onChange={(e) => setbillingAddress(e.target.value)} />
                        {error && billingAddress.length === 0 ? <p style={{ color: error && billingAddress.length === 0 ? "red" : " " }}>These field is Required</p> : ""}

                    </Form.Group>

{/* 
                    <Form.Group as={Col} md="6" controlId="formBasicName">

                        <Form.Label className="cname2">Delivery Address*</Form.Label>
                        <Form.Control type="text" autoComplete="off" value={deliveryAddress} style={{ borderColor: error && deliveryAddress.length == 0 ? "red" : "" }} name="deliveryAddress" onChange={(e) => setdeliveryAddress(e.target.value)} />
                        {error && deliveryAddress.length === 0 ? <p style={{ color: error && deliveryAddress.length === 0 ? "red" : " " }}>These field is Required</p> : ""}
                    </Form.Group> */}

                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">GST No*</Form.Label>
                        <Form.Control type="text" autoComplete="off" value={gstNumber} style={{ borderColor: error && gstNumber.length == 0 ? "red" : "" }} name="gstNumber" onChange={(e) => setgstNumber(e.target.value)} />
                        {error && gstNumber.length === 0 ? <p style={{ color: error && gstNumber.length === 0 ? "red" : " " }}>These field is Required</p> : ""}
                        
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Pan Number*</Form.Label>
                        <Form.Control type="text" autoComplete="off" value={panNumber} name="panNumber" onChange={(e) => setpanNumber(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Aadhar card Number</Form.Label>
                        <Form.Control type="text" autoComplete="off" value={aadharcard} name="panNumber" onChange={(e) => setaadharcard(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Country</Form.Label>
                        <Select value={countryoptions} />

                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Zone*</Form.Label>
                        <Select value={zoneValue} options={zoneData} style={{ borderColor: error && zonesid.length == 0 ? "red" : "" }} onChange={(e) => onChangeZone(e)} />
                        {error && zonesid.length === 0 ? <p style={{ color: error && zonesid.length === 0 ? "red" : " " }}>These field is Required</p> : ""}

                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">State*</Form.Label>
                        <Select value={statevalue} options={stateData} onChange={(e) => onChangeState(e)} style={{ borderColor: error && stateid.length == 0 ? "red" : "" }} />
                        {error && stateid.length === 0 ? <p style={{ color: error && stateid.length === 0 ? "red" : " " }}>These field is Required</p> : ""}

                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Authorized*</Form.Label>
                        <Form.Select value={authorized} style={{ borderColor: error && authorized.length == 0 ? "red" : "" }} onChange={(e) => setunauthorized(e.target.value)}>
                            <option>Select</option>
                            <option value='false'>Under&nbsp;Authorized</option>
                            <option value='true'>Authorized</option>
                            {error && authorized.length === 0 ? <p style={{ color: error && authorized.length === 0 ? "red" : " " }}>These field is Required</p> : ""}

                        </Form.Select>

                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">City*</Form.Label>
                        <Form.Control type="text" autoComplete="off" style={{ borderColor: error && city.length == 0 ? "red" : "" }} value={city} name="city" onChange={(e) => setcity(e.target.value)} />
                        {error && city.length === 0 ? <p style={{ color: error && city.length === 0 ? "red" : " " }}>These field is Required</p> : ""}

                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Zip Code*</Form.Label>
                        <Form.Control type="number" autoComplete="off" style={{ borderColor: error && pinCode.length == 0 ? "red" : "" }} value={pinCode} name="pinCode" onChange={(e) => setpinCode(e.target.value)} />
                        {error && pinCode.length === 0 ? <p style={{ color: error && pinCode.length === 0 ? "red" : " " }}>These field is Required</p> : ""}

                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Gst Type*</Form.Label>
                        <Form.Select value={gstType} style={{ borderColor: error && gstType.length == 0 ? "red" : "" }} name="gstType" onChange={(e) => setgstType(e.target.value)}>
                            <option>Select</option>
                            <option value='unregister'>UnRegister</option>
                            <option value='register'>Register</option>
                            <option value='composition'>Composition</option>
                            <option value='consumer'>Consumer</option>
                        </Form.Select>
                        {error && gstType.length === 0 ? <p style={{ color: error && gstType.length === 0 ? "red" : " " }}>These field is Required</p> : ""}

                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Mobile No*</Form.Label>
                        <Form.Control type="number" autoComplete="off" style={{ borderColor: error && perMobileNumber.length !== 10 ? "red" : "" }} value={perMobileNumber} name="perMobileNumber" onChange={(e) => setperMobileNumber(e.target.value)} />
                        {error && perMobileNumber.length !== 10 ? <p style={{ color: error && perMobileNumber.length !== 10 ? "red" : " " }}>These field is Required</p> : ""}

                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Alter Mobile No</Form.Label>
                        <Form.Control type="number" autoComplete="off"  value={alterMobileNumber} name="alterMobileNumber" onChange={(e) => setalterMobileNumber(e.target.value)} />
                        
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Email*</Form.Label>
                        <Form.Control type="email" autoComplete="off" value={perEmail} style={{ borderColor: error && perEmail.length === 0 ? "red" : "" }} name="perEmail" onChange={(e) => setperEmail(e.target.value)} />
                        {error && perEmail.length === 0 ? <p style={{ color: error && perEmail.length === 0 ? "red" : " " }}>These field is Required</p> : ""}

                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Alter Email</Form.Label>
                        <Form.Control type="email" autoComplete="off" value={alterEmail}  name="alterEmail" onChange={(e) => setalterEmail(e.target.value)} />
                        
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Credit Limit*</Form.Label>
                        <Form.Control type="number" autoComplete="off" value={creditLimit} style={{ borderColor: error && creditLimit.length === 0 ? "red" : "" }} name="creditLimit" onChange={(e) => setcreditLimit(e.target.value)} />
                        {error && creditLimit.length === 0 ? <p style={{ color: error && creditLimit.length === 0 ? "red" : " " }}>These field is Required</p> : ""}

                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Credit Days*</Form.Label>
                        <Form.Control type="number" autoComplete="off" value={creditDays} name="creditDays" style={{ borderColor: error && creditDays.length === 0 ? "red" : "" }} onChange={(e) => setcreditDays(e.target.value)} />
                        {error && creditDays.length === 0 ? <p style={{ color: error && creditDays.length === 0 ? "red" : " " }}>These field is Required</p> : ""}

                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Transporter Name*</Form.Label>
                        <Form.Control type="text" autoComplete="off" value={transporterName} style={{ borderColor: error && transporterName.length === 0 ? "red" : "" }} name="transporterName" onChange={(e) => settransporterName(e.target.value)} />
                        {error && transporterName.length === 0 ? <p style={{ color: error && transporterName.length === 0 ? "red" : " " }}>These field is Required</p> : ""}

                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Delivery Location</Form.Label>
                        <Form.Control type="text" autoComplete="off" value={deliveryLocation} name="deliveryLocation" onChange={(e) => setdeliveryLocation(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="formBasicName">
                        <Form.Label className="cname2">Box&nbsp;Product&nbsp;Trade&nbsp;Discount</Form.Label>
                        <Form.Control type="number" autoComplete="off" value={boxProductDiscount} name="boxProductDiscount" onChange={(e) => setboxProductDiscount(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="formBasicName">
                        <Form.Label className="cname2">Box&nbsp;Product&nbsp;Scheme&nbsp;Discount</Form.Label>
                        <Form.Control type="number" autoComplete="off" value={schemeboxProductDiscount} name="schemeboxProductDiscount" onChange={(e) => setschemeboxProductDiscount(e.target.value)} />
                    </Form.Group>

                    <Form.Group as={Col} md="3" controlId="formBasicName">
                        <Form.Label className="cname2">KG&nbsp;Product&nbsp;Trade&nbsp;Discount</Form.Label>
                        <Form.Control type="number" autoComplete="off" value={kgProductDiscount} name="kgProductDiscount" onChange={(e) => setkgProductDiscount(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="formBasicName">
                        <Form.Label className="cname2">KG&nbsp;Product&nbsp;Scheme&nbsp;Discount</Form.Label>
                        <Form.Control type="number" autoComplete="off" value={schemekgProductDiscount} name="schemekgProductDiscount" onChange={(e) => setschemekgProductDiscount(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="formBasicName">
                        <Form.Label className="cname2">Corporate&nbsp;Product&nbsp;Trade&nbsp;Discount</Form.Label>
                        <Form.Control type="number" autoComplete="off" value={corporaetProductDiscount} name="corporateProductDiscount" onChange={(e) => setcorporaetProductDiscount(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="formBasicName">
                        <Form.Label className="cname2">Corporate&nbsp;Product&nbsp;Scheme&nbsp;Discount</Form.Label>
                        <Form.Control type="number" autoComplete="off" value={schemecorporateProductDiscount} name="schemecorporateProductDiscount" onChange={(e) => setschemecorporateProductDiscount(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="formBasicName">
                        <Form.Label className="cname2">Cooker&nbsp;Product&nbsp;Trade&nbsp;Discount</Form.Label>
                        <Form.Control type="number" autoComplete="off" value={cookerProductDiscount} name="cookerProductDiscount" onChange={(e) => setcookerProductDiscount(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="formBasicName">
                        <Form.Label className="cname2">Cooker&nbsp;Product&nbsp;Scheme&nbsp;Discount</Form.Label>
                        <Form.Control type="number" autoComplete="off" value={schemecookerProductDiscount} name="schemecookerProductDiscount" onChange={(e) => setschemecookerProductDiscount(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="formBasicName">
                        <Form.Label className="cname2">Nosh&nbsp;Product&nbsp;Trade&nbsp;Discount</Form.Label>
                        <Form.Control type="number" autoComplete="off" value={noshProductDiscount} name="noshProductDiscount" onChange={(e) => setnoshProductDiscount(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="formBasicName">
                        <Form.Label className="cname2">NOSH&nbsp;Product&nbsp;Scheme&nbsp;Discount</Form.Label>
                        <Form.Control type="number" autoComplete="off" value={schemenoshProductDisocunt} name="schemenoshProductDisocunt" onChange={(e) => setschemenoshProductDisocunt(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Retailer Name1*</Form.Label>
                        <Form.Control type="text" autoComplete="off" style={{ borderColor: error && retailerName1.length === 0 ? "red" : "" }} value={retailerName1} name="retailerName1" onChange={(e) => setretailerName1(e.target.value)} />
                        {error && retailerName1.length === 0 ? <p style={{ color: error && retailerName1.length === 0 ? "red" : " " }}>These field is Required</p> : ""}

                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Retailer Name&nbsp;2</Form.Label>
                        <Form.Control type="text" autoComplete="off" value={retailerName2} name="retailerName2" onChange={(e) => setretailerName2(e.target.value)} />
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Date&nbsp;of&nbsp;Brith&nbsp;1*</Form.Label>
                        <Form.Control type="date" autoComplete="off" style={{ borderColor: error && defaultValue_1.length === 0 ? "red" : "" }} value={defaultValue_1} name="dob1" onChange={(e) => setdob1(e.target.value)} />
                        {error && defaultValue_1.length === 0 ? <p style={{ color: error && defaultValue_1.length === 0 ? "red" : " " }}>These field is Required</p> : ""}

                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Date&nbsp;of&nbsp;Brith&nbsp;2</Form.Label>
                        <Form.Control type="date" autoComplete="off" value={defaultValue_2} name="dob2" onChange={(e) => setdob2(e.target.value)} />
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Date&nbsp;of&nbsp;Anniversary&nbsp;1</Form.Label>
                        <Form.Control type="date" autoComplete="off" value={defaultValue1} name="doa1" onChange={(e) => setdoa1(e.target.value)} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Date&nbsp;of&nbsp;Anniversary&nbsp;2</Form.Label>
                        <Form.Control type="date" autoComplete="off" value={defaultValue2} name="doa2" onChange={(e) => setdoa2(e.target.value)} />
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Mobile No&nbsp;1*</Form.Label>
                        <Form.Control type="number" autoComplete="off" style={{ borderColor: error && (mobNo1.toString().length) !== 10 ? "red" : "" }} value={mobNo1} name="mobNo1" onChange={(e) => setmobNo1(e.target.value)} />
                        {error && (mobNo1.toString().length) !== 10 ? <p style={{ color: error && (mobNo1.toString().length) !== 10 ? "red" : " " }}>These field is Required</p> : ""}

                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Mobile No&nbsp;2</Form.Label>
                        <Form.Control type="number" autoComplete="off" value={mobNo2} name="mobNo2" onChange={(e) => setmobNo2(e.target.value)} />
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Email&nbsp;1*</Form.Label>
                        <Form.Control type="email" autoComplete="off" value={email1} style={{ borderColor: error && email1.length === 0 ? "red" : "" }} name="email1" onChange={(e) => setemail1(e.target.value)} />
                        {error && email1.length === 0 ? <p style={{ color: error && email1.length !== 10 ? "red" : " " }}>These field is Required</p> : ""}
                    
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Email&nbsp;2</Form.Label>
                        <Form.Control type="email" autoComplete="off" value={email2} name="email2" onChange={(e) => setemail2(e.target.value)} />
                    </Form.Group>

                    <Form.Group as={Col} md="6" className="ert1" controlId="formBasicName">
                            <Form.Label className="cname2">NSM</Form.Label>
                            <Select options={nsm[0]} value={nsmid} isMulti onChange={(e) => { onNsm(e) }} />
                        </Form.Group>
                        <Form.Group as={Col} md="6" className="ert2" controlId="formBasicName">
                            <Form.Label className="cname2">RSM</Form.Label>
                            <Select   isMulti options={rsm[0]} value={rsmid}  onChange={(e) => { onRsm(e) }} />
                        </Form.Group>
                        {console.log(rsmid)}
                        <Form.Group as={Col} md="6" className="ert1" controlId="formBasicName">
                            <Form.Label className="cname2">ASM</Form.Label>
                            <Select isMulti options={asm[0]} value={asmid} onChange={(e) => { onAsm(e) }} />
                        </Form.Group>
                        <Form.Group as={Col} md="6" className="ert2" controlId="formBasicName">
                            <Form.Label className="cname2">ASE</Form.Label>
                            <Select options={ase[0]} isMulti value={aseid} onChange={(e) => { onAse(e) }} />

                        </Form.Group>

                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Password</Form.Label>
                        {/* <Form.Control type="text" value={password} name="password" onChange={(e) => setPassword(e.target.value)} /> */}
                        <Form.Control type="text" autoComplete="off" name="password" onChange={(e) => setPassword(e.target.value)} />

                    </Form.Group>
                  
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Roles</Form.Label>
                        <Form.Control type="text" value={role} />
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Latitude</Form.Label>
                        <Form.Control type="text" value={latitude} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Longitude</Form.Label>
                        <Form.Control type="text" value={longitude} />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="cname2">Distributor Trade Name</Form.Label>
                        <Select value={distributorvalue} options={distributordata} name="distrubatorid" onChange={(e)=>onChangeDistributor(e)}/>
                    </Form.Group>

                    {
                  deliveryAddress!==undefined?  deliveryAddress.map((address, index) => (
                  <div key={index}>
                    <div className="flexleft"> <label>Address</label><button type='button' className="btn btn-sm red white delete" onClick={() => removeFormFields(index)}>
                      X
                    </button></div>
                    <textarea
                      type='text'
                      name='delivery_address'
                      className="form-control"
                      value={address.delivery_address}
                      onChange={(e) => handleChange(index, e)}
                    ></textarea>
                    <label className="form-label">Statename</label>
                    <input type="text" onChange={(e) => handleChange(index, e)} name='state' className="form-control" value={address.state} />
                    <label className="form-label">Pincode</label>
                    <input type="number" onChange={(e) => handleChange(index, e)} name='pincode' className="form-control" value={address.pincode} />

                    {/* <button type='button' className="btn" onClick={() => removeFormFields(index)}>
            Remove Address
          </button> */}
                  </div>
                )):""}
  <div className="row">
                  <div className="col">
                    <button type='button' className="btn btn-sm btn-primary" onClick={addFormFields}>
                      +
                    </button>
                  </div>
                </div>

                </Row>

                <Row>
                    <Col>  <Button variant="primary" type="button" onClick={SubmitForm} >
                        Submit
                    </Button></Col>

                </Row>

            </div>
        </div>
        </MDBCard>
    )
}