import React from 'react'
import { Mydatatable } from './Mydatatable';
// import { Mydatatable } from './Mydatatable';

export const Newtable = () => {

    let header = [
        { "label": "Id", "field": "id" },
        { "label": "Product Name", "field": "product_Name" },
        { "label": "Standard Qty Per Box", "field": "standard_Qty_Per_Box" },
        { "label": "Group", "field": "product_Group" },
        { "label": "Ean Code", "field": "ean_Code" },
        { "label": "Capacity", "field": "capacity" },
        { "label": "Dlp", "field": "dlp" },
        { "label": "Diameter", "field": "diameter" },
        // 
        { "label": "MRP(₹)", "field": "mrp" },
        { "label": "Created Date", "field": "createddate" },
        { "label": "Created Time", "field": "createdtime" },
        // { "label": "Created By", "field": "createdtime" },
        // { "label": "Updated By", "field": "createdtime" },
        { "label": "Action", "field": "action","subaction1":<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"/><circle cx="12" cy="12" r="3"/></svg> }
      ];


      const sumofkeys=[{"first":'mrp'} ]
      // Sumofkeys={sumofkeys}


  return (
    <Mydatatable
    head={header}
    title={"Product"}
    axiostabledata={'product'}
    Add={"Add product"}
    Create={'/pcreate'}
    Edit={"/pview"}
    View={"/pview"}
    Sumofkeys={sumofkeys}
    />
  )
}
