import axios from "axios";
import React, { useEffect, useState } from "react";
import AuthService from "../services/auth.service";
import authHeader from "../services/auth-header";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import { CSVLink } from "react-csv";
import Select from "react-select"; // import  from '../Retailer/Edit';

export const TransportReportComponent = ({
  warehouseId,
  head,
  axiostabledata,
  Edit,
  View,
  Add,
  title,
  Create,
  Sumofkeys,
  warehousehdata,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState(encodeURIComponent(" "));
  const [number, setnumber] = useState([]);
  const [total, setotal] = useState([]);
  const [size, setsize] = useState([]);
  const [getid, setgetid] = useState(0);
  const [limit, setlimit] = useState(10);
  const [sort1, setsort1] = useState(true);
  const [sort, setsort] = useState("asc");
  const [fieldnme, setfeilds] = useState("voucherDate");
  const [data, setdata] = useState([]);
  const [superid, setsuperid] = useState(0);
  const [exportpage, setexport] = useState([]);
  const [data1, setdata1] = useState(10);
  const [entries, setentries] = useState([]);
  const { pointindex } = useParams();
  const [warehousehvalue, setwarehousehvalue] = useState([]);
  const [warehousehvaluedb, setwarehousehvaluedb] = useState([]);
  const [trasportReportData, setTransportReportData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [boleean, setboleean] = useState(false);

  const pagination = (msg) => {
    //  console.log(msg);

    setgetid(msg);
  };
  const handlechange = (entry) => {
    setdata1(entry);
    setlimit(entry);
  };

  const count = [{ value: 10 }, { value: 20 }, { value: 30 }, { value: 50 }];

  const searchhandle = (search) => {
    if (search.length == 0) {
      setError(encodeURIComponent(" "));
    } else {
      setError(search);
    }
  };

  //   useEffect(() => {
  //     setCurrentPage(Number(pointindex))
  //     handlePageChange(Number(pointindex))
  //   }, [   ])

  // console.log(head)

  // const sotreddata=(value)=>{
  //   setsort(value.sort);
  //   setfeilds(value.field);
  // console.log(value.field);
  // }

  const onChangewarehouse = (e) => {
    console.log(e.value);
    setwarehousehvaluedb(Number(e.value));
    setwarehousehvalue({ value: e.value, label: e.label });
  };

  const handlesort = (field) => {
    setsort1(!sort1);
    // console.log(sort?{"sort":"asc","field":field}:{"sort":"dsc","field":field})
    //  sotreddata(sort1?{"sort":"asc","field":field}:{"sort":"desc","field":field})
    //  console.log(first)
    if (sort1) {
      setsort("asc");
      setfeilds(field);
    } else {
      setsort("desc");
      setfeilds(field);
    }

    // console.log(field,sort);
  };

  // const sotreddata=(value)=>{
  //   setsort(value.sort);
  //   setfeilds(value.field);
  // console.log(value.field);
  // }
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    // if (exportpage.length == 0) {
    //   axios
    //     .get(`http://43.225.54.138:8080/scm:8081/api/${axiostabledata}/export`, {
    //       headers: authHeader(),
    //     })
    //     .then((response) => {
    //       setexport(response.data);
    //       // console.log(`http://43.225.54.138:8080/scm:8081/api/product/page/${Number(getid)}/${Number(limit)}/${sort}/${fieldnme}`);
    //       console.log(response.data);

    //       // if(response.status >= 200 && response.status < 300){
    //       //   setError(response.data)
    //       // }
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //     });
    // }
    setLoading(true);  

    const user = AuthService.getCurrentUser();

    const headers = {
      Authorization: "Bearer your_token_here",
    };

    const startTime = new Date().getTime();
    axios
      .get(
        `http://43.225.54.138:8080/scm/api/${axiostabledata}/${startDate}/${endDate}/${
          Number(currentPage) - 1
        }/${Number(limit)}/${sort}/${fieldnme}`,
        { headers: authHeader() }
      )
      .then((response) => {
        const endTime = new Date().getTime();
        const elapsedTime = endTime - startTime;
        console.log(`Request took ${elapsedTime} milliseconds`);
        setentries(response.data.Enteries);
        console.log(response);
        if (response.data.Index == undefined) {
          setsize(0);
          setnumber(0);
          console.log(response);
          setdata(response.data.data);
          // setdata(response.data);
          // setdata(response.data.data);
          // if(axiostabledata=="staff"){
          //   setdata(response.data.data);
          // }else{
          //   setdata(response.data.data);
          // }
        } else {
          setsize(response.data.Pages);
          setnumber(response.data.Pages);
          setdata(response.data.Index);
        }
        // setdata(response.data.data);
      }) .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false); // Set loading to false when the request completes
      });

  }, [getid, limit, fieldnme, sort, warehousehvaluedb, currentPage, boleean]);

  const data5 = Object.assign(
    ...Sumofkeys.map(({ first }) => ({
      [first]: data.reduce(
        (acc, curr) => acc + (parseFloat(curr[first]) || 0),
        0
      ),
    }))
  );

  console.log(data5);
  // c

  const handle = (id) => {
    console.log(id);
    // if (id >= 0) {
    //   if (Number(pointindex) == 0) {
    //     navigate(`${Edit}/${id}/${Number(currentPage)}`);
    //   } else {
    //     navigate(`${Edit}/${id}/${currentPage}`);
    //   }
    // } else {
    //   if (Number(pointindex) == 0) {
    //     navigate(`${View}/${id.view}/${Number(currentPage)}`);
    //   } else {
    //     navigate(`${View}/${id.view}/${currentPage}`);
    //   }

    //   // navigate(`${View}/${id.view}/${currentPage}`)
    // }
  };
  const bruce = data.map((item, rowIndex) => (
    <tr className="the">
      {head.map(
        (item2) => (
          // <td className="datatabletd" id={item2.field=="action"?"":item.id}  onClick={()=>handle(item2.field=="action"?"":item.id)}>{item[item2.field]}</td>
          <td
            className="datatabletd"
            onClick={() => {
              handle(item2.field == "action" ? { view: item.id } : item.id);
            }}
          >
            {item2.field == "action" ? item2.subaction1 : item[item2.field]}
          </td>
        )
        //     {if(item.action){
        //  return   <td className="datatabletd"     >{item[item2.field]}  </td>
        //   }
        // else{
        //   return   <td className="datatabletd"  id={item.id}   >{item[item2.field]}  </td>
        // }
      )}
    </tr>
  ));
  useEffect(() => {
    console.log(currentPage);
    // setsuperid(Number(pointindex))
    // setCurrentPage(Number(pointindex)-1)

    setCurrentPage(Number(pointindex));

    setgetid(Number(pointindex));
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    // console.log(page-1);
    setsuperid(page - 1);
    pagination(page - 1);
  };
  const renderPageNumbers = () => {
    const pages = [];
    const maxPagesToShow = 3;

    for (
      let i = Math.max(0, currentPage - 2);
      i <= Math.min(number - 1, currentPage + 1);
      i++
    ) {
      pages.push(
        <li
          key={i}
          className={i === Number(currentPage) ? "newway" : ""}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </li>
      );
    }
    // pages+1
    return pages;
  };
  return (
    // data.length == null ?
    // 1!==1?(<div className="pilt">
    //     <div class="loader"></div>
    //   </div>) :
    <>
      <MDBCard data-aos="fade">
        <div className="card-header flex">
          <h1>{title}</h1>
          <div className="flex">
           {axiostabledata!=="sales/transportReport"? <Link to={Create}>
              <button className="btn btn-primary thh">
                {" "}
                <span className="add">{Add}</span>{" "}
              </button>
            </Link>:""}
            {exportpage.length !== 0 ? (
              <CSVLink className="whitefont pk " data={exportpage}>
                Export
              </CSVLink>
            ) : (
              ""
            )}
          </div>
        </div>
        <MDBCardBody>
          <form>
          <div className='row'>
          <div className='col'>
            <label className='form-label'>Start Date:</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className='form-control'
            />
            </div>
            <div className='col'>
            <label className='form-label'>End Date:</label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className='form-control'
            />
            </div>
            </div>
            <div className='row' style={{margin:"10px"}}>
             {loading ? (
        <div>Loading...</div>
      ) : (
        <>
          {/* Your component code */}
          <a
            className="btn btn-sm btn-primary"
            onClick={() => setboleean(!boleean)}
          >
            Submit
          </a>
        </>
      )}
            </div>
          </form>
          {warehousehdata !== undefined ? (
            <div className="row">
              <div className="col-6">
                <label className="form-label">Select warehouse</label>
                <Select
                  value={warehousehvalue}
                  options={warehousehdata}
                  onChange={(e) => onChangewarehouse(e)}
                />
              </div>
            </div>
          ) : (
            ""
          )}

          <br></br>
          <div className="forentriesandaddbutton">
            <div className="bottole">
              <p>Show entries</p>
              <select
                className="newselect"
                onChange={(e) => handlechange(e.target.value)}
              >
                {count.map((item) => {
                  return <option>{item.value}</option>;
                })}
              </select>
            </div>
            <div className=" ">
              <input
                type="text"
                className="form-control"
                onChange={(e) => searchhandle(e.target.value)}
              />
            </div>
          </div>
          {data.length == 0 ? (
            <div className="pilt">
              <div class="loader"></div>
            </div>
          ) : (
            ""
          )}
          <table className="table thrtable ">
            <thead>
              <tr>
                {head.map((head, index) => (
                  <th
                    onClick={() => {
                      handlesort(head.field == "action" ? "id" : head.field);
                    }}
                    key={index}
                  >
                    {head.label}
                    {sort1 ? (
                      <TiArrowSortedUp />
                    ) : (
                      <TiArrowSortedDown className="" />
                    )}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody>{bruce}</tbody>
            <tfoot>
              <tr>
                {head.map((item, index) => (
                  <th key={index}>
                    {data5.hasOwnProperty(item.field) ? data5[item.field] : ""}
                  </th>
                ))}
              </tr>
            </tfoot>
          </table>

          <div className="flex">
            <div className="showentri">
              {data1 == 10 ? (
                <p>
                  Showing {superid * data1 + 1} to {superid * data1 + 10} of{" "}
                  {entries} entries
                </p>
              ) : (
                ""
              )}
              {data1 == 20 ? (
                <p>
                  Showing {superid * data1 + 1} to {superid * data1 + 20} of{" "}
                  {entries} entries
                </p>
              ) : (
                ""
              )}
              {data1 == 30 ? (
                <p>
                  Showing {superid * data1 + 1} to {superid * data1 + 30} of{" "}
                  {entries} entries
                </p>
              ) : (
                ""
              )}
              {data1 == 50 ? (
                <p>
                  Showing {superid * data1 + 1} to {superid * data1 + 50} of{" "}
                  {entries} entries
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="pagination">
              <ul className="pagination">
                <li
                  onClick={() =>
                    handlePageChange(currentPage > 1 ? currentPage - 1 : 1)
                  }
                >
                  {"Previous"}
                </li>
                {renderPageNumbers()}
                {number}
                <li
                  onClick={() =>
                    handlePageChange(
                      currentPage == number ? number : currentPage + 1
                    )
                  }
                >
                  {"Next"}
                </li>
              </ul>
            </div>
          </div>
        </MDBCardBody>
      </MDBCard>
    </>
  );
};
