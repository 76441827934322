import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import AuthService from '../../services/auth.service';
import { BASE_URL } from '../../services/Api';
import authHeader from '../../services/auth-header';
import { useNavigate, useParams } from 'react-router-dom';

const Disoutwardupdate = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [error, setError] = useState('');
    const [disword, setDisword] = useState('');
    const [product2, setProduct2] = useState([]);
    const [distributor1, setDistributor1] = useState([]);
    const [userDate, setUserDate] = useState('');
     const [distRoles, setDistRoles] = useState('');
    // const [isLoading, setIsLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [datap, setDatap] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [user_date, setuser_date] = useState('');
    const [inwardDistributorStockItems, setinwardDistributorStockItems] = useState([]);
    const [distributor, setdistributor] = useState('');
    const [dist_name, setdist_name] = useState('');
    const [distItems, setdistItems] = useState('');
    const [quantity, setquantity] = useState('');
    const currentUser = AuthService.getCurrentUser();

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
        }
    });

    const getdistoutwarddata = () => {
        axios.get(BASE_URL + 'outdiststock/' + id, {
            headers: authHeader()
        }).then(res => {
            // console.log('mello',res.data)
            setUserDate(res.data.outdisdate);
            setdistributor(res.data.distributoroid);
            // setinwardDistributorStockItems(res.data.inwardDistributorStockItems);
            // setdistItems(res.data.inwardDistributorStockItems[0].product.productName);
            // setquantity(res.data.inwardDistributorStockItems[0].inwardqty)
            setItems(
                res.data.distributorStockItems.map(item => ({
                    id:item.product.id,
                    product_Name: item.product.productName ,  
                  total: Number(item.amount).toFixed(2),
                  qty: item.outwardqty,
                  mrp: item.rate
                }))
              );
            let dist_id = res.data.distributoroid;

            axios.get(BASE_URL + 'distributor/getById/' + dist_id, { headers: authHeader() }).then(res1 => {
                // console.log('mello',res1.data);
                setdist_name(res1.data.tradeName)
            })
        })
    }


    const onchangeproductstore = (option) => {
        const newProduct = datap.find(item => item.id === option.value);

        if (newProduct) {
            const isProductInList = items.some(item => item.id === newProduct.id);

            if (!isProductInList) {
                setItems(prevItems => [...prevItems, { ...newProduct, qty: 0, total: 0 }]);
            }
        }
    };

    const handleQuantityChange = (id, qty) => {
        setItems(prevItems =>
            prevItems.map(item =>
                item.id === id ? { ...item, qty: qty, total: item.mrp * qty } : item
            )
        );
    };

    // const handleSubmit = () => {
    //     const postData = {
    //         indate: userDate,
    //         distributorid: distributor,
    //         inwardDistributorStockItems: items.map(item => ({
    //             product:{"id":item.id},
    //             amount: item.total.toFixed(2)  ,
    //             outwardqty:item.qty,
    //             rate:item.mrp
    //         })),
    //     };

    //     setIsLoading(true);

    //     axios.post(`${BASE_URL}indiststock/`, postData, { headers: authHeader() })
    //         .then(response => {
    //             Toast.fire({
    //                 icon: 'success',
    //                 title: 'Successfully added'
    //             });
    //         }).catch(error => {
    //             console.error('Error adding  :', error);
    //             if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
    //                 Swal.fire({
    //                     icon: "error",
    //                     title: "Oops...",
    //                     text: "Something went wrong!",
    //                     footer: `
    //                         <div>
    //                           <p id="issueText">Why do I have this issue?</p>
    //                           <ul>
    //                               <li id="typer1">You might have an internet issue</li>
    //                           </ul>
    //                         </div>
    //                     `,
    //                     didOpen: () => {
    //                         applyFontSizeTransition('issueText');
    //                     }
    //                 });
    //             }
    //         })
    //         .finally(() => {
    //             setIsLoading(false);
    //         });
    // };
    const handleSubmit = () => {
        const postData = {
            outdisdate: userDate,
            distributoroid: distributor,
            distributorStockItems: items.map(item => ({
                product:{"id":item.id},
                amount: Number(item.total).toFixed(2)  ,
                outwardqty:item.qty,
                rate:item.mrp
            })),
        };

        setIsLoading(true);

        axios.put(BASE_URL + 'outdiststock/' + id, postData, { headers: authHeader() })
            .then(response => {
                navigate('/Distributor/Outward/Table/1')

                Toast.fire({
                    icon: 'success',
                    title: 'Successfully added'
                });
            }).catch(error => {
                console.error('Error adding  :', error);
                if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `
                            <div>
                              <p id="issueText">Why do I have this issue?</p>
                              <ul>
                                  <li id="typer1">You might have an internet issue</li>
                              </ul>
                            </div>
                        `,
                        didOpen: () => {
                            applyFontSizeTransition('issueText');
                        }
                    });
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    const applyFontSizeTransition = (elementId) => {
        const element = document.getElementById(elementId);
        element.style.transition = 'font-size 2s';
        element.style.fontSize = '30px';
        element.style.fontWeight = '600';
        setTimeout(() => {
            document.getElementById("typer1").classList.add('promote');
            element.style.fontSize = '24px';
        }, 1000);
    };

    const getDistributorByRoles = () => {
        // axios.get(`${BASE_URL}distributor/getById/${currentUser.id}`, { headers: authHeader() })
        //     .then(res => {
        //         setDistRoles(res.data.tradeName);
        //         setDistributor(res.data.id);
        //     });
    };
useEffect(() => {
    getdistoutwarddata()
}, [ ])

    useEffect(() => {

        if (currentUser.roles[0] === 'ROLE_DISTRIBUTOR') {
            getDistributorByRoles();
        }

        axios.get(`${BASE_URL}product/page/0/10/asc/id/${error.length ? error : encodeURIComponent(' ')}`, { headers: authHeader() })
            .then(response => {
                setDatap(response.data.data || response.data.Index);
                const products = (response.data.data || response.data.Index).map(use => ({ value: use.id, label: `${use.product_Name} - ${use.ean_Code}` }));
                setProduct2(products);
            })
            .catch(error => {
                console.error(error);
            });

        axios.get(`${BASE_URL}distributor/page/0/10/asc/id/${disword.length ? disword : encodeURIComponent(' ')}`, { headers: authHeader() })
            .then(response => {
                if (response.data.data == undefined) {
                    setDistributor1(response.data.Index.map(use => ({ value: use.id, label: use.trade_Name })));
                  } if (response.data.Index == undefined) {
                    setDistributor1(response.data.data.map(use => ({ value: use.id, label: use.trade_Name })));
          
                  }            });
    }, [error, disword]);

    const handledelete = () => {
        setIsLoading(true);  
    
        axios.delete(`${BASE_URL}outdiststock/${id}`, { headers: authHeader() })
            .then(response => {
                navigate('/Distributor/Outward/Table/1')

                Toast.fire({
                    icon: 'success',
                    title: 'Successfully deleted'
                });
            })
            .catch(error => {
                console.error('Error deleting:', error);
                if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                        footer: `
                            <div>
                              <p id="issueText">Why do I have this issue?</p>
                              <ul>
                                  <li id="typer1">You might have an internet issue</li>
                              </ul>
                            </div>
                        `,
                        didOpen: () => {
                            // Apply a custom function for font size transition if necessary
                            applyFontSizeTransition && applyFontSizeTransition('issueText');
                        }
                    });
                }
            })
            .finally(() => {
                setIsLoading(false); // End loading
            });
    };
    const handleback=()=>{
        console.log("first")
                navigate('/Distributor/Outward/Table/1')

    }
    return (
        <div className='card card-body'>
            <div className='card-header card-title'>
                <h2 style={{fontSize:"16px",fontWeight:700}}>Update</h2>
            </div>
            <div className='row'>
                <div className='col-1'>
                    <button onClick={()=>handledelete()}>Delete</button>
                 </div>
                <div className='col-1'>
                     <button onClick={()=>handleback()}>Back</button>
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    <label className='form-label'>Distributor</label>
                    {/* {currentUser.roles[0] === 'ROLE_DISTRIBUTOR' ? (
                        <input type='text' className='form-control' value={distRoles} readOnly />
                    ) : ( */}
                        <input type='text' value={dist_name} className='form-control' readOnly />

                    {/* )} */}
                </div>
                <div className='col'>
                    <label className='form-label'>Date</label>
                    <input type='date' value={userDate} className='form-control' onChange={(e) => setUserDate(e.target.value)} />
                </div>
            </div>

            <div className='row'>
                <div className='col'>
                    <label className='form-label'>Product</label>
                    <Select
                        options={product2}
                        onInputChange={(inputValue) => setError(inputValue)}
                        onChange={(options) => onchangeproductstore(options)}
                    />
                </div>
            </div>

            <table className='table'>
                <thead>
                    <tr>
                        <th>Product Name</th>
                        <th>Qty</th>
                        <th>MRP</th>
                        <th>Total</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {items.map((item) => (
                        <tr key={item.id}>
                            <td width="200px">{item.product_Name}</td>
                            <td width="100px">
                                <input
                                    type='number'
                                    className='form-control'
                                    value={item.qty}
                                    onChange={(e) => handleQuantityChange(item.id, parseInt(e.target.value) || 0)}
                                />
                            </td>
                            <td width="100px">{item.mrp}</td>
                            <td width="100px">
                                <input
                                    type='number'
                                    className='form-control'
                                    value={item.total }
                                    readOnly
                                />
                            </td>
                            <td width="100px">
                                <Button variant="danger" onClick={() => setItems(items.filter(i => i.id !== item.id))}>Remove</Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className='row'>
                <div className='col'>
                    <Button
                        variant="primary"
                        style={{ width: 'auto' }}
                        onClick={handleSubmit}
                        disabled={isLoading}
                    >
                        {isLoading ? 'Loading...' : 'Submit'}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Disoutwardupdate;
