import axios from "axios";
import { MDBCard } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Accordion, Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../services/Api";
import authHeader from "../services/auth-header";
import Swal from "sweetalert2";
import AuthService from "../services/auth.service";

export default function View() {
    const navigate = useNavigate();
    const { id } = useParams();
    const { pointindex } = useParams();
    const [Loading, setLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const applyFontSizeTransition = (elementId) => {
        const element = document.getElementById(elementId);
        element.style.transition = 'font-size 2s';
        element.style.fontSize = '30px';
        element.style.fontWeight = '600';
        // font-weight: 600;
        setTimeout(() => {

            document.getElementById("typer1").classList.add('promote');
            element.style.fontSize = '24px';
        }, 1000);
    };
    const [Data, setData] = useState("");
    const [warehouse, setwarehouse] = useState('');
    const [customer, setcustomer] = useState('');
    const [date, setdate] = useState('');
    const [date1, setdate1] = useState('');
    const [branchData, setBranchData] = useState('');
    const [salesItems, setsalesItems] = useState([]);
    const [getaddresss, setaddress] = useState([])
    const [deliveryAddress, setdelvieryaddress] = useState([]);

    const [version, setversion] = useState('');
    const [taxSch, settaxSch] = useState('');
    const [supTyp, setsupTyp] = useState('');
    const [regRev, setregRev] = useState('');
    const [igstOnIntra, setigstOnIntra] = useState('');
    const [typ, settyp] = useState('');
    const [no, setno] = useState('');
    const [dt, setdt] = useState('');
    const [sellerDtls, setseller] = useState({});
    const [buyerDtls, setbuyer] = useState({});
    const [slNo, setslNo] = useState('');
    const [prdDesc, setprdDesc] = useState('');
    const [isServc, setisServc] = useState('');
    const [hsnCd, sethsnCd] = useState('');
    const [barcde, setbarcde] = useState('');
    const [qty, setqty] = useState('');
    const [freeQty, setfreeQty] = useState('');
    const [unit, setunit] = useState('');
    const [unitPrice, setunitPrice] = useState('');
    const [totAmt, settotAmt] = useState('');
    const [discount, setdiscount] = useState('');
    const [preTaxVal, setpreTaxVal] = useState('');
    const [assAmt, setassAmt] = useState('');
    const [gstRt, setgstRt] = useState('');
    const [igstAmt, setigstAmt] = useState('');
    const [cgstAmt, setcgstAmt] = useState('');
    const [sgstAmt, setsgstAmt] = useState('');
    const [cesRt, setcesRt] = useState('');
    const [cesAmt, setcesAmt] = useState('');
    const [cesNonAdvlAmt, setcesNonAdvlAmt] = useState('');
    const [stateCesRt, setstateCesRt] = useState('');
    const [stateCesAmt, setstateCesAmt] = useState('');
    const [stateCesNonAdvlAmt, setstateCesNonAdvlAmt] = useState('');
    const [othChrg, setothChrg] = useState('');
    const [totItemVal, settotItemVal] = useState('');
    const [ordLineRef, setordLineRef] = useState('');
    const [orgCntry, setorgCntry] = useState('');
    const [prdSlNo, setprdSlNo] = useState('');
    const [nm, setnm] = useState('');
    const [expDt, setexpDt] = useState('');
    const [wrDt, setwrDt] = useState('');
    const [anm, setanm] = useState({});
    const [valDtls, setvaldtls] = useState({});
    const [distance, setdistance] = useState(null);
    const [transMode, settransMode] = useState('');
    const [transId, settransId] = useState('');
    const [transName, settransName] = useState('');
    const [transDocDt, settransDocDt] = useState('');
    const [transDocNo, settransDocNo] = useState('');
    const [vehNo, setvehNo] = useState('');
    const [vehType, setvehType] = useState('');
    const [img, setimg] = useState();
    const [pdf, setpdf] = useState();
    const getSalesData = () => {

        axios.get(BASE_URL + 'sales/' + id, {
            headers: authHeader()
        }).then(res => {
            // setdelvieryaddress(Number(res.data.deliveryAddress))
            (res.data.retailerstatus === 'customer' ? setaddress(res.data.retailer.deliveryAddress) : setaddress(res.data.distributor.deliveryAddress))

            console.log("mello", res.data);
            setData(res.data);
            setwarehouse(res.data.warehouse);
            (res.data.retailerstatus === 'customer' ? setcustomer(res.data.retailer) : setcustomer(res.data.distributor));

            setdate(res.data.ackdate);
            setdate1(res.data.invoicedate);
            setBranchData(res.data.branch);
            setsalesItems(res.data.salesItems);
            setversion(res.data.version);
            settaxSch(res.data.taxSch);
            setsupTyp(res.data.supTyp);
            setregRev(res.data.regRev);
            setigstOnIntra(res.data.igstOnIntra);
            settyp(res.data.typ);
            setno(res.data.no);
            setdt(res.data.dt);
            setimg(res.data.imglocation);
            setpdf(res.data.pdflocation);
            setseller({ "gstin": res.data.gstin, "lglNm": res.data.lglNm, "trdNm": res.data.trdNm, "addr1": res.data.addr1, "addr2": res.data.addr2, "loc": res.data.loc, "pin": res.data.pin, "stcd": res.data.stcd, "ph": res.data.ph, "em": res.data.em })
            setbuyer({ "gstin": res.data.bgstin, "pos": res.data.pos, "lglNm": res.data.blglNm, "trdNm": res.data.btrdNm, "addr1": res.data.baddr1, "addr2": res.data.baddr2, "loc": res.data.bloc, "pin": res.data.bpin, "stcd": res.data.bstcd, "ph": res.data.bph, "em": res.data.bem })
            setslNo(res.data.slNo);
            setprdDesc(res.data.prdDesc);
            setisServc(res.data.isServc);
            sethsnCd(res.data.hsnCd);
            setbarcde(res.data.barcde);
            setqty(res.data.qty);
            setfreeQty(res.data.freeQty);
            setunit(res.data.unit);
            setunitPrice(res.data.unitPrice);
            settotAmt(res.data.totAmt);
            setdiscount(res.data.discount);
            setpreTaxVal(res.data.preTaxVal);
            setassAmt(res.data.assAmt);
            setgstRt(res.data.gstRt);
            setigstAmt(res.data.igstVal);
            setcgstAmt(res.data.cgstVal);
            setsgstAmt(res.data.sgstVal);
            setcesRt(res.data.cesRt);
            setcesAmt(res.data.cesAmt);
            setcesNonAdvlAmt(res.data.cesNonAdvlAmt);
            setstateCesRt(res.data.stateCesRt);
            setstateCesAmt(res.data.stateCesAmt);
            setstateCesNonAdvlAmt(res.data.stateCesNonAdvlAmt);
            setothChrg(res.data.othChrg);
            settotItemVal(res.data.totItemVal);
            setordLineRef(res.data.ordLineRef);
            setorgCntry(res.data.orgCntry);
            setprdSlNo(res.data.prdSlNo);
            setnm(res.data.nm);
            setexpDt(res.data.expDt);
            setwrDt(res.data.wrDt);
            setanm({
                "nm": res.data.nm, "val": res.data.val, "assVal": res.data.assVal, "cgstVal": res.data.cgstVal, "sgstVal": res.data.sgstVal, 'igstVal': res.data.igstVal, "cesVal": res.data.cesVal,
                "stCesVal": res.data.stCesVal, "discount": res.data.discount, "othChrg": res.data.othChrg, "rndOffAmt": res.data.rndOffAmt, "totInvVal": res.data.totInvVal, "totInvValFc": res.data.totInvValFc
            });
            setvaldtls({
                "assVal": res.data.assVal, "cgstVal": res.data.cgstVal === 0 ? 0 : (res.data.cgstVal).toFixed(2), "sgstVal": res.data.sgstVal === 0 ? 0 : (res.data.sgstVal).toFixed(2), "igstVal": res.data.igstVal === 0 ? 0 : res.data.igstVal, "cesVal": res.data.cesVal, "stCesVal": res.data.stCesVal,
                "othChrg": res.data.vothChrg, "totInvVal": res.data.totInvVal,
            });

            setdistance(res.data.distance);
            settransMode(res.data.transMode);
            settransId(res.data.transId);
            settransName(res.data.transName);
            settransDocDt(res.data.transDocDt);
            settransDocNo(res.data.transDocNo);
            setvehNo(res.data.vehNo);
            setvehType(res.data.vehType);
        })
    };

    let date_value = new Date(date);
    let defaultValue = date_value.toLocaleDateString('en-CA');
    let date_value1 = new Date(date1);
    let defaultValue1 = date_value1.toLocaleDateString('en-CA');
    const datevalue2 = new Date(Data.lrndate);
    const defaultValue2 = datevalue2.toLocaleDateString('en-CA');
    const datevalue3 = new Date(Data.edd);
    const defaultValue3 = datevalue3.toLocaleDateString('en-CA');
    const datevalue4 = new Date(Data.grndate);
    const defaultValue4 = datevalue4.toLocaleDateString('en-CA');
    useEffect(() => {
        getSalesData();
    }, [])

    const getItem = (id) => {

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {

                axios.delete(BASE_URL + 'sales/' + id, {
                    headers: authHeader()
                }).then(res => {
                    Swal.fire(
                        'Deleted!',
                        'Your file has been deleted.',
                        'success'
                    )
                    // window.location.reload();
                    navigate(`/Sales/Table/${pointindex}`)
                }).catch(error => {
                    console.error('Error adding  :', error);
                    if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            footer: `
                    <div>
                      <p id="issueText">Why do I have this issue?</p>
                      <ul>
                  
                      <li id="typer1">You might have an internet issue</li>
                      </ul>
                    </div>
                  `,
                            didOpen: () => {
                                applyFontSizeTransition('issueText');
                            }
                        });
                    }
                })
                    .finally(() => {
                        setIsLoading(false);
                    });


            }
        })
    };
    console.log(buyerDtls.gstin)
    const stype = [
        { "id": "B2B", "label": "Business to Business" },
        { "id": "SEZWP", "label": "SEZ with payment" },
        { "id": "SEZWOP", "label": "SEZ without payment" },
        { "id": "EXPWP", "label": "Export with Payment" },
        { "id": "EXPWOP", "label": "Export without payment" },
        { "id": "DEXP", "label": "Deemed Export" }
    ]
  const modes1=[
        { "id": 1, "label": "Road" },
        { "id": 2, "label": "Rail" },
        { "id": 3, "label": "Air" },
        { "id": 4, "label": "Ship" },
        { "id": 5, "label": "In Transit" }
      ]
      const vechiletype=[
        { "id": "R", "label": "Regular" },
        { "id": "O", "label": "ODC" }
      ]
    const user = AuthService.getCurrentUser();

    const generateinvoice = () => {
        let attribDtls = [anm];
        let tranDtls = { taxSch, supTyp, regRev, igstOnIntra }
        const docDtls = { typ, no, dt, }
        let da_ta = salesItems.map((item) => ({
            "slNo": item.slNo,
            "prdDesc": item.prdDesc,
            "isServc": item.isServc,
            "qty": item.qty,
            "freeQty": item.freeQty,
            "unit": item.unit,
            "totAmt": item.totAmt,
            "discount": item.discount,
            "assAmt": item.assAmt,
            "gstRt": item.gstRt,
            "igstAmt": Number(item.igstAmt),
            "cgstAmt": Number(item.cgstAmt),
            "sgstAmt": Number(item.sgstAmt),
            "totItemVal": item.totItemVal,
            "hsnCd": item.product.hsnCode,

        }))
        let itemList = da_ta;
        // let valdtls = { "assVal": grossamount, "cgstVal": cgst, "sgstVal": sgst, "igstVal": igst, "othChrg": shippingcharge, "totInvVal": grandTotal, "cesVal": 0, "stCesVal": 0 }
let distance1=distance==0?null:distance
        let ewbDtls = { distance:distance1, transId, transName, transDocDt, transDocNo, vehNo, vehType, transMode }
        // // console.log('mello',{version,tranDtls,docDtls,sellerDtls,buyerDtls,itemList,valDtls,ewbDtls})
   if(distance1==null){
    setLoading(true)
    axios.post("http://43.225.54.138:8080/scm/gst/post/encrptedeinvoice/" + id, { version, tranDtls, docDtls, sellerDtls, buyerDtls, itemList, valDtls }, {
        headers: authHeader()
    }).then(res => {
        Swal.fire({
            title: 'Success!',
            text: 'Invoice  Generated successfully',
            icon: 'success',
            confirmButtonText: 'OK'
          });
          window.location.reload();

        console.log('mello', res.data)
    }).finally(() => {
        setLoading(false)
      });
   }else{
    setLoading(true)
    axios.post("http://43.225.54.138:8080/scm/gst/post/encrptedeinvoice/" + id, { version, tranDtls, docDtls, sellerDtls, buyerDtls, itemList, valDtls, ewbDtls }, {
        headers: authHeader()
    }).then(res => {
        Swal.fire({
            title: 'Success!',
            text: 'E-way bill and Invoice Generated successfully',
            icon: 'success',
            confirmButtonText: 'OK'
          });
          window.location.reload();

        console.log('mello', res.data)
    }).finally(() => {
        setLoading(false)
      });
   }

    }

// const kk=()=>{
//     // http://43.225.54.138:8080/scm/gst/get/cancelirn/20
// //     "irn":"55ee7831b388d4050693580880270cd80c16ba0abf29465c87ad11f6de4c6dbb",
// // "cnlRsn":"2",
// // "cnlRem":"Wrong entry"

// }
const [show, setShow] = useState(false);
const [cnlRem, setcnlRem] = useState([])
const [cnlRsn, setcnlRsn] = useState([])

const [cnlRem1, setcnlRem1] = useState([])
const [cnlRsn1, setcnlRsn1] = useState([])

const handleClose = () => setShow(false);
const handleShow = () => setShow(true);
const [show1, setShow1] = useState(false);

const handleClose1 = () => setShow1(false);
const handleShow1 = () => setShow1(true);
const cancelinvoice=()=>{
 axios.post(`http://43.225.54.138:8080/scm/gst/get/cancelirn/${id}`, { 
"irn":Data.irnno,
"cnlRsn":Number(cnlRsn),
"cnlRem":cnlRem
 }, {
   headers: authHeader()
 }).then(res => {
   console.log('mello', res.data)
   
   Swal.fire({
     title: 'Success!',
     text: 'Invoice Deleted successfully',
     icon: 'success',
     confirmButtonText: 'OK'
   });

   window.location.reload();


 }) .finally(() => {
   setLoading(false)
 });

 
}

const cancelinvoice1=()=>{
    axios.post(`http://43.225.54.138:8080/scm/gst/get/ewaycancel/${id}`, { 
   "ewbNo":Data.ewaybillno,
   "cancelRsnCode":Number(cnlRsn1),
   "cancelRmrk":cnlRem1
    }, {
      headers: authHeader()
    }).then(res => {
      console.log('mello', res.data)
      
      Swal.fire({
        title: 'Success!',
        text: 'E-way bill Deleted successfully',
        icon: 'success',
        confirmButtonText: 'OK'
      });
   
      window.location.reload();
   
   
    }) .finally(() => {
      setLoading(false)
    });
   
    
   }
    return (
        <MDBCard className="company">
    <Modal  show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Cancel INvoice</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        {/* "ewbNo":"241817689082",
"cancelRsnCode":2,
"cancelRmrk":"Cancelled the order" */}
<label className='form-control form-label'>IRN NO</label>

<input type="text" value={Data.irnno} className="form-control"/>
 <label className='form-label'>Cancel Reason code</label>
<input type='number' onChange={(e)=>setcnlRsn(e.target.value)} className='form-control'/>
<label className='form-label'>Cancel Reason</label>
 <textarea className='form-control' onChange={(e)=>setcnlRem(e.target.value)}>

 </textarea>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className='btn-sm' onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" className='btn-sm' onClick={()=>{handleClose();cancelinvoice()}}>
          Cancel Invoice
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal  show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Cancel E-WAY bill</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        {/* "ewbNo":"241817689082",
"cancelRsnCode":2,
"cancelRmrk":"Cancelled the order" */}
<label className='form-control form-label'>E-way bill No</label>

<input type="text" value={Data.ewaybillno} className="form-control"/>
 <label className='form-label'>Cancel Reason code</label>
<input type='number' onChange={(e)=>setcnlRsn1(e.target.value)} className='form-control'/>
<label className='form-label'>Cancel Reason</label>
 <textarea className='form-control' onChange={(e)=>setcnlRem1(e.target.value)}>

 </textarea>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className='btn-sm' onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" className='btn-sm' onClick={()=>{handleClose1();cancelinvoice1()}}>
          Cancel E-way bill
          </Button>
        </Modal.Footer>
      </Modal>


            {/* <Route path="/Sales/Table/:pointindex" element={<Newsales/>} /> */}
            {/* <Route path="/Sales/Update/:id/:pointindex" element={<SalesEdit />} />
                  <Route path="/Sales/View/:id/:pointindex" element={<SalesView />} />  */}
            {Data.status == 'converted' ? (
                <div className='d-flex bd-highlight'>
                    <div className="card-header card-title w-100"><h1>Sales View</h1></div>
                    <Link className='p-2 w-200 card-header card-title bd-highlight' to={`/Sales/Table/${pointindex}`}><Button>Back</Button></Link>

                </div>
            ) : (
                <div className='d-flex bd-highlight'>
                    <div className="card-header card-title w-100"><h1>Sales View</h1></div>
                    {  Data.invoice_status == "InACT" && <Link className='p-2 w-200 card-header card-title bd-highlight' to={`/Sales/Update/${id}/${pointindex}`}><Button>Edit</Button></Link>}
                    {user.roles[0] === 'ROLE_ADMIN' &&
                        <Link className='p-2 w-200 card-header card-title bd-highlight' variant="primary" style={{ width: 'auto' }} onClick={() => getItem(id)} disabled={isLoading}>

                            <Button className="btn-sm">  {isLoading ? 'Loading...' : 'Delete'}</Button>
                        </Link>}

                    <Link className='p-2 w-200 card-header card-title bd-highlight' to={`/Sales/Table/${pointindex}`}><Button>Back</Button></Link>
{          Data.invoice_status == "InACT"?Loading==false?<Link className='p-2 w-200 card-header card-title bd-highlight' variant="primary" style={{ width: 'auto' }} >   <Button  onClick={generateinvoice} style={{width:'184px'}}>Generate Invoice</Button></Link>:<Link className='p-2 w-200 card-header card-title bd-highlight' variant="primary" style={{ width: 'auto' }} >   <Button   style={{width:'184px'}}>Creating...</Button></Link>
:""}    
{Data.invoice_status == "ACT"?<Link className='p-2 w-200 card-header card-title bd-highlight' variant="primary" style={{ width: 'auto' }} >   <Button  onClick={()=>handleShow()} style={{width:'184px'}}>Cancel Invoice</Button></Link>
:""}    
{Data.eway_status == "ACT"?<Link className='p-2 w-200 card-header card-title bd-highlight' variant="primary" style={{ width: 'auto' }} >   <Button  onClick={()=>handleShow1()} style={{width:'184px'}}>Cancel E-way bill</Button></Link>
:""}    
          <Link className='p-2 w-200 card-header card-title bd-highlight' to={`/E-Way-Bill-Print/` + id}><Button variant="primary" type="button" >E&nbsp;Way&nbsp;Bill</Button></Link>
                    {/* <Link className='p-2 w-200 card-header card-title bd-highlight' to={`/InvoicePrint/` + id}><Button variant="primary" type="button" >Invoice</Button></Link> */}
                </div>
            )}


            <Form className="product1">
          {
              Data.invoice_status == "ACT"?<>
              <div className="row">
              <div className="col">
                  <b className="form-control">IRN-{Data.irnno}</b>
              </div>
              <div className="col">
              {/* <b>ACK NO-{Data.ackno}</b> */}
              <b className="form-control">Invoice-Status -{Data.invoice_status}</b>
              </div>
          </div>
          <div className="row">
              <div className="col-6">
              <b className="form-control">ACK NO-{Data.ackno}</b>   
               </div>
              <div className="col-6">
              <b className="form-control">ACK Date-{Data.ackdate}</b>    </div>
          </div>
              </>:""
          }
          {
            Data.eway_status == "ACT"? <>
             <b className="form-control">E-way bill No-{Data.ewaybillno}</b>
             <b className="form-control">E-way bill Date-{Data.ewbDt}</b>
             <b className="form-control">E-way bill Validity-{Data.ewbValidTill}</b>
             <b className="form-control">E-way bill Staus-{Data.eway_status}</b>

            </>:""
          }
          {
            Data.invoice_status=='CNL'?  <b className="form-control form-label">Invoice-Status-Canceled</b>:""
          }
  {
            Data.eway_status == "CNL"? <>
              
           
             <b className="form-control form-label">E-way bill Staus-Canceled</b>

            </>:""
          }
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Sales Data</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Branch Name</Form.Label>
                                    <Form.Control type="text" value={branchData.branchname} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">WareHouse Name</Form.Label>
                                    <Form.Control type="text" value={warehouse.name} />
                                </Form.Group>
                                {/* <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Date</Form.Label>
                                    <Form.Control type="date" value={defaultValue} />
                                </Form.Group> */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Terms of Payment</Form.Label>
                                    <Form.Control type='text' value={Data.paymentTerms} autoComplete="off" />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Customer Name</Form.Label>
                                    <Form.Control type="text" value={customer.tradeName} />

                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Buyer Order</Form.Label>
                                    <Form.Control type="text" autoComplete="off" value={Data.buyerorderno} readOnly />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Invoice No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" value={Data.invoiceno} readOnly />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Invoice Date</Form.Label>
                                    <Form.Control type="date" readOnly value={defaultValue1} />
                                </Form.Group>
                                {/* <Form.Group as={Col} md="8" controlId="formBasicName">
                                    <Form.Label className="label">Owner</Form.Label>
                                    <Form.Control type="text" autoComplete="off" />
                                </Form.Group> */}
                                {/* <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Type</Form.Label>
                                    <Form.Select value={Data.type} readOnly>
                                        <option value="b2b">B2B</option>
                                        <option value="b2c">B2C</option>
                                    </Form.Select>
                                </Form.Group> */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Voucher</Form.Label>
                                    <Form.Control type="text" value={Data.voucherseries} autoComplete="off" />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Status</Form.Label>
                                    <Form.Select value={Data.status} readOnly>
                                        <option value='true'>Open</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Tax Type</Form.Label>
                                    <Form.Select value={Data.taxtype} readOnly>
                                        <option>--Select--</option>
                                        <option value='withtax'>With Tax</option>
                                        <option value='withouttax'>Without Tax</option>
                                    </Form.Select>
                                </Form.Group>


                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Billing Address</Accordion.Header>
                        <Accordion.Body>
                            {/* <Row className="mb-1">
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Customer Address</Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Customer Details</Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Address : -</Form.Label>
                                    <span>&nbsp;{customerAll.address}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Name : -</Form.Label>
                                    <span>&nbsp;{customerSubContactsdata == "" ? customerAll.cname : customerSubContactsdata.contactname}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">City : -</Form.Label>
                                    <span>&nbsp;{customerAll.city}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Phone No : -</Form.Label>
                                    <span>&nbsp;{customerSubContactsdata == "" ? customerAll.phonenumber : customerSubContactsdata.phoneno}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Country : -</Form.Label>
                                    <span>{"India"}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Email Id : -</Form.Label>
                                    <span> {customerSubContactsdata == "" ? customerAll.email : customerSubContactsdata.email}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">State : -</Form.Label>
                                    <span>{supplierstate}</span>
                                </Form.Group>
                            </Row> */}

                            <b><span className="">Delivery Address</span></b> <br></br> <br></br>
                            <Row className="mb-1">
                                {/* <select className="form-select dd">
                                <option className="extrvagnze">select Address</option>
                               
                                {
                                    disdel.map((item)=>{
                                        return<>
                                          <option className="extrvagnze">Address:{item.delivery_address} ,State:-{item.state} &nbsp;,Pin-code:-{item.pincode}</option>
                                        </>
                                    })
                                }
                               </select> */}
                                <select className="form-select" value={deliveryAddress} onChange={(e) => setdelvieryaddress(e.target.value)}>
                                    <option>select data</option>
                                    {
                                        getaddresss.map((item) => {
                                            return <>
                                                <option value={item.id}>{item.delivery_address}</option>
                                            </>
                                        })
                                    }
                                </select>

                            </Row>

                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>Details</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Terms of Delivery</Form.Label>
                                    <Form.Control type="text" autoComplete="off" value={Data.termsofdelivery} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Delivery Note No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" value={Data.deliverynoteno} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Destination</Form.Label>
                                    <Form.Control type="text" autoComplete="off" value={Data.destination} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Packaging List No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" value={Data.packaginglistno} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">GST No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" value={Data.gstno} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Pan No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" value={Data.panno} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Eway Bill No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" value={Data.ewaybillno} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">GRN No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" value={Data.grnno} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Dispatched Through</Form.Label>
                                    <Form.Control type="text" value={Data.dispatchedthrough} autoComplete="off" />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Total No PKG </Form.Label>
                                    <Form.Control type="text" autoComplete="off" value={Data.totalnopkg} />
                                </Form.Group>
                                {/* <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Account No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" value={Data.ackno} />
                                </Form.Group> */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">LRN No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" value={Data.lrno} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Udyam No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" value={Data.udyamno} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">MSME No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" value={Data.msmeno} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">LRN Date</Form.Label>
                                    <Form.Control type="date" value={defaultValue2} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">EDD</Form.Label>
                                    <Form.Control type="date" value={defaultValue3} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">GRN Date</Form.Label>
                                    <Form.Control type="date" value={defaultValue4} />
                                </Form.Group>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>GST Details</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <h1>Transport Details</h1>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Tax Type</Form.Label>
                                    <Form.Control type="text" autoComplete="off" value={Data.taxSch} />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Type of supply</Form.Label>
                                    <Form.Control type="text" autoComplete="off" disabled value={stype.filter((item)=>item.id==Data.supTyp).map((item)=>item.label) } />
                                </Form.Group>
                                <h1>Document Details</h1>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Document No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" value={Data.no} />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Document Date</Form.Label>
                                    <Form.Control type="text" autoComplete="off" value={Data.dt} />
                                </Form.Group>
                                <h1>Seller Details</h1>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">GSTIN of Supplier </Form.Label>
                                    <Form.Control type="text" autoComplete="off" value={Data.gstin} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Legal Name</Form.Label>
                                    <Form.Control type="text" autoComplete="off" value={Data.lglNm} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Address1</Form.Label>
                                    <Form.Control type="text" autoComplete="off" value={Data.addr1} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Location</Form.Label>
                                    <Form.Control type="text" value={Data.loc} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">PinCode</Form.Label>
                                    <Form.Control type="text" value={Data.pin} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">State Code</Form.Label>
                                    <Form.Control type="text" value={Data.stcd} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Phone Number</Form.Label>
                                    <Form.Control type="text" value={Data.ph} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Email Id</Form.Label>
                                    <Form.Control type="text" value={Data.em} />
                                </Form.Group>
                                <h1>Buyer Details</h1>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">GSTIN of Buyer</Form.Label>
                                    <Form.Control type="text" value={buyerDtls.gstin} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Legal Name</Form.Label>
                                    <Form.Control type="text" value={buyerDtls.lglNm} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Sate Code of Place of Supply</Form.Label>
                                    <Form.Control type="text" value={buyerDtls.pos} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Address1</Form.Label>
                                    <Form.Control type="text" value={buyerDtls.addr1} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Location</Form.Label>
                                    <Form.Control type="text" value={buyerDtls.loc} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">PinCode </Form.Label>
                                    <Form.Control type="text" value={buyerDtls.pin} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">State Code</Form.Label>
                                    <Form.Control type="text" value={buyerDtls.stcd} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Phone No</Form.Label>
                                    <Form.Control type="text" value={buyerDtls.ph} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Email ID</Form.Label>
                                    <Form.Control type="text" value={buyerDtls.em} />
                                </Form.Group>
                                <h1>Eway Bill Details</h1>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Distance</Form.Label>
                                    <Form.Control type="text" value={Data.distance} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Mode of Transport</Form.Label>
                                    <Form.Control type="text" value={modes1.filter((item)=>item.id==Data.transMode).label } />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Transporter Id</Form.Label>
                                    <Form.Control type="text" value={Data.transId} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Transporter Name</Form.Label>
                                    <Form.Control type="text" value={Data.transName} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Transport Document Date</Form.Label>
                                    <Form.Control type="text" value={transDocDt} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Transport Document No</Form.Label>
                                    <Form.Control type="text" value={Data.transDocNo} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Vehicle No</Form.Label>
                                    <Form.Control type="text" value={Data.vehNo} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Vehicle Type</Form.Label>
                                    <Form.Control type="text" value={vechiletype.filter((item)=>item.id== Data.vehType).map((item)=>item.label)  } />
                                </Form.Group>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                {1 !== 1 ? "" : <a href={`http://43.225.54.138:8080/scm/${img}`} download target="_blank">
                    Download Document Image
                </a>}
                <br></br>
                {1 !== 1 ? "" : <a href={`http://43.225.54.138:8080/scm/${pdf}`} download target="_blank">
                    Download Document Pdf
                </a>}
                <br></br>

                <Table striped responsive hover size="sm">
                    <thead className='p-3 mb-2 bg-success text-white'>
                        <tr>
                            <th>PRODUCT&nbsp;NAME</th>
                            <th >UOM</th>
                            <th >QTY(PCS)</th>
                            <th >QTY(KG)</th>
                            <th >Rate</th>
                            <th >UOM</th>
                            <th >Trade&nbsp;Discount</th>
                            <th>GROSS AMOUNT (₹)</th>
                            <th >SCHEME DISCOUNT %</th>
                            <th >NET AMOUNT (₹)</th>
                            <th >GST Value</th>
                            <th >Amount</th>
                            {/* <th>Action</th> */}
                        </tr>
                    </thead>
                    <tbody style={{ overflow: "scroll" }}>
                        {salesItems.map(item =>
                            <tr key={item.product.id}>
                                <td>
                                    <Form.Group as={Col} md="12" >
                                        {/* <Form.Control type="text" value={item.productName} size="sm" /> */}
                                        <textarea type="text" className="form-control textcontrol" value={item.productName} size="sm" ></textarea>

                                    </Form.Group>
                                    <Form.Control as="textarea" value={item.text} rows={2} className='form-control' />
                                </td>
                                <td><Form.Group as={Col} md="12">
                                    <Form.Control type="text" min="0" size="sm" value={item.unitofmeasurement} />
                                </Form.Group>
                                </td>

                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" size="sm" value={item.salesquantity} />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" value={Number(item.salesquantitykgs)} size="sm" requied />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" size="sm" value={Number(item.dlp)} />
                                    </Form.Group>
                                </td>
                                <td><Form.Group as={Col} md="12">
                                    <Form.Control type="text" min="0" size="sm" value={item.calunitofmeasurement} />
                                </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" min="0" size="sm" value={Number(item.tradeDiscount)} />
                                    </Form.Group>
                                </td>
                                <td><Form.Group as={Col} md="12">
                                    <Form.Control type="number" min="0" value={Number(item.grossamount)} size="sm" readOnly requied />
                                </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" value={Number(item.schemeDiscount)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td><Form.Group as={Col} md="12">
                                    <Form.Control type="number" min="0" value={Number(item.netAmount)} size="sm" readOnly requied />
                                </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" value={Number(item.gstvalue)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" value={Number(item.total)} size="sm" />
                                    </Form.Group>
                                </td>
                                {/* <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type='button' className='btn btn-danger' readOnly value="X" size="sm" />
                                    </Form.Group>
                                </td> */}

                            </tr>
                        )}
                    </tbody>
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Packing&nbsp;&&nbsp;Forwarding</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={Data.shippingcharge} style={{ height: 30 }} readOnly />
                                </Form.Group>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Net&nbsp;Amount</td>
                            <td><Form.Group as={Col} md="12">
                                <Form.Control type="number" value={Data.grossamount} style={{ height: 30 }} />
                            </Form.Group></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>IGST</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={igstAmt} style={{ height: 30 }} requied />
                                </Form.Group>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>SGST</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={sgstAmt} style={{ height: 30 }} requied />
                                </Form.Group>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>CGST</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={cgstAmt} style={{ height: 30 }} requied />
                                </Form.Group>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Round&nbsp;off&nbsp;Value</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={Data.roundingofvalue} style={{ height: 30 }} />
                                </Form.Group>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Grand&nbsp;Total</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={Data.grandtotal} style={{ height: 30 }} readOnly />
                                </Form.Group>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </Table>

            </Form>
        </MDBCard>
    )
}