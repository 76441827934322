import { ESTIMATED_DELIVERY, ESTIMATED_DELIVERY_VALUE } from "../constant";


export const estimateddelivery = (data) => {
    // console.log(data,"mello");

    return {
        
        type : ESTIMATED_DELIVERY,
        data : data
    }
}

