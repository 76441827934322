import axios from "axios";
import { MDBCard } from "mdbreact";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../services/Api";
import authHeader from "../../services/auth-header";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

export default function Update(){
    const {id} = useParams();
    const [remarks, setremarks] = useState([]);
    const [staff,setstaff] = useState('');
    const [formdate,setformdate] = useState('');
    const navigate = useNavigate();

    let Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    });
    const getAssetRequestFormdata = () => {
        axios.get(BASE_URL + 'assetreqform/byid/'+id,{
            headers:authHeader()
          }).then(res=>{
            setremarks(res.data.remarks);
            setstaff(res.data.staffname);
            setformdate(res.data.formdate);
          })
    }
    const defaultvalue = new Date(formdate);
    const default_Value = defaultvalue.toLocaleDateString('en-CA');
    const [isLoading, setIsLoading] = useState(false);
    const applyFontSizeTransition = (elementId) => {
      const element = document.getElementById(elementId);
      element.style.transition = 'font-size 2s';
      element.style.fontSize = '30px';
      element.style.fontWeight = '600';
      // font-weight: 600;
      setTimeout(() => {
         
        document.getElementById("typer1").classList.add('promote');
        element.style.fontSize = '24px';
      }, 1000);
    };
    useEffect(() => {
        getAssetRequestFormdata()
    }, []);
    const submitdata = () => {
        setIsLoading(true);
        axios.put(BASE_URL + 'assetreqform/update/'+id,{remarks,formdate},{
            headers:authHeader()
        }).then(res=>{
            navigate('/Asset Request Form/Table/1');
            if(res.status==201||res.status==200){
                // alert("ee")
                Toast.fire({
                  icon: 'success',
                  title: 'Successfully Updated'
                })
              }
        }).catch(error => {
            console.error('Error adding  :', error);
            if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `
                <div>
                  <p id="issueText">Why do I have this issue?</p>
                  <ul>
              
                  <li id="typer1">You might have an internet issue</li>
                  </ul>
                </div>
              `,
              didOpen: () => {
                applyFontSizeTransition('issueText');
              }
            });
          }
          })
          .finally(() => {
            setIsLoading(false); 
          }); 
    }
    
    return(
        <MDBCard className="company">
            <h1>Update Asset Request Form</h1>
            <Form className="product1">
                <Row className="mb-1">
                <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Staff Name</Form.Label>
                        <Form.Control type="text" value={staff} readOnly/>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Date</Form.Label>
                        <Form.Control type="date" value={default_Value} name="formdate" onChange={(e) => setformdate(e.target.value)}/>
                    </Form.Group>
                <Form.Group as={Col} md="12" controlId="formBasicName">
                        <Form.Label className="label">Remarks</Form.Label>
                        <Form.Control as="textarea" rows={3} value={remarks} name='remarks' onChange={(e)=>setremarks(e.target.value)} placeholder='Enter Remarks' autoComplete="off" />
                    </Form.Group>
                    {/* <Form.Group as={Col} md="6" controlId="formBasicName">
                        <Form.Label className="label">Date</Form.Label>
                        <Form.Control type="date" />
                    </Form.Group>
                    </Row><br></br>
                    <Row className="mb-1">
                    <Form.Group as={Col} md="12" controlId="formBasicName">
                        <Form.Label className="label">Assest</Form.Label>
                        <Select   />
                    </Form.Group> */}
                </Row>
                {/* <br></br>
                <Table striped responsive hover size="sm">
                <thead className='p-3 mb-2 bg-success text-white'>
                        <tr>
                            <th>Assest Name</th>
                            <th>Qty</th>
                            <th>Rate</th>
                            <th>Amount</th>
                        </tr>
                        </thead>
                </Table> */}
                    </Form>
                    {/* <Button variant="primary" onClick={submitdata} type="button" >
                    Submit
                </Button> */}

                <Button variant="primary"   onClick={submitdata} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Submit'}
        </Button>
        </MDBCard>

    )
}
