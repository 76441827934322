// import { SET_DISTRIBUTOR_ID } from './actionTypes';

const initialState = {
    items: [],
    // data: null
};

const Addcarinjobsheet = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_TO_CART_JSHEET_INWARD':
        if (state.items.some(item => item.id == action.payload.id)) {
            alert('Item already exists in the cart');
          // console.log(action)
            return state;  
        } else {
            // console.log(state.items)
            console.log('Adding item to the cart');
            // console.log()
            return {
                ...state,
                items: [...state.items, action.payload]
            };
        }
// alert("d")
case 'UPDATE_ITEM':
   const index = state.items.findIndex(item => item.id === action.payload.id);
  if (index === -1) {
     return state;
  }
  const uomforkgscal=state.items.filter(item => item.id === action.payload.id);
  const uomcal=uomforkgscal.map((item)=>item.type)
  console.log(uomforkgscal)
  const updatedItems = [...state.items];


  
  if(action.payload.type1=='box'){

  
if(uomcal=='kgproduct'){

    const uomforkgscal=state.items.filter(item => item.id === action.payload.id);
    const uomcal=Number(uomforkgscal.map((item)=>item.uomSecondary))
    console.log(action.payload.type1)

    updatedItems[index] = {
    ...updatedItems[index],
    type1: action.payload.type1,
    jobsheet_qty: action.payload.jobsheet_qty,
    dlp: action.payload.dlp,
    jobsheet_qty_kg:(action.payload.jobsheet_qty*uomcal).toFixed(2),
    amount:((action.payload.jobsheet_qty*uomcal)*action.payload.dlp).toFixed(2)
  

}
} else{
  console.log(action.payload.type1)

  updatedItems[index] = {
  ...updatedItems[index],
  type1: action.payload.type1,
  jobsheet_qty: action.payload.jobsheet_qty,
  dlp: action.payload.dlp,
  amount:(action.payload.dlp*action.payload.jobsheet_qty).toFixed(2)
};

}


  }

// if(uomcal=='kgproduct'){
//   if(action.payload.type1=='box'){
//     const uomforkgscal=state.items.filter(item => item.id === action.payload.id);
//     const uomcal=Number(uomforkgscal.map((item)=>item.uomSecondary))
//     console.log(action.payload.type1)

//     updatedItems[index] = {
//     ...updatedItems[index],
//     type1: action.payload.type1,
//     qty: action.payload.qty,
//     dlp: action.payload.dlp,
//     qtykg:action.payload.qty*uomcal,
//     amt:0
//   };

// }
// }


else{
    console.log('need sdk',state.items)
    const uomforkgscal=state.items.filter(item => item.id === action.payload.id);
const uomcal=Number(uomforkgscal.map((item)=>item.uomSecondary))
    updatedItems[index] = {
      // console.log(action.payload.type1)

      ...updatedItems[index],
      type1: 'kg',
      jobsheet_qty:(action.payload.jobsheet_qty_kg/uomcal).toFixed(),
      dlp: action.payload.dlp,
      jobsheet_qty_kg:action.payload.jobsheet_qty_kg,
      amount:(action.payload.jobsheet_qty_kg*action.payload.dlp).toFixed(2)
      ,
    };
  
  }

  return {
    ...state,
    items: updatedItems
  };
 
 
  case 'REMOVE_FROM_CART_JSHEET_INWARD':
    // alert(action.payload)
    return {
        ...state,
        items: state.items.filter(item => item.id !== action.payload)
    };
 
    case 'EMPTY_CART_JSHEET_INWARD':
            return {
                ...state,
                items: []   
            };
            // case 'SET_DATA12':
            //   return {
            //       ...state,
            //       data: action.payload
            //   };
  default:
      return state;
  
  
  
  
    }




};

export default Addcarinjobsheet;