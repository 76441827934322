import axios from "axios";
import { MDBCard } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { BASE_URL } from "../../services/Api";
import authHeader from "../../services/auth-header";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

export default function Update(){
    const {id} = useParams();
    const[title,settitle] = useState('');
    const[group1,setgroup1] = useState('');
    const[group1data,setgroup1data] = useState([]);
    const[group1_data,setgroup1_data] = useState([]);
    const[group2,setgroup2] = useState('');
    const[group2data,setgroup2data] = useState([]);
    const[group2_data,setgroup2_data] = useState([]);
    const[group3,setgroup3] = useState('');
    const[group3data,setgroup3data] = useState([]);
    const[group3_data,setgroup3_data] = useState([]);
    const[group4,setgroup4] = useState('');
    const[group4data,setgroup4data] = useState([]);
    const[group4_data,setgroup4_data] = useState([]);
    const [disword, setdisword] = useState(encodeURIComponent(' '))

    const [isLoading, setIsLoading] = useState(false);
    const applyFontSizeTransition = (elementId) => {
      const element = document.getElementById(elementId);
      element.style.transition = 'font-size 2s';
      element.style.fontSize = '30px';
      element.style.fontWeight = '600';
      // font-weight: 600;
      setTimeout(() => {
         
        document.getElementById("typer1").classList.add('promote');
        element.style.fontSize = '24px';
      }, 1000);
    };
    const navigate = useNavigate();
    let Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

      const getdata = () => {
        axios.get(BASE_URL + 'group5/getById/'+id,{
            headers:authHeader()
        }).then(res=>{
            // console.warn(res.data)
            settitle(res.data.title);
            setgroup1({"id":res.data.group1.id})
            setgroup2({"id":res.data.group2.id})
            setgroup3({"id":res.data.group3.id})
            setgroup4({"id":res.data.group4.id})
            setgroup1_data({value:res.data.group1.id,label:res.data.group1.title})
            setgroup2_data({value:res.data.group2.id,label:res.data.group2.title})
            setgroup3_data({value:res.data.group3.id,label:res.data.group3.title})
            setgroup4_data({value:res.data.group4.id,label:res.data.group4.title})
        })
    };

    useEffect(()=>{
        getdata();
    },[]);

              /** get Group1 & Group2 */
              useEffect(()=>{
                axios.get(BASE_URL + `group1/page/0/10/asc/id/${disword.length?disword:encodeURIComponent(' ')}`,{
                    headers:authHeader()
                }).then(res=>{
                    if(res.data.data==undefined){
                        setgroup1data(res.data.Index.map(use => ({value:use.id,label:use.title})));
                     }  if(res.data.Index==undefined){
                        setgroup1data(res.data.data.map(use => ({value:use.id,label:use.title})));
                    
                     }
                    
                });
    
                axios.get(BASE_URL + `group2/page/0/10/asc/id/${disword.length?disword:encodeURIComponent(' ')}`,{
                    headers:authHeader()
                }).then(res=>{
                    if(res.data.data==undefined){
                        setgroup2data(res.data.Index.map(use => ({value:use.id,label:use.title})));
                     }  if(res.data.Index==undefined){
                        setgroup2data(res.data.data.map(use => ({value:use.id,label:use.title})));
                    
                     }
                    
                })
                axios.get(BASE_URL + `group3/page/0/10/asc/id/${disword.length?disword:encodeURIComponent(' ')}`,{
                    headers:authHeader()
                }).then(res=>{
                    if(res.data.data==undefined){
                        setgroup3data(res.data.Index.map(use => ({value:use.id,label:use.title})));
                     }  if(res.data.Index==undefined){
                        setgroup3data(res.data.data.map(use => ({value:use.id,label:use.title})));
                    
                     }
                    
                })
                axios.get(BASE_URL + `group4/page/0/10/asc/id/${disword.length?disword:encodeURIComponent(' ')}`,{
                    headers:authHeader()
                }).then(res=>{
                    if(res.data.data==undefined){
                        setgroup4data(res.data.Index.map(use => ({value:use.id,label:use.title})));
                     }  if(res.data.Index==undefined){
                        setgroup4data(res.data.data.map(use => ({value:use.id,label:use.title})));
                    
                     }
                    
                })
              },[disword]);

              const group1change = (e) => {
                setgroup1({"id":e.value})
                setgroup1_data({value:e.value,label:e.label})
               }
               
               const group2change = (e) => {
                 setgroup2({"id":e.value})
                setgroup2_data({value:e.value,label:e.label})

                }
                const group3change = (e) => {
                    setgroup3({"id":e.value})
                   setgroup3_data({value:e.value,label:e.label})
   
                   }
                   const group4change = (e) => {
                    setgroup4({"id":e.value})
                   setgroup4_data({value:e.value,label:e.label})
   
                   }
                const submitdata = () => {
                    setIsLoading(true); 
                    axios.put(BASE_URL + 'group5/update/'+id,{title,group1,group2,group3,group4},{
                        headers:authHeader()
                    }).then(res=>{
                        navigate('/Group5Table/1');
                        if(res.status==201||res.status==200){
                          // alert("ee")
                          Toast.fire({
                            icon: 'success',
                            title: 'Successfully Updated'
                          })
                        }
                    }).catch(error=>{
                          
                        if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
                          Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            footer: `
                              <div>
                                <p id="issueText">Why do I have this issue?</p>
                                <ul>
                            
                                <li id="typer1">You might have an internet issue</li>
                                </ul>
                              </div>
                            `,
                            didOpen: () => {
                              applyFontSizeTransition('issueText');
                            }
                          });
                        }else{
                          Swal.fire({
                              title: 'This Product is already Created',
                              text: "You won't be able to Add!",
                              icon: 'warning',
                            })
                      
                        }
                      })
                      .finally(() => {
                      setIsLoading(false); 
                      });
                }
    return(
        <MDBCard className="company">
        <h1>Update Group5</h1>
        <Form className="product1">
            <Row className="mb-1">
            <Form.Group as={Col} md="6" controlId="formBasicName">
                    <Form.Label className="label">Group1</Form.Label>
                    <Select value={group1_data} options={group1data} onChange={(e)=>group1change(e)} onInputChange={(inputValue) => setdisword(inputValue)}/>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="formBasicName">
                    <Form.Label className="label">Group2</Form.Label>
                    <Select value={group2_data} options={group2data} onChange={(e)=>group2change(e)} onInputChange={(inputValue) => setdisword(inputValue)}/>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="formBasicName">
                    <Form.Label className="label">Group3</Form.Label>
                    <Select value={group3_data} options={group3data} onChange={(e)=>group3change(e)} onInputChange={(inputValue) => setdisword(inputValue)}/>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="formBasicName">
                    <Form.Label className="label">Group4</Form.Label>
                    <Select value={group4_data} options={group4data} onChange={(e)=>group4change(e)} onInputChange={(inputValue) => setdisword(inputValue)}/>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="formBasicName">
                    <Form.Label className="label">Title</Form.Label>
                    <Form.Control type="text" name="title" value={title} onChange={(e) => settitle(e.target.value)} placeholder="Enter Title" />
                </Form.Group>
                </Row>
                <Button variant="primary" style={{width:'auto'}} onClick={submitdata} disabled={isLoading}>
      {isLoading ? 'Loading...' : 'Submit'}
    </Button>
        </Form>
    </MDBCard>
    )
}