import { combineReducers } from "redux"
import { workorderdata } from "./Reducer/workorderreducer";
import { workordereditdata } from "./Reducer/workorderreduceredit";
import { primaryorder } from "./Reducer/PrimaryOrder";
import { primaryorderview } from "./Reducer/primaryorderview";
import { primaryorderupdate } from "./Reducer/PrimaryOrderUpdate";
import { podata } from "./Reducer/PO";
import { poeditdata } from "./Reducer/poedit";
import { purchasedata } from "./Reducer/Purchase";
import { purchaseeditdata } from "./Reducer/PurchaseEdit";
import { supplierdeliverydata } from "./Reducer/supplierdelivery";
import { supplierdeliveryeditdata } from "./Reducer/supplierdeliveryedit";
import { materialdata } from "./Reducer/materialreceiptnote";
import { materialreceipteditdata } from "./Reducer/materialreceiptnoteedit";
import { purchasereturndata } from "./Reducer/PurchaseReturn";
import { purchasereturnupdatedata } from "./Reducer/purchasereturnupdate";
import { salesorderdata } from "./Reducer/salesorder";
import { salesordereditdata } from "./Reducer/salesorderedit";
import { deliverychallandata } from "./Reducer/Deliverychallan";
import { dcdata } from "./Reducer/DC";
import { salesdata } from "./Reducer/Sales";
import { saleupdatedata } from "./Reducer/salesupdate";
import { salesreturndata } from "./Reducer/salesreturn";
import { salesreturnupdatedata } from "./Reducer/salesreturnupdate";
import APrint1aprintcartonred from "./Reducer/APrint1aprintcartonred";
import Addcarinjobsheet from "./Reducer/Addcarinjobsheet";

export default combineReducers({
   workorderdata,
   workordereditdata,
   primaryorder,
   primaryorderview,
   primaryorderupdate,
   podata,
   poeditdata,
   purchasedata,
   purchaseeditdata,
   supplierdeliverydata,
   supplierdeliveryeditdata,
   materialdata,
   materialreceipteditdata,
   purchasereturndata,
   purchasereturnupdatedata,
   salesorderdata,
   salesordereditdata,
   deliverychallandata,
   dcdata,
   salesdata,
   saleupdatedata,
   salesreturndata,
   salesreturnupdatedata,
   Addcarinjobsheet,
   APrint1aprintcartonred,

})