import React from 'react';


 


export default function Under12() {
  

    return(<>
     <img src={require('../Login/img12.jpg')} className='poster' />

    
    </>)

}
