import React from 'react'
import { Mydatatable } from '../Product/Mydatatable'

export const Newtem = () => {

    let header = [
        { "label": "Id", "field": "id" },
        { "label": "Trade Name", "field": "trade_name" },
        { "label": "Transporter Name  ", "field":"transporter_name" },
        { "label": "Delivery Location", "field": "delivery_location" },
        { "label": "State", "field": "state" },
        { "label": "GST Number", "field": "gst_number" },
        { "label": "Created Date", "field": "createddate" },
        // { "label": "Created Time", "field": "createdtime" },
        // { "label": "Created By", "field": "createbyname" },
        // { "label": "Updated By", "field": "updatedbyname" },
    //  { "label": "Remarks", "field": "remarks" },
        { "label": "Action", "field": "action","subaction1":<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"/><circle cx="12" cy="12" r="3"/></svg> }
      ]; 
    const sumofkeys=[{"first":'grandtotal'} ]


  return (
     

    <Mydatatable
    head={header}
    title={"Temporary Retailer"}
    axiostabledata={'retailertemporary'}
    Add={""}
    Create={''}
    Edit={"/temporaryview"}
    View={"/temporaryview"}
    Sumofkeys={sumofkeys}
    />
  )
}
