import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
 import { useNavigate } from "react-router-dom";
import { MDBCard } from "mdbreact";
import Swal from 'sweetalert2'
import authHeader from "../../services/auth-header";
import AuthService from "../../services/auth.service";
import axios from "axios";
import New2 from "../New2";
export const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})
export default function Newtablw(){
    const [brandName, setbrandName] = useState([]);
    const [error, setError] = useState(encodeURIComponent(' '));
    const [number, setnumber] = useState([])
    const [total, setotal] = useState([])
    const [size, setsize] = useState([])
    const [getid, setgetid] = useState(0)
    const[limit,setlimit]=useState(10)
  const[sort,setsort]=useState("asc")
  const[fieldnme,setfeilds]=useState("id")

const navigate = useNavigate();
useEffect(() => {



const startTime = new Date().getTime();  

axios.get(`http://43.225.54.138:8080/scm/api/primaryworkorder/page/${Number(getid)}/${Number(limit)}/${sort}/${fieldnme}/${error}`, { headers: authHeader() })
  .then(response => {
    const endTime = new Date().getTime();  
    const elapsedTime = endTime - startTime;  
setotal(response.data.Enteries);
console.log(response);
setsize(response.data.Enteries);
setnumber(response.data.Pages);
setbrandName(response.data.Index);
    console.log(response);
  
  })
  .catch(error => {
    console.error(error);
  });

 
  }, [getid,limit,fieldnme,sort]);


  const sotreddata=(value)=>{
    setsort(value.sort);
    setfeilds(value.field);
  console.log(value.field);
  }
  
  
  
  const newentry=(entry)=>{
    console.log(entry);
    setlimit(entry)
  }
  
  const handlinks=(link)=>{
  console.log(link);
  
  }
  
  let header = [
    { "label": "Id", "field": "id" },
    { "label": "Primary Order Id", "field": "workOrderId" },
    { "label": "Primary Order Date", "field": "workOrderDate" },
    { "label": "tradeName", "field": "tradeName" },
    { "label": "GSt", "field": "gst" },
    { "label": "grossTotal", "field": "grossTotal" },
 { "label": "Remarks", "field": "remarks" },
    { "label": "Action", "field": "action","subaction1":<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"/><circle cx="12" cy="12" r="3"/></svg> }
  ]; 
  
   
  
    const pagination = (msg) => {
      console.log(msg);
      setgetid(msg)
    }
  const counting=[{"value":10},{"value":20},{"value":30},{"value":50}]
 
    return(
        <>
       <New2 name="Primary Order"
        addbutton="Add PO"
        
        tdata={brandName}
        head={header}
        sotreddata={sotreddata}
        handlinks={handlinks}
        count={counting}
        newentry={newentry}
        pagination={pagination}
        numberofpages={number}
        totalsize={size}
        Export="primaryworkorder"
        View='PrimaryOrderView'
        Addpage="/PrimaryOrderCreate"
        Edit='PrimaryOrderView'
        totalelemnts={total} />
        </>
    )
}

