import { PURCHASE_RETURN_ADD_TO_CART } from "../constant";
import { PURCHASE_RETURN_CHANGE_MEASUREMENT } from "../constant";
import { PURCHASE_RETURN_CHANGE_QTYPCS } from "../constant";
import { PURCHASE_RETURN_CHANGE_QTYKG } from "../constant";
import { CHANGE_COST_PURCHASE_RETURN } from "../constant";
import { PURCHASE_RETURN_CHANGE_CAL_MEASUREMENT } from "../constant";
import { CHANGE_PURCHASE_RETURN_DISCOUNT } from "../constant";
import { REMOVE_FROM_CART_FROM_PURCHASE_RETURN } from "../constant";
import { CHANGE_PURCHASE_RETURN_TEXT } from "../constant";
import { EMPTY_PURCHASE_RETURN_DATA } from "../constant";



export const purchasereturnaddtocart = (data) => {

    //  console.warn("action called", data);
    return {

        type : PURCHASE_RETURN_ADD_TO_CART,
        data : data
    }

}



export const removefromcartfrompurchasereturn = (data) => {

    // console.warn("action REMOVE_TO_CART called", data);
    return {

        type : REMOVE_FROM_CART_FROM_PURCHASE_RETURN,
        data : data
    }

}

export const purchasereturnmeasurement = (data, item, data2) => {

    return {
        type: PURCHASE_RETURN_CHANGE_MEASUREMENT,
        data: data,
        item: item,
        data2: data2
    }
}

export const purchasereturnqtypcs = (data, item, data2) => {


    return {

        type: PURCHASE_RETURN_CHANGE_QTYPCS,
        data: data,
        item: item,
        data2: data2
    }

}

export const purchasereturnqtykgs = (data, item, data2) => {

    return {
        type: PURCHASE_RETURN_CHANGE_QTYKG,
        data: data,
        item: item,
        data2: data2

    }
}



export const changecostpurchasereturn = (data, item, data2) => {

    // console.warn("cost change called", data);
    return {

        type : CHANGE_COST_PURCHASE_RETURN,
        data : data,
        item : item,
        data2 : data2

    }

}

export const purchasereturncalmeasurement = (data, item, data2 ) => {

    return{
        type: PURCHASE_RETURN_CHANGE_CAL_MEASUREMENT,
        data: data,
        item: item,
        data2: data2
    }
}

export const changepurchasereturndiscount = (data, item, data2) => {

    // console.warn("discount change called", data);
    return {

        type : CHANGE_PURCHASE_RETURN_DISCOUNT,
        data : data,
        item : item,
        data2 : data2

    }

}


export const changepurchasereturntext = (data, item) => {

    // console.warn("text change called", data);
    return {

        type : CHANGE_PURCHASE_RETURN_TEXT,
        data : data,
        item : item
    }

}


export const emptypurchasereturndata = () => {

    return {

        type : EMPTY_PURCHASE_RETURN_DATA,
    }

}