import axios from "axios";
import authHeader from "./auth-header";

const API_URL = "http://43.225.54.138:8080/scm/api/product/";



const getProduct = () => {
  return axios.get(API_URL + "page", { headers: authHeader() });
};
const getProductEdit = (id) => {
  return axios.get(API_URL + "getById/"+id, { headers: authHeader() });
};
const getProductAdd = (product) => {
  return axios.post(API_URL +'create' ,product, { headers: authHeader() });
};
const getProductIgst = (id) => {
  return axios.get(API_URL +'getIgstById/'+id , { headers: authHeader() });
};
const getgetIgstByIdonedata =(id) =>{
  return axios.get(API_URL +'getIgstByIdonedata/'+id , { headers: authHeader() })
}
const getigstByIdonedata =() =>{
  return axios.get(API_URL +'getIgstById/'+1 , { headers: authHeader() })
}
const getcgstByIdonedata =(cgstper) =>{
  return axios.get(API_URL +'getcgstByIdonedata/'+cgstper+'/'+2 , { headers: authHeader() })
}
const getsgstByIdonedata =(sgstper) =>{
  return axios.get(API_URL +'getcgstByIdonedata/'+sgstper+'/'+3 , { headers: authHeader() })
}
const getDelete = ( id ) => {
  return axios.delete(API_URL + "delete/"+id, { headers: authHeader() });
};

const getProductAll = ( ) => {
  return axios.get(API_URL + "getAllos", { headers: authHeader() });
};
const getProductAllos = ( ) => {
  return axios.get(API_URL + "getAll", { headers: authHeader() });
};

const ProductService = {
  getProduct,
  getProductEdit,
  getProductAdd,
  getProductIgst,
  getgetIgstByIdonedata,
  getigstByIdonedata,
  getcgstByIdonedata,
  getsgstByIdonedata,
  getDelete,
  getProductAll,
  getProductAllos
  
};

export default ProductService;