import axios from "axios";
import React, { useState } from "react";
import { BASE_URL } from "../services/Api";
import authHeader from "../services/auth-header";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { FaUserEdit } from "react-icons/fa";
import { BsFillEyeFill } from "react-icons/bs";
import { AiOutlineDelete } from "react-icons/ai";
import { MDBCard, MDBCardBody, MDBDataTable } from "mdbreact";
import { CSVLink } from "react-csv";


export default function Table(){

    const [ data , setData ] = useState([]);

    /* Delete Purchase */
  const getItem = (id) => {
    axios.delete( BASE_URL + 'purchase/'+id,{
      headers:authHeader()
    }).then(res=>{
      window.location.reload()
    })
  };

  /* Get All Purchase Data */

  const getPurchaseAll = () => {
    axios.get(BASE_URL + 'purchase/',{
     headers:authHeader()
    }).then(res=>{
        console.log("mello",res.data)
     let calldata = res.data;
     let x = 1;
     let rowsData = [];
     for(var index = 0; index < calldata.length; index++){

       let rowItem = {}
       rowItem["id"] = calldata[index].id
       rowItem["index"] = x
       rowItem["contactname"] = (calldata[index].supplierSubContacts) == null?null:(calldata[index].supplierSubContacts).contactname
       rowItem["purchasedate"] = calldata[index].purchasedate
       rowItem["shippingaddress"] = calldata[index].shippingaddress
    //    rowItem["companyname"] = calldata[index].companyname
    //    rowItem["companyname"] = calldata[index].companyname
       // rowItem["states"] = calldata[index].states
       rowItem["action"] = <div>
         <Link to={`/Purchase/Edit/${calldata[index].id}`} className="pubutton" id='pb1'>
         <FaUserEdit />
         </Link>
          <Link to={`/Purchase/View/${calldata[index].id}`}  className="pubutton">
           <BsFillEyeFill />
         </Link> <Link onClick={() => getItem(rowItem["id"])} className="pubutton" id='pb'>
           <AiOutlineDelete />
         </Link>
       </div>

       rowsData.push(rowItem);
       x += 1;
     }
     setData(rowsData)
    })
 } ;

 useEffect(() => {
    getPurchaseAll();
 },[]);

 const Alldata = () => {
    return {
      columns: [
        {
          label: 'Id',
          field: 'index',
          width: 100,
        },
        {
          label: 'Contact Name',
          field: 'contactname',
          width: 100,
        },
        {
            label: 'Purchase Date',
            field: 'purchasedate',
            width: 100,
          }, {
            label: 'Shipping Address',
            field: 'shippingaddress',
            width: 100,
          },
        {
          label: 'Action',
          field: 'action',
          sort: 'disabled',
          width: 100,
        },


      ],
      rows: data
    };
  };
    return(
        <div>
              <MDBCard>
           <h1>Purchase List</h1>
          <div className='d-flex bd-highlight'>
          <div className='p-2 w-100 bd-highlight'> <Link to='/Purchase/Create'> <button class="btn btn-sm btn-secondary">Add </button></Link></div>
          <div className='p-2 flex-shrink-1 bd-highlight'><CSVLink data={data} class="btn btn-sm btn-primary">Export</CSVLink></div>
        </div>
        
            <MDBCardBody>
              <MDBDataTable
              hover
              entriesOptions={[5, 10, 15, 20, 25,50,100]}
              data={Alldata()}
              materialSearch={true}
              />
            </MDBCardBody>
          </MDBCard>
        </div>
    )
}